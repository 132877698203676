import { Box, Grid, TextField, ThemeProvider } from "@material-ui/core";
import ArchiveIcon from "@material-ui/icons/Archive";
import ShareIcon from "@material-ui/icons/Share";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import React, { Component } from "react";
import { connect } from "react-redux";
import { lms_add_topic } from "../../../Component/RoutePaths";
import PublishIcon from "../../Assets/icons/Publish.svg";
import { Container, H1, textFieldTheme } from "../../Assets/StyledComponents";
import {
  deleteTopic,
  getConcepts,
  getCourses,
  getSubjects,
  getTopics,
  publishTopic,
  reviewTopic,
  approveTopic,
  draftTopic,
  setCourseProperties,
} from "../../Redux/Action/CourseMaterial";
import DialogComponent from "../../Utils/DialogComponent";
import PaginationComponent from "../../Utils/PaginationComponent";
import PlusButton from "../../Utils/PlusButton";
import DataTable from "./DataTable";
import DropDownRack from "./DropDownRack";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import UnarchiveIcon from "@material-ui/icons/Unarchive";

const INITIAL_PAGE_NO = 0;
const INITIAL_SEARCH_TEXT = "";

class CourseLanding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: [],

      anchorEl: null,

      popUpId: null,
      dialogStatus: false,
      dialogContent: null,
      role: "",
      alertState: false,
      alertMsg: "",
      alertSeverity: "",

      action: false,
      clickableStatus: "",
      loading: false,
    };
  }

  componentDidMount() {
    const role = sessionStorage.getItem("department");
    const { courseLandingProperties } = this.props;
    const {
      pageNo,
      searchText,
      field,
      order,
      conceptId,
      subjectId,
      courseId,
    } = this.props.courseLandingProperties;
    if (courseId && subjectId && conceptId) {
      this.props.getCourses((response) => {
        if (response.success) {
          this.props.getSubjects(courseId, (subjectResponse) => {
            if (subjectResponse.success) {
              this.props.getConcepts(subjectId, (conceptResponse) => {
                if (conceptResponse.success) {
                  this.setState({
                    role: role,
                    loading: true,
                  });
                }
              });
            }
          });
        }
      });
    } else
      this.props.getCourses((response) => {
        if (response.success) {
          this.props.getSubjects(response.data[0].id, (subjectResponse) => {
            if (subjectResponse.success) {
              this.props.getConcepts(
                subjectResponse.data[0].id,
                (conceptResponse) => {
                  if (conceptResponse.success) {
                    let obj = {
                      ...courseLandingProperties,
                      courseId: response.data[0].id,
                      subjectId: subjectResponse.data[0].id,
                      conceptId: conceptResponse.data[0].id,
                    };
                    this.props.setCourseProperties(obj);

                    this.setState({
                      role: role,
                      loading: true,
                    });
                  }
                }
              );
            }
          });
        }
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { courseLandingProperties } = this.props;
    const {
      pageNo,
      searchText,
      field,
      order,
      conceptId,
    } = this.props.courseLandingProperties;
    const { action, loading } = this.state;
    if (
      (conceptId &&
        prevProps.courseLandingProperties.conceptId !== conceptId &&
        prevProps.topics === this.props.topics) ||
      (prevProps.topics === this.props.topics &&
        prevProps.courseLandingProperties.pageNo !== pageNo) ||
      action ||
      prevState.loading !== loading
    ) {
      var requestBody = {
        page: pageNo,
        size: 10,
        search: searchText,
        field: field.length !== 0 ? field : null,
        order: order.length !== 0 ? order : null,
      };
      this.props.getTopics(conceptId, requestBody, () => {});
      if (action) this.setState({ action: false, loading: false });
    }
  }

  // Drop Downs Handling
  handleChange = (event) => {
    if (event.target.name === "course") {
      const { courseLandingProperties } = this.props;
      this.props.getSubjects(event.target.value, (subjectResponse) => {
        if (subjectResponse.success) {
          this.props.getConcepts(
            subjectResponse.data[0].id,
            (conceptResponse) => {
              if (conceptResponse.success) {
                let obj = {
                  ...courseLandingProperties,
                  courseId: event.target.value,
                  subjectId: subjectResponse.data[0].id,
                  conceptId: conceptResponse.data[0].id,
                  pageNo: 0,
                };
                this.props.setCourseProperties(obj);
              }
            }
          );
        }
      });
    } else if (event.target.name === "subject") {
      const { courseLandingProperties } = this.props;
      this.props.getConcepts(event.target.value, (conceptResponse) => {
        if (conceptResponse.success) {
          let obj = {
            ...courseLandingProperties,
            subjectId: event.target.value,
            conceptId: conceptResponse.data[0].id,
            pageNo: 0,
          };
          this.props.setCourseProperties(obj);
        }
      });
    } else {
      const { courseLandingProperties } = this.props;
      let obj = {
        ...courseLandingProperties,
        conceptId: event.target.value,
        pageNo: 0,
      };
      this.props.setCourseProperties(obj);
    }
  };

  // Fail response
  handleFail = (response) => {
    this.setState({
      alertState: true,
      alertMsg: response.message,
      alertSeverity: "error",
    });
    this.handleCloseIconClick();
  };

  handleSuccess = (response) => {
    this.setState({ action: response.success });
    this.handleCloseIconClick();
  };

  handleButton2Click = () => {
    if (this.state.dialogContent.type === "archive")
      this.props.deleteTopic(this.state.popUpId, (response) => {
        if (response.success) this.handleSuccess(response);
        else this.handleFail(response);
      });
    else if (this.state.dialogContent.type === "publish")
      this.props.publishTopic(this.state.popUpId, (response) => {
        if (response.success) this.handleSuccess(response);
        else this.handleFail(response);
      });
    else if (this.state.dialogContent.type === "unarchive")
      this.props.draftTopic(this.state.popUpId, (response) => {
        if (response.success) this.handleSuccess(response);
      });
    else if (this.state.dialogContent.type === "review")
      this.props.reviewTopic(this.state.popUpId, (response) => {
        if (response.success) this.handleSuccess(response);
      });
    else if (this.state.dialogContent.type === "approve")
      this.props.approveTopic(this.state.popUpId, (response) => {
        if (response.success) this.handleSuccess(response);
      });
  };

  handleOptions = (text, topicName, topicId) => {
    var deptname = window.sessionStorage.getItem("department");
    if (text === "Edit") {
      this.props.history.push(lms_add_topic + "?topic_id=" + topicId);
    } else if (text === "Archive") {
      const dialogContent = {
        type: "archive",
        icon: <UnarchiveIcon style={{ fontSize: "48px", fill: "#1093FF" }} />,
        title: "Are you sure you want to Archive?",
        body: deptname !== "assessment_engine_admin" ? topicName : "",
        button1: "No",
        button2: "Yes",
      };
      this.setState({ dialogStatus: true, dialogContent: dialogContent });
    } else if (text === "Unarchive") {
      const dialogContent = {
        type: "unarchive",
        icon: <UnarchiveIcon style={{ fontSize: "48px", fill: "#1093FF" }} />,
        title: "Are you sure you want to Unarchive?",
        body: topicName,
        button1: "No",
        button2: "Yes",
      };
      this.setState({ dialogStatus: true, dialogContent: dialogContent });
    } else if (text === "Send Review") {
      const dialogContent = {
        type: "review",
        icon: <ShareIcon style={{ fontSize: "48px", fill: "#1093FF" }} />,
        title: "Are you sure you want to Send Review?",
        body: topicName,
        button1: "Cancel",
        button2: "Send",
      };
      this.setState({ dialogStatus: true, dialogContent: dialogContent });
    } else if (text === "Approve") {
      const dialogContent = {
        type: "approve",
        icon: <ThumbUpIcon style={{ fontSize: "48px", fill: "#1093ff" }} />,
        title: "Are you sure you want to Approve?",
        body: topicName,
        button1: "Cancel",
        button2: "Approve",
      };
      this.setState({ dialogStatus: true, dialogContent: dialogContent });
    } else if (text === "Publish Now") {
      const dialogContent = {
        type: "publish",
        icon: <img src={PublishIcon} width="64px" height="64px" />,
        title: "Are you sure you want to Publish? ",
        body: topicName,
        button1: "Cancel",
        button2: "Publish now",
      };
      this.setState({ dialogStatus: true, dialogContent: dialogContent });
    }
  };

  handleThreeDotClick = (topicId, event, status) => {
    this.setState({
      anchorEl: event.currentTarget,
      popUpId: topicId,
      clickableStatus: status,
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, popUpId: null });
  };

  handlePlusButton = () => {
    this.props.history.push(lms_add_topic);
  };

  handleTextFieldChange = (event) => {
    const { courseLandingProperties } = this.props;
    let obj = {
      ...courseLandingProperties,
      searchText: event.target.value,
      pageNo: 0,
    };
    this.props.setCourseProperties(obj);

    // this.setState({ searchText: event.target.value, pageNo: 0 });
  };

  handlePageChange = (event, value) => {
    const { courseLandingProperties } = this.props;
    let obj = { ...courseLandingProperties, pageNo: value - 1 };
    this.props.setCourseProperties(obj);

    // this.setState({ pageNo: value - 1 });
  };

  handleButton1Click = () => {
    this.setState({
      dialogStatus: false,
      dialogContent: null,
    });
  };

  handleCloseIconClick = () => {
    this.setState({
      dialogStatus: false,
      dialogContent: null,
      anchorEl: null,
      popUpId: null,
    });
  };

  handleSendReviewClick = () => {
    const dialogContent = {
      type: "review",
      icon: <ShareIcon color="primary" style={{ fontSize: "48px" }} />,
      title: "Are you sure you want to Send Review?",
      button1: "Cancel",
      button2: "Send",
    };
    this.setState({ dialogStatus: true, dialogContent: dialogContent });
  };

  handleSearch = () => {
    this.setState({ action: true });
  };

  handleSortNew = (index, ordering) => {
    const fields = { 2: "name", 5: "wkStatusValue", 6: "createdAt" };
    const { courseLandingProperties } = this.props;
    const {
      pageNo,
      searchText,
      field,
      order,
      conceptId,
      courseId,
      subjectId,
    } = this.props.courseLandingProperties;
    let obj = {
      ...courseLandingProperties,
      order: order.concat(ordering),
      field: field.concat(fields[index]),
    };
    this.props.setCourseProperties(obj);

    this.setState({
      // field: this.state.field.concat(fields[index]),
      // order: this.state.order.concat(order),
      action: true,
    });
  };

  handleSortBlue = (fieldIndex) => {
    const { courseLandingProperties } = this.props;
    const {
      pageNo,
      searchText,
      field,
      order,
      conceptId,
      courseId,
      subjectId,
    } = this.props.courseLandingProperties;
    let obj = {
      ...courseLandingProperties,
      order: order.filter((item, index) => {
        if (index !== fieldIndex) return item;
      }),
      field: field.filter((item, index) => {
        if (index !== fieldIndex) return item;
      }),
    };
    this.props.setCourseProperties(obj);
  };

  handleSortBlur = (fieldIndex) => {
    const { courseLandingProperties } = this.props;
    const {
      pageNo,
      searchText,
      field,
      order,
      conceptId,
      courseId,
      subjectId,
    } = this.props.courseLandingProperties;

    if (order[fieldIndex] === "ASC") {
      let newOrder = order;
      newOrder.splice(fieldIndex, 1, "DESC");
      let obj = { ...courseLandingProperties, order: newOrder };
      this.props.setCourseProperties(obj);

      this.setState({ action: true });
    } else {
      let newOrder = order;
      newOrder.splice(fieldIndex, 1, "ASC");
      let obj = { ...courseLandingProperties, order: newOrder };
      this.props.setCourseProperties(obj);
      this.setState({ action: true });
    }
  };

  render() {
    const {
      anchorEl,

      popUpId,
      dialogStatus,
      dialogContent,
      role,
    } = this.state;
    const {
      handlePlusButton,
      handleThreeDotClick,
      handleClose,
      handleChange,
      handleTextFieldChange,
      handlePageChange,
      handleButton1Click,
      handleCloseIconClick,
      handleButton2Click,
      handleSendReviewClick,
      handleOptions,
      handleSearch,
      handleSortNew,
      handleSortBlue,
      handleSortBlur,
    } = this;
    const {
      pageNo,
      searchText,
      field,
      order,
      conceptId,
      courseId,
      subjectId,
    } = this.props.courseLandingProperties;
    const { courses, subjects, concepts, topics, totalPageNo } = this.props;
    return (
      <Container>
        <Grid style={{ minWidth: 0 }} container spacing={3}>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            style={{ marginBottom: "35px" }}
          >
            <Grid item>
              <H1>Course Materials</H1>
            </Grid>
            <div>
              <Grid item container alignItems="center" spacing={2}>
                <Grid item>
                  <ThemeProvider theme={textFieldTheme}>
                    <TextField
                      style={{ height: "40px" }}
                      variant="outlined"
                      placeholder="Search topic name"
                      onChange={handleTextFieldChange}
                      onKeyPress={(e) => e.key === "Enter" && handleSearch()}
                    />
                  </ThemeProvider>
                </Grid>
                <Grid item>
                  <PlusButton onClick={handlePlusButton}>Add</PlusButton>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Box width="100%" marginBottom="40px">
            <DropDownRack
              courses={courses}
              subjects={subjects}
              concepts={concepts}
              handleChange={handleChange}
              courseId={courseId}
              subjectId={subjectId}
              conceptId={conceptId}
            />
          </Box>
          <Box overflow="auto" width="100%" height="900px">
            <DataTable
              topics={topics}
              anchorEl={anchorEl}
              handleThreeDotClick={handleThreeDotClick}
              handleClose={handleClose}
              handlePlusButton
              pageNo={pageNo}
              popUpId={popUpId}
              handleSendReview={handleSendReviewClick}
              role={role}
              handleOptions={handleOptions}
              handleSortNew={handleSortNew}
              field={field}
              order={order}
              handleSortBlue={handleSortBlue}
              handleSortBlur={handleSortBlur}
              clickableStatus={this.state.clickableStatus}
            />
          </Box>
        </Grid>
        {topics !== null && (
          <PaginationComponent
            pageCount={totalPageNo}
            onPageChange={handlePageChange}
          />
        )}

        {/* Popup components */}
        <Snackbar
          open={this.state.alertState}
          onClose={() => this.setState({ alertState: false })}
        >
          <Alert severity={this.state.alertSeverity} variant="filled">
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
        <DialogComponent
          open={dialogStatus}
          dialogContent={dialogContent}
          handleButton1Click={handleButton1Click}
          handleCloseIconClick={handleCloseIconClick}
          handleButton2Click={handleButton2Click}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    courses: state.CourseMaterialReducer.courses,
    subjects: state.CourseMaterialReducer.subjects,
    concepts: state.CourseMaterialReducer.concepts,
    topics: state.CourseMaterialReducer.topics,
    totalPageNo: state.CourseMaterialReducer.totalPageNo,
    courseLandingProperties:
      state.CourseMaterialReducer.courseLandingProperties,
  };
};

export default connect(mapStateToProps, {
  getCourses,
  getSubjects,
  getConcepts,
  getTopics,
  deleteTopic,
  publishTopic,
  reviewTopic,
  approveTopic,
  draftTopic,
  setCourseProperties,
})(CourseLanding);
