import { B_SCHOOL } from "../Redux/Action";
const initialState = {
  getAllBSchoolList: null,
  AddedBSchool: null,
  getBSchoolSubRegionList: null,
  getBSchoolCountryLists: null,
  getAllBSchoolProgramList: null,
  getOneBSchoolProgramData: null,
  createBSchoolRankReducer: null,
  getAllBSchoolRankList: null,
  createAptitudeForBSchool: null,
  getAllBSchoolRoundData: null,
  createBSProgramData: null,
  getSeasonDropdown: [],
  getBSchoolRoundPerData: null,
  getBSchoolEssayQuesData: null,
  getPerBSchoolEssayQuesData: null,
  createBSchoolProgramEssayQuestion: null,
  getPerBSchoolDataForAptitudeTest: null,
  getAllBSchoolDataForAptitudeTest: null,
  bSchoolDegreeData: null,
  bSchoolProgramCategoryData: null,
  bSchoolAreaOfSpecialization: null,
  getBSRankIdData: null,
};

export const BSchoolReducer = (state = initialState, action) => {
  switch (action.type) {
    case B_SCHOOL.getAllBSchool:
      return {
        ...state,
        getAllBSchoolList: action.payload,
      };
    case B_SCHOOL.createBSchool:
      return {
        ...state,
        AddedBSchool: action.payload,
      };
    case B_SCHOOL.getBSchoolSubRegionById:
      return {
        ...state,
        getBSchoolSubRegionList: action.payload,
      };
    case B_SCHOOL.getBSchoolRegionList:
      return {
        ...state,
        getBSchoolRegionList: action.payload,
      };
    case B_SCHOOL.getBSchoolCountryList:
      return {
        ...state,
        getBSchoolCountryLists: action.payload,
      };
    case B_SCHOOL.getAllBSchoolProgram:
      return {
        ...state,
        getAllBSchoolProgramList: action.payload,
      };
    case B_SCHOOL.getPerBSchoolProgram:
      return {
        ...state,
        getOneBSchoolProgramData: action.payload,
      };
    case B_SCHOOL.BSchool_clearData:
      return {
        ...state,
        getOneBSchoolProgramData: null,
        getBSchoolRoundPerData: null,
        getPerBSchoolEssayQuesData: null,
      };
    case B_SCHOOL.CreateBSchoolRank:
      return {
        ...state,
        createBSchoolRankReducer: action.payload,
      };
    case B_SCHOOL.getAllBSchoolRank:
      return {
        ...state,
        getAllBSchoolRankList: action.payload,
      };
    case B_SCHOOL.createBSchoolAptitude:
      return {
        ...state,
        createAptitudeForBSchool: action.payload,
      };
    case B_SCHOOL.getAllbsRound:
      return {
        ...state,
        getAllBSchoolRoundData: action.payload,
      };
    case B_SCHOOL.createBSchoolProgramRound:
      return {
        ...state,
        createBSProgramData: action.payload,
      };
    case B_SCHOOL.bs_seasons:
      return {
        ...state,
        getSeasonDropdown: action.payload,
      };
    case B_SCHOOL.getPerBSchoolRound:
      return {
        ...state,
        getBSchoolRoundPerData: action.payload,
      };
    case B_SCHOOL.getAllBSchoolEssayQues:
      return {
        ...state,
        getBSchoolEssayQuesData: action.payload,
      };

    case B_SCHOOL.getPerBSchoolEssayQues:
      return {
        ...state,
        getPerBSchoolEssayQuesData: action.payload,
      };
    case B_SCHOOL.createBSchoolEssayQuestions:
      return {
        ...state,
        createBSchoolProgramEssayQuestion: action.payload,
      };
    case B_SCHOOL.getPerAptitudeTestForBSchool:
      return {
        ...state,
        getPerBSchoolDataForAptitudeTest: action.payload,
      };

    case B_SCHOOL.getAllAptitudeTestForBSchool:
      return {
        ...state,
        getAllBSchoolDataForAptitudeTest: action.payload,
      };
    case B_SCHOOL.bSchoolDegree:
      return {
        ...state,
        bSchoolDegreeData: action.payload,
      };
    case B_SCHOOL.bSchoolProgramCategory:
      return {
        ...state,
        bSchoolProgramCategoryData: action.payload,
      };
    case B_SCHOOL.bSchoolAreaOfSpecialization:
      return {
        ...state,
        bSchoolAreaOfSpecialization: action.payload,
      };
    case B_SCHOOL.getBSRankId:
      return {
        ...state,
        getBSRankIdData: action.payload,
      };
    default:
      break;
  }
  return state;
};
