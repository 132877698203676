import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import MySnackBar from "../MySnackBar";
import { GSContainer, GSTitle } from "../../Asset/StyledComponents/Styles";
import { makeStyles } from "@material-ui/core/styles";
import BackButton from "../../Asset/Images/backbutton.svg";
import { AppBar, Breadcrumbs } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Typography } from "@material-ui/core";
import { Grid, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  createBSEssayQuestion,
  getOneBSEssayQuestions,
} from "../../Actions/BSchoolAction";
import { isOnlyNumber } from "../Validation";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
  },
  btnStyles: {
    paddingLeft: 30,
    paddingRight: 30,
  },
}));
export default function BSEasyQuestion(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getPerBSchoolEssayQuesData } = useSelector(
    (state) => state.BSchoolReducer
  );
  const [snack, setSnack] = useState({
    open: false,
    message: "",
    color: "",
  });
  const [state, setState] = useState({
    question: null,
    difficult: null,
    orderNo: null,
    id: "",
    programId: null,
  });

  const handleSave = () => {
    if (state.difficult && state.question && state.orderNo) {
      if (state.id.length > 0) {
        let obj = {
          question: state.question,
          difficult: state.difficult,
          orderNo: Number(state.orderNo) === 0 ? null : Number(state.orderNo),
          programId: state.programId,
          id: state.id,
        };
        dispatch(
          createBSEssayQuestion(obj, (res) => {
            if (res.success) {
              setSnack({
                message: "Updated Successfully!",
                open: true,
                color: "success",
              });
            } else {
              setSnack({
                message: res.data.message,
                open: true,
                color: "error",
              });
            }
          })
        );
      } else {
        let obj = {
          question: state.question,
          difficult: state.difficult,
          orderNo: Number(state.orderNo) === 0 ? null : Number(state.orderNo),
          programId: props.location.state,
        };
        dispatch(
          createBSEssayQuestion(obj, (res) => {
            if (res.success) {
              setSnack({
                message: "Saved Successfully!",
                open: true,
                color: "success",
              });
              setState({
                question: "",
                difficult: "",
                orderNo: "",
                id: "",
                programId: null,
              });
            } else {
              setSnack({
                message: res.data.message,
                open: true,
                color: "error",
              });
            }
          })
        );
      }
    }
  };

  useEffect(() => {
    if (props.location.state?.state?.id) {
      dispatch(getOneBSEssayQuestions(props.location.state.state.id));
    }
  }, []);

  useEffect(() => {
    if (getPerBSchoolEssayQuesData !== null) {
      const {
        id,
        orderNo,
        question,
        difficult,
        programId,
      } = getPerBSchoolEssayQuesData;
      setState({
        ...state,
        id: id,
        orderNo: orderNo,
        question: question,
        difficult: difficult,
        programId: programId,
      });
    }
  }, [getPerBSchoolEssayQuesData]);

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div style={{ display: "flex", flexDirection: "row", margin: "10px" }}>
          <img
            src={BackButton}
            style={{ cursor: "pointer", marginTop: "-10px" }}
            onClick={() => props.history.goBack()}
          />
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Typography
              style={{
                cursor: "pointer",
                fontWeight: "600",
                marginLeft: "10px",
              }}
            >
              Back
            </Typography>
          </Breadcrumbs>
        </div>
        <AppBar position="static" color="default">
          <GSContainer>
            <GSTitle>
              {" "}
              {state.id.length > 0 ? "Edit" : "Add"} Essay Question
            </GSTitle>
            <Grid container justifyContent="center" spacing={4}>
              <Grid item md={10}>
                <Grid container spacing={4}>
                  <Grid item md={6}>
                    <TextField
                      value={state.question}
                      onChange={(e) =>
                        setState({ ...state, question: e.target.value })
                      }
                      name={"question"}
                      label={"Question"}
                      fullWidth
                      InputLabelProps={{
                        shrink: state.question === null ? false : true,
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      value={state.difficult}
                      onChange={(e) =>
                        setState({ ...state, difficult: e.target.value })
                      }
                      name={"difficult"}
                      label={"Difficult"}
                      fullWidth
                      InputLabelProps={{
                        shrink: state.difficult === null ? false : true,
                      }}
                      type="text"
                      onKeyDown={(e) => isOnlyNumber(e) && e.preventDefault()}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      value={state.orderNo}
                      onChange={(e) =>
                        setState({ ...state, orderNo: e.target.value })
                      }
                      name={"orderNo"}
                      label={"Order No"}
                      fullWidth
                      InputLabelProps={{
                        shrink: state.orderNo === null ? false : true,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Grid container justifyContent="center">
                  <Grid item md={1}>
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      className={classes.btnStyles}
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </GSContainer>
        </AppBar>
        <MySnackBar
          snackOpen={snack.open}
          snackVariant={snack.color}
          snackMsg={snack.message}
          onClose={() =>
            setSnack({
              open: false,
              message: "",
              color: "",
            })
          }
        />
      </div>
    </React.Fragment>
  );
}
