import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import LatexViewer from "../../../../../Utils/LatexViewer";
import { Passage as passage } from "../../../../../Assets/css/Preview/TestComponent";
import { QuestionDiv } from "../../../../../Assets/css/Preview/GmatStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@mui/material/Tabs";
import DropDown from "../../../../../Utils/PreviewDropDown";

export default function MultiTab(props) {
  const { testResponse } = props;
  console.log(testResponse, "testResponse");
  // const options = [
  //   { title: "Select", value: "product", id: "default", disabled: true },
  //   { title: "1 out of 6", value: "1 out of 6", id: "one" },
  //   { title: "1 out of 3", value: "1 out of 3", id: "two" },
  //   { title: "2 out of 3", value: "2 out of 3", id: "three" },
  //   { title: "5 out of 6", value: "5 out of 6", id: "four" },
  // ];

  // const math = (ii) => {
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(ii, "text/html").body.textContent;
  //   return doc;
  // };

  const { Question } = passage;
  const tabValues = [0, 1, 2];
  return (
    <Grid container>
      <Grid item xs={6} className="blue_border">
        <Box height="100%">
          <>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                // onChange={handleChangeTab}
                value={testResponse.data.tabValue}
                aria-label="lab API tabs example"
              >
                {tabValues.map((value) => (
                  <Tab
                    key={value}
                    label={`Item ${value + 1}`}
                    value={value}
                    // {...a11yProps(value)}
                  />
                ))}
              </Tabs>
            </Box>
            <Box
              fontSize={16}
              color={"#052A4E"}
              lineHeight={"30px"}
              padding={"10px 12px"}
            >
              <LatexViewer
                math={
                  testResponse.data.multiDescription[
                    testResponse.data.tabValue
                  ]["description"]
                }
              />
            </Box>
            {/* </div>
                </Fragment>
              );
            })} */}
          </>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box className={"overflow-scroll"} id="inside-test-container">
          {testResponse.data.type === "MULTI_TAB" && (
            <QuestionDiv>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  <Question style={{ marginBottom: "15px" }}>
                    <LatexViewer math={testResponse.data.question} />
                  </Question>
                  <Box display={"flex"}>
                    <Question>
                      <span>Yes &nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </Question>
                    <Question>
                      <span>No</span>
                    </Question>
                  </Box>
                </FormLabel>
                {testResponse.data.choices.map((item) => {
                  return (
                    <Grid
                      container
                      spacing={2}
                      style={{ marginBottom: "10px" }}
                    >
                      <Grid item>
                        <RadioGroup
                          row
                          style={{ alignItems: "center" }}
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="row-radio-buttons-group"
                          value={item.correctChoice}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            style={{ marginBottom: "0px" }}
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            style={{ marginRight: "0px", marginBottom: "0px" }}
                          />
                        </RadioGroup>
                      </Grid>
                      <Grid item xs style={{ alignSelf: "center" }}>
                        <LatexViewer math={item.text} />
                      </Grid>
                    </Grid>
                  );
                })}
              </FormControl>
            </QuestionDiv>
          )}
          {/* {testResponse.data.type === "MULTITWO_PART" && (
            <QuestionDiv>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  <Question style={{ marginBottom: "15px" }}>
                    <LatexViewer math={testResponse.data.question} />
                  </Question>
                </FormLabel>
                <table className="two-part-table">
                  <tr>
                    <th>Liters in fuel in 1 h</th>
                    <th>Liters of fuel in 60 km</th>
                    <th></th>
                  </tr>
                  <tr>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        {testResponse.data.choices.map((item) => {
                          return (
                            <Grid item>
                              <RadioGroup
                                row
                                style={{ justifyContent: "center" }}
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="row-radio-buttons-group"
                                value={item.correctChoice}
                                // onChange={handleChange}
                              >
                                <FormControlLabel
                                  value={true}
                                  control={<Radio />}
                                  style={{ margin: "0px" }}
                                />
                              </RadioGroup>
                            </Grid>
                          );
                        })}
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        {testResponse.data.choices.map((item) => {
                          return (
                            <Grid item>
                              <RadioGroup
                                row
                                style={{ justifyContent: "center" }}
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="row-radio-buttons-group"
                                value={item.wrongChoice}
                                // onChange={handleChange}
                              >
                                <FormControlLabel
                                  value={true}
                                  control={<Radio />}
                                  style={{ margin: "0px" }}
                                />
                              </RadioGroup>
                            </Grid>
                          );
                        })}
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        {testResponse.data.choices.map((choice, index) => (
                          <Typography
                            variant="h4"
                            color="textPrimary"
                            style={{ margin: "10px" }}
                          >
                            {choice.text ? (
                              <LatexViewer math={choice.text} />
                            ) : (
                              <img src={choice.choiceImage} alt="" />
                            )}
                          </Typography>
                        ))}
                      </div>
                    </td>
                  </tr>
                </table>
              </FormControl>
            </QuestionDiv>
          )}
          {testResponse.data.type === "MULTIDROP_DOWN" && (
            <QuestionDiv>
              <Question style={{ marginBottom: "15px" }}>
                <LatexViewer math={testResponse.data.question} />
              </Question>
              {testResponse.data.choices.map((item) => {
                return (
                  <Grid container spacing={2}>
                    <span
                      style={{
                        wordBreak: "break-word",
                        width: "100%",
                        marginBottom: "2rem",
                      }}
                    >
                      <span>
                        {math(item.text)}
                        <DropDown
                          name="Map Error Genre"
                          items={options}
                          value={item.dropDown || "default"}
                        />
                      </span>
                    </span>
                  </Grid>
                );
              })}
            </QuestionDiv>
          )} */}
        </Box>
      </Grid>
    </Grid>
  );
}
