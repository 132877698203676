import { Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { GSContainer, useStyles } from "../../Asset/StyledComponents/Styles";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  clearData,
  createGradSchoolRequirementsList,
  getGradSchoolRequirementsList,
} from "../../Actions/GradSchoolAction";
import MySnackBar from "../MySnackBar";
import { isEmptyString } from "../Validation";
export default function GradSchoolRequirements(props) {
  const dispatch = useDispatch();
  const {
    getGradSchoolRequirementReducer,
    getPerGradSchoolProgramData,
  } = useSelector((state) => state.GradSchoolReducer);
  const classes = useStyles();
  const [productRequirement, setProductRequirement] = useState({
    id: "",
    specialRequirements: "",
    documentRequired: "",
    extraRequirementOne: "",
    extraRequirementTwo: "",
    extraRequirementThree: "",
    extraRequirementFour: "",
  });
  const [state, setState] = useState({
    open: false,
    message: "",
    color: "",
  });
  const coursesFields = [
    {
      name: "specialRequirements",
      value: productRequirement.specialRequirements,
      label: "Special Requirements",
    },
    {
      name: "documentRequired",
      value: productRequirement.documentRequired,
      label: "Documents Required",
    },
    {
      name: "extraRequirementOne",
      value: productRequirement.extraRequirementOne,
      label: "Extra Requirements 1",
    },
    {
      name: "extraRequirementTwo",
      value: productRequirement.extraRequirementTwo,
      label: "Extra Requirements 2",
    },
    {
      name: "extraRequirementThree",
      value: productRequirement.extraRequirementThree,
      label: "Extra Requirements 3",
    },
    {
      name: "extraRequirementFour",
      value: productRequirement.extraRequirementFour,
      label: "Extra Requirements 4",
    },
  ];
  const onChange = (e) => {
    const { name, value } = e.target;
    setProductRequirement({
      ...productRequirement,
      [name]: value,
    });
  };

  const handleSave = () => {
    if (
      !isEmptyString(productRequirement.specialRequirements) ||
      !isEmptyString(productRequirement.documentRequired) ||
      !isEmptyString(productRequirement.extraRequirementOne) ||
      !isEmptyString(productRequirement.extraRequirementTwo) ||
      !isEmptyString(productRequirement.extraRequirementThree) ||
      !isEmptyString(productRequirement.extraRequirementFour)
    ) {
      if (productRequirement.id?.length > 0) {
        let obj = {
          id: productRequirement.id,
          specialRequirements: productRequirement.specialRequirements,
          documentsRequired: productRequirement.documentRequired,
          extraRequirement1: productRequirement.extraRequirementOne,
          extraRequirement2: productRequirement.extraRequirementTwo,
          extraRequirement3: productRequirement.extraRequirementThree,
          extraRequirement4: productRequirement.extraRequirementFour,
        };
        dispatch(
          createGradSchoolRequirementsList(obj, (res) => {
            if (res.data.success) {
              setState({
                open: true,
                message: "Updated Successfully",
                color: "success",
              });
              dispatch(getGradSchoolRequirementsList(props.location.state.id));
            }
          })
        );
      } else {
        let obj = {
          programId:
            props.gradSchoolProgramCreatedId || props.location.state.id,
          specialRequirements: productRequirement.specialRequirements,
          documentsRequired: productRequirement.documentRequired,
          extraRequirement1: productRequirement.extraRequirementOne,
          extraRequirement2: productRequirement.extraRequirementTwo,
          extraRequirement3: productRequirement.extraRequirementThree,
          extraRequirement4: productRequirement.extraRequirementFour,
        };
        dispatch(
          createGradSchoolRequirementsList(obj, (res) => {
            if (res.data.success) {
              setState({
                open: true,
                message: "Saved Successfully",
                color: "success",
              });
              dispatch(clearData());
            }
          })
        );
      }
    }
  };

  useEffect(() => {
    if (props.location.state.id) {
      dispatch(getGradSchoolRequirementsList(props.location.state.id));
    }
  }, []);

  useEffect(() => {
    if (getGradSchoolRequirementReducer !== null) {
      if (getGradSchoolRequirementReducer.success) {
        const {
          documentsRequired,
          extraRequirement1,
          extraRequirement2,
          extraRequirement3,
          extraRequirement4,
          id,
          programId,
          specialRequirements,
        } = getGradSchoolRequirementReducer.data;
        setProductRequirement({
          id: id,
          specialRequirements: specialRequirements ? specialRequirements : "",
          documentRequired: documentsRequired ? documentsRequired : "",
          extraRequirementOne: extraRequirement1 ? extraRequirement1 : "",
          extraRequirementTwo: extraRequirement2 ? extraRequirement2 : "",
          extraRequirementThree: extraRequirement3 ? extraRequirement3 : "",
          extraRequirementFour: extraRequirement4 ? extraRequirement4 : "",
        });
      }
    }
  }, [getGradSchoolRequirementReducer]);

  return (
    <React.Fragment>
      <GSContainer>
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems="center"
          spacing={4}
        >
          <Grid item md={10}>
            <Grid container spacing={6}>
              {coursesFields.map((item) => {
                return (
                  <Grid item md={6}>
                    <TextField
                      value={item.value}
                      onChange={onChange}
                      name={item.name}
                      label={item.label}
                      fullWidth
                      type={item.type}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item md={2}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              className={classes.btnStyles}
              onClick={handleSave}
              disabled={
                props.location.state.id
                  ? false
                  : props.gradSchoolProgramCreatedId
                  ? false
                  : true
              }
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </GSContainer>

      <MySnackBar
        snackOpen={state.open}
        snackVariant={state.color}
        snackMsg={state.message}
        onClose={() =>
          setState({
            open: false,
            message: "",
            color: "",
          })
        }
      />
    </React.Fragment>
  );
}
