import React, { useState, useEffect } from 'react';
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
} from '@material-ui/core';
import useTable from '../../Utils/useTable';
import Controls from '../../Utils/controls/Controls';
import { Search } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Notification from '../../Utils/Notification';
import { useHistory } from 'react-router-dom';
import { createWhatsNewPath, editWhatsNewPath } from '../../RoutePaths';
import Loader from '../../Utils/controls/Loader';
import MuiAlert from '@material-ui/lab/Alert';
import ConfirmDialog from '../../Utils/ConfirmDialog';
import { useSelector, useDispatch } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import { deleteWallWhatsNew, listWallWhatsNew } from '../../../Actions/WallActions';

const Alert = (props) => <MuiAlert elevation={6} variant='filled' {...props} />;

const useStyles = makeStyles((theme) => ({
  pageContent: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
  },
  searchInput: {
    width: '65%',
  },
  newButton: {
    position: 'absolute',
    right: '20px',
    borderRadius: '26px',
  },
}));

const headCells = [
  { id: 'category', label: 'User Type', disableSorting: true },
  { id: 'caption', label: 'Caption' },
  { id: 'elevWhatsNewRedirectionUrl', label: 'Redirection', disableSorting: true },
  { id: 'supportingMedia', label: 'Type' },
  { id: 'actions', label: 'Actions', disableSorting: true },
];

export default function WhatsNew() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading, error, posts } = useSelector((state) => state.wallWhatsNewReducer);
  let totalPages = posts?.data?.totalPages;

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting, page } = useTable(
    posts?.data?.content,
    headCells,
    filterFn,
    totalPages
  );

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value == '') return items;
        else
          return items.filter((x) => x.caption.toLowerCase().includes(target.value.toLowerCase()));
      },
    });
  };

  // Render the "name" from the array of objects
  const renderListCategory = (categories) => {
    let category = '';
    for (let i = 0; i < categories.length; i++) {
      category =
        i !== categories.length - 1
          ? category.concat(categories[i].userType, ', ')
          : category.concat(categories[i].userType, '');
    }
    return category;
  };

  const openInPage = (item) => {
    history.push({
      pathname: `${editWhatsNewPath}/${item.id}`,
      postType: 'WhatsNew',
    });
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    dispatch(deleteWallWhatsNew(id));
    setTimeout(() => {
      dispatch(listWallWhatsNew(page));
    }, 1200);
    setNotify({
      isOpen: true,
      message: 'Deleted Successfully',
      type: 'success',
    });
  };

  useEffect(() => {
    dispatch(listWallWhatsNew(page));
  }, [dispatch, page]);

  return (
    <>
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.RoundedInput
            className={classes.searchInput}
            placeholder="Search What's New by caption..."
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          <Controls.Button
            text="Create What's New"
            variant='contained'
            color='primary'
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={() => {
              history.push({
                pathname: createWhatsNewPath,
                type: false,
                postType: 'WhatsNew',
              });
            }}
          />
        </Toolbar>

        <TblContainer>
          <TblHead />
          {posts?.data?.content && (
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell style={{ width: '350px', lineHeight: '25px' }}>
                      {renderListCategory(item.elevWhatsNewUserTypes)}
                    </TableCell>
                    <TableCell>{`${item.caption?.slice(0, 30)}...`}</TableCell>
                    <TableCell>{item.elevWhatsNewRedirectionUrl?.redirectionUrlName}</TableCell>
                    <TableCell>{item.supportingMedia}</TableCell>
                    <TableCell>
                      <Controls.ActionButton onClick={() => openInPage(item)}>
                        <EditOutlinedIcon fontSize='small' color='primary' />
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title: 'Are you sure to delete this post?',
                            subTitle: "You can't undo this operation",
                            onConfirm: () => {
                              onDelete(item.id);
                            },
                          });
                        }}
                      >
                        <DeleteIcon fontSize='small' color='secondary' />
                      </Controls.ActionButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </TblContainer>
        <div style={{ margin: '2rem auto', width: '60%' }}>
          {loading && <Loader />}
          {error && <Alert severity='error'>{error}</Alert>}
          {!loading && posts?.data?.length === 0 && (
            <Alert severity='info'>0 What's New Content Found</Alert>
          )}
        </div>
        <TblPagination />
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
}
