/**
 * Icanio Technology. All rights reserved.
 **/

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PremiumUsersLanding from '../Component/PremiumUsers/PremiumUserLanding';
import '../Designs/Asset/Login.css';
import Curated_Course from '../Designs/CuratedCourse';
import MLogin from '../Designs/MLogin';
import Personal_information from '../Designs/PersonalInformation';
//LMS
import CourseLanding from '../Lms/Component/CourseLanding/CourseLanding';
import AddNewTopic from '../Lms/Component/CourseMaterials/addNewTopic/Index';
import CourseTaken from '../Lms/Component/Student/Index';
import AddStudyPlans from '../Lms/Component/StudyPlans/AddStudyPlans';
import StudyPlans from '../Lms/Component/StudyPlans/Index';
import AddTest from '../Lms/Component/Test/AddTest/Add';
import BulkUpload from '../Lms/Component/Test/BulkUpload/Index';
import TestLanding from '../Lms/Component/Test/Index';
import SingleUpload from '../Lms/Component/Test/SingleUpload/Index';
import AppVersionChange from './AppVersion/AppVersionChange';
import AspirationTab from './Aspiration/AspirationTab';
import Callschedule from './Callschedule';
import CareerTrackVideo from './CareerTrack/CareerTrackVideo';
import CareerTrackVideoSet from './CareerTrack/CareerTrackVideoSet';
import CareerTrack from './CareerTrack/Index';
import City from './City';
import College from './College';
import TabPanel from './Course/TabPanel';
import Courses from './Courses';
import Degree from './Degree';
import Department from './Department';
import InTake from './InTake';
import LandingAdmin from './LandingAdmin';
import Notification from './Notification';
// import Onboarding from './ObCallSummary/Rating';
import CallSummaryLayout from './ObCallSummary/CallSummaryLayout';
// import Rating from './ObOnboarding/Onboarding';
// import PersonaInfo, { personalInfo } from './ObOnboarding/personalInfo'
import ClientDetails from './ObCallSummary/ClientDetails';
import ProductActivation from './ObCallSummary/productActivation';
import PersonaInfo from './ObOnboarding/personalInfo';
import ObOperationLanding from './ObOperations/ObOperationLanding';
import ProductBasedUsers from './ObOperations/ProductBasedUsers';
import StageBasedLayout from './ObOperations/StageBasedLayout';
import ProductComboForm from './Product/ProductComboForm';
import Product from './Product/ProductLanding';
import ProductStages from './Product/ProductStages';
import ProductVariantRoot from './Product/ProductVariantRoot';
import StarterPack from './ProductBased/StarterPack';
import ProductPunchingLanding from './ProductPunching/ProductPunchingLanding';
import Choice from './Question/Choice';
import Question from './Question/Question';
import QuestionSet from './Question/QuestionSet';
import QuestionBank from './QuestionBank';
import ReportRootContainer from './Report/Index';
import ReportHome from './ReportHome';
import ClsaLanding from '../Component/Clsa/ClsaLanding';
import {
  addCoursePath,
  appVersion,
  aspirationPath,
  bulk_upload,
  callSchedulePath,
  callSummaryLayoutPath,
  careerTrackPath,
  careerTrackVideoPath,
  careerTrackVideoSetPath,
  choicePath,
  cityPath,
  clientDetailsPath,
  collegePath,
  coursePath,
  createPath,
  curatedPath,
  degreePath,
  departmentPath,
  editCoursePath,
  editPath,
  intakePath,
  gradSchool,
  landingAdminPath,
  listUsersProdBasedPath,
  lmsTest,
  lms_add_study_plan,
  lms_add_test,
  lms_add_topic,
  lms_course_landing,
  lms_course_taken,
  lms_study_plans,
  lms_copy_question,
  lms_passage,
  lms_view_study_plan,
  loginPath,
  notificationPath,
  obOperationPath,
  personelInfoPath,
  premiumUsersPath,
  productActivationPath,
  productBasedPath,
  productcomboPath,
  productPath,
  productPunchingPath,
  productstructurePath,
  productuserPunchingPath,
  productVariantPath,
  questionBankPath,
  questionSetPath,
  questionsPath,
  reportContentPath,
  reportsPath,
  single_upload,
  stagedTabsPath,
  starterPackPath,
  studentIdPath,
  studentPath,
  testCreate,
  testEdit,
  testimonialsPath,
  testPath,
  universityPath,
  videoPath,
  wallPath,
  webinarPath,
  clsaPath,
  clsaTestCreate,
  clsaTestEdit,
  userManagementPath,
  result,
  placementDrives,
  drivePath,
  thirdYear,
  createWebinarPath,
  editWebinarPath,
  lms_concept,
  clsaResultPath,
  addGradSchool,
  addGradSchoolProgram,
  viewGradSchoolProgram,
  bSchool,
  addBSchool,
  rankView,
  bsRank,
  bsProgram,
  bsRound,
  bsEssayQuestion,
  addBSEssayQuestion,
  addBSProgram,
  bsAptitudeTest,
  addRound,
  editRound,
  createSuccessPath,
  editSuccessPath,
  lms_result,
  CRUDPath,
  CRUDwebinarPath,
  FolderSecondPagePath,
  lms_feedback,
  lms_feedback_overview,
  examSpacePath,
  semesterPath,
  subjectPath,
  examSpaceUniversityPath,
  chapterPath,
  dashboard,
  FileSecondPagePath,
  FolderPath,
  FilePath,
  Folder2Path,
  driveTrackingPath,
  createWhatsNewPath,
  editWhatsNewPath,
  createStoriesPath,
  productStudentPath,
  cvReviewPath,
  ibhSpacePath,
  createIbhWebinarPath,
  editIbhWebinarPath,
  // CRUDwebinarPath,
} from './RoutePaths';

import Student from './Student';
import Student_data from './StudentData';
import StudentHome from './StudentHome';
import TableComponent from './TableComponent/Index';
import AppTestLanding from './Test/AppTestLanding';
import CreateTest from './Test/Pages/CreateTest';
import EditTest from './Test/Pages/EditTest';
import TestimonialDashboard from './Testimonials/TestimonialDashboard';
import University from './University';
import Video from './Video/Video';
import CreatePost from './Wall/Pages/CreatePost';
import EditPost from './Wall/Pages/EditPost';
import WallLanding from './Wall/WallLanding';
import CreateSuccessStory from './Wall/Pages/CreateSuccessStory';
import ThirdYearWallLanding from './ThirdYearWall/ThirdYearWallLanding';
import ThirdYearWebinar from './ThirdYearWall/Pages/Webinar';
import Webinar from './Webinar/Webinar';
import ClsaTestPath from '../Component/Clsa/CreateTest';
import UserManagement from './UserManagement';
import PlacementDrives from './Wall/PlacementDrives';
import DriveResult from './Wall/Pages/DriveResult';
import ClsaResult from './Clsa/ClsaResult';
import CopyQuestion from '../Lms/Component/Test/CopyQuestion/Index';
import Passage from '../Lms/Component/Passage/Index';
import ViewStudyPlan from '../Lms/Component/StudyPlans/ViewStudyPlan';
import LmsConcept from '../Lms/Component/Concept/Index';
import GradSchoolHome from './GradSchool/GradSchoolHome';
import AddGradSchool from './GradSchool/AddGradSchool';
import AddGradSchoolProgramIndex from './GradSchool/AddGradSchoolProgramIndex';
import AddGradSchoolDetails from './GradSchool/GradSchoolDetails';
// import FeedbackLanding from "../Lms/Component/FeedbackRating/Index";
import { Feedback } from '@material-ui/icons';
// import { FeedbackRating } from "../Lms/Component/FeedbackRating/Index";
import FeedBackRatings from '../Lms/Component/FeedBackRatings/Index';
import FeedbackOverview from '../Lms/Component/FeedbackOverview/Index';
import BSchool from './B-School/BSchool';
import AddBSchool from './B-School/AddBSchool';
import BSRankIndex from './B-School/BSRankIndex';
import BSRank from './B-School/BSRank';
import BSProgramIndex from './B-School/BSProgramIndex';
import BSRoundIndex from './B-School/BSRoundIndex';
import BSEssayQuesIndex from './B-School/BSEssayQuesIndex';
import BSEasyQuestion from './B-School/BSEasyQuestion';
import BSProgram from './B-School/BSProgram';
import BSAptitudeTest from './B-School/BSAptitudeTest';
import BSRound from './B-School/BSRound';
import AeResult from '../Lms/Component/Test/ClsaAeResult/AeResult';
import CRUD from './CRUD/Index';
import CRUDWebinar from './CRUD/Webinar';
import FolderCorner from './FilesCorner/Index';
import FilesPage from './FilesCorner/Files';
import Folder from './FilesCorner/Folder';
// import FeedBackRatings from "../Lms/Component/FeedBackRatings/Index";
// import FeedbackOverview from "../Lms/Component/FeedbackOverview/Index";
import ExamSpaceLanding from './ExamSpace/ExamSpaceLanding';
import UniversityPage from './ExamSpace/Pages/UniversityPage';
import SemesterPage from './ExamSpace/Pages/SemesterPage';
import SubjectPage from './ExamSpace/Pages/SubjectPage';
import ChapterPage from './ExamSpace/Pages/ChapterPage';
import Dashboard from './Wall/Dashboard';
import DriveTracking from './Wall/Pages/DriveTracking';
import CreateWhatsNew from './Wall/Pages/CreateWhatsNew';
import FolderSecond from './FilesCorner/FolderSecond';
import FilesSecond from './FilesCorner/FileSecond';
import CreateStories from './Wall/Pages/CreateStories';
import StudentLanding from './StudentLanding';
import ProfileMentoring from './ProfileMentoring';
import IbhLanding from './Ibh/IbhLanding';
import CreateIbhWebinar from './Ibh/Pages/CreateIbhWebinar';
// import PersonaInfo from './Utils/DoccumentCard'

export default function Routes(props) {
  return (
    <Switch>
      {/* <Route restricted={false} exact path="/" component={Login} /> */}
      <Route exact path={studentPath} render={(props) => <StudentHome {...props} />} />
      <Route exact path={coursePath} render={(props) => <Courses {...props} />} />
      <Route exact path={studentPath} render={(props) => <StudentHome {...props} />} />
      <Route exact path={coursePath} render={(props) => <Courses {...props} />} />
      <Route
        exact
        path={editCoursePath.concat(':id')}
        // path={studentPath}
        render={(props) => <StudentHome {...props} />}
      />
      <Route exact path={coursePath} render={(props) => <Courses {...props} />} />
      <Route exact path={studentPath} render={(props) => <StudentHome {...props} />} />
      <Route exact path={coursePath} render={(props) => <Courses {...props} />} />
      <Route
        exact
        path={editCoursePath.concat(':id')}
        render={(props) => <TabPanel {...props} />}
      />
      <Route exact path={dashboard} render={(props) => <Dashboard {...props} />} />
      <Route exact path={addCoursePath} render={(props) => <TabPanel {...props} />} />
      <Route exact path={addCoursePath} render={(props) => <TabPanel {...props} />} />
      <Route exact path={addCoursePath} render={(props) => <TabPanel {...props} />} />
      <Route exact path={collegePath} render={(props) => <College {...props} />} />
      <Route exact path={degreePath} render={(props) => <Degree {...props} />} />
      <Route exact path={departmentPath} render={(props) => <Department {...props} />} />
      <Route exact path={intakePath} render={(props) => <InTake {...props} />} />
      <Route exact path={gradSchool} render={(props) => <GradSchoolHome {...props} />} />
      <Route exact path={addGradSchool} render={(props) => <AddGradSchool {...props} />} />
      <Route exact path={universityPath} render={(props) => <University {...props} />} />
      <Route exact path={addRound} render={(props) => <BSRound {...props} />} />
      <Route exact path={editRound} render={(props) => <BSRound {...props} />} />
      <Route exact path={bsRound} render={(props) => <BSRoundIndex {...props} />} />
      <Route exact path={studentIdPath + '/:id'} render={(props) => <Student_data {...props} />} />
      <Route exact path={loginPath} render={(props) => <MLogin {...props} />} />
      <Route exact path={addBSProgram} render={(props) => <BSProgram {...props} />} />
      <Route exact path={bsEssayQuestion} render={(props) => <BSEssayQuesIndex {...props} />} />
      <Route exact path={wallPath} render={(props) => <WallLanding {...props} />} />
      <Route exact path={thirdYear} render={(props) => <ThirdYearWallLanding {...props} />} />
      <Route exact path={placementDrives} render={(props) => <PlacementDrives {...props} />} />
      <Route exact path={bSchool} render={(props) => <BSchool {...props} />} />
      <Route exact path={addBSchool} render={(props) => <AddBSchool {...props} />} />
      <Route
        exact
        path={addGradSchoolProgram}
        render={(props) => <AddGradSchoolProgramIndex {...props} />}
      />
      <Route
        exact
        path={viewGradSchoolProgram}
        render={(props) => <AddGradSchoolDetails {...props} />}
      />
      <Route exact path={rankView} render={(props) => <BSRankIndex {...props} />} />
      <Route exact path={bsProgram} render={(props) => <BSProgramIndex {...props} />} />
      <Route exact path={gradSchool} render={(props) => <GradSchoolHome {...props} />} />
      <Route exact path={addGradSchool} render={(props) => <AddGradSchool {...props} />} />
      <Route exact path={bsRank} render={(props) => <BSRank {...props} />} />
      <Route exact path={bsRound} render={(props) => <BSRoundIndex {...props} />} />
      <Route exact path={addBSProgram} render={(props) => <BSProgram {...props} />} />
      <Route exact path={bsEssayQuestion} render={(props) => <BSEssayQuesIndex {...props} />} />
      <Route exact path={addBSEssayQuestion} render={(props) => <BSEasyQuestion {...props} />} />
      <Route exact path={bsAptitudeTest} render={(props) => <BSAptitudeTest {...props} />} />
      <Route exact path={addRound} render={(props) => <BSRound {...props} />} />
      <Route exact path={editRound} render={(props) => <BSRound {...props} />} />
      <Route exact path={createPath} render={(props) => <CreatePost {...props} />} />
      <Route exact path={editPath + ':id'} render={(props) => <EditPost {...props} />} />
      <Route exact path={createSuccessPath} render={(props) => <CreateSuccessStory {...props} />} />
      <Route
        exact
        path={`${editSuccessPath}/:id`}
        render={(props) => <CreateSuccessStory {...props} />}
      />
      <Route exact path={createWhatsNewPath} render={(props) => <CreateWhatsNew {...props} />} />
      <Route
        exact
        path={`${editWhatsNewPath}/:id`}
        render={(props) => <CreateWhatsNew {...props} />}
      />
      <Route exact path={createStoriesPath} render={(props) => <CreateStories {...props} />} />
      <Route exact path={createWebinarPath} render={(props) => <ThirdYearWebinar {...props} />} />
      <Route
        exact
        path={`${editWebinarPath}/:id`}
        render={(props) => <ThirdYearWebinar {...props} />}
      />
      {/* Exam Space Paths */}
      <Route exact path={examSpacePath} render={(props) => <ExamSpaceLanding {...props} />} />
      <Route
        exact
        path={examSpaceUniversityPath}
        render={(props) => <UniversityPage {...props} />}
      />
      <Route exact path={semesterPath} render={(props) => <SemesterPage {...props} />} />
      <Route exact path={subjectPath} render={(props) => <SubjectPage {...props} />} />
      <Route exact path={chapterPath} render={(props) => <ChapterPage {...props} />} />
      {/* Exam Space Paths */}
      {/* IBH Paths */}
      <Route exact path={ibhSpacePath} render={(props) => <IbhLanding {...props} />} />
      <Route
        exact
        path={createIbhWebinarPath}
        render={(props) => <CreateIbhWebinar {...props} />}
      />
      <Route
        exact
        path={`${editIbhWebinarPath}/:id`}
        render={(props) => <CreateIbhWebinar {...props} />}
      />
      {/* IBH Paths */}
      <Route exact path={premiumUsersPath} render={(props) => <PremiumUsersLanding {...props} />} />
      <Route
        exact
        path={addGradSchoolProgram}
        render={(props) => <AddGradSchoolProgramIndex {...props} />}
      />
      <Route
        exact
        path={viewGradSchoolProgram}
        render={(props) => <AddGradSchoolDetails {...props} />}
      />
      <Route exact path={appVersion} render={(props) => <AppVersionChange {...props} />} />
      <Route exact path={testPath} render={(props) => <AppTestLanding {...props} />} />
      <Route exact path={testCreate} render={(props) => <CreateTest {...props} />} />
      <Route exact path={testEdit} render={(props) => <EditTest {...props} />} />
      <Route
        exact
        path={personelInfoPath}
        render={(props) => <Personal_information {...props} />}
      />
      <Route exact path={curatedPath} render={(props) => <Curated_Course {...props} />} />
      <Route exact path={questionBankPath} render={(props) => <QuestionBank {...props} />} />
      <Route exact path={FolderPath} render={(props) => <FolderCorner {...props} />} />
      <Route exact path={Folder2Path} render={(props) => <Folder {...props} />} />
      <Route exact path={FilePath} render={(props) => <FilesPage {...props} />} />
      <Route exact path={FolderSecondPagePath} render={(props) => <FolderSecond {...props} />} />
      <Route exact path={FileSecondPagePath} render={(props) => <FilesSecond {...props} />} />
      <Route exact path={CRUDPath} render={(props) => <CRUD {...props} />} />
      <Route exact path={CRUDwebinarPath} render={(props) => <CRUDWebinar {...props} />} />
      <Route exact path={cvReviewPath} render={(props) => <ProfileMentoring {...props} />} />
      <Route exact path={'/admin/table'} render={(props) => <TableComponent {...props} />} />
      <Route exact path={callSchedulePath} render={(props) => <Callschedule {...props} />} />
      {/* Selva */}
      <Route exact path={cityPath} render={(props) => <City {...props} />} />
      <Route exact path={aspirationPath} render={(props) => <AspirationTab {...props} />} />
      <Route exact path={questionSetPath} render={(props) => <QuestionSet {...props} />} />
      <Route exact path={questionsPath.concat(':id')} render={(props) => <Question {...props} />} />
      <Route exact path={choicePath.concat(':id')} render={(props) => <Choice {...props} />} />
      <Route exact path={videoPath} render={(props) => <Video {...props} />} />
      <Route exact path={productPath} render={(props) => <Product {...props} />} />
      <Route
        exact
        path={testimonialsPath}
        render={(props) => <TestimonialDashboard {...props} />}
      />
      <Route exact path={notificationPath} render={(props) => <Notification {...props} />} />
      <Route exact path={reportsPath} render={(props) => <ReportHome {...props} />} />
      <Route
        exact
        path={reportContentPath}
        render={(props) => <ReportRootContainer {...props} />}
      />
      <Route exact path={webinarPath} render={(props) => <Webinar {...props} />} />
      <Route exact path={careerTrackPath} render={(props) => <CareerTrack {...props} />} />
      <Route exact path={productBasedPath} render={(props) => <PersonaInfo {...props} />} />
      <Route exact path={starterPackPath} render={(props) => <StarterPack {...props} />} />
      <Route
        exact
        path={productVariantPath}
        render={(props) => <ProductVariantRoot {...props} />}
      />
      <Route
        exact
        path={productVariantPath + '/:id'}
        render={(props) => <ProductVariantRoot {...props} />}
      />
      <Route exact path={productPunchingPath} render={(props) => <Student {...props} />} />
      <Route exact path={productStudentPath} render={(props) => <StudentLanding {...props} />} />
      <Route exact path={productcomboPath} render={(props) => <ProductComboForm {...props} />} />
      <Route exact path={productPunchingPath} render={(props) => <Student {...props} />} />
      <Route exact path={productcomboPath} render={(props) => <ProductComboForm {...props} />} />
      <Route
        exact
        path={productuserPunchingPath.concat(':id')}
        render={(props) => <ProductPunchingLanding {...props} />}
      />
      <Route
        exact
        path={productstructurePath.concat(':id')}
        render={(props) => <ProductStages {...props} />}
      />
      <Route exact path={clientDetailsPath} render={(props) => <ClientDetails {...props} />} />
      <Route exact path={clientDetailsPath} render={(props) => <ClientDetails {...props} />} />
      <Route
        exact
        path={callSummaryLayoutPath.concat(':studentId/product/:productId')}
        render={(props) => <CallSummaryLayout {...props} />}
      />
      <Route exact path={obOperationPath} render={(props) => <ObOperationLanding {...props} />} />
      <Route exact path={obOperationPath} render={(props) => <ObOperationLanding {...props} />} />
      <Route
        exact
        path={obOperationPath + '/:productId'}
        render={(props) => <ObOperationLanding {...props} />}
      />
      <Route
        exact
        path={listUsersProdBasedPath}
        render={(props) => <ProductBasedUsers {...props} />}
      />
      <Route
        exact
        path={listUsersProdBasedPath + '/:productId'}
        render={(props) => <ProductBasedUsers {...props} />}
      />
      <Route
        exact
        path={stagedTabsPath.concat(':studentId' + '/:productId')}
        render={(props) => <StageBasedLayout {...props} />}
      />
      <Route
        exact
        path={productActivationPath}
        render={(props) => <ProductActivation {...props} />}
      />
      <Route
        exact
        path={productActivationPath + '/:productId'}
        render={(props) => <ProductActivation {...props} />}
      />
      <Route exact path={landingAdminPath} render={(props) => <LandingAdmin {...props} />} />
      <Route
        exact
        // path={`${careerTrackPath}/:id${careerTrackVideoSetPath}`}
        path={careerTrackVideoSetPath.concat(':id')}
        render={(props) => <CareerTrackVideoSet {...props} />}
      />
      <Route
        exact
        // path={`${careerTrackPath}${careerTrackVideoSetPath}/:id${careerTrackVideoPath}`}
        path={careerTrackVideoPath.concat(':id')}
        render={(props) => <CareerTrackVideo {...props} />}
      />
      <Route
        exact
        // path={`${careerTrackPath}${careerTrackVideoSetPath}/:id${careerTrackVideoPath}`}
        path={lms_add_study_plan}
        render={(props) => <AddStudyPlans {...props} />}
      />
      <Route exact path={lms_view_study_plan} render={(props) => <ViewStudyPlan {...props} />} />
      <Route exact path={clsaPath} render={(props) => <ClsaLanding {...props} />} />
      <Route exact path={clsaTestCreate} render={(props) => <ClsaTestPath {...props} />} />
      <Route exact path={clsaTestEdit + ':id'} render={(props) => <ClsaTestPath {...props} />} />
      <Route exact path={userManagementPath} component={UserManagement} />
      {/* LMS */}
      <Route exact path={lms_course_landing} component={CourseLanding} />
      <Route exact path={lms_feedback} component={FeedBackRatings}></Route>
      <Route exact path={lms_feedback_overview} component={FeedbackOverview} />
      <Route exact path={lms_add_topic} component={AddNewTopic} />
      <Route exact path={lms_study_plans} component={StudyPlans} />
      <Route exact path={lmsTest} component={TestLanding} />
      <Route exact path={lms_course_taken} component={CourseTaken} />
      <Route exact path={lms_add_test} component={AddTest} />
      <Route
        exact
        path={`${lms_copy_question}/:testQuestionSetId/:sectionId?`}
        component={CopyQuestion}
      />
      <Route exact path={lms_passage} component={Passage} />
      <Route
        exact
        path={bulk_upload + '/:testQuestionSetId/:sectionId?/:courseId?'}
        component={BulkUpload}
      />
      <Route export exact path={single_upload} component={SingleUpload} />
      <Route export exact path={lms_concept} component={LmsConcept} />
      <Route exact path={lms_result + ':id'} render={(props) => <AeResult {...props} />} />
      <Route exact path={clsaPath} render={(props) => <ClsaLanding {...props} />} />
      <Route exact path={clsaTestCreate} render={(props) => <ClsaTestPath {...props} />} />
      <Route exact path={clsaTestEdit + ':id'} render={(props) => <ClsaTestPath {...props} />} />
      <Route exact path={drivePath + ':id'} render={(props) => <DriveResult {...props} />} />
      <Route exact path={clsaResultPath + ':id'} render={(props) => <ClsaResult {...props} />} />
      <Route exact path={driveTrackingPath} render={(props) => <DriveTracking {...props} />} />
    </Switch>
  );
}
