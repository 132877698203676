import axios from 'axios';
import { catchError, errorHandler } from '../Component/Utils/Helpers';
import { CLSA } from '../Redux/Action';
import { URL } from './URL';

// Clearing all the data in the reducer while left from that page like ComponentWillUnmount.
export const clearData = () => (dispatch) => {
  dispatch({ type: CLSA.CLEAR_DATA });
};

export const createTest = async (data) => {
  let accessToken = window.sessionStorage.getItem('accessToken');

  try {
    const response = await axios.post(URL + '/api/v1/clsatest', data, {
      headers: {
        admin: 'yes',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (error) {
    return error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};

export const clsaData = async (id) => {
  let accessToken = window.sessionStorage.getItem('accessToken');

  try {
    const response = await axios.get(URL + '/api/v1/clsatest/' + id, {
      headers: {
        admin: 'yes',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (error) {
    return error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};

// CLSA Test - Job category dropdown action
export const getClsaJobList = () => {
  let accessToken = window.sessionStorage.getItem('accessToken');

  return async (dispatch) => {
    try {
      await axios
        .get(`${URL}/api/v1/clsatest/jobcategory`, {
          headers: {
            admin: 'yes',
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          dispatch({
            type: CLSA.getClsaJobCategory,
            payload: response.data,
          });
        });
    } catch (error) {
      dispatch(errorHandler(CLSA.getClsaJobCategory, error, false));
    }
  };
};

// CLSA Result page details
export const getClsaResultDetails = (id, page, size, searchValue, requestBody, callback) => {
  let accessToken = window.sessionStorage.getItem('accessToken');

  return async (dispatch) => {
    try {
      // dispatch({ type: CLSA.loader });
      await axios
        .post(`${URL}/api/v1/clsatest/${id}/result`, requestBody, {
          headers: {
            admin: 'yes',
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            page: page,
            size: size,
            search: searchValue,
          },
        })
        .then((response) => {
          callback(response?.data);
          // dispatch({
          //   type: CLSA.getClsaResultDetails,
          //   payload: response.data,
          //   loading: true,
          // });
        });
    } catch (error) {
      callback(catchError(error));
    }
  };
};

// CLSA Video Band dropdown
export const getVidBanding = (id) => {
  let accessToken = window.sessionStorage.getItem('accessToken');

  return async (dispatch) => {
    try {
      await axios
        .get(`${URL}/api/v1/clsatest/${id}/videobandrating`, {
          headers: {
            admin: 'yes',
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          dispatch({
            type: CLSA.getVidBands,
            payload: response.data,
          });
        });
    } catch (error) {
      dispatch(errorHandler(CLSA.getVidBands, error, false));
    }
  };
};

// CLSA Update result (Thumbs Up) Put call
export const updateResult = (id, payload) => {
  let accessToken = window.sessionStorage.getItem('accessToken');

  return async (dispatch) => {
    try {
      dispatch({ type: CLSA.loader });
      await axios
        .put(`${URL}/api/v1/clsatest/${id}/videobandrating`, payload, {
          headers: {
            admin: 'yes',
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          dispatch({
            type: CLSA.updateClsaResult,
            payload: response.data,
          });
        });
    } catch (error) {
      dispatch(errorHandler(CLSA.updateClsaResult, error, false));
    }
  };
};

export const clsaTestDelete = async (id) => {
  let accessToken = window.sessionStorage.getItem('accessToken');

  try {
    const response = await axios.delete(URL + '/api/v1/clsatest/' + id, {
      headers: {
        admin: 'yes',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (error) {
    return error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};

export const clsaQuestionSetUpload = async (id, data) => {
  let accessToken = window.sessionStorage.getItem('accessToken');

  try {
    const response = await axios.post(
      URL + '/api/v1/clsatest/' + id + '/testquestionset/import?type=SINGLE_SELECT',
      data,
      {
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};

export const clsaTestList = async (page, search = '') => {
  let accessToken = window.sessionStorage.getItem('accessToken');

  try {
    const response = await axios.get(
      URL + '/api/v1/clsatest/viewall?page=' + page + '&size=10&search=' + search,
      {
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};

export const updateClsaData = async (id, data) => {
  let accessToken = window.sessionStorage.getItem('accessToken');

  try {
    const response = await axios.put(URL + '/api/v1/clsatest/' + id, data, {
      headers: {
        admin: 'yes',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (error) {
    return error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};

export const clsaQuestionsetDelete = async (id, questionsetId) => {
  let accessToken = window.sessionStorage.getItem('accessToken');

  try {
    const response = await axios.delete(
      URL + '/api/v1/clsatest/' + id + '/testquestionset/' + questionsetId,
      {
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};

export const clsaQuestionsetList = async (page, testID) => {
  let accessToken = window.sessionStorage.getItem('accessToken');

  try {
    const response = await axios.get(
      URL + '/api/v1/clsatest/' + testID + '/testquestionset/viewall?page=' + page + '&size=5',
      {
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};

export const setFieldValue = (fieldName, fieldValue) => {
  return (dispatch) => {
    dispatch({
      type: CLSA.setFieldValue,
      fieldName: fieldName,
      fieldValue: fieldValue,
    });
  };
};

// CLSA Master Sheet download - result page
export const clsaSheetDownload = (id, data, callback) => {
  let accessToken = window.sessionStorage.getItem('accessToken');
  return () => {
    axios
      .post(URL + `/api/v1/students/clsa/${id}/report/master`, data, {
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'blob',
      })
      .then((result) => {
        callback(result);
      })
      .catch((error) => {
        callback(catchError(error));
      });
  };
};

// CLSA Master Sheet download - home page
export const clsaMasterSheetDownload = (id, callback) => {
  let accessToken = window.sessionStorage.getItem('accessToken');

  return async (dispatch) => {
    try {
      await axios
        .get(URL + `/api/v1/students/clsa/${id}/report`, {
          headers: {
            admin: 'yes',
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          callback(response);
          dispatch({
            type: CLSA.clsaMasterSheet,
            payload: response.data,
          });
        });
    } catch (error) {
      callback(catchError(error));
    }
  };
};
