import {
  InputAdornment,
  makeStyles,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MuiAlert from '@material-ui/lab/Alert';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearData, deleteWebinarById, getAllWebinarList } from '../../../Actions/IbhAction';
import { createIbhWebinarPath, editIbhWebinarPath } from '../../RoutePaths';
import ConfirmDialog from '../../Utils/ConfirmDialog';
import Controls from '../../Utils/controls/Controls';
import Loader from '../../Utils/controls/Loader';
import Notification from '../../Utils/Notification';
import useTable from '../../Utils/useTable';

const ACTIVE_STATUS = ['Live', 'Scheduled', 'Expired'];

const Alert = (props) => <MuiAlert elevation={6} variant='filled' {...props} />;

const useStyles = makeStyles((theme) => ({
  pageContent: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
  },
  searchInput: {
    width: '65%',
  },
  filterBtn: {
    position: 'absolute',
    right: '250px',
    borderRadius: '26px',
  },
  newButton: {
    position: 'absolute',
    right: '20px',
    borderRadius: '26px',
  },
  actions: {
    width: '140px',
  },
}));

const headCells = [
  { id: 'webinarTitle', label: 'Title' },
  { id: 'published', label: 'Published' },
  { id: 'roundName', label: 'Round Name', disableSorting: true },
  { id: 'status', label: 'Status', disableSorting: true },
  { id: 'startDate', label: 'Start date' },
  { id: 'endDate', label: 'End date' },
  { id: 'actions', label: 'Actions', disableSorting: true },
];

export default function IbhWebinar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading, allWebinarList, deleteStatus } = useSelector((state) => state.ibhReducer);

  let totalPages = allWebinarList?.data?.totalPages;

  let filteredWebinars =
    allWebinarList?.data?.content?.filter((webinar) =>
      ACTIVE_STATUS.includes(webinar.activeStatus)
    ) || [];

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting, page } = useTable(
    filteredWebinars,
    headCells,
    filterFn,
    totalPages
  );

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value == '') return items;
        else
          return items.filter((x) =>
            x.webinarTitle.toLowerCase().includes(target.value.toLowerCase())
          );
      },
    });
  };

  const openInPage = (item) => {
    history.push({
      pathname: `${editIbhWebinarPath}/${item.webinarId}`,
      postType: 'Webinar',
      postTypeTab: 0,
    });
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    dispatch(deleteWebinarById(id));
  };

  useEffect(() => {
    // Clearing all the data in the reducer while left from that page like ComponentWillUnmount.
    return () => {
      dispatch(clearData());
    };
  }, []);

  useEffect(() => {
    dispatch(getAllWebinarList(page));
  }, [dispatch, page]);

  useEffect(() => {
    if (deleteStatus) {
      if (deleteStatus.success) {
        setNotify({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'success',
        });
        setTimeout(() => {
          dispatch(getAllWebinarList(page));
        }, 800);
      } else {
        setNotify({
          isOpen: true,
          message: deleteStatus.message,
          type: 'error',
        });
      }
    }
  }, [deleteStatus]);

  const handleDeleteClick = (item) => {
    setConfirmDialog({
      isOpen: true,
      title: 'DELETE IBH WEBINAR?',
      subTitle: `Are you sure you want to delete the selected Webinar?
        It will go longer will be visible on the App.`,
      onConfirm: () => {
        onDelete(item.webinarId);
      },
    });
  };

  return (
    <>
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.RoundedInput
            className={classes.searchInput}
            placeholder='Search IBH Webinars by title'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />

          <Controls.Button
            text='Create New Webinar'
            variant='contained'
            color='primary'
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={() => {
              history.push({
                pathname: createIbhWebinarPath,
                type: false,
                postType: 'Webinar',
                postTypeTab: 0,
              });
            }}
          />
        </Toolbar>

        <TblContainer>
          <TblHead />
          {filteredWebinars && (
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => {
                return (
                  <TableRow key={item.webinarId}>
                    <TableCell style={{ width: '180px' }}>{`${item.webinarTitle}`}</TableCell>
                    <TableCell>{moment(item.published).fromNow()}</TableCell>
                    <TableCell style={{ width: '130px' }}>{`${item?.ibhRound?.name}`}</TableCell>
                    <TableCell>{item.activeStatus}</TableCell>
                    <TableCell>{moment(item.startDate).format('MMM Do, hh:mm a')}</TableCell>
                    <TableCell>{moment(item.endDate).format('MMM Do, hh:mm a')}</TableCell>
                    <TableCell className={classes.actions}>
                      <Controls.ActionButton onClick={() => openInPage(item)}>
                        <EditOutlinedIcon fontSize='small' color='primary' />
                      </Controls.ActionButton>
                      <Controls.ActionButton onClick={() => handleDeleteClick(item)}>
                        <DeleteIcon fontSize='small' color='secondary' />
                      </Controls.ActionButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </TblContainer>
        <div style={{ margin: '2rem auto', width: '60%' }}>
          {loading && <Loader />}
          {!loading && filteredWebinars?.length === 0 && (
            <Alert severity='info'>0 Webinars Found</Alert>
          )}
        </div>
        <TblPagination />
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
}
