import { Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { GSContainer, useStyles } from "../../Asset/StyledComponents/Styles";
import Button from "@material-ui/core/Button";
import {
  clearData,
  createGradSchoolGmatList,
  getGradSchoolGmatList,
} from "../../Actions/GradSchoolAction";
import { useDispatch, useSelector } from "react-redux";
import MySnackBar from "../MySnackBar";
export default function GradSchoolGmat(props) {
  const { getGradSchoolGmatReducer } = useSelector(
    (state) => state.GradSchoolReducer
  );
  const dispatch = useDispatch();
  const classes = useStyles();
  const [gradSclGmatVariant, setGradSclGmatVariant] = useState({
    gradSchoolProgramId: "",
    gmatTotal: null,
    gmatV: null,
    gmatQ: null,
    id: "",
    gmatAwa: null,
  });
  const [state, setState] = useState({
    open: false,
    message: "",
    color: "",
  });
  const coursesFields = [
    {
      name: "gmatTotal",
      value: gradSclGmatVariant.gmatTotal,
      label: "GMAT Total",
      type: "number",
    },
    {
      name: "gmatV",
      value: gradSclGmatVariant.gmatV,
      label: "GMAT V",
      type: "number",
    },
    {
      name: "gmatQ",
      value: gradSclGmatVariant.gmatQ,
      label: "GMAT Q",
      type: "number",
    },
    {
      name: "gmatAwa",
      value: gradSclGmatVariant.gmatAwa,
      label: "GMAT AWA",
      type: "number",
    },
  ];
  const onChange = (e) => {
    const { name, value } = e.target;
    setGradSclGmatVariant({
      ...gradSclGmatVariant,
      [name]: value,
    });
  };

  const handleSave = () => {
    if (
      gradSclGmatVariant.gmatTotal ||
      gradSclGmatVariant.gmatQ ||
      gradSclGmatVariant.gmatV ||
      gradSclGmatVariant.gmatAwa
    ) {
      if (gradSclGmatVariant.id.length > 0) {
        let obj = {
          id: gradSclGmatVariant.id,
          gmatTotal:
            Number(gradSclGmatVariant.gmatTotal) === 0
              ? null
              : Number(gradSclGmatVariant.gmatTotal),
          gmatQ:
            Number(gradSclGmatVariant.gmatQ) === 0
              ? null
              : Number(gradSclGmatVariant.gmatQ),
          gmatV:
            Number(gradSclGmatVariant.gmatV) === 0
              ? null
              : Number(gradSclGmatVariant.gmatV),
          gmatAwa:
            Number(gradSclGmatVariant.gmatAwa) === 0
              ? null
              : Number(gradSclGmatVariant.gmatAwa),
        };
        dispatch(
          createGradSchoolGmatList(obj, (res) => {
            if (res.success) {
              setState({
                open: true,
                message: "Updated Successfully",
                color: "success",
              });
              dispatch(getGradSchoolGmatList(props.location.state.id));
            }
          })
        );
      } else {
        let obj = {
          gradSchoolProgramId:
            props.gradSchoolProgramCreatedId || props.location.state.id,
          gmatTotal:
            Number(gradSclGmatVariant.gmatTotal) === 0
              ? null
              : Number(gradSclGmatVariant.gmatTotal),
          gmatV:
            Number(gradSclGmatVariant.gmatV) === 0
              ? null
              : Number(gradSclGmatVariant.gmatV),
          gmatQ:
            Number(gradSclGmatVariant.gmatQ) === 0
              ? null
              : Number(gradSclGmatVariant.gmatQ),
          gmatAwa:
            Number(gradSclGmatVariant.gmatAwa) === 0
              ? null
              : Number(gradSclGmatVariant.gmatAwa),
        };
        dispatch(
          createGradSchoolGmatList(obj, (res) => {
            if (res.success) {
              setState({
                open: true,
                message: "Saved Successfully",
                color: "success",
              });
              dispatch(clearData());
            }
          })
        );
      }
    }
  };

  useEffect(() => {
    if (getGradSchoolGmatReducer !== null) {
      if (getGradSchoolGmatReducer.success) {
        const {
          gmatAwa,
          gmatQ,
          gmatV,
          gmatTotal,
          id,
        } = getGradSchoolGmatReducer.data;
        setGradSclGmatVariant({
          ...gradSclGmatVariant,
          gmatTotal: gmatTotal,
          gmatV: gmatV,
          gmatQ: gmatQ,
          id: id,
          gmatAwa: gmatAwa,
        });
      }
    }
  }, [getGradSchoolGmatReducer]);

  useEffect(() => {
    if (props.location.state.id) {
      dispatch(getGradSchoolGmatList(props.location.state.id));
    }
  }, []);

  return (
    <React.Fragment>
      <GSContainer>
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems="center"
          spacing={4}
        >
          <Grid item md={10}>
            <Grid container spacing={6}>
              {coursesFields.map((item) => {
                return (
                  <Grid item md={6}>
                    <TextField
                      value={item.value}
                      onChange={onChange}
                      name={item.name}
                      label={item.label}
                      fullWidth
                      type={item.type}
                      InputLabelProps={{
                        shrink: item.value === null ? false : true,
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item md={2}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              className={classes.btnStyles}
              onClick={handleSave}
              disabled={
                props.location.state.id
                  ? false
                  : props.gradSchoolProgramCreatedId
                  ? false
                  : true
              }
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </GSContainer>
      <MySnackBar
        snackOpen={state.open}
        snackVariant={state.color}
        snackMsg={state.message}
        onClose={() =>
          setState({
            open: false,
            message: "",
            color: "",
          })
        }
      />
    </React.Fragment>
  );
}
