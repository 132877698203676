import React, { useEffect, useRef } from "react";

const MathElement = ({ math }) => {
  const ref = useRef();
  const element = math || "";

  useEffect(() => {
    renderMath();
  }, [element]);

  const renderMath = () => {
    if (window.MathJax)
      window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, ref.current]);
  };

  return (
    <div
      ref={ref}
      key={Math.random(0, 20).toString()}
      dangerouslySetInnerHTML={{ __html: element }}
    />
  );
};

export default React.memo(MathElement);
