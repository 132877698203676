import React, { useState, useEffect } from 'react';
// import TestimonialForm from './TestimonialForm';
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  Typography
} from '@material-ui/core';
import useTable from '../Utils/useTable';
import Controls from '../Utils/controls/Controls';
import { Search } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import Popup from '../Utils/Popup';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CloseIcon from '@material-ui/icons/Close';
import Notification from '../Utils/Notification';
import Loader from '../Utils/controls/Loader';
import MuiAlert from '@material-ui/lab/Alert';
import ConfirmDialog from '../Utils/ConfirmDialog';
import { useSelector, useDispatch } from 'react-redux';
import {
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Grid,
    TextField,
    FormControlLabel,
    Checkbox,
  } from "@material-ui/core";
import {
  listTestimonials,
  deleteTestimonial,
  createTestimonial,
  updateTestimonial,
} from '../../Actions/TestimonialActions';
import {CRUDwebinarPath, FilePath, FileSecondPagePath, studentPath } from "../RoutePaths";
import BackButton from '../../Asset/Images/backbutton.svg';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Breadcrumbs} from '@material-ui/core'
import { Typo } from '../Utils/controls/Styles';
import { Co2Sharp } from '@mui/icons-material';
import { getFilesList, getToolsCall, getWebinarCall } from '../../Actions/CRUDwebinar';
import moment from 'moment';
import { getDocumentList } from '../../Actions/CRUDwebinar';

const Alert = (props) => <MuiAlert elevation={6} variant='filled' {...props} />;

const useStyles = makeStyles((theme) => ({
  pageContent: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(3),
  },
  folderIcon:{
  padding:"3%",
  alignItems:"center",
  alignContent:"center",
  textAlign:"center"
  },
 
}));



export default function FolderSecond(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { documentList,fileList} = useSelector((state) => state.CRUDwebinarReducer);
  let studentId = sessionStorage.getItem("studentId");
let productId = sessionStorage.getItem("productId")
let type = sessionStorage.getItem("type")


 


const filePathRoute = (item) => {
   
    sessionStorage.setItem("type2",item.type);

  dispatch(getFilesList(studentId,productId,type))

    props.history.push(
     FileSecondPagePath
    );
    };
  



  useEffect(() => {
    dispatch(getDocumentList(studentId,productId));
    dispatch(getFilesList(studentId,productId,type))
  }, []);




  return (
     <>
      
      <div style={{display:"flex",flexDirection:"row",margin:"10px"}}>
          <img
            src={BackButton}
            style={{ cursor: "pointer",marginTop:"-10px" }}
            onClick={() => props.history.goBack()}
             />
               <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <Typography style={{ cursor: "pointer", fontWeight: "600",marginLeft:"10px" }} onClick={()=>props.history.push(studentPath)}>
                Home
              </Typography>
              <Typography style={{ cursor: "pointer", fontWeight: "600" }}>
                Manage Client
              </Typography>
              <Typography style={{ cursor: "pointer", fontWeight: "600" }}>
                Files
              </Typography>
            </Breadcrumbs>
            </div>
            <Grid container xs={12}>
           {fileList?.data?.data !== 0  && fileList?.success === true && fileList?.data?.data?.map((item,fileName) => {
   {console.log(item,fileName,item.fileName)}
    return( <Grid item xs={3}>
        <div className={classes.folderIcon}>
      <img
                   src={require("../../Asset/Images/Folder.png")}
                   alt={"Not supported"}
                   style={{ cursor: "pointer"}}
                   onClick={() => filePathRoute(item)}
               
                 />
                 </div>
                 <Typography style={{ cursor: "pointer", fontWeight: "600",color:"grey",textAlign:"center"}}>
                 {item.name? item.name : item.fileName}
                 </Typography>
                 
                 </Grid>)
   
                })}
                    {/* <Grid item xs={3}>
     <div className={classes.folderIcon}>
        {console.log(fileList)}
   <img
                src={require("../../Asset/Images/Folder.png")}
                alt={"Not supported"}
                style={{ cursor: "pointer"}}
                onClick={() => filePathRoute("CV")}
              />
              </div>
              <Typography style={{ cursor: "pointer", fontWeight: "600",color:"grey",textAlign:"center"}}>
               {fileList?.data}
              </Typography>
              
              </Grid>

              <Grid item xs={3}>
     <div className={classes.folderIcon}>
   <img
                src={require("../../Asset/Images/Folder.png")}
                alt={"Not supported"}
                style={{ cursor: "pointer"}}
                onClick={() => filePathRoute("Essay")}
              />
              </div>
              <Typography style={{ cursor: "pointer", fontWeight: "600",color:"grey",textAlign:"center"}}>
              {documentList?.data?.data?.documentNames?.essayName}
              </Typography>
              
              </Grid>

              <Grid item xs={3}>
     <div className={classes.folderIcon}>
   <img
                src={require("../../Asset/Images/Folder.png")}
                alt={"Not supported"}
                style={{ cursor: "pointer"}}
                onClick={() => filePathRoute("GAT")}
              />
              </div>
              <Typography style={{ cursor: "pointer", fontWeight: "600",color:"grey",textAlign:"center"}}>
              {documentList?.data?.data?.documentNames?.gatName }
              </Typography>
              
              </Grid>

              <Grid item xs={3}>
     <div className={classes.folderIcon}>
   <img
                src={require("../../Asset/Images/Folder.png")}
                alt={"Not supported"}
                style={{ cursor: "pointer"}}
                onClick={() => filePathRoute("LOR")}
              />
              </div>
              <Typography style={{ cursor: "pointer", fontWeight: "600",color:"grey",textAlign:"center"}}>
              {documentList?.data?.data?.documentNames?.lorname}
              </Typography>
              
              </Grid>

              <Grid item xs={3}>
     <div className={classes.folderIcon}>
   <img
                src={require("../../Asset/Images/Folder.png")}
                alt={"Not supported"}
                style={{ cursor: "pointer"}}
                onClick={() => filePathRoute("Marksheets")}
              />
              </div>
              <Typography style={{ cursor: "pointer", fontWeight: "600",color:"grey",textAlign:"center"}}>
              {documentList?.data?.data?.documentNames?.marksheetName}
              </Typography>
              
              </Grid>

              <Grid item xs={3}>
     <div className={classes.folderIcon}>
   <img
                src={require("../../Asset/Images/Folder.png")}
                alt={"Not supported"}
                style={{ cursor: "pointer"}}
                onClick={() => filePathRoute("Others")}
              />
              </div>
              <Typography style={{ cursor: "pointer", fontWeight: "600",color:"grey",textAlign:"center"}}>
              {documentList?.data?.data?.documentNames?.others}
              </Typography>
              
              </Grid>  <Grid item xs={3}>
     <div className={classes.folderIcon}>
   <img
                src={require("../../Asset/Images/Folder.png")}
                alt={"Not supported"}
                style={{ cursor: "pointer"}}
                onClick={() => filePathRoute("Reports")}
              />
              </div>
              <Typography style={{ cursor: "pointer", fontWeight: "600",color:"grey",textAlign:"center"}}>
              {documentList?.data?.data?.documentNames?.reportName }
              </Typography>
              
              </Grid> */}

             
              </Grid>
           


              
     </>
  );
}
