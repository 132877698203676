import { CLSA } from "../Redux/Action";

const initialState = {
  createClsaTest: null,
  getClsaDetails: null,
  jobs: null,
  getClsaResult: null,
  getVideoBand: null,
  updateStatus: null,
  loading: false,
};

function ClsaReducer(state = initialState, action) {
  switch (action.type) {
    case CLSA.loader:
      return {
        ...state,
        loading: true,
      };
    case CLSA.createClsaTest:
      return {
        createClsaTest: action.createClsaTest,
      };
    case CLSA.getClsaDetails:
      return {
        getClsaDetails: action.getClsaDetails,
      };
    case CLSA.getClsaJobCategory:
      return {
        jobs: action.payload,
      };
    case CLSA.getClsaResultDetails:
      return {
        ...state,
        getClsaResult: action.payload,
      };
    case CLSA.getVidBands:
      return {
        ...state,
        getVideoBand: action.payload,
      };
    case CLSA.updateClsaResult:
      return {
        ...state,
        updateStatus: action.payload,
      };
    case CLSA.setFieldValue:
      return {
        ...state,
        loading: false,
        [action.fieldName]: action.fieldValue,
      };
    default:
      break;
  }
  return state;
}

export default ClsaReducer;
