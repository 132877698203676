import React, { useEffect, useState, useRef } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ThumbUp from "@material-ui/icons/ThumbUp";
import FilterIcon from "@material-ui/icons/Tune";
import SortIcon from "@material-ui/icons/CompareArrows";
import SearchIcon from "@material-ui/icons/SearchSharp";
import ExportIcon from "@material-ui/icons/GetApp";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import ClearIcon from "@material-ui/icons/Clear";
import { downloadTest } from "../../../Redux/Action/Test";
import { Download } from "@mui/icons-material";
import Loader from "../../../../Component/Utils/controls/Loader";
import {
  FormControl,
  InputAdornment,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Autocomplete, Pagination } from "@material-ui/lab";
import {
  getClsaResultDetails,
  setFieldValue,
  getVidBanding,
  aeSheetDownload,
} from "../../../Redux/Action/Test";
// import {
//   getClsaResultDetails,
//   getVidBanding,
//   setFieldValue,
//   updateResult,
//   aeSheetDownload,
// } from '../../../../../src/Actions/ClsaActions';

import {
  Check,
  ChevronLeft,
  ChevronRight,
  Edit,
  Search,
} from "@material-ui/icons";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import { Add } from "@mui/icons-material";
import Controls from "../../../../Component/Utils/controls/Controls";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Notification from "../../../../Component/Utils/Notification";
import { textToDownloadFile } from "../../../../Component/Utils/Helpers";

const useStyles = makeStyles((theme) => ({
  searchInput: {
    width: "50%",
    marginLeft: 30,
  },
}));

function AeResult() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [sslcValue, setSslcValue] = useState("all");
  const [hscValue, setHscValue] = useState("all");
  const [ugValue, setUgValue] = useState("all");
  const [quantitative, setQuantitative] = useState("all");
  const [quantitativeScore, setQuantitativeScore] = useState("all");
  const [problemSolving, setProblemSolving] = useState("all");
  const [sec1Score, setSec1Score] = useState("all");
  const [sec2Score, setSec2Score] = useState("all");
  const [sec3Score, setSec3Score] = useState("all");
  const [sec4Score, setSec4Score] = useState("all");
  const [sec5Score, setSec5Score] = useState("all");
  const [sec6Score, setSec6Score] = useState("all");

  const [overallScorePer, setOverallScorePer] = useState("all");
  const [codeAnalysis, setCodeAnalysis] = useState("all");
  const [codeAnalysisScore, setCodeAnalysisScore] = useState("all");
  const [aptitude, setAptitude] = useState("all");
  const [aptitudeScore, setAptitudeScore] = useState("all");
  const [overallScore, setOverallScore] = useState("all");
  const [videoBands, setVideoBands] = useState();
  const [tableData, setTableData] = useState([]);
  const [clsaTitle, setClsaTitle] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const [pageSize, setPageSize] = useState(60);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState();
  const [page, setPage] = useState(0);
  const [options, setOptions] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [date, setDate] = useState(null);
  const { getClsaResult, updateStatus, getVideoBand, loading } = useSelector(
    (state) => state.TestReducer
  );

  useEffect(() => {
    dispatch(getClsaResultDetails(id));
    dispatch(getVidBanding());
  }, []);

  useEffect(() => {
    if (getClsaResult) {
      if (getClsaResult.success) {
        setTableData(getClsaResult?.data.clsaResultModels);
        setFilteredData(getClsaResult?.data.clsaResultModels);
        setClsaTitle(getClsaResult?.data.clsaTitle);
        setTotalPages(getClsaResult?.data.totalPages);
        setTotalElements(getClsaResult?.data.totalElements);
        dispatch(setFieldValue("getClsaResult", null));
      } else {
        setTableData([]);
        dispatch(setFieldValue("getClsaResult", null));
      }
    }
  }, [getClsaResult]);

  useEffect(() => {
    if (getVideoBand) {
      if (getVideoBand.success) {
        setOptions(getVideoBand.data);
      } else {
        setOptions([]);
      }
    }
  }, [getVideoBand]);

  useEffect(() => {
    if (updateStatus) {
      if (updateStatus.success) {
        if (loading) dispatch(getClsaResultDetails(id));
        else {
          setNotify({
            isOpen: true,
            message: "Updated Successfully!",
            type: "success",
          });
          dispatch(setFieldValue("updateStatus", null));
        }
      } else {
        setNotify({
          isOpen: true,
          message: updateStatus.message,
          type: "error",
        });
        dispatch(setFieldValue("updateStatus", null));
      }
    }
  }, [updateStatus, loading]);

  const handleSearch = (text) => {
    setSearchValue(text);
    setPage(0);
    dispatch(getClsaResultDetails(id, 0, pageSize, text));
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage - 1);
    dispatch(getClsaResultDetails(id, newPage - 1, pageSize, searchValue));
  };

  // Field names in the columns variable must be same as the KEY of the data variable.
  let columns = [
    {
      title: "ID",
      field: "id",
      hidden: true,
    },
    {
      title: "Date of test taken",
      field: "dateOfTestTaken",
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      type: "date",
      dateSetting: {
        locale: "en-GB",
      },
      filterComponent: () => (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            format="dd/MM/yyyy"
            clearable
            InputAdornmentProps={{ position: "start" }}
            value={date}
            onChange={(event) => {
              setDate(event);
              let filteredData = tableData.filter(
                (student) =>
                  new Date(student.dateOfTestTaken).getDate() ===
                    new Date(event).getDate() || !event
              );
              setFilteredData(filteredData);
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
      ),
    },
    {
      title: "Candidate Name",
      field: "userName",
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Date of Birth",
      field: "dob",
      filtering: false,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      type: "date",
      dateSetting: {
        format: "dd-MM-yyyy",
        locale: "en-GB",
      },
    },
    {
      title: "Gender",
      field: "gender",
      filtering: true,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      lookup: { Male: "Male", Female: "Female", Others: "Others" },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Contact Number",
      field: "phoneNumber",
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Email",
      field: "emailId",
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Fresher or Experienced",
      field: "experienceStatus",
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      lookup: { Fresher: "Fresher", Experienced: "Experienced" },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Graduation Qualification",
      field: "graduationQualification",
      filtering: true,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Do you have any active backlogs?",
      field: "activeBackLog",
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
      filtering: true,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      lookup: { true: "Yes", false: "No" },
    },
    {
      title: "History of backlog",
      field: "historyOfBacklogs",
      filtering: true,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Attempted Status",
      field: "attemptStatus",
      filtering: true,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "10th Passing year",
      field: "sscEndDate",
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      render: (rowData) => (
        <div>{new Date(rowData.sscEndDate).getFullYear()}</div>
      ),
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "12th Passing year",
      field: "hscEndDate",
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      render: (rowData) => (
        <div>{new Date(rowData.hscEndDate).getFullYear()}</div>
      ),
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "10%",
      field: "sscPercentage",
      editable: false,
      cellStyle: {
        textAlign: "center",
      },
      filterComponent: () => (
        <Select
          id="sscPercentage"
          style={{ width: 70 }}
          value={sslcValue}
          onChange={(e) => {
            setSslcValue(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.sscPercentage) >= e.target.value ||
                e.target.value === "all"
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={"all"}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={10}>10%</MenuItem>
          <MenuItem value={20}>20%</MenuItem>
          <MenuItem value={30}>30%</MenuItem>
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={60}>60%</MenuItem>
          <MenuItem value={70}>70%</MenuItem>
          <MenuItem value={80}>80%</MenuItem>
          <MenuItem value={90}>90%</MenuItem>
        </Select>
      ),
      emptyValue: () => <em>--</em>,
      render: (rowData) => (
        <div style={{ width: "70px" }}>{rowData.sscPercentage}</div>
      ),
    },
    {
      title: "12%",
      field: "hscPercentage",
      editable: false,
      cellStyle: {
        textAlign: "center",
      },
      filterComponent: () => (
        <Select
          id="hscPercentage"
          style={{ width: 70 }}
          value={hscValue}
          onChange={(e) => {
            console.log(e.target.value, "pp");
            setHscValue(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.hscPercentage) >= e.target.value ||
                e.target.value === "all"
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={"all"}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={10}>10%</MenuItem>
          <MenuItem value={20}>20%</MenuItem>
          <MenuItem value={30}>30%</MenuItem>
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={60}>60%</MenuItem>
          <MenuItem value={70}>70%</MenuItem>
          <MenuItem value={80}>80%</MenuItem>
          <MenuItem value={90}>90%</MenuItem>
        </Select>
      ),
      emptyValue: () => <em>--</em>,
      render: (rowData) => (
        <div style={{ width: "70px" }}>{rowData.hscPercentage}</div>
      ),
    },
    {
      title: "UG Degree",
      field: "ugDegree",
      filtering: true,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "UG Branch",
      field: "ugBranch",
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "UG College",
      field: "ugCollege",
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
      render: (rowData) => (
        <div style={{ width: "100px" }}>{rowData.ugCollege}</div>
      ),
    },
    {
      title: "UG Year Of Passing",
      field: "ugEndDate",
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      render: (rowData) => (
        <div>{new Date(rowData.ugEndDate).getFullYear()}</div>
      ),
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "UG Percentage",
      field: "ugPercentage",
      editable: false,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
      filterComponent: () => (
        <Select
          id="ugPercentage"
          style={{ width: 90 }}
          value={ugValue}
          onChange={(e) => {
            setUgValue(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.ugPercentage) >= e.target.value ||
                e.target.value === "all"
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={"all"}>
            <em>All</em>
          </MenuItem>

          <MenuItem value={10}>10%</MenuItem>
          <MenuItem value={20}>20%</MenuItem>
          <MenuItem value={30}>30%</MenuItem>
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={60}>60%</MenuItem>
          <MenuItem value={70}>70%</MenuItem>
          <MenuItem value={80}>80%</MenuItem>
          <MenuItem value={90}>90%</MenuItem>
        </Select>
      ),
      emptyValue: () => <em>--</em>,
      render: (rowData) => (
        <div style={{ width: "70px" }}>{rowData.ugPercentage}</div>
      ),
    },
    {
      title: "PG Degree",
      field: "pgDegree",
      filtering: true,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "PG Branch",
      field: "pgBranch",
      filtering: true,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "PG Semester",
      field: "pgSemester",
      filtering: true,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "PG Percentage",
      field: "pgPercentage",
      editable: false,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
      filterComponent: () => (
        <Select
          id="pgPercentage"
          style={{ width: 70 }}
          value={ugValue}
          onChange={(e) => {
            setUgValue(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.pgPercentage) >= e.target.value ||
                e.target.value === "all"
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={"all"}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={10}>10%</MenuItem>
          <MenuItem value={20}>20%</MenuItem>
          <MenuItem value={30}>30%</MenuItem>
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={60}>60%</MenuItem>
          <MenuItem value={70}>70%</MenuItem>
          <MenuItem value={80}>80%</MenuItem>
          <MenuItem value={90}>90%</MenuItem>
        </Select>
      ),
      emptyValue: () => <em>--</em>,
      render: (rowData) => (
        <div style={{ width: "70px" }}>{rowData.pgPercentage}</div>
      ),
    },
    {
      title: "PG College",
      field: "pgCollege",
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
      render: (rowData) => (
        <div style={{ width: "100px" }}>{rowData.pgCollege}</div>
      ),
    },
    {
      title: "PG Year of Passing",
      field: "pgEndDate",
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      render: (rowData) => (
        <div>{new Date(rowData.pgEndDate).getFullYear()}</div>
      ),
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Father's Name",
      field: "fatherName",
      filtering: true,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Mother's Name",
      field: "motherName",
      filtering: true,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Parent's Phone Number",
      field: "parentPhoneNumber",
      filtering: true,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Aadhar/PAN number",
      field: "aadharOrPanNo",
      filtering: true,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Gap in Education or any Year Back?",
      field: "educationalGap",
      filtering: true,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      lookup: { true: "Yes", false: "No" },
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Preferred Location",
      field: "preferredLocation",
      filtering: true,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Section 1 Score",
      field: "sectionOneScore",
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      filterComponent: () => (
        <Select
          id="sectionOneScore"
          style={{ width: 80 }}
          value={sec1Score}
          onChange={(e) => {
            setSec1Score(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.sectionOneScore) >= e.target.value ||
                e.target.value === "all"
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={"all"}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={10}>10%</MenuItem>
          <MenuItem value={20}>20%</MenuItem>
          <MenuItem value={30}>30%</MenuItem>
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={60}>60%</MenuItem>
          <MenuItem value={70}>70%</MenuItem>
          <MenuItem value={80}>80%</MenuItem>
          <MenuItem value={90}>90%</MenuItem>
        </Select>
      ),
      render: (rowData) => (
        <div style={{ width: "70px" }}>{rowData.sectionOneScore}</div>
      ),
    },
    {
      title: "Section 1 Result",
      field: "sectionOneResultStatus",
      filtering: true,
      editable: false,
      sorting: false,
      lookup: {
        Qualified: "Qualified",
        Disqualified: "Disqualified",
        NA: "NA",
      },
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Section 2 Score",
      field: "sectionTwoScore",
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      filterComponent: () => (
        <Select
          id="sectionTwoScore"
          style={{ width: 80 }}
          value={sec2Score}
          onChange={(e) => {
            setSec2Score(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.sectionTwoScore) >= e.target.value ||
                e.target.value === "all"
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={"all"}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={10}>10%</MenuItem>
          <MenuItem value={20}>20%</MenuItem>
          <MenuItem value={30}>30%</MenuItem>
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={60}>60%</MenuItem>
          <MenuItem value={70}>70%</MenuItem>
          <MenuItem value={80}>80%</MenuItem>
          <MenuItem value={90}>90%</MenuItem>
        </Select>
      ),
      render: (rowData) => (
        <div style={{ width: "70px" }}>{rowData.sectionTwoScore}</div>
      ),
    },
    {
      title: "Section 2 Result",
      field: "sectionTwoResultStatus",
      filtering: true,
      editable: false,
      sorting: false,
      lookup: {
        Qualified: "Qualified",
        Disqualified: "Disqualified",
        NA: "NA",
      },
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Section 3 Score",
      field: "sectionThreeScore",
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      filterComponent: () => (
        <Select
          id="sectionThreeScore"
          style={{ width: 80 }}
          value={sec3Score}
          onChange={(e) => {
            setSec3Score(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.sectionThreeScore) >= e.target.value ||
                e.target.value === "all"
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={"all"}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={10}>10%</MenuItem>
          <MenuItem value={20}>20%</MenuItem>
          <MenuItem value={30}>30%</MenuItem>
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={60}>60%</MenuItem>
          <MenuItem value={70}>70%</MenuItem>
          <MenuItem value={80}>80%</MenuItem>
          <MenuItem value={90}>90%</MenuItem>
        </Select>
      ),
      render: (rowData) => (
        <div style={{ width: "70px" }}>{rowData.sectionThreeScore}</div>
      ),
    },
    {
      title: "Section 3 Result",
      field: "sectionThreeResultStatus",
      filtering: true,
      editable: false,
      sorting: false,
      lookup: {
        Qualified: "Qualified",
        Disqualified: "Disqualified",
        NA: "NA",
      },
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Section 4 Score",
      field: "sectionFourScore",
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      filterComponent: () => (
        <Select
          id="sectionFourScore"
          style={{ width: 80 }}
          value={sec4Score}
          onChange={(e) => {
            setSec4Score(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.sectionFourScore) >= e.target.value ||
                e.target.value === "all"
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={"all"}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={10}>10%</MenuItem>
          <MenuItem value={20}>20%</MenuItem>
          <MenuItem value={30}>30%</MenuItem>
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={60}>60%</MenuItem>
          <MenuItem value={70}>70%</MenuItem>
          <MenuItem value={80}>80%</MenuItem>
          <MenuItem value={90}>90%</MenuItem>
        </Select>
      ),
      render: (rowData) => (
        <div style={{ width: "70px" }}>{rowData.sectionFourScore}</div>
      ),
    },
    {
      title: "Section 4 Result",
      field: "sectionFourResultStatus",
      filtering: true,
      editable: false,
      sorting: false,
      lookup: {
        Qualified: "Qualified",
        Disqualified: "Disqualified",
        NA: "NA",
      },
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Section 5 Score",
      field: "sectionFiveScore",
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      filterComponent: () => (
        <Select
          id="sectionFiveScore"
          style={{ width: 80 }}
          value={sec5Score}
          onChange={(e) => {
            setSec5Score(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.sectionFiveScore) >= e.target.value ||
                e.target.value === "all"
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={"all"}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={10}>10%</MenuItem>
          <MenuItem value={20}>20%</MenuItem>
          <MenuItem value={30}>30%</MenuItem>
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={60}>60%</MenuItem>
          <MenuItem value={70}>70%</MenuItem>
          <MenuItem value={80}>80%</MenuItem>
          <MenuItem value={90}>90%</MenuItem>
        </Select>
      ),
      render: (rowData) => (
        <div style={{ width: "70px" }}>{rowData.sectionFiveScore}</div>
      ),
    },
    {
      title: "Section 5 Result",
      field: "sectionFiveResultStatus",
      filtering: true,
      editable: false,
      sorting: false,
      lookup: {
        Qualified: "Qualified",
        Disqualified: "Disqualified",
        NA: "NA",
      },
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Section 6 Score",
      field: "sectionSixScore",
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      filterComponent: () => (
        <Select
          id="sectionSixScore"
          style={{ width: 80 }}
          value={sec6Score}
          onChange={(e) => {
            setSec6Score(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.sectionSixScore) >= e.target.value ||
                e.target.value === "all"
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={"all"}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={10}>10%</MenuItem>
          <MenuItem value={20}>20%</MenuItem>
          <MenuItem value={30}>30%</MenuItem>
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={60}>60%</MenuItem>
          <MenuItem value={70}>70%</MenuItem>
          <MenuItem value={80}>80%</MenuItem>
          <MenuItem value={90}>90%</MenuItem>
        </Select>
      ),
      render: (rowData) => (
        <div style={{ width: "70px" }}>{rowData.sectionSixScore}</div>
      ),
    },
    {
      title: "Section 6 Result",
      field: "sectionSixResultStatus",
      filtering: true,
      editable: false,
      sorting: false,
      lookup: {
        Qualified: "Qualified",
        Disqualified: "Disqualified",
        NA: "NA",
      },
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Overall Score",
      field: "overallScore",
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
      filterComponent: () => (
        <Select
          id="overallScore"
          style={{ width: 70 }}
          value={overallScorePer}
          onChange={(e) => {
            setOverallScorePer(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.overallScore) >= e.target.value ||
                e.target.value === "all"
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={"all"}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={10}>10%</MenuItem>
          <MenuItem value={20}>20%</MenuItem>
          <MenuItem value={30}>30%</MenuItem>
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={60}>60%</MenuItem>
          <MenuItem value={70}>70%</MenuItem>
          <MenuItem value={80}>80%</MenuItem>
          <MenuItem value={90}>90%</MenuItem>
        </Select>
      ),
      render: (rowData) => (
        <div
          style={{
            background:
              rowData.overallScore >= 70.0
                ? "#02ae02af"
                : rowData.overallScore < 70.0 && rowData.overallScore > 0
                ? "#d80303aa"
                : "#9f9f9f",
            borderRadius: "4px",
            textAlign: "center",
            padding: 5,
            width: "70px",
          }}
        >
          {rowData.overallScore}
        </div>
      ),
    },
    {
      title: "Overall Qualification",
      field: "overallResultStatus",
      filtering: true,
      editable: false,
      sorting: false,
      lookup: {
        Qualified: "Qualified",
        Disqualified: "Disqualified",
        NA: "NA",
      },
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Band",
      field: "banding",
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Video link",
      field: "videoUrl",
      filtering: false,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData) => (
        <a
          href={rowData.videoUrl}
          style={{ width: "70px" }}
          target="_blank"
          rel="noreferrer noopener"
        >
          {`${rowData?.videoUrl?.slice(0, 15)}...`}
        </a>
      ),
    },
    // {
    //   title: 'Video bands',
    //   field: 'videoBand',
    //   filtering: false,
    //   sorting: false,
    //   cellStyle: {
    //     textAlign: 'center',
    //   },
    //   editComponent: (props) => {
    //     return (
    //       <Autocomplete
    //         id='videoBand'
    //         name='videoBand'
    //         getOptionLabel={(option) => option?.videoBands}
    //         options={options}
    //         onChange={(e, newVal) => {
    //           props.onChange(newVal);
    //         }}
    //         value={props.value}
    //         fullWidth
    //         renderInput={(params) => <TextField {...params} label='Band' variant='outlined' />}
    //       />
    //     );
    //   },
    //   render: (rowData) => <div style={{ width: '100px' }}>{rowData.videoBand?.videoBands}</div>,
    // },
  ];

  const handleDownload = (testQuestionSetId, downloadpath) => {
    dispatch(downloadTest(testQuestionSetId, downloadpath));
  };

  return (
    <div>
      <h3 style={{ textAlign: "center" }}>{clsaTitle}</h3>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <MaterialTable
            columns={columns}
            data={filteredData}
            isLoading={Boolean(loading)}
            // editable={{
            //   onRowUpdate: (newData) =>
            //     new Promise((resolve, reject) => {
            //       setTimeout(() => {
            //         const dataUpdate = [...tableData];
            //         const index = tableData.findIndex(
            //           (item) => item.studentId === newData.studentId
            //         );
            //         dataUpdate[index]['videoBand'] = newData.videoBand;
            //         setTableData([...dataUpdate]);
            //         resolve();
            //       }, 1000);
            //     }),
            // }}
            localization={{
              header: {
                actions: "",
              },
            }}
            components={{
              Toolbar: (props) => (
                <div
                  style={{
                    display: "flex",
                    marginTop: "1rem",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "2px solid #0094b1",
                    borderTop: "2px solid #007d93",
                    borderRadius: "4px",

                    color: "#1093ff",
                  }}
                >
                  <>
                    <div style={{ flexGrow: 2, width: "50%" }}>
                      <Controls.RoundedInput
                        // style={{color:"#1093FF"}}
                        className={classes.searchInput}
                        placeholder="Search by Name, Email & Phone Number..."
                        helperText={"Press Enter key to search after typing"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Search />
                            </InputAdornment>
                          ),
                        }}
                        defaultValue={searchValue} // search text was stay remains even the material-table is re-rendered.
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            handleSearch(e.target.value);
                          }
                        }}
                      />
                    </div>

                    {/* <p
                      style={{ display: 'flex', color: '#4CC68C', cursor: 'pointer' }}
                      onClick={() => handleDownload(id)}
                    >
                      <Download />
                      Download{' '}
                    </p> */}

                    <div
                      style={{
                        flexGrow: 0,
                        padding: "2rem 1rem 2rem",
                        width: "25%",
                      }}
                    >
                      <MTableToolbar {...props} />
                    </div>
                  </>
                </div>
              ),
            }}
            actions={[
              {
                icon: () => <ThumbUp style={{ color: "green" }} />,
                tooltip: "Update",
                onClick: (e, tableData) => {
                  let payload = tableData.map((item) => ({
                    studentId: item.studentId,
                    clsaVideoBandRatingId: item.videoBand?.id,
                  }));
                  let booleanArr = tableData.map((item) =>
                    Boolean(item.videoBand?.id)
                  );
                  if (booleanArr.includes(false)) {
                    setNotify({
                      isOpen: true,
                      message: "Please Choose the Video Band",
                      type: "error",
                    });
                  } else {
                    // dispatch(updateResult(id, payload));
                  }
                },
              },
              {
                icon: () => <ExportIcon style={{ color: "#009be5" }} />,
                tooltip: "Master Sheet Download",
                onClick: (e, tableData) => {
                  let filteredIds = tableData.map((student) => student.id);
                  let obj = { studentIdList: filteredIds };
                  dispatch(
                    aeSheetDownload(id, obj, (response) => {
                      if (response.status === 200) {
                        textToDownloadFile(response?.data, clsaTitle, "xlsx");
                        setNotify({
                          isOpen: true,
                          message: "Master Sheet will be downloaded...",
                          type: "success",
                        });
                      } else {
                        setNotify({
                          isOpen: true,
                          message:
                            "Something went wrong, Unable to download..!",
                          type: "error",
                        });
                      }
                    })
                  );
                },
              },
            ]}
            options={{
              sorting: true,
              search: false,
              searchFieldAlignment: "right",
              searchFieldVariant: "outlined",
              filtering: true,
              paging: false,
              pageSize: 60, // make initial page size
              emptyRowsWhenPaging: false, // To avoid of having empty rows
              exportButton: false,
              exportAllData: false,
              exportFileName: getClsaResult?.data?.clsaTitle,
              addRowPosition: "first",
              actionsColumnIndex: -1,
              selection: true,
              showSelectAllCheckbox: true,
              showTextRowsSelected: false,
              grouping: false,
              columnsButton: true,
              rowStyle: (tableData, index) =>
                index % 2 === 0 ? { background: "#f5f5f5" } : null,
              headerStyle: { background: "aliceblue", color: "#000" },
            }}
            title={""}
            icons={{
              Add: Add,
              Edit: Edit,
              Check: Check,
              FirstPage: FirstPage,
              NextPage: ChevronRight,
              PreviousPage: ChevronLeft,
              LastPage: LastPage,
              Filter: () => <FilterIcon style={{ color: "#807f7f" }} />,
              SortArrow: () => (
                <SortIcon
                  fontSize="small"
                  style={{ color: "#c6c6c6", transform: "rotate(-90deg)" }}
                />
              ),
              Clear: () => <ClearIcon />,
              Export: () => <ExportIcon style={{ color: "green" }} />,
              ResetSearch: () => <ClearIcon />,
              Search: () => <SearchIcon />,
              ViewColumn: () => <ViewColumnIcon />,
            }}
          />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <Pagination
              page={page + 1}
              count={totalPages}
              // onChange={handlePageChange}
              variant="outlined"
              color="primary"
              size="large"
            />
          </div>
        </div>
      )}
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}

export default AeResult;
