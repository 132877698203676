import { EXAMSPACE } from "../Redux/Action";

const initialState = {
  getUniversityList: null,
  createSemesterList: null,
  getSemesterList: null,
  getSubjectList: null,
  createSubjectList: null,
  loading: false,
  allExamSpaceList: null,
  getUniversityList: null,
  getBranchList: null,
  deleteStatus: null,
  removingSubject: null,
  removingSemester: null,
  createUnivBranch: null,
  getUnivBranch: null,
  deleteBranchStatus: null,
  createChapter: null,
  getAllChapter: null,
  deleteChapterStatus: null,
  getContentType: null,
  getAllContents: null,
  removeContent: null,
  createVideo: null,
  createFile: null,
};

function examSpaceReducer(state = initialState, action) {
  switch (action.type) {
    case EXAMSPACE.loader:
      return {
        ...state,
        loading: true,
      };
    case EXAMSPACE.examSpaceHomeDetails:
      return {
        ...state,
        loading: false,
        allExamSpaceList: action.payload,
      };
    case EXAMSPACE.getUniversity:
      return {
        ...state,
        loading: false,
        getUniversityList: action.payload,
      };
    case EXAMSPACE.getSemester:
      return {
        ...state,
        loading: false,
        getSemesterList: action.payload,
      };
    case EXAMSPACE.createSemester:
      return {
        ...state,
        createSemesterList: action.payload,
      };
    case EXAMSPACE.getSubject:
      return {
        ...state,
        loading: false,
        getSubjectList: action.payload,
      };
    case EXAMSPACE.createSubject:
      return {
        ...state,
        createSubjectList: action.payload,
      };
    case EXAMSPACE.getBranch:
      return {
        ...state,
        getBranchList: action.payload,
      };
    case EXAMSPACE.removeSubject:
      return {
        ...state,
        removingSubject: action.payload,
      };
    case EXAMSPACE.removeSemester:
      return {
        ...state,
        removingSemester: action.payload,
      };
    case EXAMSPACE.saveVideoLink:
      return {
        ...state,
        createVideo: action.payload,
      };
    case EXAMSPACE.deleteContent:
      return {
        ...state,
        removeContent: action.payload,
      };
    case EXAMSPACE.listAllContents:
      return { ...state, loading: false, getAllContents: action.payload };

    case EXAMSPACE.deleteUniversityById:
      return { ...state, loading: false, deleteStatus: action.payload };
    case EXAMSPACE.createUniversityBranch:
      return { ...state, loading: false, createUnivBranch: action.payload };
    case EXAMSPACE.getUniversityBranch:
      return { ...state, loading: false, getUnivBranch: action.payload };
    case EXAMSPACE.deleteBranchById:
      return { ...state, loading: false, deleteBranchStatus: action.payload };
    case EXAMSPACE.createChapter:
      return { ...state, loading: false, createChapter: action.payload };
    case EXAMSPACE.getAllChapter:
      return { ...state, loading: false, getAllChapter: action.payload };
    case EXAMSPACE.deleteChapterById:
      return { ...state, loading: false, deleteChapterStatus: action.payload };
    case EXAMSPACE.getContent:
      return {
        ...state,
        getContentType: action.payload,
      };
    case EXAMSPACE.listAllContents:
      return { ...state, loading: false, getAllContents: action.payload };
    case EXAMSPACE.deleteContent:
      return {
        ...state,
        removeContent: action.payload,
      };
    case EXAMSPACE.saveVideo:
      return {
        ...state,
        createVideo: action.payload,
      };
    case EXAMSPACE.saveFile:
      return {
        ...state,
        createFile: action.payload,
      };
    case EXAMSPACE.setFieldValue:
      return {
        ...state,
        loading: false,
        [action.fieldName]: action.fieldValue,
      };
    default:
      break;
  }
  return state;
}
export default examSpaceReducer;
