import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { clearData, getDetails } from "../../../Actions/PostAdmitServices";
import DocumentCard from "./DocumentCard";
import { DocumentComponentForAdmitCard } from "./DocumentComponent";

function AdmitIndex() {
  const params = useParams();
  const { studentId, productId } = params;
  const { getCardDetails } = useSelector(
    (state) => state.PostAdmitServicesReducer
  );
  const [thirdPage, setThirdPage] = useState(false);
  const dispatch = useDispatch();
  const uploadDocument = (upload) => {
    setThirdPage(upload);
  };
  console.log(getCardDetails, "jndjdjdj");
  const headerRouting = (headingRoute) => {
    setThirdPage(false);
  };
  useEffect(() => {
    dispatch(getDetails(studentId, productId));
    dispatch(clearData(""));
  }, []);
  const renderComponent = () => {
    if (thirdPage) {
      return <DocumentComponentForAdmitCard headerRouting={headerRouting} />;
    } else {
      return <DocumentCard uploadDocument={uploadDocument} />;
    }
  };
  return <div>{renderComponent()}</div>;
}

export default AdmitIndex;
