import { IconButton, Table, TableBody, TableRow } from '@material-ui/core';
import { MoreVertRounded } from '@material-ui/icons';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import React from 'react';
import { useDispatch } from 'react-redux';
import Blue from '../../../Asset/icons/Down.svg';
import Blur from '../../../Asset/icons/Up.png';
import DescriptionIcon from '@material-ui/icons/Description';
import { useHistory, useParams } from 'react-router-dom';
import {
  BodyCell,
  BoldCell,
  Head,
  HeadCell,
  HeadInline,
  IconBox,
  TableBox,
} from '../../Assets/StyledTableComponents';
import { downloadTest } from '../../Redux/Action/Test';
import Menu from './Menu';
import { lms_result } from '../../../Component/RoutePaths';
let deptName = window.sessionStorage.getItem('department');

const handleShowThreeDot = (role, status) => {
  let deptName = window.sessionStorage.getItem('department');
  return !(
    deptName === 'lms_editor' &&
    (status === 'Live' || status === 'In Review' || status === 'Approved' || status === 'Sheduled')
  );
};

export default function TableComp(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const headText =
    props.deptname === 'assessment_engine_admin' &&
    window.sessionStorage.getItem('role') === 'SUPER ADMIN'
      ? [
          'Name',
          'Test Type',
          '#  Que Assignes',
          '# Que filled',
          '',
          '',
          'Status',

          'Download',
          'Test URL',
          '',
          'Result',
          '',
        ]
      : [
          'Name',
          'Test Type',
          '#  Que Assignes',
          '# Que filled',
          'Course',
          'Topic name',
          'Status',

          '',
        ];
  const handleDownload = (testQuestionSetId, downloadpath) => {
    // setScheduler(true);
    // setData(item);
    //

    dispatch(downloadTest(testQuestionSetId, downloadpath));
  };
  const {
    tableContent,
    field,
    order,
    handleSortNew,
    handleSortBlue,
    handleSortBlur,
    role,
    handleThreeDotClick,
    anchorEl,
    popUpId,
    handleClose,
    handleOptions,
    openStatus,
    clickedStatus,
    myId,
  } = props;
  const aedept = window.sessionStorage.getItem('department');

  //Sort Icons
  const renderIcons = (field, order, index) => {
    const typeIndex = field.indexOf('type');
    const queIndex = field.indexOf('queValue');
    const courseNameIndex = field.indexOf('courseName');
    const statusIndex = field.indexOf('wkStatusValue');
    const fields = ['type', 'courseName', 'wkStatusValue', 'queValue'];

    if (index === 1 && order[typeIndex] === 'ASC') {
      return (
        <IconBox>
          <img
            src={Blue}
            alt='Up arrow'
            className={'up_arrow rotate'}
            id='type'
            onClick={() => {
              handleSortBlue(typeIndex);
            }}
          />
          <img
            src={Blur}
            alt='Down arrow'
            className={'down_arrow rotate'}
            id='type'
            onClick={() => {
              handleSortBlur(typeIndex);
            }}
          />
        </IconBox>
      );
    } else if (index === 1 && order[typeIndex] === 'DESC') {
      return (
        <IconBox>
          <img
            src={Blur}
            alt='Up arrow'
            className={'up_arrow'}
            id='type'
            onClick={() => {
              handleSortBlur(typeIndex);
            }}
          />
          <img
            src={Blue}
            alt='Down arrow'
            className={'down_arrow'}
            id='type'
            onClick={() => handleSortBlue(typeIndex)}
          />
        </IconBox>
      );
    } else if (index === 4 && order[courseNameIndex] === 'ASC') {
      return (
        <IconBox>
          <img
            src={Blue}
            alt='Up arrow'
            className={'up_arrow rotate'}
            id={'courseName'}
            onClick={() => handleSortBlue(courseNameIndex)}
          />
          <img
            src={Blur}
            alt='Down arrow'
            className={'down_arrow rotate'}
            id={'courseName'}
            onClick={() => handleSortBlur(courseNameIndex)}
          />
        </IconBox>
      );
    } else if (index === 4 && order[courseNameIndex] === 'DESC') {
      return (
        <IconBox>
          <img
            src={Blur}
            alt='Up arrow'
            className={'up_arrow'}
            id={'courseName'}
            onClick={() => handleSortBlur(courseNameIndex)}
          />
          <img
            src={Blue}
            alt='Down arrow'
            className={'down_arrow'}
            id={'courseName'}
            onClick={() => handleSortBlue(courseNameIndex)}
          />
        </IconBox>
      );
    } else if (index === 3 && order[queIndex] === 'ASC') {
      return (
        <IconBox>
          <img
            src={Blue}
            alt='Up arrow'
            className={'up_arrow rotate'}
            id={'queValue'}
            onClick={() => handleSortBlue(queIndex)}
          />
          <img
            src={Blur}
            alt='Down arrow'
            className={'down_arrow rotate'}
            id={'queValue'}
            onClick={() => handleSortBlur(queIndex)}
          />
        </IconBox>
      );
    } else if (index === 3 && order[queIndex] === 'DESC') {
      return (
        <IconBox>
          <img
            src={Blur}
            alt='Up arrow'
            className={'up_arrow'}
            id={'queValue'}
            onClick={() => handleSortBlur(queIndex)}
          />
          <img
            src={Blue}
            alt='Down arrow'
            className={'down_arrow'}
            id={'queValue'}
            onClick={() => handleSortBlue(queIndex)}
          />
        </IconBox>
      );
    } else if (index === 6 && order[statusIndex] === 'ASC') {
      return (
        <IconBox>
          <img
            src={Blue}
            alt='Up arrow'
            className={'up_arrow rotate'}
            id={'wkStatusValue'}
            onClick={() => handleSortBlue(statusIndex)}
          />
          <img
            src={Blur}
            alt='Down arrow'
            className={'down_arrow rotate'}
            id={'wkStatusValue'}
            onClick={() => handleSortBlur(statusIndex)}
          />
        </IconBox>
      );
    } else if (index === 6 && order[statusIndex] === 'DESC') {
      return (
        <IconBox>
          <img
            src={Blur}
            alt='Up arrow'
            className={'up_arrow'}
            id={'courseName'}
            onClick={() => handleSortBlur(statusIndex)}
          />
          <img
            src={Blue}
            alt='Down arrow'
            className={'down_arrow'}
            id={'courseName'}
            onClick={() => handleSortBlue(statusIndex)}
          />
        </IconBox>
      );
    }
    // Default
    else
      return (
        <IconBox>
          <img
            src={Blur}
            alt='Up arrow'
            className={'up_arrow'}
            onClick={() => {
              handleSortNew(index, 'ASC');
            }}
          />
          <img
            src={Blur}
            alt='Down arrow'
            className={'down_arrow rotate'}
            onClick={() => {
              handleSortNew(index, 'DESC');
            }}
          />
        </IconBox>
      );
  };
  const openResult = (item) => {
    const myId = item.id;
    history.push({
      pathname: lms_result + item.id,
      id: item.id,
    });
    // setOpenDrawer(false);
  };

  return (
    <TableBox style={{ height: aedept === 'assessment_engine_admin' ? '450px' : '' }}>
      <Table>
        <Head>
          <TableRow>
            {headText.map((item, index) => (
              <HeadCell>
                <HeadInline>
                  {item}
                  {aedept !== 'assessment_engine_admin'
                    ? (index === 1 || index === 4 || index === 6) &&
                      renderIcons(field, order, index)
                    : (index === 1 || index === 6) && renderIcons(field, order, index)}
                </HeadInline>
              </HeadCell>
            ))}
          </TableRow>
        </Head>
        <TableBody>
          {tableContent &&
            tableContent.map((item) => {
              return (
                <TableRow key={item.id} style={{ border: '0 0 0 0' }}>
                  <BoldCell>{item.name}</BoldCell>
                  <BoldCell>{item.testType}</BoldCell>
                  <BoldCell className={'table_center_align'}>{item.queAssigns}</BoldCell>
                  <BodyCell className={'table_center_align'}>{item.queFilled}</BodyCell>
                  <BodyCell>{item.courseName}</BodyCell>
                  <BodyCell>{item.topicName}</BodyCell>
                  <BodyCell>{item.status}</BodyCell>
                  {item.testType === 'AE_TEST' && aedept === 'assessment_engine_admin' ? (
                    <>
                      <BodyCell>
                        {/* {item.uniqueUrl} */}

                        {/* <Controls.ActionButton
                      disabled={!item.attemptedStudents}
                      href={`${process.env.REACT_APP_API_URL}`}
                    > */}
                        <CloudDownloadIcon
                          fontSize='small'
                          style={{ marginLeft: '40px' }}
                          onClick={() => handleDownload(item.id)}
                        />
                        {/* </Controls.ActionButton> */}
                        {/* <Controls.ActionButton onClick={() => onSchedule(item)}> */}
                        {/* <ScheduleIcon fontSize='small' color='primary' /> */}
                        {/* </Controls.ActionButton> */}
                      </BodyCell>
                      <BodyCell>
                        <a href={`${item.uniqueUrl}`} target={'_blank'}>
                          {item.uniqueUrl}
                        </a>
                      </BodyCell>{' '}
                    </>
                  ) : (
                    <>
                      <BodyCell></BodyCell>
                      <BodyCell></BodyCell>
                    </>
                  )}
                  <BodyCell>
                    {handleShowThreeDot(role, item.status) && (
                      <div>
                        {item.status !== 'Expired' ? (
                          <IconButton
                            aria-controls={item.id}
                            aria-haspopup='true'
                            onClick={(event) => handleThreeDotClick(event, item.id, item.status)}
                            style={{ padding: '0px' }}
                          >
                            <MoreVertRounded style={{ fill: '#1093FF' }} />
                          </IconButton>
                        ) : (
                          <></>
                        )}
                        <Menu
                          role={role}
                          anchorEl={anchorEl}
                          open={openStatus}
                          // open={handleOpen(item.id, popUpId, role, item.status)}
                          handleClose={handleClose}
                          status={item.status}
                          handleOptions={handleOptions}
                          name={item.name}
                          topicId={item.id}
                          activeStatus={clickedStatus}
                        />
                      </div>
                    )}
                  </BodyCell>
                  {item.testType === 'AE_TEST' && aedept === 'assessment_engine_admin' ? (
                    <BodyCell>
                      <DescriptionIcon
                        fontSize='small'
                        color='primary'
                        style={{ cursor: 'pointer' }}
                        onClick={() => openResult(item)}
                      />
                    </BodyCell>
                  ) : (
                    ''
                  )}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableBox>
  );
}
