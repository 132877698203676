import axios from "axios";
import { errorHandler } from "../Component/Utils/Helpers";
import { CRUD_WEBINAR } from "../Redux/Action";
import { URL } from "./URL";

export const postWebinarCall = (data) => {
  let accessToken = window.sessionStorage.getItem('accessToken');
  return dispatch => {
    //   dispatch({ type: CRUD_WEBINAR.loader });
    axios
      .post(`${URL}/api/v1/webinars`, data, {
        headers: {

          Authorization: `Bearer ${accessToken}`,
          admin: "yes"
        },

      })
      .then(result => {
        dispatch({
          type: CRUD_WEBINAR.addWebinarLink,
          payload: { success: true, data: result.data },
          loading: false,
        });
      })
      .catch(error => {
        dispatch(errorHandler(CRUD_WEBINAR.addWebinarLink, error, false));
      });
  };
};
export const updateWebinarCall = (id, data) => {
  let accessToken = window.sessionStorage.getItem('accessToken');
  return dispatch => {
    //   dispatch({ type: CRUD_WEBINAR.loader });
    axios
      .put(`${URL}/api/v1/webinars/${id}`, data, {
        headers: {

          Authorization: `Bearer ${accessToken}`,
          admin: "yes"
        },

      })
      .then(result => {
        dispatch({
          type: CRUD_WEBINAR.addWebinarLink,
          payload: { success: true, data: result.data },
          loading: false,
        });
      })
      .catch(error => {
        dispatch(errorHandler(CRUD_WEBINAR.addWebinarLink, error, false));
      });
  };
};
export const getWebinarCall = (data) => {
  let accessToken = window.sessionStorage.getItem('accessToken');
  return dispatch => {
    //   dispatch({ type: CRUD_WEBINAR.loader });
    axios
      .get(`${URL}/api/v1/webinars`, {
        headers: {

          Authorization: `Bearer ${accessToken}`,
          admin: "yes"
        },

      })
      .then(result => {
        dispatch({
          type: CRUD_WEBINAR.getWebinarLink,
          payload: { success: true, data: result.data },
          loading: false,
        });
      })
      .catch(error => {
        dispatch(errorHandler(CRUD_WEBINAR.getWebinarLink, error, false));
      });
  };
};
export const getToolsCall = (data) => {
  let accessToken = window.sessionStorage.getItem('accessToken');
  return dispatch => {
    //   dispatch({ type: CRUD_WEBINAR.loader });
    axios
      .get(`${URL}/api/v1/resources/webinars`, {
        headers: {

          Authorization: `Bearer ${accessToken}`,
          admin: "yes"
        },

      })
      .then(result => {
        dispatch({
          type: CRUD_WEBINAR.getTools,
          payload: { success: true, data: result.data },
          loading: false,
        });
      })
      .catch(error => {
        dispatch(errorHandler(CRUD_WEBINAR.getTools, error, false));
      });
  };
};
export const deleteWebinarCall = (id) => {
  let accessToken = window.sessionStorage.getItem('accessToken');
  return dispatch => {
    //   dispatch({ type: CRUD_WEBINAR.loader });
    axios
      .delete(`${URL}/api/v1/webinars/${id}`, {
        headers: {

          Authorization: `Bearer ${accessToken}`,
          admin: "yes"
        },

      })
      .then(result => {
        dispatch({
          type: CRUD_WEBINAR.deleteWebinarLink,
          payload: { success: true, data: result.data },
          loading: false,
        });
      })
      .catch(error => {
        dispatch(errorHandler(CRUD_WEBINAR.deleteWebinarLink, error, false));
      });
  };
};

export const getDocumentList = (studentId, productId) => {
  let accessToken = window.sessionStorage.getItem('accessToken');
  return dispatch => {
    //   dispatch({ type: CRUD_WEBINAR.loader });
    axios
      .get(`${URL}/api/v1/students/${studentId}/products/${productId}/studentdocuments`, {
        headers: {

          Authorization: `Bearer ${accessToken}`,
          admin: "yes"
        },

      })
      .then(result => {
        dispatch({
          type: CRUD_WEBINAR.documentList,
          payload: { success: true, data: result.data },
          loading: false,
        });
      })
      .catch(error => {
        dispatch(errorHandler(CRUD_WEBINAR.documentList, error, false));
      });
  };
};
export const getFilesList = (studentId, productId, type) => {
  let accessToken = window.sessionStorage.getItem('accessToken');
  return dispatch => {
    //   dispatch({ type: CRUD_WEBINAR.loader });
    axios
      .get(`${URL}/api/v1/students/${studentId}/products/${productId}/studentdocumentList?type=${type}`


      )
      .then(result => {
        dispatch({
          type: CRUD_WEBINAR.fileList,
          payload: { success: true, data: result.data },
          loading: false,
        });
      })
      .catch(error => {
        dispatch(errorHandler(CRUD_WEBINAR.fileList, error, false));
      });
  };
};



export const downloadFileCV = (studentId, productId, documentId, cvPath) => {
  let accessToken = window.sessionStorage.getItem('accessToken');
  return dispatch => {
    dispatch({ type: CRUD_WEBINAR.loader });
    axios
      .get(`${URL}/api/v1/cv/students/${studentId}/product/${productId}/downloadcv/${documentId}
        `, {
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'blob',
      })
      .then(result => {
        var filename = cvPath;
        const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `${filename}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        dispatch({
          type: CRUD_WEBINAR.getdownloadFileCV,
          payload: { success: true, data: result.data },
          loading: false,
        });
      })
      .catch(error => {
        dispatch(errorHandler(CRUD_WEBINAR.getdownloadFileCV, error, false));
      });
  };
};

export const downloadFileEssayLorReports = (studentId, subStageId, fileId, cvPath) => {
  let accessToken = window.sessionStorage.getItem('accessToken');
  return dispatch => {
    dispatch({ type: CRUD_WEBINAR.loader });
    axios
      .get(`${URL}/api/v1/students/${studentId}/subStage/${subStageId}/${fileId}
        `, {
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'blob',
      })
      .then(result => {
        var filename = cvPath;
        const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `${filename}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        dispatch({
          type: CRUD_WEBINAR.getdownloadFileCV,
          payload: { success: true, data: result.data },
          loading: false,
        });
      })
      .catch(error => {
        dispatch(errorHandler(CRUD_WEBINAR.getdownloadFileCV, error, false));
      });
  };
};
export const downloadFileAppEssayLorReports = (studentId, subStageId, schoolId, path, type, cvPath) => {
  let accessToken = window.sessionStorage.getItem('accessToken');
  return dispatch => {
    dispatch({ type: CRUD_WEBINAR.loader });
    axios
      .get(`${URL}/api/v1/students/${studentId}/subStage/${subStageId}/${schoolId}/${path}?type=${type}
        `, {
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'blob',
      })
      .then(result => {
        var filename = cvPath;
        const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `${filename}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        dispatch({
          type: CRUD_WEBINAR.getdownloadFileCV,
          payload: { success: true, data: result.data },
          loading: false,
        });
      })
      .catch(error => {
        dispatch(errorHandler(CRUD_WEBINAR.getdownloadFileCV, error, false));
      });
  };
};

export const downloadFileMarksheetsGat = (studentId, fileId, cvPath) => {
  let accessToken = window.sessionStorage.getItem('accessToken');
  return dispatch => {
    dispatch({ type: CRUD_WEBINAR.loader });
    axios
      .get(`${URL}/api/v1/students/${studentId}/file/${fileId}
        `, {
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'blob',
      })
      .then(result => {
        var filename = cvPath;
        const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `${filename}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        dispatch({
          type: CRUD_WEBINAR.getdownloadFileCV,
          payload: { success: true, data: result.data },
          loading: false,
        });
      })
      .catch(error => {
        dispatch(errorHandler(CRUD_WEBINAR.getdownloadFileCV, error, false));
      });
  };
};

export const downloadFileOthersAdmit = (studentId, productId, admitcardId, postadmitschoolId, fileId, cvPath) => {
  let accessToken = window.sessionStorage.getItem('accessToken');
  return dispatch => {
    dispatch({ type: CRUD_WEBINAR.loader });
    axios
      .get(`${URL}/api/v1/students/${studentId}/products/${productId}/postadmitservices/${admitcardId}/${postadmitschoolId}/${fileId}
        `, {
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'blob',
      })
      .then(result => {
        var filename = cvPath;
        const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `${filename}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        dispatch({
          type: CRUD_WEBINAR.getdownloadFileCV,
          payload: { success: true, data: result.data },
          loading: false,
        });
      })
      .catch(error => {
        dispatch(errorHandler(CRUD_WEBINAR.getdownloadFileCV, error, false));
      });
  };
};

export const downloadFileOthersPerusal = (studentId, productId, fileId, cvPath) => {
  let accessToken = window.sessionStorage.getItem('accessToken');
  return dispatch => {
    dispatch({ type: CRUD_WEBINAR.loader });
    axios
      .get(`${URL}/api/v1/students/${studentId}/products/${productId}/postadmitservices/documents/${fileId}
        `, {
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'blob',
      })
      .then(result => {
        var filename = cvPath;
        const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `${filename}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        dispatch({
          type: CRUD_WEBINAR.getdownloadFileCV,
          payload: { success: true, data: result.data },
          loading: false,
        });
      })
      .catch(error => {
        dispatch(errorHandler(CRUD_WEBINAR.getdownloadFileCV, error, false));
      });
  };
};

export const clearCustomData = (fieldName) => {
  return (dispatch) => {
    dispatch({
      type: CRUD_WEBINAR.clearCustomData,
      fieldName: fieldName,
    });
  };
};