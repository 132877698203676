import {
  InputAdornment,
  makeStyles,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { examSpaceUniversityPath } from "../../RoutePaths";
import ConfirmDialog from "../../Utils/ConfirmDialog";
import Controls from "../../Utils/controls/Controls";
import Loader from "../../Utils/controls/Loader";
import Notification from "../../Utils/Notification";
import useTable from "../../Utils/useTable";
import {
  deleteUniversityById,
  examSpaceHomeDetails,
  examSpaceSheetDownload,
} from "../../../Actions/ExamSpaceAction";
import { textToDownloadFile } from "../../Utils/Helpers";

const Alert = (props) => <MuiAlert elevation={6} variant='filled' {...props} />;

const useStyles = makeStyles((theme) => ({
  pageContent: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
  },
  searchInput: {
    width: "65%",
  },
  newButton: {
    position: "absolute",
    right: "20px",
    bottom: "25px",
    borderRadius: "26px",
  },
  actions: {
    width: "200px",
    alignContent: "center",
    alignItems: "center",
  },
}));

const headCells = [
  { id: "universityTitle", label: "University" },
  { id: "branch", label: "Branch" },
  { id: "actions", label: "Actions", disableSorting: true },
];

export default function ExamSpaceHome() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [searchValue, setSearchValue] = useState();
  const [totalElements, setTotalElements] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [filteredLists, setFilteredLists] = useState([]);
  const [deleteClick, setDeleteClick] = useState(false);

  const { loading, allExamSpaceList, deleteStatus } = useSelector(
    (state) => state.examSpaceReducer
  );

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    page,
    setPage,
  } = useTable(filteredLists, headCells, filterFn, totalPages);

  useEffect(() => {
    dispatch(examSpaceHomeDetails(page - 1));
  }, [dispatch, page]);

  useEffect(() => {
    if (allExamSpaceList) {
      if (allExamSpaceList.success) {
        setFilteredLists(allExamSpaceList?.data?.content);
        setTotalPages(allExamSpaceList?.data.totalPages);
        setTotalElements(allExamSpaceList?.data.totalElements);
      } else {
        setFilteredLists([]);
      }
    }
  }, [allExamSpaceList]);

  useEffect(() => {
    if (deleteClick && deleteStatus) {
      if (deleteStatus.success) {
        setNotify({
          isOpen: true,
          message: deleteStatus.message,
          type: "success",
        });
        setTimeout(() => {
          if (deleteStatus.currentPageContentLength)
            dispatch(examSpaceHomeDetails(page - 1));
          else {
            let newPage = page - 1 === 0 ? 1 : page - 1;
            dispatch(examSpaceHomeDetails(newPage));
            setPage(newPage);
          }
        }, 1200);
      } else {
        setNotify({
          isOpen: true,
          message: deleteStatus.message,
          type: "error",
        });
      }
      setDeleteClick(false);
    }
  }, [deleteClick, deleteStatus]);

  const handleDeleteClick = (item) => {
    setConfirmDialog({
      isOpen: true,
      title: "DELETE UNIVERSITY?",
      subTitle: `Are you sure you want to delete the selected University?
        It will go longer will be visible on the App.`,
      onConfirm: () => {
        onDelete(item.universityId);
      },
    });
  };

  const openInPage = (item) => {
    history.push(`${examSpaceUniversityPath}?univId=${item.universityId}`);
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    let currentPageContentLength = filteredLists.length - 1;
    dispatch(deleteUniversityById(id, currentPageContentLength));
    setDeleteClick(true);
  };

  const handleSearch = (text) => {
    setSearchValue(text);
    dispatch(examSpaceHomeDetails(0, text));
  };

  return (
    <>
      <Paper className={classes.pageContent}>
        <Toolbar style={{ alignItems: "center" }}>
          <Controls.RoundedInput
            className={classes.searchInput}
            placeholder='Search Universities'
            helperText={"Press Enter key to search after typing"}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Search />
                </InputAdornment>
              ),
            }}
            defaultValue={searchValue} // search text was stay remains even the material-table is re-rendered.
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSearch(e.target.value);
              }
            }}
          />

          <Controls.Button
            text='Create New University'
            variant='contained'
            color='primary'
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={() => {
              history.push({
                pathname: examSpaceUniversityPath,
                postType: "University",
                postTypeTab: 0,
              });
            }}
          />
        </Toolbar>

        <TblContainer>
          <TblHead />
          {filteredLists && (
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => {
                return (
                  <TableRow key={item.universityId}>
                    <TableCell>{`${item.universityName}`}</TableCell>
                    <TableCell>{`${item.branchName}`}</TableCell>
                    <TableCell className={classes.actions}>
                      <Controls.ActionButton onClick={() => openInPage(item)}>
                        <EditOutlinedIcon fontSize='small' color='primary' />
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        onClick={() => {
                          dispatch(
                            examSpaceSheetDownload(
                              item.universityId,
                              (response) => {
                                if (response.status === 200) {
                                  textToDownloadFile(
                                    response.data,
                                    item.universityName +
                                      "_exam_space_master_report",
                                    "csv"
                                  );
                                } else {
                                  setNotify({
                                    isOpen: true,
                                    message: response.message,
                                    type: "error",
                                  });
                                }
                              }
                            )
                          );
                        }}
                      >
                        <CloudDownloadIcon
                          fontSize='small'
                          style={{
                            color: "green",
                          }}
                        />
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        onClick={() => handleDeleteClick(item)}
                      >
                        <DeleteIcon fontSize='small' color='secondary' />
                      </Controls.ActionButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </TblContainer>
        <div style={{ margin: "2rem auto", width: "60%" }}>
          {!loading && allExamSpaceList ? (
            allExamSpaceList.success ? (
              filteredLists?.length === 0 && (
                <Alert severity='info'>0 University Found</Alert>
              )
            ) : (
              <Alert severity='error'>{allExamSpaceList.message}</Alert>
            )
          ) : (
            <Loader />
          )}
        </div>
        <TblPagination />
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
