import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import MySnackBar from "../MySnackBar";
import { GSContainer, GSTitle } from "../../Asset/StyledComponents/Styles";
import { makeStyles } from "@material-ui/core/styles";
import BackButton from "../../Asset/Images/backbutton.svg";
import { AppBar, Breadcrumbs, FormHelperText } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Typography } from "@material-ui/core";
import { Grid, TextField } from "@material-ui/core";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  bSchoolDegree,
  bSchoolProgramAreaOfSpecialization,
  bSchoolProgramCategory,
  createBSchoolProgram,
  getOneBSchoolProgram,
} from "../../Actions/BSchoolAction";
import {
  isEmptyString,
  isOnlyNumber,
  urlPatternValidation,
} from "../Validation";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
  },
  btnStyles: {
    paddingLeft: 30,
    paddingRight: 30,
  },
}));
export default function BSProgram(props) {
  const {
    getOneBSchoolProgramData,
    bSchoolDegreeData,
    bSchoolProgramCategoryData,
    bSchoolAreaOfSpecialization,
  } = useSelector((state) => state.BSchoolReducer);

  const dispatch = useDispatch();
  const [state, setState] = useState({
    id: "",
    programName: null,
    intakeTerm: null,
    intakeYear: null,
    programType: null,
    courseStartDate: null,
    programLink: null,
    registrationLink: null,
    avgWorkExp: null,
    minWorkExp: null,
    rangeForWorkExp: null,
    percentageOfWomen: null,
    percentageOfMen: null,
    PercentageOfInternationalStudents: null,
    classSize: null,
    averageAge: null,
    classProfile: null,
    employmentStatistics: null,
    tuitionFee: null,
    costOfAttendance: null,
    costOfAttendanceAndTuitionFeesLink: null,
    admissionsRequirementLink: null,
    bachelorDegree: null,
    GPARequirement: null,
    examsRequired: null,
    nameOfApplicationDeadlineCalledAs: null,
    nameOfDecisionDateCalledAs: null,
    nameOfFinalDecisionDate: null,
    nameOfTheTerm: null,
    springIntake: null,
    stemDesigned: null,
    combo: null,
    standardizedTests: null,
    FTRanking: null,
    averageWorkExperience: null,
    averageGmat: null,
    assignment: null,
    durationMonth: null,
    durationYear: null,
    housingFood: null,
    booksAndComputer: null,
    medicalInsurance: null,
    visaFees: null,
    transportation: null,
    miscellaneous: null,
    personalExpense: null,
    applicationFees: null,
    courseDuration: null,
    scholarship: null,
    totalCostOfAttendance: null,
    livingCost: null,
    earlyActionOpenDate: null,
    interviewDate: null,
    decisionReleaseDate: null,
    finalDecisionDate: null,
    earlyActionDateTo: null,
    dataVerifiedDate: null,
    bsDegree: null,
    programCategory: null,
    bsAreaOfSpecialization: null,
    bSchoolId: null,
    yearOption: [],
    currentYear: new Date().getFullYear(),
  });
  const classes = useStyles();
  const [snack, setSnack] = useState({
    open: false,
    message: "",
    color: "",
  });
  const textField = [
    {
      name: "programName",
      value: state.programName,
      label: "Program Name",
      type: "text",
      required: true,
    },
    {
      name: "intakeTerm",
      value: state.intakeTerm,
      label: "Intake Term",
      type: "text",
      required: true,
    },
    {
      name: "intakeYear",
      value: state.intakeYear,
      label: "Intake Year",
      type: "number",
      required: true,
    },
    {
      name: "programType",
      value: state.programType,
      label: "Program Type",
      type: "text",
      required: true,
    },
    {
      name: "courseStartDate",
      value: state.courseStartDate,
      label: "Course Start Date",
      type: "text",
      required: true,
    },
    {
      name: "programLink",
      value: state.programLink,
      label: "Program Link",
      type: "text",
      required: true,
    },
    {
      name: "registrationLink",
      value: state.registrationLink,
      label: "Registration Link",
      type: "text",
    },
    {
      name: "avgWorkExp",
      value: state.avgWorkExp,
      label: "Average Work Experience (Months)",
      type: "number",
    },
    {
      name: "minWorkExp",
      value: state.minWorkExp,
      label: "Minimum Work Experience (Months)",
      type: "number",
    },
    {
      name: "rangeForWorkExp",
      value: state.rangeForWorkExp,
      label: "Range For Work Experience",
      type: "number",
    },
    {
      name: "percentageOfWomen",
      value: state.percentageOfWomen,
      label: "Percentage Of Women",
      type: "number",
    },
    {
      name: "percentageOfMen",
      value: state.percentageOfMen,
      label: "Percentage Of Men",
      type: "number",
    },
    {
      name: "PercentageOfInternationalStudents",
      value: state.PercentageOfInternationalStudents,
      label: "Percentage Of International Students",
      type: "number",
    },
    {
      name: "classSize",
      value: state.classSize,
      label: "Class Size",
      type: "number",
    },
    {
      name: "averageAge",
      value: state.averageAge,
      label: "Average Age",
      type: "number",
    },
    {
      name: "classProfile",
      value: state.classProfile,
      label: "Class Profile Link",
      type: "text",
    },
    {
      name: "employmentStatistics",
      value: state.employmentStatistics,
      label: "Employment Statistics Link",
      type: "text",
    },
    {
      name: "tuitionFee",
      value: state.tuitionFee,
      label: "Tuition Fee",
      type: "number",
      required: true,
    },
    {
      name: "costOfAttendance",
      value: state.costOfAttendance,
      label: "Cost Of Attendance",
      type: "number",
      required: true,
    },
    {
      name: "costOfAttendanceAndTuitionFeesLink",
      value: state.costOfAttendanceAndTuitionFeesLink,
      label: "Cost Of Attendance And Tuition FeesLink",
      type: "text",
    },
    {
      name: "admissionsRequirementLink",
      value: state.admissionsRequirementLink,
      label: "Admissions RequirementLink",
      type: "text",
    },
    {
      name: "bachelorDegree",
      value: state.bachelorDegree,
      label: "Bachelor Degree",
      type: "text",
    },
    {
      name: "GPARequirement",
      value: state.GPARequirement,
      label: "GPA Requirement",
      type: "text",
      required: true,
    },
    {
      name: "examsRequired",
      value: state.examsRequired,
      label: "Exams Required",
      type: "text",
      required: true,
    },
    {
      name: "nameOfApplicationDeadlineCalledAs",
      value: state.nameOfApplicationDeadlineCalledAs,
      label: "Name Of Application Deadline Called As",
      type: "text",
      required: true,
    },
    {
      name: "nameOfDecisionDateCalledAs",
      value: state.nameOfDecisionDateCalledAs,
      label: "Name Of Decision Date Called As",
      type: "text",
      required: true,
    },
    {
      name: "nameOfFinalDecisionDate",
      value: state.nameOfFinalDecisionDate,
      label: "Name Of Final Decision Date",
      type: "text",
      required: true,
    },
    {
      name: "nameOfTheTerm",
      value: state.nameOfTheTerm,
      label: "Name Of The Term",
      type: "text",
    },
    {
      name: "springIntake",
      value: state.springIntake,
      label: "Spring Intake",
      type: "text",
    },
    {
      name: "stemDesigned",
      value: state.stemDesigned,
      label: "Stem Designed",
      type: "text",
      required: true,
    },
    {
      name: "combo",
      value: state.combo,
      label: "Combo",
      type: "text",
      required: true,
    },
    {
      name: "standardizedTests",
      value: state.standardizedTests,
      label: "Standardized Tests",
      type: "text",
    },
    {
      name: "FTRanking",
      value: state.FTRanking,
      label: "FT Ranking",
      type: "number",
    },
    {
      name: "averageWorkExperience",
      value: state.averageWorkExperience,
      label: "Average Work Experience (in Round off)",
      type: "number",
    },
    {
      name: "averageGmat",
      value: state.averageGmat,
      label: "Average GMAT Score (full time)",
      type: "number",
    },
    {
      name: "assignment",
      value: state.assignment,
      label: "Assignment",
      type: "text",
    },
    {
      name: "durationMonth",
      value: state.durationMonth,
      label: "Duration Month",
      type: "number",
      required: true,
    },
    {
      name: "durationYear",
      value: state.durationYear,
      label: "Duration Year",
      type: "text",
      required: true,
    },

    {
      name: "earlyActionOpenDate",
      value: state.earlyActionOpenDate,
      label: "Early Action Open Date",
      type: "Date",
    },
    {
      name: "interviewDate",
      value: state.interviewDate,
      label: "Interview Date",
      type: "Date",
    },
    {
      name: "decisionReleaseDate",
      value: state.decisionReleaseDate,
      label: "Decision Release Date",
      type: "Date",
    },
    {
      name: "finalDecisionDate",
      value: state.finalDecisionDate,
      label: "Final Decision Date",
      type: "Date",
    },
    {
      name: "earlyActionDateTo",
      value: state.earlyActionDateTo,
      label: "Early Action Date To",
      type: "Date",
    },
    {
      name: "dataVerifiedDate",
      value: state.dataVerifiedDate,
      label: "Data Verified Date",
      type: "Date",
    },
  ];

  const bsCompareFields = [
    {
      name: "housingFood",
      value: state.housingFood,
      label: "Housing Food",
      type: "number",
    },
    {
      name: "booksAndComputer",
      value: state.booksAndComputer,
      label: "Books And Computer",
      type: "number",
    },
    {
      name: "medicalInsurance",
      value: state.medicalInsurance,
      label: "Medical Insurance",
      type: "number",
    },
    {
      name: "visaFees",
      value: state.visaFees,
      label: "Visa Fees",
      type: "number",
    },
    {
      name: "transportation",
      value: state.transportation,
      label: "Transportation",
      type: "number",
    },
    {
      name: "miscellaneous",
      value: state.miscellaneous,
      label: "Miscellaneous",
      type: "number",
    },
    {
      name: "personalExpense",
      value: state.personalExpense,
      label: "Personal Expense",
      type: "number",
    },
    {
      name: "applicationFees",
      value: state.applicationFees,
      label: "Application Fees",
      type: "number",
    },
    {
      name: "courseDuration",
      value: state.courseDuration,
      label: "Course Duration",
      type: "number",
    },
    {
      name: "scholarship",
      value: state.scholarship,
      label: "Scholarship",
      type: "number",
    },
    {
      name: "totalCostOfAttendance",
      value: state.totalCostOfAttendance,
      label: "Total Cost Of Attendance",
      type: "number",
    },
    {
      name: "livingCost",
      value: state.livingCost,
      label: "Living Cost",
      type: "number",
    },
  ];

  const [programNameErr, setProgramNameErr] = useState("");
  const [intakeTermErr, setIntakeTermErr] = useState("");
  const [tuitionFeeErr, setTuitionFeeErr] = useState("");
  const [attendanceErr, setAttendanceErr] = useState("");
  const [courseStateDateErr, setCourseStartDateErr] = useState("");
  const [programLinkErr, setProgramLinkErr] = useState("");
  const [durationYearErr, setDurationYearErr] = useState("");
  const [durationMonthErr, setDurationMonthErr] = useState("");
  const [intakeYearErr, setIntakeYearErr] = useState("");
  const [programTypeErr, setProgramTypeErr] = useState("");
  const [gpaRequirementErr, setGAPRequirementErr] = useState("");
  const [examsRequiredErr, setExamsRequiredErr] = useState("");
  const [stemDesignatedErr, setStemDesignatedErr] = useState("");
  const [comboErr, setComboErr] = useState("");
  const [applicationDeadlineErr, setApplicationDeadlineErr] = useState("");
  const [decisionDateCalledAsErr, setDecisionDateCalledAsErr] = useState("");
  const [finalDecisionDateErr, setFinalDecisionDateErr] = useState("");
  const [registrationLinkErr, setRegistrationLinkErr] = useState("");
  const [
    admissionsRequirementLinkErr,
    setAdmissionsRequirementLinkErr,
  ] = useState("");
  const [costOfTuitionAttendanceErr, setCostOfTuitionAttendanceErr] = useState(
    ""
  );
  const [classProfileErr, setClassProfileErr] = useState("");
  const [employmentStatisticsErr, setEmploymentStatisticsErr] = useState("");
  const [programCategoryErr, setProgramCategoryErr] = useState("");

  const onChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
    if (name === "programName") {
      setProgramNameErr("");
    }
    if (name === "intakeTerm") {
      setIntakeTermErr("");
    }
    if (name === "tuitionFee") {
      setTuitionFeeErr("");
    }
    if (name === "costOfAttendance") {
      setAttendanceErr("");
    }
    if (name === "courseStartDate") {
      setCourseStartDateErr("");
    }
    if (name === "programLink") {
      setProgramLinkErr("");
      if (!urlPatternValidation(value)) {
        setProgramLinkErr("Please fill the valid Program Link");
      }
      if (isEmptyString(value)) {
        setProgramLinkErr("Please fill the Program Link");
      }
    }
    if (name === "durationYear") {
      setDurationYearErr("");
    }
    if (name === "durationMonth") {
      setDurationMonthErr("");
    }
    if (name === "intakeYear") {
      setIntakeYearErr("");
    }
    if (name === "programType") {
      setProgramTypeErr("");
    }
    if (name === "GPARequirement") {
      setGAPRequirementErr("");
    }
    if (name === "examsRequired") {
      setExamsRequiredErr("");
    }
    if (name === "stemDesigned") {
      setStemDesignatedErr("");
    }
    if (name === "combo") {
      setComboErr("");
    }
    if (name === "nameOfApplicationDeadlineCalledAs") {
      setApplicationDeadlineErr("");
    }
    if (name === "nameOfDecisionDateCalledAs") {
      setDecisionDateCalledAsErr("");
    }
    if (name === "nameOfFinalDecisionDate") {
      setFinalDecisionDateErr("");
    }
    if (name === "registrationLink") {
      setRegistrationLinkErr("");
      if (value) {
        if (!urlPatternValidation(value)) {
          setRegistrationLinkErr("Please fill the valid Registration Link");
        }
      }
    }
    if (name === "admissionsRequirementLink") {
      setAdmissionsRequirementLinkErr("");
      if (value) {
        if (!urlPatternValidation(value)) {
          setAdmissionsRequirementLinkErr(
            "Please fill the valid Admissions Requirement Link"
          );
        }
      }
    }
    if (name === "costOfAttendanceAndTuitionFeesLink") {
      setCostOfTuitionAttendanceErr("");
      if (value) {
        if (!urlPatternValidation(value)) {
          setCostOfTuitionAttendanceErr(
            "Please fill the valid Cost Of Attendance And Tuition Fees Link"
          );
        }
      }
    }
    if (name === "classProfile") {
      setClassProfileErr("");
      if (value) {
        if (!urlPatternValidation(value)) {
          setClassProfileErr("Please fill the valid Class Profile Link");
        }
      }
    }
    if (name === "employmentStatistics") {
      setEmploymentStatisticsErr("");
      if (value) {
        if (!urlPatternValidation(value)) {
          setEmploymentStatisticsErr(
            "Please fill the valid Employment Statistics Link"
          );
        }
      }
    }
  };

  useEffect(() => {
    if (getOneBSchoolProgramData !== null) {
      setState({
        ...state,
        id: getOneBSchoolProgramData.id,
        visaFees: getOneBSchoolProgramData.visaFees,
        programName: getOneBSchoolProgramData.programName,
        admissionsRequirementLink:
          getOneBSchoolProgramData.admissionsRequirementLink,
        applicationFees: getOneBSchoolProgramData.applicationFees,
        assignment: getOneBSchoolProgramData.assignment,
        averageAge: getOneBSchoolProgramData.averageAge,
        averageGmat: getOneBSchoolProgramData.averageGMAT,
        averageWorkExperience: getOneBSchoolProgramData.averageWorkExperience,
        avgWorkExp: getOneBSchoolProgramData.avgWorkExp,
        bachelorDegree: getOneBSchoolProgramData.bachelorDegree,
        booksAndComputer: getOneBSchoolProgramData.booksAndComputer,
        bsAreaOfSpecialization: getOneBSchoolProgramData.bsAreaOfSpecialisation,
        bsDegree: getOneBSchoolProgramData.bsDegree,
        bSchoolId: getOneBSchoolProgramData.bschoolId,
        classProfile: getOneBSchoolProgramData.classProfile,
        classSize: getOneBSchoolProgramData.classSize,
        combo: getOneBSchoolProgramData.combo,
        costOfAttendance: getOneBSchoolProgramData.costOfAttendance,
        costOfAttendanceAndTuitionFeesLink:
          getOneBSchoolProgramData.costOfAttendanceAndTuitionFeesLink,
        courseDuration: getOneBSchoolProgramData.courseDuration,
        courseStartDate: getOneBSchoolProgramData.courseStartDate,
        dataVerifiedDate: getOneBSchoolProgramData.dataVerifiedDate,
        decisionReleaseDate: getOneBSchoolProgramData.decisionReleaseDate,
        durationMonth: getOneBSchoolProgramData.durationMonth,
        durationYear: getOneBSchoolProgramData.durationYear,
        earlyActionDateTo: getOneBSchoolProgramData.earlyActionDateTo,
        earlyActionOpenDate: getOneBSchoolProgramData.earlyActionOpenDate,
        employmentStatistics: getOneBSchoolProgramData.employmentStatistics,
        examsRequired: getOneBSchoolProgramData.examRequired,
        finalDecisionDate: getOneBSchoolProgramData.finalDecisionDate,
        FTRanking: getOneBSchoolProgramData.ftranking,
        GPARequirement: getOneBSchoolProgramData.gparequirement,
        housingFood: getOneBSchoolProgramData.housingAndFood,
        intakeTerm: getOneBSchoolProgramData.intakeTerm,
        intakeYear: getOneBSchoolProgramData.intakeYear,
        interviewDate: getOneBSchoolProgramData.interviewDate,
        livingCost: getOneBSchoolProgramData.livingCost,
        medicalInsurance: getOneBSchoolProgramData.medicalInsurance,
        minWorkExp: getOneBSchoolProgramData.minWorkExp,
        miscellaneous: getOneBSchoolProgramData.miscellaneous,
        nameOfApplicationDeadlineCalledAs:
          getOneBSchoolProgramData.nameOfApplicationDeadLineCalledAs,
        nameOfDecisionDateCalledAs:
          getOneBSchoolProgramData.nameOfDecisionDateCalledAS,
        nameOfFinalDecisionDate:
          getOneBSchoolProgramData.nameOfFinalDecisionDate,
        nameOfTheTerm: getOneBSchoolProgramData.nameOfTheTerm,
        PercentageOfInternationalStudents:
          getOneBSchoolProgramData.percentageOfInternationalStudents,
        percentageOfMen: getOneBSchoolProgramData.percentageOfMen,
        percentageOfWomen: getOneBSchoolProgramData.percentageOfWomen,
        personalExpense: getOneBSchoolProgramData.personalExpense,
        programCategory: getOneBSchoolProgramData.programCategory,
        programLink: getOneBSchoolProgramData.programLink,
        programName: getOneBSchoolProgramData.programName,
        programType: getOneBSchoolProgramData.programType,
        rangeForWorkExp: getOneBSchoolProgramData.rangeForWorkExp,
        registrationLink: getOneBSchoolProgramData.registrationLink,
        scholarship: getOneBSchoolProgramData.scholarShip,
        springIntake: getOneBSchoolProgramData.springInTake,
        standardizedTests: getOneBSchoolProgramData.standardizedTest,
        stemDesigned: getOneBSchoolProgramData.stemDesignated,
        totalCostOfAttendance: getOneBSchoolProgramData.totalCostOfAttendance,
        transportation: getOneBSchoolProgramData.transportation,
        tuitionFee: getOneBSchoolProgramData.tuitionFee,
        visaFees: getOneBSchoolProgramData.visaFees,
      });
    }
  }, [getOneBSchoolProgramData]);

  const handleSave = () => {
    isEmptyString(state.programName)
      ? setProgramNameErr("Please fill the Program Name")
      : setProgramNameErr("");
    isEmptyString(state.intakeTerm)
      ? setIntakeTermErr("Please fill the Intake Term")
      : setIntakeTermErr("");
    isEmptyString(state.tuitionFee)
      ? setTuitionFeeErr("Please fill the Tuition Fee")
      : setTuitionFeeErr("");

    isEmptyString(state.costOfAttendance)
      ? setAttendanceErr("Please fill the Cost Of Attendance")
      : setAttendanceErr("");

    isEmptyString(state.courseStartDate)
      ? setCourseStartDateErr("Please fill the Course Start Date")
      : setCourseStartDateErr("");
    isEmptyString(state.programLink)
      ? setProgramLinkErr("Please fill the Program Link")
      : !urlPatternValidation(state.programLink)
      ? setProgramLinkErr("Please fill the valid Program Link")
      : setProgramLinkErr("");
    isEmptyString(state.durationYear)
      ? setDurationYearErr("Please fill the Duration Year")
      : setDurationYearErr("");
    isEmptyString(state.durationMonth)
      ? setDurationMonthErr("Please fill the Duration Month")
      : setDurationMonthErr("");
    isEmptyString(state.intakeYear)
      ? setIntakeYearErr("Please fill the Intake Year")
      : setIntakeYearErr("");
    isEmptyString(state.programType)
      ? setProgramTypeErr("Please fill the Program Type")
      : setProgramTypeErr("");
    isEmptyString(state.GPARequirement)
      ? setGAPRequirementErr("Please fill the GPA Requirement")
      : setGAPRequirementErr("");
    isEmptyString(state.examsRequired)
      ? setExamsRequiredErr("Please fill the Exams Required")
      : setExamsRequiredErr("");
    isEmptyString(state.stemDesigned)
      ? setStemDesignatedErr("Please fill the Stem Designed")
      : setStemDesignatedErr("");
    isEmptyString(state.combo)
      ? setComboErr("Please fill the Combo")
      : setComboErr("");
    isEmptyString(state.nameOfApplicationDeadlineCalledAs)
      ? setApplicationDeadlineErr(
          "Please fill the Name of Application Deadline Called As"
        )
      : setApplicationDeadlineErr("");
    isEmptyString(state.nameOfDecisionDateCalledAs)
      ? setDecisionDateCalledAsErr(
          "Please fill the Name of the Decision Date Called As"
        )
      : setDecisionDateCalledAsErr("");
    isEmptyString(state.nameOfFinalDecisionDate)
      ? setFinalDecisionDateErr("Please fill the Final Decision Date")
      : setFinalDecisionDateErr("");
    if (!isEmptyString(state.registrationLink)) {
      !urlPatternValidation(state.registrationLink)
        ? setRegistrationLinkErr("Please fill the valid Registration Link")
        : setRegistrationLinkErr("");
    } else {
      setRegistrationLinkErr("");
    }
    if (!isEmptyString(state.admissionsRequirementLink)) {
      !urlPatternValidation(state.admissionsRequirementLink)
        ? setAdmissionsRequirementLinkErr(
            "Please fill the valid Admissions Requirement Link"
          )
        : setAdmissionsRequirementLinkErr("");
    } else {
      setAdmissionsRequirementLinkErr("");
    }
    if (!isEmptyString(state.costOfAttendanceAndTuitionFeesLink)) {
      !urlPatternValidation(state.costOfAttendanceAndTuitionFeesLink)
        ? setCostOfTuitionAttendanceErr(
            "Please fill the valid Cost Of Attendance And Tuition Fees Link"
          )
        : setCostOfTuitionAttendanceErr("");
    } else {
      setCostOfTuitionAttendanceErr("");
    }

    if (!isEmptyString(state.classProfile)) {
      !urlPatternValidation(state.classProfile)
        ? setClassProfileErr("Please fill the valid Class Profile Link")
        : setClassProfileErr("");
    } else {
      setClassProfileErr("");
    }

    if (!isEmptyString(state.employmentStatistics)) {
      !urlPatternValidation(state.employmentStatistics)
        ? setEmploymentStatisticsErr(
            "Please fill the valid Employment Statistics Link"
          )
        : setEmploymentStatisticsErr("");
    } else {
      setEmploymentStatisticsErr("");
    }

    if (
      !isEmptyString(state.programName) &&
      !isEmptyString(state.intakeTerm) &&
      !isEmptyString(state.tuitionFee) &&
      !isEmptyString(state.costOfAttendance) &&
      !isEmptyString(state.courseStartDate) &&
      !isEmptyString(state.programLink) &&
      !isEmptyString(state.durationYear) &&
      !isEmptyString(state.durationMonth) &&
      !isEmptyString(state.intakeYear) &&
      !isEmptyString(state.programType) &&
      !isEmptyString(state.GPARequirement) &&
      !isEmptyString(state.examsRequired) &&
      !isEmptyString(state.stemDesigned) &&
      !isEmptyString(state.combo) &&
      !isEmptyString(state.nameOfApplicationDeadlineCalledAs) &&
      !isEmptyString(state.nameOfDecisionDateCalledAs) &&
      !isEmptyString(state.nameOfFinalDecisionDate) &&
      isEmptyString(registrationLinkErr) &&
      isEmptyString(admissionsRequirementLinkErr) &&
      isEmptyString(costOfTuitionAttendanceErr) &&
      isEmptyString(programLinkErr) &&
      isEmptyString(registrationLinkErr) &&
      isEmptyString(admissionsRequirementLinkErr) &&
      isEmptyString(costOfTuitionAttendanceErr) &&
      isEmptyString(employmentStatisticsErr) &&
      isEmptyString(classProfileErr)
    ) {
      if (props.location.state?.data?.id) {
        let obj = {
          bschoolId: state.bSchoolId,
          programName: state.programName,
          intakeTerm: state.intakeTerm,
          id: state.id,
          intakeYear: state.intakeYear,
          programType: state.programType,
          courseStartDate: state.courseStartDate,
          programLink: state.programLink,
          registrationLink: state.registrationLink,
          avgWorkExp:
            Number(state.avgWorkExp) === 0 ? null : Number(state.avgWorkExp),
          minWorkExp: state.minWorkExp,
          rangeForWorkExp: state.rangeForWorkExp,
          percentageOfWomen:
            Number(state.percentageOfWomen) === 0
              ? null
              : Number(state.percentageOfWomen),
          percentageOfMen:
            Number(state.percentageOfWomen) === 0
              ? null
              : Number(state.percentageOfWomen),
          percentageOfInternationalStudents:
            state.PercentageOfInternationalStudents,
          classSize: state.classSize,
          averageAge:
            Number(state.averageAge) === 0 ? null : Number(state.averageAge),
          classProfile: state.classProfile,
          employmentStatistics: state.employmentStatistics,
          tuitionFee:
            Number(state.tuitionFee) === 0 ? null : Number(state.tuitionFee),
          costOfAttendance: state.costOfAttendance,
          costOfAttendanceAndTuitionFeesLink:
            state.costOfAttendanceAndTuitionFeesLink,
          admissionsRequirementLink: state.admissionsRequirementLink,
          bachelorDegree: state.bachelorDegree,
          examRequired: state.examsRequired,
          nameOfApplicationDeadLineCalledAs:
            state.nameOfApplicationDeadlineCalledAs,
          nameOfDecisionDateCalledAS: state.nameOfDecisionDateCalledAs,
          nameOfFinalDecisionDate: state.nameOfFinalDecisionDate,
          nameOfTheTerm: state.nameOfTheTerm,
          springInTake: state.springIntake,
          earlyActionOpenDate: state.earlyActionOpenDate,
          interviewDate: state.interviewDate,
          decisionReleaseDate: state.decisionReleaseDate,
          finalDecisionDate: state.finalDecisionDate,
          earlyActionDateTo: state.earlyActionDateTo,
          stemDesignated: state.stemDesigned,
          combo: state.combo,
          standardizedTest: state.standardizedTests,
          dataVerifiedDate: state.dataVerifiedDate,
          averageWorkExperience:
            Number(state.averageWorkExperience) === 0
              ? null
              : Number(state.averageWorkExperience),
          averageGMAT:
            Number(state.averageGmat) === 0 ? null : Number(state.averageGmat),
          assignment: state.assignment,
          durationMonth:
            Number(state.durationMonth) === 0
              ? null
              : Number(state.durationMonth),
          durationYear: state.durationYear,
          bsDegree: state.bsDegree?.name
            ? state.bsDegree?.name
            : state.bsDegree,
          programCategory: state.programCategory?.name
            ? state.programCategory?.name
            : state.programCategory,
          bsAreaOfSpecialisation: state.bsAreaOfSpecialization?.name
            ? state.bsAreaOfSpecialization?.name
            : state.bsAreaOfSpecialization,
          housingAndFood:
            Number(state.housingFood) === 0 ? null : Number(state.housingFood),
          booksAndComputer:
            Number(state.booksAndComputer) === 0
              ? null
              : Number(state.booksAndComputer),
          medicalInsurance:
            Number(state.medicalInsurance) === 0
              ? null
              : Number(state.medicalInsurance),
          visaFees:
            Number(state.visaFees) === 0 ? null : Number(state.visaFees),
          transportation:
            Number(state.transportation) === 0
              ? null
              : Number(state.transportation),
          miscellaneous:
            Number(state.miscellaneous) === 0
              ? null
              : Number(state.miscellaneous),
          personalExpense:
            Number(state.personalExpense) === 0
              ? null
              : Number(state.personalExpense),
          applicationFees:
            Number(state.applicationFees) === 0
              ? null
              : Number(state.applicationFees),
          courseDuration:
            Number(state.courseDuration) === 0
              ? null
              : Number(state.courseDuration),
          scholarShip:
            Number(state.scholarship) === 0 ? null : Number(state.scholarship),
          totalCostOfAttendance:
            Number(state.totalCostOfAttendance) === 0
              ? null
              : Number(state.totalCostOfAttendance),
          livingCost:
            Number(state.livingCost) === 0 ? null : Number(state.livingCost),
          gparequirement: state.GPARequirement,
          ftranking:
            Number(state.FTRanking) === 0 ? null : Number(state.FTRanking),
        };
        dispatch(
          createBSchoolProgram(obj, (res) => {
            if (res.success) {
              setSnack({
                message: "Updated Successfully!",
                open: true,
                color: "success",
              });
            } else {
              setSnack({
                message: res.data.message,
                open: true,
                color: "error",
              });
            }
          })
        );
      } else {
        let obj = {
          bschoolId: props.location.state.b_schoolDetail.id,
          programName: state.programName,
          intakeTerm: state.intakeTerm,
          intakeYear: state.intakeYear,
          programType: state.programType,
          courseStartDate: state.courseStartDate,
          programLink: state.programLink,
          registrationLink: state.registrationLink,
          avgWorkExp:
            Number(state.avgWorkExp) === 0 ? null : Number(state.avgWorkExp),
          minWorkExp: state.minWorkExp,
          rangeForWorkExp: state.rangeForWorkExp,
          percentageOfWomen:
            Number(state.percentageOfWomen) === 0
              ? null
              : Number(state.percentageOfWomen),
          percentageOfMen:
            Number(state.percentageOfWomen) === 0
              ? null
              : Number(state.percentageOfWomen),
          percentageOfInternationalStudents:
            state.PercentageOfInternationalStudents,
          classSize: state.classSize,
          averageAge:
            Number(state.averageAge) === 0 ? null : Number(state.averageAge),
          classProfile: state.classProfile,
          employmentStatistics: state.employmentStatistics,
          tuitionFee:
            Number(state.tuitionFee) === 0 ? null : Number(state.tuitionFee),
          costOfAttendance: state.costOfAttendance,
          costOfAttendanceAndTuitionFeesLink:
            state.costOfAttendanceAndTuitionFeesLink,
          admissionsRequirementLink: state.admissionsRequirementLink,
          bachelorDegree: state.bachelorDegree,
          examRequired: state.examsRequired,
          nameOfApplicationDeadLineCalledAs:
            state.nameOfApplicationDeadlineCalledAs,
          nameOfDecisionDateCalledAS: state.nameOfDecisionDateCalledAs,
          nameOfFinalDecisionDate: state.nameOfFinalDecisionDate,
          nameOfTheTerm: state.nameOfTheTerm,
          springInTake: state.springIntake,
          earlyActionOpenDate: state.earlyActionOpenDate,
          interviewDate: state.interviewDate,
          decisionReleaseDate: state.decisionReleaseDate,
          finalDecisionDate: state.finalDecisionDate,
          earlyActionDateTo: state.earlyActionDateTo,
          stemDesignated: state.stemDesigned,
          combo: state.combo,
          standardizedTest: state.standardizedTests,
          dataVerifiedDate: state.dataVerifiedDate,
          averageWorkExperience:
            Number(state.averageWorkExperience) === 0
              ? null
              : Number(state.averageWorkExperience),
          averageGMAT:
            Number(state.averageGmat) === 0 ? null : Number(state.averageGmat),
          assignment: state.assignment,
          durationMonth:
            Number(state.durationMonth) === 0
              ? null
              : Number(state.durationMonth),
          durationYear: state.durationYear,
          bsDegree: state.bsDegree?.name
            ? state.bsDegree?.name
            : state.bsDegree,
          programCategory: state.programCategory?.name
            ? state.programCategory?.name
            : state.programCategory,
          bsAreaOfSpecialisation: state.bsAreaOfSpecialization?.name
            ? state.bsAreaOfSpecialization?.name
            : state.bsAreaOfSpecialization,
          housingAndFood:
            Number(state.housingFood) === 0 ? null : Number(state.housingFood),
          booksAndComputer:
            Number(state.booksAndComputer) === 0
              ? null
              : Number(state.booksAndComputer),
          medicalInsurance:
            Number(state.medicalInsurance) === 0
              ? null
              : Number(state.medicalInsurance),
          visaFees:
            Number(state.visaFees) === 0 ? null : Number(state.visaFees),
          transportation:
            Number(state.transportation) === 0
              ? null
              : Number(state.transportation),
          miscellaneous:
            Number(state.miscellaneous) === 0
              ? null
              : Number(state.miscellaneous),
          personalExpense:
            Number(state.personalExpense) === 0
              ? null
              : Number(state.personalExpense),
          applicationFees:
            Number(state.applicationFees) === 0
              ? null
              : Number(state.applicationFees),
          courseDuration:
            Number(state.courseDuration) === 0
              ? null
              : Number(state.courseDuration),
          scholarShip:
            Number(state.scholarship) === 0 ? null : Number(state.scholarship),
          totalCostOfAttendance:
            Number(state.totalCostOfAttendance) === 0
              ? null
              : Number(state.totalCostOfAttendance),
          livingCost:
            Number(state.livingCost) === 0 ? null : Number(state.livingCost),
          gparequirement: state.GPARequirement,
          ftranking:
            Number(state.FTRanking) === 0 ? null : Number(state.FTRanking),
        };
        dispatch(
          createBSchoolProgram(obj, (res) => {
            if (res.success) {
              setSnack({
                message: "Saved Successfully!",
                open: true,
                color: "success",
              });
              setState({
                id: "",
                programName: "",
                intakeTerm: "",
                intakeYear: "",
                programType: "",
                courseStartDate: "",
                programLink: "",
                registrationLink: "",
                avgWorkExp: "",
                minWorkExp: "",
                rangeForWorkExp: "",
                percentageOfWomen: "",
                percentageOfMen: "",
                PercentageOfInternationalStudents: "",
                classSize: "",
                averageAge: "",
                classProfile: "",
                employmentStatistics: "",
                tuitionFee: "",
                costOfAttendance: "",
                costOfAttendanceAndTuitionFeesLink: "",
                admissionsRequirementLink: "",
                bachelorDegree: "",
                GPARequirement: "",
                examsRequired: "",
                nameOfApplicationDeadlineCalledAs: "",
                nameOfDecisionDateCalledAs: "",
                nameOfFinalDecisionDate: "",
                nameOfTheTerm: "",
                springIntake: "",
                stemDesigned: "",
                combo: "",
                standardizedTests: "",
                FTRanking: "",
                averageWorkExperience: "",
                averageGmat: "",
                assignment: "",
                durationMonth: "",
                durationYear: "",
                housingFood: "",
                booksAndComputer: "",
                medicalInsurance: "",
                visaFees: "",
                transportation: "",
                miscellaneous: "",
                personalExpense: "",
                applicationFees: "",
                courseDuration: "",
                scholarship: "",
                totalCostOfAttendance: "",
                livingCost: "",
                earlyActionOpenDate: null,
                interviewDate: null,
                decisionReleaseDate: null,
                finalDecisionDate: null,
                earlyActionDateTo: null,
                dataVerifiedDate: null,
                bsDegree: null,
                programCategory: "",
                bsAreaOfSpecialization: "",
                bSchoolId: "",
                yearOption: [],
                currentYear: new Date().getFullYear(),
              });
            } else {
              setSnack({
                message: res.data.message,
                open: true,
                color: "error",
              });
            }
          })
        );
      }
    }
  };

  const filter = createFilterOptions();

  const handleDateChange = (val, e, name) => {
    setState({
      ...state,
      [name]: e,
    });
  };

  useEffect(() => {
    if (props.location.state?.data?.id?.length > 0) {
      dispatch(getOneBSchoolProgram(props.location.state?.data?.id));
    }
  }, []);

  useEffect(() => {
    dispatch(bSchoolDegree());
    dispatch(bSchoolProgramCategory());
    dispatch(bSchoolProgramAreaOfSpecialization());
    for (let i = 0; i < 10; i++) {
      let arr = state.currentYear + i;
      state.yearOption.push({ title: arr });
    }
  }, []);

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div style={{ display: "flex", flexDirection: "row", margin: "10px" }}>
          <img
            src={BackButton}
            style={{ cursor: "pointer", marginTop: "-10px" }}
            onClick={() => props.history.goBack()}
          />
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Typography
              style={{
                cursor: "pointer",
                fontWeight: "600",
                marginLeft: "10px",
              }}
            >
              Back
            </Typography>
          </Breadcrumbs>
        </div>
        <AppBar position="static" color="default">
          <GSContainer>
            <GSTitle>
              {" "}
              {state.id.length > 0 ? "Edit" : "Add"} B - School Program
            </GSTitle>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={4}>
                {textField.map((item) => {
                  return (
                    <Grid item md={3}>
                      {item.type === "Date" ? (
                        <KeyboardDatePicker
                          margin="normal"
                          id="date-picker-dialog"
                          label={item.label}
                          format="yyyy-MM-dd"
                          value={item.value || null}
                          name={item.name}
                          onChange={(newValue, e) =>
                            handleDateChange(newValue, e, item.name)
                          }
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      ) : item.type === "select" ? (
                        <FormControl
                          fullWidth
                          variant="standard"
                          error={intakeYearErr}
                        >
                          <InputLabel
                            id="demo-simple-select-label"
                            shrink={item.value ? true : false}
                          >
                            {item.label} *
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={item.value}
                            name={item.name}
                            label={item.label}
                            onChange={(e) => onChange(e)}
                          >
                            {item.option.map((menuItem, idx) => (
                              <MenuItem value={menuItem.title} key={idx}>
                                {menuItem.title}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            <div style={{ color: "#f44336" }}>
                              {intakeYearErr}
                            </div>
                          </FormHelperText>
                        </FormControl>
                      ) : (
                        <TextField
                          name={item.name}
                          value={item.value || ""}
                          label={item.label}
                          onChange={onChange}
                          fullWidth
                          type={item.type}
                          InputLabelProps={{
                            shrink: item.value
                              ? true
                              : item.value === 0
                              ? true
                              : item.value === ""
                              ? false
                              : false,
                          }}
                          required={item.required}
                          helperText={
                            item.name === "programName"
                              ? programNameErr
                              : null || item.name === "intakeTerm"
                              ? intakeTermErr
                              : null || item.name === "tuitionFee"
                              ? tuitionFeeErr
                              : null || item.name === "costOfAttendance"
                              ? attendanceErr
                              : null || item.name === "courseStartDate"
                              ? courseStateDateErr
                              : null || item.name === "programLink"
                              ? programLinkErr
                              : null || item.name === "durationYear"
                              ? durationYearErr
                              : null || item.name === "durationMonth"
                              ? durationMonthErr
                              : null || item.name === "programType"
                              ? programTypeErr
                              : null || item.name === "GPARequirement"
                              ? gpaRequirementErr
                              : null || item.name === "examsRequired"
                              ? examsRequiredErr
                              : null ||
                                item.name ===
                                  "nameOfApplicationDeadlineCalledAs"
                              ? applicationDeadlineErr
                              : null ||
                                item.name === "nameOfDecisionDateCalledAs"
                              ? decisionDateCalledAsErr
                              : null || item.name === "nameOfFinalDecisionDate"
                              ? finalDecisionDateErr
                              : null || item.name === "stemDesigned"
                              ? stemDesignatedErr
                              : null || item.name === "combo"
                              ? comboErr
                              : null || item.name === "registrationLink"
                              ? registrationLinkErr
                              : null ||
                                item.name ===
                                  "costOfAttendanceAndTuitionFeesLink"
                              ? costOfTuitionAttendanceErr
                              : null ||
                                item.name === "admissionsRequirementLink"
                              ? admissionsRequirementLinkErr
                              : null || item.name === "intakeYear"
                              ? intakeYearErr
                              : null || item.name === "classProfile"
                              ? classProfileErr
                              : null || item.name === "employmentStatistics"
                              ? employmentStatisticsErr
                              : null
                          }
                          error={
                            item.name === "programName" && programNameErr !== ""
                              ? true
                              : false ||
                                (item.name === "intakeTerm" &&
                                  intakeTermErr !== "")
                              ? true
                              : false ||
                                (item.name === "tuitionFee" &&
                                  tuitionFeeErr !== "")
                              ? true
                              : false ||
                                (item.name === "costOfAttendance" &&
                                  attendanceErr !== "")
                              ? true
                              : false ||
                                (item.name === "courseStartDate" &&
                                  courseStateDateErr !== "")
                              ? true
                              : false ||
                                (item.name === "programLink" &&
                                  programLinkErr !== "")
                              ? true
                              : false ||
                                (item.name === "durationYear" &&
                                  durationYearErr !== "")
                              ? true
                              : false ||
                                (item.name === "durationMonth" &&
                                  durationMonthErr !== "")
                              ? true
                              : false ||
                                (item.name === "GPARequirement" &&
                                  gpaRequirementErr !== "")
                              ? true
                              : false ||
                                (item.name === "programType" &&
                                  programTypeErr !== "")
                              ? true
                              : false ||
                                (item.name === "examsRequired" &&
                                  examsRequiredErr !== "")
                              ? true
                              : false ||
                                (item.name ===
                                  "nameOfApplicationDeadlineCalledAs" &&
                                  applicationDeadlineErr !== "")
                              ? true
                              : false ||
                                (item.name === "nameOfDecisionDateCalledAs" &&
                                  decisionDateCalledAsErr !== "")
                              ? true
                              : false ||
                                (item.name === "nameOfFinalDecisionDate" &&
                                  finalDecisionDateErr !== "")
                              ? true
                              : false ||
                                (item.name === "stemDesigned" &&
                                  stemDesignatedErr !== "")
                              ? true
                              : false ||
                                (item.name === "combo" && comboErr !== "")
                              ? true
                              : false ||
                                (item.name === "registrationLink" &&
                                  registrationLinkErr !== "")
                              ? true
                              : false ||
                                (item.name ===
                                  "costOfAttendanceAndTuitionFeesLink" &&
                                  costOfTuitionAttendanceErr !== "")
                              ? true
                              : false ||
                                (item.name === "admissionsRequirementLink" &&
                                  admissionsRequirementLinkErr !== "")
                              ? true
                              : false ||
                                (item.name === "intakeYear" &&
                                  intakeYearErr !== "")
                              ? true
                              : false ||
                                (item.name === "classProfile" &&
                                  classProfileErr !== "")
                              ? true
                              : false ||
                                (item.name === "employmentStatistics" &&
                                  employmentStatisticsErr !== "")
                              ? true
                              : false
                          }
                          onKeyDown={
                            item.name === "programName" ||
                            item.name === "intakeTerm" ||
                            item.name === "programType" ||
                            item.name === "nameOfApplicationDeadlineCalledAs" ||
                            item.name === "nameOfDecisionDateCalledAs" ||
                            item.name === "nameOfFinalDecisionDate" ||
                            item.name === "nameOfTheTerm" ||
                            item.name === "springIntake" ||
                            item.name === "stemDesigned" ||
                            item.name === "combo"
                              ? (e) => isOnlyNumber(e) && e.preventDefault()
                              : null
                          }
                        />
                      )}
                    </Grid>
                  );
                })}
                <Grid item md={3}>
                  <Autocomplete
                    value={state.bsDegree}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setState({
                          ...state,
                          bsDegree: {
                            name: newValue,
                          },
                        });
                      } else if (newValue && newValue.inputValue) {
                        setState({
                          ...state,
                          bsDegree: {
                            name: newValue.inputValue,
                          },
                        });
                      } else {
                        setState({ ...state, bsDegree: newValue });
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;

                      const isExisting = options.some(
                        (option) => inputValue === option.name
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          name: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={bSchoolDegreeData}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.name;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.name}</li>
                    )}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Degree"
                        variant="standard"
                        fullWidth
                        color="primary"
                        size="small"
                        onKeyDown={(e) => isOnlyNumber(e) && e.preventDefault()}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={3}>
                  <Autocomplete
                    value={state.programCategory}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setState({
                          ...state,
                          programCategory: {
                            name: newValue,
                          },
                        });
                      } else if (newValue && newValue.inputValue) {
                        setState({
                          ...state,
                          programCategory: {
                            name: newValue.inputValue,
                          },
                        });
                        setProgramCategoryErr("");
                      } else {
                        setState({ ...state, programCategory: newValue });
                        setProgramCategoryErr("");
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;

                      const isExisting = options.some(
                        (option) => inputValue === option.name
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          name: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={bSchoolProgramCategoryData}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.name;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.name}</li>
                    )}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Program Category"
                        variant="standard"
                        fullWidth
                        color="primary"
                        size="small"
                        onKeyDown={(e) => isOnlyNumber(e) && e.preventDefault()}
                        helperText={programCategoryErr}
                        error={programCategoryErr === "" ? false : true}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={3}>
                  <Autocomplete
                    value={state.bsAreaOfSpecialization}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setState({
                          ...state,
                          bsAreaOfSpecialization: {
                            name: newValue,
                          },
                        });
                      } else if (newValue && newValue.inputValue) {
                        setState({
                          ...state,
                          bsAreaOfSpecialization: {
                            name: newValue.inputValue,
                          },
                        });
                      } else {
                        setState({
                          ...state,
                          bsAreaOfSpecialization: newValue,
                        });
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;

                      const isExisting = options.some(
                        (option) => inputValue === option.name
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          name: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={bSchoolAreaOfSpecialization}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.name;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.name}</li>
                    )}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Area Of Specialization"
                        variant="standard"
                        fullWidth
                        color="primary"
                        size="small"
                        onKeyDown={(e) => isOnlyNumber(e) && e.preventDefault()}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
            <br />
            <br />
            <GSTitle> Add B - School Compare</GSTitle>
            <Grid container spacing={4}>
              {bsCompareFields.map((item) => {
                return (
                  <Grid item md={3}>
                    <TextField
                      name={item.name}
                      value={item.value || ""}
                      label={item.label}
                      onChange={onChange}
                      fullWidth
                      type={item.type}
                      InputLabelProps={{
                        shrink: item.value === null ? false : true,
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>{" "}
            <Grid item md={12}>
              <Grid
                container
                justifyContent="center"
                alignContent="center"
                style={{ paddingTop: 30, paddingBottom: 10 }}
              >
                <Grid item md={1}>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    className={classes.btnStyles}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </GSContainer>
        </AppBar>
        <MySnackBar
          snackOpen={snack.open}
          snackVariant={snack.color}
          snackMsg={snack.message}
          onClose={() =>
            setSnack({
              open: false,
              message: "",
              color: "",
            })
          }
        />
      </div>
    </React.Fragment>
  );
}
