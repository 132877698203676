import { Table, TableBody, TableRow } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { lms_course_taken } from "../../../Component/RoutePaths";
import {
  BlueCell,
  BodyCell,
  Head,
  HeadCell,
  HeadInline,
} from "../../Assets/StyledTableComponents";

const columns = ["No.", "User Name", "Email", "Rating given"];

function TableComponent({ overviewData }) {
  const history = useHistory();

  const handleViewUser = (studentId, productId) => {
    history.push(
      `${lms_course_taken}?studentId=${studentId}&productId=${productId}&course_taken=feedbackGiven`
    );
  };

  return (
    <React.Fragment>
      <Table stickyHeader>
        <Head>
          <TableRow>
            {columns.map((item, index) => (
              <HeadCell
                className={(index === 3 || index === 0) && "table_center_align"}
              >
                <HeadInline>{item}</HeadInline>
              </HeadCell>
            ))}
          </TableRow>
        </Head>
        <TableBody>
          {overviewData && overviewData.length !== 0 ? (
            overviewData.map((item, i) => {
              return (
                <TableRow>
                  <BodyCell className={"table_center_align"}>{i + 1}</BodyCell>
                  <BodyCell>{item.userName}</BodyCell>
                  <BlueCell
                    onClick={() =>
                      handleViewUser(item.studentId, item.productId)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {item.email}
                  </BlueCell>
                  <BodyCell className={"table_center_align"}>
                    {item.ratingGiven}
                  </BodyCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <BodyCell className={"table_center_align"} colSpan={4}>
                {"No ratings given for this Video"}
              </BodyCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
export default TableComponent;
