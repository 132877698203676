import { Check, ChevronLeft, ChevronRight, Edit } from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import SortIcon from "@material-ui/icons/CompareArrows";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import DescriptionIcon from "@material-ui/icons/Description";
import FirstPage from "@material-ui/icons/FirstPage";
import ExportIcon from "@material-ui/icons/GetApp";
import LastPage from "@material-ui/icons/LastPage";
import SearchIcon from "@material-ui/icons/SearchSharp";
import FilterIcon from "@material-ui/icons/Tune";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import { Add } from "@mui/icons-material";
import ThumbUp from "@material-ui/icons/ThumbUp";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { drivePath } from "../../RoutePaths";
import { TextField } from "@mui/material";
import { Autocomplete, Pagination } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import Controls from "../../Utils/controls/Controls";
import {
  driveTrackingSheetDownload,
  getAllDriveList,
  getRoundUpdateData,
  updateRoundUpdatedData,
  driveFilter,
} from "../../../Actions/WallActions";
import { textToDownloadFile } from "../../Utils/Helpers";
import Notification from "../../Utils/Notification";
import Loader from "../../../Lms/Utils/Loader";
import {
  InputAdornment,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

function DriveTracking() {
  const [tableData, setTableData] = useState([]);
  const [options, setOptions] = useState([]);
  const [page, setPage] = useState(0);
  const [filterPage, setFilterPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalFilterPages, setTotalFilterPages] = useState(0);
  const [searchValue, setSearchValue] = useState();
  const [isFirstTimeLoading, setIsFirstTimeLoading] = useState(true);
  const [Stage, setStage] = useState("all");
  const [studentRegistered, setStudentRegistered] = useState();
  const [pStage, setPStage] = useState("");
  const [noOfStudentRegister, setNoOfStudentRegister] = useState(0);
  const [currentRoundName, setCurrentRoundName] = useState(null);
  const [updateDate, setUpdateDate] = useState(null);
  const [offerReleased, setOfferReleased] = useState(null);
  const [roundUpdate, setRoundUpdate] = useState(null);
  const [filter, setFilter] = useState(false);
  const [offerValue, setOfferValue] = useState("");
  const [cRName, setCRName] = useState("");
  const [date, setDate] = useState(null);
  const [roundMessage, setRoundMessage] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const useStyles = makeStyles((theme) => ({
    searchInput: {
      width: "50%",
      marginLeft: 30,
    },
  }));

  const {
    loading,
    allDriveList,
    allUpdateRound,
    updatedData,
    filterData,
  } = useSelector((state) => state.driveTrackingReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const filterPayload = {
    stage: pStage,
    noOfStudentRegister: noOfStudentRegister,
    currentRoundName: currentRoundName,
    updateDate: updateDate,
    offerReleased: offerReleased,
    roundUpdate: roundUpdate,
  };

  let columns = [
    {
      title: "Date of Drive",
      field: "driveDate",
      filtering: true,
      editable: false,
      type: "date",
      cellStyle: {
        textAlign: "center",
      },
      dateSetting: {
        locale: "en-GB",
        format: "dd-MM-yyyy",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Drive Name",
      field: "driveName",
      editable: false,
      sorting: false,
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Stage",
      field: "driveUpdatedStatus",
      editable: false,
      filtering: true,
      render: (rowData) => (
        <p
          style={{
            fontWeight: "bold",
            color:
              rowData.driveUpdatedStatus === "Good"
                ? "#008000"
                : rowData.driveUpdatedStatus === "Need Assistance"
                ? "#d80303aa"
                : rowData.driveUpdatedStatus === "Alert"
                ? "#FFBF00"
                : "#6495ED",
            borderRadius: "4px",
            textAlign: "center",
            padding: 5,
          }}
        >
          {rowData.driveUpdatedStatus}
        </p>
      ),
      filterComponent: () => (
        <Select
          id='stage'
          style={{ width: 70 }}
          value={Stage}
          onChange={(e) => {
            setStage(e.target.value);
            stageFilter(e.target.value);
          }}
        >
          <MenuItem value={"all"}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={"Good"}>Good</MenuItem>
          <MenuItem value={"Need Assistance"}>Need Assistance</MenuItem>
          <MenuItem value={"Alert"}>Alert</MenuItem>
          <MenuItem value={"Closed"}>Closed</MenuItem>
        </Select>
      ),
      cellStyle: {
        textAlign: "center",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "No of People Registered",
      field: "studentRegisteredCount",
      filtering: true,
      editable: false,
      cellStyle: {
        textAlign: "center",
      },
      filterComponent: () => (
        <Controls.Input
          style={{ width: 120 }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position='end'
                style={{ cursor: "pointer" }}
                onClick={() => clearSearch()}
              >
                <ClearIcon />
              </InputAdornment>
            ),
          }}
          defaultValue={studentRegistered}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              studentRegisteredSearch(e.target.value);
            }
          }}
        />
      ),
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Current Round Name",
      field: "currentRoundName",
      editable: false,
      sorting: false,
      filtering: true,
      cellStyle: {
        textAlign: "center",
      },
      filterComponent: () => (
        <Controls.Input
          style={{ width: 120 }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position='end'
                style={{ cursor: "pointer" }}
                onClick={() => clearSearch()}
              >
                <ClearIcon />
              </InputAdornment>
            ),
          }}
          defaultValue={cRName}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              currentRoundNameSearch(e.target.value);
            }
          }}
        />
      ),
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Updated On",
      field: "driveStatusUpdatedOn",
      type: "date",
      filtering: true,
      editable: false,
      cellStyle: {
        textAlign: "center",
      },
      dateSetting: {
        locale: "en-GB",
        format: "dd-MM-yyyy",
      },
      filterComponent: () => (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            style={{ width: 120 }}
            margin='normal'
            id='date-picker-dialog'
            format='dd/MM/yyyy'
            InputAdornmentProps={{ position: "start" }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position='end'
                  style={{ cursor: "pointer" }}
                  onClick={() => clearUpdatedDateSearch()}
                >
                  <ClearIcon />
                </InputAdornment>
              ),
            }}
            value={filter ? date : null}
            onChange={(e) => {
              setDate(e);
              updatedOnSearch(e);
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
      ),
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Round Message",
      field: "roundMessage",
      editable: false,
      sorting: false,
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Mail Sent",
      field: "emailCount",
      filtering: true,
      editable: false,
      cellStyle: {
        textAlign: "center",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "WA Sent",
      field: "whatsAppCount",
      filtering: true,
      editable: false,
      cellStyle: {
        textAlign: "center",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Name of Round 1",
      field: "wallSteps[0].roundName",
      editable: false,
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Round 1 updated On",
      field: "wallSteps[0].roundUpdatedOn",
      filtering: true,
      editable: false,
      cellStyle: {
        textAlign: "center",
      },
      type: "date",
      dateSetting: {
        locale: "en-GB",
        format: "dd-MM-yyyy",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Round 1 (qualified)",
      field: "wallSteps[0].qualifiedCount",
      editable: false,
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Rejection reason",
      field: "wallSteps[0].rejectionReason",
      editable: false,
      sorting: false,
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Name of Round 2",
      field: "wallSteps[1].roundName",
      editable: false,
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Round 2 updated On",
      field: "wallSteps[1].roundUpdatedOn",
      filtering: true,
      editable: false,
      cellStyle: {
        textAlign: "center",
      },
      type: "date",
      dateSetting: {
        locale: "en-GB",
        format: "dd-MM-yyyy",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Round 2 (qualified)",
      field: "wallSteps[1].qualifiedCount",
      editable: false,
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Rejection reason",
      field: "wallSteps[1].rejectionReason",
      editable: false,
      sorting: false,
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Name of Round 3",
      field: "wallSteps[2].roundName",
      editable: false,
      filtering: false,
      hidden: true,
      cellStyle: {
        textAlign: "center",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Round 3 updated On",
      field: "wallSteps[2].roundUpdatedOn",
      filtering: true,
      editable: false,
      hidden: true,
      cellStyle: {
        textAlign: "center",
      },
      type: "date",
      dateSetting: {
        locale: "en-GB",
        format: "dd-MM-yyyy",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Round 3 (qualified)",
      field: "wallSteps[2].qualifiedCount",
      editable: false,
      filtering: false,
      hidden: true,
      cellStyle: {
        textAlign: "center",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Rejection reason",
      field: "wallSteps[2].rejectionReason",
      editable: false,
      sorting: false,
      filtering: false,
      hidden: true,
      cellStyle: {
        textAlign: "center",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Name of Round 4",
      field: "wallSteps[3].roundName",
      editable: false,
      filtering: false,
      hidden: true,
      cellStyle: {
        textAlign: "center",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Round 4 updated On",
      field: "wallSteps[3].roundUpdatedOn",
      filtering: true,
      editable: false,
      hidden: true,
      cellStyle: {
        textAlign: "center",
      },
      type: "date",
      dateSetting: {
        locale: "en-GB",
        format: "dd-MM-yyyy",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Round 4 (qualified)",
      field: "wallSteps[3].qualifiedCount",
      editable: false,
      filtering: false,
      hidden: true,
      cellStyle: {
        textAlign: "center",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Rejection reason",
      field: "wallSteps[3].rejectionReason",
      editable: false,
      sorting: false,
      filtering: false,
      hidden: true,
      cellStyle: {
        textAlign: "center",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Name of Round 5",
      field: "wallSteps[4].roundName",
      editable: false,
      filtering: false,
      hidden: true,
      cellStyle: {
        textAlign: "center",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Round 5 updated On",
      field: "wallSteps[4].roundUpdatedOn",
      filtering: true,
      editable: false,
      hidden: true,
      cellStyle: {
        textAlign: "center",
      },
      type: "date",
      dateSetting: {
        locale: "en-GB",
        format: "dd-MM-yyyy",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Round 5 (qualified)",
      field: "wallSteps[4].qualifiedCount",
      editable: false,
      filtering: false,
      hidden: true,
      cellStyle: {
        textAlign: "center",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Rejection reason",
      field: "wallSteps[4].rejectionReason",
      editable: false,
      sorting: false,
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
      hidden: true,
      emptyValue: () => <em>--</em>,
    },
    {
      title: "No of offer released",
      field: "noOfOfferReleased",
      filtering: true,
      cellStyle: {
        textAlign: "center",
      },
      emptyValue: () => <em>--</em>,
      filterComponent: () => (
        <Controls.Input
          style={{ width: 120 }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position='end'
                style={{ cursor: "pointer" }}
                onClick={() => clearSearch()}
              >
                <ClearIcon />
              </InputAdornment>
            ),
          }}
          defaultValue={offerValue}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              offerReleasedSearch(e.target.value);
            }
          }}
        />
      ),
    },
    {
      title: "Offer released On",
      field: "offerReleasedOn",
      type: "date",
      filtering: true,
      cellStyle: {
        textAlign: "center",
      },
      dateSetting: {
        locale: "en-GB",
        format: "dd-MM-yyyy",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Round update ",
      field: "roundUpdate",
      cellStyle: {
        textAlign: "center",
      },
      emptyValue: () => <em>--</em>,
      editComponent: (props) => {
        return (
          <Autocomplete
            id='roundUpdate'
            name='roundUpdate'
            getOptionLabel={(option) => option?.roundMessage}
            options={options}
            onChange={(e, newVal) => {
              props.onChange(newVal);
            }}
            value={props.value}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label='Rounds' variant='outlined' />
            )}
          />
        );
      },
      render: (rowData) => (
        <div style={{ width: "100px" }}>
          {rowData.roundUpdate?.roundMessage}
        </div>
      ),
      filterComponent: () => (
        <Controls.Input
          style={{ width: 120 }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position='end'
                style={{ cursor: "pointer" }}
                onClick={() => clearSearch()}
              >
                <ClearIcon />
              </InputAdornment>
            ),
          }}
          defaultValue={roundMessage}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              roundUpdateSearch(e.target.value);
            }
          }}
        />
      ),
    },
    {
      title: "Round Update Date",
      field: "roundUpdatedDate",
      filtering: true,
      cellStyle: {
        textAlign: "center",
      },
      type: "date",
      dateSetting: {
        locale: "en-GB",
      },
      emptyValue: () => <em>--</em>,
    },
    {
      title: "Company follow up",
      field: "companyFollowUp",
      sorting: false,
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
      emptyValue: () => <em>--</em>,
    },
  ];

  const openResultPage = (item) => {
    history.push({
      pathname: drivePath + item.eventId,
      id: item.eventId,
    });
  };

  useEffect(() => {
    if (Stage === "all") {
      setFilter(false);
      setPage(0);
      // setSearchValue(null);
      dispatch(getAllDriveList(0, searchValue));
    }
    if (Stage !== "all") {
      setFilterPage(0);
      setSearchValue(null);
      dispatch(driveFilter(0, filterPayload));
    }
  }, [
    noOfStudentRegister,
    pStage,
    Stage,
    offerReleased,
    currentRoundName,
    date,
    roundUpdate,
  ]);

  const handleSearch = (text) => {
    setSearchValue(text);
    setPage(0);
    setCRName("");
    setDate(null);
    setStudentRegistered("");
    setOfferValue("");
    setRoundMessage("");
    setStage("all");
    setFilter(false);
    dispatch(getAllDriveList(0, text));
  };

  // For All global filters
  const clearSearch = () => {
    setCRName("");
    setDate(null);
    setStudentRegistered("");
    setOfferValue("");
    setRoundMessage("");
    setStage("all");
    setFilter(false);
    setFilterPage(0);
    setPage(0);
    dispatch(getAllDriveList(0));
  };

  // Only for "Update On" date filter
  const clearUpdatedDateSearch = () => {
    setCRName("");
    setStudentRegistered("");
    setOfferValue("");
    setRoundMessage("");
    setStage("all");
    setFilter(false);
    setFilterPage(0);
    setPage(0);
    dispatch(getAllDriveList(0));
  };

  const studentRegisteredSearch = (text) => {
    if (
      pStage !== "" ||
      offerReleased !== 0 ||
      currentRoundName !== "" ||
      updateDate !== null ||
      roundUpdate !== null
    ) {
      setPStage(null);
      setOfferReleased(null);
      setCurrentRoundName(null);
      setUpdateDate(null);
      setRoundUpdate(null);
      setStage("");
      setOfferValue("");
      setCRName("");
      setDate(null);
      setRoundMessage("");
    }
    if (text > 0) {
      setFilterPage(0);
      if (noOfStudentRegister == text) {
        setStudentRegistered(text);
        dispatch(driveFilter(filterPage, filterPayload));
      }
      setNoOfStudentRegister(parseInt(text));
      setStudentRegistered(text);
    }
  };

  const stageFilter = (text) => {
    if (
      noOfStudentRegister !== 0 ||
      offerReleased !== 0 ||
      currentRoundName !== "" ||
      updateDate !== null ||
      roundUpdate !== null
    ) {
      setNoOfStudentRegister(null);
      setOfferReleased(null);
      setCurrentRoundName(null);
      setUpdateDate(null);
      setRoundUpdate(null);
      setStudentRegistered("");
      setOfferValue("");
      setCRName("");
      setDate(null);
      setRoundMessage("");
    }
    if (text !== "") {
      setPStage(text);
    }
  };

  const offerReleasedSearch = (text) => {
    if (
      noOfStudentRegister !== 0 ||
      pStage !== "" ||
      currentRoundName !== "" ||
      updateDate !== null ||
      roundUpdate !== null
    ) {
      setNoOfStudentRegister(null);
      setPStage(null);
      setCurrentRoundName(null);
      setUpdateDate(null);
      setRoundUpdate(null);
      setStudentRegistered("");
      setStage("");
      setCRName("");
      setDate(null);
      setRoundMessage("");
    }
    if (text > 0) {
      setFilterPage(0);
      if (offerReleased == text) {
        setOfferValue(text);
        dispatch(driveFilter(filterPage, filterPayload));
      }
      setOfferReleased(parseInt(text));
      setOfferValue(text);
    }
  };

  const currentRoundNameSearch = (text) => {
    if (
      noOfStudentRegister !== 0 ||
      pStage !== "" ||
      offerReleased !== 0 ||
      updateDate !== "" ||
      roundUpdate !== null
    ) {
      setNoOfStudentRegister(null);
      setPStage(null);
      setOfferReleased(null);
      setDate(null);
      setUpdateDate(null);
      setRoundUpdate(null);
      setStudentRegistered("");
      setStage("");
      setOfferValue("");
      setRoundMessage("");
    }
    if (text !== "") {
      setFilterPage(0);
      if (currentRoundName === text) {
        setCRName(text);
        dispatch(driveFilter(filterPage, filterPayload));
      }
      setCurrentRoundName(text);
      setCRName(text);
    }
  };

  const updatedOnSearch = (text) => {
    if (
      noOfStudentRegister !== 0 ||
      pStage !== "" ||
      offerReleased !== 0 ||
      currentRoundName !== "" ||
      roundUpdate !== null
    ) {
      setNoOfStudentRegister(null);
      setPStage(null);
      setOfferReleased(null);
      setCurrentRoundName(null);
      setRoundUpdate(null);
      setStudentRegistered("");
      setStage("");
      setOfferValue("");
      setCRName("");
      setRoundMessage("");
    }
    if (text !== "") {
      setUpdateDate(text);
    }
  };

  const roundUpdateSearch = (text) => {
    if (
      noOfStudentRegister !== 0 ||
      pStage !== "" ||
      offerReleased !== 0 ||
      currentRoundName !== "" ||
      updateDate !== null
    ) {
      setNoOfStudentRegister(null);
      setPStage(null);
      setOfferReleased(null);
      setCurrentRoundName(null);
      setUpdateDate(null);
      setStudentRegistered("");
      setStage("");
      setOfferValue("");
      setCRName("");
      setDate(null);
    }
    if (text !== "") {
      if (roundUpdate === text) {
        setRoundMessage(text);
        dispatch(driveFilter(filterPage, filterPayload));
      }
      setRoundMessage(text);
      setRoundUpdate(text);
    }
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage - 1);
    dispatch(getAllDriveList(newPage - 1, searchValue));
  };

  const handleFilterPageChange = (e, newPage) => {
    setFilterPage(newPage - 1);
    dispatch(driveFilter(newPage - 1, filterPayload));
  };

  useEffect(() => {
    if (filterData) {
      if (filterData?.success && filterData?.data?.content.length !== 0) {
        setFilter(filterData?.success);
        setTableData(filterData?.data?.content);
        setTotalFilterPages(filterData?.data?.totalPages);
      }
    }
  }, [filterData, filterPage]);

  useEffect(() => {
    if (
      studentRegistered !== 0 ||
      Stage !== "" ||
      cRName !== "" ||
      offerReleased !== null ||
      date !== null ||
      roundMessage !== ""
    ) {
      setCRName("");
      setDate(null);
      setStudentRegistered("");
      setOfferValue("");
      setRoundMessage("");
      setStage("");
    }
    dispatch(getAllDriveList(page));
  }, [updatedData]);

  useEffect(() => {
    dispatch(getRoundUpdateData());
  }, []);

  useEffect(() => {
    if (allDriveList) {
      if (allDriveList.success) {
        setTableData(allDriveList?.data?.content);
        setTotalPages(allDriveList?.data?.totalPages);
      } else {
        setTableData([]);
      }
      setIsFirstTimeLoading(false);
      setFilter(false);
    }
    if (allUpdateRound) {
      if (allUpdateRound.success) {
        setOptions(allUpdateRound?.data);
      } else {
        setTableData([]);
      }
    }
  }, [allDriveList, allUpdateRound]);

  return (
    <div>
      {loading || isFirstTimeLoading ? (
        <Loader />
      ) : (
        <MaterialTable
          columns={columns}
          data={tableData}
          editable={{
            onRowUpdate: (newRow, oldRow) =>
              new Promise((resolve, reject) => {
                const updatedData = [...tableData];
                updatedData[oldRow.tableData.id] = newRow;
                setTableData(updatedData);
                setTimeout(() => resolve(), 500);
              }),
          }}
          components={{
            Toolbar: (props) => (
              <div
                style={{
                  display: "flex",
                  marginTop: "1rem",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "2px solid #0094b1",
                  borderTop: "2px solid #007d93",
                  borderRadius: "4px",
                }}
              >
                <>
                  <div style={{ flexGrow: 2, width: "70%" }}>
                    <Controls.RoundedInput
                      className={classes.searchInput}
                      placeholder='Search by Drive Name'
                      helperText={"Press Enter to search after typing"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      defaultValue={searchValue}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleSearch(e.target.value);
                        }
                      }}
                    />
                  </div>
                  <div style={{ flexGrow: 1, padding: "2rem", width: "80%" }}>
                    <MTableToolbar {...props} />
                  </div>
                </>
              </div>
            ),
          }}
          actions={[
            {
              icon: () => <DescriptionIcon style={{ color: "green" }} />,
              tooltip: "Drive Result",
              position: "row",
              onClick: (e, tableData) => {
                openResultPage(tableData);
              },
            },
            {
              icon: () => <ThumbUp style={{ color: "green" }} />,
              tooltip: "Accept",
              onClick: (e, tableData) => {
                let payload = tableData.map((item) => ({
                  eventId: item.eventId,
                  roundDataId: item.roundUpdate?.id,
                  noOfOfferReleased: item.noOfOfferReleased,
                  offerReleasedOn: item.offerReleasedOn,
                  roundUpdateDate: item.roundUpdatedDate,
                }));

                let booleanArr = tableData.map((item) =>
                  Boolean(item.roundUpdate?.roundMessage)
                );
                if (booleanArr.includes(false)) {
                  setNotify({
                    isOpen: true,
                    message: "Please Choose the Round Update ",
                    type: "error",
                  });
                } else {
                  dispatch(updateRoundUpdatedData(payload));
                  setPage(0);
                  setFilter(false);
                }
              },
            },
            {
              icon: () => <ExportIcon style={{ color: "green" }} />,
              tooltip: "Export all selected rows",
              onClick: (e, tableData) => {
                let filteredIds = tableData.map((event) => event.eventId);
                let obj = { eventId: filteredIds };
                dispatch(
                  driveTrackingSheetDownload(obj, (response) => {
                    if (response.status === 200) {
                      textToDownloadFile(response.data, "DriveTracking", "csv");
                    } else {
                      setNotify({
                        isOpen: true,
                        message: response.message,
                        type: "error",
                      });
                    }
                  })
                );
              },
            },
          ]}
          options={{
            sorting: true,
            search: false,
            filtering: true,
            paging: false,
            pageSizeOptions: [1, 2, 5, 10, 20, 25, 50, 100],
            pageSize: 5,
            paginationType: "stepped",
            showFirstLastPageButtons: false,
            paginationPosition: "bottom",
            emptyRowsWhenPaging: false, // To avoid of having empty rows
            exportButton: false,
            exportAllData: true,
            exportFileName: "Drive-Tracking",
            addRowPosition: "first",
            actionsColumnIndex: -1,
            selection: true,
            showSelectAllCheckbox: true,
            showTextRowsSelected: false,
            grouping: false,
            columnsButton: true,
            rowStyle: (tableData, index) =>
              index % 2 === 0 ? { background: "#f5f5f5" } : null,
            headerStyle: { background: "aliceblue", color: "#000" },
          }}
          title='Drive Tracking'
          icons={{
            Add: Add,
            Edit: Edit,
            Check: Check,
            FirstPage: FirstPage,
            NextPage: ChevronRight,
            PreviousPage: ChevronLeft,
            LastPage: LastPage,
            Filter: () => <FilterIcon style={{ color: "#807f7f" }} />,
            SortArrow: () => (
              <SortIcon
                fontSize='small'
                style={{ color: "#c6c6c6", transform: "rotate(-90deg)" }}
              />
            ),
            Clear: () => <ClearIcon />,
            Export: () => <ExportIcon style={{ color: "green" }} />,
            ResetSearch: () => <ClearIcon />,
            Search: () => <SearchIcon />,
            ViewColumn: () => <ViewColumnIcon />,
            Delete: () => <DeleteIcon />,
          }}
        />
      )}
      {!loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "30px",
          }}
        >
          {filter ? (
            <Pagination
              page={filterPage + 1}
              count={totalFilterPages}
              onChange={handleFilterPageChange}
              variant='outlined'
              color='primary'
              size='large'
            />
          ) : (
            <Pagination
              page={page + 1}
              count={totalPages}
              onChange={handlePageChange}
              variant='outlined'
              color='primary'
              size='large'
            />
          )}
        </div>
      )}
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}

export default DriveTracking;
