import { Grid, TextField, Slide } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import MySnackBar from "../MySnackBar";
import { GSContainer, GSTitle } from "../../Asset/StyledComponents/Styles";
import { makeStyles } from "@material-ui/core/styles";
import BackButton from "../../Asset/Images/backbutton.svg";
import { AppBar, Breadcrumbs } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Typography } from "@material-ui/core";
import "../../Asset/All.css";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {
  isEmptyString,
  isOnlyNumber,
  isStringOnly,
  urlPatternValidation,
} from "../Validation";
import { useDispatch, useSelector } from "react-redux";
import {
  createGradSchoolList,
  getCountryLists,
  getGradSclRegionList,
  getSubRegionListCall,
} from "../../Actions/GradSchoolAction";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
  },
  btnStyles: {
    paddingLeft: 30,
    paddingRight: 30,
  },
}));

export default function AddGradSchool(props) {
  const { getRegionList, getListOfCountry, getSubRegionList } = useSelector(
    (state) => state.GradSchoolReducer
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    id: "",
    name: null,
    region: "",
    subRegion: null,
    location: null,
    country: null,
    show: false,
    gradSchoolLink: null,
    imageUrl: null,
    logoUrl: null,
    regionId: "",
    snack: {
      open: false,
      message: "",
      color: "",
    },
  });

  const [nameErr, setNameErr] = useState("");
  const [regionErr, setRegionErr] = useState("");
  const [subRegionErr, setSubRegionErr] = useState("");
  const [countryErr, setCountryErr] = useState("");
  const [locationErr, setLocationErr] = useState("");
  const [gradSchoolLinkErr, setGradSchoolLinkErr] = useState("");
  const [imageUrlErr, setImageUrlErr] = useState("");
  const [logoUrlErr, setLogoUrlErr] = useState("");
  const [regionList, setRegionList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [subRegionList, setSubRegionList] = useState([]);
  const handleAddSave = () => {
    const {
      country,
      location,
      name,
      region,
      gradSchoolLink,
      imageUrl,
      logoUrl,
      id,
      subRegion,
    } = state;

    isEmptyString(name) ? setNameErr("Please fill the Name") : setNameErr("");
    isEmptyString(location)
      ? setLocationErr("Please fill the Location")
      : setLocationErr("");
    isEmptyString(country)
      ? setCountryErr("Please select the Country")
      : setCountryErr("");
    isEmptyString(region)
      ? setRegionErr("Please select the Region")
      : setRegionErr("");
    isEmptyString(subRegion)
      ? setSubRegionErr("Please select the Sub Region")
      : setSubRegionErr("");
    isEmptyString(gradSchoolLink)
      ? setGradSchoolLinkErr("Please fill the Grad School Link")
      : !urlPatternValidation(gradSchoolLink)
      ? setGradSchoolLinkErr("Please fill the Valid URL")
      : setGradSchoolLinkErr("");

    if (
      !isEmptyString(name) &&
      !isEmptyString(location) &&
      !isEmptyString(country) &&
      !isEmptyString(region) &&
      !isEmptyString(subRegion) &&
      !isEmptyString(gradSchoolLink) &&
      isEmptyString(imageUrlErr) &&
      isEmptyString(logoUrlErr) &&
      urlPatternValidation(gradSchoolLink) &&
      isEmptyString(gradSchoolLinkErr)
    ) {
      let objAdd = {
        name: name,
        region: region.name,
        country: country.name,
        location: location,
        gradSchoolLink: gradSchoolLink,
        imageUrl: imageUrl === null ? "" : imageUrl,
        logoUrl: logoUrl === null ? "" : logoUrl,
        subRegion: subRegion.name,
      };

      let objEdit = {
        id: id,
        name: name,
        region: region.name ? region.name : region,
        country: country.name ? country.name : country,
        location: location,
        gradSchoolLink: gradSchoolLink,
        imageUrl: imageUrl === null ? "" : imageUrl,
        logoUrl: logoUrl === null ? "" : logoUrl,
        subRegion: subRegion.name ? subRegion.name : subRegion,
      };

      if (state.id.length === 0) {
        dispatch(
          createGradSchoolList(objAdd, (res) => {
            if (res.message === "Saved Successfully") {
              setState({
                id: "",
                name: "",
                region: "",
                location: "",
                country: "",
                show: false,
                gradSchoolLink: "",
                imageUrl: "",
                logoUrl: "",
                snack: {
                  open: true,
                  message: res.message,
                  color: "success",
                },
              });
            } else if (res.message === "Location Already Exists!") {
              setState({
                ...state,
                snack: {
                  open: true,
                  message: res.message,
                  color: "error",
                },
              });
            } else {
            }
          })
        );
      } else {
        dispatch(
          createGradSchoolList(objEdit, (res) => {
            if (res.message === "Updated Successfully") {
              setState({
                ...state,
                snack: {
                  open: true,
                  message: res.message,
                  color: "success",
                },
              });
            }
          })
        );
      }
    }
  };

  useEffect(() => {
    if (state.logoUrl) {
      if (!urlPatternValidation(state.logoUrl) && state.logoUrl !== "") {
        setLogoUrlErr("Please fill the valid URL");
      }
    } else {
      setLogoUrlErr("");
    }
  }, []);

  useEffect(() => {
    if (props.location.state?.id.length > 0) {
      const {
        country,
        id,
        location,
        name,
        region,
        logoUrl,
        imageUrl,
        gradSchoolLink,
        subRegionId,
        regionId,
        subRegion,
      } = props.location?.state;

      setState({
        ...state,
        id: id,
        country: country,
        location: location,
        name: name,
        region: region,
        gradSchoolLink: gradSchoolLink,
        imageUrl: imageUrl,
        logoUrl: logoUrl,
        subRegion: subRegion,
        regionId: regionId,
      });

      dispatch(getSubRegionListCall(regionId));
      dispatch(getCountryLists(regionId, subRegionId));
    }
    dispatch(getGradSclRegionList());
  }, []);

  useEffect(() => {
    if (getRegionList !== null) {
      setRegionList(getRegionList);
    }
    if (getListOfCountry !== null) {
      setCountryList(getListOfCountry);
    }
    if (getSubRegionList !== null) {
      setSubRegionList(getSubRegionList);
    }
  }, [getRegionList, getListOfCountry, getSubRegionList]);

  const filter = createFilterOptions();

  console.log(state, "hello");

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div style={{ display: "flex", flexDirection: "row", margin: "10px" }}>
          <img
            src={BackButton}
            style={{ cursor: "pointer", marginTop: "-10px" }}
            onClick={() => props.history.goBack()}
          />
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Typography
              style={{
                cursor: "pointer",
                fontWeight: "600",
                marginLeft: "10px",
              }}
            >
              Back
            </Typography>
          </Breadcrumbs>
        </div>
        <AppBar position="static" color="default">
          <GSContainer>
            <GSTitle>
              {" "}
              {props.location.state?.id.length > 0 ? "Edit" : "Add"} Grad School
            </GSTitle>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              spacing={4}
            >
              <Grid item md={12}>
                <Grid container spacing={4}>
                  <Grid item md={6}>
                    <TextField
                      variant="standard"
                      color="primary"
                      label={"Name"}
                      value={state.name}
                      fullWidth
                      onChange={(e) => {
                        setState({
                          ...state,
                          name: e.target.value,
                        });
                        setNameErr("");
                      }}
                      helperText={nameErr}
                      required
                      onKeyDown={(e) => isOnlyNumber(e) && e.preventDefault()}
                      error={nameErr === "" ? false : true}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Autocomplete
                      value={state.region}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          setState({
                            ...state,
                            region: {
                              name: newValue,
                            },
                          });
                          setRegionErr("");
                        } else if (newValue && newValue.inputValue) {
                          setState({
                            ...state,
                            region: {
                              name: newValue.inputValue,
                            },
                          });
                          setRegionErr("");
                        } else {
                          setState({
                            ...state,
                            region: newValue,
                            regionId: newValue?.id,
                          });
                          setRegionErr("");
                          dispatch(getSubRegionListCall(newValue?.id));
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some(
                          (option) => inputValue === option.name
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            name: `Add "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      options={regionList}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.name;
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option.name}</li>
                      )}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Region"
                          variant="standard"
                          fullWidth
                          color="primary"
                          onKeyDown={(e) =>
                            isOnlyNumber(e) && e.preventDefault()
                          }
                          helperText={regionErr}
                          required
                          error={regionErr === "" ? false : true}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Autocomplete
                      value={state.subRegion}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          setState({
                            ...state,
                            subRegion: {
                              name: newValue,
                            },
                          });
                          setSubRegionErr("");
                        } else if (newValue && newValue.inputValue) {
                          setState({
                            ...state,
                            subRegion: {
                              name: newValue.inputValue,
                            },
                          });
                          setSubRegionErr("");
                        } else {
                          setState({
                            ...state,
                            subRegion: newValue,
                          });
                          setSubRegionErr("");
                          dispatch(
                            getCountryLists(state.regionId, newValue?.id)
                          );
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some(
                          (option) => inputValue === option.name
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            name: `Add "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      options={subRegionList}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.name;
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option.name}</li>
                      )}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sub Region"
                          variant="standard"
                          fullWidth
                          color="primary"
                          onKeyDown={(e) =>
                            isOnlyNumber(e) && e.preventDefault()
                          }
                          helperText={subRegionErr}
                          required
                          error={subRegionErr === "" ? false : true}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Autocomplete
                      value={state.country}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          setState({
                            ...state,
                            country: {
                              name: newValue,
                            },
                          });
                          setCountryErr("");
                        } else if (newValue && newValue.inputValue) {
                          setState({
                            ...state,
                            country: {
                              name: newValue.inputValue,
                            },
                          });
                          setCountryErr("");
                        } else {
                          setState({
                            ...state,
                            country: newValue,
                          });
                          setCountryErr("");
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        const isExisting = options.some(
                          (option) => inputValue === option.name
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            name: `Add "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      options={countryList}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.name;
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option.name}</li>
                      )}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Country"
                          variant="standard"
                          fullWidth
                          color="primary"
                          required
                          error={countryErr === "" ? false : true}
                          helperText={countryErr}
                          onKeyDown={(e) =>
                            isOnlyNumber(e) && e.preventDefault()
                          }
                        />
                      )}
                    />
                  </Grid>

                  <Grid item md={6}>
                    <TextField
                      variant="standard"
                      color="primary"
                      label={"Location"}
                      value={state.location}
                      fullWidth
                      onChange={(e) => {
                        setState({
                          ...state,
                          location: e.target.value,
                        });
                        setLocationErr("");
                      }}
                      helperText={locationErr}
                      error={locationErr === "" ? false : true}
                      required
                      onKeyDown={(e) => isOnlyNumber(e) && e.preventDefault()}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      variant="standard"
                      color="primary"
                      label={"Grad School Link"}
                      value={state.gradSchoolLink}
                      fullWidth
                      onChange={(e) => {
                        setState({
                          ...state,
                          gradSchoolLink: e.target.value,
                        });
                        setGradSchoolLinkErr("");
                        if (e.target.value) {
                          !urlPatternValidation(e.target.value)
                            ? setGradSchoolLinkErr("Please fill the valid URL")
                            : setGradSchoolLinkErr("");
                        }
                      }}
                      onKeyDown={(e) => isOnlyNumber(e) && e.preventDefault()}
                      required
                      error={gradSchoolLinkErr === "" ? false : true}
                      helperText={
                        gradSchoolLinkErr === "" ? "" : gradSchoolLinkErr
                      }
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      variant="standard"
                      color="primary"
                      label={"Image URL"}
                      value={state.imageUrl}
                      fullWidth
                      onChange={(e) => {
                        setState({ ...state, imageUrl: e.target.value });
                        setImageUrlErr("");
                        if (e.target.value !== "") {
                          if (
                            !urlPatternValidation(e.target.value) &&
                            e.target.value !== ""
                          ) {
                            setImageUrlErr("Please fill the valid URL");
                          } else {
                            setImageUrlErr("");
                          }
                        } else if (e.target.value === "") {
                          setImageUrlErr("");
                        } else {
                          setImageUrlErr("");
                        }
                      }}
                      error={
                        imageUrlErr === ""
                          ? false
                          : state.imageUrl === ""
                          ? false
                          : true
                      }
                      helperText={
                        imageUrlErr === ""
                          ? ""
                          : state.imageUrl === ""
                          ? ""
                          : imageUrlErr
                      }
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      variant="standard"
                      color="primary"
                      label={"Logo URL"}
                      value={state.logoUrl}
                      fullWidth
                      onChange={(e) => {
                        setState({ ...state, logoUrl: e.target.value });

                        setLogoUrlErr("");
                        if (e.target.value !== "") {
                          if (
                            !urlPatternValidation(e.target.value) &&
                            e.target.value !== ""
                          ) {
                            setLogoUrlErr("Please fill the valid URL");
                          } else {
                            setLogoUrlErr("");
                          }
                        } else {
                          setLogoUrlErr("");
                        }
                      }}
                      error={
                        logoUrlErr === ""
                          ? false
                          : state.logoUrl === ""
                          ? false
                          : true
                      }
                      helperText={
                        logoUrlErr === ""
                          ? ""
                          : state.logoUrl === ""
                          ? ""
                          : logoUrlErr
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddSave}
                  className={classes.btnStyles}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </GSContainer>
        </AppBar>
        <MySnackBar
          snackOpen={state.snack.open}
          snackVariant={state.snack.color}
          snackMsg={state.snack.message}
          onClose={() =>
            setState({
              ...state,
              snack: {
                open: false,
                message: "",
                color: "",
              },
            })
          }
        />
      </div>
    </React.Fragment>
  );
}
