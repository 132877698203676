import {
  Divider,
  Grid,
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ConfirmDialog from '../../Utils/ConfirmDialog';
import Controls from '../../Utils/controls/Controls';
import Loader from '../../Utils/controls/Loader';
import Notification from '../../Utils/Notification';
import * as yup from 'yup';
import useTable from '../../Utils/useTable';
import { ButtonsContainer } from '../Assets/Styles/CreatePostStyles';
import { Form, Formik } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import {
  getDriveLimitDetails,
  getDriveLimitUserLists,
  updateDriveLimit,
} from '../../../Actions/WallActions';

const useStyles = makeStyles((theme) => ({
  rowNo: {
    width: '30px',
  },
  limitTableStyle: {
    width: '50px',
  },
  btnStyle: {
    borderRadius: '4px',
    width: '140px',
  },
  driveLimitHeading: {
    color: '#1093FF',
    fontSize: '18px',
    margin: '0 !important',
    fontWeight: '600',
  },
  driveLimitTableHeading: {
    color: '#1093FF',
    fontSize: '16px',
    margin: '0 !important',
    fontWeight: '600',
  },
  limitStyle: {
    width: '100%',
  },
}));

const headCells = [
  { id: '#', label: '#', disableSorting: true },
  { id: 'key', label: 'Year of Graduation' },
  { id: 'value', label: 'Drive Limits', disableSorting: true },
];

export default function DriveLimit() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { loading, getUserLists, getDriveLimitLists, updateDriveLimits } = useSelector(
    (state) => state.driveLimitReducer
  );
  const [userLists, setUserLists] = useState([]);
  const [driveLimitLists, setDriveLimitLists] = useState([]);
  const [state, setState] = useState({
    yearOfGraduation: null,
    driveLimit: '',
  });

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    driveLimitLists,
    headCells,
    filterFn
  );

  useEffect(() => {
    dispatch(getDriveLimitUserLists());
    dispatch(getDriveLimitDetails());
  }, [dispatch]);

  useEffect(() => {
    if (getUserLists) {
      if (getUserLists.success) {
        setUserLists(getUserLists?.data);
      } else {
        setUserLists([]);
      }
    }
  }, [getUserLists]);

  useEffect(() => {
    if (getDriveLimitLists) {
      if (getDriveLimitLists.success) {
        setDriveLimitLists(getDriveLimitLists?.data);
      } else {
        setDriveLimitLists([]);
      }
    }
  }, [getDriveLimitLists]);

  useEffect(() => {
    if (updateDriveLimits) {
      if (updateDriveLimits.success) {
        setNotify({
          isOpen: true,
          message: updateDriveLimits.message,
          type: 'success',
        });
        dispatch(getDriveLimitDetails());
      } else {
        setNotify({
          isOpen: true,
          message: updateDriveLimits.message,
          type: 'error',
        });
      }
    }
  }, [updateDriveLimits]);

  const validationSchema = yup.object({
    yearOfGraduation: yup
      .object()
      .nullable()
      .required('Year of graduation is required'),
    driveLimit: yup.string().required('Drive Limit is required'),
  });

  const handleDone = (data) => {
    let value = data?.driveLimit.toString();
    let payload = {
      key: data?.yearOfGraduation?.userType,
      value: value,
    };
    dispatch(updateDriveLimit(payload));
  };

  return (
    <>
      <Formik
        initialValues={state || []}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleDone(values);
          setTimeout(() => {
            resetForm();
          }, 500);
        }}
        enableReinitialize
      >
        {({ handleSubmit, errors, handleChange, values, touched, setFieldValue }) => {
          return (
            <>
              <div className='CreatePost'>
                <Form onSubmit={handleSubmit} autoComplete='off'>
                  <Grid container spacing={1} direction='column' style={{ margin: 0 }}>
                    <Grid item style={{ padding: 0, margin: '30px 0 25px 0' }}>
                      <h5 className={classes.driveLimitHeading}>Set Drive Limit</h5>
                    </Grid>

                    <Grid
                      container
                      spacing={1}
                      direction='row'
                      style={{ margin: '0 0 30px 0', alignItems: 'center' }}
                    >
                      <Grid item md={4} style={{ padding: 0 }}>
                        <Autocomplete
                          id='yearOfGraduation'
                          name='yearOfGraduation'
                          getOptionLabel={(option) => option?.userType}
                          options={userLists}
                          onChange={(e, value) => {
                            setFieldValue('yearOfGraduation', value);
                          }}
                          fullWidth
                          value={values.yearOfGraduation}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label='Year of Graduation'
                              name='yearOfGraduation'
                              variant='outlined'
                              error={
                                touched.yearOfGraduation &&
                                Boolean(values.yearOfGraduation === null)
                              }
                            />
                          )}
                        />
                      </Grid>

                      <Grid item md={4} style={{ padding: '0 0 0 30px' }}>
                        <Controls.Input
                          type='number'
                          onKeyDown={(e) =>
                            ['ArrowUp', 'ArrowDown', 'e', 'E', '.', '+', '-'].includes(e.key) &&
                            e.preventDefault()
                          }
                          label='Enter Drive Limit'
                          name='driveLimit'
                          value={values.driveLimit}
                          onChange={handleChange}
                          error={touched.driveLimit && Boolean(errors.driveLimit)}
                          className={classes.limitStyle}
                        />
                      </Grid>

                      <Grid item xs>
                        <ButtonsContainer style={{ justifyContent: 'flex-end', margin: 0 }}>
                          <Controls.Button
                            text={`Submit`}
                            variant='contained'
                            color='primary'
                            className={classes.btnStyle}
                            type={'submit'}
                          />
                        </ButtonsContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={12}>
                    <Divider
                      style={{
                        backgroundColor: '#BEBEBE',
                        marginBottom: '24px',
                      }}
                    />
                  </Grid>
                  <h5 className={classes.driveLimitTableHeading}>Drive Limit Details</h5>
                  {!loading ? (
                    <TblContainer>
                      <TblHead />
                      {driveLimitLists && (
                        <TableBody>
                          {recordsAfterPagingAndSorting().map((item, index) => {
                            return (
                              <TableRow key={item.id}>
                                <TableCell className={classes.rowNo}>{index + 1}</TableCell>
                                <TableCell
                                  className={classes.limitTableStyle}
                                >{`${item.key}`}</TableCell>
                                <TableCell
                                  className={classes.limitTableStyle}
                                >{`${item.value}`}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      )}
                    </TblContainer>
                  ) : (
                    <Loader />
                  )}
                </Form>
              </div>
            </>
          );
        }}
      </Formik>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
}
