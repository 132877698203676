import React, { useState, useEffect } from 'react';
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import useTable from '../../Utils/useTable';
import Controls from '../../Utils/controls/Controls';
import { Search } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import Notification from '../../Utils/Notification';
import { useHistory } from 'react-router-dom';
import { createStoriesPath } from '../../RoutePaths';
import Loader from '../../Utils/controls/Loader';
import MuiAlert from '@material-ui/lab/Alert';
import * as moment from 'moment';
import ConfirmDialog from '../../Utils/ConfirmDialog';
import { useSelector, useDispatch } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import { deleteWallStory, listWallStories, updateHideStatus } from '../../../Actions/WallActions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const Alert = (props) => <MuiAlert elevation={6} variant='filled' {...props} />;

const useStyles = makeStyles((theme) => ({
  pageContent: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
  },
  searchInput: {
    width: '65%',
  },
  newButton: {
    position: 'absolute',
    right: '20px',
    borderRadius: '26px',
  },
}));

const headCells = [
  { id: 'category', label: 'User', disableSorting: true },
  { id: 'createdAt', label: 'Posted on' },
  { id: 'noOfViews', label: 'No. of Views' },
  { id: 'redirectionUrlName', label: 'Redirection' },
  { id: 'createdBy', label: 'Posted by' },
  { id: 'actions', label: 'Actions', disableSorting: true },
];

export default function Stories() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading, error, stories } = useSelector((state) => state.wallStoryReducer);
  let totalPages = stories?.data?.totalPages;

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting, page } = useTable(
    stories?.data?.content,
    headCells,
    filterFn,
    totalPages
  );

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value == '') return items;
        else
          return items.filter((x) =>
            x.userType.map((item) => item.toLowerCase()).includes(target.value.toLowerCase())
          );
      },
    });
  };

  const openInPage = (item) => {
    dispatch(updateHideStatus(item.id, !item.hideStatus));
    setTimeout(() => {
      dispatch(listWallStories(page));
    }, 1200);
  };

  // Render the "name" from the array of objects
  const renderListCategory = (categories) => {
    let category = '';
    for (let i = 0; i < categories.length; i++) {
      category =
        i !== categories.length - 1
          ? category.concat(categories[i], ', ')
          : category.concat(categories[i], '');
    }
    return category;
  };

  // Convert Time Format
  const convertTimeFormat = (date) =>
    new Date(date).toLocaleTimeString('en-US', {
      hour: 'numeric',
      hour12: true,
      minute: 'numeric',
    });

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    dispatch(deleteWallStory(id));
    setTimeout(() => {
      dispatch(listWallStories(page));
    }, 1200);
    setNotify({
      isOpen: true,
      message: 'Deleted Successfully',
      type: 'success',
    });
  };

  useEffect(() => {
    dispatch(listWallStories(page));
  }, [dispatch, page]);

  return (
    <>
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.RoundedInput
            className={classes.searchInput}
            placeholder='Search Stories by user...'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          <Controls.Button
            text='Create New Story'
            variant='contained'
            color='primary'
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={() => {
              history.push({
                pathname: createStoriesPath,
                type: false,
                postType: 'Stories',
              });
            }}
          />
        </Toolbar>

        <TblContainer>
          <TblHead />
          {stories?.data?.content && (
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell style={{ lineHeight: '25px', width: '150px' }}>
                      {renderListCategory(item.userType)}
                    </TableCell>
                    <TableCell>
                      {moment(new Date(item.createdAt)).format('DD MMM yyyy')} ,{' '}
                      {convertTimeFormat(item.createdAt)}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', width: '145px' }}>
                      {item.noOfViews}
                    </TableCell>
                    <TableCell>{item.redirectionUrlName}</TableCell>
                    <TableCell>{item.createdBy}</TableCell>
                    <TableCell>
                      <Controls.ActionButton
                        onClick={() => {
                          openInPage(item);
                        }}
                      >
                        {item.hideStatus ? (
                          <Tooltip title='Hide a Story' placement='Bottom'>
                            <VisibilityOffIcon fontSize='small' color='default' />
                          </Tooltip>
                        ) : (
                          <Tooltip title='Show a Story' placement='Bottom'>
                            <VisibilityIcon fontSize='small' color='default' />
                          </Tooltip>
                        )}
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title: 'Are you sure to delete this post?',
                            subTitle: "You can't undo this operation",
                            onConfirm: () => {
                              onDelete(item.id);
                            },
                          });
                        }}
                      >
                        <DeleteIcon fontSize='small' color='secondary' />
                      </Controls.ActionButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </TblContainer>
        <div style={{ margin: '2rem auto', width: '60%' }}>
          {loading && <Loader />}
          {error && <Alert severity='error'>{error}</Alert>}
          {!loading && stories?.data?.content?.length === 0 && (
            <Alert severity='info'>0 Stories Found</Alert>
          )}
        </div>
        <TblPagination />
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
}
