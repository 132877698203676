import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  getFocusList,
  getPlanOfAction,
  getPlanOfActionForQPMC,
  savePlanOfAction,
  saveSingleFocus,
} from "../../../AsyncApiCall/PgaReport/PlanOfAction";
import BottomContainer from "../../../Component/PgaReport/BottomContainer";
import PlanOfActionTable from "../../../Component/PgaReport/Components/PlanOfActionTable";
import { PageWrapper } from "../../../Component/PgaReport/Components/StyledComponents";
import { useStyles } from "../../../Component/PgaReport/Styles/Index";
import { HELPER_TEXT } from "../../../Constant/Variables";
import TextFieldComponent from "../../Controls/TextField";
import MySnackBar from "../../MySnackBar";
import { isEmptyObject, isEmptyString } from "../../Validation";

function PlanOfAction(props) {
  console.log(props.editable, "*********************versionNo");
  const classes = useStyles();
  const [focusList, setFocusList] = useState([]);
  const [planOfAction, setPlanOfAction] = useState({
    criteriaCGPA: "",
    rows: [],
    plans: [],
  });
  const [snack, setSnack] = useState({
    snackOpen: false,
    snackMsg: "",
    snackVariant: "",
  });
  const [selectedQuarterId, setSelectedQuarterId] = useState([]);

  const getAndSetPlanOfAction = () => {
    getPlanOfActionForQPMC(
      props.studentId,
      props.productId,
      props.version
    ).then((response) => {
      if (response.status === 200) {
        setPlanOfAction({ ...response.data.data });
      }
    });
  };

  useEffect(() => {
    getFocusList(props.productId).then((response) => {
      if (response.status === 200) {
        setFocusList(response.data.data);
      }
    });
    getAndSetPlanOfAction("");
  }, [props?.version]);

  useEffect(() => {
    if (selectedQuarterId.length !== 0) {
      const commaSeparatedId = selectedQuarterId.join(",");
      getAndSetPlanOfAction(commaSeparatedId);
    } else {
      getAndSetPlanOfAction("");
    }
  }, [selectedQuarterId]);

  const handleFocusChange = (
    value,
    focusNo,
    planId,
    name,
    rowIndex,
    cellIndex
  ) => {
    if (value) {
      let copyOf = { ...planOfAction };
      copyOf.rows[rowIndex][cellIndex].name = value;
      setPlanOfAction(copyOf);
      let requestBody = {
        planId: planId,
        focusNo: parseInt(focusNo),
        pgaPoaFocus: value,
      };

      saveSingleFocus(props.studentId, props.productId, requestBody).then(
        (response) => {
          if (response.status === 200) {
            getAndSetPlanOfAction("");
          }
        }
      );
    } else {
      let copyOf = { ...planOfAction };
      copyOf.rows[rowIndex][cellIndex].name = value;
      let quarterIndex = copyOf.plans.findIndex((el) => el.id === planId);
      let focusIndex = copyOf.plans[quarterIndex].pgaStudentPoaFocus.findIndex(
        (el) => el.orderNo === focusNo
      );
      copyOf.plans[quarterIndex].pgaStudentPoaFocus[
        focusIndex
      ].pgaPoaFocus = null;
      setPlanOfAction(copyOf);
    }
  };

  const handleTextChange = (e, quarterIndex, focusIndex) => {
    let copyOf = { ...planOfAction };
    copyOf.plans[quarterIndex].pgaStudentPoaFocus[focusIndex][e.target.name] =
      e.target.value;
    setPlanOfAction(copyOf);
  };

  const handleSave = () => {
    let error = false;
    for (let index = 0; index < planOfAction.plans.length; index++) {
      for (
        let j = 0;
        j < planOfAction.plans[index].pgaStudentPoaFocus.length;
        j++
      ) {
        if (
          isEmptyObject(
            planOfAction.plans[index].pgaStudentPoaFocus[j].activity
          )
        ) {
          error = true;
          break;
        }
        if (
          isEmptyString(planOfAction.plans[index].pgaStudentPoaFocus[j].remark)
        ) {
          error = true;
          break;
        }
      }
    }
    if (!error) {
      savePlanOfAction(
        props.studentId,
        props.productId,
        planOfAction.plans
      ).then((response) => {
        if (response.status === 200) {
          getAndSetPlanOfAction("");
          setSnack({
            snackMsg: "Saved Successfully",
            snackVariant: "success",
            snackOpen: true,
          });
          setSelectedQuarterId([]);
        } else {
          setSnack({
            snackMsg: response,
            snackVariant: "error",
            snackOpen: true,
          });
        }
      });
    } else {
      setSnack({
        snackMsg: HELPER_TEXT.requiredField,
        snackVariant: "error",
        snackOpen: true,
      });
    }
  };
  console.log(focusList, "***************focusList");
  return (
    <PageWrapper>
      <div className={classes.containerStyle}>
        <Grid container spacing={2} className={classes.planOfActionContainer}>
          <Grid item xs={12}>
            <PlanOfActionTable
              data={planOfAction.rows}
              focusList={focusList}
              handleFocusChange={handleFocusChange}
              edit={props.editable}
            />
          </Grid>
          {planOfAction.plans.map((eachPlan, quarterIndex) => {
            return (
              <>
                <Grid item md={12} xs={12} sm={12} lg={12} xl={12}>
                  <Typography className={classes.quarterlyTypo}>
                    {`Quarter ${eachPlan.quarterPlan}`}
                  </Typography>
                </Grid>
                {eachPlan.pgaStudentPoaFocus.map((eachFocus, focusIndex) => {
                  return (
                    <>
                      <Grid item md={12} xs={12} sm={12} lg={12} xl={12}>
                        <Typography>{"Focus " + eachFocus.orderNo}</Typography>
                      </Grid>
                      <Grid item md={6} xs={12} sm={12}>
                        <TextFieldComponent
                          value={eachFocus.activity}
                          name={"activity"}
                          onChange={(e) =>
                            handleTextChange(e, quarterIndex, focusIndex)
                          }
                          className={props.editable ? "" : classes.pointerEvent}
                          label={"Category Description"}
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6} xs={12} sm={12}>
                        <TextFieldComponent
                          name={"remark"}
                          value={eachFocus.remark}
                          onChange={(e) =>
                            handleTextChange(e, quarterIndex, focusIndex)
                          }
                          label={"Remarks"}
                          className={props.editable ? "" : classes.pointerEvent}
                          fullWidth
                          multiline
                        />
                      </Grid>
                    </>
                  );
                })}
              </>
            );
          })}
        </Grid>
      </div>
      {props?.editable ? <BottomContainer onClick={handleSave} /> : ""}
      <MySnackBar
        onClose={() =>
          setSnack({
            snackOpen: false,
            snackMsg: "",
            snackVariant: "",
          })
        }
        snackOpen={snack.snackOpen}
        snackVariant={snack.snackVariant}
        snackMsg={snack.snackMsg}
      />
    </PageWrapper>
  );
}

export default PlanOfAction;
