import { Box, Grid, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import CustomDropZone from "../Component/Utils/controls/CustomDropZone";
import { Typo } from "../Component/Utils/controls/Styles";
import React from "react";
import { FlexViewContainer } from "../Asset/StyledComponent";

function BulkUpload({ file, disabled, onDrop, onDelete }) {
  return (
    <Box>
      <CustomDropZone
        acceptTypes={
          "text/csv , application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
        onDrop={onDrop}
        disabled={disabled}
      />
      <FlexViewContainer justifyContent={"end"}>
        {file?.name && (
          <>
            <Typo variant={"caption"} color={"#333333"}>
              {file.name}
            </Typo>
            <IconButton onClick={onDelete}>
              <DeleteIcon
                style={{
                  padding: "2px !important",
                }}
                color={"error"}
              />
            </IconButton>
          </>
        )}
      </FlexViewContainer>
    </Box>
  );
}

export default BulkUpload;
