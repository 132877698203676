import {
  Breadcrumbs,
  Divider,
  Grid,
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { examSpacePath, semesterPath } from "../../RoutePaths";
import ConfirmDialog from "../../Utils/ConfirmDialog";
import Controls from "../../Utils/controls/Controls";
import Loader from "../../Utils/controls/Loader";
import Notification from "../../Utils/Notification";
import useTable from "../../Utils/useTable";
import BackHandler from "../Components/BackHandler";
import {
  ButtonsContainer,
  CreatePostContainer,
} from "../Assets/Styles/CreatePostStyles";
import { Form, Formik, FieldArray, Field } from "formik";
import { Autocomplete } from "@material-ui/lab";
import CustomStepper from "../Components/CustomStepper";
import {
  createUniversityBranch,
  deleteBranchById,
  getAllBranch,
  getAllUniversity,
  getUniversityBranch,
  setFieldValue,
} from "../../../Actions/ExamSpaceAction";

const Alert = (props) => <MuiAlert elevation={6} variant='filled' {...props} />;

const useStyles = makeStyles((theme) => ({
  rowNo: {
    width: "30px",
  },
  branch: {
    width: "200px",
  },
  actions: {
    width: "200px",
    textAlign: "right",
  },
  btnContainer: {
    justifyContent: "flex-end !important",
    gap: "10px",
    marginTop: "30px !important",
  },
  btnStyle: {
    borderRadius: "4px",
    width: "140px",
  },
  univBranchHeading: {
    color: "#001D3A",
    fontSize: "18px",
    margin: "0 !important",
  },
  breadCrumbsContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "0 10px",
  },
  newButton: {
    borderRadius: "26px",
    width: "153px",
    fontSize: "13px",
  },
  fieldStyle: {
    display: "flex",
  },
  doneButton: {
    padding: " 4px 40px 4px 40px",
    marginTop: "19px",
    marginLeft: "190px",
  },
}));

const headCells = [
  { id: "#", label: "#", disableSorting: true },
  { id: "branch", label: "Branch Name" },
  { id: "actions", label: "", disableSorting: true },
];

export default function UniversityPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  let location = useLocation();
  const params = useParams();
  const search = location.search;
  const univId = new URLSearchParams(search).get("univId");
  const [deleteClick, setDeleteClick] = useState(false);
  const [universityOptions, setUniversityOptions] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);
  const [branchLists, setBranchLists] = useState([]);
  const [state, setState] = useState({
    universityName: {},
    branchList: [{ branch: "", done: "" }],
  });
  const universityId = state.universityName?.id;
  const steps = ["University & Branch", "Semester & Subject", "Chapter"];

  const {
    loading,
    getUniversityList,
    getBranchList,
    createUnivBranch,
    getUnivBranch,
    deleteBranchStatus,
  } = useSelector((state) => state.examSpaceReducer);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    branchLists,
    headCells,
    filterFn
  );

  useEffect(() => {
    dispatch(getAllUniversity());
    dispatch(getAllBranch());
    if (univId) {
      dispatch(getUniversityBranch(univId));
    }
  }, [dispatch, univId]);

  useEffect(() => {
    if (getUniversityList) {
      if (getUniversityList.success) {
        setUniversityOptions(getUniversityList?.data);
      } else {
        setUniversityOptions([]);
      }
    }
  }, [getUniversityList]);

  useEffect(() => {
    if (getBranchList) {
      if (getBranchList.success) {
        setBranchOptions(getBranchList?.data);
      } else {
        setBranchOptions([]);
      }
    }
  }, [getBranchList]);

  useEffect(() => {
    if (getUnivBranch) {
      if (getUnivBranch.success) {
        setBranchLists(getUnivBranch?.data?.branches);
        setState({
          ...state,
          universityName: getUnivBranch?.data?.examCrackerUniversity,
        });
      } else {
        setBranchLists([]);
      }
      dispatch(setFieldValue("getUnivBranch", null));
    }
  }, [getUnivBranch]);

  useEffect(() => {
    if (createUnivBranch) {
      if (createUnivBranch.success) {
        setNotify({
          isOpen: true,
          message: createUnivBranch.message,
          type: "success",
        });
        setTimeout(() => {
          dispatch(getUniversityBranch(universityId));
        }, 700);
      } else {
        setNotify({
          isOpen: true,
          message: createUnivBranch.message,
          type: "error",
        });
      }
      dispatch(setFieldValue("createUnivBranch", null));
    }
  }, [createUnivBranch]);

  useEffect(() => {
    if (deleteClick && deleteBranchStatus) {
      if (deleteBranchStatus.success) {
        setNotify({
          isOpen: true,
          message: deleteBranchStatus.message,
          type: "success",
        });
        setTimeout(() => {
          dispatch(getUniversityBranch(universityId));
        }, 700);
      } else {
        setNotify({
          isOpen: true,
          message: deleteBranchStatus.message,
          type: "error",
        });
      }
      setDeleteClick(false);
    }
  }, [deleteClick, deleteBranchStatus]);

  const handleDeleteClick = (item) => {
    setConfirmDialog({
      isOpen: true,
      title: "DELETE BRANCH?",
      subTitle: `Are you sure you want to delete the selected Branch?
          It will go longer will be visible on the App.`,
      onConfirm: () => {
        onDelete(item.branchId);
      },
    });
  };

  const onDelete = (branchId) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    dispatch(deleteBranchById(universityId, branchId));
    setDeleteClick(true);
  };

  const handleNext = () => {
    let list = branchLists ?? {};
    const branch = Object.values(list).pop(); // return last value of the obj
    history.push(
      `${semesterPath}?univId=${universityId}&univBranchId=${branch?.universityBranchId}&branch=${branch?.branchName}`
    );
  };

  const openInPage = (item) => {
    history.push(
      `${semesterPath}?univId=${universityId}&univBranchId=${item?.universityBranchId}&branch=${item?.branchName}`
    );
  };

  const validate = (values) => {
    if (branchLists?.length < 1) {
      setNotify({
        isOpen: true,
        message: "Create atleast one branch...",
        type: "error",
      });
      return false;
    }
    return true;
  };

  /**
   * Handle to Save the branch
   * @param {Array} branchList
   * @param {Number} index
   * @param {Function} remove
   */
  const handleDone = (university, branch, index, remove) => {
    if ((university && !university.id) || !university) {
      setNotify({
        isOpen: true,
        message: "Please Choose the University",
        type: "error",
      });
    } else if ((branch && !branch.id) || !branch) {
      setNotify({
        isOpen: true,
        message: "Please Choose the Branch",
        type: "error",
      });
    } else {
      let payload = {
        universityId: university.id,
        branchId: branch.id,
      };
      setState({
        ...state,
        universityName: university,
      });
      dispatch(createUniversityBranch(payload));
      remove(index);
    }
  };

  const onDiscard = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    setTimeout(() => {
      history.push({
        pathname: examSpacePath,
        tab: 0,
      });
    }, 1000);
    setNotify({
      isOpen: true,
      message: "Discarded",
      type: "warning",
    });
  };

  return (
    <>
      <div className={classes.breadCrumbsContainer}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />}>
          <Typography
            style={{
              cursor: "pointer",
              fontWeight: "500",
              color: "#1093FF",
            }}
            onClick={() => {
              history.push({
                pathname: examSpacePath,
                tab: 0,
              });
            }}
          >
            Exam Space
          </Typography>
          <Typography style={{ fontWeight: "400", color: "#001D3A" }}>
            Add University
          </Typography>
        </Breadcrumbs>
      </div>
      <div>
        <BackHandler
          title={`Add University`}
          tab={0}
          pathname={examSpacePath}
        />
      </div>

      <div>
        <CustomStepper stepsLabel={steps} activeStep={0} />
      </div>

      <CreatePostContainer>
        <Formik
          initialValues={state}
          onSubmit={(values, { resetForm }) => {
            if (validate(values)) {
              handleNext();
            }
          }}
          enableReinitialize
        >
          {({
            handleSubmit,
            errors,
            handleChange,
            values,
            touched,
            setFieldValue,
            submitForm,
          }) => {
            return (
              <>
                <div className='CreatePost'>
                  <Form onSubmit={handleSubmit} autoComplete='off'>
                    <Grid
                      container
                      spacing={1}
                      direction='column'
                      style={{ margin: 0 }}
                    >
                      <Grid item style={{ padding: 0, margin: "0 0 24px 0" }}>
                        <h5 className={classes.univBranchHeading}>
                          University
                        </h5>
                      </Grid>

                      <Grid
                        container
                        spacing={1}
                        direction='row'
                        style={{ margin: "0 0 30px 0", alignItems: "center" }}
                      >
                        <Grid item md={4} style={{ padding: 0 }}>
                          <Autocomplete
                            id='universityName'
                            name='universityName'
                            getOptionLabel={(option) => option?.name}
                            options={universityOptions}
                            disabled={state.universityName.id}
                            onChange={(e, value) => {
                              setFieldValue("universityName", value);
                            }}
                            fullWidth
                            value={values.universityName}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='University Name'
                                name='universityName'
                                variant='outlined'
                                error={
                                  touched.universityName &&
                                  Boolean(values.universityName === null)
                                }
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={12}>
                      <Divider
                        style={{
                          backgroundColor: "#BEBEBE",
                          marginBottom: "24px",
                        }}
                      />
                    </Grid>
                    <h5 className={classes.univBranchHeading}>
                      Branch Details
                    </h5>
                    {!loading ? (
                      <TblContainer>
                        <TblHead />
                        {branchLists && (
                          <TableBody>
                            {recordsAfterPagingAndSorting().map(
                              (item, index) => {
                                return (
                                  <TableRow key={item.branchId}>
                                    <TableCell className={classes.rowNo}>
                                      {index + 1}
                                    </TableCell>
                                    <TableCell
                                      className={classes.branch}
                                    >{`${item.branchName}`}</TableCell>
                                    <TableCell className={classes.actions}>
                                      <Controls.ActionButton
                                        onClick={() => openInPage(item)}
                                      >
                                        <EditOutlinedIcon
                                          fontSize='small'
                                          color='primary'
                                        />
                                      </Controls.ActionButton>
                                      <Controls.ActionButton
                                        onClick={() => handleDeleteClick(item)}
                                      >
                                        <DeleteIcon
                                          fontSize='small'
                                          color='secondary'
                                        />
                                      </Controls.ActionButton>
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )}
                          </TableBody>
                        )}
                      </TblContainer>
                    ) : (
                      <Loader />
                    )}

                    <div style={{ marginTop: "30px" }}>
                      <FieldArray
                        name='branchList'
                        className={classes.fieldStyle}
                        render={({ insert, remove, push }) => (
                          <Grid
                            container
                            spacing={1}
                            direction='column'
                            style={{ margin: 0 }}
                          >
                            {values.branchList.map((key, index) => (
                              <>
                                <Grid
                                  container
                                  style={{
                                    alignItems: "center",
                                    marginBottom: "15px",
                                  }}
                                >
                                  <Grid item md={4}>
                                    <Autocomplete
                                      id='branchName'
                                      name={`branchList.${index}.branch`}
                                      getOptionLabel={(option) =>
                                        option?.branch
                                      }
                                      options={branchOptions}
                                      onChange={(e, value) => {
                                        setFieldValue(
                                          `branchList.${index}.branch`,
                                          value !== null
                                            ? value
                                            : state.branchList[0].branch
                                        );
                                      }}
                                      fullWidth
                                      value={values.branchList[index].branch}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label='Branch Name'
                                          name={`branchList.${index}.branch`}
                                          variant='outlined'
                                        />
                                      )}
                                    />
                                  </Grid>

                                  <Grid item md={2}>
                                    <Controls.ActionButton
                                      onClick={() => remove(index)}
                                    >
                                      <DeleteIcon
                                        fontSize='small'
                                        color='secondary'
                                      />
                                    </Controls.ActionButton>
                                  </Grid>

                                  <Grid item xs>
                                    <ButtonsContainer
                                      style={{ justifyContent: "flex-end" }}
                                    >
                                      <Controls.Button
                                        name={`branchList.${index}.done`}
                                        text={`Done`}
                                        variant='contained'
                                        color='primary'
                                        className={classes.btnStyle}
                                        onClick={() =>
                                          handleDone(
                                            values.universityName,
                                            values.branchList[index].branch,
                                            index,
                                            remove
                                          )
                                        }
                                      />
                                    </ButtonsContainer>
                                  </Grid>
                                </Grid>
                              </>
                            ))}
                            <Controls.Button
                              text='Add Branch'
                              variant='contained'
                              color='primary'
                              startIcon={<AddIcon />}
                              className={classes.newButton}
                              onClick={() => push({ branch: "", done: "" })}
                            />
                          </Grid>
                        )}
                      />
                    </div>
                  </Form>
                </div>
                <ButtonsContainer className={classes.btnContainer}>
                  <Controls.Button
                    text={`Cancel`}
                    variant='outlined'
                    color='primary'
                    className={classes.btnStyle}
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to discard this?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDiscard();
                        },
                      });
                    }}
                  />
                  <Controls.Button
                    text={`Next`}
                    variant='contained'
                    color='primary'
                    className={classes.btnStyle}
                    type={"submit"}
                    onClick={submitForm}
                  />
                </ButtonsContainer>
              </>
            );
          }}
        </Formik>
      </CreatePostContainer>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
