import { Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { GSContainer, useStyles } from "../../Asset/StyledComponents/Styles";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  clearData,
  createGradSchoolIeltslList,
  getGradSchoolIeltsList,
} from "../../Actions/GradSchoolAction";
import MySnackBar from "../MySnackBar";

export default function GradSchoolIelts(props) {
  const { getGradSchoolIeltsReducer } = useSelector(
    (state) => state.GradSchoolReducer
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const [gradSclIeltsVariant, setGradSclIeltsVariant] = useState({
    ieltsTotal: null,
    ieltsReading: null,
    ieltsListening: null,
    ieltsWriting: null,
    ieltsSpeaking: null,
    id: "",
  });

  const [state, setState] = useState({
    open: false,
    message: "",
    color: "",
  });
  const coursesFields = [
    {
      name: "ieltsTotal",
      value: gradSclIeltsVariant.ieltsTotal,
      label: "IELTS Total",
      type: "number",
    },

    {
      name: "ieltsListening",
      value: gradSclIeltsVariant.ieltsListening,
      label: "IELTS Listening",
      type: "number",
    },
    {
      name: "ieltsReading",
      value: gradSclIeltsVariant.ieltsReading,
      label: "IELTS Reading",
      type: "number",
    },
    {
      name: "ieltsWriting",
      value: gradSclIeltsVariant.ieltsWriting,
      label: "IELTS Writing",
      type: "number",
    },
    {
      name: "ieltsSpeaking",
      value: gradSclIeltsVariant.ieltsSpeaking,
      label: "IELTS Speaking",
      type: "number",
    },
  ];
  const onChange = (e) => {
    const { name, value } = e.target;
    setGradSclIeltsVariant({
      ...gradSclIeltsVariant,
      [name]: value,
    });
  };

  const handleSave = () => {
    const {
      ieltsTotal,
      ieltsReading,
      ieltsListening,
      ieltsWriting,
      ieltsSpeaking,
      id,
    } = gradSclIeltsVariant;
    if (
      ieltsTotal ||
      ieltsReading ||
      ieltsListening ||
      ieltsWriting ||
      ieltsSpeaking
    ) {
      if (id.length > 0) {
        let obj = {
          ieltsTotal: Number(ieltsTotal) === 0 ? null : Number(ieltsTotal),
          ieltsListening:
            Number(ieltsListening) === 0 ? null : Number(ieltsListening),
          ieltsReading:
            Number(ieltsReading) === 0 ? null : Number(ieltsReading),
          ieltsWriting:
            Number(ieltsWriting) === 0 ? null : Number(ieltsWriting),
          ieltsSpeaking:
            Number(ieltsSpeaking) === 0 ? null : Number(ieltsSpeaking),
          id: id,
        };
        dispatch(
          createGradSchoolIeltslList(obj, (res) => {
            if (res.success) {
              setState({
                open: true,
                message: "Updated Successfully",
                color: "success",
              });
              dispatch(getGradSchoolIeltsList(props.location.state.id));
            }
          })
        );
      } else {
        let obj = {
          ieltsTotal: Number(ieltsTotal) === 0 ? null : Number(ieltsTotal),
          ieltsListening:
            Number(ieltsListening) === 0 ? null : Number(ieltsListening),
          ieltsReading:
            Number(ieltsReading) === 0 ? null : Number(ieltsReading),
          ieltsWriting:
            Number(ieltsWriting) === 0 ? null : Number(ieltsWriting),
          ieltsSpeaking:
            Number(ieltsSpeaking) === 0 ? null : Number(ieltsSpeaking),
          gradSchoolProgram:
            props.gradSchoolProgramCreatedId || props.location.state.id,
        };
        dispatch(
          createGradSchoolIeltslList(obj, (res) => {
            if (res.success) {
              setState({
                open: true,
                message: "Saved Successfully",
                color: "success",
              });
              dispatch(clearData());
            }
          })
        );
      }
    }
  };

  useEffect(() => {
    if (getGradSchoolIeltsReducer !== null) {
      if (getGradSchoolIeltsReducer.success) {
        const {
          ieltsListening,
          id,
          ieltsReading,
          ieltsSpeaking,
          ieltsTotal,
          ieltsWriting,
        } = getGradSchoolIeltsReducer.data;
        setGradSclIeltsVariant({
          ieltsTotal: ieltsTotal ? ieltsTotal : null,
          ieltsReading: ieltsReading ? ieltsReading : null,
          ieltsListening: ieltsListening ? ieltsListening : null,
          ieltsWriting: ieltsWriting ? ieltsWriting : null,
          ieltsSpeaking: ieltsSpeaking ? ieltsSpeaking : null,
          id: id,
        });
      }
    }
  }, [getGradSchoolIeltsReducer]);

  useEffect(() => {
    if (props.location.state.id) {
      dispatch(getGradSchoolIeltsList(props.location.state.id));
    }
  }, []);

  return (
    <React.Fragment>
      <GSContainer>
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems="center"
          spacing={4}
        >
          <Grid item md={10}>
            <Grid container spacing={6}>
              {coursesFields.map((item) => {
                return (
                  <Grid item md={6}>
                    <TextField
                      value={item.value}
                      onChange={onChange}
                      name={item.name}
                      label={item.label}
                      fullWidth
                      type={item.type}
                      InputLabelProps={{
                        shrink: item.value === null ? false : true,
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item md={2}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              className={classes.btnStyles}
              onClick={handleSave}
              disabled={
                props.location.state.id
                  ? false
                  : props.gradSchoolProgramCreatedId
                  ? false
                  : true
              }
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </GSContainer>
      <MySnackBar
        snackOpen={state.open}
        snackVariant={state.color}
        snackMsg={state.message}
        onClose={() =>
          setState({
            open: false,
            message: "",
            color: "",
          })
        }
      />
    </React.Fragment>
  );
}
