import React, { useState, useEffect, useLayoutEffect } from 'react';
// import TestimonialForm from './TestimonialForm';
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  Typography
} from '@material-ui/core';
import useTable from '../Utils/useTable';
import Controls from '../Utils/controls/Controls';
import { Search } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import Popup from '../Utils/Popup';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CloseIcon from '@material-ui/icons/Close';
import Notification from '../Utils/Notification';
import Loader from '../Utils/controls/Loader';
import MuiAlert from '@material-ui/lab/Alert';
import ConfirmDialog from '../Utils/ConfirmDialog';
import { useSelector, useDispatch } from 'react-redux';
import {
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Grid,
    TextField,
    FormControlLabel,
    Checkbox,
  } from "@material-ui/core";
import {
  listTestimonials,
  deleteTestimonial,
  createTestimonial,
  updateTestimonial,
} from '../../Actions/TestimonialActions';
import {CRUDwebinarPath, FilePath, Folder2Path, studentPath } from "../RoutePaths";
import BackButton from '../../Asset/Images/backbutton.svg';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Breadcrumbs} from '@material-ui/core'
import { Typo } from '../Utils/controls/Styles';
import { Co2Sharp } from '@mui/icons-material';
import { useParams } from "react-router-dom";

import { getDocumentList, getToolsCall, getWebinarCall } from '../../Actions/CRUDwebinar';
import moment from 'moment';

const Alert = (props) => <MuiAlert elevation={6} variant='filled' {...props} />;

const useStyles = makeStyles((theme) => ({
    
  pageContent: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(3),
  },
  folderIcon:{
  padding:"3%",
  alignItems:"center",
  alignContent:"center",
  textAlign:"center"
  },
 
}));



export default function FilesCorner(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { documentList} = useSelector((state) => state.CRUDwebinarReducer);
  const params = useParams();
//   const { studentId, productId } = params;
let studentId = sessionStorage.getItem("studentId");
let productId = sessionStorage.getItem("productId")
  console.log( props,studentId,productId)

 

  


  useEffect(() => {
    dispatch(getDocumentList(studentId,productId))
    

  }, []);
  

  console.log(documentList?.data?.data)


  return (
     <>
      
      <div style={{display:"flex",flexDirection:"row",margin:"10px"}}>
          <img
            src={BackButton}
            style={{ cursor: "pointer",marginTop:"-10px" }}
            onClick={() => props.history.goBack()}
             />
               <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <Typography style={{ cursor: "pointer", fontWeight: "600",marginLeft:"10px" }} onClick={()=>props.history.push(studentPath)}>
                Home
              </Typography>
              <Typography style={{ cursor: "pointer", fontWeight: "600" }}>
                Manage Client
              </Typography>
              <Typography style={{ cursor: "pointer", fontWeight: "600" }}>
                Files
              </Typography>
            </Breadcrumbs>
            </div>
            <Grid container xs={12}>
                
                 
                        <Grid item xs={2}>
                        <div className={classes.folderIcon}>
                      <img
                                   src={require("../../Asset/Images/Folder.png")}
                                   alt={"Not supported"}
                                   style={{ cursor: "pointer"}}
                                   onClick={()=>props.history.push(Folder2Path)}
                                 />
                                 </div>
                                 <Typography style={{ cursor: "pointer", fontWeight: "600",color:"grey",textAlign:"center"}}>
                                 {documentList?.data?.data?.name}
                                 </Typography>
                                 
                                 </Grid>
                
                    
           
                   
              </Grid>
           


              
     </>
  );
}
