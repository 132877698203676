import {
  createTheme,
  Divider,
  Grid,
  Typography,
  ThemeProvider,
  withStyles,
  Breadcrumbs,
  Box,
  TextField,
  IconButton,
} from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import TodayDocument from "./TodayDocument";
import TodayWorkCompletion from "./TodayWorkCompletion";
import add from "../../Asset/Images/add.svg";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import pbResource from "../../Asset/Images/PB resource icon.svg";
import {
  listUsersProdBasedPath,
  productActivationPath,
  studentPath,
} from "../RoutePaths";
import {
  getAdminLinkedProduct,
  getSearchingProduct,
  getStagesCount,
  getStudentByStages,
  clearData,
} from "../../Actions/AdminAction";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import BackButton from "../../Asset/Images/backbutton.svg";
import { isEmptyString } from "../Validation";
import MySnackBar from "../MySnackBar";
import ManageTable from "./ManageTable";
import DropDown from "../Controls/DropDown";
import Manage from "../../Asset/Images/Manage.PNG";
import Product from "../../Asset/Images/Product.PNG"

class ObOperationLanding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      adminDepartment: null,
      shrink: false,
      search: null,
      snackOpen: false,
      snackVariant: "",
      snackMsg: "",
    };
  }
  theme = createTheme({
    overrides: {
      MuiDivider: {
        root: {
          margin: "revert",
        },
      },
    },
  });
  componentWillMount() {
    this.props.clearData();
  }
  componentDidMount() {
    // To get admin department
    this.props.getAdminLinkedProduct();
  }
  // componentDidMount() {
  //   // To get Stages Count
  //   this.props.getStagesCount(this.props.match.params.productId);
  // }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.adminLinkedProductDetails !==
      prevProps.adminLinkedProductDetails
    ) {
      this.setState({
        adminDepartment: this.props.adminLinkedProductDetails?.department,
      });

      // window.sessionStorage.setItem(
      //   "adminLinkedProduct",
      //   JSON.stringify(this.props.adminLinkedProductDetails)
      // );
    }
    if (
      this.props?.getSearchingProductId !== prevProps?.getSearchingProductId
    ) {
      if (this.props?.getSearchingProductId?.data?.success) {
        console.log(
          this.props.getSearchingProductId.data.data, "&&&&&tab data"

        )
        this.props.history.push({
          pathname:
            listUsersProdBasedPath + "/" + this.props.match.params.productId,
          state: this.props.getSearchingProductId.data.data,
          // state:
        });
      } else {
        if (this.props?.getSearchingProductId?.message !== undefined) {
          this.setState({
            snackMsg: this.props?.getSearchingProductId?.message,
            snackOpen: true,
            snackVariant: "error",
          });
        }
      }
    }
  }
  handleSnackClose = () => {
    this.setState({
      snackOpen: false,
      snackVariant: "",
      snackMsg: "",
    });
  };

  filterStudentList = (searchingElement) => {
    const { match } = this.props;
    const { search } = this.state;
    const productId = match.params.productId;

    this.props.getSearchingProduct(
      productId,

      searchingElement || search
    );
  };
  // To handle search
  handleSearch = () => {
    const { search } = this.state;
    if (!isEmptyString(search)) {
      this.filterStudentList(search);
    }
    console.log(search, "*********search");
  };
  shrink() {
    this.setState({ shrink: true });
  }

  handleProductActivate = () => {
    this.props.history.push(
      productActivationPath + "/" + this.props.match.params.productId
    );
  };
  componentWillUnmount() {
    console.log("one two three");
  }
  render() {
    const { classes } = this.props;
    const { snackOpen, snackVariant, snackMsg } = this.state;
    return (
      <div>
        <div style={{ display: "flex", flexDirection: "row", margin: "10px" }}>
          <img
            src={BackButton}
            style={{ cursor: "pointer", marginTop: "-10px" }}
            onClick={() => this.props.history.push(studentPath)}
          />
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Typography
              style={{
                cursor: "pointer",
                fontWeight: "600",
                marginLeft: "10px",
              }}
              onClick={() => this.props.history.push(studentPath)}
            >
              Home
            </Typography>
            <Typography style={{ cursor: "pointer", fontWeight: "600" }}>
              Operation
            </Typography>
          </Breadcrumbs>
        </div>
        <Grid container>
          <div style={{ display: "flex", marginLeft: "32rem", marginTop: "-3.5rem", alignItems: "center" }}>

            <Grid item md={3} lg={4}>
              <div
                onClick={() =>
                  this.props.history.push(
                    listUsersProdBasedPath +
                    "/" +
                    this.props.match.params.productId
                  )
                }
                style={{ cursor: "pointer" }}
              // style={{ marginTop: "-3.5rem", marginLeft: "90rem" }}
              >
                <img src={Manage}></img>
                <Typography variant="caption" style={{ fontSize: "0.8rem", fontWeight: 500 }}>
                  Manage Students
                </Typography>
              </div>


            </Grid>
            <Grid item md={1} lg={4}>
              <div
                // className={classes.items}
                onClick={this.handleProductActivate}
                style={{ cursor: "pointer" }}
              // style={{ marginTop: "-3.5rem", marginLeft: "2rem" }}
              >
                <img src={Product}></img>
                <Typography variant="caption" style={{ fontSize: "0.8rem", fontWeight: 500 }}>
                  Product Activate
                </Typography>
              </div>

            </Grid>
            <Grid item md={4} lg={4}>
              <div
              // style={{ marginTop: "-3.5rem", marginLeft: "10rem", height: "0rem", backgroundColor: "pink" }}
              >
                {" "}
                <TextField
                  label={
                    <div style={{ fontSize: "13px", marginRight: "1rem" }}>
                      {`Search by Email ID / Mobile / CLS ID `}
                    </div>
                  }
                  variant="outlined"
                  value={this.state.search}
                  onChange={(e) => {
                    this.setState({ search: e.target.value });
                  }}
                  InputLabelProps={{
                    shrink: this.state.shrink,
                  }}
                  onFocus={() => this.shrink()}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      document.getElementById("search").click();
                    }
                  }}
                  fullWidth
                />
              </div>
              <div
                style={{ marginTop: "-4rem", marginLeft: "13rem" }}
              >
                <IconButton
                  style={{ marginLeft: "8px" }}
                  onClick={this.handleSearch}
                  color="primary"
                  id={"search"}
                  aria-label="search"
                >
                  <SearchRoundedIcon />
                </IconButton>
              </div>

            </Grid>
          </div>
        </Grid>
        {/* <div className={classes.rowContainer}> */}

        {/* </div> */}


        <Grid container>
          <Grid item md={4}>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12}>
                <TodayWorkCompletion {...this.props} />
              </Grid>
              <Grid item md={12} sm={12}>
                <TodayDocument {...this.props} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={1}>
            <ThemeProvider theme={this.theme}>
              <Divider
                orientation={"vertical"}
                style={{ backgroundColor: "#cacaca", height: "100vh" }}
              />
            </ThemeProvider>

          </Grid>
          <Grid item md={7}>
            <div
              style={{
                width: "100%",
                marginTop: "1rem",
                height: "60vh",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
                boxShadow: "0px 0px 8px 1px rgba(72, 166, 227, 0.1)",
                cursor: "pointer",
              }}
            >

              <div>
                <ManageTable />
              </div>

            </div>



          </Grid>


          {/* <div className={classes.quickAccess}>
            <Typography style={{ fontWeight: 600 }}>Quick Access</Typography>

          </div> */}
        </Grid>
        <MySnackBar
          snackOpen={snackOpen}
          snackMsg={snackMsg}
          snackVariant={snackVariant}
          onClose={this.handleSnackClose}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  adminLinkedProductDetails: state.AdminReducer.adminLinkedProductDetails,
  getSearchingProductId: state.AdminReducer.getSearchingProductId,
  // getStagesCount: state.AdminReducer.getStagesCount
});

const useStyles = () => ({
  quickAccess: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "100px",
  },
  rowContainer: {
    display: "flex",
  },
  items: {
    marginRight: "25px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    boxShadow: "0px 0px 8px 1px rgba(72, 166, 227, 0.1)",
  },
});

export default connect(mapStateToProps, {
  getAdminLinkedProduct,
  getSearchingProduct,
  // getStagesCount,
  getStudentByStages,
  clearData,
})(withStyles(useStyles)(ObOperationLanding));
