import React, { useEffect, useRef, useState } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { useDispatch } from 'react-redux';
import ThumbUp from '@material-ui/icons/ThumbUp';
import DescriptionIcon from '@mui/icons-material/Description';
import ThumbDown from '@material-ui/icons/ThumbDown';
import FilterIcon from '@material-ui/icons/Tune';
import SortIcon from '@material-ui/icons/CompareArrows';
import SearchIcon from '@material-ui/icons/SearchSharp';
import ExportIcon from '@material-ui/icons/GetApp';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import ClearIcon from '@material-ui/icons/Clear';
import Loader from '../../Utils/controls/Loader';
import { Button, IconButton } from '@material-ui/core';
import { FormControl, MenuItem, Select, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Notification from '../../Utils/Notification';
import {
  addUpdateFormDetails,
  clearData,
  getFormDetails,
  getRoundLists,
  getStudentList,
  ibhMasterSheetDownload,
  updateStudentStatus,
} from '../../../Actions/IbhAction';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Controls from '../../Utils/controls/Controls';
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Popup from '../Components/Popup';
import DynamicFormContainer from '../Components/DynamicForm';
import { Formik } from 'formik';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DateRangePickerComp from '../Components/DateRangePicker';
import '../../../Asset/All.css';

function IbhResult() {
  const [noOfDrivesTaken, setNoOfDrivesTaken] = useState('all');
  const [whatsappMsgSentCount, setWhatsappMsgSentCount] = useState('all');
  const dispatch = useDispatch();
  const [rounds, setRounds] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRound, setSelectedRound] = useState([]);
  const [customQuestions, setCustomQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [masterReportIsVisible, setMasterReportIsVisible] = useState();
  const [dateOfEntry, setDateOfEntry] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [whatsappLastMsgSentDate, setWhatsappLastMsgSentDate] = useState(null);
  const [overAllScore, setOverAllScore] = useState('all');
  const [openPopup, setOpenPopup] = useState(false);
  const [formTextStatus, setFormTextStatus] = useState(false);

  const [startDate, setStartDate] = useState(new Date(new Date().setUTCHours(0, 0, 0)));
  const [endDate, setEndDate] = useState(new Date(new Date().setUTCHours(23, 59, 59)));
  const [value, setValue] = useState(false);

  const [state, setState] = useState({
    ibhForm: [
      {
        questions: '',
        answerType: 'TEXT',
        ibhFormQuestionChoices: [],
      },
    ],
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });

  let columns = [
    {
      title: 'ID',
      field: 'studentId',
      hidden: true,
    },
    {
      title: 'Date of Entry',
      field: 'dateOfEntry',
      filtering: true,
      editable: false,
      sorting: true,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      type: 'date',
      dateSetting: {
        locale: 'en-GB',
      },
      filterComponent: () => (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            margin='normal'
            id='date-picker-dialog'
            style={{ width: '120px' }}
            format='dd/MM/yyyy'
            clearable
            InputAdornmentProps={{ position: 'start' }}
            value={dateOfEntry}
            onChange={(event) => {
              setDateOfEntry(event);
              let filteredData = tableData.filter(
                (student) =>
                  new Date(student.dateOfEntry).getDate() === new Date(event).getDate() || !event
              );
              setFilteredData(filteredData);
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      ),
    },
    {
      title: 'Name',
      field: 'fullName',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      editable: false,
      filtering: true,
      sorting: true,
      defaultSort: 'asc',
      emptyValue: () => <em>--</em>,
      render: (rowData) => (
        <div style={{ width: '150px', wordWrap: 'break-word' }}>{rowData.fullName}</div>
      ),
    },
    {
      title: 'Form Filled',
      field: 'formFilled',
      sorting: false,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div style={{ width: '70px' }}>{rowData.formFilled}</div>,
      lookup: { Yes: 'Yes', No: 'No' },
    },
    {
      title: 'No. of drives taken',
      field: 'noOfDrivesTaken',
      sorting: false,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      filterComponent: () => (
        <Select
          id='noOfDrivesTaken'
          value={noOfDrivesTaken}
          style={{ width: '110px' }}
          onChange={(e) => {
            setNoOfDrivesTaken(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.noOfDrivesTaken) >= e.target.value || e.target.value === 'all'
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={'all'}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={60}>60</MenuItem>
          <MenuItem value={90}>90</MenuItem>
          <MenuItem value={120}>120</MenuItem>
        </Select>
      ),
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div>{rowData.noOfDrivesTaken}</div>,
    },
    {
      title: 'Elev8 Premium',
      field: 'elev8Premium',
      sorting: false,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div>{rowData.elev8Premium === false ? 'No' : 'Yes'}</div>,
      lookup: { true: 'Yes', false: 'No' },
    },
    {
      title: 'Status',
      field: 'status',
      sorting: false,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      render: (rowData) => (
        <div
          style={{
            width: '100px',
            background:
              rowData.status === 'QUALIFIED'
                ? '#02ae02d1'
                : rowData.status === 'NOTQUALIFIED'
                ? '#f80a0aaa'
                : '#9f9f9f',
            borderRadius: '20px',
            textAlign: 'center',
            padding: 8,
            fontSize: '13px',
            color: '#ffffff',
            fontWeight: 'bold',
          }}
        >
          {rowData.status === 'QUALIFIED'
            ? 'Qualified'
            : rowData.status === 'NOTQUALIFIED'
            ? 'Not Qualified'
            : 'NA'}
        </div>
      ),
      lookup: {
        QUALIFIED: 'Qualified',
        NOTQUALIFIED: 'Not Qualified',
        NA: 'NA',
      },
    },
    {
      title: 'DOB',
      field: 'dateOfBirth',
      filtering: true,
      editable: false,
      sorting: true,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      type: 'date',
      dateSetting: {
        locale: 'en-GB',
      },
      filterComponent: () => (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            margin='normal'
            id='date-picker-dialog'
            style={{ width: '100px' }}
            format='dd/MM/yyyy'
            clearable
            InputAdornmentProps={{ position: 'start' }}
            value={dateOfBirth}
            onChange={(event) => {
              setDateOfBirth(event);
              let filteredData = tableData.filter(
                (student) =>
                  new Date(student.dateOfBirth).getDate() === new Date(event).getDate() || !event
              );
              setFilteredData(filteredData);
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      ),
    },
    {
      title: 'YOG',
      field: 'yearOfPassing',
      filtering: true,
      editable: false,
      sorting: true,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div style={{ width: '100px' }}>{rowData.yearOfPassing}</div>,
    },
    {
      title: 'Gender',
      field: 'gender',
      filtering: true,
      editable: false,
      sorting: false,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div style={{ width: '80px' }}>{rowData.gender}</div>,
    },
    {
      title: 'Contact Number',
      field: 'phoneNumber',
      filtering: true,
      editable: false,
      sorting: false,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div style={{ width: '80px' }}>{rowData.phoneNumber}</div>,
    },
    {
      title: 'Email',
      field: 'emailId',
      filtering: true,
      editable: false,
      sorting: false,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      render: (rowData) => (
        <div style={{ width: '180px', wordWrap: 'break-word' }}>{rowData.emailId}</div>
      ),
    },
    {
      title: 'WA Message Sent Count',
      field: 'whatsappMsgSentCount',
      sorting: false,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      filterComponent: () => (
        <Select
          id='whatsappMsgSentCount'
          style={{ width: '110px' }}
          value={whatsappMsgSentCount}
          onChange={(e) => {
            setWhatsappMsgSentCount(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.whatsappMsgSentCount) >= e.target.value || e.target.value === 'all'
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={'all'}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={60}>60</MenuItem>
          <MenuItem value={90}>90</MenuItem>
          <MenuItem value={120}>120</MenuItem>
          <MenuItem value={150}>150</MenuItem>
          <MenuItem value={180}>180</MenuItem>
          <MenuItem value={200}>200</MenuItem>
        </Select>
      ),
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div>{rowData.whatsappMsgSentCount}</div>,
    },
    {
      title: 'Last WA Message Sent',
      field: 'whatsappLastMsgSentDate',
      filtering: true,
      editable: false,
      sorting: true,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      type: 'date',
      dateSetting: {
        locale: 'en-GB',
      },
      filterComponent: () => (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            margin='normal'
            id='date-picker-dialog'
            style={{ width: '100px' }}
            format='dd/MM/yyyy'
            clearable
            InputAdornmentProps={{ position: 'start' }}
            value={whatsappLastMsgSentDate}
            onChange={(event) => {
              setWhatsappLastMsgSentDate(event);
              let filteredData = tableData.filter(
                (student) =>
                  new Date(student.whatsappLastMsgSentDate).getDate() ===
                    new Date(event).getDate() || !event
              );
              setFilteredData(filteredData);
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      ),
    },
    {
      title: 'Score in CLSA',
      field: 'overAllScore',
      editable: false,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      filterComponent: () => (
        <Select
          id='overAllScore'
          style={{ width: '110px' }}
          value={overAllScore}
          onChange={(e) => {
            setOverAllScore(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.overAllScore) >= e.target.value || e.target.value === 'all'
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={'all'}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={60}>60%</MenuItem>
          <MenuItem value={70}>70%</MenuItem>
          <MenuItem value={80}>80%</MenuItem>
          <MenuItem value={90}>90%</MenuItem>
        </Select>
      ),
      emptyValue: () => <em>--</em>,
      render: (rowData) => (
        <div
          style={{
            background:
              rowData.overAllScore >= 70.0
                ? '#02ae02d1'
                : rowData.overAllScore < 70.0 && rowData.overAllScore > 0
                ? '#f80a0aaa'
                : '#9f9f9f',
            borderRadius: '10px',
            textAlign: 'center',
            width: '100px',
            padding: '10px 5px',
            fontSize: '14px',
            color: '#ffffff',
            fontWeight: 'bold',
          }}
        >
          {rowData.overAllScore}
        </div>
      ),
    },
    {
      title: 'Banding',
      field: 'softwareBandScore',
      filtering: true,
      editable: false,
      sorting: false,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div style={{ width: '70px' }}>{rowData.softwareBandScore}</div>,
    },
    {
      title: 'Video Bands',
      field: 'softwareVideoBand',
      filtering: true,
      editable: false,
      sorting: false,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      render: (rowData) => (
        <div style={{ width: '120px', wordWrap: 'break-word' }}>{rowData.softwareVideoBand}</div>
      ),
    },
  ];

  //Pushing the custom questions into Columns array
  const customQuestionFields = customQuestions?.forEach((question, idx) => {
    columns.push({
      title: question,
      field: 'answers',
      filtering: false,
      sorting: false,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) => (
        <div style={{ width: '200px', wordWrap: 'break-word' }}>
          {rowData?.answers && rowData?.answers[idx] ? (
            rowData?.answers[idx].includes('http') ? (
              <IconButton aria-label='pdf' size='large'>
                <a href={rowData?.answers[idx]} target='_blank'>
                  <PictureAsPdfIcon style={{ color: 'red' }} />
                </a>
              </IconButton>
            ) : (
              rowData?.answers[idx]
            )
          ) : (
            '--'
          )}
        </div>
      ),
      customFilterAndSearch: (term, rowData) =>
        Boolean(
          rowData?.answers &&
            rowData?.answers[idx] &&
            rowData?.answers[idx].toLowerCase().indexOf(term.toLowerCase()) > -1
        ),
    });
    return columns;
  });

  // Get all Round names & Custom questions
  const fetchDriveDetails = () => {
    setIsLoading(true);
    dispatch(
      getRoundLists(false, (response) => {
        setCustomQuestions(response?.data[0]?.questionList);
        setRounds(response?.data);
        setIsLoading(false);
      })
    );
  };

  //Load More Students
  const fetchMoreStudentList = (number) => {
    // console.log(pageNo, 'pageNo');
    setIsLoading(true);
    setPageNo((prev) => prev + number);
    let requestBody = {
      fromDate: startDate,
      toDate: endDate,
    };
    dispatch(
      getStudentList(pageNo, selectedRound?.id, requestBody, (response) => {
        setTotalItems(response?.data?.totalElements ?? 0);
        setTableData([...(tableData || []), ...(response?.data?.content ?? [])]);
        setFilteredData([...(tableData || []), ...(response?.data?.content ?? [])]);
        setIsLoading(false);
        setMasterReportIsVisible(response?.data?.isEnableMasterReport);
      })
    );
    setValue(false);
  };

  useEffect(() => {
    fetchDriveDetails();
    dispatch(
      getFormDetails((response) => {
        if (response?.data?.length) {
          setState({ ...state, ibhForm: response?.data });
          setFormTextStatus(true);
        }
      })
    );
    return () => {
      // Clearing all the data in the reducer while left from that page like ComponentWillUnmount.
      dispatch(clearData());
    };
  }, []);

  useEffect(() => {
    if (tableData.length < totalItems) {
      setIsLoading(true);
      fetchMoreStudentList(1);
    } else {
      setIsLoading(false);
    }
  }, [tableData]);

  //Updates the specific round details
  const _roundUpdate = (data) => {
    setIsLoading(true);
    let payload = {
      roundId: selectedRound?.id,
      ibhRoundStatusList: data,
    };

    //once the update is success, it re-renders the ui with latest information
    dispatch(
      updateStudentStatus(payload, (res) => {
        if (res.success) {
          fetchDriveDetails();
          setNotify({
            isOpen: true,
            message: res.message,
            type: 'success',
          });
          setPageNo(0);
        } else {
          setIsLoading(false);
          setNotify({
            isOpen: true,
            message: res.message,
            type: 'error',
          });
        }
      })
    );
  };

  const handleSave = (values, setFieldValue) => {
    let arr = [...values];
    let payload = arr.map((item, index) => {
      if (item.ibhFormQuestionChoices?.length) {
        let arr2 = [...item.ibhFormQuestionChoices];
        let values = arr2.map((item2, index2) => ({ ...item2, orderNo: index2 + 1 }));
        return { ...item, orderNo: index + 1, ibhFormQuestionChoices: values };
      } else {
        return { ...item, orderNo: index + 1 };
      }
    });

    dispatch(
      addUpdateFormDetails(payload, (response) => {
        if (response.success === true) {
          setNotify({
            isOpen: true,
            message: 'Question created/Updated successfully!',
            type: 'success',
          });
          dispatch(
            getFormDetails((response) => {
              if (response.success === true) {
                setFieldValue('ibhForm', response?.data);
                if (response?.data?.length) {
                  setFormTextStatus(true);
                }
              }
            })
          );
        } else {
          setNotify({
            isOpen: true,
            message: 'Something went wrong!!!',
            type: 'error',
          });
        }
      })
    );
  };

  return (
    <div>
      {selectedRound?.id && (
        <div
          style={{
            marginTop: '2rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          <Button
            disabled={
              (tableData.length > 0 && tableData.length === totalItems) ||
              isLoading ||
              tableData.length !== totalItems
            }
            color='primary'
            variant='outlined'
            style={{ height: '45px' }}
            onClick={() => fetchMoreStudentList(1)}
          >
            {isLoading ? 'Loading...' : 'Load More Students List'}
          </Button>

          {tableData.length > 0 && (
            <h6
              style={{
                marginBottom: 0,
                padding: '10px 20px',
                backgroundColor: ' #1093FF',
                borderRadius: '10px',
                color: '#ffffff',
                fontWeight: 800,
                fontSize: '15px',
              }}
            >
              {tableData.length}/{totalItems}
            </h6>
          )}

          {formTextStatus === true ? (
            <Controls.ActionButton
              style={{ height: '45px' }}
              disabled={tableData.length !== totalItems}
              variant='outlined'
              onClick={() => {
                setOpenPopup(true);
              }}
            >
              <EditOutlinedIcon fontSize='small' color='primary' /> &nbsp; Edit Form
            </Controls.ActionButton>
          ) : (
            <Controls.ActionButton
              style={{ height: '45px' }}
              disabled={tableData.length !== totalItems}
              variant='outlined'
              onClick={() => {
                setOpenPopup(true);
              }}
            >
              <AddBoxIcon fontSize='small' color='primary' /> &nbsp; Add Form
            </Controls.ActionButton>
          )}
          <div>
            <DateRangePickerComp
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setValue={setValue}
              setPageNo={setPageNo}
            />
          </div>
        </div>
      )}

      <Formik
        initialValues={state}
        // validationSchema={records?.isEvent ? eventvalidationSchema : postvalidationSchema}
        onSubmit={(values, { resetForm }) => {
          // resetForm();
        }}
        enableReinitialize
      >
        {({ handleSubmit, errors, handleChange, values, touched, setFieldValue, submitForm }) => {
          return (
            <>
              <Popup title='Add or Edit IBH Form' openPopup={openPopup} setOpenPopup={setOpenPopup}>
                <DynamicFormContainer
                  ibhForm={values.ibhForm}
                  setFieldValue={setFieldValue}
                  handleSave={handleSave}
                  // handleDelete={handleDelete}
                />
              </Popup>
            </>
          );
        }}
      </Formik>

      <MaterialTable
        columns={columns}
        data={filteredData}
        components={{
          Toolbar: (props) => (
            <div
              style={{
                display: 'flex',
                marginTop: '2rem',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderTop: '5px double #007d93',
                borderBottom: '3px solid #0094b1',
                borderRadius: '7px',
              }}
            >
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <FormControl>
                    <Autocomplete
                      style={{ width: '300px', paddingLeft: '2rem' }}
                      name='rounds'
                      getOptionLabel={(option) => option?.name}
                      options={rounds ?? []}
                      value={selectedRound}
                      onChange={(e, value) => {
                        if (value) {
                          setSelectedRound(value);
                          setTableData([]);
                          setFilteredData([]);
                          setPageNo(0);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Select Round'
                          name='rounds'
                          variant='outlined'
                        />
                      )}
                    />
                  </FormControl>
                  <div
                    id='material-table'
                    style={{ flexGrow: 1, padding: '2rem 0rem', marginLeft: '30px' }}
                  >
                    <MTableToolbar {...props} />
                  </div>
                </>
              )}
            </div>
          ),
        }}
        actions={[
          {
            icon: () => (
              <ThumbUp
                style={{ color: selectedRound?.isIbhSurveyForm === true ? 'gray' : 'green' }}
              />
            ),
            tooltip: 'Accept',
            disabled: selectedRound?.isIbhSurveyForm === true,
            onClick: (e, data) => {
              let filterStatus = data.map((student) => {
                student.status = 'QUALIFIED';
                return student;
              });
              setTableData(tableData, filterStatus);
              setFilteredData(filteredData, filterStatus);
              _roundUpdate(filterStatus);
            },
          },
          {
            icon: () => (
              <ThumbDown
                style={{ color: selectedRound?.isIbhSurveyForm === true ? 'gray' : 'red' }}
              />
            ),
            tooltip: 'Reject',
            disabled: selectedRound?.isIbhSurveyForm === true,
            onClick: (e, data) => {
              let filterStatus = data.map((student) => {
                student.status = 'NOTQUALIFIED';
                return student;
              });
              setTableData(tableData, filterStatus);
              setFilteredData(filteredData, filterStatus);
              _roundUpdate(filterStatus);
            },
          },
          {
            icon: () => (
              <DescriptionIcon
                style={{ color: masterReportIsVisible === false ? 'gray' : '#1093FF' }}
              />
            ),
            tooltip: 'Send Master Sheet to Email',
            disabled: masterReportIsVisible === false,
            onClick: (e, data) => {
              let filteredIds = data.map((student) => student.studentId);
              let obj = { studentIdList: filteredIds };
              dispatch(
                ibhMasterSheetDownload(obj, (response) => {
                  if (response.status === 200) {
                    setPageNo(0);
                    setTableData([]);
                    setNotify({
                      isOpen: true,
                      message: 'Master Sheet will be sent to your Email soon...',
                      type: 'success',
                    });
                  } else {
                    setNotify({
                      isOpen: true,
                      message: 'Something went wrong, Unable to download..!',
                      type: 'error',
                    });
                  }
                })
              );
            },
          },
        ]}
        localization={{
          toolbar: {
            nRowsSelected: 'No. of Student(s) : {0}',
          },
        }}
        options={{
          sorting: true,
          search: true,
          searchFieldAlignment: 'right',
          searchFieldVariant: 'outlined',
          filtering: true,
          paging: false,
          exportButton: false,
          exportAllData: false,
          exportFileName: 'Master Sheet',
          addRowPosition: 'first',
          actionsColumnIndex: -1,
          selection: true,
          showSelectAllCheckbox: true,
          showTextRowsSelected: true,
          grouping: false,
          columnsButton: true,
          rowStyle: (data, index) => (index % 2 === 0 ? { background: '#f5f5f5' } : null),
          headerStyle: { background: 'aliceblue', color: '#000' },
        }}
        title={''}
        icons={{
          Filter: () => <FilterIcon style={{ color: '#807f7f' }} />,
          SortArrow: () => (
            <SortIcon fontSize='small' style={{ color: '#c6c6c6', transform: 'rotate(-90deg)' }} />
          ),
          Clear: () => <ClearIcon />,
          Export: () => <ExportIcon style={{ color: 'green' }} />,
          ResetSearch: () => <ClearIcon />,
          Search: () => <SearchIcon />,
          ViewColumn: () => <ViewColumnIcon />,
        }}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}

export default IbhResult;
