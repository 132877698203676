import React, { useState, useEffect } from 'react';
// import TestimonialForm from './TestimonialForm';
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  Typography
} from '@material-ui/core';
import useTable from '../Utils/useTable';
import Controls from '../Utils/controls/Controls';
import { Search } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import Popup from '../Utils/Popup';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CloseIcon from '@material-ui/icons/Close';
import Notification from '../Utils/Notification';
import Loader from '../Utils/controls/Loader';
import MuiAlert from '@material-ui/lab/Alert';
import ConfirmDialog from '../Utils/ConfirmDialog';
import { useSelector, useDispatch } from 'react-redux';
import {
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Grid,
    TextField,
    FormControlLabel,
    Checkbox,
  } from "@material-ui/core";
import {
  listTestimonials,
  deleteTestimonial,
  createTestimonial,
  updateTestimonial,
} from '../../Actions/TestimonialActions';
import {CRUDwebinarPath, studentPath } from "../RoutePaths";
import BackButton from '../../Asset/Images/backbutton.svg';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Breadcrumbs} from '@material-ui/core'
import { Typo } from '../Utils/controls/Styles';
import { Co2Sharp } from '@mui/icons-material';
import { getToolsCall, getWebinarCall } from '../../Actions/CRUDwebinar';
import moment from 'moment';

const Alert = (props) => <MuiAlert elevation={6} variant='filled' {...props} />;

const useStyles = makeStyles((theme) => ({
  pageContent: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
  },
  searchInput: {
    width: '75%',
  },
  newButton: {
    position: 'absolute',
    right: '10px',
  },
  CRUDcard:{
   border:"1px solid #E7E7E7",
   borderRadius:"10px",
   paddingRight:"20px"
  },
  cardDetails:{
    padding:"10px"
  },
  toolTitle:{
    font:" normal normal normal 20px/16px Poppins",
    letterSpacing:" 0.52px",
    color: "#18202C",
    opacity: "1",
    paddingTop:"10px",
  },
  nameAndDate:{
    fontWeight:"1000"

  },
}));

const headCells = [
  { id: 'studentName', label: 'Student Name' },
  { id: 'program', label: 'Program' },
  { id: 'mixedTag', label: 'Tagging' },
  { id: 'scores.gre', label: 'GRE Score' },
  { id: 'scores.gmat', label: 'GMAT Score' },
  { id: 'products', label: 'Product' },
  { id: 'yop', label: 'Year' },
  { id: 'actions', label: 'Actions', disableSorting: true },
];

export default function CRUD(props) {
  const classes = useStyles();
  const dispatch = useDispatch();


 

  const { addWebinarLink,getTools,deleteWebinarLink } = useSelector((state) => state.CRUDwebinarReducer);

  const [openPopup, setOpenPopup] = useState(false);
  
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });




  


  useEffect(() => {
    dispatch(getToolsCall());

  }, []);
console.log(getTools)

  return (
     <>
      <div style={{display:"flex",flexDirection:"row",margin:"10px"}}>
          <img
            src={BackButton}
            style={{ cursor: "pointer",marginTop:"-10px" }}
            onClick={() => props.history.goBack()}
             />
               <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <Typography style={{ cursor: "pointer", fontWeight: "600",marginLeft:"10px" }} onClick={()=>props.history.push(studentPath)}>
                Home
              </Typography>
              <Typography style={{ cursor: "pointer", fontWeight: "600" }}>
                CRUD
              </Typography>
            </Breadcrumbs>
            </div>
     <Grid container spacing={1}>
        <Grid item xs={12}>
        <Typo variant={"h6"} paddingTop={"10px"} paddingBottom={"10px"} fontWeight={550} fontFamily={"'poppins',sans-serif"} >
          {"Tools (1)"}
        </Typo>
           
        </Grid>
        <Grid item xs={3}>
            <div className={classes.CRUDcard}>
                <div className={classes.cardDetails}>
              <Grid item xs={12}>
                <Typo className={classes.toolTitle}>Webinar</Typo>
              </Grid>
              <Grid container>
              <Grid item xs={5}>
                <Typo paddingTop={"10px"} paddingBottom={"5px"}>Modified&nbsp;by</Typo>
              </Grid>
              <Grid item xs={1}>
              </Grid>
              <Grid item xs={6}>
               <Typo paddingTop={"10px"} paddingBottom={"5px"} textAlign={"right"}>Last&nbsp;Modified&nbsp;on</Typo> 
              </Grid>
              </Grid>
              <Grid container >
              <Grid item xs={5}>
                <Typo className={classes.nameAndDate}>{getTools?.data?.data?.modifiedBy} </Typo>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={6}>
                <Typo textAlign={"right"} className={classes.nameAndDate}>{getTools?.data?.data?.lastModifiedOn}</Typo>
              </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={8}>

                </Grid>
                <Grid item xs={4}>
                    
                       <Typo cursor={"pointer"}textAlign={"right"} color={"primary"} onClick={()=>props.history.push(CRUDwebinarPath)} paddingTop={"10px"}>View&nbsp;Data</Typo> 
                   
                </Grid>
              </Grid>
              </div>
            </div>
        </Grid>
        <Grid item xs={0.2}></Grid>
     </Grid>
     </>
  );
}
