import React, { useEffect, useRef, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { makeStyles } from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import moment from 'moment';

import format from 'date-fns/format';
import { addDays } from 'date-fns';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import '../../../Asset/All.css';

const useStyle = makeStyles({
  calendarElement: {
    position: 'absolute',
    transform: 'translateX(-70%)',
    backgroundColor: '#fff',
    top: '40px',
    border: '1px solid #ccc',
    zIndex: 999,
  },
  buttonContainer: {
    border: '1px solid #E1E5EB',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    color: '#354354',
    height: '35px',
    cursor: 'pointer',
  },
  icons: {
    fontSize: '6px',
    color: '#828D98',
  },
});

const DateRangePickerComp = (props) => {
  const classes = useStyle();
  let dates = moment(new Date());
  let today = dates.format('L');
  let yesterday = dates.subtract(1, 'day').format('L');
  let tomorrow = dates.add(2, 'days').format('L');

  // date state
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
  };

  function handleSelect(ranges) {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  }

  function toIsoStringStart(date) {
    var tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function(num) {
        return (num < 10 ? '0' : '') + num;
      };

    return (
      date.getFullYear() +
      '-' +
      pad(date.getMonth() + 1) +
      '-' +
      pad(date.getDate()) +
      'T' +
      pad(date.getHours()) +
      ':' +
      pad(date.getMinutes()) +
      ':' +
      pad(date.getSeconds()) +
      dif +
      pad(Math.floor(Math.abs(tzo) / 60)) +
      ':' +
      pad(Math.abs(tzo) % 60)
    );
  }

  function toIsoStringEnd(date) {
    var tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function(num) {
        return (num < 10 ? '0' : '') + num;
      };

    return (
      date.getFullYear() +
      '-' +
      pad(date.getMonth() + 1) +
      '-' +
      pad(date.getDate()) +
      'T' +
      pad(date.getHours() === 23 ? 23 : 23) +
      ':' +
      pad(date.getMinutes() === 59 ? 59 : 59) +
      ':' +
      pad(date.getSeconds() === 59 ? 59 : 59) +
      dif +
      pad(Math.floor(Math.abs(tzo) / 60)) +
      ':' +
      pad(Math.abs(tzo) % 60)
    );
  }

  const onClickChange = () => {
    props.setStartDate(toIsoStringStart(new Date(startDate)));
    props.setEndDate(toIsoStringEnd(new Date(endDate)));
    props.setValue(true);
    textDisaplay();
    setOpen(false);
  };

  // open close
  const [open, setOpen] = useState(false);
  const [inputText, setInputText] = useState('Today');

  const textDisaplay = () => {
    if (
      format(selectionRange.startDate, 'MM/dd/yyyy') ===
      format(selectionRange.endDate, 'MM/dd/yyyy')
    ) {
      if (format(selectionRange.startDate, 'MM/dd/yyyy') === today) {
        return setInputText('Today');
      } else {
        if (format(selectionRange.startDate, 'MM/dd/yyyy') === yesterday) {
          return setInputText('Yesterday');
        } else {
          return setInputText(
            `${format(selectionRange.startDate, 'MM/dd/yyyy')}`
          );
        }
      }
    } else {
      return setInputText(
        `${format(selectionRange.startDate, 'MM/dd/yyyy')} to ${format(
          selectionRange.endDate,
          'MM/dd/yyyy'
        )}`
      );
    }
  };

  return (
    <div className='calendarWrap'>
      <Box
        onClick={() => setOpen((open) => !open)}
        className={classes.buttonContainer}
      >
        <input value={inputText} readOnly className='inputBox' />
        <KeyboardArrowDownIcon className={classes.icons} />
      </Box>

      {open && (
        <>
          <div className={classes.calendarElement}>
            <DateRangePicker
              onChange={handleSelect}
              editableDateInputs={true}
              moveRangeOnFirstSelection={false}
              ranges={[selectionRange]}
              direction='vertical'
              maxDate={new Date()}
              scroll={{ enabled: true }}
              months={1}
            />
            <div
              style={{
                display: 'flex',
                margin: 10,
                columnGap: 10,
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant='contained'
                color='inherit'
                onClick={() => {
                  setOpen(false);
                }}
              >
                Close
              </Button>
              <Button
                variant='contained'
                color='success'
                onClick={onClickChange}
              >
                Apply
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DateRangePickerComp;
