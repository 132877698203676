import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { GSContainer, useStyles } from "../../Asset/StyledComponents/Styles";
import { Grid, TextField } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  isEmptyString,
  isOnlyNumber,
  urlPatternValidation,
} from "../Validation";
import {
  createGradSchoolProgramList,
  getGradSchoolProgramListById,
  GradSchoolAreaOfSpecializationList,
  GradSchoolCategoryList,
  GradSchoolDegreeList,
  GradSchoolFieldOfStudyList,
  GradSchoolProgramList,
} from "../../Actions/GradSchoolAction";
import { useDispatch, useSelector } from "react-redux";
import MySnackBar from "../MySnackBar";
export default function AddGradSchoolProgram(props) {
  const dispatch = useDispatch();
  const {
    getGradSchoolProgramCategoryLists,
    getGradSchoolProgramDegreeLists,
    getGradSchoolProgramFieldOfStudyLists,
    getGradSchoolProgramAreaOfSpecializationLists,
    getGradSchoolProgramLists,
    insertGradSchoolProgram,
    getPerGradSchoolProgramData,
  } = useSelector((state) => state.GradSchoolReducer);
  const classes = useStyles();
  const [state, setState] = useState({
    id: "",
    show: false,
    programName: null,
    programLink: "",
    eligibilityCriteria: "",
    deadlineFromPreviousIntakeFall: null,
    deadlineFromPreviousIntakeSpring: null,
    examTypeGmatGre: "",
    intakeYear: null,
    categoryBasedOnFieldOfStudy: "",
    intake: "",
    intakeTerm: "",
    preRequisites: "",
    deadlineFromPreviousIntake: null,
    gmatGre: "",
    standardizedTest: "",
    deadlineFromPreviousIntakeFalls: "",
    criteriaOfGmatGre: "",
    toeflIelts: "",
    englishLanguageProficiencyTest: "",
    testType: "",
    ownerOfOps: "",
    streamForProfiling: "",
    deadlineFromPreviousIntakeSprings: null,
    pteTotal: null,
    concatenatedData: "",
    gradSchoolCategory: null,
    aspirationDegree: null,
    aspirationFieldOfStudy: null,
    aspirationAreaOfSpecialization: null,
    currentYear: new Date().getFullYear(),
    intakeYearOption: [],
  });

  const [snack, setSnack] = useState({
    open: false,
    message: "",
    color: "",
  });
  const [disabled, setDisabled] = useState(false);
  const filter = createFilterOptions();
  const intakeSession = [
    { title: "Fall" },
    { title: "Summer" },
    { title: "Spring" },
  ];
  const standardizedTest = [
    { title: "GMAT" },
    { title: "GRE" },
    { title: "GMAT/GRE" },
  ];

  const testType = [
    { title: "TOEFL" },
    { title: "IELTS" },
    { title: "TOEFL/IELTS" },
  ];

  const textFields = [
    {
      value: state.programLink,
      name: "programLink",
      label: "Program Link",
      type: "text",
    },
    {
      value: state.eligibilityCriteria,
      label: "Eligibility Criteria",
      name: "eligibilityCriteria",
      type: "text",
    },
    {
      value: state.intakeYear,
      label: "Intake Year",
      name: "intakeYear",
      type: "select",
      option: state.intakeYearOption,
    },
    {
      value: state.intake,
      label: "Intake Term",
      name: "intake",
      type: "select",
      option: intakeSession,
    },
    {
      value: state.preRequisites,
      label: "Pre Requisites",
      name: "preRequisites",
      type: "text",
    },
    {
      value: state.deadlineFromPreviousIntake,
      label: "Deadline from Previous Intake",
      name: "deadlineFromPreviousIntake",
      type: "date",
    },
    {
      value: state.standardizedTest,
      label: "Standardized Test",
      name: "standardizedTest",
      type: "select",
      option: standardizedTest,
    },
    {
      value: state.deadlineFromPreviousIntakeFalls,
      label: "Deadline from Previous Intake Falls",
      name: "deadlineFromPreviousIntakeFalls",
      type: "text",
    },
    {
      value: state.criteriaOfGmatGre,
      label: "Criteria Of GMAT GRE",
      name: "criteriaOfGmatGre",
      type: "text",
    },
    {
      value: state.testType,
      label: "Test Type",
      name: "testType",
      type: "select",
      option: testType,
    },

    {
      value: state.deadlineFromPreviousIntakeSprings,
      label: "Deadline From Previous Intake Springs",
      name: "deadlineFromPreviousIntakeSprings",
      type: "date",
    },
  ];

  const [programNameErr, setProgramNameErr] = useState("");
  const [programLinkErr, setProgramLinkErr] = useState("");
  const [eligibilityCriteriaErr, setEligibilityCriteriaErr] = useState("");
  const [gradSchoolCategoryErr, setGradSchoolCategoryErr] = useState("");
  const [degreeErr, setDegreeErr] = useState("");
  const [fieldOfStudyErr, setFieldOfStudyErr] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [degreeList, setDegreeList] = useState([]);
  const [fieldOfStudyList, setFieldOfStudyList] = useState([]);
  const [areaOfSpecializationList, setAreaOfSpecializationList] = useState([]);
  const [programNameList, setProgramNameList] = useState([]);
  const [gradShoolProgramId, setGradSchoolProgramSchoolId] = useState(null);
  useEffect(() => {
    dispatch(GradSchoolCategoryList());
    dispatch(GradSchoolDegreeList());
    dispatch(GradSchoolProgramList());
    setGradSchoolProgramSchoolId(props.location.state.gradSchoolProgramId);

    for (let i = 0; i < 10; i++) {
      let arr = state.currentYear + i;
      state.intakeYearOption.push({ title: arr });
    }
  }, [dispatch]);

  useEffect(() => {
    if (props.location.state.id) {
      dispatch(getGradSchoolProgramListById(props.location.state.id));
    }
  }, []);

  useEffect(() => {
    if (props.location.state.id) {
      if (getPerGradSchoolProgramData !== null) {
        if (getPerGradSchoolProgramData.success) {
          const {
            areaOfSpecialization,
            concatenatedData,
            criteriaOfGmatGre,
            deadLineFromPreviousIntake,
            deadLineFromPreviousIntakeFall,
            deadLineFromPreviousIntakeFalls,
            deadLineFromPreviousIntakeSpring,
            deadLineFromPreviousIntakeSprings,
            degree,
            eligibilityCriteria,
            englishLanguageProficiencyTest,
            examTypeGmatGre,
            fieldOfStudy,
            gmatGre,
            gradSchoolCategory,
            gradSchoolId,
            id,
            inTakeYear,
            intake,
            preRequisites,
            programLink,
            programName,
            standardizedTest,
            testType,
            toeflIelts,
          } = getPerGradSchoolProgramData.data;

          setState({
            ...state,
            id: id,
            programName: programName,
            programLink: programLink,
            eligibilityCriteria:
              eligibilityCriteria === null ? "" : eligibilityCriteria,
            deadlineFromPreviousIntakeFall: deadLineFromPreviousIntakeFall,
            deadlineFromPreviousIntakeSpring: deadLineFromPreviousIntakeSpring,
            examTypeGmatGre: examTypeGmatGre === null ? "" : examTypeGmatGre,
            intakeYear: inTakeYear === null ? "" : inTakeYear,
            categoryBasedOnFieldOfStudy: "",
            intake: intake === null ? "" : intake,
            intakeTerm: "",
            preRequisites: preRequisites === null ? "" : preRequisites,
            deadlineFromPreviousIntake:
              deadLineFromPreviousIntake === null
                ? null
                : deadLineFromPreviousIntake === ""
                ? null
                : moment(new Date(deadLineFromPreviousIntake)).format(
                    "YYYY-MMM-DD"
                  ),
            gmatGre: gmatGre === null ? "" : gmatGre,
            standardizedTest: standardizedTest === null ? "" : standardizedTest,
            deadlineFromPreviousIntakeFalls:
              deadLineFromPreviousIntakeFalls === null
                ? ""
                : deadLineFromPreviousIntakeFalls,
            criteriaOfGmatGre:
              criteriaOfGmatGre === null ? "" : criteriaOfGmatGre,
            toeflIelts: toeflIelts === null ? "" : toeflIelts,
            englishLanguageProficiencyTest:
              englishLanguageProficiencyTest === null
                ? ""
                : englishLanguageProficiencyTest,
            testType: testType === null ? "" : testType,
            ownerOfOps: "",
            streamForProfiling: "",
            deadlineFromPreviousIntakeSprings:
              deadLineFromPreviousIntakeSprings === null
                ? null
                : deadLineFromPreviousIntakeSprings === ""
                ? null
                : moment(new Date(deadLineFromPreviousIntakeSprings)).format(
                    "YYYY-MMM-DD"
                  ),
            pteTotal: null,
            concatenatedData: concatenatedData,
            gradSchoolCategory: gradSchoolCategory,
            aspirationDegree: degree,
            aspirationFieldOfStudy: fieldOfStudy,
            aspirationAreaOfSpecialization: areaOfSpecialization,
          });
        }
      }
    }
  }, [getPerGradSchoolProgramData]);

  useEffect(() => {
    setCategoryList(getGradSchoolProgramCategoryLists?.data);
    setDegreeList(getGradSchoolProgramDegreeLists?.data);
    setFieldOfStudyList(getGradSchoolProgramFieldOfStudyLists);
    setAreaOfSpecializationList(getGradSchoolProgramAreaOfSpecializationLists);
    setProgramNameList(getGradSchoolProgramLists);
  }, [
    getGradSchoolProgramCategoryLists,
    getGradSchoolProgramDegreeLists,
    getGradSchoolProgramFieldOfStudyLists,
    getGradSchoolProgramAreaOfSpecializationLists,
  ]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });

    if (name === "programLink") {
      isEmptyString(value)
        ? setProgramLinkErr("Please fill the Program Link")
        : !urlPatternValidation(value)
        ? setProgramLinkErr("Please fill the Valid URL")
        : setProgramLinkErr("");
    }
    if (name === "eligibilityCriteria") {
      if (value) {
        isEmptyString(value)
          ? setEligibilityCriteriaErr("Please fill the Eligibility Criteria")
          : setEligibilityCriteriaErr("");
      }
    }
  };
  const handleAddSave = () => {
    delete state.show;
    delete state.id;

    isEmptyString(state.programName)
      ? setProgramNameErr("Please fill the Program Name")
      : setProgramNameErr("");

    isEmptyString(state.programLink)
      ? setProgramLinkErr("Please fill the Program Link")
      : !urlPatternValidation(state.programLink)
      ? setProgramLinkErr("Please fill the Valid URL")
      : setProgramLinkErr("");
    isEmptyString(state.eligibilityCriteria)
      ? setEligibilityCriteriaErr("Please fill the Eligibility Criteria")
      : setEligibilityCriteriaErr("");
    isEmptyString(state.gradSchoolCategory)
      ? setGradSchoolCategoryErr("Please select the Grad School Category")
      : setGradSchoolCategoryErr("");
    isEmptyString(state.aspirationDegree)
      ? setDegreeErr("Please select  the Degree")
      : setDegreeErr("");
    isEmptyString(state.aspirationFieldOfStudy)
      ? setFieldOfStudyErr("Please select the Field Of Study")
      : setFieldOfStudyErr("");

    if (
      !isEmptyString(state.programName) &&
      !isEmptyString(state.programLink) &&
      urlPatternValidation(state.programLink) &&
      !isEmptyString(state.eligibilityCriteria) &&
      !isEmptyString(state.gradSchoolCategory) &&
      !isEmptyString(state.aspirationDegree) &&
      !isEmptyString(state.aspirationFieldOfStudy)
    ) {
      if (props.location.state.id) {
        let obj = {
          id: props.location.state.id,
          gradSchoolId: props.location.state.gradSchoolId,
          programName: state.programName.name
            ? state.programName.name
            : state.programName,
          intake: state.intake,
          criteriaOfGmatGre: state.criteriaOfGmatGre,
          programLink: state.programLink,
          toeflIelts: state.toeflIelts,
          concatenatedData: "",
          eligibilityCriteria: state.eligibilityCriteria,
          preRequisites: state.preRequisites,
          englishLanguageProficiencyTest: state.englishLanguageProficiencyTest,
          gradSchoolCategory: state.gradSchoolCategory.name
            ? state.gradSchoolCategory.name
            : state.gradSchoolCategory,
          deadLineFromPreviousIntake: state.deadlineFromPreviousIntake,
          testType: state.testType,
          degree: state.aspirationDegree.name
            ? state.aspirationDegree.name
            : state.aspirationDegree,
          examTypeGmatGre: state.examTypeGmatGre,
          gmatGre: state.gmatGre,
          fieldOfStudy: state.aspirationFieldOfStudy.name
            ? state.aspirationFieldOfStudy.name
            : state.aspirationFieldOfStudy,
          inTakeYear: state.intakeYear,
          standardizedTest: state.standardizedTest,
          areaOfSpecialization: state.aspirationAreaOfSpecialization?.name
            ? state.aspirationAreaOfSpecialization?.name
            : state.aspirationAreaOfSpecialization,
          deadLineFromPreviousIntakeSpring:
            state.deadlineFromPreviousIntakeSpring,
          deadLineFromPreviousIntakeFall: state.deadlineFromPreviousIntakeFall,
          deadLineFromPreviousIntakeFalls:
            state.deadlineFromPreviousIntakeFalls,
          deadLineFromPreviousIntakeSprings:
            state.deadlineFromPreviousIntakeSprings,
        };
        dispatch(
          createGradSchoolProgramList(obj, (res) => {
            if (res.success) {
              setSnack({
                open: true,
                message: "Updated Successfully",
                color: "success",
              });
              dispatch(getGradSchoolProgramListById(res.data.id));
            }
          })
        );
      } else {
        let obj = {
          gradSchoolId: gradShoolProgramId,
          programName: state.programName.name,
          intake: state.intake,
          criteriaOfGmatGre: state.criteriaOfGmatGre,
          programLink: state.programLink,
          toeflIelts: state.toeflIelts,
          concatenatedData: "",
          eligibilityCriteria: state.eligibilityCriteria,
          preRequisites: state.preRequisites,
          englishLanguageProficiencyTest: state.englishLanguageProficiencyTest,
          gradSchoolCategory: state.gradSchoolCategory.name,
          deadLineFromPreviousIntake: state.deadlineFromPreviousIntake,
          testType: state.testType,
          degree: state.aspirationDegree.name,
          examTypeGmatGre: state.examTypeGmatGre,
          gmatGre: state.gmatGre,
          fieldOfStudy: state.aspirationFieldOfStudy.name,
          inTakeYear: state.intakeYear,
          standardizedTest: state.standardizedTest,
          areaOfSpecialization: state.aspirationAreaOfSpecialization?.name,
          deadLineFromPreviousIntakeSpring:
            state.deadlineFromPreviousIntakeSpring,
          deadLineFromPreviousIntakeFall: state.deadlineFromPreviousIntakeFall,
          deadLineFromPreviousIntakeFalls:
            state.deadlineFromPreviousIntakeFalls,
          deadLineFromPreviousIntakeSprings:
            state.deadlineFromPreviousIntakeSprings,
        };
        dispatch(
          createGradSchoolProgramList(obj, (res) => {
            if (res.success) {
              setSnack({
                open: true,
                message: "Saved Successfully",
                color: "success",
              });
              setTimeout(() => {
                props.gradSchoolProgramData(res.data.id);
              }, 1000);
            }
          })
        );
      }
    }
  };

  const handleDateChange = (val, e, name) => {
    setState({
      ...state,
      [name]: e,
    });
  };

  return (
    <React.Fragment>
      <GSContainer>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            spacing={4}
          >
            <Grid item md={12}>
              <Grid container spacing={2}>
                <Grid item md={4}>
                  <Autocomplete
                    value={state.programName}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setState({
                          ...state,
                          programName: {
                            name: newValue,
                          },
                        });
                        setProgramNameErr("");
                      } else if (newValue && newValue.inputValue) {
                        setState({
                          ...state,
                          programName: {
                            name: newValue.inputValue,
                          },
                        });
                        setProgramNameErr("");
                      } else {
                        setState({ ...state, programName: newValue });
                        setProgramNameErr("");
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;

                      const isExisting = options.some(
                        (option) => inputValue === option.name
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          name: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={programNameList}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.name;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.name}</li>
                    )}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Program Name"
                        variant="standard"
                        fullWidth
                        color="primary"
                        size="small"
                        required
                        onKeyDown={(e) => isOnlyNumber(e) && e.preventDefault()}
                        helperText={programNameErr}
                        error={programNameErr === "" ? false : true}
                      />
                    )}
                  />
                </Grid>

                {textFields.map((item, index) => {
                  return (
                    <Grid item md={4} key={index}>
                      {item.type === "select" ? (
                        <FormControl fullWidth variant="standard">
                          <InputLabel
                            id="demo-simple-select-label"
                            shrink={item.value ? true : false}
                          >
                            {item.label}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={item.value}
                            name={item.name}
                            label={item.label}
                            onChange={(e) => onChange(e)}
                          >
                            {item.option.map((menuItem, idx) => (
                              <MenuItem value={menuItem.title} key={idx}>
                                {menuItem.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : item.type === "date" ? (
                        <KeyboardDatePicker
                          margin="normal"
                          id="date-picker-dialog"
                          label={item.label}
                          format={"yyyy-MMM-dd"}
                          value={item.value}
                          name={item.name}
                          fullWidth
                          onChange={(newValue, e) =>
                            handleDateChange(newValue, e, item.name)
                          }
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      ) : (
                        <TextField
                          variant="standard"
                          color="primary"
                          label={item.label}
                          name={item.name}
                          fullWidth
                          type={item.type}
                          size="small"
                          value={item.value}
                          onChange={(e) => onChange(e)}
                          InputLabelProps={{
                            shrink: item.shrink || item.value ? true : false,
                            className: "label_pad",
                          }}
                          required={
                            item.name === "programLink" ||
                            item.name === "eligibilityCriteria"
                          }
                          helperText={
                            item.name === "programLink"
                              ? programLinkErr
                              : null || item.name === "eligibilityCriteria"
                              ? eligibilityCriteriaErr
                              : null
                          }
                          error={
                            item.name === "programLink" && programLinkErr !== ""
                              ? true
                              : false ||
                                (item.name === "eligibilityCriteria" &&
                                  eligibilityCriteriaErr !== "")
                              ? true
                              : false
                          }
                          onKeyDown={(e) =>
                            isOnlyNumber(e) && e.preventDefault()
                          }
                        />
                      )}
                    </Grid>
                  );
                })}

                <Grid item md={4}>
                  <Autocomplete
                    value={state.gradSchoolCategory}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setState({
                          ...state,
                          gradSchoolCategory: {
                            name: newValue,
                          },
                        });
                        setGradSchoolCategoryErr("");
                      } else if (newValue && newValue.inputValue) {
                        setState({
                          ...state,
                          gradSchoolCategory: {
                            name: newValue.inputValue,
                          },
                        });
                        setGradSchoolCategoryErr("");
                      } else {
                        setState({ ...state, gradSchoolCategory: newValue });
                        setGradSchoolCategoryErr("");
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      const isExisting = options.some(
                        (option) => inputValue === option.name
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          name: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={categoryList}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.name;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.name}</li>
                    )}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Grad School Category"
                        variant="standard"
                        fullWidth
                        color="primary"
                        size="small"
                        onKeyDown={(e) => isOnlyNumber(e) && e.preventDefault()}
                        required
                        helperText={gradSchoolCategoryErr}
                        error={gradSchoolCategoryErr === "" ? false : true}
                      />
                    )}
                  />
                </Grid>

                <Grid item md={4}>
                  <Autocomplete
                    value={state.aspirationDegree}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setState({
                          ...state,
                          aspirationDegree: {
                            name: newValue,
                          },
                        });
                        setDegreeErr("");
                      } else if (newValue && newValue.inputValue) {
                        setState({
                          ...state,
                          aspirationDegree: {
                            name: newValue.inputValue,
                          },
                        });
                        setDegreeErr("");
                      } else {
                        setState({ ...state, aspirationDegree: newValue });
                        setDegreeErr("");
                        dispatch(GradSchoolFieldOfStudyList(newValue.id));
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      const isExisting = options.some(
                        (option) => inputValue === option.name
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          name: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={degreeList}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.name;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.name}</li>
                    )}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Degree"
                        variant="standard"
                        fullWidth
                        color="primary"
                        size="small"
                        onKeyDown={(e) => isOnlyNumber(e) && e.preventDefault()}
                        required
                        helperText={degreeErr}
                        error={degreeErr === "" ? false : true}
                      />
                    )}
                  />
                </Grid>

                <Grid item md={4}>
                  <Autocomplete
                    value={state.aspirationFieldOfStudy}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setState({
                          ...state,
                          aspirationFieldOfStudy: {
                            name: newValue,
                          },
                        });
                        setFieldOfStudyErr("");
                      } else if (newValue && newValue.inputValue) {
                        setState({
                          ...state,
                          aspirationFieldOfStudy: {
                            name: newValue.inputValue,
                          },
                        });
                        setFieldOfStudyErr("");
                      } else {
                        setState({
                          ...state,
                          aspirationFieldOfStudy: newValue,
                        });
                        setFieldOfStudyErr("");
                        dispatch(
                          GradSchoolAreaOfSpecializationList(newValue?.id)
                        );
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      const isExisting = options.some(
                        (option) => inputValue === option.name
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          name: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={fieldOfStudyList}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.name;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.name}</li>
                    )}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Field Of Study"
                        variant="standard"
                        fullWidth
                        color="primary"
                        size="small"
                        onKeyDown={(e) => isOnlyNumber(e) && e.preventDefault()}
                        required
                        error={fieldOfStudyErr !== "" ? true : false}
                        helperText={fieldOfStudyErr}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4}>
                  <Autocomplete
                    value={state.aspirationAreaOfSpecialization}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setState({
                          ...state,
                          aspirationAreaOfSpecialization: {
                            name: newValue,
                          },
                        });
                      } else if (newValue && newValue.inputValue) {
                        setState({
                          ...state,
                          aspirationAreaOfSpecialization: {
                            name: newValue.inputValue,
                          },
                        });
                      } else {
                        setState({
                          ...state,
                          aspirationAreaOfSpecialization: newValue,
                        });
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      const isExisting = options.some(
                        (option) => inputValue === option.name
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          name: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={areaOfSpecializationList}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.name;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.name}</li>
                    )}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Area Of Specialization"
                        variant="standard"
                        fullWidth
                        color="primary"
                        size="small"
                        onKeyDown={(e) => isOnlyNumber(e) && e.preventDefault()}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Button
                variant="contained"
                color="primary"
                className={classes.btnStyles}
                onClick={handleAddSave}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </GSContainer>
      <MySnackBar
        snackOpen={snack.open}
        snackVariant={snack.color}
        snackMsg={snack.message}
        onClose={() =>
          setSnack({
            open: false,
            message: "",
            color: "",
          })
        }
      />
    </React.Fragment>
  );
}
