import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Container, TopTab, TopTabs } from './Assets/Styles/WallStyles';
import { useLocation } from 'react-router-dom';
import IbhWebinar from './Pages/IbhWebinar';
import IbhResult from './Pages/IbhResult';

const IbhLanding = () => {
  let location = useLocation();
  const [tabCount, setTabCount] = useState(location.tab ?? 0);

  const renderContent = (value) => {
    try {
      if (value === 0) {
        return <IbhResult />;
      } else if (value === 1) {
        return <IbhWebinar />;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <Grid container>
        <Grid item md={12}>
          <TopTabs
            value={tabCount}
            textColor={'inherit'}
            onChange={(e, value) => setTabCount(value)}
            aria-label='tabs'
          >
            <TopTab label='IBH Result Page' />
            <TopTab label='IBH Webinars' />
          </TopTabs>
        </Grid>
        <Grid item md={12} overflow='auto'>
          {renderContent(tabCount)}
        </Grid>
      </Grid>
    </Container>
  );
};

export default IbhLanding;
