import React, { useState, useEffect } from "react";
// import TestimonialForm from './TestimonialForm';
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  Typography,
  IconButton,
} from "@material-ui/core";
import useTable from "../Utils/useTable";
import Controls from "../Utils/controls/Controls";
import { Search } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import Popup from "../Utils/Popup";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import Notification from "../Utils/Notification";
import Loader from "../Utils/controls/Loader";
import MuiAlert from "@material-ui/lab/Alert";
import ConfirmDialog from "../Utils/ConfirmDialog";
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import { RiDeleteBinLine } from "react-icons/ri";

import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import {
  listTestimonials,
  deleteTestimonial,
  createTestimonial,
  updateTestimonial,
} from "../../Actions/TestimonialActions";
import { studentPath } from "../RoutePaths";
import BackButton from "../../Asset/Images/backbutton.svg";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Breadcrumbs } from "@material-ui/core";
import { HeaderDiv, Typo } from "../Utils/controls/Styles";
import { StyledButton } from "../../Asset/StyledComponents/CvReview";
import CustomPopup from "../Utils/controls/CustomPopup";
import {
  Container,
  ContentWrapper,
  customTheme,
  FlexEndView,
  FlexJustifyView,
  StyledTable,
  Wrapper,
} from "../../Asset/StyledComponents/CvReview";
import { CommentIcon } from "../../Lms/Assets/css/Preview/TaskDetailsStyledComponent";
import { Autocomplete } from "@mui/material";
import {
  deleteWebinarCall,
  getWebinarCall,
  postWebinarCall,
  updateWebinarCall,
} from "../../Actions/CRUDwebinar";
import { urlPatternValidation } from "../Validation";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  CRUDcard: {
    border: "1px solid #E7E7E7",
    borderRadius: "10px",
    paddingRight: "20px",
  },
  cardDetails: {
    padding: "10px",
  },
  toolTitle: {
    font: " normal normal normal 20px/16px Poppins",
    letterSpacing: " 0.52px",
    color: "#18202C",
    opacity: "1",
    paddingTop: "10px",
  },
  nameAndDate: {
    fontWeight: "600",
  },
  bottomPad: {
    padding: "10px",
  },
  addWebinarButton: {
    alignItems: "right",
    float: "right",
  },
}));

export default function CRUDWebinar(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [webinarName, setWebinarName] = useState(null);
  const [webinarLink, setWebinarLink] = useState(null);
  const [category, setCategory] = useState(null);
  const [data, setData] = useState(null);
  const [edit, setEdit] = useState(false);
  const [webID, setWebID] = useState();

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const { addWebinarLink, getWebinarLink, deleteWebinarLink } = useSelector(
    (state) => state.CRUDwebinarReducer
  );

  const [openPopup, setOpenPopup] = useState(false);
  const [closePopup, setClosePopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const handleClosePopup = (item) => {
    setOpenPopup(false);
    setWebinarLink(null);
    setWebinarName(null);
    setCategory(null);
  };
  const handleDelete = (item) => {
    dispatch(deleteWebinarCall(item?.id));
    setTimeout(() => {
      setNotify({
        isOpen: true,
        message: "Deleted Successfully",
        type: "error",
      });
    }, 500);
  };

  const handleEdit = (item) => {
    setOpenPopup(true);
    console.log(item.webinarCategory);
    setCategory({
      name: item.webinarCategory,
    });
    setWebinarLink(item.webinarLink);
    setWebinarName(item.webinarName);
    setEdit(true);
    setWebID(item.id);
  };

  const handleSave = () => {
    console.log(category);
    let arr = {
      webinarCategory: category?.name,
      webinarLink: webinarLink,
      webinarName: webinarName,
    };
    if (webinarName === null || webinarName === "") {
      console.log("its working");
      setNotify({
        isOpen: true,
        message: "Please enter  Webinar Name",
        type: "error",
      });
    } else if (webinarLink === null || webinarLink === "") {
      console.log("its working");
      setNotify({
        isOpen: true,
        message: "Please enter  Webinar Link",
        type: "error",
      });
    } else if (category === null || category === "") {
      console.log("its working");
      setNotify({
        isOpen: true,
        message: "Please choose Category",
        type: "error",
      });
    } else if (edit === true) {
      dispatch(updateWebinarCall(webID, arr));
      setOpenPopup(false);
      setTimeout(() => {
        setNotify({
          isOpen: true,
          message: "Updated Successfully",
          type: "success",
        });
      }, 500);
    } else if (edit === false) {
      dispatch(postWebinarCall(arr));
      setOpenPopup(false);
      setTimeout(() => {
        setNotify({
          isOpen: true,
          message: "Saved Successfully",
          type: "success",
        });
      }, 500);
    }
  };

  const categoryDropDown = [
    {
      name: "Generic",
    },
  ];
  const renderTable = () => {
    return (
      <StyledTable>
        <tr>
          <th>{"Sl No"}</th>
          <th>{"Webinar Name"}</th>
          <th>{"Webinar Link"}</th>
          <th>{"Added By"}</th>
          <th>{"Added On"}</th>
          <th></th>
        </tr>
        {getWebinarLink &&
          getWebinarLink?.data?.data.map((item, index) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td>{item.webinarName}</td>
                <td>{item.webinarLink}</td>
                <td>{item.addedBy}</td>
                <td>{item.addOnDate}</td>

                <td>
                  <Button
                    height={"25px"}
                    variant={"outlined"}
                    color={"primary"}
                    // style={customTheme.palette.outlined}
                    onClick={() => handleEdit(item)}
                  >
                    {"Edit"}
                  </Button>
                  <Button color={"secondary"}>
                    <DeleteIcon onClick={() => handleDelete(item)} />
                  </Button>
                </td>
              </tr>
            );
          })}
      </StyledTable>
    );
  };
  const [urlValidation, setUrlValidation] = useState(true);
  const webinarValid = (e, value) => {
    const a = urlPatternValidation(e.target.value);
    setUrlValidation(a);
    console.log(a, "***********************abc");
    setWebinarLink(e.target.value);
  };
  const renderDialogContent = () => {
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              name={"webinarName"}
              required
              type={"text"}
              placeholder={"Webinar Name"}
              value={webinarName}
              className={classes.bottomPad}
              onChange={(e, value) => setWebinarName(e.target.value)}
              fullWidth
            />
            {console.log(webinarName)}
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <TextField
              name={"link"}
              value={webinarLink}
              placeholder={"Webinar Link"}
              helperText={
                !urlValidation ? "Please fill the valid Webinar Link" : ""
              }
              error={!urlValidation}
              onChange={(e, value) => webinarValid(e, value)}
              className={classes.bottomPad}
              fullWidth
            />
            {console.log(webinarLink)}
          </Grid>

          <Autocomplete
            id="category"
            disabled={false}
            options={categoryDropDown}
            getOptionLabel={(option) => option.name}
            value={category || null}
            className={classes.bottomPad}
            onChange={(e, getOptionLabel) => setCategory(getOptionLabel)}
            renderInput={(params) => (
              <TextField {...params} label={"Category"} />
            )}
            fullWidth
          />
          {console.log(category)}
        </Grid>
      </>
    );
  };

  useEffect(() => {
    dispatch(getWebinarCall());
  }, []);
  useEffect(() => {
    dispatch(getWebinarCall());
    setCategory(null);
    setWebinarLink(null);
    setWebinarName(null);
  }, [addWebinarLink]);
  useEffect(() => {
    dispatch(getWebinarCall());
  }, [deleteWebinarLink]);

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", margin: "10px" }}>
        <img
          src={BackButton}
          style={{ cursor: "pointer", marginTop: "-10px" }}
          onClick={() => props.history.goBack()}
        />
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <Typography
            style={{ cursor: "pointer", fontWeight: "600", marginLeft: "10px" }}
            onClick={() => props.history.push(studentPath)}
          >
            Home
          </Typography>
          <Typography style={{ cursor: "pointer", fontWeight: "600" }}>
            CRUD
          </Typography>
          <Typography style={{ cursor: "pointer", fontWeight: "600" }}>
            Webinar
          </Typography>
        </Breadcrumbs>
      </div>

      <Paper className={classes.pageContent}>
        <HeaderDiv>
          <Grid container>
            <Grid item xs={4}>
              <Typo
                variant={"h6"}
                fontWeight={550}
                fontFamily={"'poppins',sans-serif"}
              >
                {"Webinar Link"}
              </Typo>
            </Grid>
            <Grid item xs={5}></Grid>
            <Grid item xs={3}>
              <StyledButton
                variant={"contained"}
                color={"primary"}
                className={classes.addWebinarButton}
                onClick={() => {
                  setOpenPopup(true);
                  setEdit(false);
                }}
              >
                {"Add Webinar Link"}
              </StyledButton>
            </Grid>
          </Grid>
        </HeaderDiv>
        {renderTable()}
      </Paper>
      <CustomPopup
        title={edit === true ? "Edit Webinar Link" : "Add Webinar Link"}
        open={openPopup}
        setOpenPopup={setOpenPopup}
        leftButtonText={"Cancel"}
        rightButtonText={edit === true ? "Update" : "Add Link"}
        handleRightButton={handleSave}
        handleLeftButton={handleClosePopup}
        dialogContent={renderDialogContent()}
        width={"600px"}
      ></CustomPopup>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
