import { Box, IconButton } from "@material-ui/core";
import React from "react";
import { ReactComponent as CommentIcon } from "../../Asset/icons/comment.svg";
import {
  customTheme,
  StyledButton,
  StyledCustomStaticTable,
  useStyles,
} from "../../Asset/StyledComponents/Styles";
import { ReactComponent as Document } from "../../Asset/icons/empty-document.svg";
import { Typo } from "./controls/Styles";
import moment from "moment";

function DownloadCvTablePAS({
  headers,
  body,
  handleComment,
  handleDownload,
  handleDelete,
}) {
  const classes = useStyles();
  console.log(body);
  return body?.length !== 0 ? (
    <StyledCustomStaticTable>
      <thead>
        <tr>
          {headers &&
            headers.length !== 0 &&
            headers.map((item, index) => <th id={`head${index}`}>{item}</th>)}
        </tr>
      </thead>
      <tbody>
        {body?.success !== false &&
          body?.length !== 0 &&
          body?.map(
            (
              {
                regionName,
                uploadedDate,
                documentName,
                fileName,
                documentId,
                path,
              },
              index,
              status
            ) => (
              <tr id={`row${index}`}>
                {status === "Review Completed" ? (
                  <td>{documentName}</td>
                ) : (
                  <td>{documentName}</td>
                )}
                <td>{regionName ? regionName : null}</td>
                <td>
                  {uploadedDate
                    ? moment(new Date(uploadedDate)).format("DD MMMM YYYY")
                    : "NA"}
                </td>

                <td>
                  <Box
                    display={"flex"}
                    gridGap={"10px"}
                    justifyContent={"flex-end"}
                  >
                    <StyledButton
                      height={"25px"}
                      variant={"outlined"}
                      style={customTheme.palette.delete}
                      onClick={(e) => handleDelete(e, documentId)}
                    >
                      {"Delete"}
                    </StyledButton>

                    <StyledButton
                      height={"25px"}
                      variant={"outlined"}
                      style={customTheme["palette"]["outlined"]}
                      onClick={(e) =>
                        handleDownload(documentId, fileName, path, e)
                      }
                    >
                      {"Download"}
                    </StyledButton>
                  </Box>
                </td>
              </tr>
            )
          )}
      </tbody>
    </StyledCustomStaticTable>
  ) : (
    <Box
      margin={"75px 0px"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <Document />
      <Typo padding={"10px 0px"} variant={"h6"}>
        {"No Document added"}
      </Typo>
    </Box>
  );
}

export { DownloadCvTablePAS };
