import { makeStyles } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import styled from "styled-components";
import { COLORS } from "../../../Utils/Shared";

export const useStyles = makeStyles((theme) => ({
  breadCrumpsText: {
    lineHeight: "normal",
    paddingLeft: "5px",
    color: "#009be5",
    fontSize: "16px",
    cursor: "pointer",

    "&:active": {
      color: "#1093ff",
    },
  },

  breadCrumpsArrow: {
    fill: "#009be5 !important",
    fontSize: "22px !important",
  },
  rescheduleButton: {
    width: "200px",
    height: "33px",
    borderRadius: "1000000000px"
  },
  listItemText: {
    fontFamily: "Poppins, sans-serif !important",
    fontSize: "13px !important",
    // margin: "0px 15px 0px -15px",
  },
  selectedListItem: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: COLORS.white,
  },
  container: {
    padding: "10px 10px 10px 20px",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  stageCompleteButton: {
    "&:hover": {
      backgroundColor: COLORS.secondary,
      borderColor: COLORS.secondary,
      color: COLORS.white,
    },
  },
  afterStageCompleteButton: {
    color: COLORS.white,
    cursor: "not-allowed",
  },
  addSchoolButton: {
    width: "10%",
    paddingTop: "3px",
    paddingBottom: "3px",
    whiteSpace: "nowrap",
  },
  title: {
    color: "white",
  },
  schoolImage: {
    paddingRight: "20px",
    marginTop: "5px",
  },
  schoolCard: {
    height: "30px !important",
    overflowY: "hidden !important",
    whiteSpace: "nowrap !important",
    width: "100% !important",
    overflowX: "hidden !important",
    margin: "6px 0px !important",
    display: "flex !important",
    alignItems: "center !important",
    padding: "5px !important",
    borderRadius: "0px !important",
    cursor: "pointer !important",
  },
  schoolName: {
    marginBottom: "5px",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Poppins, sans-serif !important",
    wordWrap: "break"


  },
  tabDiv: {
    marginLeft: "10px",
    marginTop: "10px",
  },
}))
export const typographyStyle = {
  cursor: "pointer",
  fontWeight: "600",
  marginLeft: "10px",
};
export const FlexView = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
export const StyledCustomStaticTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  display: grid !important;

  th,
  td {
    text-align: center;
    color: #333333;
    font-size: 14px;
    font-weight: normal !important;
    padding: 10px;
    width: 25%;
  }

  th {
    padding: 20px 10px;
  }

  tr:hover {
    background-color: #ffffff !important;
  }

  tr:nth-child(odd) {
    background-color: #efefef;
  }

  tr:nth-child(odd):hover {
    background-color: #efefef !important;
  }

  tr th:last-child,
  tr td:last-child {
    text-align: right;
    padding-right: 20px;
  }

  tr td:first-child,
  tr th:first-child {
    text-align: left;
    padding-left: 38px;
  }

  thead,
  tbody {
    display: block;
  }

  tbody {
    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  thead tr {
    background-color: #ffffff !important;
    align-items: unset !important;
    &:first-child:hover {
      background-color: #ffffff !important;
    }
  }

  tr {
    display: flex;
    overflow: hidden;
    align-items: center;
  }
`;
