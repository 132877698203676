import { Box, Button, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Breadcrumbs } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import React from "react";
import { useHistory } from "react-router-dom";
import { textToDownloadFile } from "../../../Component/Utils/Helpers";
import Before from "../../../Lms/Assets/icons/BeforeNavigate.svg";
import { Container, FlexView } from "../../Assets/StyledComponents";
import {
  getConcepts,
  getCourses,
  getSubjects,
} from "../../Redux/Action/CourseMaterial";
import { feedbackExport, getFeedback } from "../../Redux/Action/FeedBack";
import { getTopicListByConceptId } from "../../Redux/Action/Test";
import PaginationComponent from "../../Utils/PaginationComponent";
import FilterComponent from "./FilterComponent";
import TableComponent from "./TableComponent";

function Index() {
  const { feedList, loading } = useSelector((state) => state.FeedbackReducer);
  const { courses, subjects, concepts } = useSelector(
    (state) => state.CourseMaterialReducer
  );
  const { topicList } = useSelector((state) => state.TestReducer);

  const feedbackData =
    (Array.isArray(feedList?.data?.content) && feedList.data.content) || [];
  const history = useHistory();

  const SIZE = 10;
  const [state, setState] = useState({
    courseId: "default",
    subjectId: null,
    conceptId: null,
    topicId: null,
    page: 0,
    totalPage: 0,
  });

  const { courseId, subjectId, conceptId, topicId, page, totalPage } = state;

  const handlePageChange = (e, newPage) => {
    setState({
      ...state,
      page: newPage - 1,
    });
  };

  useEffect(() => {
    if (feedList && feedList.success) {
      setState({
        ...state,
        totalPage: feedList.data?.totalPages || 0,
      });
    }
  }, [feedList]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getCourses((response) => {
        if (response.success) {
          dispatch(
            getSubjects(response.data[0]?.id, (subjectResponse) => {
              if (subjectResponse.success) {
                dispatch(
                  getConcepts(
                    subjectResponse.data[0]?.id,
                    (conceptResponse) => {
                      if (conceptResponse.success) {
                        dispatch(
                          getTopicListByConceptId(
                            conceptResponse.data[0]?.id,
                            (topicResponse) => {
                              setState({
                                ...state,
                                courseId: response.data[0]?.id,
                                subjectId: subjectResponse.data[0]?.id,
                                conceptId: conceptResponse.data[0]?.id,
                                topicId: topicResponse.data[0]?.id || null,
                              });
                            }
                          )
                        );
                      }
                    }
                  )
                );
              }
            })
          );
        }
      })
    );
  }, []);

  useEffect(() => {
    if (topicId) dispatch(getFeedback(page, SIZE, topicId));
  }, [page, topicId]);

  const handleExport = () => {
    dispatch(
      feedbackExport(topicId, (res) => {
        if (res.success)
          textToDownloadFile(res.data, "Feedback_Master_Report", "xlsx");
      })
    );
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "course") {
      dispatch(
        getSubjects(value, (subjectResponse) => {
          if (subjectResponse.success) {
            dispatch(
              getConcepts(subjectResponse.data[0].id, (conceptResponse) => {
                if (conceptResponse.success) {
                  dispatch(
                    getTopicListByConceptId(
                      conceptResponse.data[0]?.id,
                      (topicResponse) => {
                        setState({
                          ...state,
                          courseId: value,
                          subjectId: subjectResponse.data[0].id,
                          conceptId: conceptResponse.data[0].id,
                          topicId: topicResponse.data[0]?.id || null,
                          page: 0,
                        });
                      }
                    )
                  );
                }
              })
            );
          }
        })
      );
    } else if (name === "subject") {
      dispatch(
        getConcepts(value, (conceptResponse) => {
          if (conceptResponse.success) {
            dispatch(
              getTopicListByConceptId(
                conceptResponse.data[0]?.id,
                (topicResponse) => {
                  setState({
                    ...state,
                    subjectId: value,
                    conceptId: conceptResponse.data[0].id,
                    topicId: topicResponse.data[0]?.id || null,
                    page: 0,
                  });
                }
              )
            );
          }
        })
      );
    } else if (name === "concept") {
      dispatch(
        getTopicListByConceptId(value, (topicResponse) => {
          setState({
            ...state,
            conceptId: value,
            topicId: topicResponse.data?.[0]?.id,
            page: 0,
          });
        })
      );
    } else {
      setState({
        ...state,
        [name]: value,
        page: 0,
      });
    }
  };

  const filterProps = {
    courses: { ...courses },
    courseId: courseId,
    subjects: { ...subjects },
    subjectId: subjectId,
    concepts: { ...concepts },
    conceptId: conceptId,
    topicId: topicId,
    topicList: topicList,
    onChange: handleChange,
  };

  const disabled = feedbackData && feedbackData.length === 0;

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs>
          <FlexView>
            <div>
              <FlexView gap={"6px"}>
                <img
                  src={Before}
                  style={style.pointer}
                  onClick={() => history.goBack()}
                />
                <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />}>
                  <Typography style={style.navText}>{"Feedback"}</Typography>
                </Breadcrumbs>
              </FlexView>
            </div>
            <Button
              onClick={handleExport}
              variant={"contained"}
              style={style.exportButton(disabled)}
              disabled={disabled}
            >
              {"Export Report"}
            </Button>
          </FlexView>
        </Grid>
        <Grid item xs={12}>
          <FilterComponent {...filterProps} />
        </Grid>
        <Grid item xs={12}>
          <TableComponent feedbackData={feedbackData} pageNo={page} />
          <Box padding={"15px 0px 20px !important"}>
            <PaginationComponent
              pageCount={totalPage}
              onPageChange={handlePageChange}
              page={page + 1}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
export default Index;

const style = {
  navText: {
    fontWeight: "600",
    marginLeft: "10px",
    color: "#009be5",
  },
  pointer: {
    cursor: "pointer",
  },
  exportButton: (disabled) => ({
    background: !disabled && "#1093FF",
    color: !disabled && "#ffffff",
    textTransform: "none",
    minWidth: 130,
    fontWeight: 500,
    fontSize: "14px",
  }),
};
