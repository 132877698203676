import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import MySnackBar from "../MySnackBar";
import { GSContainer, GSTitle } from "../../Asset/StyledComponents/Styles";
import { makeStyles } from "@material-ui/core/styles";
import BackButton from "../../Asset/Images/backbutton.svg";
import { AppBar, Breadcrumbs } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Typography } from "@material-ui/core";
import { Grid, TextField } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useDispatch, useSelector } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  createBSround,
  getBSchoolSeasons,
  getOneBSchoolRound,
} from "../../Actions/BSchoolAction";
import { isEmptyString } from "../Validation";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
  },
  btnStyles: {
    paddingLeft: 30,
    paddingRight: 30,
  },
}));
export default function BSRound(props) {
  const {
    createBSProgramData,
    getSeasonDropdown,
    getBSchoolRoundPerData,
  } = useSelector((state) => state.BSchoolReducer);
  const classes = useStyles();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    roundNo: null,
    openDate: null,
    DeadlineDate: null,
    interviewDate: null,
    decisionReleaseDate: null,
    decisionDate: null,
    interviewDecisionDate: null,
    season: null,
    seasonOption: [],
    id: "",
    programId: null,
    intakeYear: null,
  });
  const [snack, setSnack] = useState({
    open: false,
    message: "",
    color: "",
  });
  const [intakeYearErr, setIntakeYearErr] = useState("");
  const textField = [
    {
      name: "roundNo",
      value: state.roundNo,
      label: "Round Number",
      type: "String",
    },
    {
      name: "openDate",
      value: state.openDate,
      label: "Open Date",
      type: "Date",
    },
    {
      name: "DeadlineDate",
      value: state.DeadlineDate,
      label: "DeadLine Date",
      type: "Date",
    },
    {
      name: "interviewDate",
      value: state.interviewDate,
      label: "Interview Date",
      type: "Date",
    },
    {
      name: "decisionReleaseDate",
      value: state.decisionReleaseDate,
      label: "Decision Release Date",
      type: "Date",
    },
    {
      name: "decisionDate",
      value: state.decisionDate,
      label: "Decision Date",
      type: "Date",
    },
    {
      name: "interviewDecisionDate",
      value: state.interviewDecisionDate,
      label: "Interview Decision Date",
      type: "Date",
    },
    {
      value: state.season,
      label: "Season",
      name: "season",
      type: "select",
      option: state.seasonOption,
    },
    {
      value: state.intakeYear,
      label: "Intake Year",
      name: "intakeYear",
      type: "number",
    },
  ];
  const onChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
    if (name === "intakeYear") {
      setIntakeYearErr("");
      if (value.length > 4 || value.length < 4) {
        setIntakeYearErr("Please Enter the valid Intake Year");
      }
      if (value === "" || null) {
        setIntakeYearErr("");
      }
    }
  };

  const handleSave = () => {
    if (state.intakeYear) {
      if (state.intakeYear?.length > 4 || state.intakeYear?.length < 4) {
        setIntakeYearErr("Please Enter the valid Intake Year");
      }
    }
    console.log(isEmptyString(intakeYearErr));
    if (
      state.roundNo &&
      state.season &&
      state.intakeYear &&
      isEmptyString(intakeYearErr)
    ) {
      if (state.id.length > 0) {
        let obj = {
          roundNo: Number(state.roundNo) === 0 || state.roundNo === null ? null : state.roundNo,
          openDate: state.openDate,
          deadlineDate: state.DeadlineDate,
          interviewDate: state.interviewDate,
          decisionReleaseDate: state.decisionReleaseDate,
          decisionDate: state.decisionDate,
          interviewDecisionDate: state.interviewDecisionDate,
          bsSeasonsId: state.season,
          programId: state.programId,
          id: state.id,
          intakeYear:
            Number(state.intakeYear) === 0 ? null : Number(state.intakeYear),
        };

        dispatch(
          createBSround(obj, (res) => {
            if (res.success) {
              setSnack({
                message: "Updated Successfully",
                open: true,
                color: "success",
              });
            } else {
              setSnack({
                message: res.message,
                open: true,
                color: "error",
              });
            }
          })
        );
      } else {
        let obj = {
          roundNo: Number(state.roundNo) === 0 || state.roundNo === null ? null : state.roundNo,
          openDate: state.openDate,
          deadlineDate: state.DeadlineDate,
          interviewDate: state.interviewDate,
          decisionReleaseDate: state.decisionReleaseDate,
          decisionDate: state.decisionDate,
          interviewDecisionDate: state.interviewDecisionDate,
          bsSeasonsId: state.season,
          programId: props.location.state,
          intakeYear:
            Number(state.intakeYear) === 0 ? null : Number(state.intakeYear),
        };
        dispatch(
          createBSround(obj, (res) => {
            if (res.success) {
              setSnack({
                message: "Added Successfully",
                open: true,
                color: "success",
              });
              setState({
                roundNo: null || "",
                openDate: null,
                DeadlineDate: null,
                interviewDate: null,
                decisionReleaseDate: null,
                decisionDate: null,
                interviewDecisionDate: null,
                season: null,
                seasonOption: [],
                id: "",
                programId: null,
                intakeYear: null || "",
              });
            } else {
              setSnack({
                message: res.message,
                open: true,
                color: "error",
              });
            }
          })
        );
      }
    }
  };

  useEffect(() => {
    dispatch(getBSchoolSeasons());
  }, []);

  useEffect(() => {
    setState({
      ...state,
      seasonOption: getSeasonDropdown,
    });
  }, [getSeasonDropdown]);

  const handleDateChange = (val, e, name) => {
    setState({
      ...state,
      [name]: e,
    });
  };

  useEffect(() => {
    if (props.location.state?.data?.id) {
      dispatch(getOneBSchoolRound(props.location.state.data.id));
    }
  }, []);

  useEffect(() => {
    if (getBSchoolRoundPerData !== null) {
      const {
        bsSeasonsId,
        deadlineDate,
        decisionDate,
        decisionReleaseDate,
        id,
        interviewDate,
        openDate,
        openDateMonth,
        openDateYear,
        roundNo,
        interviewDecisionDate,
        programId,
        intakeYear,
      } = getBSchoolRoundPerData;
      setState({
        ...state,
        roundNo: roundNo !== null ? roundNo : null,
        openDate: openDate,
        DeadlineDate: deadlineDate,
        interviewDate: interviewDate,
        decisionReleaseDate: decisionReleaseDate,
        decisionDate: decisionDate,
        id: id,
        programId: programId,
        season: bsSeasonsId,
        interviewDecisionDate: interviewDecisionDate,
        intakeYear: intakeYear,
      });
    }
  }, [getBSchoolRoundPerData]);

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div style={{ display: "flex", flexDirection: "row", margin: "10px" }}>
          <img
            src={BackButton}
            style={{ cursor: "pointer", marginTop: "-10px" }}
            onClick={() => props.history.goBack()}
          />
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Typography
              style={{
                cursor: "pointer",
                fontWeight: "600",
                marginLeft: "10px",
              }}
            >
              Back
            </Typography>
          </Breadcrumbs>
        </div>
        <AppBar position="static" color="default">
          <GSContainer>
            <GSTitle> {state.id.length > 0 ? "Edit" : "Add"} Round</GSTitle>
            <Grid
              container
              justifyContent="center"
              direction="column"
              alignItems="center"
              spacing={4}
            >
              <Grid item md={10}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container spacing={6}>
                    {textField.map((item) => {
                      return (
                        <Grid item md={6}>
                          {item.type === "Date" ? (
                            <KeyboardDatePicker
                              margin="normal"
                              // disableFuture
                              id="date-picker-dialog"
                              label={item.label}
                              format="yyyy-MM-dd"
                              value={item.value}
                              name={item.name}
                              fullWidth
                              // error={this.state.enrolldateErr.length > 0}
                              // helperText={this.state.enrolldateErr}
                              onChange={(newValue, e) =>
                                // this.setState({ enrolldate: newValue })
                                // handleDateChange(newValue)
                                handleDateChange(newValue, e, item.name)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          ) : item.type === "select" ? (
                            <FormControl fullWidth variant="standard">
                              <InputLabel
                                id="demo-simple-select-label"
                                shrink={item.value ? true : false}
                              >
                                {item.label}
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={item.value}
                                name={item.name}
                                label={item.label}
                                onChange={(e) => onChange(e)}
                              >
                                {item.option.map((menuItem, idx) => (
                                  <MenuItem value={menuItem.id} key={idx}>
                                    {menuItem.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : (
                            <TextField
                              name={item.name}
                              value={item.value}
                              label={item.label}
                              onChange={onChange}
                              fullWidth
                              type={item.type}
                              InputLabelProps={{
                                shrink: item.value
                                  ? true
                                  : item.value === 0
                                  ? true
                                  : false,
                              }}
                              error={
                                item.name === "intakeYear"
                                  ? intakeYearErr === ""
                                    ? false
                                    : true
                                  : false
                              }
                              helperText={
                                item.name === "intakeYear" ? intakeYearErr : ""
                              }
                            />
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={2}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  className={classes.btnStyles}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </GSContainer>
        </AppBar>
        <MySnackBar
          snackOpen={snack.open}
          snackVariant={snack.color}
          snackMsg={snack.message}
          onClose={() =>
            setSnack({
              open: false,
              message: "",
              color: "",
            })
          }
        />
      </div>
    </React.Fragment>
  );
}
