import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import MySnackBar from "../MySnackBar";
import { GSContainer, GSTitle } from "../../Asset/StyledComponents/Styles";
import { makeStyles } from "@material-ui/core/styles";
import BackButton from "../../Asset/Images/backbutton.svg";
import { AppBar, Breadcrumbs } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Typography } from "@material-ui/core";
import { Grid, TextField } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
  getBSclRegionList,
  createBSchoolRankList,
  getBSchoolBSRank,
} from "../../Actions/BSchoolAction";
import { isOnlyNumber, isEmptyString } from "../Validation";
import { clearData } from "../../Actions/StrategySession";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
  },
  btnStyles: {
    paddingLeft: 30,
    paddingRight: 30,
  },
}));
export default function BSRank(props) {
  const { getBSchoolRegionList } = useSelector((state) => state.BSchoolReducer);

  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    id: "",
    serialNo: null,
    type: null,
    year: null,
    rank: null,
    regionRank: null,
    bschoolId: null,
  });
  const [snack, setSnack] = useState({
    open: false,
    message: "",
    color: "",
  });
  const [regionList, setRegionList] = useState([]);
  const [regionErr, setRegionErr] = useState("");

  const textField = [
    {
      name: "serialNo",
      value: state.serialNo,
      label: "Serial No *",
      type: "number",
    },
    { name: "type", value: state.type, label: "Type *", type: "select" },
    { name: "year", value: state.year, label: "Year *", type: "number" },
    { name: "rank", value: state.rank, label: "Rank *", type: "number" },
    {
      name: "regionRank",
      value: state.regionRank,
      label: "Region rank *",
      type: "text",
    },
  ];

  const onChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const filter = createFilterOptions();

  const typeValues = [
    "The Finacial Times",
    "Bloomberg",
    "US News and Report",
    "Forbes",
    "The Economist",
    "US News",
    "Poets & Quants",
    "FT Ranking",
  ];

  // Rank Page Put Call//

  const handleSave = () => {
    if (state.serialNo && state.type && state.regionRank) {
      if (props.location.state?.data?.id) {
        let obj = {
          id: state.id,
          bschoolId: state.bschoolId,

          regionRank: state.regionRank === 0 ? null : state.regionRank,
          serialNo:
            Number(state.serialNo) === 0 ? null : Number(state.serialNo),
          type: state.type === 0 ? null : state.type,
          year: Number(state.year) === 0 ? null : Number(state.year),
          rank: Number(state.rank) === 0 ? null : Number(state.rank),
        };
        dispatch(
          createBSchoolRankList(obj, (res) => {
            if (res.success) {
              setSnack({
                ...snack,
                open: true,
                message: "Updated Successfully!",
                color: "success",
              });
            } else {
              setSnack({
                ...snack,
                open: true,
                message: res.data.message,
                color: "error",
              });
            }
          })
        );
      } else {
        let obj = {
          bschoolId: state.bschoolId,
          regionRank: state.regionRank === 0 ? null : state.regionRank,
          serialNo:
            Number(state.serialNo) === 0 ? null : Number(state.serialNo),
          type: state.type === 0 ? null : state.type,
          year: Number(state.year) === 0 ? null : Number(state.year),
          rank: Number(state.rank) === 0 ? null : Number(state.rank),
        };
        dispatch(
          createBSchoolRankList(obj, (res) => {
            if (res.success) {
              setState({
                id: "",
                serialNo: "",
                type: "",
                year: "",
                rank: "",
                regionRank: "",
                bschoolId: "",
              });
              setSnack({
                open: true,
                message: "Saved Successfully!",
                color: "success",
              });
            } else {
              setSnack({
                open: true,
                message: res.data.message,
                color: "error",
              });
            }
          })
        );
      }
    }
  };
  useEffect(() => {
    if (props.location.state && props.location.state?.add) {
      setState((e) => {
        return { ...e, bschoolId: props.location.state.bschoolId };
      });
    } else {
      setState(props.location.state.data);
    }
    dispatch(getBSclRegionList());
  }, []);

  useEffect(() => {
    if (getBSchoolRegionList !== null) {
      setRegionList(getBSchoolRegionList);
    }
  }, [getBSchoolRegionList]);

  //Get BS Rank Call//

  useEffect(() => {
    if (props.location.state?.data?.id) {
      dispatch(getBSchoolBSRank(props.location.state.data.id));
    }
  }, []);
  return (
    <React.Fragment>
      <div className={classes.root}>
        <div style={{ display: "flex", flexDirection: "row", margin: "10px" }}>
          <img
            src={BackButton}
            style={{ cursor: "pointer", marginTop: "-10px" }}
            onClick={() => props.history.goBack()}
          />
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Typography
              style={{
                cursor: "pointer",
                fontWeight: "600",
                marginLeft: "10px",
              }}
            >
              Back
            </Typography>
          </Breadcrumbs>
        </div>
        <AppBar position="static" color="default">
          <GSContainer>
            <GSTitle>
              {" "}
              {state.id.length > 0 ? "Edit" : "Add"} B - School Rank
            </GSTitle>
            <Grid
              container
              justifyContent="center"
              direction="column"
              alignItems="center"
              spacing={4}
            >
              <Grid item md={10}>
                <Grid container spacing={6}>
                  {textField.map((item) => {
                    return (
                      <Grid item md={6}>
                        {item.type === "select" ? (
                          <FormControl fullWidth variant="standard">
                            <InputLabel
                              id="demo-simple-select-label"
                              shrink={item.value ? true : false}
                            >
                              {item.label}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={item.value}
                              name={item.name}
                              label={item.label}
                              onChange={(e) => onChange(e)}
                            >
                              {typeValues.map((menuItem, idx) => (
                                <MenuItem value={menuItem} key={idx}>
                                  {menuItem}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ) : (
                          <TextField
                            value={item.value}
                            onChange={onChange}
                            name={item.name}
                            label={item.label}
                            type={item.type}
                            fullWidth
                          />
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item md={2}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  className={classes.btnStyles}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </GSContainer>
        </AppBar>
        <MySnackBar
          snackOpen={snack.open}
          snackVariant={snack.color}
          snackMsg={snack.message}
          onClose={() =>
            setSnack({
              open: false,
              message: "",
              color: "",
            })
          }
        />
      </div>
    </React.Fragment>
  );
}
