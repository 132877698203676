/**
 * (c) CareerLabs. All rights reserved.
 **/

import { Box, Checkbox, Grid, Radio, Typography } from "@material-ui/core";
import React from "react";
import {
  BundleDiv,
  FormControlLabel2,
  FormGroup,
  QuestionDiv,
  RadioGroup,
  TextBox,
} from "../../../../../Assets/css/Preview/GmatStyles";
import Latex from "../../../../../Utils/LatexViewer";
import BundleComp from "./Bundle";
import Binary from "./Binary";
import Twopart from "./Twopart";
import DropDown from "./DropDown";
import MultiTab from "./multitab";

const SingleSelect = (testResponse, state, onSelect) => {
  if (testResponse.data.isHaveDescription || testResponse.data.isHaveImage) {
    return (
      <Grid container>
        <Grid item xs={6} className="blue_border">
          <QuestionDiv>
            {testResponse.data.isHaveDescription && (
              <Typography variant="h4" color="textPrimary">
                <Latex math={testResponse.data.description} />
              </Typography>
            )}
            {testResponse.data.isHaveImage && (
              <img src={testResponse.data.imgURL} />
            )}
          </QuestionDiv>
        </Grid>
        <Grid item xs={6}>
          <QuestionDiv>
            <Typography variant="h4" color="textPrimary">
              <Latex math={testResponse.data.question} />
            </Typography>
            <RadioGroup value={true}>
              {testResponse.data.choices.map((choice, index) => (
                <FormControlLabel2
                  control={<Radio color="secondary" />}
                  value={choice.correctChoice}
                  onChange={onSelect}
                  label={
                    <Typography variant="h4" color="textPrimary">
                      {choice.text ? (
                        <Latex math={choice.text} />
                      ) : (
                        <img src={choice.choiceImage} alt="" />
                      )}
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>
          </QuestionDiv>
        </Grid>
      </Grid>
    );
  } else
    return (
      // ------------- Single Select With Image Choice and Text ------------------
      <QuestionDiv>
        <Typography variant="h5" color="textPrimary">
          <Latex math={testResponse.data.question} />
        </Typography>
        <RadioGroup value={true}>
          {testResponse.data.choices.map((choice, index) => (
            <FormControlLabel2
              control={<Radio color="secondary" />}
              value={choice.correctChoice}
              onChange={onSelect}
              label={
                <Typography variant="h4" color="textPrimary">
                  {choice.text ? (
                    <Latex math={choice.text} />
                  ) : (
                    <img src={choice.choiceImage} alt="" />
                  )}
                </Typography>
              }
            />
          ))}
        </RadioGroup>
      </QuestionDiv>
    );
};

const Passage = (testResponse, state, onTextChange) => (
  <Grid container>
    <Grid item xs={6} className="blue_border">
      <Box display="flex" height="100%" justifyContent="space-between">
        <QuestionDiv>
          {testResponse.data.isHaveDescription === false && (
            <Typography variant="h5" color="textPrimary">
              <Latex math={testResponse.data.question} />
            </Typography>
          )}
          {testResponse.data.isHaveDescription && (
            <Typography variant="h4" color="textPrimary">
              <Latex math={testResponse.data.description} />
            </Typography>
          )}
          {testResponse.data.isHaveImage && (
            <img src={testResponse.data.imgURL} />
          )}
        </QuestionDiv>
      </Box>
    </Grid>
    <Grid item xs={6}>
      <QuestionDiv>
        {testResponse.data.isHaveDescription && (
          <Typography
            variant="h5"
            color="textPrimary"
            style={{ marginBottom: "1em" }}
          >
            <Latex math={testResponse.data.question} />
          </Typography>
        )}
        <TextBox
          type={"text"}
          placeholder={"Enter your answer here"}
          onChange={onTextChange}
          value={state.textAnswer}
        />
      </QuestionDiv>
    </Grid>
  </Grid>
);

const MultiChoice = (testResponse, state, onMultiSelect) => {
  if (testResponse.data.isHaveDescription || testResponse.data.isHaveImage) {
    return (
      <Grid container>
        <Grid item xs={6} className="blue_border">
          <QuestionDiv>
            {testResponse.data.isHaveDescription && (
              <Typography variant="h4" color="textPrimary">
                <Latex math={testResponse.data.description} />
              </Typography>
            )}
            {testResponse.data.isHaveImage && (
              <img src={testResponse.data.imgURL} />
            )}
          </QuestionDiv>
        </Grid>
        <Grid item xs={6}>
          <QuestionDiv>
            <Typography variant="h4" color="textPrimary">
              <Latex math={testResponse.data.question} />
            </Typography>
            <FormGroup value={state.selectedChoice}>
              {testResponse.data.choices.map((choice, index) => (
                <FormControlLabel2
                  control={<Checkbox color="secondary" />}
                  value={choice.id}
                  checked={state.selectedChoice.indexOf(choice.id) > -1}
                  onChange={onMultiSelect}
                  label={
                    <Typography variant="h4" color="textPrimary">
                      {choice.text ? (
                        <Latex math={choice.text} />
                      ) : (
                        <img src={choice.choiceImage} alt="" />
                      )}
                    </Typography>
                  }
                />
              ))}
            </FormGroup>
          </QuestionDiv>
        </Grid>
      </Grid>
    );
  } else
    return (
      // ------------- Multi Select With Image Choice and Text ------------------
      <QuestionDiv>
        <Typography variant="h5" color="textPrimary">
          <Latex math={testResponse.data.question} />
        </Typography>
        <FormGroup value={state.selectedChoice}>
          {testResponse.data.choices.map((choice, index) => (
            <FormControlLabel2
              control={<Checkbox color="secondary" />}
              value={choice.id}
              checked={state.selectedChoice.indexOf(choice.id) > -1}
              onChange={onMultiSelect}
              label={
                <Typography variant="h4" color="textPrimary">
                  {choice.text ? (
                    <Latex math={choice.text} />
                  ) : (
                    <img src={choice.choiceImage} alt="" />
                  )}
                </Typography>
              }
            />
          ))}
        </FormGroup>
      </QuestionDiv>
    );
};

const Bundle = (testResponse, state, onBundleChange) => {
  return (
    <Grid container>
      <Grid
        item
        xs={testResponse.data.isHaveDescription === false ? 12 : 6}
        className="blue_border"
      >
        <QuestionDiv>
          {testResponse.data.isHaveDescription === false && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="h5" color="textPrimary">
                <Latex math={testResponse.data.question} />
              </Typography>
              <BundleDiv>
                <BundleComp
                  bundleLength={testResponse.data.totalBundle}
                  choices={testResponse.data.choices}
                  onChange={onBundleChange}
                  selectedChoice={state.bundleSelect}
                />
              </BundleDiv>
            </div>
          )}
          {testResponse.data.isHaveDescription && (
            <Typography variant="h4" color="textPrimary">
              <Latex math={testResponse.data.description} />
            </Typography>
          )}
          {testResponse.data.isHaveImage && (
            <img src={testResponse.data.imgURL} />
          )}
        </QuestionDiv>
      </Grid>
      {testResponse.data.isHaveDescription === true && (
        <Grid item xs={6}>
          <QuestionDiv>
            {/* {testResponse.data.isHaveDescription === true && ( */}
            <Typography variant="h5" color="textPrimary">
              <Latex math={testResponse.data.question} />
            </Typography>
            {/* )} */}
            <BundleDiv>
              <BundleComp
                bundleLength={testResponse.data.totalBundle}
                choices={testResponse.data.choices}
                onChange={onBundleChange}
                selectedChoice={state.bundleSelect}
              />
            </BundleDiv>
          </QuestionDiv>
        </Grid>
      )}
    </Grid>
  );
};

function Test({
  testResponse,
  state,
  onSelect,
  startTest2,
  location,
  onTextChange,
  onMultiSelect,
  onBundleChange,
  getTestSection,
}) {
  if (testResponse !== null) {
    if (testResponse.data.type === "SINGLE_SELECT") {
      return SingleSelect(testResponse, state, onSelect);
    } else if (testResponse.data.type === "SUBJECTIVE")
      return Passage(testResponse, state, onTextChange);
    else if (testResponse.data.type === "FRACTION")
      return Passage(testResponse, state, onTextChange);
    else if (testResponse.data.type === "MULTI_CHOICE")
      return MultiChoice(testResponse, state, onMultiSelect);
    else if (testResponse.data.type === "BUNDLE")
      return Bundle(testResponse, state, onBundleChange);
    else if (testResponse.data.type === "BINARY") {
      return <Binary testResponse={testResponse} />;
    } else if (testResponse.data.type === "TWO_PART") {
      return <Twopart testResponse={testResponse} />;
    } else if (testResponse.data.type === "DROP_DOWN") {
      return <DropDown testResponse={testResponse} />;
    } else if (testResponse.data.multitype === "MULTI_TAB") {
      return <MultiTab testResponse={testResponse} />;
    } else return null;
  }
  return null;
}

export default Test;
