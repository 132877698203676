import React from 'react';
import { Dialog, DialogTitle, DialogContent, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Controls from '../../Utils/controls/Controls';

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: theme.spacing(3),
    position: 'absolute',
    top: theme.spacing(2),
  },
  dialogTitle: {
    padding: '2px 10px',
  },
  title: {
    flexGrow: 1,
    fontSize: '19px',
    fontWeight: 800,
    marginBottom: '10px',
    color: '#1093FF',
  },
  closeIcon: {
    marginBottom: '10px',
    marginLeft: '10px',
    padding: '0px',
    color: 'red',
  },
}));

export default function Popup(props) {
  const { title, children, openPopup, setOpenPopup } = props;
  const classes = useStyles();

  return (
    <Dialog open={openPopup} maxWidth='lg' classes={{ paper: classes.dialogWrapper }}>
      <DialogTitle className={classes.dialogTitle}>
        <div style={{ display: 'flex' }}>
          <Typography variant='h6' component='div' className={classes.title}>
            {title}
          </Typography>
          <Controls.ActionButton
            color='secondary'
            className={classes.closeIcon}
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            <CloseIcon />
          </Controls.ActionButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}
