import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React from "react";
import CvViewer from "../ProfileGapAnalysis/CvViewer";
import { DocumentUploadPopup } from "../Utils/controls/DocumentUploadPopup";
import { customTheme, StyledButton } from "../Utils/controls/Styles";
import { DownloadCvTable } from "../Utils/DownloadCvTable";

function DocumentComponentForReport({
  open,
  stepName,
  handleCancel,
  handleUploadReport,
  handleUploadClick,
  onDrop,
  handleComment,
  activeTabValue,
  handleStageComplete,
  handleDownload,
  handleDelete,
  tableData,
  handleChange,
  handleDownloadReport,
  comment,
  fileName,
  fileNameHelperText,
  commentHelperText,
  file,
  disabledUploadButton,
  isDisabledFileName,
  lastestCVLoading,
  ...props
}) {
  return (
    <Grid container>
      <Grid item xs={8}>
        <Box margin={"26px 20px 30px 30px"}>
          <Box textAlign={"right"}>
            {activeTabValue === "QPMC 2" && (
              <StyledButton
                variant={"outlined"}
                color={"primary"}
                style={customTheme["palette"][("outlined", "margin")]}
                onClick={handleStageComplete}
              >
                {"QPMC 2 Complete"}
              </StyledButton>
            )}
            &nbsp;
            <StyledButton
              variant={"contained"}
              color={"primary"}
              style={customTheme["palette"]["contained"]}
              onClick={handleUploadClick}
              //   disabled={disabledUploadButton}
            >
              {"Upload QPMC 2 Report"}
            </StyledButton>
          </Box>
          <Grid item xs={12}>
            <Box>
              <DownloadCvTable
                headers={["Version", "Uploaded date", "Comment", ""]}
                body={tableData}
                handleComment={handleComment}
                handleDownload={handleDownloadReport}
                handleDelete={handleDelete}
              />
            </Box>
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={4}>
        {/* <CvViewer doctype={"cv"} cvloading={lastestCVLoading} {...props} /> */}
      </Grid>
      <DocumentUploadPopup
        open={open}
        title={`Upload Report | ${stepName}`}
        leftButtonText={"Upload"}
        rightButtonText={"Cancel"}
        handleLeftButton={handleUploadReport}
        handleRightButton={handleCancel}
        handleClose={handleCancel}
        acceptTypes={".xlsx,.xls,.doc,.docx,.pdf"}
        onDrop={onDrop}
        handleChange={handleChange}
        comment={comment}
        fileName={fileName}
        fileNameHelperText={fileNameHelperText}
        commentHelperText={commentHelperText}
        file={file}
        isDisabledFileName={isDisabledFileName}
      />
    </Grid>
  );
}

export default DocumentComponentForReport;
