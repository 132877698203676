import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { URL } from "../../Actions/URL";
import PdfViewer from "../../Utils/PdfViewer";
import CvViewer from "../ProfileGapAnalysis/CvViewer";
import { DocumentUploadPopup } from "../Utils/controls/DocumentUploadPopup";
import { customTheme, StyledButton, Typo } from "../Utils/controls/Styles";
import { DownloadCvTable } from "../Utils/DownloadCvTable";
import FileViewer from "../../Utils/FileViewer";
import {
  clearCustomData,
  getQPMC2ReportCall,
  qpmcStageComplete,
  uploadFileForReport,
} from "../../Actions/ProfileMentoring";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { bytesToMegaBytes } from "../Utils/Helpers";
import { CommentBoxPopper } from "../Utils/controls/CommentBoxPopper";
import MySnackBar from "../MySnackBar";
import {
  clearData,
  getDownloadByDocumentId,
  getFilePath,
} from "../../Actions/StrategySession";
import {
  getStepsBySubStageId,
  getStudentStageByProductId,
} from "../../Actions/Student";
const FILE_FORMAT_ERROR = "Invalid file format";
const FILE_SIZE_ERROR = "Please check the file size";
const FILE_UPLOAD_ERROR = "Please select a file";
const UPLOADED_SUCCESS = "Uploaded Successfully";
const REQUIRED_ERROR = "Please fill the required field";
function StrategySessionFileUpload({
  // open,
  stepName,
  // handleCancel,
  // handleUpload,
  // handleUploadClick,
  onDrop,
  // handleComment,
  handleDownload,
  handleDelete,
  activeTabValue,
  tableData,
  // handleStageComplete,
  // handleChange,
  // comment,
  // fileName,
  // fileNameHelperText,
  // commentHelperText,
  // file,
  completeValue,
  disabledUploadButton,
  isDisabledFileName,
  stageName,
  stageId,
  fileId,
  ...props
}) {
  console.log(activeTabValue);
  const [state, setState] = useState({
    steps: [],
    documentList: [],
    activeTabValue: null,
    activeNeededValue: null,
    activeMainTabValue: "Pre Strategy call",
    open: false,
    comment: null,
    upcomingFileName: null,
    fileName: null,
    fileNameHelperText: "",
    commentHelperText: "",
    file: null,
    snackMsg: "",
    snackOpen: false,
    snackVariant: "",
    status: null,
    anchorEl: null,
    popoverComment: null,
    completedStagesList: [],
  });
  const {
    open,
    fileName,
    fileNameHelperText,
    commentHelperText,
    file,
    comment,
    documentList,
    upcomingFileName,
    snackMsg,
    snackOpen,
    snackVariant,
    popoverComment,
    anchorEl,
  } = state;
  const dispatch = useDispatch();
  const params = useParams();
  const { loading, fileObject } = useSelector(
    (state) => state.StrategySessionReducer
  );
  const { studentStages, subStageSteps, completedStages } = useSelector(
    (state) => state.StudentReducer
  );
  const handleDownloadStrategy = (path, id) => {
    console.log(path, id, "^6666666");
    dispatch(getDownloadByDocumentId(studentId, strategyDocumentID, path, id));
  };
  const { studentId, productId, subStageId } = params;
  const handleStageComplete = () => {
    dispatch(qpmcStageComplete(studentId, productId, completeValue));
    setState({
      ...state,
      snackOpen: true,
      snackVariant: "success",
      snackMsg: "Strategy Session Completed Successfully",
    });
  };
  console.log(
    studentStages?.data
      ?.find((el) => el.stepName === "Strategy Session")
      ?.steps?.find((el) => el.stepName === "Strategy Session")
      ?.steps?.find((el) => el.stepName === "Strategy Documents")?.id
  );
  const strategyDocumentID = studentStages?.data
    ?.find((el) => el.stepName === "Strategy Session")
    ?.steps?.find((el) => el.stepName === "Strategy Session")
    ?.steps?.find((el) => el.stepName === "Strategy Documents")?.id;
  const {
    fileUploadStatus,
    getQPMC2ReportStatus,
    qpmcStageCompletionStatus,
  } = useSelector((state) => state.ProfileMentoringReducer);
  useEffect(() => {
    dispatch(clearData());
  }, []);
  useEffect(() => {
    dispatch(clearData());
    dispatch(
      getQPMC2ReportCall(
        studentId,
        productId,
        strategyDocumentID,
        (response) => {
          console.log(
            response.status,
            response.data.data,
            "getthereportstatus"
          );
          if (response.status === 200) {
            setState({
              documentList:
                (response.data?.data?.length && response.data?.data) || [],
            });
          }
        }
      )
    );
    // dispatch(getQPMC2ReportCall(studentId, productId, strategyDocumentID));
    dispatch(getStudentStageByProductId(studentId, productId));
  }, []);
  console.log(documentList, "gethtedocumentresponse");
  useEffect(() => {
    if (getQPMC2ReportStatus) {
      if (getQPMC2ReportStatus.success) {
        const { data } = getQPMC2ReportStatus;
        setState({
          ...state,
          // upcomingFileName: data.fileName,
          // status: data.stepStatus,
          documentList: data || [],
          open: false,
        });
        const index = data.length - 1;
        const path = data.length !== 0 ? data[index]["path"] : "";
        dispatch(getFilePath(studentId, strategyDocumentID, path));
      } else {
        // setState({
        //   ...state,
        //   snackOpen: true,
        //   snackVariant: "error",
        //   snackMsg: getQPMC2ReportStatus.message,
        //   documentList: [],
        //   status: null,
        //   upcomingFileName: null,
        // });
      }
      dispatch(clearCustomData("documentModel"));
    }
  }, [getQPMC2ReportStatus]);
  const [msg, setMsg] = useState(false);
  const handleUpload = () => {
    let error = false;
    if (!file) {
      error = true;
      setState({
        ...state,
        snackOpen: true,
        snackMsg: FILE_UPLOAD_ERROR,
        snackVariant: "error",
      });
    } else if (!(fileName && fileName.trim().length !== 0)) {
      error = true;
      setState({ ...state, fileNameHelperText: REQUIRED_ERROR });
    } else if (!(comment && comment.trim().length !== 0)) {
      error = true;
      setState({ ...state, commentHelperText: REQUIRED_ERROR });
    }
    if (!error && fileId) {
      var fileObj = file;
      var newFileName = fileName;
      console.log(fileName, "**************fileName");
      var newFileType = fileObj.path.split(".").pop();
      console.log(newFileType, "*************fileName1");
      var blob = new Blob([fileObj], { type: newFileType });
      console.log(blob, "*******************fileName2");
      var newFile = new File([blob], `${newFileName}`, {
        type: newFileType,
      });
      console.log(newFile, "*****************fileName3");
      let uploadFormData = new FormData();
      uploadFormData.append("file", newFile);
      uploadFormData.append("comment", comment);
      dispatch(
        uploadFileForReport(
          studentId,
          productId,
          strategyDocumentID,
          uploadFormData
        )
      );
      setState({
        ...state,
        snackOpen: true,
        snackVariant: "success",
        snackMsg: UPLOADED_SUCCESS,
      });
    }
    setMsg(true);
  };
  useEffect(() => {
    if (fileUploadStatus) {
      if (fileUploadStatus.success) {
        console.log("file uploaded successfully", fileUploadStatus.success);

        let requestBody = {
          id: fileUploadStatus.data?.id,
          uploadedBy: "admin",
          status: "Review Completed",
          comment: comment,
          fileName: fileName,
        };
        dispatch(getQPMC2ReportCall(studentId, productId, strategyDocumentID));
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackVariant: "error",
          snackMsg: fileUploadStatus.message,
        });
      }
      dispatch(clearCustomData("fileUploadStatus"));
    }
  }, [msg]);
  useEffect(() => {
    if (fileUploadStatus?.success) {
      dispatch(getQPMC2ReportCall(studentId, productId, strategyDocumentID));
    }
  }, [fileUploadStatus]);
  const handleDrop = (fileArray) => {
    if (fileArray.length !== 0) {
      if (bytesToMegaBytes(fileArray[0]["size"]) > 5) {
        setState({
          ...state,
          snackOpen: true,
          snackMsg: FILE_SIZE_ERROR,
          snackVariant: "error",
        });
      } else {
        setState({
          ...state,
          file: fileArray[0],
          fileName: upcomingFileName || fileArray[0]["name"],
        });
      }
    } else {
      setState({
        ...state,
        snackOpen: true,
        snackMsg: FILE_FORMAT_ERROR,
        snackVariant: "error",
      });
    }
  };

  const handleComment = (comment, e) => {
    console.log(comment, e, "*************jai");
    setState({
      ...state,
      popoverComment: comment,
      anchorEl: e.currentTarget,
    });
  };
  const handleSnackClose = () => {
    setState({ ...state, snackOpen: false, snackVariant: "", snackMsg: "" });
  };

  const handleClickAway = () => {
    setState({ ...state, anchorEl: null, popoverComment: null });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value, [`${name}HelperText`]: null });
  };
  const handleUploadClick = () => {
    setState({ ...state, open: true, fileName: "", comment: "" });
  };
  const handleCancel = () => {
    setState({
      ...state,
      file: null,
      fileName: null,
      comment: null,
      fileNameHelperText: "",
      commentHelperText: "",
      open: false,
    });
  };

  return (
    <Grid container>
      <Grid item xs={8}>
        {/* <Grid style={{ marginLeft: "20px" }}>{stageName} Download</Grid> */}

        <Box margin={"26px 20px 30px 30px"}>
          {/* <Grid style={{ marginLeft: "20px" }}>{stageName} Download</Grid> */}
          <Box textAlign={"right"}>
            {activeTabValue === "Strategy Session" && (
              <StyledButton
                variant={"outlined"}
                // disabled={qpmcStageCompletionStatus}
                style={customTheme["palette"]["outlined"]}
                onClick={handleStageComplete}
              >
                {"Strategy Session Complete"}
              </StyledButton>
            )}
            &nbsp;&nbsp;&nbsp;
            <StyledButton
              variant={"contained"}
              style={customTheme.palette.contained}
              onClick={handleUploadClick}
              // disabled={disabledUploadButton}
            >
              {"Upload"}
            </StyledButton>
          </Box>
          <Grid item xs={12}>
            <Box>
              <DownloadCvTable
                headers={["Version", "Uploaded date", "Comment", ""]}
                body={documentList || []}
                handleComment={handleComment}
                handleDownload={handleDownloadStrategy}
                handleDelete={handleDelete}
              />
            </Box>
          </Grid>
        </Box>
      </Grid>
      {console.log(fileObject, "**************fileObject")}
      <Grid item xs={4}>
        <FileViewer
          filePath={window.URL.createObjectURL(new Blob([fileObject?.path]))}
          fileType={fileObject?.type}
        />
      </Grid>
      <DocumentUploadPopup
        open={open}
        title={`Upload Document | ${stepName}`}
        leftButtonText={"Upload"}
        rightButtonText={"Cancel"}
        handleLeftButton={handleUpload}
        handleRightButton={handleCancel}
        handleClose={handleCancel}
        acceptTypes={".doc, .docx, .xls, .xlsx, .csv, .pdf"}
        onDrop={handleDrop}
        handleChange={handleChange}
        comment={comment}
        fileName={fileName}
        fileNameHelperText={fileNameHelperText}
        commentHelperText={commentHelperText}
        file={file}
        isDisabledFileName={isDisabledFileName}
      />
      <CommentBoxPopper
        handleClickAway={handleClickAway}
        anchorEl={anchorEl}
        popperComment={popoverComment}
      />
      <MySnackBar
        onClose={handleSnackClose}
        snackOpen={snackOpen}
        snackVariant={snackVariant}
        snackMsg={snackMsg}
      />
    </Grid>
  );
}

export default StrategySessionFileUpload;
