import {
  Box,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Dropdown } from "bootstrap";
import React from "react";
import DropzoneComponent from "./CustomDropZone.js";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  customTheme,
  DialogBox,
  DialogHeaderTitle,
  StyledButton,
  Typo,
  useStyles,
} from "./Styles.js";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountryPAS,
  getDropDownPAS,
  getRegionPAS,
} from "../../../Actions/PostAdmitServices.js";

function DocumentUploadPopupForPostAdmit(
  {
    open,
    title,
    variant,
    width,
    leftButtonText,
    rightButtonText,
    handleLeftButton,
    handleRightButton,
    handleClose,
    acceptTypes,
    onDrop,
    handleChange,
    fileName,
    comment,
    fileNameHelperText,
    commentHelperText,
    file,
    isDisabledFileName,
    needed,
    neededNext,
    neededName,
  },
  props
) {
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();
  const [documentName, setDocumentName] = useState("");
  const [countryRegion, setCountryRegion] = useState("");
  const { studentId, productId } = params;
  const { PostAdmitServicesDropDown, getCountry, getRegion } = useSelector(
    (state) => state.PostAdmitServicesReducer
  );
  useEffect(() => {
    dispatch(getDropDownPAS(studentId));
    dispatch(getCountryPAS());
    dispatch(getRegionPAS(studentId));
  }, []);
  console.log(PostAdmitServicesDropDown, getCountry, getRegion);
  const Onchange = (newValue) => {
    neededName(newValue?.name);
    setDocumentName(newValue);
    needed(newValue?.id);
    setCountryRegion(null);
  };
  const OnchangeCountryRegion = (newValue) => {
    setCountryRegion(newValue);
    neededNext(newValue?.id);
  };
  useEffect(() => {
    if (open === false) {
      setDocumentName(null);
      setCountryRegion(null);
    }
  }, [open]);
  return (
    <DialogBox
      open={open}
      maxWidth={variant ? variant : "sx"}
      width={width || "600px"}
      onClose={handleClose}
    >
      <DialogHeaderTitle>{title}</DialogHeaderTitle>
      <DialogContent className={classes.dialogContent}>
        <Box>
          <DropzoneComponent acceptTypes={acceptTypes} onDrop={onDrop} />
          <Box
            margin={"20px 15px 0px 0px"}
            display={"flex"}
            flexDirection={"column"}
            gridGap={"15px"}
          >
            <Typo color={"#333333"} fontSize={"18px"} paddingBottom={"15px"}>
              {"Document Details"}
            </Typo>
            {console.log(documentName, "sadasdasdasdasdasd")}
            <Autocomplete
              id="debug"
              disabled={false}
              options={PostAdmitServicesDropDown?.data}
              getOptionLabel={(option) => option.name}
              value={documentName || []}
              onChange={(e, newValue) => Onchange(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // disabled={this.state.documentedit}
                  // error={this.state.diplomaCollegeErr.length > 0}
                  // helperText={this.state.diplomaCollegeErr}
                  label="Document Name/Type"
                  // margin="normal"
                />
              )}
            />
            {(documentName?.name === "Scholarship details" ||
              documentName?.name === "Visa checklist") && (
              <Autocomplete
                id="debug"
                disabled={false}
                options={
                  documentName?.name === "Visa checklist"
                    ? getCountry?.data
                    : getRegion?.data
                }
                getOptionLabel={(option) => option.name}
                value={countryRegion || []}
                onChange={(e, newValue) => OnchangeCountryRegion(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // disabled={this.state.documentedit}
                    // error={this.state.diplomaCollegeErr.length > 0}
                    // helperText={this.state.diplomaCollegeErr}
                    label={
                      documentName?.name === "Scholarship details"
                        ? "Region"
                        : "Country"
                    }
                    // margin="normal"
                  />
                )}
              />
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogBottomContainer}>
        {rightButtonText && (
          <StyledButton
            variant={"contained"}
            style={customTheme.palette.contained}
            onClick={handleLeftButton}
          >
            {leftButtonText}
          </StyledButton>
        )}
        <StyledButton
          variant={"text"}
          style={customTheme.palette.text}
          onClick={handleRightButton}
        >
          {rightButtonText}
        </StyledButton>
      </DialogActions>
    </DialogBox>
  );
}

export { DocumentUploadPopupForPostAdmit };
