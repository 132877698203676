import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@mui/material/Box';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import { userJourneyData } from '../../../Actions/DashboardAction';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Lms/Utils/Loader';
import DateRangePickerComp from './DateRangePicker';
import UserJourneyChart from './UserJourneyChart';

const useStyle = makeStyles((theme) => ({
  paper: {
    boxShadow: '0px 16px 24px rgba(146, 170, 212, 0.12)',
    borderRadius: '8px',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  textWithIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  headerText: {
    fontSize: '18px',
    color: '#354354',
  },
  modalContainer: {
    textAlign: 'center',
    alignItems: 'center',
    width: '140px',
    position: 'absolute',
    right: 0,
    top: -50,
    backgroundColor: '#fefefe',
    borderRadius: '8px',
  },
  btnText: {
    height: '25px',
    width: '100%',
  },
  buttonContainer: {
    border: '1px solid #E1E5EB',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    color: '#354354',
    width: '100px',
    height: '35px',
    cursor: 'pointer',
  },
  icons: {
    fontSize: '6px',
    color: '#828D98',
  },
}));

const DashboardUserJourney = () => {
  const classes = useStyle();
  const [startDate, setStartDate] = useState(new Date(new Date().setUTCHours(0, 0, 0)));
  const [endDate, setEndDate] = useState(new Date(new Date().setUTCHours(23, 59, 59)));
  const [value, setValue] = useState(false);

  const dispatch = useDispatch();
  const { loading, error, userJourney } = useSelector((state) => state.dashboardUserJourneyReducer);

  useEffect(() => {
    userJourneyCalling();
    setValue(false);
  }, [value]);

  let requestBody = {
    fromDate: startDate,
    toDate: endDate,
  };

  const userJourneyCalling = () => {
    dispatch(userJourneyData(requestBody));
  };

  return (
    <Grid item xs={12}>
      <Paper elevation={3} className={classes.paper}>
        <div style={{ padding: '20px' }}>
          <Box className={classes.headerContainer}>
            <Box className={classes.textWithIcon}>
              <PersonPinIcon sx={{ color: 'rgb(255, 114, 132)', fontSize: '40px' }} />
              <Typography fontWeight={'500'} marginLeft={2} className={classes.headerText}>
                User Journey
              </Typography>
            </Box>
            <Box>
              <DateRangePickerComp
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setValue={setValue}
              />
            </Box>
          </Box>
        </div>
        {userJourney?.data && (
          <UserJourneyChart
            series={[
              {
                name: 'Total Count',
                data: [
                  userJourney?.data?.infoSplashPageReached,
                  userJourney?.data?.infoSplashPageGetStartedClicked,
                  userJourney?.data?.phNoPageReached,
                  userJourney?.data?.getOtpPressed,
                  userJourney?.data?.otpPageReached,
                  userJourney?.data?.otpEntered,
                  userJourney?.data?.nameEntered,
                  userJourney?.data?.emailEntered,
                  userJourney?.data?.yearEntered,
                  userJourney?.data?.goalSelected,
                  userJourney?.data?.landOnRespectiveHomePage,
                ],
                color: '#22D1EE',
              },
            ]}
            categories={[
              'IS Screen',
              'IS Btn Clicked',
              'Phone Number ',
              'Get OTP',
              'OTP Page',
              'OTP Entered',
              'Name',
              'Email',
              'YOG',
              'Goals',
              'Home Page',
            ]}
            yaxisTitle={'User Count'}
          />
        )}
        <div style={{ margin: '2rem auto', width: '60%' }}>
          {loading && <Loader />}
          {error && <Alert severity='info'>0 Tests Found</Alert>}
        </div>
      </Paper>
    </Grid>
  );
};

export default DashboardUserJourney;
