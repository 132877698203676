import React, { Component } from 'react'
import './Asset/Spinner.css'
export default class App extends Component {
  //other logic
  render() {
    return (
      <div className="spin-load">
        <div class="sk-circle">
          <div class="sk-circle1 sk-child"></div>
          <div class="sk-circle2 sk-child"></div>
          <div class="sk-circle3 sk-child"></div>
          <div class="sk-circle4 sk-child"></div>
          <div class="sk-circle5 sk-child"></div>
          <div class="sk-circle6 sk-child"></div>
          <div class="sk-circle7 sk-child"></div>
          <div class="sk-circle8 sk-child"></div>
          <div class="sk-circle9 sk-child"></div>
          <div class="sk-circle10 sk-child"></div>
          <div class="sk-circle11 sk-child"></div>
          <div class="sk-circle12 sk-child"></div>
        </div>
        <div className="msg">
          <label>Please give us a moment to procces information</label><br />
          <label>We wil share the curated career tracks shortly</label>
        </div>
      </div>
    );
  }
}