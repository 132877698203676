import React, { useEffect, useState } from "react";
import TableComponent from "../TableComponent/TableComponent";
import { createTheme, Grid, ThemeProvider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BackButton from "../../Asset/Images/backbutton.svg";
import { Breadcrumbs } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Typography } from "@material-ui/core";
import { addBSEssayQuestion, studentPath } from "../RoutePaths";
import { useDispatch, useSelector } from "react-redux";
import { clearData, getAllEsEssayQuestions } from "../../Actions/BSchoolAction";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
  },
}));
export default function BSEssayQuesIndex(props) {
  const { getBSchoolEssayQuesData } = useSelector(
    (state) => state.BSchoolReducer
  );
  const dispatch = useDispatch();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const col = [
    {
      title: "ID",
      fieldName: "id",
    },
    { title: "Order No", fieldName: "orderNo" },
    { title: "Question", fieldName: "question" },
    { title: "Created By", fieldName: "createdBy" },
    { title: "Created Date", fieldName: "createdDate" },
    { title: "Updated By", fieldName: "updatedBy" },
    { title: "Updated Date", fieldName: "updatedDate" },
  ];

  const tableTheme = () => {
    createTheme({
      palette: {
        primary: {
          main: "#007bff",
        },
      },
      overrides: {
        MuiTypography: {
          h6: {
            fontWeight: "bold",
          },
        },
        MuiIconButton: {
          root: {
            "&:hover": {
              backgroundColor: "none",
              borderRadius: 0,
            },
          },
        },
      },
    });
  };

  const handleClickOpen = () => {
    props.history.push({
      pathname: addBSEssayQuestion,
      state: props.location.state.data.id,
    });
    dispatch(clearData());
  };

  const handleEdit = (data) => {
    props.history.push({ pathname: addBSEssayQuestion }, { state: data });
  };

  useEffect(() => {
    dispatch(getAllEsEssayQuestions(props.location.state.data.id, 0, 20));
  }, []);
  useEffect(() => {
    if (getBSchoolEssayQuesData !== null) {
      setData(getBSchoolEssayQuesData.content);
    }
  }, [getBSchoolEssayQuesData]);
  const paginate = (page, size) => {
    dispatch(getAllEsEssayQuestions(props.location.state.data.id, page, size));
  };
  return (
    <React.Fragment>
      <div className={classes.root}>
        <div style={{ display: "flex", flexDirection: "row", margin: "10px" }}>
          <img
            src={BackButton}
            style={{ cursor: "pointer", marginTop: "-10px" }}
            onClick={() => props.history.goBack()}
          />
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Typography
              style={{
                cursor: "pointer",
                fontWeight: "600",
                marginLeft: "10px",
              }}
              onClick={() => props.history.push(studentPath)}
            >
              Back
            </Typography>
          </Breadcrumbs>
        </div>
        <ThemeProvider theme={tableTheme}>
          <div>
            <Grid container>
              <Grid item md={12}>
                <TableComponent
                  cols={col}
                  data={data}
                  title={"Essay Question"}
                  add={true}
                  action={true}
                  onAddClick={handleClickOpen}
                  totalCount={
                    getBSchoolEssayQuesData &&
                    getBSchoolEssayQuesData.totalElements
                  }
                  totalPage={
                    getBSchoolEssayQuesData &&
                    getBSchoolEssayQuesData.totalPages
                  }
                  onEdit={true}
                  notNeedSearchPlace={true}
                  onEditClick={handleEdit}
                  paginate={paginate}
                />
              </Grid>
            </Grid>
          </div>
        </ThemeProvider>
      </div>
    </React.Fragment>
  );
}
