import React from "react";
import Grid from "@mui/material/Grid";
import Controls from "../../Utils/controls/Controls";
import { makeStyles } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import { SemesterButton, Typodef } from "../Assets/Styles/PopoverStyles";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  doneButton: {
    position: "absolute",
    padding: " 4px 40px 4px 40px",
    marginTop: "19px",
    marginLeft: "230px",
  },
  newButton: {
    borderRadius: "26px",
    width: "195px",
    fontSize: "15px",
  },
  popupStyle: {
    marginRight: "2px",
    width: "100rem",
    marginLeft: "-2rem",
  },
  root: {
    "&.MuiPaper-root": {
      top: "313px",
      left: "-65px",
    },
  },
  MuiPopoverPaper: {
    backgroundColor: "red",
  },
}));

const Semester = [
  {
    id: 1,
    SemNo: "Semester 1",
  },
  {
    id: 2,
    SemNo: "Semester 2",
  },
  {
    id: 3,
    SemNo: "Semester 3",
  },
  {
    id: 4,
    SemNo: "Semester 4",
  },
  {
    id: 5,
    SemNo: "Semester 5",
  },
  {
    id: 6,
    SemNo: "Semester 6",
  },
  {
    id: 7,
    SemNo: "Semester 7",
  },
  {
    id: 8,
    SemNo: "Semester 8",
  },
];

export const SemesterPopover = ({
  semButtons,
  handleSemester,
  handleDoneClick,
  handleClick,
  open,
  handleClose,
  selectSem,
}) => {
  const classes = useStyles();

  return (
    <>
      <Controls.Button
        text='Add Semester'
        variant='contained'
        color='primary'
        disabled={selectSem.length === 8}
        startIcon={<AddIcon />}
        className={classes.newButton}
        onClick={handleClick}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='lg'
        lg={{ "& .MuiDialog-paper": { maxWidth: "100%", maxHeight: 500 } }}
      >
        <Grid container spacing={1} padding={3}>
          <Grid item md={12}>
            <Typodef>Select Semester</Typodef>
          </Grid>
          <Grid item md={8}>
            <Grid container>
              {Semester.map((sem) => (
                <Grid item md={3} padding={2}>
                  <SemesterButton
                    onClick={() => {
                      handleSemester(sem.id);
                    }}
                    act={semButtons.includes(sem.id)}
                  >
                    {sem.SemNo}
                  </SemesterButton>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item md={4}>
            <Controls.Button
              text={`Done`}
              variant='contained'
              color='primary'
              className={classes.doneButton}
              onClick={handleDoneClick}
              type={"submit"}
            />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};
