import { Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { uploadPremiumUsers } from '../../Actions/PremiumUsersActions';
import ConfirmDialog from '../Utils/ConfirmDialog';
import Controls from '../Utils/controls/Controls';
import Notification from '../Utils/Notification';
import { Container, TopTab, TopTabs } from '../Wall/Assets/Styles/WallStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiSelect-root': {
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '4px',
      padding: '1rem',
    },
  },
  btnStyle: {
    borderRadius: '24px',
    width: '200px',
    height: '45px',
    fontSize: '14px',
  },
  dropzone: {
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 5,
    cursor: 'pointer',
    background: theme.palette.background.default,
    height: theme.spacing(12),
    outline: 'none',
    fontSize: '16px',
  },
}));

const PremiumUserLanding = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let location = useLocation();
  const [tabCount, setTabCount] = useState(location.tab ?? 0);
  const [uploadDisabled, setUploadDisabled] = useState(false);
  const [state, setState] = useState({
    premiumUsersCategories: [],
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const handlePremiumUsersSheetUpload = async (e, formFieldsData) => {
    const fileType = e.currentTarget.files[0].name;
    setUploadDisabled(true);
    // File type must be .csv file
    if (fileType.includes('.csv')) {
      let formData = new FormData();
      formData.append('file', e.currentTarget.files[0]);
      dispatch(
        uploadPremiumUsers(formData, (response) => {
          if (response.message == 'Upload Success') {
            setUploadDisabled(false);
            setNotify({
              isOpen: true,
              message: 'File Uploaded Successfully!',
              type: 'success',
            });
          } else if (response.message == 'Invalid Details Found') {
            function getErrorToast(response) {
              let errMsg = '';
              let msg = 'Operation Failed!';
              Object.entries(response).map(([key, value]) => {
                if (Array.isArray(value))
                  errMsg += (errMsg.length > 0 ? '\n' : '') + key + ': ' + value.join(',');
              });
              return msg + '\n' + errMsg;
            }
            setUploadDisabled(false);
            setNotify({
              isOpen: true,
              message: getErrorToast(response),
              type: 'error',
            });
          } else {
            setUploadDisabled(false);
            setNotify({
              isOpen: true,
              message: 'Please try later! Not able to upload file',
              type: 'error',
            });
          }
        })
      );
    } else {
      setUploadDisabled(false);
      setNotify({
        isOpen: true,
        message: 'Please upload a csv file...',
        type: 'error',
      });
    }
  };

  return (
    <div>
      <Formik
        initialValues={state || []}
        onSubmit={(values, { resetForm }) => {
          let data = new FormData();
          values.premiumUsersSheet.forEach((premiumUserSheet, index) => {
            data.append(`premiumUsersSheet-${index}`, values.premiumUsersSheet[index]);
          });
        }}
        enableReinitialize
      >
        {({ handleSubmit, errors, handleChange, values, touched, setFieldValue }) => {
          return (
            <>
              <Container>
                <TopTabs
                  value={tabCount}
                  textColor={'inherit'}
                  onChange={(e, value) => setTabCount(value)}
                  aria-label='tabs'
                >
                  <TopTab label='Upload Premium Users' />
                </TopTabs>
                <div style={{ marginTop: '20px' }}>
                  <Form onSubmit={handleSubmit} autoComplete='off'>
                    <FormControl className={classes.root} style={{ float: 'right' }}>
                      <Controls.Button
                        text={`Download Template`}
                        variant='contained'
                        color='primary'
                        className={classes.btnStyle}
                        onClick={() =>
                          window.open(
                            'https://assets.thecareerlabs.com/elev8/premium_users/Premium_Sheet_Template.csv'
                          )
                        }
                      />
                    </FormControl>
                    <FormControl
                      className={classes.root}
                      style={{ width: '100%', marginTop: '20px' }}
                    >
                      <Button
                        variant='outlined'
                        component='label'
                        disabled={uploadDisabled}
                        color={!uploadDisabled ? 'primary' : 'default'}
                        className={classes.dropzone}
                      >
                        {!uploadDisabled
                          ? 'Click here to upload a CSV file'
                          : 'Uploading a file...'}
                        <input
                          hidden
                          type='file'
                          onChange={(e) => handlePremiumUsersSheetUpload(e, values)}
                          onClick={(e) => (e.currentTarget = null)}
                        />
                      </Button>
                      <p style={{ fontSize: '13px', color: 'gray', marginTop: '5px' }}>
                        (Supported format: CSV file only)
                      </p>
                    </FormControl>
                  </Form>
                </div>
              </Container>
            </>
          );
        }}
      </Formik>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </div>
  );
};

export default PremiumUserLanding;
