import React from "react";
import ReactApexChart from "react-apexcharts";

const StackedChart = ({ series, categories, yaxisTitle }) => {
  return (
    <div>
      <ReactApexChart
        type="bar"
        height={330}
        series={series}
        options={{
          chart: {
            stacked: true,
          },
          xaxis: {
            categories: categories,
          },
          yaxis: {
            title: {
              text: yaxisTitle,
            },
          },
          plotOptions: {
            bar: { columnWidth: "10%" },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            position: "top",
            fontSize: "12px",
            markers: {
              radius: 50,
              offsetX: -5,
            },
            itemMargin: {
              horizontal: 10,
            },
          },
        }}
      />
    </div>
  );
};

export default StackedChart;
