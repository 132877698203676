import axios from "axios";
import { URL } from "../../../Actions/URL";
import { catchError, errorHandler } from "../../../Component/Utils/Helpers";
import { FEEDBACK } from "../Action";

const DEV_LMS = URL;
export const getFeedback = (page, size, topicId) => {
  let accessToken = sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .get(`${URL}/api/v1/lms/adminfeedback/topic/${topicId}`, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          page: page,
          size: size,
          export: false,
        },
      })
      .then((response) => {
        dispatch({
          type: FEEDBACK.getFeedback,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch(errorHandler(FEEDBACK.getFeedback, error, false));
      });
  };
};

export const getOverview = (size, page, videoId) => {
  let accessToken = sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .get(`${DEV_LMS}/api/v1/lms/userrating/${videoId}`, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          size: size,
          page: page,
          export: false,
        },
      })
      .then((response) => {
        dispatch({
          type: FEEDBACK.getOverview,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch(errorHandler(FEEDBACK.getOverview, error, false));
      });
  };
};

export const feedbackExport = (topicId, callback) => {
  let accessToken = sessionStorage.getItem("accessToken");
  return () => {
    axios
      .get(`${URL}/api/v1/lms/adminfeedback/topic/${topicId}`, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: "blob",
        params: {
          export: true,
        },
      })
      .then((response) => {
        callback({ success: true, data: response.data });
      })
      .catch((error) => callback(catchError(error)));
  };
};

export const overviewExport = (videoId, callback) => {
  let accessToken = sessionStorage.getItem("accessToken");
  return () => {
    axios
      .get(`${URL}/api/v1/lms/userrating/${videoId}`, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: "blob",
        params: {
          export: true,
        },
      })
      .then((response) => {
        callback({ success: true, data: response.data });
      })
      .catch((error) => callback(catchError(error)));
  };
};
