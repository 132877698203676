import MaterialTable from 'material-table';
import React, { forwardRef } from 'react';
import Alert from '@mui/material/Alert';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

const tableIcons = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
};

const DataTable = ({
  columns,
  data,
  marginTop,
  overflowWrap,
  sorting,
  maxBodyHeight,
  minBodyHeight,
}) => {
  return (
    <div style={{ marginTop: marginTop }}>
      <MaterialTable
        icons={tableIcons}
        components={{
          Container: (props) => props.children,
        }}
        columns={columns}
        data={data}
        options={{
          search: false,
          showTitle: false,
          sorting: sorting,
          grouping: false,
          draggable: false,
          paging: false,
          maxBodyHeight: maxBodyHeight,
          minBodyHeight: minBodyHeight,
          headerStyle: {
            fontSize: '14px',
            color: '#354354',
            fontWeight: '700',
            borderBottom: '2px solid #828D98',
          },
          rowStyle: {
            overflowWrap: { overflowWrap },
          },
        }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <div
                style={{
                  marginTop: '20%',
                  marginLeft: '19%',
                  width: '60%',
                }}
              >
                <Alert variant='filled' severity='info' sx={{ justifyContent: 'center' }}>
                  No records to display
                </Alert>
              </div>
            ),
          },
        }}
      />
    </div>
  );
};

export default DataTable;
