import React, { useEffect, useState } from "react";
import TableComponent from "../TableComponent/TableComponent";
import { createTheme, Grid, ThemeProvider } from "@material-ui/core";
import {
  addBSchool,
  addBSProgram,
  bsEssayQuestion,
  bsRank,
  bsRound,
  bsAptitudeTest,
} from "../RoutePaths";
import { makeStyles } from "@material-ui/core/styles";
import BackButton from "../../Asset/Images/backbutton.svg";
import { Breadcrumbs } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Typography } from "@material-ui/core";
import { studentPath } from "../RoutePaths";
import { useDispatch, useSelector } from "react-redux";
import {
  clearData,
  getAllAptitudeTest,
  getOneBSchoolProgram,
  listOfBSchoolProgram,
} from "../../Actions/BSchoolAction";
import { isEmptyString } from "../Validation";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
  },
}));
export default function BSProgramIndex(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getAllBSchoolProgramList } = useSelector(
    (state) => state.BSchoolReducer
  );
  const [BSchoolProgramList, setBSchoolProgramList] = useState([]);
  const [search, setSearch] = useState({
    size: 20,
    page: 0,
    keyword: "",
  });
  const col = [
    {
      title: "ID",
      fieldName: "id",
    },
    { title: "Program Name", fieldName: "programName" },
    { title: "Created By", fieldName: "createdBy" },
    { title: "Created Date", fieldName: "createdDate" },
    { title: "Updated By", fieldName: "updatedBy" },
    { title: "Updated Date", fieldName: "updatedDate" },
  ];

  const tableTheme = () => {
    createTheme({
      palette: {
        primary: {
          main: "#007bff",
        },
      },
      overrides: {
        MuiTypography: {
          h6: {
            fontWeight: "bold",
          },
        },
        MuiIconButton: {
          root: {
            "&:hover": {
              backgroundColor: "none",
              borderRadius: 0,
            },
          },
        },
      },
    });
  };

  const handleClickOpen = () => {
    props.history.push({
      pathname: addBSProgram,
      state: { b_schoolDetail: props.location.state.data },
    });
    dispatch(clearData());
  };

  const handleEdit = (data) => {
    props.history.push({ pathname: addBSProgram, state: { data } });
    // dispatch(getOneBSchoolProgram(data.id));
  };

  const handleRoundClick = (data) => {
    props.history.push({ pathname: bsRound, state: { data } });
  };

  const handleEssayQuestion = (data) => {
    props.history.push({ pathname: bsEssayQuestion, state: { data } });
  };

  const handleAptitudeTestClick = (data) => {
    props.history.push({ pathname: bsAptitudeTest, state: data });
  };

  useEffect(() => {
    dispatch(listOfBSchoolProgram(0, 20, "", props.location.state.data.id));
  }, []);

  useEffect(() => {
    if (getAllBSchoolProgramList !== null) {
      setBSchoolProgramList(getAllBSchoolProgramList.content);
    }
  }, [getAllBSchoolProgramList]);
  const paginate = (page, size, keyword) => {
    setSearch({ ...search, page: page, size: size, keyword: keyword });
    if (page !== search.page || size !== search.size) {
      dispatch(
        listOfBSchoolProgram(page, size, keyword, props.location.state.data.id)
      );
    } else if (isEmptyString(keyword)) {
      dispatch(listOfBSchoolProgram(0, 20, "", props.location.state.data.id));
    } else {
    }
  };

  const handleSearch = (e) => {
    dispatch(
      listOfBSchoolProgram(
        search.page,
        search.size,
        search.keyword,
        props.location.state.data.id
      )
    );
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div style={{ display: "flex", flexDirection: "row", margin: "10px" }}>
          <img
            src={BackButton}
            style={{ cursor: "pointer", marginTop: "-10px" }}
            onClick={() => props.history.goBack()}
          />
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Typography
              style={{
                cursor: "pointer",
                fontWeight: "600",
                marginLeft: "10px",
              }}
              onClick={() => props.history.push(studentPath)}
            >
              Back
            </Typography>
          </Breadcrumbs>
        </div>
        <ThemeProvider theme={tableTheme}>
          <div>
            <Grid container>
              <Grid item md={12}>
                <TableComponent
                  cols={col}
                  data={BSchoolProgramList}
                  title={"B - School Program"}
                  add={true}
                  action={true}
                  onAddClick={handleClickOpen}
                  Round={true}
                  onRoundClick={handleRoundClick}
                  AptitudeTest={true}
                  onAptitudeTestClick={handleAptitudeTestClick}
                  EssayQues={true}
                  onEssayQuesClick={handleEssayQuestion}
                  totalCount={
                    getAllBSchoolProgramList &&
                    getAllBSchoolProgramList.totalElements
                  }
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      document.getElementById("search").click();
                    }
                  }}
                  onSearchClick={(e) => handleSearch(e)}
                  pageCount={
                    getAllBSchoolProgramList &&
                    getAllBSchoolProgramList.totalPages
                  }
                  onSearch={paginate}
                  needSearch
                  paginate={paginate}
                  searchPlaceHolder="Program Name"
                  onEdit={true}
                  onEditClick={handleEdit}
                />
              </Grid>
            </Grid>
          </div>
        </ThemeProvider>
      </div>
    </React.Fragment>
  );
}
