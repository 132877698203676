import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import LatexViewer from "../../../../Utils/LatexViewer";
import { Passage as passage } from "../../../../Assets/css/Preview/TestComponent";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@mui/material/Tabs";
import DropDown from "../../../../Utils/PreviewDropDown";
import { Fragment } from "react";
import { useState } from "react";

export default function Binary(props) {
  const {
    choices,
    description,
    isHaveDescription,
    question,
    multiAnswerType,
    multiDescription,
  } = props;
  console.log(choices, "asiffsio", multiDescription);
  const { Question } = passage;
  // const options = [
  //   { title: "Select", value: "product", id: "default", disabled: true },
  //   { title: "1 out of 6", value: "1 out of 6", id: "one" },
  //   { title: "1 out of 3", value: "1 out of 3", id: "two" },
  //   { title: "2 out of 3", value: "2 out of 3", id: "three" },
  //   { title: "5 out of 6", value: "5 out of 6", id: "four" },
  // ];

  // const math = (ii) => {
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(ii, "text/html").body.textContent;
  //   return doc;
  // };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const [tabValue, setValue] = useState(0);
  const tabValues = [0, 1, 2];
  return (
    <Grid container id="test-container">
      <Grid item md={6} id="inside-test-container">
        <Box display={"flex"} className={"overflow-scroll"} height={"inherit"}>
          <Box flex={1} className={"question-left-container"}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabValue}
                onChange={handleChangeTab}
                aria-label="lab API tabs example"
                sx={{
                  "& .Mui-selected": {
                    backgroundColor: "#1976d2",
                    color: "#fff !important",
                  },
                }}
              >
                {tabValues.map((value) => (
                  <Tab key={value} label={`Item ${value + 1}`} value={value} />
                ))}
              </Tabs>
            </Box>

            {tabValues.map((index) => {
              console.log(index, "pokjjj");
              return (
                <Fragment key={index}>
                  <div hidden={tabValue !== index} style={{ padding: "15px" }}>
                    <TabPanel
                      value={tabValue}
                      index={index}
                      className="tabs-question-tab"
                    >
                      <Box fontSize={16} color={"#052A4E"} lineHeight={"30px"}>
                        <LatexViewer
                          math={multiDescription[tabValue]["description"]}
                        />
                      </Box>
                    </TabPanel>
                  </div>
                </Fragment>
              );
            })}
          </Box>
          <Box>
            <Divider variant="middle" orientation={"vertical"} />
          </Box>
        </Box>
      </Grid>
      <Grid item md={6} id="inside-test-container-right">
        <Box
          minHeight={400}
          className={"overflow-scroll"}
          id="inside-test-container"
        >
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">
              <Question style={{ marginBottom: "15px" }}>
                <LatexViewer math={question} />
              </Question>
              <Box display={"flex"}>
                <Question>
                  <span>Yes &nbsp;&nbsp;&nbsp;&nbsp;</span>
                </Question>
                <Question>
                  <span>No</span>
                </Question>
              </Box>
            </FormLabel>
            {choices.map((item) => {
              console.log(item.correctChoice, "pp");
              return (
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item>
                    <RadioGroup
                      row
                      style={{ alignItems: "center" }}
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="row-radio-buttons-group"
                      value={
                        item.firstSelected === true
                          ? true
                          : item.secondSelected === true
                          ? false
                          : ""
                      }
                      //   onChange={handleChange}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        style={{ marginBottom: "0px" }}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        style={{ marginRight: "0px", marginBottom: "0px" }}
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid
                    item
                    xs
                    style={{ alignSelf: "center", fontWeight: 600 }}
                  >
                    <LatexViewer math={item.text} />
                  </Grid>
                </Grid>
              );
            })}
          </FormControl>
          {/* )}
          {multiAnswerType === "MULTITWO_PART" && (
            <table className="two-part-table">
              <tr>
                <th>Liters in fuel in 1 h</th>
                <th>Liters of fuel in 60 km</th>
                <th></th>
              </tr>
              <tr>
                <td>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    {choices.map((item) => {
                      return (
                        <Grid item>
                          <RadioGroup
                            row
                            style={{ justifyContent: "center" }}
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="row-radio-buttons-group"
                            value={item.correctChoice}
                            // onChange={handleChange}
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              style={{ margin: "0px" }}
                            />
                          </RadioGroup>
                        </Grid>
                      );
                    })}
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    {choices.map((item) => {
                      return (
                        <Grid item>
                          <RadioGroup
                            row
                            style={{ justifyContent: "center" }}
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="row-radio-buttons-group"
                            value={item.wrongChoice}
                            // onChange={handleChange}
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              style={{ margin: "0px" }}
                            />
                          </RadioGroup>
                        </Grid>
                      );
                    })}
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    {choices.map((choice, index) => (
                      <Typography
                        variant="h4"
                        color="textPrimary"
                        style={{ margin: "10px" }}
                      >
                        {choice.text ? (
                          <LatexViewer math={choice.text} />
                        ) : (
                          <img src={choice.choiceImage} alt="" />
                        )}
                      </Typography>
                    ))}
                  </div>
                </td>
              </tr>
            </table>
          )}
          {multiAnswerType === "MULTIDROP_DOWN" && (
            <>
              <Question style={{ marginBottom: "15px" }}>
                <LatexViewer math={question} />
              </Question>

              {choices.map((item) => {
                return (
                  <Grid container>
                    <span
                      style={{
                        wordBreak: "break-word",
                        width: "100%",
                        marginBottom: "1.2rem",
                      }}
                    >
                      <span>
                        {math(item.text)}
                        <DropDown
                          name="Map Error Genre"
                          items={options}
                          value={item.dropDown || "default"}
                        />
                      </span>
                    </span>
                  </Grid>
                );
              })}
            </>
          )} */}
        </Box>
      </Grid>
    </Grid>
  );
}
