/**
 * (c) CareerLabs. All rights reserved.
 **/
import React from "react";
import { Grid, Box, Divider } from "@material-ui/core";
import {
  Passage as passage,
  TextBox,
  TextUnitField,
  TextWrapper,
  TextBoxValueShow,
  DividerLine,
  TextBoxFraction,
  Div,
  NoMarginTextWrapper,
} from "../../../../Assets/css/Preview/TestComponent";
import SingleSelect from "./SingleSelect";
import Bundle from "./Bundle";
import Latex from "../../../../Utils/LatexViewer";

const Passage = ({
  para,
  question,
  choices,
  bundle,
  selectedChoice,
  subjective,
  fraction,
  bundleLength,
  answer,
  answer1,
  optionalType,
  unit,
  description,
  isHaveDescription,
  imgUrl,
  isMulti,
}) => {
  const { Para } = passage;
  const { Question } = passage;
  console.log(answer, "sneh");
  console.log(answer1, "snehsdfd");
  console.log(unit, "sdsd");
  const style = {
    show: {
      display: "block",
    },
    hide: {
      display: "none",
    },
  };
  const { hide, show } = style;
  return (
    <Grid container id="test-container">
      <Grid
        item
        md={
          (subjective &&
            isHaveDescription === false &&
            optionalType === "NUMERICAL_ENTRY") ||
          (bundle && isHaveDescription === false) ||
          (fraction && isHaveDescription === false)
            ? 12
            : 6
        }
        id="inside-test-container"
      >
        <Box display={"flex"} className={"overflow-scroll"} height={"inherit"}>
          <Box flex={1} className={"question-left-container"}>
            {bundle && isHaveDescription === false && (
              <>
                <Question style={{ marginBottom: "1em" }}>
                  <Latex math={para} />
                </Question>
                <Bundle
                  bundleLength={bundleLength}
                  choices={choices}
                  selectedChoice={selectedChoice}
                  question={para}
                  isHaveDescription={isHaveDescription}
                />
              </>
            )}
            <Box fontSize={16} color={"#052A4E"} lineHeight={"30px"}>
              <Latex math={description} />
            </Box>
            {subjective && isHaveDescription === false && (
              <Question>
                <Latex math={question} />
              </Question>
            )}
            {optionalType === "NUMERICAL_ENTRY" && isHaveDescription === false && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "2em",
                }}
              >
                <TextBoxFraction value={answer} type={"text"} disabled />
                <TextUnitField disabled>{unit}</TextUnitField>
              </div>
            )}

            {fraction && isHaveDescription === false && (
              <>
                <Question>
                  <Latex math={question} />
                </Question>
                <div style={{ display: "flex", marginTop: "2em" }}>
                  <div>
                    <TextBoxValueShow value={answer} type={"number"} disabled />
                    <DividerLine />
                    <TextBoxValueShow
                      value={answer1}
                      type={"number"}
                      disabled
                    />
                  </div>
                  <TextUnitField disabled>{unit}</TextUnitField>
                </div>
              </>
            )}

            <Box>
              {imgUrl && (
                <img src={imgUrl} alt={""} width={"100%"} height={"100%"} />
              )}
            </Box>
          </Box>
          {(subjective &&
            isHaveDescription === false &&
            optionalType === "NUMERICAL_ENTRY") ||
          (bundle && isHaveDescription === false) ||
          (fraction && isHaveDescription === false) ? (
            ""
          ) : (
            <Box>
              <Divider variant="middle" orientation={"vertical"} />
            </Box>
          )}
        </Box>
      </Grid>

      <Grid
        item
        md={6}
        id="inside-test-container-right"
        style={
          (subjective &&
            isHaveDescription === false &&
            optionalType === "NUMERICAL_ENTRY") ||
          (bundle && isHaveDescription === false) ||
          (fraction && isHaveDescription === false)
            ? hide
            : show
        }
      >
        <Box
          minHeight={400}
          className={"overflow-scroll"}
          id="inside-test-container"
        >
          {bundle ? (
            <Bundle
              bundleLength={bundleLength}
              choices={choices}
              selectedChoice={selectedChoice}
              question={para}
              isHaveDescription={isHaveDescription}
            />
          ) : subjective ? (
            optionalType === "NUMERICAL_ENTRY" ? (
              <React.Fragment>
                <NoMarginTextWrapper>
                  {subjective && isHaveDescription === true && (
                    <>
                      <Question>
                        <Latex math={question} />
                      </Question>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "2rem",
                        }}
                      >
                        <TextBoxFraction
                          value={answer}
                          type={"text"}
                          disabled
                        />
                        <TextUnitField disabled>{unit}</TextUnitField>
                      </div>
                    </>
                  )}
                </NoMarginTextWrapper>
              </React.Fragment>
            ) : (
              <>
                <Box>
                  <Div>
                    {isHaveDescription === true && (
                      <Question style={{ marginBottom: "1em" }}>
                        <Latex math={question} />
                      </Question>
                    )}

                    <Box>
                      {imgUrl && (
                        <img
                          src={imgUrl}
                          alt={""}
                          width={"100%"}
                          height={"100%"}
                        />
                      )}
                    </Box>
                    {/* <OptionContainer>{renderChoices()}</OptionContainer> */}
                  </Div>
                </Box>
                {optionalType === null && (
                  <TextBox type={"text"} value={answer} disabled />
                )}
              </>
            )
          ) : fraction ? (
            <React.Fragment>
              <NoMarginTextWrapper>
                {fraction && isHaveDescription === true && (
                  <>
                    <Question>
                      <Latex math={question} />
                    </Question>
                    <div style={{ display: "flex", marginTop: "2rem" }}>
                      <div>
                        <TextBoxValueShow
                          value={answer}
                          type={"number"}
                          disabled
                        />
                        <DividerLine />
                        <TextBoxValueShow
                          value={answer1}
                          type={"number"}
                          disabled
                        />
                      </div>
                      <TextUnitField disabled>{unit}</TextUnitField>
                    </div>
                  </>
                )}
              </NoMarginTextWrapper>
            </React.Fragment>
          ) : (
            <SingleSelect
              question={question}
              options={choices}
              selectedChoice={selectedChoice}
              isMulti={isMulti}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Passage;
