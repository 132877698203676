import { Button, IconButton } from "@material-ui/core";
import React from "react";
import "../../../../../Assets/css/Preview/Preview.css";
import {
  DividerBookmark,
  SubHeading,
  TopGridSubPara,
  PlayerBox,
  Title,
  ToolTip,
  CommentIcon,
} from "../../../../../Assets/css/Preview/TaskDetailsStyledComponent";
import Comment from "../../../../../Assets/icons/comment.svg";

import ClockImage from "../../../../../Assets/images/ClockImage.png";
import { RenderBookMark } from "../../../../../Utils/Bookmark";
import VideoPlayer from "../../../../../Utils/VideoPlayer";
import PostAddRoundedIcon from "@material-ui/icons/PostAddRounded";

function RightCard(props) {
  const videos = [...props.content.contentVideo];
  const videosort = videos.sort((a, b) => {
    let orderNo1 = a.orderNo;
    let orderNo2 = b.orderNo;
    if (orderNo1 === null) {
      return 1;
    }
    if (orderNo2 === null) {
      return -1;
    }
    return orderNo1 < orderNo2 ? -1 : 1;
  });

  return (
    <div className={"main-card-align left-container-task-view"}>
      <div className={"main_card_pad"}>
        <DividerBookmark>
          <div style={{ padding: "4px 20px 0 0" }}>
            <RenderBookMark bookMarked={props.isBookmarked} />
          </div>
        </DividerBookmark>
        <ToolTip title={"All Notes"} placement="left" arrow>
          <CommentIcon>
            <IconButton
            // onClick={props.handleNotesIconClick}
            >
              <img src={Comment} />
            </IconButton>
          </CommentIcon>
        </ToolTip>
        <SubHeading>{props.content && props.content.title}</SubHeading>
        <div className={"left-side-card-text"}>
          <img src={ClockImage} alt="clockImage" style={{ width: 17 }} />
          &nbsp;&nbsp;&nbsp;
          {`${
            props.content && props.content.duration ? props.content.duration : 0
          } Mins`}
        </div>

        <TopGridSubPara>
          {videosort.map((item) => {
            return (
              <PlayerBox>
                <Title>{item.title}</Title>
                <div className="video-box">
                  <div className="icon-box-task-details">
                    <IconButton>
                      <PostAddRoundedIcon
                        color="primary"
                        style={{ fontSize: "xx-large" }}
                      />
                    </IconButton>
                  </div>
                  <VideoPlayer
                    playBackInfo={item.videoPlaybackInfo}
                    otp={item.videoOtp}
                    getVideoObj={props.getVideoObj}
                    startTime={item.videoMillSec}
                    playCount={item.videoPlayedCount}
                    videoId={item.videoId}
                  />
                </div>
              </PlayerBox>
            );
          })}
          <p
            className={"copy__allowed"}
            dangerouslySetInnerHTML={{
              __html: props.content && props.content.content,
            }}
          />
        </TopGridSubPara>
      </div>

      <div className={"bottom-card-align"}>
        <Button variant="outlined" color="primary" className={"footer-align"}>
          {"Mark as Read"}
        </Button>
      </div>
    </div>
  );
}

export default RightCard;
