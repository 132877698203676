import axios from "axios";
import { URL } from "./URL";
import { UPLOAD_PGA_REPORT } from "../Redux/Action";
import { errorHandler } from "../Component/Utils/Helpers";

const BASE_URL = `${URL}/api/v1/files`;

export const pgaReportUpload = (studentId, productId, data, stageId) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    dispatch({ type: UPLOAD_PGA_REPORT.loader });
    axios
      .post(
        `${BASE_URL}/students/${studentId}/products/${productId}/stage/${stageId}/upload/pgaReport`,
        data,
        {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((result) => {
        dispatch({
          type: UPLOAD_PGA_REPORT.pgaReportUpload,
          payload: { success: true, data: result.data },
          loading: false,
        });
      })
      .catch((error) => {
        dispatch(errorHandler(UPLOAD_PGA_REPORT.pgaReportUpload, error, false));
      });
  };
};

export const getStudentPgaList = (studentId, productId, stageId) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    dispatch({ type: UPLOAD_PGA_REPORT.loader });
    axios
      .get(
        `${BASE_URL}/students/${studentId}/products/${productId}/stage/${stageId}/pgaReport`,
        {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((result) => {
        dispatch({
          type: UPLOAD_PGA_REPORT.getStudentPgaList,
          payload: result.data,
          loading: false,
        });
      })
      .catch((error) => {
        dispatch(
          errorHandler(UPLOAD_PGA_REPORT.getStudentPgaList, error, false)
        );
      });
  };
};

export const pgaDownload = (studentId, productId, fileId) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    dispatch({ type: UPLOAD_PGA_REPORT.loader });
    axios
      .get(
        `${BASE_URL}/download/students/${studentId}/${fileId}/products/${productId}/pgaReport`,
        {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: "blob",
        }
      )
      .then((result) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "PGA Report.pdf");
        document.body.appendChild(link);
        link.click();
        link.remove();
        dispatch({
          type: UPLOAD_PGA_REPORT.pgaDownload,
          payload: { success: true, data: result.data },
          loading: false,
        });
      })
      .catch((error) => {
        dispatch(errorHandler(UPLOAD_PGA_REPORT.pgaDownload, error, false));
      });
  };
};
