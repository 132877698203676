import React, { useEffect, useState, useRef } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { useDispatch, useSelector } from 'react-redux';
import FilterIcon from '@material-ui/icons/Tune';
import SortIcon from '@material-ui/icons/CompareArrows';
import SearchIcon from '@material-ui/icons/SearchSharp';
import ExportIcon from '@material-ui/icons/GetApp';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import ClearIcon from '@material-ui/icons/Clear';
import Loader from '../../Utils/controls/Loader';
import { InputAdornment, makeStyles } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Notification from '../../Utils/Notification';
import { Check, ChevronLeft, ChevronRight, Edit, Search } from '@material-ui/icons';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import { Add } from '@mui/icons-material';
import Controls from '../../Utils/controls/Controls';
import 'date-fns';
import { textToDownloadFile } from '../../Utils/Helpers';
import {
  getJobDescriptionDetails,
  jdSheetDownload,
  setFieldValue,
} from '../../../Actions/WallActions';

const useStyles = makeStyles((theme) => ({
  searchInput: {
    width: '50%',
    marginLeft: 30,
  },
}));

function JobDescription() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [tableData, setTableData] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const [pageSize, setPageSize] = useState(30);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState();
  const [page, setPage] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const { loading, getJobDescription } = useSelector((state) => state.jobDescriptionReducer);

  useEffect(() => {
    dispatch(getJobDescriptionDetails());
  }, []);

  useEffect(() => {
    if (getJobDescription) {
      if (getJobDescription.success) {
        setTableData(getJobDescription?.data.content);
        setFilteredData(getJobDescription?.data.content);
        setTotalPages(getJobDescription?.data.totalPages);
        setTotalElements(getJobDescription?.data.totalElements);
        dispatch(setFieldValue('getJobDescription', null));
      } else {
        setTableData([]);
        setFilteredData([]);
        dispatch(setFieldValue('getJobDescription', null));
      }
    }
  }, [getJobDescription]);

  const handleSearch = (text) => {
    setSearchValue(text);
    setPage(0);
    dispatch(getJobDescriptionDetails(0, text));
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage - 1);
    dispatch(getJobDescriptionDetails(newPage - 1, searchValue));
  };

  // FIELD names in the columns variable must be same as the KEY (in the API response) of the data variable.
  let columns = [
    {
      title: 'ID',
      field: 'pdId',
      hidden: true,
    },
    {
      title: 'Timestamp',
      field: 'timestamp',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      type: 'date',
      dateSetting: {
        format: 'dd-MM-yyyy',
        locale: 'en-GB',
      },
    },
    {
      title: 'Email Address',
      field: 'emailAddress',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Company name',
      field: 'eventTitle',
      cellStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      render: (rowData) => <p style={{ width: '150px' }}>{rowData.eventTitle}</p>,
    },
    {
      title: 'About the Company',
      field: 'aboutTheCompany',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div style={{ width: '350px' }}>{rowData.aboutTheCompany}</div>,
    },
    {
      title: 'No. of Vacancies',
      field: 'noOfVacancies',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Target Degree',
      field: 'targetDegree',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Targeted Batch',
      field: 'targetedBatch',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Branch',
      field: 'branchName',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Salary Bi-furcation (Fixed Component - Lower Range)',
      field: 'salaryBiFurcation',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData) => <div style={{ width: '150px' }}>{rowData.salaryBiFurcation}</div>,
    },
    {
      title: 'Designation',
      field: 'designation',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Roles and Responsibilities',
      field: 'rolesAndResponsibilities',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div style={{ width: '350px' }}>{rowData.rolesAndResponsibilities}</div>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Interview',
      field: 'interviewType',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div style={{ width: '100px' }}>{rowData.interviewType}</div>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Joining (Virtual/ Office)',
      field: 'joiningType',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Joining Period',
      field: 'joiningPeriod',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Selection Process (Interview Rounds)',
      field: 'selectionProcess',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) => <div style={{ width: '200px' }}>{rowData.selectionProcess}</div>,
    },
    {
      title: 'Diversity hiring (gender) preference',
      field: 'genderPreference',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div>{rowData.genderPreference}</div>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Academic % Cut-off (if any)',
      field: 'academicCutOff',
      editable: false,
      cellStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div style={{ width: '110px' }}>{rowData.academicCutOff}</div>,
    },
    {
      title: 'Bond to be Signed',
      field: 'bondSigned',
      cellStyle: {
        textAlign: 'center',
      },
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div style={{ width: '100px' }}>{rowData.bondSigned}</div>,
    },
    {
      title: 'Bond Details (if applicable)',
      field: 'bondDetails',
      editable: false,
      cellStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div style={{ width: '100px' }}>{rowData.bondDetails}</div>,
    },
    {
      title: 'HR Contact Person Name',
      field: 'hrContactPersonName',
      editable: false,
      cellStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div style={{ width: '120px' }}>{rowData.hrContactPersonName}</div>,
    },
    {
      title: 'HR Mobile Number',
      field: 'hrPhoneNumber',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'HR Email ID',
      field: 'hrEmailId',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Secondary Point of Contact Name',
      field: 'secondaryContactName',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData) => <div style={{ width: '120px' }}>{rowData.secondaryContactName}</div>,
    },
    {
      title: 'Secondary Point of Contact Mobile',
      field: 'secondaryPhoneNumber',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData) => <div style={{ width: '120px' }}>{rowData.secondaryPhoneNumber}</div>,
    },
    {
      title: 'Secondary Point of Contact Email',
      field: 'secondaryEmailId',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Max. Time to Complete Recruitment Process',
      field: 'maxTimeToRecruitmentProcess',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData) => (
        <div style={{ width: '180px' }}>{rowData.maxTimeToRecruitmentProcess}</div>
      ),
    },
    {
      title: 'Ask a Question/ Additional Details',
      field: 'additionalDetails',
      editable: false,
      cellStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div style={{ width: '350px' }}>{rowData.additionalDetails}</div>,
    },
    {
      title: 'Are you okay for a commercial model?',
      field: 'commercialModel',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData) => <div style={{ width: '150px' }}>{rowData.commercialModel}</div>,
    },
    {
      title: 'Shift Details',
      field: 'shiftDetails',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Job Role',
      field: 'jobRoleType',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Staffing on 3rd Party Payroll',
      field: 'staffingOnThirdPartyPayroll',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData) => (
        <div style={{ width: '100px' }}>{rowData.staffingOnThirdPartyPayroll}</div>
      ),
    },
    {
      title: 'Salary Bi-furcation (Variable Component)',
      field: 'salaryBiFurcationVariableComponent',
      editable: false,
      cellStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      render: (rowData) => (
        <div style={{ width: '100px' }}>{rowData.salaryBiFurcationVariableComponent}</div>
      ),
    },
    {
      title: 'CTC (In-hand Component)',
      field: 'ctc',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Role: Technical /Non-Technical',
      field: 'roleType',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData) => <div style={{ width: '100px' }}>{rowData.roleType}</div>,
    },
    {
      title: 'Job Location',
      field: 'jobLocation',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Commercial Model Rate Agreed Upon',
      field: 'commercialModelRateAgreeUpon',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData) => (
        <div style={{ width: '150px' }}>{rowData.commercialModelRateAgreeUpon}</div>
      ),
    },
    {
      title: 'Salary Bi-furcation (Fixed Component - Upper Range)',
      field: 'salaryBiFurcationFixedComponent',
      editable: false,
      cellStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      render: (rowData) => (
        <div style={{ width: '150px' }}>{rowData.salaryBiFurcationFixedComponent}</div>
      ),
    },
    {
      title: 'Do you have a vendor empanelment process?',
      field: 'vendorEmpanelmentProcess',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData) => <div style={{ width: '100px' }}>{rowData.vendorEmpanelmentProcess}</div>,
    },
    {
      title: 'Can we get empanelled as a new vendor?',
      field: 'empanelledAsNewVendo',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData) => <div style={{ width: '100px' }}>{rowData.empanelledAsNewVendo}</div>,
    },
    {
      title: 'Can we work with an existing vendor?',
      field: 'canWeWorkWithAnExistingVendor',
      filtering: false,
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData) => (
        <div style={{ width: '100px' }}>{rowData.canWeWorkWithAnExistingVendor}</div>
      ),
    },
  ];

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <MaterialTable
            columns={columns}
            data={filteredData}
            isLoading={Boolean(loading)}
            localization={{
              header: {
                actions: '',
              },
            }}
            components={{
              Toolbar: (props) => (
                <div
                  style={{
                    display: 'flex',
                    marginTop: '1rem',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderBottom: '2px solid #0094b1',
                    borderTop: '2px solid #007d93',
                    borderRadius: '4px',
                  }}
                >
                  <>
                    <div style={{ flexGrow: 2, width: '70%' }}>
                      <Controls.RoundedInput
                        className={classes.searchInput}
                        placeholder='Search by Company Name...'
                        helperText={'Press Enter key to search after typing'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Search />
                            </InputAdornment>
                          ),
                        }}
                        defaultValue={searchValue} // search text was stay remains even the material-table is re-rendered.
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            handleSearch(e.target.value);
                          }
                        }}
                      />
                    </div>
                    <div
                      style={{
                        flexGrow: 1,
                        padding: '2rem',
                        width: '35%',
                      }}
                    >
                      <MTableToolbar {...props} />
                    </div>
                  </>
                </div>
              ),
            }}
            actions={[
              {
                icon: () => <ExportIcon style={{ color: 'green' }} />,
                tooltip: 'Master Sheet Download',
                onClick: (e, tableData) => {
                  let filteredIds = tableData.map((student) => student.pdId);
                  let obj = { eventId: filteredIds };
                  dispatch(
                    jdSheetDownload(obj, (response) => {
                      if (response.status === 200) {
                        textToDownloadFile(response.data, 'JD Master Sheet', 'csv');
                      } else {
                        setNotify({
                          isOpen: true,
                          message: response.message,
                          type: 'error',
                        });
                      }
                    })
                  );
                },
              },
            ]}
            options={{
              sorting: false,
              search: false,
              searchFieldAlignment: 'right',
              searchFieldVariant: 'outlined',
              filtering: false,
              paging: false,
              pageSize: pageSize, // make initial page size: ;
              emptyRowsWhenPaging: false, // To avoid of having empty rows
              exportButton: false,
              exportAllData: false,
              // exportFileName: getClsaResult?.data?.clsaTitle,
              addRowPosition: 'first',
              actionsColumnIndex: -1,
              selection: true,
              showSelectAllCheckbox: true,
              showTextRowsSelected: false,
              grouping: false,
              columnsButton: true,
              rowStyle: (tableData, index) => (index % 2 === 0 ? { background: '#f5f5f5' } : null),
              headerStyle: { background: 'aliceblue', color: '#000', textAlign: 'center' },
            }}
            title={''}
            icons={{
              Add: Add,
              Edit: Edit,
              Check: Check,
              FirstPage: FirstPage,
              NextPage: ChevronRight,
              PreviousPage: ChevronLeft,
              LastPage: LastPage,
              Filter: () => <FilterIcon style={{ color: '#807f7f' }} />,
              SortArrow: () => (
                <SortIcon
                  fontSize='small'
                  style={{
                    color: '#c6c6c6',
                    transform: 'rotate(-90deg)',
                  }}
                />
              ),
              Clear: () => <ClearIcon />,
              Export: () => <ExportIcon style={{ color: 'green' }} />,
              ResetSearch: () => <ClearIcon />,
              Search: () => <SearchIcon />,
              ViewColumn: () => <ViewColumnIcon />,
            }}
          />

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '30px',
            }}
          >
            <Pagination
              page={page + 1}
              count={totalPages}
              onChange={handlePageChange}
              variant='outlined'
              color='primary'
              size='large'
            />
          </div>
        </div>
      )}
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}

export default JobDescription;
