import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import MySnackBar from "../MySnackBar";
import { GSContainer, GSTitle } from "../../Asset/StyledComponents/Styles";
import { makeStyles } from "@material-ui/core/styles";
import BackButton from "../../Asset/Images/backbutton.svg";
import { AppBar, Breadcrumbs, colors } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Typography } from "@material-ui/core";
import { Grid, TextField } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {
  getCountryLists,
  getGradSclRegionList,
} from "../../Actions/GradSchoolAction";
import {
  createBSchoolList,
  getBSchoolSubRegion,
  getBSclRegionList,
  getBSclCountryLists,
} from "../../Actions/BSchoolAction";
import { useDispatch, useSelector } from "react-redux";
import {
  isEmptyString,
  isOnlyNumber,
  isStringOnly,
  urlPatternValidation,
  isEmailSpecialChar,
} from "../Validation";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
  },
  btnStyles: {
    paddingLeft: 30,
    paddingRight: 30,
  },
}));
export default function AddBSchool(props) {
  const {
    getAllBSchoolList,
    AddedBSchool,
    getBSchoolSubRegionList,
    getBSchoolRegionList,
    getBSchoolCountryLists,
  } = useSelector((state) => state.BSchoolReducer);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [bSchoolData, setBSchoolData] = useState({
    id: "",
    name: null,
    shortName: null,
    state: null,
    location: null,
    collegeLink: null,
    imageLogoLink: null,
    imageUrl: null,
    collegeEmailId: null,
    region: null,
    subRegion: null,
    country: null,
    snack: {
      open: false,
      message: "",
      color: "",
    },
  });

  const textField = [
    { name: "shortName", label: "Short Name", value: bSchoolData.shortName },
    { name: "state", label: "State", value: bSchoolData.state },
    { name: "location", label: "Location", value: bSchoolData.location },
    {
      name: "collegeLink",
      label: "College Link",
      value: bSchoolData.collegeLink,
    },
    {
      name: "imageLogoLink",
      label: "Image Logo Link",
      value: bSchoolData.imageLogoLink,
    },
    { name: "imageUrl", label: "Image URL", value: bSchoolData.imageUrl },
    {
      name: "collegeEmailId",
      label: "College Email ID",
      value: bSchoolData.collegeEmailId,
      type: "email",
    },
  ];

  //Validation//
  const [nameErr, setNameErr] = useState("");
  const [locationErr, setLocationErr] = useState("");
  const [regionErr, setRegionErr] = useState("");
  const [countryErr, setCountryErr] = useState("");
  const [regionList, setRegionList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [imageUrlErr, setImageUrlErr] = useState("");
  const [imageLogoLinkErr, setImageLogoLinkErr] = useState("");
  const [subRegion, setSubRegion] = useState([]);
  const [collegeMailIdErr, setCollegeMailIdErr] = useState("");
  const [shortName, setShortName] = useState("");
  const [state, setState] = useState("");
  const [location, setLocation] = useState("");
  const [collegeLinkErr, setCollegeLinkErr] = useState("");
  const [subRegionErr, setSubRegionErr] = useState("");

  const onChange = (e) => {
    const { name, value } = e.target;
    setBSchoolData({
      ...bSchoolData,
      [name]: value,
    });
    if (name === "shortName") {
      if (value) {
        isEmptyString(value)
          ? setShortName("Please fill the Short Name")
          : setShortName("");
      }
    }
    if (name === "state") {
      if (value) {
        isEmptyString(value) ? setState("Please fill the State") : setState("");
      }
    }
    if (name === "location") {
      if (value) {
        isEmptyString(value)
          ? setLocation("Please fill the Location")
          : setLocation("");
      }
    }
    if (name === "collegeLink") {
      isEmptyString(value)
        ? setCollegeLinkErr("Please fill the College Link")
        : !urlPatternValidation(value)
        ? setCollegeLinkErr("Please fill the  valid Link")
        : setCollegeLinkErr("");
    }
    if (name === "imageLogoLink") {
      if (value) {
        !urlPatternValidation(value)
          ? setImageLogoLinkErr("Please fill the valid Image Logo Link")
          : setImageLogoLinkErr("");
      } else {
        setImageLogoLinkErr("");
      }
    }
    if (name === "imageUrl") {
      if (value) {
        !urlPatternValidation(value)
          ? setImageUrlErr("Please fill the valid Image URL")
          : setImageUrlErr("");
      } else {
        setImageUrlErr("");
      }
    }
    if (name === "collegeEmailId") {
      if (value) {
        !isEmailSpecialChar(value)
          ? setCollegeMailIdErr("Please fill the valid College EMail Id")
          : setCollegeMailIdErr("");
      } else {
        setCollegeMailIdErr("");
      }
    }
  };

  const nameValue = [{ name: "India" }, { name: "Korea" }];

  const filter = createFilterOptions();

  const handleSave = () => {
    const {
      country,
      region,
      subRegion,
      id,
      shortName,
      state,
      name,
      imageUrl,
      imageLogoLink,
      collegeLink,
      collegeEmailId,
      location,
    } = bSchoolData;
    isEmptyString(name) ? setNameErr("Please fill the Name") : setNameErr("");
    isEmptyString(shortName)
      ? setShortName("Please fill the Short Name")
      : setShortName("");
    isEmptyString(state) ? setState("Please fill the State") : setState("");
    isEmptyString(location)
      ? setLocation("Please fill the Location")
      : setLocation("");
    isEmptyString(collegeLink)
      ? setCollegeLinkErr("Please fill the College Link")
      : !urlPatternValidation(collegeLink)
      ? setCollegeLinkErr("Please fill the valid Link")
      : setCollegeLinkErr("");
    isEmptyString(subRegion)
      ? setSubRegionErr("Please select the Sub Region")
      : setSubRegionErr("");
    isEmptyString(country)
      ? setCountryErr("Please select the Country")
      : setCountryErr("");
    isEmptyString(region)
      ? setRegionErr("Please select the Region")
      : setRegionErr("");

    if (collegeEmailId) {
      !isEmailSpecialChar(collegeEmailId)
        ? setCollegeMailIdErr("Please fill the College Email Id")
        : setCollegeMailIdErr("");
    }
    if (imageUrl) {
      !urlPatternValidation(imageUrl)
        ? setImageUrlErr("Please fill the valid Link")
        : setImageUrlErr("");
    }

    if (imageLogoLink) {
      !urlPatternValidation(imageLogoLink)
        ? setImageLogoLinkErr("Please fill the valid Link")
        : setImageLogoLinkErr("");
    }

    if (
      !isEmptyString(name) &&
      !isEmptyString(shortName) &&
      !isEmptyString(state) &&
      !isEmptyString(location) &&
      !isEmptyString(collegeLink) &&
      isEmptyString(collegeLinkErr) &&
      !isEmptyString(subRegion) &&
      !isEmptyString(country) &&
      !isEmptyString(region) &&
      isEmptyString(imageLogoLinkErr) &&
      isEmptyString(imageUrlErr) &&
      isEmptyString(collegeMailIdErr)
    ) {
      let objAdd = {
        name: name,
        shortName: bSchoolData.shortName,
        state: bSchoolData.state,
        collegeLink: bSchoolData.collegeLink,
        imageUrl: imageUrl === null ? "" : imageUrl,
        imageLogoUrl: bSchoolData.imageLogoLink,
        collegeEmailId: bSchoolData.collegeEmailId,
        region: region?.name,
        subRegion: bSchoolData.subRegion?.name,
        country: country?.name,
        location: location,
      };
      let objEdit = {
        id: id,
        name: name,
        shortName: bSchoolData.shortName,
        state: bSchoolData.state,
        collegeLink: bSchoolData.collegeLink,
        imageUrl: imageUrl === null ? "" : imageUrl,
        imageLogoUrl: bSchoolData.imageLogoLink,
        collegeEmailId: bSchoolData.collegeEmailId,
        region: region?.name ? region?.name : region,
        subRegion: bSchoolData.subRegion?.name
          ? bSchoolData.subRegion?.name
          : bSchoolData.subRegion,
        country: country?.name ? country.name : country,
        location: location,
      };

      if (bSchoolData.id === "") {
        dispatch(
          createBSchoolList(objAdd, (res) => {
            console.log(res);
            if (res.message === "Saved Successfully") {
              setBSchoolData({
                id: "",
                name: "",
                shortName: "",
                state: "",
                location: "",
                collegeLink: "",
                imageLogoLink: "",
                imageUrl: "",
                collegeEmailId: "",
                region: "",
                subRegion: "",
                country: "",
                snack: {
                  open: true,
                  message: res.message,
                  color: "success",
                },
              });
            } else {
              setBSchoolData({
                ...bSchoolData,
                snack: {
                  open: true,
                  message: res.data.message,
                  color: "error",
                },
              });
            }
          })
        );
      } else {
        dispatch(
          createBSchoolList(objEdit, (res) => {
            if (res.success) {
              setBSchoolData({
                ...bSchoolData,
                snack: {
                  open: true,
                  message: res.message,
                  color: "success",
                },
              });
            } else {
              setBSchoolData({
                ...bSchoolData,
                snack: {
                  open: true,
                  message: res.data.message,
                  color: "error",
                },
              });
            }
          })
        );
      }
    }
  };

  useEffect(() => {
    if (props.location.state?.data.id.length > 0) {
      const {
        country,
        id,
        location,
        region,
        shortName,
        state,
        name,
        collegeEmailId,
        collegeLink,
        imageLogoUrl,
        imageUrl,
        logoUrl,
        subRegion,
        subRegionId,
        regionId,
      } = props.location?.state?.data;
      setBSchoolData({
        ...bSchoolData,
        id: id,
        country: country,
        region: {
          name: region ? region : "",
        },
        name: name,
        state: state,
        collegeLink: collegeLink,
        collegeEmailId: collegeEmailId,
        imageLogoLink: imageLogoUrl,
        shortName: shortName,
        location: location,
        imageUrl: imageUrl,
        logoUrl: logoUrl,
        subRegion: subRegion,
      });
      console.log(props.location.state.data);
      dispatch(getBSchoolSubRegion(regionId));
      dispatch(getBSclCountryLists(regionId, subRegionId));
    }
    dispatch(getBSclRegionList());
  }, []);
  useEffect(() => {
    if (getBSchoolRegionList !== null) {
      setRegionList(getBSchoolRegionList);
    }
    if (getBSchoolCountryLists !== null) {
      setCountryList(getBSchoolCountryLists);
    }
    if (getBSchoolSubRegionList !== null) {
      setSubRegion(getBSchoolSubRegionList);
    }
  }, [getBSchoolRegionList, getBSchoolCountryLists, getBSchoolSubRegionList]);

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div style={{ display: "flex", flexDirection: "row", margin: "10px" }}>
          <img
            src={BackButton}
            style={{ cursor: "pointer", marginTop: "-10px" }}
            onClick={() => props.history.goBack()}
          />
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Typography
              style={{
                cursor: "pointer",
                fontWeight: "600",
                marginLeft: "10px",
              }}
            >
              Back
            </Typography>
          </Breadcrumbs>
        </div>
        <AppBar position="static" color="default">
          <GSContainer>
            <GSTitle>
              {" "}
              {bSchoolData.id.length > 0 ? "Edit" : "Add"} B - School
            </GSTitle>
            <Grid
              container
              justifyContent="center"
              direction="column"
              alignItems="center"
              spacing={4}
            >
              <Grid item md={10}>
                <Grid container spacing={6}>
                  <Grid item md={6}>
                    <TextField
                      variant="standard"
                      color="primary"
                      label={"Name"}
                      value={bSchoolData.name}
                      fullWidth
                      onChange={(e) => {
                        setBSchoolData({
                          ...bSchoolData,
                          name: e.target.value,
                        });
                        setNameErr("");
                      }}
                      helperText={nameErr}
                      required
                      onKeyDown={(e) => isOnlyNumber(e) && e.preventDefault()}
                      error={nameErr === "" ? false : true}
                    />
                  </Grid>
                  {textField.map((item) => {
                    return (
                      <Grid item md={6}>
                        <TextField
                          value={item.value}
                          onChange={onChange}
                          name={item.name}
                          label={item.label}
                          type={item.type}
                          fullWidth
                          InputLabelProps={{
                            shrink: item.value === null ? false : true,
                          }}
                          required={
                            item.name === "shortName" ||
                            item.name === "state" ||
                            item.name === "location" ||
                            item.name === "collegeLink"
                          }
                          helperText={
                            item.name === "shortName"
                              ? shortName
                              : null || item.name === "state"
                              ? state
                              : null || item.name === "location"
                              ? location
                              : null || item.name === "collegeLink"
                              ? collegeLinkErr
                              : null || item.name === "imageLogoLink"
                              ? imageLogoLinkErr
                              : null || item.name === "imageUrl"
                              ? imageUrlErr
                              : null || item.name === "collegeEmailId"
                              ? collegeMailIdErr
                              : null
                          }
                          error={
                            item.name === "shortName" && shortName !== ""
                              ? true
                              : false || (item.name === "state" && state !== "")
                              ? true
                              : false ||
                                (item.name === "location" && location !== "")
                              ? true
                              : false ||
                                (item.name === "collegeLink" &&
                                  collegeLinkErr !== "")
                              ? true
                              : false ||
                                (item.name === "imageLogoLink" &&
                                  imageLogoLinkErr !== "")
                              ? true
                              : false ||
                                (item.name === "imageUrl" && imageUrlErr !== "")
                              ? true
                              : false ||
                                (item.name === "collegeEmailId" &&
                                  collegeMailIdErr !== "")
                              ? true
                              : false
                          }
                          onKeyDown={(e) =>
                            item.name === "collegeEmailId"
                              ? ""
                              : isOnlyNumber(e) && e.preventDefault()
                          }
                        />
                      </Grid>
                    );
                  })}
                  <Grid item md={6}>
                    <Autocomplete
                      value={bSchoolData.region}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          setBSchoolData({
                            ...bSchoolData,
                            region: {
                              name: newValue,
                            },
                          });
                          setRegionErr("");
                        } else if (newValue && newValue.inputValue) {
                          setBSchoolData({
                            ...bSchoolData,
                            region: {
                              name: newValue.inputValue,
                            },
                          });
                          setRegionErr("");
                        } else {
                          setBSchoolData({ ...bSchoolData, region: newValue });
                          setRegionErr("");

                          dispatch(getBSchoolSubRegion(newValue.id));
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;

                        const isExisting = options.some(
                          (option) => inputValue === option.name
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            name: `Add "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      options={regionList}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.name;
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option.name}</li>
                      )}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Region"
                          variant="standard"
                          fullWidth
                          color="primary"
                          size="small"
                          required
                          onKeyDown={(e) =>
                            isOnlyNumber(e) && e.preventDefault()
                          }
                          helperText={regionErr}
                          error={regionErr === "" ? false : true}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Autocomplete
                      value={bSchoolData.subRegion}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          setBSchoolData({
                            ...bSchoolData,
                            subRegion: {
                              name: newValue,
                            },
                          });
                          setSubRegionErr("");
                        } else if (newValue && newValue.inputValue) {
                          setBSchoolData({
                            ...bSchoolData,
                            subRegion: {
                              name: newValue.inputValue,
                            },
                          });
                          setSubRegionErr("");
                          dispatch(
                            getBSclCountryLists(
                              bSchoolData.region.id,
                              newValue.id
                            )
                          );
                        } else {
                          setBSchoolData({
                            ...bSchoolData,
                            subRegion: newValue,
                          });
                          setSubRegionErr("");
                          dispatch(
                            getBSclCountryLists(
                              bSchoolData.region?.id,
                              newValue?.id
                            )
                          );
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;

                        const isExisting = options.some(
                          (option) => inputValue === option.name
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            name: `Add "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      options={subRegion}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.name;
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option.name}</li>
                      )}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sub Region"
                          variant="standard"
                          fullWidth
                          color="primary"
                          size="small"
                          required
                          onKeyDown={(e) =>
                            isOnlyNumber(e) && e.preventDefault()
                          }
                          helperText={subRegionErr}
                          error={subRegionErr === "" ? false : true}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Autocomplete
                      value={bSchoolData.country}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          setBSchoolData({
                            ...bSchoolData,
                            country: {
                              name: newValue,
                            },
                          });
                          setCountryErr("");
                        } else if (newValue && newValue.inputValue) {
                          setBSchoolData({
                            ...bSchoolData,
                            country: {
                              name: newValue.inputValue,
                            },
                          });
                          setCountryErr("");
                        } else {
                          setBSchoolData({ ...bSchoolData, country: newValue });
                          setCountryErr("");
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;

                        const isExisting = options.some(
                          (option) => inputValue === option.name
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            name: `Add "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      options={countryList}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.name;
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option.name}</li>
                      )}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Country"
                          variant="standard"
                          fullWidth
                          color="primary"
                          size="small"
                          required
                          onKeyDown={(e) =>
                            isOnlyNumber(e) && e.preventDefault()
                          }
                          helperText={countryErr}
                          error={countryErr === "" ? false : true}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={2}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  className={classes.btnStyles}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </GSContainer>
        </AppBar>
        <MySnackBar
          snackOpen={bSchoolData.snack.open}
          snackVariant={bSchoolData.snack.color}
          snackMsg={bSchoolData.snack.message}
          onClose={() =>
            setBSchoolData({
              ...bSchoolData,
              snack: {
                open: false,
                message: "",
                color: "",
              },
            })
          }
        />
      </div>
    </React.Fragment>
  );
}
