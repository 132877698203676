import React from 'react';
import ReactApexChart from 'react-apexcharts';

const UserJourneyChart = ({ series, categories, yaxisTitle }) => {
  return (
    <div>
      <ReactApexChart
        type='bar'
        height={350}
        series={series}
        options={{
          chart: {
            // stacked: true,
            type: 'bar',
            animations: {
              enabled: true,
              easing: 'easeinout',
              speed: 3000,
              animateGradually: {
                enabled: true,
                delay: 1500,
              },
              dynamicAnimation: {
                enabled: true,
                speed: 350,
              },
            },
          },
          xaxis: {
            categories: categories,
            labels: {
              style: {
                fontSize: '11px',
              },
            },
          },
          yaxis: {
            title: {
              text: yaxisTitle,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: '15%',
              dataLabels: {
                position: 'top',
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function(val) {
              return val;
            },
            offsetY: -25,
            style: {
              fontSize: '12px',
              colors: ['#0f0f10'],
            },
          },
          legend: {
            position: 'top',
            fontSize: '12px',
            markers: {
              radius: 50,
              offsetX: -5,
            },
            itemMargin: {
              horizontal: 10,
            },
          },
        }}
      />
    </div>
  );
};

export default UserJourneyChart;
