import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { FlexViewContainer } from "../../Asset/StyledComponent";

const useStyles = makeStyles((theme) => ({
  uploadButtonRoot: {
    maxWidth: "88px",
  },
  text: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

function PopupComponent({
  open,
  title,
  onCancel,
  onSave,
  onPreview,
  onUpload,
  children,
  disabledOnSave,
  disabledOnUpload,
  isPreview,
  isUpload,
  isSave,
  maxWidth,
  loading,
}) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      fullWidth
      onClose={loading ? () => {} : onCancel}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <FlexViewContainer
          gap={"25px"}
          padding={"15px 20px !important"}
          minWidth={"300px"}
        >
          <Button
            color={"primary"}
            variant={"outlined"}
            onClick={onCancel}
            disabled={loading}
            fullWidth
          >
            {"Cancel"}
          </Button>

          {isPreview && (
            <Button
              color={"primary"}
              variant={"contained"}
              title={"Preview template for student bulk upload"}
              onClick={onPreview}
              disabled={loading}
              fullWidth
            >
              {"Preview"}
            </Button>
          )}

          {isUpload && (
            <Button
              color={"primary"}
              variant={"contained"}
              onClick={onUpload}
              disabled={loading || disabledOnUpload}
              classes={{ root: classes.uploadButtonRoot }}
              fullWidth
              startIcon={
                loading && <CircularProgress size={14} color={"inherit"} />
              }
            >
              <span className={classes.text}>{"Upload"}</span>
            </Button>
          )}

          {isSave && (
            <Button
              color={"primary"}
              variant={"contained"}
              className={"button-style"}
              onClick={onSave}
              disabled={disabledOnSave}
              fullWidth
            >
              {"Save"}
            </Button>
          )}
        </FlexViewContainer>
      </DialogActions>
    </Dialog>
  );
}

export default PopupComponent;
