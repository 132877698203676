import axios from "axios";
import { errorHandler } from "../Component/Utils/Helpers";
import { POST_ADMIT_SERVICES } from "../Redux/Action";
import { URL } from "./URL";

export const getDropDownPAS = (studentId) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      // dispatch({ type: POST_ADMIT_SERVICES.loader });
      await axios
        .get(
          `${URL}/api/v1/students/${studentId}/postadmitservices/documenttypes`,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: POST_ADMIT_SERVICES.PostAdmitServicesDropDown,
            payload: response.data,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(
        errorHandler(
          POST_ADMIT_SERVICES.PostAdmitServicesDropDown,
          error,
          false
        )
      );
    }
  };
};
export const getFilesPAS = (studentId, productId) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      // dispatch({ type: POST_ADMIT_SERVICES.loader });
      await axios
        .get(
          `${URL}/api/v1/students/${studentId}/products/${productId}/postadmitservices/documents`,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: POST_ADMIT_SERVICES.getFiles,
            payload: response.data,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(POST_ADMIT_SERVICES.getFiles, error, false));
    }
  };
};
export const getRegionPAS = (studentId) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      // dispatch({ type: POST_ADMIT_SERVICES.loader });
      await axios
        .get(`${URL}/api/v1/students/${studentId}/school/regions`, {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          dispatch({
            type: POST_ADMIT_SERVICES.getRegion,
            payload: response.data,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(POST_ADMIT_SERVICES.getRegion, error, false));
    }
  };
};
export const getCountryPAS = (studentId) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      // dispatch({ type: POST_ADMIT_SERVICES.loader });
      await axios
        .get(`${URL}/api/v1/country?q=`, {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          dispatch({
            type: POST_ADMIT_SERVICES.getCountry,
            payload: response.data,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(POST_ADMIT_SERVICES.getCountry, error, false));
    }
  };
};
export const uploadFilePASregion = (
  studentId,
  productId,
  documentTypeId,
  regionId,
  data,
  comment
) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      // dispatch({ type: POST_ADMIT_SERVICES.loader });
      await axios
        .post(
          `${URL}/api/v1/students/${studentId}/products/${productId}/postadmitservices/${documentTypeId}?regionId=${regionId}&countryId=`,
          data,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              comment: comment,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: POST_ADMIT_SERVICES.postFileUpload,
            payload: { success: true, data: response.data },
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(POST_ADMIT_SERVICES.postFileUpload, error, false));
    }
  };
};
export const uploadFilePAScountry = (
  studentId,
  productId,
  documentTypeId,
  countryId,
  data,
  comment
) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      // dispatch({ type: POST_ADMIT_SERVICES.loader });
      await axios
        .post(
          `${URL}/api/v1/students/${studentId}/products/${productId}/postadmitservices/${documentTypeId}?regionId=&countryId=${countryId}`,
          data,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              comment: comment,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: POST_ADMIT_SERVICES.postFileUpload,
            payload: { success: true, data: response.data },
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(POST_ADMIT_SERVICES.postFileUpload, error, false));
    }
  };
};
export const uploadFilePAS = (
  studentId,
  productId,
  documentTypeId,
  data,
  comment
) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      // dispatch({ type: POST_ADMIT_SERVICES.loader });
      await axios
        .post(
          `${URL}/api/v1/students/${studentId}/products/${productId}/postadmitservices/${documentTypeId}?regionId=&countryId=`,
          data,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              comment: comment,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: POST_ADMIT_SERVICES.postFileUpload,
            payload: { success: true, data: response.data },
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(POST_ADMIT_SERVICES.postFileUpload, error, false));
    }
  };
};
export const deleteFilesPAS = (studentId, productId, fileName) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      // dispatch({ type: POST_ADMIT_SERVICES.loader });
      await axios
        .delete(
          `${URL}/api/v1/students/${studentId}/products/${productId}/postadmitservices/documents/${fileName}`,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: POST_ADMIT_SERVICES.deleteFiles,
            payload: response.data,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(POST_ADMIT_SERVICES.deleteFiles, error, false));
    }
  };
};
export const getMomPoints = (studentId, productId, stageId) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return async (dispatch) => {
    try {
      await axios
        .get(
          `${URL}/api/v1/students/${studentId}/products/${productId}/stages/${stageId}/mompoints`,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: POST_ADMIT_SERVICES.momPoints,
            payload: response.data,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(POST_ADMIT_SERVICES.momPoints, error, false));
    }
  };
};
export const savePoints = (studentId, productId, stageId, data) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      // dispatch({ type: PROFILE_MENTORING.loader });
      await axios
        .post(
          `${URL}/api/v1/students/${studentId}/products/${productId}/stages/${stageId}/mompoints`,
          data,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: POST_ADMIT_SERVICES.savePoints,
            payload: { success: true, data: response.data },
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(POST_ADMIT_SERVICES.savePoints, error, false));
    }
  };
};

export const getDocumentCard = (studentId, productId, postadmitschoolId) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return async (dispatch) => {
    try {
      await axios
        .get(
          `${URL}/api/v1/students/${studentId}/products/${productId}/postadmitservices/${postadmitschoolId}/admitcardfiles`,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: POST_ADMIT_SERVICES.getDocuments,
            payload: response.data,
            loading: false,
          });
        });
    } catch (error) {
      // dispatch(errorHandler(POST_ADMIT_SERVICES.getDocuments, error, false));
    }
  };
};
export const DocumentDownload = (
  studentId,
  productId,
  admitcardId,
  postadmitschoolId,
  fileName,
  documentName
) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    // dispatch({ type: CV_REVIEW.loader });
    axios
      .get(`${URL}/api/v1/students/${studentId}/products/${productId}/postadmitservices/${admitcardId}/${postadmitschoolId}/${fileName}
          `, {
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'blob',
      })
      .then(result => {
        var filename = documentName;
        const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `${filename}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        dispatch({
          type: POST_ADMIT_SERVICES.downloadDocuments,
          payload: { success: true, data: result.data },
          loading: false,
        });
      })
      .catch((error) => {
        dispatch(
          errorHandler(POST_ADMIT_SERVICES.downloadDocuments, error, false)
        );
      });
  };
};
export const DocumentDownloadPAS = (
  studentId,
  productId,
  fileId,
  documentName
) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    // dispatch({ type: CV_REVIEW.loader });
    axios
      .get(
        `${URL}/api/v1/students/${studentId}/products/${productId}/postadmitservices/documents/${fileId}
        `,
        {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: "blob",
        }
      )
      .then((result) => {
        var filename = documentName;

        const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `${filename}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        dispatch({
          type: POST_ADMIT_SERVICES.downloadDocuments,
          payload: { success: true, data: result.data },
          loading: false,
        });
      })
      .catch((error) => {
        dispatch(
          errorHandler(POST_ADMIT_SERVICES.downloadDocuments, error, false)
        );
      });
  };
};
export const getDetails = (studentId, productId) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return async (dispatch) => {
    try {
      await axios
        .get(
          `${URL}/api/v1/students/${studentId}/products/${productId}/postadmitservices/schools`,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: POST_ADMIT_SERVICES.getCardDetails,
            payload: response.data,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(POST_ADMIT_SERVICES.getCardDetails, error, false));
    }
  };
};

export const clearData = () => {
  return (dispatch) => {
    dispatch({ type: POST_ADMIT_SERVICES.clearData });
  };
};
