export const COURSES = {
  GetCourses: 'GetCourses',
  AddCourse: 'AddCourse',
  GetCourseById: 'GetCourseById',
  UpdateCourse: 'UpdateCourse',
  GetRecommendedCouses: 'GetRecommendedCourses',
  GetPopularCourses: 'GetPopularCourses',
  GetSimilarCourses: 'GetSimilarCourses',
  GetMarkettingRecommended: 'GetMarkettingRecommended',
  GetServiceRecommended: 'GetServiceRecommended',
  DomainList: 'DomainList',
  SubDomainList: 'SubDomainList',
  ProductVariant: 'ProductVariant',
  AdvanceCourse: 'AdvanceCourse',

  // Selva
  deleteCourse: 'deleteCourse',
  getPaginateCourse: 'getPaginateCourse',
};
export const USERMANAGEMENT = {
  getUserDepartment: 'getUserDepartment',
  getUserDetails: 'getUserDetails',
  editAdmin: 'editAdmin',
};

export const QUESTIONS = {
  getQuestions: 'getQuestions',
};
export const PROFILE_GAP_ANALYSIS = {
  loader: 'profileGapAnalysisLoader',
  getgeneraldetails: 'getgeneraldetails',
  getstatus: 'getstatus',
  getCommentHistory: 'getCommentHistory',
  updatestatus: 'updatestatus',
  getTestResults: 'getTestResults',
  getPpgaCallNotes: 'getPpgaCallNotes',
  updatePpgaCallNotes: 'updatePpgaCallNotes',
  getcvresult: 'getcvresult',
  deletecvresult: 'deletecvresult',
  updatecvresult: 'updatecvresult',
  getdashboarddetails: 'getdashboarddetails',
  getPgaList: 'getPgaList',
  getReportPreview: 'getReportPreview',
  getReportStatus: 'getReportStatus',
  saveSemesterDetails: 'saveSemesterDetails',
  ppgaCallNotesStatus: 'ppgaCallNotesStatus',
  skipPPGAcallStatus: 'skipPPGAcallStatus',
  skipPPGAbutton: 'skipPPGAbutton',
  downloadPgaList: 'downloadPgaList',
};

export const COLLEGES = {
  getCollege: 'getCollege',
  getAllColleges: 'getAllColleges',
  addCollges: 'addColleges',
  updateColleges: 'updateColleges',
  deleteCollege: 'deleteCollege',
  getBranches: 'getBranches',
  getUniversity: 'getUniversity',
  addUniversity: 'addUniversity',
  updateUniversity: 'updateUniversity',
  deleteUniversity: 'deleteUniversity',
  getDegrees: 'getDegrees',
  getPaginateDegree: 'getPaginateDegree',
  // Selva
  getPaginateCollege: 'getPaginateCollege',
  getPaginateUniversity: 'getPaginateUniversity',
  getPGDegrees: 'getPGDegrees',
};

export const DEGREE = {
  getDegreePaginate: 'getDegreePaginate',
  getAllDegrees: 'getAllDegrees',
  addDegree: 'addDegree',
  updateDegree: 'updateDegree',
  deleteDegree: 'deleteDegree',
};

export const STUDENT = {
  getStudent: 'getStudent',
  getStudentById: 'getStudentById',
  postStudent: 'postStudent',
  studentCollegeInformation: 'studentCollegeInformation',
  postQuestion: 'postQuestion',
  studentFeedback: 'studentFeedback',
  startTestExecution: 'startTestExecution',
  careerInterestSurvey: 'careerInterestSurvey',
  getAnswer: 'getAnswer',
  getStudentPaginate: 'getStudentPaginate',
  viewDocumet: 'viewDocumet',
  editDocument: 'editDocument',
  downloadDocument: 'downloadDocument',
  deleteDocument: 'deleteDocument',
  mernStudentSignUp: 'mernStudentSignUp',
  mernStudentEdit: 'mernStudentEdit',
  getBlackListedUser: 'getBlackListedUser',
  getWhiteListedUser: 'getWhiteListedUser',
  getMernUser: 'getMernUser',
  getManualUser: 'getManualUser',
  catchSignUpError: 'catchSignUpError',
  viewAllCity: 'viewAllCity',
  getAspirationById: 'getAspirationById',
  getTempPersonalData: 'getTempPersonalData',
  verifyNewPersonalData: 'verifyNewPersonalData',
  updateUserData: 'updateUserData',
  getAcademicInfo: 'getAcademicInfo',
  updateAcademicInfo: 'updateAcademicInfo',
  uploadFile: 'uploadFile',
  sscexamboard: 'sscexamboard',
  getDocumentList: 'getDocumentList',
  deleteDocument: 'deleteDocument',
  deleteDocumentGraduate: 'deleteDocumentGraduate',
  filterStageBaseUsers: 'filterStageBaseUsers',
  searchStudentInStages: 'searchStudentInStages',
  StudentStepDetails: 'StudentStepDetails',
  ObIncomplete: 'ObIncomplete',
  ObComplete: 'ObComplete',
  IncompleteStatus: 'IncompleteStatus',
  getexpecteddate: 'getexpecteddate',
  getieltsexam: 'getieltsexam',
  aspirationWork: 'aspirationWork',
  aspirationPackage: 'aspirationPackage',
  aspirationLocation: 'aspirationLocation',
  getaspirationData: 'getaspirationData',
  postaspirationData: 'postaspirationData',
  saveFieldOfStudy: 'saveFieldOfStudy',
  AspirationCollege: 'AspirationCollege',
  aspirationTerm: 'aspirationTerm',
  aspirationDegree: 'aspirationDegree',
  aspirationBranch: 'aspirationBranch',
  aspirationCountry: 'aspirationCountry',
  getRegions: 'getRegions',
  getDegree: 'getDegree',
  AspirationCollege: 'AspirationCollege',
  aspirationSpecialization: 'aspirationSpecialization',
  getAllIntakeList: 'getAllIntakeList',
  opsStageComplete: 'opsStageComplete',
  getStudentStageByProductId: 'getStudentStageByProductId',
  getStepsBySubStageId: 'getStepsBySubStageId',
  getCompletedStages: 'getCompletedStages',
  getStageLockStatus: 'getStageLockStatus',
  downloadStudentPaginate: 'downloadStudentPaginate',
};

export const CHOICE_ANSWER = {
  getChoiceAnswer: 'getChoiceAnswer',
};

/**department */
export const DEPARTMENT = {
  addDepartment: 'addDepartment',
  updateDepartment: 'updateDepartment',
  deleteDepartment: 'deleteDepartment',
  getAllDepartments: 'getAllDepartments',
  getPaginateDepartment: 'getPaginateDepartment',
};

/**Intake */
export const INTAKE = {
  addIntake: 'addIntake',
  updateIntake: 'updateIntake',
  deleteIntake: 'deleteIntake',
  getAllIntakes: 'getAllIntakes',
  getPaginateIntake: 'getPaginateIntake',
};

// Selva
export const ASPIRATION = {
  getAllSpecialization: 'getAllSpecialization',
  getAllDegree: 'getAllDegree',
  getAllBranch: 'getAllBranch',
  getAllUniversity: 'getAllUniversity',
  getAllTerms: 'getAllTerms',
  viewSpecialization: 'viewSpecialization',
  addSpecialization: 'addSpecialization',
  updateSpecialization: 'updateSpecialization',
  deleteSpecialization: 'deleteSpecialization',
  viewDegree: 'viewDegree',
  addDegree: 'addDegree',
  updateDegree: 'updateDegree',
  deleteDegree: 'deleteDegree',
  viewFeild: 'viewFeild',
  addFeild: 'addFeild',
  updateFeild: 'updateFeild',
  deleteFeild: 'deleteFeild',
  viewCountry: 'viewCountry',
  addCountry: 'addCountry',
  updateCountry: 'updateCountry',
  deleteCountry: 'deleteCountry',
  viewCollege: 'viewCollege',
  addCollege: 'addCollege',
  updateCollege: 'updateCollege',
  deleteCollege: 'deleteCollege',
  viewTerm: 'viewTerm',
  addTerm: 'addTerm',
  updateTerm: 'updateTerm',
  deleteTerm: 'deleteTerm',
  viewCountryForSelect: 'viewCountryForSelect',
  viewCity: 'viewCity',
  addCity: 'addCity',
  updateCity: 'updateCity',
  deleteCity: 'deleteCity',
  getallcountry: 'getallcountry',
  getAspirationQuestion: 'getAspirationQuestion',
};

export const QUESTIONSET = {
  viewQuestionSet: 'viewQuestionSet',
  addQuestionSet: 'addQuestionSet',
  editQuestionSet: 'editQuestionSet',
  deleteQuestionSet: 'deleteQuestionSet',
  viewQuestion: 'viewQuestion',
  addQuestion: 'addQuestion',
  editQuestion: 'editQuestion',
  deleteQuestion: 'deleteQuestion',
  viewChoice: 'viewChoice',
  addChoice: 'addChoice',
  editChoice: 'editChoice',
  deleteChoice: 'deleteChoice',
};
export const CAREER_ROLE_GRAPH = {
  getStudentGoals: 'getStudentGoals',
  deleteStudentGoals: 'deleteStudentGoals',
  updateStudentGoals: 'updateStudentGoals',
  getGoalsType: 'getGoalsType',
  getGraphDetails: 'getGraphDetails',
  getCareerDetails: 'getCareerDetails',
};

export const VIDEO = {
  viewVideo: 'viewVideo',
  addVideo: 'addVideo',
  editVideo: 'editVideo',
  deleteVideo: 'deleteVideo',
};

export const PRODUCT = {
  addPaidData: 'addPaidData',
  viewProduct: 'viewProduct',
  addProduct: 'addProduct',
  editProduct: 'editProduct',
  deleteProduct: 'deleteProduct',
  viewProductToStudent: 'viewProductToStudent',
  addProductToStudent: 'addProductToStudent',
  getAllProductFamily: 'getAllProductFamily',
  getProductByFamilyId: 'getProductByFamilyId',
  postproductfamily: 'postproductfamily',
  getProductVarient: 'getProductVarient',
  postProductVarient: 'postProductVarient',
  updateProductVarient: 'updateProductVarient',
  getAllProductImages: 'getAllProductImages',
  getAllProductVideos: 'getAllProductVideos',
  getAllProductQuesAns: 'getAllProductQuesAns',
  updateproductfamily: 'updateproductfamily',
  postProductVideos: 'postProductVideos',
  updateProductVideos: 'updateProductVideos',
  postvarientimage: 'postvarientimage',
  updatevarientimage: 'updatevarientimage',
  updatefamily: 'updatefamily',
  deletefamily: 'deletefamily',
  postgeneraldetails: 'postgeneraldetails',
  deleteproductvarient: 'deleteproductvarient',
  getFaq: 'getFaq',
  updateFaq: 'updateFaq',
  postFaq: 'postFaq',
  getvarientByid: 'getvarientByid',
  updateProductPunching: 'updateProductPunching',
  addproductcombo: 'addproductcombo',
  getproductcombo: 'getproductcombo',
  isVariantCreated: 'isVariantCreated',
  updateProductOnelinerAndDesc: 'updateProductOnelinerAndDesc',
  addProductPunching: 'addProductPunching',
  publishvarient: 'publishvarient',
  comboexcel: 'comboexcel',
  varientexcel: 'varientexcel',
  getPunchingData: 'getPunchingData',
  postPunchingData: 'postPunchingData',
  putproductstructure: 'putproductstructure',
  getproductstructure: 'getproductstructure',
  postproductstructure: 'postproductstructure',
  getUserDataAcademicInfo: 'getUserDataAcademicInfo',
  getproductsteps: 'getproductsteps',
  searchProductActivationList: 'searchProductActivationList',
  getVariantStepsById: 'getVariantStepsById',
  getReferProductVariantByProductId: 'getReferProductVariantByProductId',
  downloadProductActivationList: 'downloadProductActivationList',
  getData: 'getData',
};

export const ADMIN = {
  loader: 'adminLoader',
  adminLogin: 'adminLogin',
  refreshToken: 'refreshToken',
  studentAccess: 'studentAccess',
  updatePersonalData: 'updatePersonalData',
  updateEducationalData: 'updateEducationalData',
  updateContactData: 'updateContactData',
  updateAccountStatus: 'updateAccountStatus',
  giveInternAccess: 'giveInternAccess',
  updateAspirationData: 'updateAspirationData',
  viewStudentStatus: 'viewStudentStatus',
  updateVerificationStatus: 'updateVerificationStatus',
  getAllMentor: 'getAllMentor',
  alocateMentor: 'alocateMentor',
  updateLmsAccess: 'updateLmsAccess',
  getAwaitingUsersByAdminId: 'getAwaitingUsersByAdminId',
  activateStudentProduct: 'activateStudentProduct',
  getAdminLinkedProduct: 'getAdminLinkedProduct',
  checkTokenStatus: 'checkTokenStatus',
  getStudentsByStages: 'getStudentsByStages',
  getAllAdminUsers: 'getAllAdminUsers',
  deletementor: 'deletementor',
  getAdminUserDepartments: 'getAdminUserDepartments',
  clearCustomData: 'clearAdminLoginCustomData',
  getProductsInReports: 'getProductsInReports',
  getManageStudentsByStages: 'getManageStudentsByStages',
  getSearchingProductId: 'getSearchingProductId',
  clearData: 'clearData',
  getDropdown: 'getDropdown',
  getCount: 'getCount',
  downloadStudentsByStages: 'downloadStudentsByStages',
  downloadManageStudentsByStages: 'downloadManageStudentsByStages',
  getGuide: 'getGuide',
  report: 'report',
};

export const MENTORSCHEDULELIST = {
  viewSchedule: 'viewSchedule',
  getstudentMapping: 'getstudentMapping',
  getproductdetails: 'getproductdetails',
  updateallocatementor: 'updateallocatementor',
  addmentor: 'addmentor',
  updatementor: 'updatementor',
  getmentor: 'getmentor',
};

export const CALL_DETAILS = {
  updateclientdetails: 'updateclientdetails',
  updateQuestions: 'updateQuestions',
  updateRating: 'updateRating',
  academicdetails: 'academicdetails',
  getPersonalInfo: 'getPersonalInfo',
  updatePersonalInfo: 'updatePersonalInfo',
  academicdetails: 'academicdetails',
  updateworkexp: 'updateworkexp',
  getworkexp: 'getworkexp',
  getClientInfo: 'getClientInfo',
  getAspirationDetails: 'getAspirationDetails',
  getgrescore: 'getgrescore',
  getgmatscore: 'getgmatscore',
  gettoeflscore: 'gettoeflscore',
  getieltsscore: 'getieltsscore',
  updategrescore: 'updategrescore',
  updategmatscore: 'updategmatscore',
  updatetoeflscore: 'updatetoeflscore',
  updateieltsscore: 'updateieltsscore',
  downloadGAT: 'downloadGAT',
  fileuploadGAT: 'fileuploadGAT',
  completecall: 'completecall',
  skipcall: 'skipcall',
  course: 'course',
  getIeltsCompletedExamScore: 'getIeltsCompletedExamScore',
  getIntakeTermList: 'getIntakeTermList',
  course: 'course',
  // getsearchlist:"getsearchlist"
};

export const CAREER_TRACK = {
  addCareerTrack: 'addCareerTrack',
  viewCareerTrack: 'viewCareerTrack',
  updateCareerTrack: 'updateCareerTrack',
  deleteCareerTrack: 'deleteCareerTrack',
};

export const NOTIFICATION = {
  viewNotification: 'viewNotification',
  addNotification: 'addNotification',
  updateNotification: 'updateNotification',
  deleteNotification: 'deleteNotification',
};

export const REPORTS = {
  viewTermsAndConReport: 'viewTermsAndConReport',
  viewCvReport: 'viewCvReport',
  viewMarksheetReport: 'viewMarksheetReport',
  viewMyDetailsReport: 'viewMyDetailsReport',
  viewTechTestMechReport: 'viewTechTestMechReport',
  viewTechTestCseReport: 'viewTechTestCseReport',
  viewTestRating: 'viewTestRating',
  viewTechTestElectronics: 'viewTechTestElectronics',
  viewDiagTestReport: 'viewDiagTestReport',
  viewCareerExpoTest: 'viewCareerExpoTest',
  generateSalesReport: 'generateSalesReport',
  viewSalesReport: 'viewSalesReport',
  generateMasterReport: 'generateMasterReport',
  generateMasterReport2: 'generateMasterReport2',
  viewMasterReport: 'viewMasterReport',
  clearCustomData: 'reportClearCustomData',
};

export const STUDENTMARKDETAILS = {
  viewStudentMarkDetails: 'viewStudentMarkDetails',
  viewResetTest: 'viewResetTest',
  viewAnswers: 'viewAnswers',
};
export const SCOREDETAILS = {
  viewScoreDetails: 'viewScoreDetails',
};

export const PGA = {
  getScoreDetails: 'getScoreDetails',
  getCareerInterest: 'getCareerInterest',
  postAcademicData: 'postAcademicData',
  getPgaAcademicData: 'getPgaAcademicData',
  getChoosenTrack: 'getChoosenTrack',
  postGenralDetails: 'postGenralDetails',
  getPgaCvAndPpga: 'getPgaCvAndPpga',
  postPgaCvAndPpga: 'postPgaCvAndPpga',
  getppgaques: 'getppgaques',
  postcvandppga: 'postcvandppga',
  getcvandppga: 'getcvandppga',
  getppgaques: 'getppgaques',
  getcvques: 'getcvques',
  getAllEnrollmentPeriod: 'getAllEnrollmentPeriod',
  getQuarterPlan: 'getQuarterPlan',
  getAllStarterPack: 'getAllStarterPack',
  getPackageByStudentId: 'getPackageByStudentId',
  getAllQuarterPlan: 'getAllQuarterPlan',
  postCommentsAndPoints: 'postCommentsAndPoints',
  getAdditionalPoints: 'getAdditionalPoints',
  postAditionalPoints: 'postAditionalPoints',
  getQuarterPlanByType: 'getQuarterPlanByType',
  getPbChoosenTrack: 'getPbChoosenTrack',
  getStudentGrade: 'getStudentGrade',
  getAllSpecialization: 'getAllSpecialization',
  postPgaPlanCareerTrack: 'postPgaPlanCareerTrack',
  postQuarterPgaPlan: 'postQuarterPgaPlan',
  getallcourse: 'getallcourse',
  newenroll: 'newenroll',
  getenroll: 'getenroll',
  unenroll: 'unenroll',
  getAllEnroll: 'getAllEnroll',
  getFilteredCourseForEnroll: 'getFilteredCourseForEnroll',
  clearNewEnroll: 'clearNewEnroll',
  clearUnEnroll: 'clearUnEnroll',
  uploadfile: 'uploadfile',
  getallfiles: 'getallfiles',
  downlaodfiles: 'downlaodfiles',
  deletefiles: 'deletefiles',
  viewfiles: 'viewfiles',
  viewAcademicDetails: 'viewAcademicDetails',
  viewSemesterDetails: 'viewSemesterDetails',
  saveSemesterDetails: 'saveSemesterDetails',
  deleteSemesterDetails: 'deleteSemesterDetails',
  saveAcademicDetails: 'saveAcademicDetails',
  updateCalculation: 'updateCalculation',
  // fileDownload : "fileDownload"
};

export const HELPER = {
  storeItInState: 'storeItInState',
  setPopperAnchorEl: 'setPopperAnchorEl',
  setFilterAnchorEl: 'setFilterAnchorEl',
  getAcademicType: 'getAcademicType',
  isClickedSem: 'isClickedSem',
  saveCopyData: 'saveCopyData',
  saveTemplate: 'saveTemplate',
  saveSchool: 'saveSchool',
  saveCourse: 'saveCourse',
};

//MOHAMMED
export const TESTIMONIAL = {
  LIST_REQUEST: 'LIST_REQUEST',
  LIST_SUCCESS: 'LIST_SUCCESS',
  LIST_FAIL: 'LIST_FAIL',

  DETAILS_REQUEST: 'DETAILS_REQUEST',
  DETAILS_SUCCESS: 'DETAILS_SUCCESS',
  DETAILS_FAIL: 'DETAILS_FAIL',

  DELETE_REQUEST: 'DELETE_REQUEST',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  DELETE_FAIL: 'DELETE_FAIL',

  CREATE_REQUEST: 'CREATE_REQUEST',
  CREATE_SUCCESS: 'CREATE_SUCCESS',
  CREATE_FAIL: 'CREATE_FAIL',
  CREATE_RESET: 'CREATE_RESET',

  UPDATE_REQUEST: 'UPDATE_REQUEST',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  UPDATE_FAIL: 'UPDATE_FAIL',
  UPDATE_RESET: 'UPDATE_RESET',
};

export const APPVERSION = {
  REQUEST: 'APP_VERSION_REQUEST',
  SUCCESS: 'APP_VERSION_SUCCESS',
  FAIL: 'APP_VERSION_FAIL',
  CREATE_REQUEST: 'CREATE_APP_VERSION_REQUEST',
  CREATE_SUCCESS: 'CREATE_APP_VERSION_SUCCESS',
  CREATE_FAIL: 'CREATE_APP_VERSION_FAIL',
};

// Dashboard
export const DASHBOARD = {
  ANALYTICS_REQUEST: 'ANALYTICS_REQUEST',
  ANALYTICS_SUCCESS: 'ANALYTICS_SUCCESS',
  ANALYTICS_FAIL: 'ANALYTICS_FAIL',

  LIVEDRIVE_REQUEST: 'LIVEDRIVE_REQUEST',
  LIVEDRIVE_SUCCESS: 'LIVEDRIVE_SUCCESS',
  LIVEDRIVE_FAIL: 'LIVEDRIVE_FAIL',

  CLSA_TEST_REQUEST: 'CLSA_TEST_REQUEST',
  CLSA_TEST_SUCCESS: 'CLSA_TEST_SUCCESS',
  CLSA_TEST_FAIL: 'CLSA_TEST_FAIL',

  STATUS_ALERT_REQUEST: 'STATUS_ALERT_REQUEST',
  STATUS_ALERT_SUCCESS: 'STATUS_ALERT_SUCCESS',
  STATUS_ALERT_FAIL: 'STATUS_ALERT_FAIL',

  UPDATE_DRIVES_REQUEST: 'UPDATE_DRIVES_REQUEST',
  UPDATE_DRIVES_SUCCESS: 'UPDATE_DRIVES_SUCCESS',
  UPDATE_DRIVES_FAIL: 'UPDATE_DRIVES_FAIL',

  CLSA_COUNT_REQUEST: 'CLSA_COUNT_REQUEST',
  CLSA_COUNT_SUCCESS: 'CLSA_COUNT_SUCCESS',
  CLSA_COUNT_FAIL: 'CLSA_COUNT_FAIL',

  USER_JOURNEY_REQUEST: 'USER_JOURNEY_REQUEST',
  USER_JOURNEY_SUCCESS: 'USER_JOURNEY_SUCCESS',
  USER_JOURNEY_FAIL: 'USER_JOURNEY_FAIL',
};

export const WALL = {
  LIST_REQUEST: 'WALL_LIST_REQUESTED',
  LIST_SUCCESS: 'WALL_LIST_SUCCESSFUL',
  LIST_FAIL: 'WALL_LIST_FAILED',

  WEBINAR_LIST_REQUEST: 'WEBINAR_LIST_REQUESTED',
  WEBINAR_LIST_SUCCESS: 'WEBINAR_LIST_SUCCESSFUL',
  WEBINAR_LIST_FAIL: 'WEBINAR_LIST_FAILED',

  DETAILS_REQUEST: 'WALL_DETAILS_REQUEST',
  DETAILS_SUCCESS: 'WALL_DETAILS_SUCCESS',
  DETAILS_FAIL: 'WALL_DETAILS_FAIL',

  WALL_CATEGORIES_REQUEST: 'WALL_CATEGORIES_REQUEST',
  WALL_CATEGORIES_SUCCESS: 'WALL_CATEGORIES_SUCCESS',
  WALL_CATEGORIES_FAIL: 'WALL_CATEGORIES_FAIL',

  WALL_JOB_LIST_REQUEST: 'WALL_JOB_LIST_REQUEST',
  WALL_JOB_LIST_SUCCESS: 'WALL_JOB_LIST_SUCCESS',
  WALL_JOB_LIST_FAIL: 'WALL_JOB_LIST_FAIL',

  DELETE_REQUEST: 'WALL_DELETE_REQUEST',
  DELETE_SUCCESS: 'WALL_DELETE_SUCCESS',
  DELETE_FAIL: 'WALL_DELETE_FAIL',

  CREATE_REQUEST: 'WALL_CREATE_REQUEST',
  CREATE_SUCCESS: 'WALL_CREATE_SUCCESS',
  CREATE_FAIL: 'WALL_CREATE_FAIL',
  CREATE_RESET: 'WALL_CREATE_RESET',

  UPDATE_REQUEST: 'WALL_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'WALL_UPDATE_SUCCESS',
  UPDATE_FAIL: 'WALL_UPDATE_FAIL',
  UPDATE_RESET: 'WALL_UPDATE_RESET',
  GET_PD_BY_ID: 'GET_PD_BY_ID',

  PLATFORMS: 'PLATFORMS',
  CONCEPTS: 'CONCEPTS',
  ACCOUNTS: 'ACCOUNTS',
  VIEWCOURSES: 'VIEWCOURSES',

  /* Success Story */
  LIST_STORY_REQUEST: 'WALL_STORY_REQUESTED',
  LIST_STORY_SUCCESS: 'WALL_STORY_SUCCESSFUL',
  LIST_STORY_FAIL: 'WALL_STORY_FAILED',
  CREATE_STORY_REQUEST: 'WALL_CREATE_STORY_REQUEST',
  CREATE_STORY_SUCCESS: 'WALL_CREATE_STORY_SUCCESS',
  CREATE_STORY_FAIL: 'WALL_CREATE_STORY_FAIL',
  UPDATE_STORY_REQUEST: 'WALL_UPDATE_STORY_REQUEST',
  UPDATE_STORY_SUCCESS: 'WALL_UPDATE_STORY_SUCCESS',
  UPDATE_STORY_FAIL: 'WALL_UPDATE_STORY_FAIL',
  GET_STORY_BY_ID: 'WALL_GET_STORY_BY_ID',
  /* Success Story */

  LOADER: 'WALL_LOADER',
  GET_DRIVE_BY_ID: 'GET_DRIVE_BY_ID',
  UPDATE_DRIVE_BY_ID: 'UPDATE_DRIVE_BY_ID',
  GET_ROUND_UPDATE_BY_ID: 'GET_ROUND_UPDATE_BY_ID',
  UPDATE_ROUND_UPDATE_BY_ROUND_MESSAGE: 'UPDATE_ROUND_UPDATE_BY_ROUND_MESSAGE',
  SENT_WHATSAPP_MSG_BY_ID: 'SENT_WHATSAPP_MSG_BY_ID',
  FILTER_DRIVE_BY_VALUE: 'FILTER_DRIVE_BY_VALUE',

  /* What's New */
  WALL_USERTYPE_REQUEST: 'WALL_USERTYPE_REQUEST',
  WALL_USERTYPE_SUCCESS: 'WALL_USERTYPE_SUCCESS',
  WALL_USERTYPE_FAIL: 'WALL_USERTYPE_FAIL',
  WALL_REDIRECTIONTYPE_REQUEST: 'WALL_REDIRECTIONTYPE_REQUEST',
  WALL_REDIRECTIONTYPE_SUCCESS: 'WALL_REDIRECTIONTYPE_SUCCESS',
  WALL_REDIRECTIONTYPE_FAIL: 'WALL_REDIRECTIONTYPE_FAIL',
  CREATE_WHATSNEW_REQUEST: 'CREATE_WHATSNEW_REQUEST',
  CREATE_WHATSNEW_SUCCESS: 'CREATE_WHATSNEW_SUCCESS',
  CREATE_WHATSNEW_FAIL: 'CREATE_WHATSNEW_FAIL',
  UPDATE_WHATSNEW_REQUEST: 'UPDATE_WHATSNEW_REQUEST',
  UPDATE_WHATSNEW_SUCCESS: 'UPDATE_WHATSNEW_SUCCESS',
  UPDATE_WHATSNEW_FAIL: 'UPDATE_WHATSNEW_FAIL',
  LIST_WHATSNEW_REQUEST: 'LIST_WHATSNEW_REQUEST',
  LIST_WHATSNEW_SUCCESS: 'LIST_WHATSNEW_SUCCESS',
  LIST_WHATSNEW_FAIL: 'LIST_WHATSNEW_FAIL',
  GET_WHATSNEW_BY_ID: 'GET_WHATSNEW_BY_ID',
  DELETE_WHATSNEW_REQUEST: 'DELETE_WHATSNEW_REQUEST',
  DELETE_WHATSNEW_SUCCESS: 'DELETE_WHATSNEW_SUCCESS',
  DELETE_WHATSNEW_FAIL: 'DELETE_WHATSNEW_FAIL',
  /* What's New */
  PDMASTERSHEET: 'PDMASTERSHEET',

  /* Wall Story */
  WALL_STORY_USERTYPE_REQUEST: 'WALL_STORY_USERTYPE_REQUEST',
  WALL_STORY_USERTYPE_SUCCESS: 'WALL_STORY_USERTYPE_SUCCESS',
  WALL_STORY_USERTYPE_FAIL: 'WALL_STORY_USERTYPE_FAIL',
  WALL_TAG_REQUEST: 'WALL_TAG_REQUEST',
  WALL_TAG_SUCCESS: 'WALL_TAG_SUCCESS',
  WALL_TAG_FAIL: 'WALL_TAG_FAIL',
  LIST_STORIES_REQUEST: 'LIST_STORIES_REQUEST',
  LIST_STORIES_SUCCESS: 'LIST_STORIES_SUCCESS',
  LIST_STORIES_FAIL: 'LIST_STORIES_FAIL',
  DELETE_STORIES_REQUEST: 'DELETE_STORIES_REQUEST',
  DELETE_STORIES_SUCCESS: 'DELETE_STORIES_SUCCESS',
  DELETE_STORIES_FAIL: 'DELETE_STORIES_FAIL',
  UPDATE_HIDDEN_STATUS_REQUEST: 'UPDATE_HIDDEN_STATUS_REQUEST',
  UPDATE_HIDDEN_STATUS_SUCCESS: 'UPDATE_HIDDEN_STATUS_SUCCESS',
  UPDATE_HIDDEN_STATUS_FAIL: 'UPDATE_HIDDEN_STATUS_FAIL',
  CREATE_STORIES_REQUEST: 'CREATE_STORIES_REQUEST',
  CREATE_STORIES_SUCCESS: 'CREATE_STORIES_SUCCESS',
  CREATE_STORIES_FAIL: 'CREATE_STORIES_FAIL',
  STORY_REDIRECTIONTYPE_REQUEST: 'STORY_REDIRECTIONTYPE_REQUEST',
  STORY_REDIRECTIONTYPE_SUCCESS: 'STORY_REDIRECTIONTYPE_SUCCESS',
  STORY_REDIRECTIONTYPE_FAIL: 'STORY_REDIRECTIONTYPE_FAIL',
  /* Wall Story */

  /*Animated Banner*/
  ANIMATED_BANNER_LISTS: 'ANIMATED_BANNER_LISTS',
  LOADER: 'LOADER',
  DELETE_ANIMATED_BANNER: 'DELETE_ANIMATED_BANNER',
  CREATE_ANIMATED_BANNER: 'CREATE_ANIMATED_BANNER',
  setFieldValue: 'wallSetFieldValue',
  /*Animated Banner*/

  /*JD*/
  getJobDescriptionDetails: 'getJobDescriptionDetails',
  setFieldValue: 'wallSetFieldValue',
  /*JD*/

  /*Drive Limit*/
  getDriveLimitUserLists: 'getDriveLimitUserLists',
  getDriveLimitDetails: 'getDriveLimitDetails',
  updateDriveLimit: 'updateDriveLimit',
  /*Drive Limit*/
};

export const THIRD_YEAR_WEBINAR = {
  LOADER: 'THIRD_YEAR_WEBINAR_LOADER',
  CLEAR_DATA: 'THIRD_YEAR_WEBINAR_CLEAR_DATA',
  CREATE_WEBINAR: 'CREATE_THIRD_YEAR_WEBINAR',
  UPDATE_WEBINAR: 'UPDATE_THIRD_YEAR_WEBINAR',
  DELETE_WEBINAR_BY_ID: 'DELETE_THIRD_YEAR_WEBINAR_BY_ID',
  GET_WEBINAR_BY_ID: 'GET_THIRD_YEAR_WEBINAR_BY_ID',
  GET_ALL_WEBINAR_LIST: 'GET_ALL_THIRD_YEAR_WEBINAR_LIST',
};

/*IBH Action Types*/
export const IBH = {
  LOADER: 'IBH_LOADER',
  CLEAR_DATA: 'IBH_CLEAR_DATA',
  getIbhRoundList: 'getIbhRoundList',
  CREATE_WEBINAR: 'CREATE_WEBINAR',
  UPDATE_WEBINAR: 'UPDATE_WEBINAR',
  GET_ALL_WEBINAR_LIST: 'GET_ALL_WEBINAR_LIST',
  DELETE_WEBINAR_BY_ID: 'DELETE_WEBINAR_BY_ID',
  GET_WEBINAR_BY_ID: 'GET_WEBINAR_BY_ID',
};
/*IBH Action Types*/

export const PREMIUM_USERS = {
  PREMIUM_USERS_UPLOAD_REQUEST: 'PREMIUM_USERS_UPLOAD_REQUESTED',
  PREMIUM_USERS_UPLOAD_SUCCESS: 'PREMIUM_USERS_UPLOAD_SUCCESSFUL',
  PREMIUM_USERS_UPLOAD_FAIL: 'PREMIUM_USERS_UPLOAD_FAILED',
};

export const CLSA = {
  CLEAR_DATA: 'CLSA_CLEAR_DATA',
  createClsaTest: 'createClsaTest',
  getClsaDetails: 'getClsaDetails',
  getClsaJobCategory: 'getClsaJobCategory',
  getClsaResultDetails: 'getClsaResultDetails',
  getVidBands: 'getVidBands',
  updateClsaResult: 'updateClsaResult',
  loader: 'clsaLoader',
  setFieldValue: 'clsaSetFieldValue',
  clsaMasterSheet: 'clsaMasterSheet',
};

export const EXAMSPACE = {
  loader: 'examSpaceLoader',
  getUniversity: 'getUniversity',
  getSemester: 'getSemester',
  createSemester: 'createSemester',
  getSubject: 'getSubject',
  createSubject: 'createSubject',
  getBranch: 'getBranch',
  examSpaceHomeDetails: 'examSpaceHomeDetails',
  deleteUniversityById: 'deleteUniversityById',
  createUniversityBranch: 'createUniversityBranch',
  setFieldValue: 'examSpaceSetFieldValue',
  getUniversityBranch: 'getUniversityBranch',
  deleteBranchById: 'deleteBranchById',
  createChapter: 'createChapter',
  getAllChapter: 'getAllChapter',
  deleteChapterById: 'deleteChapterById',
  removeSubject: 'removeSubject',
  removeSemester: 'removeSemester',
  getContent: 'getContent',
  listAllContents: 'listAllContents',
  deleteContent: 'deleteContent',
  saveVideo: 'saveVideo',
  saveFile: 'saveFile',
};

export const TEST = {
  LIST_REQUEST: 'TEST_LIST_REQUESTED',
  LIST_SUCCESS: 'TEST_LIST_SUCCESSFUL',
  LIST_FAIL: 'TEST_LIST_FAILED',

  DETAILS_REQUEST: 'TEST_DETAILS_REQUEST',
  DETAILS_SUCCESS: 'TEST_DETAILS_SUCCESS',
  DETAILS_FAIL: 'TEST_DETAILS_FAIL',

  TEST_CATEGORIES_REQUEST: 'TEST_CATEGORIES_REQUEST',
  TEST_CATEGORIES_SUCCESS: 'TEST_CATEGORIES_SUCCESS',
  TEST_CATEGORIES_FAIL: 'TEST_CATEGORIES_FAIL',

  DELETE_REQUEST: 'TEST_DELETE_REQUEST',
  DELETE_SUCCESS: 'TEST_DELETE_SUCCESS',
  DELETE_FAIL: 'TEST_DELETE_FAIL',

  CREATE_REQUEST: 'TEST_CREATE_REQUEST',
  CREATE_SUCCESS: 'TEST_CREATE_SUCCESS',
  CREATE_FAIL: 'TEST_CREATE_FAIL',
  CREATE_RESET: 'TEST_CREATE_RESET',

  UPDATE_REQUEST: 'TEST_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'TEST_UPDATE_SUCCESS',
  UPDATE_FAIL: 'TEST_UPDATE_FAIL',
  UPDATE_RESET: 'TEST_UPDATE_RESET',

  SCHEDULE_REQUEST: 'TEST_SCHEDULE_REQUEST',
  SCHEDULE_SUCCESS: 'TEST_SCHEDULE_SUCCESS',
  SCHEDULE_FAIL: 'TEST_SCHEDULE_FAIL',
  SCHEDULE_RESET: 'TEST_SCHEDULE_RESET',

  CUTOFF_REQUEST: 'CUTOFF_REQUEST',
  CUTOFF_SUCCESS: 'CUTOFF_SUCCESS',
  CUTOFF_FAIL: 'CUTOFF_FAIL',

  POST_TEST_UPLOAD_STATUS_REQUEST: 'POST_TEST_UPLOAD_STATUS_REQUEST',
  POST_TEST_UPLOAD_STATUS_SUCCESS: 'POST_TEST_UPLOAD_STATUS_SUCCESS',
  POST_TEST_UPLOAD_STATUS_FAIL: 'POST_TEST_UPLOAD_STATUS_FAIL',
};

export const PGA_REPORT = {
  loader: 'pgaLoader',
  getSpiderGraph: 'getSpiderGraph',
  postParseResume: 'postParseResume',
  getColleges: 'getColleges',
  getDepartments: 'getDepartments',
  getUniversity: 'getUniversity',
  getDegrees: 'getDegrees',
  getProfessionalCertificates: 'getProfessionalCertificates',
  getAcademicCertificates: 'getAcademicCertificates',
  getRelevantSkills: 'getRelevantSkills',
  getElectiveSubjects: 'getElectiveSubjects',
  getAwardHobby: 'getAwardHobby',
  generateCareerTracks: 'generateCareerTracks',
  addStudentCareerTrackDetails: 'addStudentCareerTrackDetails',
  profileScoreGenerate: 'profileScoreGenerate',
  getResumePdfPath: 'getResumePdfPath',
  getResumePdfDownloadUrl: 'getResumePdfDownloadUrl',
  getResumeQuestionnaire: 'getResumeQuestionnaire',
  postSpiderGraph: 'postSpiderGraph',
  careerTrackProfileSimilarity: 'careerTrackProfileSimilarity',
};

export const PROFILE_FIT_SPIDER_GRAPH = {
  loader: 'profileFitSpiderGraphLoader',
  getSpiderGraphQuestions: 'getSpiderGraphQuestions',
  putSpiderGraphAnswers: 'putSpiderGraphAnswers',
  getSpiderDetails: 'getSpiderDetails',
  getSpiderGraph: 'getSpiderGraph',
  updateRemark: 'updateRemark',
  spiderGraph: 'spiderGraph',
};

export const CV_REVIEW = {
  loader: 'cvReviewLoader',
  reviewCompleted: 'cvReviewCompleted',
  cvDownload: 'cvDownload',
  getStudentCvList: 'getStudentCvList',
  cvUpload: 'cvUpload',
  clearCustomData: 'cvClearCustomData',
};
export const UPLOAD_PGA_REPORT = {
  loader: 'pgaLoader',
  pgaReportUpload: 'pgaReportUpload',
  getStudentPgaList: 'getStudentPgaList',
  pgaDownload: 'pgaDownload',
};

export const STRATEGY_SESSION = {
  loader: 'strategySessionLoader',
  clearingData: 'strategySessionClearData',
  clearCustomData: 'strategySessionClearCustomData',
  getDocumentModelBySubStageId: 'getDocumentModelBySubStageId',
  postFileUploadBySubStageId: 'postFileUploadBySubStageId',
  putDocumentBySubStageId: 'putDocumentBySubStageId',
  getDownloadByDocumentId: 'getDownloadByDocumentId',
  deleteDocumentByDocumentId: 'deleteDocumentByDocumentId',
  uploadFile: 'gatTestTranscriptsUploadFile',
  getGreData: 'getTestTranscriptsGreData',
  updateGreData: 'updateTestTranscriptsGreData',
  getGmatData: 'getTestTranscriptsGmatData',
  updateGmatData: 'updateTestTranscriptsGmatData',
  getToeflData: 'getTestTranscriptsToeflData',
  updateToeflData: 'updateTestTranscriptsToeflData',
  getIeltsData: 'getTestTranscriptsIeltsData',
  updateIeltsData: 'updateTestTranscriptsIeltsData',
  getTestTranscriptFiles: 'getTestTranscriptFiles',
  getFilePath: 'getFilePath',
  // New File//
  getDownloadByDocumentFile: 'getDownloadByDocumentFile',
};

// Recommender Selcetor Tool //
export const RECOMMENDER_SELECTOR = {
  getRecommendersDetails: 'getRecommendersDetails',
  putRecommendersDetails: 'putRecommendersDetails',
  downloadRecommenderDetails: 'downloadRecommenderDetails',
};

export const PROFILE_MENTORING = {
  loader: 'profileMentoringLoader',
  clearData: 'profileMentoringClearData',
  clearCustomData: 'profileMentoringClearCustomData',
  getDocumentModelBySubStageId: 'getProfileMentoringDocumentModelBySubStageId',
  postFileUpload: 'postProfileMentoringFileUpload',
  putDocumentBySubStageId: 'putProfileMentoringDocumentBySubStageId',
  getDownloadByDocumentId: 'getProfileMentoringDownloadByDocumentId',
  downloadDocument: 'downloadDocument',
  deleteDocumentByDocumentId: 'deleteProfileMentoringDocumentByDocumentId',
  getCvId: 'getCvId',
  getPoints: 'getPoints',
  getQPMC2Report: 'getQPMC2Report',
  qpmcStageComplete: 'qpmcStageComplete',
  momPoints: 'momPoints',
  PostAdmitServicesStatus: 'PostAdmitServicesStatus',
  enableStage: 'enableStage',
  disableStage: 'disableStage',
};

export const POST_ADMIT_SERVICES = {
  loader: 'profileMentoringLoader',
  clearData: 'clearData',
  momPoints: 'momPoints',
  savePoints: 'savePoints',
  getDocuments: 'getDocuments',
  downloadDocuments: 'downloadDocuments',
  getCardDetails: 'getCardDetails',
  PostAdmitServicesDropDown: 'PostAdmitServicesDropDown',
  getCountry: 'getCountry',
  getRegion: 'getRegion',
  postFileUpload: 'postFileUpload',
  getFiles: 'getFiles',
  deleteFiles: 'deleteFiles',
};

export const APPLICATION_STAGE = {
  loader: 'applicationStageLoader',
  clearData: 'applicationStageClearData',
  clearCustomData: 'applicationStageClearCustomData',
  getDocumentModelBySubStageId: 'getApplicationStageDocumentModelBySubStageId',
  postFileUploadBySubStageId: 'postApplicationStageFileUploadBySubStageId',
  putDocumentBySubStageId: 'putApplicationStageDocumentBySubStageId',
  getDownloadByDocumentId: 'getApplicationStageDownloadByDocumentId',
  getSchoolList: 'getApplicationStageSchoolList',
  getMiscellaneousList: 'getApplicationStageMiscellaneousList',
};

export const CRUD_WEBINAR = {
  addWebinarLink: 'addWebinarLink',
  getWebinarLink: 'getWebinarLink',
  deleteWebinarLink: 'deleteWebinarLink',
  updateWebinarLink: 'updateWebinarLink',
  getTools: 'getTools',
  documentList: 'documentList',
  fileList: 'fileList',
  getdownloadFileCV: 'getdownloadFileCV',
  loader: 'true',
  clearCustomData: 'clearCustomData',
};

export const SCHOOL_RESEARCH = {
  getNumberOfPreferences: 'getNumberOfPreferences',
  getPreferenceListBasedOnPreferenceID: 'getPreferenceListBasedOnPreferenceID',
  addRecommendation: 'addRecommendation',
  getStageComplete: 'getStageComplete',
  getStageCalls: 'getStageCalls',
  clearData: 'clearData',
};

export const GRAD_SCHOOL = {
  getGradSchool: 'getGradSchool',
  getGradSchoolProgram: 'getGradSchoolProgram',
  setGradSchool: 'setGradSchool',
  createGradSchool: 'createGradSchool',
  getGradSchoolById: 'getGradSchoolById',
  getRegionList: 'getRegionList',
  getCountryList: 'getCountryList',
  getSubRegionList: 'getSubRegionList',
  getSearchOnGradSclList: 'getSearchOnGradSclList',
  createGradSchoolGmat: 'createGradSchoolGmat',
  gradSchoolDegree: 'gradSchoolDegree',
  gradSchoolProgramCategoryList: 'gradSchoolProgramCategoryList',
  gradSchoolProgramDegreeList: 'gradSchoolProgramDegreeList',
  gradSchoolProgramFieldOfStudyList: 'gradSchoolProgramFieldOfStudyList',
  gradSchoolProgramAreaOfSpecializationList: 'gradSchoolProgramAreaOfSpecializationList',
  gradSchoolProgramList: 'gradSchoolProgramList',
  createGradShoolRequirement: 'createGradShoolRequirement',
  createGradSchoolProgram: 'createGradSchoolProgram',
  createGradSchoolGre: 'createGradSchoolGre',
  createGradSchoolToefl: 'createGradSchoolToefl',
  createGradSchoolIelts: 'createGradSchoolIelts',
  getGradSchoolRequirements: 'getGradSchoolRequirements',
  getGradSchoolGmat: 'getGradSchoolGmat',
  getGradSchoolGre: 'getGradSchoolGre',
  getGradSchoolToefl: 'getGradSchoolToefl',
  getGradSchoolIelts: 'getGradSchoolIelts',
  clearData: 'clearData',
  getOneGradSchoolProgramList: 'getOneGradSchoolProgramList',
};

export const B_SCHOOL = {
  getAllBSchool: 'getAllBSchool',
  createBSchool: 'createBSchool',
  getBSchoolSubRegionById: 'getBSchoolSubRegionById',
  getBSchoolRegionList: 'getBSchoolRegionList',
  getBSchoolCountryList: 'getBSchoolCountryList',
  getAllBSchoolProgram: 'getAllBSchoolProgram',
  createPerBSchoolProgram: 'createPerBSchoolProgram',
  getPerBSchoolProgram: 'getPerBSchoolProgram',
  BSchool_clearData: 'BSchool_clearData',
  CreateBSchoolRank: 'CreateBSchoolRank',
  getAllBSchoolRank: 'getAllBSchoolRank',
  createBSchoolAptitude: 'createBSchoolAptitude',
  getAllbsRound: 'getAllbsRound',
  createBSchoolProgramRound: 'createBSchoolProgramRound',
  bs_seasons: 'bs_seasons',
  getPerBSchoolRound: 'getPerBSchoolRound',
  getAllBSchoolEssayQues: 'getAllBSchoolEssayQues',
  getPerBSchoolEssayQues: 'getPerBSchoolEssayQues',
  createBSchoolEssayQuestions: 'createBSchoolEssayQuestions',
  getPerAptitudeTestForBSchool: 'getPerAptitudeTestForBSchoolList',
  getAllAptitudeTestForBSchool: 'getAllAptitudeTestForBSchool',
  bSchoolDegree: 'bSchoolDegree',
  bSchoolProgramCategory: 'bSchoolProgramCategory',
  bSchoolAreaOfSpecialization: 'bSchoolAreaOfSpecialization',
  getBSRankId: 'getBSRankId',
};
