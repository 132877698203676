import React, { useEffect, useState } from "react";
import TableComponent from "../TableComponent/TableComponent";
import { createTheme, Grid, ThemeProvider } from "@material-ui/core";
import { addBSchool, bsRank } from "../RoutePaths";
import { makeStyles } from "@material-ui/core/styles";
import BackButton from "../../Asset/Images/backbutton.svg";
import { Breadcrumbs } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Typography } from "@material-ui/core";
import { studentPath } from "../RoutePaths";
import { listOfBSchoolRankList } from "../../Actions/BSchoolAction";
import { isEmptyString } from "../Validation";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
  },
}));
export default function BSRankIndex(props) {
  const { getAllBSchoolRankList } = useSelector(
    (state) => state.BSchoolReducer
  );
  const [bSchoolRank, setBSchoolRank] = useState([]);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [search, setSearch] = useState({
    size: 20,
    page: 0,
    keyword: "",
  });
  const col = [
    {
      title: "ID",
      fieldName: "id",
    },
    { title: "Serial No", fieldName: "serialNo" },
    { title: "Created By", fieldName: "createdBy" },
    { title: "Created Date", fieldName: "createdDate" },
    { title: "Updated By", fieldName: "updatedBy" },
    { title: "Updated Date", fieldName: "updatedDate" },
  ];

  const data = [
    { id: "1", name: "Booth School of Business" },
    { id: "2", name: "Samuel Curtis Johnson Graduate School of Management" },
  ];

  const tableTheme = () => {
    createTheme({
      palette: {
        primary: {
          main: "#007bff",
        },
      },
      overrides: {
        MuiTypography: {
          h6: {
            fontWeight: "bold",
          },
        },
        MuiIconButton: {
          root: {
            "&:hover": {
              backgroundColor: "none",
              borderRadius: 0,
            },
          },
        },
      },
    });
  };

  const handleClickOpen = () => {
    props.history.push({
      pathname: bsRank,
      state: { bschoolId: props.location.state.data.id, add: true },
    });
  };

  const handleEdit = (data) => {
    props.history.push({ pathname: bsRank, state: { data: data, edit: true } });
  };
  useEffect(() => {
    dispatch(
      listOfBSchoolRankList(0, 20, "", props?.location?.state?.data?.id)
    );
  }, []);
  useEffect(() => {
    if (getAllBSchoolRankList !== null) {
      setBSchoolRank(getAllBSchoolRankList.content);
    }
  }, [getAllBSchoolRankList]);

  const paginate = (page, size, keyword, id) => {
    setSearch({ ...search, page: page, size: size, keyword: keyword });
    if (page !== search.page || size !== search.size) {
      dispatch(
        listOfBSchoolRankList(
          page,
          size,
          keyword,
          props?.location?.state?.data?.id
        )
      );
    } else if (isEmptyString(keyword)) {
      dispatch(
        listOfBSchoolRankList(0, 20, "", props?.location?.state?.data?.id)
      );
    } else {
    }
  };

  const handleSearch = (e) => {
    dispatch(
      listOfBSchoolRankList(
        search.page,
        search.size,
        search.keyword,
        props.location.state.data.id
      )
    );
  };
  return (
    <React.Fragment>
      <div className={classes.root}>
        <div style={{ display: "flex", flexDirection: "row", margin: "10px" }}>
          <img
            src={BackButton}
            style={{ cursor: "pointer", marginTop: "-10px" }}
            onClick={() => props.history.goBack()}
          />
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Typography
              style={{
                cursor: "pointer",
                fontWeight: "600",
                marginLeft: "10px",
              }}
              onClick={() => props.history.push(studentPath)}
            >
              Back
            </Typography>
          </Breadcrumbs>
        </div>

        <ThemeProvider theme={tableTheme}>
          <div>
            <Grid container>
              <Grid item md={12}>
                <TableComponent
                  cols={col}
                  data={bSchoolRank}
                  title={"Rank"}
                  add={true}
                  action={true}
                  onAddClick={handleClickOpen}
                  BSRank={true}
                  totalCount={
                    getAllBSchoolRankList && getAllBSchoolRankList.totalElements
                  }
                  pageCount={
                    getAllBSchoolRankList && getAllBSchoolRankList.totalPages
                  }
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      document.getElementById("search").click();
                    }
                  }}
                  onEdit={true}
                  onEditClick={handleEdit}
                  onSearch={paginate}
                  onSearchClick={handleSearch}
                  paginate={paginate}
                  notNeedSearchPlace={true}
                />
              </Grid>
            </Grid>
          </div>
        </ThemeProvider>
      </div>
    </React.Fragment>
  );
}
