import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Box, Divider, Grid } from "@material-ui/core";
import LatexViewer from "../../../../Utils/LatexViewer";
import { Passage as passage } from "../../../../Assets/css/Preview/TestComponent";

export default function Binary(props) {
  const { choices, description, isHaveDescription, question } = props;
  // console.log(choices, "asiffsio");
  const { Question } = passage;
  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    console.log(event.target.value);
    setValue(event.target.value);
  };
  const style = {
    show: {
      display: "block",
    },
    hide: {
      display: "none",
    },
  };
  const { hide, show } = style;
  return (
    <Grid container id="test-container">
      <Grid
        item
        md={isHaveDescription === false ? 12 : 6}
        style={isHaveDescription === false ? hide : show}
        id="inside-test-container"
      >
        <Box display={"flex"} className={"overflow-scroll"} height={"inherit"}>
          <Box flex={1} className={"question-left-container"}>
            {description !== "" && (
              <Box fontSize={16} color={"#052A4E"} lineHeight={"30px"}>
                <LatexViewer math={description} />
              </Box>
            )}
          </Box>
          {isHaveDescription === false ? (
            ""
          ) : (
            <Box>
              <Divider variant="middle" orientation={"vertical"} />
            </Box>
          )}
        </Box>
      </Grid>
      <Grid
        item
        md={isHaveDescription === false ? 12 : 6}
        id="inside-test-container-right"
      >
        <Box
          minHeight={400}
          className={"overflow-scroll"}
          id="inside-test-container"
        >
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">
              <Question style={{ marginBottom: "15px" }}>
                <LatexViewer math={question} />
              </Question>
              <Box display={"flex"}>
                <Question>
                  <span>Yes &nbsp;&nbsp;&nbsp;&nbsp;</span>
                </Question>
                <Question>
                  <span>No</span>
                </Question>
              </Box>
            </FormLabel>
            {choices.map((item) => {
              console.log(item.correctChoice, "pp");
              return (
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item>
                    <RadioGroup
                      row
                      style={{ alignItems: "center" }}
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="row-radio-buttons-group"
                      value={
                        item.firstSelected === true
                          ? true
                          : item.secondSelected === true
                          ? false
                          : ""
                      }
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        style={{ marginBottom: "0px" }}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        style={{ marginRight: "0px", marginBottom: "0px" }}
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid
                    item
                    xs
                    style={{ alignSelf: "center", fontWeight: 600 }}
                  >
                    <LatexViewer math={item.text} />
                  </Grid>
                </Grid>
              );
            })}
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
}
