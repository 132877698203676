import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BackButton from "../../Asset/Images/backbutton.svg";
import { AppBar, Breadcrumbs, Tabs } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Typography } from "@material-ui/core";
import AddGradSchoolProgram from "./AddGradSchoolProgram";
import { Box } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import GradSchoolGre from "./GradSchoolGre";
import GradSchoolGmat from "./GradSchoolGmat";
import GradSchoolRequirements from "./GradSchoolRequirements";
import GradSchoolToefl from "./GradSchoolToefl";
import GradSchoolIelts from "./GradSchoolIelts";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
  },
}));
export default function AddGradSchoolProgramIndex(props) {
  const [value, setValue] = useState(0);
  const [gradSchoolProgramCreatedId, setGradSchoolProgramCreatedId] = useState(
    null
  );

  const gradSchoolProgramData = (data) => {
    setGradSchoolProgramCreatedId(data);
  };

  const classes = useStyles();

  const allProps = (index) => {
    return {
      id: `grad-school-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`grad-school-tab-${index}`}
        aria-labelledby={`simple-tabpanel-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div style={{ display: "flex", flexDirection: "row", margin: "10px" }}>
          <img
            src={BackButton}
            style={{ cursor: "pointer", marginTop: "-10px" }}
            onClick={() => props.history.goBack()}
          />
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Typography
              style={{
                cursor: "pointer",
                fontWeight: "600",
                marginLeft: "10px",
              }}
            >
              Back
            </Typography>
          </Breadcrumbs>
        </div>
        <AppBar position="static" color="default">
          <Tabs value={value} variant="fullWidth" onChange={handleChange}>
            <Tab label={"Grad School Program"} {...allProps(0)}></Tab>
            <Tab label={"Grad School Requirements"} {...allProps(1)}></Tab>
            <Tab label={"GRE"} {...allProps(2)}></Tab>
            <Tab label={"GMAT"} {...allProps(3)}></Tab>
            <Tab label={"TOEFL"} {...allProps(4)}></Tab>
            <Tab label={"IELTS"} {...allProps(5)}></Tab>
          </Tabs>

          <TabPanel value={value} index={0}>
            <AddGradSchoolProgram
              {...props}
              gradSchoolProgramData={gradSchoolProgramData}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <GradSchoolRequirements
              {...props}
              gradSchoolProgramCreatedId={gradSchoolProgramCreatedId}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <GradSchoolGre
              {...props}
              gradSchoolProgramCreatedId={gradSchoolProgramCreatedId}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <GradSchoolGmat
              {...props}
              gradSchoolProgramCreatedId={gradSchoolProgramCreatedId}
            />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <GradSchoolToefl
              {...props}
              gradSchoolProgramCreatedId={gradSchoolProgramCreatedId}
            />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <GradSchoolIelts
              {...props}
              gradSchoolProgramCreatedId={gradSchoolProgramCreatedId}
            />
          </TabPanel>
        </AppBar>
      </div>
    </React.Fragment>
  );
}
