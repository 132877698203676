import React, { useState, useEffect } from "react";
import { ButtonsContainer } from "../Assets/Styles/CreatePostStyles";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Grid,
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Paper,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { examSpacePath } from "../../RoutePaths";
import ConfirmDialog from "../../Utils/ConfirmDialog";
import Controls from "../../Utils/controls/Controls";
import Notification from "../../Utils/Notification";
import useTable from "../../Utils/useTable";
import { Form, Formik } from "formik";
import * as yup from "yup";
import {
  saveVideoById,
  listAllContents,
  deleteContent,
} from "../../../Actions/ExamSpaceAction";
import Loader from "../../Utils/controls/Loader";

const useStyles = makeStyles((theme) => ({
  rowNo: {
    width: "30px",
  },
  fileName: { width: "100px" },
  file: {
    width: "200px",
  },
  actions: {
    width: "200px",
    textAlign: "right",
  },
  btnStyle: {
    borderRadius: "4px",
    width: "140px",
  },
  btnContainer: {
    justifyContent: "flex-end !important",
    gap: "10px",
    marginTop: "30px !important",
  },
}));

const headCells = [
  { id: "#", label: "#", disableSorting: true },
  { id: "fileName", label: "File Name" },
  { id: "url", label: "URL", disableSorting: true },
  { id: "delete", label: "", disableSorting: true },
];

const VideoPage = ({ contentId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  let location = useLocation();
  const params = useParams();
  const search = location.search;
  const chapterId = new URLSearchParams(search).get("chapId");

  const [deleteClick, setDeleteClick] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const [videoError, setVideoError] = useState(false);
  const [state, setState] = useState({
    id: params?.id || null,
    fileName: "",
    url: "",
  });

  const { getAllContents, removeContent, loading } = useSelector(
    (state) => state.examSpaceReducer
  );

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    videoList,
    headCells,
    filterFn
  );

  useEffect(() => {
    if (chapterId) {
      dispatch(listAllContents(chapterId, contentId));
    }
  }, [dispatch, chapterId]);

  useEffect(() => {
    if (getAllContents) {
      if (getAllContents.success) {
        setVideoList(getAllContents?.data);
      } else {
        setVideoList([]);
      }
    }
  }, [getAllContents]);

  useEffect(() => {
    if (deleteClick && removeContent) {
      if (removeContent.success) {
        setNotify({
          isOpen: true,
          message: removeContent.message,
          type: "success",
        });
        setTimeout(() => {
          dispatch(listAllContents(chapterId, contentId));
        }, 700);
      } else {
        setNotify({
          isOpen: true,
          message: removeContent.message,
          type: "error",
        });
      }
      setDeleteClick(false);
    }
  }, [deleteClick, removeContent]);

  const handleDeleteClick = (item) => {
    setConfirmDialog({
      isOpen: true,
      title: "DELETE THIS?",
      subTitle: `Are you sure you want to delete the selected videoContent?
          It will go longer will be visible on the App.`,
      onConfirm: () => {
        onDelete(item.id);
      },
    });
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    dispatch(deleteContent(id));
    setDeleteClick(true);
  };

  const onDiscard = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    setTimeout(() => {
      history.push({
        pathname: examSpacePath,
        tab: 0,
      });
    }, 1200);
    setNotify({
      isOpen: true,
      message: "Discarded",
      type: "warning",
    });
  };

  const onSubmit = () => {
    setTimeout(() => {
      history.push({
        pathname: examSpacePath,
        tab: 0,
      });
    }, 1200);
    setNotify({
      isOpen: true,
      message: "Submitted, Redirecting to Homepage...",
      type: "success",
    });
  };

  // Video URL validation
  const isValidVideoLink = (str) => {
    var regexQuery =
      "^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$";
    var url = new RegExp(regexQuery, "i");

    if (url.test(str)) {
      setVideoError(true);
      return true;
    } else {
      setVideoError(false);
      return false;
    }
  };

  const validationSchema = yup.object({
    fileName: yup
      .string()
      .nullable()
      .required(),
    url: yup
      .string()
      .required()
      //.url()
      .test("is-valid-video-link", "Not a valid URL", (value) =>
        isValidVideoLink(value)
      ),
  });

  const handleDone = (fileName, url) => {
    let obj = {
      isVideo: true,
      url: url,
      name: fileName,
    };

    if (fileName.trim().length === 0 && url.trim().length === 0) {
      setNotify({
        isOpen: true,
        message: "Enter the file name & URL",
        type: "error",
      });
    } else if (fileName.trim().length === 0) {
      setNotify({
        isOpen: true,
        message: "Enter the file name",
        type: "error",
      });
    } else if (url.trim().length === 0) {
      setNotify({
        isOpen: true,
        message: "Enter the URL",
        type: "error",
      });
    } else if (videoError === false) {
      setNotify({
        isOpen: true,
        message: "Not a valid URL",
        type: "error",
      });
    } else {
      dispatch(
        saveVideoById(obj, chapterId, contentId, (res) => {
          if (res.success) {
            setNotify({
              isOpen: true,
              message: "Video Content created successfully",
              type: "success",
            });

            dispatch(listAllContents(chapterId, contentId));
            if (getAllContents) {
              if (getAllContents.success) {
                setVideoList(getAllContents?.data);
              } else {
                setVideoList([]);
              }
            }
          } else {
            setNotify({
              isOpen: true,
              message: res.message,
              type: "error",
            });
          }
        })
      );
    }
  };

  return (
    <>
      <Formik
        initialValues={state}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          resetForm({ values: "" });
        }}
        enableReinitialize
      >
        {({
          handleSubmit,
          errors,
          handleChange,
          values,
          touched,
          setFieldValue,
          submitForm,
        }) => {
          return (
            <>
              <Form onSubmit={handleSubmit} autoComplete='off'>
                <Grid
                  container
                  spacing={1}
                  style={{ margin: "30px 0 10px", alignItems: "center" }}
                >
                  <Grid item md={4}>
                    <Controls.Input
                      label='File Name'
                      name='fileName'
                      value={values.fileName}
                      onChange={handleChange}
                      error={touched.fileName && Boolean(errors.fileName)}
                      style={{
                        width: "80%",
                      }}
                    />
                  </Grid>

                  <Grid item md={4}>
                    <Controls.Input
                      label='URL'
                      name='url'
                      value={values.url}
                      error={touched.url && Boolean(errors.url)}
                      helperText={touched.url && errors.url}
                      style={{
                        width: "80%",
                      }}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs>
                    <ButtonsContainer
                      style={{ marginTop: "0px", justifyContent: "flex-end" }}
                    >
                      <Controls.Button
                        text={`Done`}
                        variant='contained'
                        color='primary'
                        className={classes.btnStyle}
                        type={"submit"}
                        onClick={() => handleDone(values.fileName, values.url)}
                      />
                    </ButtonsContainer>
                  </Grid>
                </Grid>
              </Form>

              <Grid container>
                <Grid item md={12}>
                  {!loading && getAllContents ? (
                    <TblContainer>
                      <TblHead />
                      {videoList && (
                        <TableBody>
                          {recordsAfterPagingAndSorting().map((item, index) => {
                            return (
                              <TableRow key={item.id}>
                                <TableCell className={classes.rowNo}>
                                  {index + 1}
                                </TableCell>
                                <TableCell className={classes.fileName}>{`${
                                  item.name === null ? "NA" : item.name
                                }`}</TableCell>
                                <TableCell className={classes.file}>
                                  <a
                                    href={item.url}
                                    target='_blank'
                                    rel='noreferrer noopener'
                                  >
                                    {`${item.url.slice(0, 50)}...`}
                                  </a>
                                </TableCell>
                                <TableCell className={classes.actions}>
                                  <Controls.ActionButton
                                    onClick={() => handleDeleteClick(item)}
                                  >
                                    <DeleteIcon
                                      fontSize='small'
                                      color='secondary'
                                    />
                                  </Controls.ActionButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      )}
                    </TblContainer>
                  ) : (
                    <Loader />
                  )}
                </Grid>
              </Grid>

              <ButtonsContainer className={classes.btnContainer}>
                <Controls.Button
                  text={`Cancel`}
                  variant='outlined'
                  color='primary'
                  className={classes.btnStyle}
                  onClick={() => {
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are you sure to discard this?",
                      subTitle: "You can't undo this operation",
                      onConfirm: () => {
                        onDiscard();
                      },
                    });
                  }}
                />
                <Controls.Button
                  text={`Submit`}
                  variant='contained'
                  color='primary'
                  className={classes.btnStyle}
                  onClick={() => onSubmit()}
                />
              </ButtonsContainer>
            </>
          );
        }}
      </Formik>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default VideoPage;
