import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Box, Divider, Grid } from "@material-ui/core";
import { Passage as passage } from "../../../../Assets/css/Preview/TestComponent";
import LatexViewer from "../../../../Utils/LatexViewer";
import { Typography } from "@mui/material";

export default function Twopart(props) {
  const {
    choices,
    description,
    isHaveDescription,
    question,
    firstSelectedName,
    secondSelectedName,
  } = props;
  // console.log(choices, "asiffsio");
  const { Question } = passage;
  const style = {
    show: {
      display: "block",
    },
    hide: {
      display: "none",
    },
  };
  const { hide, show } = style;
  return (
    <Grid container id="test-container">
      <Grid
        item
        md={isHaveDescription === false ? 12 : 6}
        style={isHaveDescription === false ? hide : show}
        id="inside-test-container"
      >
        <Box display={"flex"} className={"overflow-scroll"} height={"inherit"}>
          <Box flex={1} className={"question-left-container"}>
            {description !== "" && (
              <Box fontSize={16} color={"#052A4E"} lineHeight={"30px"}>
                <LatexViewer math={description} />
              </Box>
            )}
          </Box>
          {isHaveDescription === false ? (
            ""
          ) : (
            <Box>
              <Divider variant="middle" orientation={"vertical"} />
            </Box>
          )}
        </Box>
      </Grid>
      <Grid
        item
        md={isHaveDescription === false ? 12 : 6}
        id="inside-test-container-right"
      >
        <Box
          minHeight={400}
          className={"overflow-scroll"}
          id="inside-test-container"
        >
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">
              <Question style={{ marginBottom: "15px" }}>
                <LatexViewer math={question} />
              </Question>
            </FormLabel>
            <table className="two-part-table">
              <tr>
                <th>{firstSelectedName}</th>
                <th>{secondSelectedName}</th>
                <th></th>
              </tr>
              <tr>
                <td>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    {choices.map((item) => {
                      return (
                        <Grid item>
                          <RadioGroup
                            row
                            style={{ justifyContent: "center" }}
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="row-radio-buttons-group"
                            value={item.firstSelected}
                            // onChange={handleChange}
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              style={{ margin: "0px" }}
                            />
                          </RadioGroup>
                        </Grid>
                      );
                    })}
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    {choices.map((item) => {
                      return (
                        <Grid item>
                          <RadioGroup
                            row
                            style={{ justifyContent: "center" }}
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="row-radio-buttons-group"
                            value={item.secondSelected}
                            // onChange={handleChange}
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              style={{ margin: "0px" }}
                            />
                          </RadioGroup>
                        </Grid>
                      );
                    })}
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    {choices.map((choice, index) => (
                      <Typography
                        variant="h4"
                        color="textPrimary"
                        style={{
                          margin: "10px",
                          fontWeight:
                            choice.firstSelected || choice.secondSelected
                              ? 600
                              : "normal",
                        }}
                      >
                        {choice.text ? (
                          <LatexViewer math={choice.text} />
                        ) : (
                          <img src={choice.choiceImage} alt="" />
                        )}
                      </Typography>
                    ))}
                  </div>
                </td>
              </tr>
            </table>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
}
