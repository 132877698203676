import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@mui/material/Box";
import DataTable from "./DataTable";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import TimerIcon from "@mui/icons-material/Timer";
import DateRangePickerComp from "./DateRangePicker";
import { useDispatch, useSelector } from "react-redux";
import { driveStatusAlertData } from "../../../Actions/DashboardAction";
import Loader from "../../../Lms/Utils/Loader";
import Alert from "@mui/material/Alert";

const useStyle = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 16px 24px rgba(146, 170, 212, 0.12)",
    borderRadius: "8px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
  },
  textWithIcon: {
    display: "flex",
    alignItems: "center",
  },
  headerText: {
    fontSize: "18px",
    color: "#354354",
  },
  modalContainer: {
    textAlign: "center",
    alignItems: "center",
    width: "140px",
    position: "absolute",
    right: 0,
    top: -50,
    backgroundColor: "#fefefe",
    borderRadius: "8px",
  },
  btnText: {
    height: "25px",
    width: "100%",
  },
  buttonContainer: {
    border: "1px solid #E1E5EB",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    color: "#354354",
    width: "100px",
    height: "35px",
    cursor: "pointer",
  },
  icons: {
    fontSize: "6px",
    color: "#828D98",
  },
}));

const DashboardDriveStatus = () => {
  const classes = useStyle();
  const [startDate, setStartDate] = useState(
    new Date(new Date().setUTCHours(0, 0, 0))
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().setUTCHours(23, 59, 59))
  );
  const [value, setValue] = useState(false);

  const { loading, error, driveStatus } = useSelector(
    (state) => state.dashboardDriveStatusAlertReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    statusAlertCalling();
    setValue(false);
  }, [value]);

  let requestBody = {
    fromDate: startDate,
    toDate: endDate,
  };

  const statusAlertCalling = () => {
    dispatch(driveStatusAlertData(requestBody));
  };

  return (
    <Grid item xs={6}>
      <Paper elevation={3} className={classes.paper}>
        <div style={{ padding: "20px" }}>
          <Box className={classes.headerContainer}>
            <Box className={classes.textWithIcon}>
              <TimerIcon sx={{ color: "#8D82CD", fontSize: "28px" }} />
              <Typography
                fontWeight={"500"}
                marginLeft={2}
                className={classes.headerText}
              >
                Drive Status Alert
              </Typography>
            </Box>
            <Box>
              <DateRangePickerComp
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setValue={setValue}
              />
            </Box>
          </Box>
        </div>
        {driveStatus?.data && (
          <DataTable
            maxBodyHeight={345}
            minBodyHeight={345}
            columns={[
              {
                title: "#No",
                field: "srno",
                width: "10%",
                type: "numeric",
                align: "left",
                sorting: false,
                render: (rowData) => rowData.tableData.id + 1,
              },
              {
                title: "Drive Name",
                field: "eventName",
                width: "40%",
                sorting: false,
              },
              {
                title: "Stage",
                field: "stageStatus",
                align: "center",
                width: "30%",
                render: (rowData) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FiberManualRecordIcon
                      style={{
                        color:
                          rowData.stageStatus === "Need Assistance"
                            ? "#FF7284"
                            : "#E7D000",
                        fontSize: "12px",
                        marginRight: "6px",
                      }}
                    />
                    <div
                      style={{
                        color:
                          rowData.stageStatus === "Need Assistance"
                            ? "#BE0000"
                            : "#E7D000",
                        fontWeight: "300",
                      }}
                    >
                      {rowData.stageStatus}
                    </div>
                  </div>
                ),
                customSort: (a, b) => a.stageStatus.length - b.stageStatus.length,
              },
              {
                title: "Delay",
                field: "days",
                align: "center",
                width: "20%",
                sorting: false,
              },
            ]}
            data={driveStatus?.data}
          />
        )}
        <div style={{ margin: "2rem auto", width: "60%" }}>
          {loading && <Loader />}
          {error && <Alert severity="info">0 Tests Found</Alert>}
        </div>
      </Paper>
    </Grid>
  );
};

export default DashboardDriveStatus;
