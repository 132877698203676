import {
  Backdrop,
  Box,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Typo } from "../../Utils/controls/Styles";
import BottomContainer from "../../PgaReport/BottomContainer";

import { useStyles } from "./Styles";
import { PageWrapper } from "../../PgaReport/Components/StyledComponents";
import {
  getMOMPoints,
  updateMOMpoints,
} from "../../../Actions/ProfileMentoring";
import CustomizedSnackBars from "../../CustomizedSnackBars";
import MySnackBar from "../../MySnackBar";

function MomIndex(props) {
  console.log(props, "**************************hi");
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const { studentId, productId } = params;
  console.log(studentId, productId, "**********************student");
  const [state, setState] = useState({
    activeTabValue: null,
    open: false,
    comment: null,
    snackMessage: "",
    snackOpen: false,
    snackVariant: "",
    status: null,
    anchorEl: null,
    popoverComment: null,
    momQPMC1: null,
    momQPMC2: null,
  });
  const {
    activeTabValue,
    snackMsg,
    snackOpen,
    snackVariant,
    anchorEl,
    popoverComment,
    momQPMC1,
    momQPMC2,
  } = state;
  console.log(
    props.activeTabValue,
    "***********************activeTabValue****************"
  );
  const handleSave = () => {
    if (props.activeTabValue === "QPMC 1") {
      if (momQPMC1 && momQPMC1.trim().length !== 0) {
        if (momQPMC1) {
          let obj = {
            momPoints: momQPMC1,
          };
          dispatch(
            updateMOMpoints(studentId, productId, props?.activeStateValue, obj)
          );
        }
        setState({
          snackMsg: "Saved Successfully",
          snackOpen: true,
          snackVariant: "success",
        });
      }

      // else {
      //   setState({
      //     ...state,
      //     programLinkError: "Please Enter MOM Before Saving",
      //   });
      // }
    } else if (props.activeTabValue === "QPMC 2") {
      if (momQPMC2 && momQPMC2.trim().length !== 0) {
        if (momQPMC2) {
          let obj = {
            momPoints: momQPMC2,
          };
          dispatch(
            updateMOMpoints(studentId, productId, props?.activeStateValue, obj)
          );
        }
      }
      setState({
        snackMsg: "Saved Successfully",
        snackOpen: true,
        snackVariant: "success",
      });
    }
  };
  const handleSnackClose = () => {
    setState({
      ...state,
      snackOpen: false,
      snackMsg: "",
      snackVariant: "",
    });
  };
  // useEffect(() => {
  //   dispatch(getMOMPoints(studentId, productId, props?.activeStateValue));
  // }, []);
  useEffect(() => {
    dispatch(getMOMPoints(studentId, productId, props?.activeStateValue));
  }, [props.activeStateValue]);
  const { momPoints } = useSelector((state) => state.ProfileMentoringReducer);
  useEffect(() => {
    if (momPoints?.success) {
      setState({
        ...state,
        momQPMC1: momPoints?.data?.momPoints,
        momQPMC2: momPoints?.data?.momPoints,
      });
    }
  }, [momPoints]);
  console.log(
    momPoints?.data?.momPoints,
    "*************profileMentoringReducer"
  );
  useEffect(() => {
    if (props?.activeTabValue) {
      setState({
        ...state,
        momQPMC1: "",
        momQPMC2: "",
      });
    }
  }, [props?.activeTabValue]);
  return (
    <PageWrapper>
      {props.activeTabValue === "QPMC 1" && (
        <Grid container xs={12} className={classes.mainGrid}>
          <Grid item xs={12}>
            <div className={classes.heading}>QPMC 1 CALL - MOM</div>
            <Grid item xs={12} className={classes.textField}>
              <TextField
                value={state.momQPMC1}
                multiline
                onChange={(e) =>
                  setState({
                    ...state,
                    momQPMC1: e.target.value,
                  })
                }
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {props.activeTabValue === "QPMC 2" && (
        <Grid container className={classes.mainGrid}>
          <Grid item xs={12}>
            <div className={classes.heading}>QPMC 2 CALL - MOM</div>
            <Grid item xs={12} className={classes.textField}>
              <TextField
                value={state.momQPMC2}
                multiline
                onChange={(e) =>
                  setState({
                    ...state,
                    momQPMC2: e.target.value,
                  })
                }
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      <BottomContainer onClick={handleSave} />

      <MySnackBar
        onClose={handleSnackClose}
        snackOpen={snackOpen}
        snackVariant={snackVariant}
        snackMsg={snackMsg}
      />
    </PageWrapper>
  );
}

export default MomIndex;
