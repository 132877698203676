import React, { useState, useEffect } from "react";
import {
  Breadcrumbs,
  Grid,
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  ButtonsContainer,
  CreatePostContainer,
} from "../Assets/Styles/CreatePostStyles";
import { Formik, Form, FieldArray, Field } from "formik";
import Notification from "../../Utils/Notification";
import ConfirmDialog from "../../Utils/ConfirmDialog";
import CustomStepper from "../Components/CustomStepper";
import BackHandler from "../Components/BackHandler";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Loader from "../../Utils/controls/Loader";
import {
  chapterPath,
  examSpacePath,
  examSpaceUniversityPath,
  semesterPath,
} from "../../RoutePaths";
import useTable from "../../Utils/useTable";
import Controls from "../../Utils/controls/Controls";
import {
  createChapters,
  deleteChapterById,
  getAllChapters,
  setFieldValue,
} from "../../../Actions/ExamSpaceAction";

const useStyles = makeStyles((theme) => ({
  rowNo: {
    width: "30px",
  },
  chapter: {
    width: "200px",
  },
  actions: {
    width: "200px",
    textAlign: "right",
  },
  btnContainer: {
    justifyContent: "flex-end !important",
    gap: "10px",
    marginTop: "30px !important",
  },
  btnStyle: {
    borderRadius: "4px",
    width: "140px",
  },
  univBranchHeading: {
    color: "#001D3A",
    fontSize: "18px",
    margin: "0 !important",
  },
  breadCrumbsContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "0 10px",
  },
  newButton: {
    borderRadius: "26px",
    width: "160px",
    fontSize: "13px",
  },
  fieldStyle: {
    display: "flex",
  },
}));

const headCells = [
  { id: "#", label: "#", disableSorting: true },
  { id: "chapter", label: "Chapter Name" },
  { id: "actions", label: "", disableSorting: true },
];

const SubjectPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  let location = useLocation();
  const search = location.search;
  const subId = new URLSearchParams(search).get("subId");
  const univId = new URLSearchParams(search).get("univId");
  const univBranchId = new URLSearchParams(search).get("univBranchId");
  const subjectName = new URLSearchParams(search).get("subjectName");
  const branchName = new URLSearchParams(search).get("branch");
  const tabCount = new URLSearchParams(search).get("tabCount");

  const [deleteClick, setDeleteClick] = useState(false);
  const [chapterLists, setChapterLists] = useState([]);
  const [subjectId, setSubjectId] = useState("");
  const [state, setState] = useState({
    chapterList: [{ chapter: "", done: "" }],
  });
  const steps = ["University & Branch", "Semester & Subject", "Chapter"];

  const {
    createChapter,
    getAllChapter,
    deleteChapterStatus,
    loading,
  } = useSelector((state) => state.examSpaceReducer);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    chapterLists,
    headCells,
    filterFn
  );

  useEffect(() => {
    if (subId) {
      dispatch(getAllChapters(subId));
    }
  }, [dispatch, subId]);

  useEffect(() => {
    if (getAllChapter) {
      if (getAllChapter.success) {
        setChapterLists(getAllChapter?.data?.examCrackerChaptersList);
        setSubjectId(getAllChapter?.data?.subjectId);
      } else {
        setChapterLists([]);
      }
    }
  }, [getAllChapter]);

  useEffect(() => {
    if (createChapter) {
      if (createChapter.success) {
        setNotify({
          isOpen: true,
          message: createChapter.message,
          type: "success",
        });
        setTimeout(() => {
          dispatch(getAllChapters(subjectId));
        }, 700);
      } else {
        setNotify({
          isOpen: true,
          message: createChapter.message,
          type: "error",
        });
      }
      dispatch(setFieldValue("createChapter", null));
    }
  }, [createChapter]);

  useEffect(() => {
    if (deleteClick && deleteChapterStatus) {
      if (deleteChapterStatus.success) {
        setNotify({
          isOpen: true,
          message: deleteChapterStatus.message,
          type: "success",
        });
        setTimeout(() => {
          dispatch(getAllChapters(subjectId));
        }, 700);
      } else {
        setNotify({
          isOpen: true,
          message: deleteChapterStatus.message,
          type: "error",
        });
      }
      setDeleteClick(false);
    }
  }, [deleteClick, deleteChapterStatus]);

  const handleDeleteClick = (item) => {
    setConfirmDialog({
      isOpen: true,
      title: "DELETE CHAPTER?",
      subTitle: `Are you sure you want to delete the selected Chapter?
          It will go longer will be visible on the App.`,
      onConfirm: () => {
        onDelete(item.id);
      },
    });
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    dispatch(deleteChapterById(id));
    setDeleteClick(true);
  };

  const validate = (values) => {
    if (chapterLists === null) {
      setNotify({
        isOpen: true,
        message: "Create atleast one Chapter...",
        type: "error",
      });
      return false;
    }
    return true;
  };

  const handleNext = () => {
    let list = chapterLists ?? {};
    const chapter = Object.values(list).pop(); // return last value of the obj
    history.push(
      `${chapterPath}?univId=${univId}&univBranchId=${univBranchId}&subjectName=${subjectName}&subId=${subId}&branch=${branchName}&chapter=${chapter?.chapter}&chapId=${chapter?.id}`
    );
  };

  const openInPage = (item) => {
    history.push(
      `${chapterPath}?univId=${univId}&univBranchId=${univBranchId}&subjectName=${subjectName}&subId=${subId}&branch=${branchName}&chapter=${item?.chapter}&chapId=${item?.id}`
    );
  };

  const onDiscard = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    setTimeout(() => {
      history.push({
        pathname: examSpacePath,
        tab: 0,
      });
    }, 1200);
    setNotify({
      isOpen: true,
      message: "Discarded",
      type: "warning",
    });
  };

  /**
   * Handle to Save the chapter
   * @param {Array} chapterList
   * @param {Number} index
   * @param {Function} remove
   */
  const handleDone = (chapter, index, remove) => {
    if (chapter.trim().length === 0) {
      setNotify({
        isOpen: true,
        message: "Enter the Chapter name",
        type: "error",
      });
    } else {
      let payload = {
        subjectId: subjectId,
        chapter: chapter,
      };
      dispatch(createChapters(payload));
      remove(index);
    }
  };

  return (
    <>
      <div className={classes.breadCrumbsContainer}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />}>
          <Typography
            style={{
              cursor: "pointer",
              fontWeight: "500",
              color: "#1093FF",
            }}
            onClick={() => {
              history.push({
                pathname: examSpacePath,
              });
            }}
          >
            Exam Space
          </Typography>
          <Typography
            style={{
              cursor: "pointer",
              fontWeight: "500",
              color: "#1093FF",
            }}
            onClick={() => {
              history.push(`${examSpaceUniversityPath}?univId=${univId}`);
            }}
          >
            University
          </Typography>
          <Typography
            style={{
              cursor: "pointer",
              fontWeight: "500",
              color: "#1093FF",
            }}
            onClick={() => {
              history.push(
                `${semesterPath}?univId=${univId}&univBranchId=${univBranchId}&branch=${branchName}&tabCount=${tabCount}`
              );
            }}
          >
            Semester
          </Typography>
          <Typography style={{ fontWeight: "400", color: "#001D3A" }}>
            Subject
          </Typography>
        </Breadcrumbs>
      </div>
      <div>
        <BackHandler
          title={subjectName}
          pathname={semesterPath}
          search={`?univId=${univId}&univBranchId=${univBranchId}&branch=${branchName}&tabCount=${tabCount}`}
        />
      </div>

      <div>
        <CustomStepper stepsLabel={steps} activeStep={1} />
      </div>

      <CreatePostContainer style={{ padding: "0 20px 30px" }}>
        <div>
          {!loading && getAllChapter ? (
            <TblContainer>
              <TblHead />
              {chapterLists && (
                <TableBody>
                  {recordsAfterPagingAndSorting().map((item, index) => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell className={classes.rowNo}>
                          {index + 1}
                        </TableCell>
                        <TableCell
                          className={classes.chapter}
                        >{`${item.chapter}`}</TableCell>
                        <TableCell className={classes.actions}>
                          <Controls.ActionButton
                            onClick={() => openInPage(item)}
                          >
                            <EditOutlinedIcon
                              fontSize='small'
                              color='primary'
                            />
                          </Controls.ActionButton>
                          <Controls.ActionButton
                            onClick={() => handleDeleteClick(item)}
                          >
                            <DeleteIcon fontSize='small' color='secondary' />
                          </Controls.ActionButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </TblContainer>
          ) : (
            <Loader />
          )}
        </div>

        <Formik
          initialValues={state}
          onSubmit={(values, { resetForm }) => {
            if (validate(values)) {
              handleNext();
            }
          }}
          enableReinitialize
        >
          {({
            handleSubmit,
            errors,
            handleChange,
            values,
            touched,
            setFieldValue,
            submitForm,
          }) => {
            return (
              <>
                <div className='CreatePost'>
                  <Form onSubmit={handleSubmit} autoComplete='off'>
                    <div style={{ marginTop: "30px" }}>
                      <FieldArray
                        name='chapterList'
                        className={classes.fieldStyle}
                        render={({ insert, remove, push }) => (
                          <Grid
                            container
                            spacing={1}
                            direction='column'
                            style={{ margin: 0 }}
                          >
                            {values.chapterList.map((key, index) => (
                              <>
                                <Grid
                                  container
                                  style={{
                                    alignItems: "center",
                                    marginBottom: "15px",
                                  }}
                                >
                                  <Grid item md={3}>
                                    <Controls.Input
                                      label='Chapter'
                                      name={`chapterList.${index}.chapter`}
                                      fullWidth
                                      value={values.chapterList[index].chapter}
                                      onChange={handleChange}
                                    />
                                  </Grid>

                                  <Grid item md={2}>
                                    <Controls.ActionButton
                                      onClick={() => remove(index)}
                                    >
                                      <DeleteIcon
                                        fontSize='small'
                                        color='secondary'
                                      />
                                    </Controls.ActionButton>
                                  </Grid>

                                  <Grid item xs>
                                    <ButtonsContainer
                                      style={{ justifyContent: "flex-end" }}
                                    >
                                      <Controls.Button
                                        name={`chapterList.${index}.done`}
                                        text={`Done`}
                                        variant='contained'
                                        color='primary'
                                        className={classes.btnStyle}
                                        onClick={() =>
                                          handleDone(
                                            values.chapterList[index].chapter,
                                            index,
                                            remove
                                          )
                                        }
                                      />
                                    </ButtonsContainer>
                                  </Grid>
                                </Grid>
                              </>
                            ))}
                            <Controls.Button
                              text='Add Chapter'
                              variant='contained'
                              color='primary'
                              startIcon={<AddIcon />}
                              className={classes.newButton}
                              onClick={() => push({ chapter: "", done: "" })}
                            />
                          </Grid>
                        )}
                      />
                    </div>
                  </Form>
                </div>
                <ButtonsContainer className={classes.btnContainer}>
                  <Controls.Button
                    text={`Cancel`}
                    variant='outlined'
                    color='primary'
                    className={classes.btnStyle}
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to discard this?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDiscard();
                        },
                      });
                    }}
                  />
                  <Controls.Button
                    text={`Next`}
                    variant='contained'
                    color='primary'
                    className={classes.btnStyle}
                    type={"submit"}
                    onClick={submitForm}
                  />
                </ButtonsContainer>
              </>
            );
          }}
        </Formik>
      </CreatePostContainer>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default SubjectPage;
