import { Box, Typography } from "@material-ui/core";
import React from "react";
import {
  DescriptionBox,
  DescriptionHeader,
  Grid,
  OutlineLeft,
  OutlineRight,
  TextBox,
  DividerLine,
  TextWrapper,
  TextUnitField,
  TextBoxValueShow,
  Center,
  Centar,
  ChoiceDiv,
  TextBoxField,
} from "../../../../../../Assets/css/Preview/GreStyles";
import {
  Passage as passage,
  Div,
} from "../../../../../../Assets/css/Preview/TestComponent";
import Latex from "../../../../../../Utils/LatexViewer";
import Bundle from "./Bundle";
import SingleMulti from "./SingleMulti";
import { maxHeight } from "@material-ui/system";

const Passage = ({
  question,
  choices,
  bundle,
  selectedChoice,
  subjective,
  optionalType,
  fraction,
  bundleLength,
  answer,
  description,
  imgUrl,
  isMulti,
  isCalculator,
  bottomText,
  topText,
  isHaveDescription,
}) => {
  const { Para, Question } = passage;
  const style = {
    show: {
      display: "block",
    },
    hide: {
      display: "none",
    },
  };
  const { hide, show } = style;
  return (
    <Grid container style={{ maxHeight: "100vh" }}>
      <Grid
        item
        md={
          (subjective &&
            isHaveDescription === false &&
            optionalType === "NUMERICAL_ENTRY") ||
          (fraction && isHaveDescription === false)
            ? 12
            : 6
        }
      >
        <OutlineLeft>
          <DescriptionHeader>
            Question 7 is based on this passage.
          </DescriptionHeader>
          <DescriptionBox>
            <Box flex={1} className={"question-left-container"}>
              {/* {subjective && (
              <Typography variant="h6" color="textPrimary">
                <Latex math={question} />
              </Typography>
            )}
            {fraction && (
              <Typography variant="h6" color="textPrimary">
                <Latex math={question} />
              </Typography>
            )} */}
              {description !== "" && (
                <Typography variant="h5" color="textPrimary">
                  <Latex math={description} />{" "}
                </Typography>
              )}

              {subjective &&
                // optionalType === "NUMERICAL_ENTRY" &&
                isHaveDescription === false && (
                  <Typography variant="h5" color="textPrimary">
                    <Latex math={question} />
                  </Typography>
                )}
              {optionalType === "NUMERICAL_ENTRY" &&
                isHaveDescription === false && (
                  <div style={{ display: "flex", marginTop: "2em" }}>
                    <TextBoxValueShow value={null} type={"text"} />
                    <TextUnitField style={{ marginTop: "10px" }}>
                      {choices[0]?.unit}
                    </TextUnitField>
                  </div>
                )}
              {fraction && isHaveDescription === false && (
                <>
                  <Typography variant="h5" color="textPrimary">
                    <Latex math={question} />
                  </Typography>
                  <div style={{ display: "flex", marginTop: "2rem" }}>
                    <div>
                      <TextBoxValueShow
                        // value={choices[0]?.text}
                        value={null}
                        type={"number"}
                      />

                      <DividerLine />
                      <TextBoxValueShow
                        // value={choices[0]?.text1}
                        value={null}
                        type={"number"}
                      />
                    </div>
                    <TextUnitField>{choices[0]?.unit}</TextUnitField>
                  </div>
                </>
              )}
              <Box>
                {imgUrl && (
                  <img src={imgUrl} alt={""} width={"100%"} height={"100%"} />
                )}
              </Box>
            </Box>
          </DescriptionBox>
        </OutlineLeft>
      </Grid>
      <Grid
        item
        md={6}
        style={
          (subjective &&
            isHaveDescription === false &&
            optionalType === "NUMERICAL_ENTRY") ||
          (fraction && isHaveDescription === false)
            ? hide
            : show
        }
      >
        <OutlineRight>
          {bundle ? (
            <Bundle
              bundleLength={bundleLength}
              choices={choices}
              selectedChoice={selectedChoice}
              question={question}
              isCalculator={isCalculator}
              bottomText={bottomText}
              topText={topText}
            />
          ) : subjective ? (
            optionalType === "NUMERICAL_ENTRY" ? (
              <React.Fragment>
                <TextWrapper>
                  {subjective && isHaveDescription === true ? (
                    <Question>
                      <Latex math={question} />
                    </Question>
                  ) : (
                    <></>
                  )}
                  <div style={{ display: "flex", marginTop: "2rem" }}>
                    <TextBoxValueShow value={null} type={"text"} />
                    <TextUnitField style={{ marginTop: "10px" }}>
                      {choices[0]?.unit}
                    </TextUnitField>
                  </div>
                </TextWrapper>
              </React.Fragment>
            ) : (
              <>
                <Box>
                  <Div>
                    {isHaveDescription === true && (
                      <Centar>
                        <Typography variant="h5" color="textPrimary">
                          <Latex math={question} />
                        </Typography>
                      </Centar>
                    )}

                    <Box>
                      {imgUrl && (
                        <img
                          src={imgUrl}
                          alt={""}
                          width={"100%"}
                          height={"100%"}
                        />
                      )}
                    </Box>
                  </Div>
                </Box>
                {optionalType === null && (
                  <TextBox
                    type={"text"}
                    placeholder={"Enter your answer here"}
                    // value={answer}
                    value={null}
                    disabled
                  />
                )}
              </>
            )
          ) : fraction ? (
            <React.Fragment>
              <TextWrapper>
                {fraction && isHaveDescription === true && (
                  <Question>
                    <Latex math={question} />
                  </Question>
                )}
                <div style={{ display: "flex", marginTop: "2rem" }}>
                  <div>
                    <TextBoxValueShow
                      // value={choices[0]?.text}
                      value={null}
                      type={"number"}
                    />

                    <DividerLine />
                    <TextBoxValueShow
                      // value={choices[0]?.text1}
                      value={null}
                      type={"number"}
                    />
                  </div>
                  <TextUnitField>{choices[0]?.unit}</TextUnitField>
                </div>
              </TextWrapper>
            </React.Fragment>
          ) : (
            <SingleMulti
              question={question}
              options={choices}
              selectedChoice={selectedChoice}
              isMulti={isMulti}
              isCalculator={isCalculator}
              bottomText={bottomText}
              topText={topText}
            />
          )}
        </OutlineRight>
      </Grid>
    </Grid>
  );
};

export default Passage;
