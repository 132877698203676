import { GRAD_SCHOOL } from "../Redux/Action";

const initialState = {
  getGradSchoolList: {},
  AddedGradSchool: null,
  getPerGradSchoolDetails: null,
  getRegionList: null,
  getListOfCountry: null,
  getSearchedListOnGradScl: null,
  insertGmat: null,
  getGradSchoolDegree: null,
  getGradSchoolProgramCategoryLists: null,
  getGradSchoolProgramDegreeLists: null,
  getGradSchoolProgramFieldOfStudyLists: [],
  getGradSchoolProgramAreaOfSpecializationLists: [],
  getGradSchoolProgramLists: [],
  insertGradSchoolProgram: null,
  getPerGradSchoolProgramList: null,
  getGradSchoolRequirementReducer: null,
  getGradSchoolGmatReducer: null,
  getGradSchoolGreReducer: null,
  getGradSchoolToeflReducer: null,
  getGradSchoolIeltsReducer: null,
  getPerGradSchoolProgramData: null,
  getSubRegionList: null,
};
export const GradSchoolReducer = (state = initialState, action) => {
  switch (action.type) {
    case GRAD_SCHOOL.getGradSchool:
      return {
        ...state,
        getGradSchoolList: action.payload,
      };
    case GRAD_SCHOOL.createGradSchool:
      return {
        ...state,
        AddedGradSchool: action.payload,
      };
    case GRAD_SCHOOL.getGradSchoolById:
      return {
        ...state,
        getPerGradSchoolDetails: action.payload,
      };
    case GRAD_SCHOOL.getRegionList:
      return {
        ...state,
        getRegionList: action.payload,
      };
    case GRAD_SCHOOL.getCountryList:
      return {
        ...state,
        getListOfCountry: action.payload,
      };
    case GRAD_SCHOOL.getSearchOnGradSclList:
      return {
        ...state,
        getSearchedListOnGradScl: action.payload,
      };
    case GRAD_SCHOOL.getSubRegionList:
      return {
        ...state,
        getSubRegionList: action.payload,
      };

    case GRAD_SCHOOL.createGradSchoolGmat: {
      return {
        ...state,
        insertGmat: action.payload,
      };
    }
    case GRAD_SCHOOL.gradSchoolDegree:
      return {
        ...state,
        getGradSchoolDegree: action.payload,
      };
    case GRAD_SCHOOL.gradSchoolProgramCategoryList:
      return {
        ...state,
        getGradSchoolProgramCategoryLists: action.payload,
      };
    case GRAD_SCHOOL.gradSchoolProgramDegreeList:
      return {
        ...state,
        getGradSchoolProgramDegreeLists: action.payload,
      };
    case GRAD_SCHOOL.gradSchoolProgramFieldOfStudyList:
      return {
        ...state,
        getGradSchoolProgramFieldOfStudyLists: action.payload,
      };
    case GRAD_SCHOOL.gradSchoolProgramAreaOfSpecializationList:
      return {
        ...state,
        getGradSchoolProgramAreaOfSpecializationLists: action.payload,
      };
    case GRAD_SCHOOL.gradSchoolProgramList:
      return {
        ...state,
        getGradSchoolProgramLists: action.payload,
      };
    case GRAD_SCHOOL.createGradSchoolProgram:
      return {
        ...state,
        insertGradSchoolProgram: action.payload,
      };
    case GRAD_SCHOOL.getGradSchoolProgram:
      return {
        ...state,
        getPerGradSchoolProgramList: action.payload,
      };
    case GRAD_SCHOOL.getGradSchoolRequirements:
      return {
        ...state,
        getGradSchoolRequirementReducer: action.payload,
      };
    case GRAD_SCHOOL.getGradSchoolGmat:
      return {
        ...state,
        getGradSchoolGmatReducer: action.payload,
      };
    case GRAD_SCHOOL.getGradSchoolGre:
      return {
        ...state,
        getGradSchoolGreReducer: action.payload,
      };
    case GRAD_SCHOOL.getGradSchoolToefl:
      return {
        ...state,
        getGradSchoolToeflReducer: action.payload,
      };
    case GRAD_SCHOOL.getGradSchoolIelts:
      return {
        ...state,
        getGradSchoolIeltsReducer: action.payload,
      };
    case GRAD_SCHOOL.getOneGradSchoolProgramList:
      return {
        ...state,
        getPerGradSchoolProgramData: action.payload,
      };
    case GRAD_SCHOOL.clearData:
      return {
        ...state,
        getGradSchoolRequirementReducer: null,
        getGradSchoolGmatReducer: null,
        getGradSchoolGreReducer: null,
        getGradSchoolToeflReducer: null,
        getGradSchoolIeltsReducer: null,
        getPerGradSchoolProgramData: null,
        getListOfCountry: null,
      };
    default:
      break;
  }
  return state;
};
