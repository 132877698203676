import React, { useEffect, useState } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ThumbUp from '@material-ui/icons/ThumbUp';
import FilterIcon from '@material-ui/icons/Tune';
import DescriptionIcon from '@mui/icons-material/Description';
import SortIcon from '@material-ui/icons/CompareArrows';
import BackHandler from './Components/BackHandler';
import SearchIcon from '@material-ui/icons/SearchSharp';
import ExportIcon from '@material-ui/icons/GetApp';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import ClearIcon from '@material-ui/icons/Clear';
import Loader from '../Utils/controls/Loader';
import { Button, InputAdornment, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  clearData,
  clsaSheetDownload,
  getClsaResultDetails,
  getVidBanding,
  setFieldValue,
  updateResult,
} from '../../Actions/ClsaActions';
import Notification from '../Utils/Notification';
import { Check, ChevronLeft, ChevronRight, Edit, Search } from '@material-ui/icons';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import { Add } from '@mui/icons-material';
import Controls from '../Utils/controls/Controls';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateRangePickerComp from './Components/DateRangePicker';
import RefreshIcon from '@mui/icons-material/Refresh';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles((theme) => ({
  searchInput: {
    width: '80%',
    marginLeft: 30,
  },
}));

function ClsaResult() {
  const classes = useStyles();
  const [noOfDriveTaken, setNoOfDriveTaken] = useState('all');
  const [sslcValue, setSslcValue] = useState('all');
  const [hscValue, setHscValue] = useState('all');
  const [ugValue, setUgValue] = useState('all');
  const [quantitative, setQuantitative] = useState('all');
  const [quantitativeScore, setQuantitativeScore] = useState('all');
  const [problemSolving, setProblemSolving] = useState('all');
  const [problemSolvingScore, setProblemSolvingScore] = useState('all');
  const [codeAnalysis, setCodeAnalysis] = useState('all');
  const [codeAnalysisScore, setCodeAnalysisScore] = useState('all');
  const [aptitude, setAptitude] = useState('all');
  const [aptitudeScore, setAptitudeScore] = useState('all');
  const [overallScore, setOverallScore] = useState('all');
  const [videoBands, setVideoBands] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [tableData, setTableData] = useState([]);
  const [clsaTitle, setClsaTitle] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const [pageSize, setPageSize] = useState(100);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [page, setPage] = useState(0);
  const [options, setOptions] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [date, setDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [masterReportIsVisible, setMasterReportIsVisible] = useState();
  const [clsaHead, setClsaHead] = useState('');
  const [startDate, setStartDate] = useState(new Date(new Date().setUTCHours(0, 0, 0)));
  const [endDate, setEndDate] = useState(new Date(new Date().setUTCHours(23, 59, 59)));
  const [value, setValue] = useState(false);
  const { getClsaResult, getVideoBand, updateStatus, loading } = useSelector(
    (state) => state.ClsaReducer
  );

  useEffect(() => {
    dispatch(getVidBanding(id));
    return () => {
      // Clearing all the data in the reducer while left from that page like ComponentWillUnmount.
      dispatch(clearData());
    };
  }, []);

  useEffect(() => {
    if (getVideoBand) {
      if (getVideoBand.success) {
        setOptions(getVideoBand?.data?.videoBandRating);
        setClsaHead(getVideoBand?.data?.clsaTitle);
      } else {
        setOptions([]);
      }
    }
  }, [getVideoBand]);

  useEffect(() => {
    if (updateStatus) {
      if (updateStatus.success) {
        if (loading) {
          setNotify({
            isOpen: true,
            message: 'Updated Successfully!',
            type: 'success',
          });
          setIsLoading(true);
          let requestBody = {
            fromDate: startDate,
            toDate: endDate,
          };
          dispatch(
            getClsaResultDetails(id, 0, pageSize, '', requestBody, (response) => {
              setClsaTitle(response?.data?.clsaTitle);
              setTotalElements(response?.data?.totalElements ?? 0);
              setTableData(response?.data?.clsaTestResultPageModel ?? []);
              setFilteredData(response?.data?.clsaTestResultPageModel ?? []);
              setIsLoading(false);
            })
          );
          setPageNo(1);
        }
      } else {
        setNotify({
          isOpen: true,
          message: updateStatus.message,
          type: 'error',
        });
      }
    }
  }, [updateStatus, loading]);

  useEffect(() => {
    if (tableData.length < totalElements) {
      setIsLoading(true);
      fetchMoreStudentList(1);
    } else {
      setIsLoading(false);
    }
  }, [tableData]);

  //Load More Students
  const fetchMoreStudentList = (number, text = '') => {
    setIsLoading(true);
    setPageNo((prev) => prev + number);
    let requestBody = {
      fromDate: startDate,
      toDate: endDate,
    };
    dispatch(
      getClsaResultDetails(id, pageNo, pageSize, text, requestBody, (response) => {
        setClsaTitle(response?.data?.clsaTitle);
        setTotalElements(response?.data?.totalElements ?? 0);
        setMasterReportIsVisible(response?.data?.isEnableMasterReport);
        setTableData([...(tableData || []), ...(response?.data?.clsaTestResultPageModel ?? [])]);
        setFilteredData([...(tableData || []), ...(response?.data?.clsaTestResultPageModel ?? [])]);
        setIsLoading(false);
      })
    );
    setValue(false);
  };

  const handleSearch = (text) => {
    setIsLoading(true);
    setSearchValue(text);
    setPageNo(1);
    let requestBody = {
      fromDate: startDate,
      toDate: endDate,
    };
    dispatch(
      getClsaResultDetails(id, 0, pageSize, text, requestBody, (response) => {
        setClsaTitle(response?.data.clsaTitle);
        setTotalElements(response?.data?.totalElements ?? 0);
        setTableData(response?.data?.clsaTestResultPageModel ?? []);
        setFilteredData(response?.data?.clsaTestResultPageModel ?? []);
        setIsLoading(false);
      })
    );
  };

  // Field names in the columns variable must be same as the KEY of the data variable.
  let columns = [
    {
      title: 'ID',
      field: 'studentId',
      hidden: true,
    },
    {
      title: 'Date of test taken',
      field: 'dateOfTestTaken',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      type: 'date',
      dateSetting: {
        locale: 'en-GB',
      },
      filterComponent: () => (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            margin='normal'
            id='date-picker-dialog'
            format='dd/MM/yyyy'
            clearable
            InputAdornmentProps={{ position: 'start' }}
            value={date}
            onChange={(event) => {
              setDate(event);
              let filteredData = tableData.filter(
                (student) =>
                  new Date(student.dateOfTestTaken).getDate() === new Date(event).getDate() ||
                  !event
              );
              setFilteredData(filteredData);
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      ),
    },
    {
      title: 'Candidate Name',
      field: 'fullName',
      filtering: false,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'No. of Drives taken',
      field: 'noOfDriveTaken',
      cellStyle: {
        textAlign: 'center',
      },
      filterComponent: () => (
        <Select
          id='noOfDriveTaken'
          style={{ width: 70 }}
          value={noOfDriveTaken}
          onChange={(e) => {
            setNoOfDriveTaken(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.noOfDriveTaken) >= e.target.value || e.target.value === 'all'
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={'all'}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={60}>60</MenuItem>
          <MenuItem value={90}>90</MenuItem>
          <MenuItem value={120}>120</MenuItem>
          <MenuItem value={150}>150</MenuItem>
          <MenuItem value={180}>180</MenuItem>
          <MenuItem value={200}>200</MenuItem>
        </Select>
      ),
      emptyValue: () => <em>--</em>,
      render: (rowData) => <p style={{ width: '80px' }}>{rowData.noOfDriveTaken}</p>,
    },
    {
      title: 'Elev8 Premium',
      field: 'elev8Premium',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div>{rowData.elev8Premium === false ? 'No' : 'Yes'}</div>,
      lookup: { true: 'Yes', false: 'No' },
    },
    {
      title: 'Date of Birth',
      field: 'dateOfBirth',
      filtering: false,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      type: 'date',
      dateSetting: {
        format: 'dd-MM-yyyy',
        locale: 'en-GB',
      },
    },
    {
      title: 'Gender',
      field: 'gender',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      lookup: { male: 'Male', female: 'Female', others: 'Others' },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Contact Number',
      field: 'phoneNumber',
      filtering: false,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
    },
    {
      title: 'Email',
      field: 'emailId',
      filtering: false,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Education Gap',
      field: 'educationGap',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      lookup: { Yes: 'Yes', No: 'No' },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Fresher/Experience',
      field: 'experienceStatus',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      lookup: { Fresher: 'Fresher', Experienced: 'Experienced' },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '10th Passing year',
      field: 'sscPassingYear',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div>{new Date(rowData.sscPassingYear).getFullYear()}</div>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '12th Passing year',
      field: 'hscPassingYear',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div>{new Date(rowData.hscPassingYear).getFullYear()}</div>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Graduation Qualification',
      field: 'graduationQualification',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Branch',
      field: 'branch',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
    },
    {
      title: 'College',
      field: 'collegeName',
      filtering: false,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) => <div style={{ width: '100px' }}>{rowData.collegeName}</div>,
    },
    {
      title: 'Year Of Passing',
      field: 'yearOfPassing',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div>{new Date(rowData.yearOfPassing).getFullYear()}</div>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Degree %',
      field: 'degreePercentage',
      editable: false,
      cellStyle: {
        textAlign: 'center',
      },
      filterComponent: () => (
        <Select
          id='degreePercentage'
          style={{ width: 70 }}
          value={ugValue}
          onChange={(e) => {
            setUgValue(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.degreePercentage) >= e.target.value || e.target.value === 'all'
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={'all'}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={6}>6</MenuItem>
          <MenuItem value={7}>7</MenuItem>
          <MenuItem value={8}>8</MenuItem>
          <MenuItem value={9}>9</MenuItem>
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={60}>60%</MenuItem>
          <MenuItem value={70}>70%</MenuItem>
          <MenuItem value={80}>80%</MenuItem>
          <MenuItem value={90}>90%</MenuItem>
        </Select>
      ),
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div style={{ width: '70px' }}>{rowData.degreePercentage}</div>,
    },
    {
      title: 'Do you have any active backlogs?',
      field: 'activeBacklogsStatus',
      cellStyle: {
        textAlign: 'center',
      },
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      lookup: { Yes: 'Yes', No: 'No' },
    },
    {
      title: '10%',
      field: 'sscPercentage',
      editable: false,
      cellStyle: {
        textAlign: 'center',
      },
      filterComponent: () => (
        <Select
          id='sscPercentage'
          style={{ width: 70 }}
          value={sslcValue}
          onChange={(e) => {
            setSslcValue(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.sscPercentage) >= e.target.value || e.target.value === 'all'
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={'all'}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={60}>60%</MenuItem>
          <MenuItem value={70}>70%</MenuItem>
          <MenuItem value={80}>80%</MenuItem>
          <MenuItem value={90}>90%</MenuItem>
        </Select>
      ),
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div style={{ width: '70px' }}>{rowData.sscPercentage}</div>,
    },
    {
      title: '12%',
      field: 'hscPercentage',
      editable: false,
      cellStyle: {
        textAlign: 'center',
      },
      filterComponent: () => (
        <Select
          id='hscPercentage'
          style={{ width: 70 }}
          value={hscValue}
          onChange={(e) => {
            setHscValue(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.hscPercentage) >= e.target.value || e.target.value === 'all'
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={'all'}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={60}>60%</MenuItem>
          <MenuItem value={70}>70%</MenuItem>
          <MenuItem value={80}>80%</MenuItem>
          <MenuItem value={90}>90%</MenuItem>
        </Select>
      ),
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div style={{ width: '70px' }}>{rowData.hscPercentage}</div>,
    },
    {
      title: 'Attempted Status',
      field: 'attemptedStatus',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Team',
      field: 'team',
      filtering: false,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'No of questions attempted (out of 6)',
      field: 'noOfQuestionAttemptedQuantitative',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'No of questions correct',
      field: 'noOfQuestionCorrectQuantitative',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'No of questions With Penality',
      field: 'noOfQuestionPenalityQuantitative',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Score(32.0)',
      field: 'scoreQuantitative',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
      filterComponent: () => (
        <Select
          id='scoreQuantitative'
          style={{ width: 70 }}
          value={quantitativeScore}
          onChange={(e) => {
            setQuantitativeScore(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.scoreQuantitative) >= e.target.value || e.target.value === 'all'
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={'all'}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={5}>5%</MenuItem>
          <MenuItem value={10}>10%</MenuItem>
          <MenuItem value={20}>20%</MenuItem>
          <MenuItem value={30}>30%</MenuItem>
        </Select>
      ),
      render: (rowData) => <div style={{ width: '70px' }}>{rowData.scoreQuantitative}</div>,
    },
    {
      title: 'Quantitative Score(%) (out of 32.0)',
      field: 'scorePercentageQuantitative',
      editable: false,
      cellStyle: {
        textAlign: 'center',
      },
      filterComponent: () => (
        <Select
          id='scorePercentageQuantitative'
          style={{ width: 70 }}
          value={quantitative}
          onChange={(e) => {
            setQuantitative(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.scorePercentageQuantitative) >= e.target.value ||
                e.target.value === 'all'
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={'all'}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={60}>60%</MenuItem>
          <MenuItem value={70}>70%</MenuItem>
          <MenuItem value={80}>80%</MenuItem>
          <MenuItem value={90}>90%</MenuItem>
        </Select>
      ),
      emptyValue: () => <em>--</em>,
      render: (rowData) => (
        <div style={{ width: '70px' }}>{rowData.scorePercentageQuantitative}</div>
      ),
    },
    {
      title: 'No of questions attempted (out of 4)',
      field: 'noOfQuestionAttemptedProblemSolving',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'No of questions correct',
      field: 'noOfQuestionCorrectProblemSolving',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'No of questions With Penality',
      field: 'noOfQuestionPenalityProblemSolving',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Score(33.0)',
      field: 'scoreProblemSolving',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
      filterComponent: () => (
        <Select
          id='scoreProblemSolving'
          style={{ width: 70 }}
          value={problemSolvingScore}
          onChange={(e) => {
            setProblemSolvingScore(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.scoreProblemSolving) >= e.target.value || e.target.value === 'all'
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={'all'}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={5}>5%</MenuItem>
          <MenuItem value={10}>10%</MenuItem>
          <MenuItem value={20}>20%</MenuItem>
          <MenuItem value={30}>30%</MenuItem>
        </Select>
      ),
      render: (rowData) => <div style={{ width: '70px' }}>{rowData.scoreProblemSolving}</div>,
    },
    {
      title: 'Problem Solving Score(%) (out of 33.0)',
      field: 'scorePercentageProblemSolving',
      editable: false,
      cellStyle: {
        textAlign: 'center',
      },
      filterComponent: () => (
        <Select
          id='scorePercentageProblemSolving'
          style={{ width: 70 }}
          value={problemSolving}
          onChange={(e) => {
            setProblemSolving(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.scorePercentageProblemSolving) >= e.target.value ||
                e.target.value === 'all'
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={'all'}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={60}>60%</MenuItem>
          <MenuItem value={70}>70%</MenuItem>
          <MenuItem value={80}>80%</MenuItem>
          <MenuItem value={90}>90%</MenuItem>
        </Select>
      ),
      emptyValue: () => <em>--</em>,
      render: (rowData) => (
        <div style={{ width: '70px' }}>{rowData.scorePercentageProblemSolving}</div>
      ),
    },
    {
      title: 'No of questions attempted (out of 7)',
      field: 'noOfQuestionAttemptedCodeAnalysis',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'No of questions correct',
      field: 'noOfQuestionCorrectCodeAnalysis',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'No of questions With Penality',
      field: 'noOfQuestionPenalityCodeAnalysis',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Score(35.0)',
      field: 'scoreCodeAnalysis',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
      filterComponent: () => (
        <Select
          id='scoreCodeAnalysis'
          style={{ width: 70 }}
          value={codeAnalysisScore}
          onChange={(e) => {
            setCodeAnalysisScore(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.scoreCodeAnalysis) >= e.target.value || e.target.value === 'all'
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={'all'}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={5}>5%</MenuItem>
          <MenuItem value={10}>10%</MenuItem>
          <MenuItem value={20}>20%</MenuItem>
          <MenuItem value={30}>30%</MenuItem>
        </Select>
      ),
      render: (rowData) => <div style={{ width: '70px' }}>{rowData.scoreCodeAnalysis}</div>,
    },
    {
      title: 'Code Analysis Score(%) (out of 35.0)',
      field: 'scorePercentageCodeAnalysis',
      editable: false,
      cellStyle: {
        textAlign: 'center',
      },
      filterComponent: () => (
        <Select
          id='scorePercentageCodeAnalysis'
          style={{ width: 70 }}
          value={codeAnalysis}
          onChange={(e) => {
            setCodeAnalysis(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.scorePercentageCodeAnalysis) >= e.target.value ||
                e.target.value === 'all'
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={'all'}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={60}>60%</MenuItem>
          <MenuItem value={70}>70%</MenuItem>
          <MenuItem value={80}>80%</MenuItem>
          <MenuItem value={90}>90%</MenuItem>
        </Select>
      ),
      emptyValue: () => <em>--</em>,
      render: (rowData) => (
        <div style={{ width: '70px' }}>{rowData.scorePercentageCodeAnalysis}</div>
      ),
    },
    {
      title: 'No of questions attempted (out of 10)',
      field: 'noOfQuestionAttemptedAptitude',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'No of questions correct',
      field: 'noOfQuestionCorrectAptitude',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'No of questions With Penality',
      field: 'noOfQuestionPenalityAptitude',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Score(65.0)',
      field: 'scoreAptitude',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
      filterComponent: () => (
        <Select
          id='scoreAptitude'
          style={{ width: 70 }}
          value={aptitudeScore}
          onChange={(e) => {
            setAptitudeScore(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.scoreAptitude) >= e.target.value || e.target.value === 'all'
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={'all'}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={20}>20%</MenuItem>
          <MenuItem value={30}>30%</MenuItem>
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={60}>60%</MenuItem>
        </Select>
      ),
      render: (rowData) => <div style={{ width: '70px' }}>{rowData.scoreAptitude}</div>,
    },
    {
      title: 'Aptitude Score(%) (out of 65.0)',
      field: 'scorePercentageAptitude',
      editable: false,
      cellStyle: {
        textAlign: 'center',
      },
      filterComponent: () => (
        <Select
          id='scorePercentageAptitude'
          style={{ width: 70 }}
          value={aptitude}
          onChange={(e) => {
            setAptitude(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.scorePercentageAptitude) >= e.target.value ||
                e.target.value === 'all'
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={'all'}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={60}>60%</MenuItem>
          <MenuItem value={70}>70%</MenuItem>
          <MenuItem value={80}>80%</MenuItem>
          <MenuItem value={90}>90%</MenuItem>
        </Select>
      ),
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div style={{ width: '70px' }}>{rowData.scorePercentageAptitude}</div>,
    },
    {
      title: 'Overall Score',
      field: 'overAllScore',
      editable: false,
      cellStyle: {
        textAlign: 'center',
      },
      filterComponent: () => (
        <Select
          id='overAllScore'
          style={{ width: 70 }}
          value={overallScore}
          onChange={(e) => {
            setOverallScore(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.overAllScore) >= e.target.value || e.target.value === 'all'
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={'all'}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={60}>60%</MenuItem>
          <MenuItem value={70}>70%</MenuItem>
          <MenuItem value={80}>80%</MenuItem>
          <MenuItem value={90}>90%</MenuItem>
        </Select>
      ),
      emptyValue: () => <em>--</em>,
      render: (rowData) => (
        <div
          style={{
            background:
              rowData.overAllScore >= 70.0
                ? '#02ae02af'
                : rowData.overAllScore < 70.0 && rowData.overAllScore > 0
                ? '#d80303aa'
                : '#9f9f9f',
            borderRadius: '4px',
            textAlign: 'center',
            padding: 5,
            width: '70px',
          }}
        >
          {rowData.overAllScore}
        </div>
      ),
    },
    {
      title: 'Genunity Score',
      field: 'genunityScore',
      filtering: false,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData) => <div style={{ width: '70px' }}>{rowData.genunityScore}</div>,
    },
    {
      title: 'Banding',
      field: 'softwareBandScore',
      filtering: true,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Video link',
      field: 'videoLink',
      filtering: false,
      editable: false,
      sorting: true,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData) => (
        <a
          href={rowData.videoLink}
          style={{ width: '70px' }}
          target='_blank'
          rel='noreferrer noopener'
        >
          {`${rowData.videoLink.slice(0, 15)}...`}
        </a>
      ),
    },
    {
      title: 'Video bands',
      field: 'softwareVideoBand',
      filtering: false,
      sorting: false,
      cellStyle: {
        textAlign: 'center',
      },
      editComponent: (props) => {
        return (
          <Autocomplete
            id='softwareVideoBand'
            name='softwareVideoBand'
            getOptionLabel={(option) => option?.videoBands}
            options={options}
            onChange={(e, newVal) => {
              props.onChange(newVal);
            }}
            value={props.value}
            fullWidth
            renderInput={(params) => <TextField {...params} label='Band' variant='outlined' />}
          />
        );
      },
      render: (rowData) => (
        <div style={{ width: '100px' }}>{rowData.softwareVideoBand?.videoBands}</div>
      ),
    },
  ];

  return (
    <div>
      <BackHandler title='' tab={0} isClsa={true} />
      <div>
        <h3 style={{ textAlign: 'center', margin: '0 !important', color: 'rgb(67, 131, 231)' }}>
          {clsaHead}
        </h3>
        <div
          style={{
            margin: '2rem 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          <Button
            disabled={
              (tableData.length > 0 && tableData.length === totalElements) ||
              isLoading ||
              tableData.length !== totalElements
            }
            color='primary'
            variant='outlined'
            style={{ height: '45px' }}
            onClick={() => {
              fetchMoreStudentList(1);
            }}
          >
            {isLoading ? 'Loading...' : 'Load More Students List'}
          </Button>

          {tableData.length > 0 && (
            <h6
              style={{
                marginBottom: 0,
                padding: '10px 20px',
                backgroundColor: ' #1093FF',
                borderRadius: '10px',
                color: '#ffffff',
                fontWeight: 800,
                fontSize: '15px',
              }}
            >
              {tableData.length}/{totalElements}
            </h6>
          )}

          <div>
            <DateRangePickerComp
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setValue={setValue}
              setPageNo={setPageNo}
              setTableData={setTableData}
            />
          </div>

          <Controls.ActionButton
            onClick={() => {
              window.location.reload(true);
            }}
          >
            <Tooltip title='Reload' placement='top'>
              <RefreshIcon fontSize='medium' color='primary' />
            </Tooltip>
          </Controls.ActionButton>
        </div>
        <MaterialTable
          columns={columns}
          data={filteredData}
          editable={{
            onRowUpdate: (newData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataUpdate = [...tableData];
                  const index = tableData.findIndex((item) => item.studentId === newData.studentId);
                  dataUpdate[index]['softwareVideoBand'] = newData.softwareVideoBand;
                  setTableData([...dataUpdate]);
                  resolve();
                }, 1000);
              }),
          }}
          localization={{
            header: {
              actions: '',
            },
            toolbar: {
              nRowsSelected: 'No. of Student(s) : {0}',
            },
          }}
          components={{
            Toolbar: (props) => (
              <div
                style={{
                  display: 'flex',
                  marginTop: '1rem',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderBottom: '2px solid #0094b1',
                  borderTop: '2px solid #007d93',
                  borderRadius: '4px',
                }}
              >
                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    <div style={{ flexGrow: 2, width: '10%' }}>
                      <Controls.RoundedInput
                        disabled={tableData.length !== totalElements}
                        className={classes.searchInput}
                        placeholder='Search by Name, Email or Phone Number...'
                        helperText={'Press Enter key to search after typing'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Search />
                            </InputAdornment>
                          ),
                        }}
                        defaultValue={searchValue} // search text was stay remains even the material-table is re-rendered.
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            handleSearch(e.target.value);
                          }
                        }}
                      />
                    </div>
                    <div id='material-table' style={{ flexGrow: 1, padding: '2rem', width: '35%' }}>
                      <MTableToolbar {...props} />
                    </div>
                  </>
                )}
              </div>
            ),
          }}
          actions={[
            {
              icon: () => <ThumbUp style={{ color: 'green' }} />,
              tooltip: 'Update',
              onClick: (e, tableData) => {
                let payload = tableData.map((item) => ({
                  studentId: item.studentId,
                  clsaVideoBandRatingId: item.softwareVideoBand?.id,
                }));
                let booleanArr = tableData.map((item) => Boolean(item.softwareVideoBand?.id));
                if (booleanArr.includes(false)) {
                  setNotify({
                    isOpen: true,
                    message: 'Please Choose any Video Band',
                    type: 'error',
                  });
                } else {
                  dispatch(updateResult(id, payload));
                }
              },
            },
            {
              icon: () => (
                <DescriptionIcon
                  style={{ color: masterReportIsVisible === false ? 'gray' : '#1093FF' }}
                />
              ),
              tooltip: 'Send Master Sheet to Email',
              disabled: masterReportIsVisible === false,
              onClick: (e, tableData) => {
                let filteredIds = tableData.map((student) => student.studentId);
                let obj = { studentIdList: filteredIds };
                dispatch(
                  clsaSheetDownload(id, obj, (response) => {
                    if (response.status === 200) {
                      setPageNo(0);
                      setTableData([]);
                      setNotify({
                        isOpen: true,
                        message: 'Master Sheet will be sent to your Email soon...',
                        type: 'success',
                      });
                    } else {
                      setNotify({
                        isOpen: true,
                        message: 'Something went wrong, Unable to download..!',
                        type: 'error',
                      });
                    }
                  })
                );
              },
            },
          ]}
          options={{
            sorting: true,
            search: false,
            searchFieldAlignment: 'right',
            searchFieldVariant: 'outlined',
            filtering: true,
            paging: false,
            pageSize: 60, // make initial page size
            emptyRowsWhenPaging: false, // To avoid of having empty rows
            exportButton: false,
            exportAllData: false,
            exportFileName: getClsaResult?.data?.clsaTitle,
            addRowPosition: 'first',
            actionsColumnIndex: -1,
            selection: true,
            showSelectAllCheckbox: true,
            showTextRowsSelected: true,
            grouping: false,
            columnsButton: true,
            rowStyle: (tableData, index) => (index % 2 === 0 ? { background: '#f5f5f5' } : null),
            headerStyle: { background: 'aliceblue', color: '#000' },
          }}
          title={''}
          icons={{
            Add: Add,
            Edit: Edit,
            Check: Check,
            FirstPage: FirstPage,
            NextPage: ChevronRight,
            PreviousPage: ChevronLeft,
            LastPage: LastPage,
            Filter: () => <FilterIcon style={{ color: '#807f7f' }} />,
            SortArrow: () => (
              <SortIcon
                fontSize='small'
                style={{ color: '#c6c6c6', transform: 'rotate(-90deg)' }}
              />
            ),
            Clear: () => <ClearIcon />,
            Export: () => <ExportIcon style={{ color: 'green' }} />,
            ResetSearch: () => <ClearIcon />,
            Search: () => <SearchIcon />,
            ViewColumn: () => <ViewColumnIcon />,
          }}
        />
      </div>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}

export default ClsaResult;
