import { IBH } from '../Redux/Action';
import axios from 'axios';
import { catchError, errorHandler } from '../Component/Utils/Helpers';

// Clearing all the data in the reducer while left from that page like ComponentWillUnmount.
export const clearData = () => (dispatch) => {
  dispatch({ type: IBH.CLEAR_DATA });
};

// Get all IBH Webinar lists
export const getAllWebinarList = (page = 0) => async (dispatch) => {
  try {
    dispatch({ type: IBH.LOADER });
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/ibh/webinar`, {
      crossDomain: true,
      headers: {
        admin: 'yes',
        Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
      },
      params: {
        page: page - 1,
        size: 6,
      },
    });
    dispatch({
      type: IBH.GET_ALL_WEBINAR_LIST,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(IBH.GET_ALL_WEBINAR_LIST, error, false));
  }
};

// Delete Webinar by id
export const deleteWebinarById = (webinarId) => async (dispatch) => {
  try {
    // dispatch({ type: IBH.LOADER });
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/api/v1/ibh/webinar/${webinarId}`,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    dispatch({
      type: IBH.DELETE_WEBINAR_BY_ID,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(IBH.DELETE_WEBINAR_BY_ID, error, false));
  }
};

// IBH Rounds dropdown
export const getIbhRounds = (isWebinar) => {
  let accessToken = window.sessionStorage.getItem('accessToken');

  return async (dispatch) => {
    try {
      dispatch({ type: IBH.LOADER });
      await axios
        .get(`${process.env.REACT_APP_API_URL}/api/v1/ibh/rounds`, {
          crossDomain: true,
          headers: {
            admin: 'yes',
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            isWebinar: isWebinar,
          },
        })
        .then((response) => {
          dispatch({
            type: IBH.getIbhRoundList,
            payload: response?.data?.data,
          });
        });
    } catch (error) {
      dispatch(errorHandler(IBH.getIbhRoundList, error, false));
    }
  };
};

// Create IBH Webinar
export const createWebinar = (payload) => async (dispatch) => {
  try {
    dispatch({ type: IBH.LOADER });
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/v1/ibh/webinar`,
      payload,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    dispatch({
      type: IBH.CREATE_WEBINAR,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(IBH.CREATE_WEBINAR, error, false));
  }
};

// Update IBH Webinar
export const updateWebinar = (payload) => async (dispatch) => {
  try {
    dispatch({ type: IBH.LOADER });
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/v1/ibh/webinar`,
      payload,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    dispatch({
      type: IBH.UPDATE_WEBINAR,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(IBH.UPDATE_WEBINAR, error, false));
  }
};

// Get Webinar data by id
export const getWebinarById = (id) => async (dispatch) => {
  try {
    dispatch({ type: IBH.LOADER });
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/ibh/webinar/${id}`, {
      crossDomain: true,
      headers: {
        admin: 'yes',
        Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
      },
    });
    dispatch({
      type: IBH.GET_WEBINAR_BY_ID,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(IBH.GET_WEBINAR_BY_ID, error, false));
  }
};

// Get rounds list & Custom Questions in Result page
export const getRoundLists = (isWebinar, callback) => {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/ibh/rounds`, {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
        params: {
          isWebinar: isWebinar,
        },
      })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        console.log(error);
        callback(error?.response?.data);
      });
  };
};

// Get all student lists in IBH result page
export const getStudentList = (pageNo, roundId, data, callback) => {
  return (dispatch) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/ibh/rounds/${roundId}/status?page=${pageNo}&size=100`,
        data,
        {
          crossDomain: true,
          headers: {
            admin: 'yes',
            Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
          },
        }
      )
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        console.log(error);
        callback(error.response?.data);
      });
  };
};

// IBH Result page - Master Sheet download
export const ibhMasterSheetDownload = (data, callback) => {
  let accessToken = window.sessionStorage.getItem('accessToken');
  return () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/ibh/masterreport`, data, {
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'blob',
      })
      .then((result) => {
        callback(result);
      })
      .catch((error) => {
        callback(catchError(error));
      });
  };
};

// Update student status (Qualified / Not Qualified)
export const updateStudentStatus = (data, callback) => {
  return (dispatch) => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/api/v1/ibh/status`, data, {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        console.log(error);
        callback(error.response?.data);
      });
  };
};

// Get the form details
export const getFormDetails = (callback) => {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/ibh/form/questions`, {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        console.log(error);
        callback(error.response?.data);
      });
  };
};

// Add / Update form details
export const addUpdateFormDetails = (payload, callback) => {
  return (dispatch) => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/api/v1/ibh/form/questions`, payload, {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        console.log(error);
        callback(error.response?.data);
      });
  };
};

// // Delete question by id in IBH form
// export const deleteFormQuestionById = (questionId, callback) => {
//   return (dispatch) => {
//     axios
//       .delete(`${process.env.REACT_APP_API_URL}/api/v1/ibh/form/questions/${questionId}`, {
//         crossDomain: true,
//         headers: {
//           admin: 'yes',
//           Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
//         },
//       })
//       .then((response) => {
//         callback(response.data);
//       })
//       .catch((error) => {
//         console.log(error);
//         callback(error.response?.data);
//       });
//   };
// };
