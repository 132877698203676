import { Backdrop, Box, Breadcrumbs, Divider, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import {
  clearCustomData,
  getDisableButtonCall,
  getDocumentModelBySubStageId,
  getDownloadByDocumentForCv,
  getDownloadByDocumentId,
  getDownloadByDocumentIdForReport,
  getEnableCall,
  getQPMC2ReportCall,
  qpmcStageComplete,
  uploadFile,
  uploadFileForReport,
} from '../Actions/ProfileMentoring';
import { getStepsBySubStageId, getStudentStageByProductId } from '../Actions/Student';
import MySnackBar from './MySnackBar';
import { CommentBoxPopper } from './Utils/controls/CommentBoxPopper';
import { CustomTab, CustomTabs } from './Utils/controls/CustomTabComponent';
import CustomTabsPM from './ProfileMentoring/additionalPoints/CustomTabsForProfileMentoring';
import Loader from './Utils/controls/Loader';
import { getVariantStepsById } from '../Actions/ProductAction';
// import PreferenceIndex from "../SchoolResearchPreferenceList/Index";
// import DocumentComponentForReport from "./DocumentComponentForReport";
import { customTheme, StyledButton } from './Utils/controls/Styles';
import { bytesToMegaBytes, getSubStageByStage, textToDownloadFile } from './Utils/Helpers';
import BackButton from '../Asset/Images/backbutton.svg';
import DocumentComponent from './ProfileMentoring/DocumentComponent';
import { useStyles } from './ProfileMentoring/Styles';
import { getproductstepsCall } from '../Actions/ProductAction';
// import PreferenceList from "../SchoolResearchPreferenceList/PreferenceList";
// import PlanOfAction from "./additionalPoints/Index";
import { tabListForQPMC1, tabListForQPMC2 } from '../Utils/Data';
// import PlanOfActionIndex from "./PlanOfAction/Index";
// import MomIndex from "./MOM/Index";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { CRUDwebinarPath, studentPath } from './RoutePaths';

const FILE_FORMAT_ERROR = 'Invalid file format';
const FILE_SIZE_ERROR = 'Please check the file size';
const FILE_UPLOAD_ERROR = 'Please select a file';
const UPLOADED_SUCCESS = 'Uploaded Successfully';
const REQUIRED_ERROR = 'Please fill the required field';

function ProfileMentoring(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const { studentId, productId } = params;
  console.log(studentId, productId, '^^^^^ studentId1');
  let prodId = window.sessionStorage.getItem('productId');
  let studId = window.sessionStorage.getItem('studentId');
  let stagId = window.sessionStorage.getItem('stageId');
  const student = location.studentId === undefined ? studId : location.studentId;
  const product = location.productId === undefined ? prodId : location.productId;
  console.log(
    location.studentId,
    location.productId,
    location,
    studId,
    prodId,
    stagId,
    student,
    product,
    '^^^^^ studentId'
  );
  const [stageDetails, setStageDetails] = useState('');
  const [qpmc1Notif, setQpmc1Notif] = useState(true);
  const [state, setState] = useState({
    steps: [],
    documentList: [],
    activeTabValue: 'QPMC 1',
    sectionId: null,
    open: false,
    comment: null,
    upcomingFileName: null,
    fileName: null,
    fileNameHelperText: '',
    commentHelperText: '',
    file: null,
    snackMsg: '',
    snackOpen: false,
    snackVariant: '',
    status: null,
    anchorEl: null,
    popoverComment: null,
    completedStagesList: [],
    cvloader: false,
  });

  const [stageId, setStageId] = useState(null);
  const stage = stageId === null || undefined ? stagId : stageId;

  const {
    steps,
    documentList,
    sectionId,
    activeTabValue,
    activeMainTabValue,
    activeStateValue,
    activeSubTabValue,
    SubsectionId,
    open,
    comment,
    fileName,
    fileNameHelperText,
    commentHelperText,
    file,
    snackMsg,
    snackOpen,
    snackVariant,
    upcomingFileName,
    status,
    anchorEl,
    popoverComment,
    completedStagesList,
    cvloader,
  } = state;
  const {
    loading,
    documentModel,
    fileUploadStatus,
    downloadFileResponse,
    getQPMC2ReportStatus,
    qpmcStageCompletionStatus,
    disableStage,
    enableStage,
    momPoints,
  } = useSelector((state) => state.ProfileMentoringReducer);

  const { studentStages, subStageSteps, completedStages } = useSelector(
    (state) => state.StudentReducer
  );

  const { getproductsteps } = useSelector((state) => state.ProductReducer);
  useEffect(() => {
    if (qpmcStageCompletionStatus?.success) {
      setState({
        ...state,
        snackOpen: true,
        snackVariant: 'success',
        snackMsg:
          activeTabValue === 'QPMC 1'
            ? 'QPMC 1 Completed Successfully'
            : 'QPMC 2 Completed Successfully',
      });
      // setCompletionMessage(qpmcStageCompletionStatus?.message);
    } else {
      setState({
        ...state,
        snackOpen: true,
        snackVariant: 'error',
        snackMsg: qpmcStageCompletionStatus?.message,
      });
    }
  }, [qpmcStageCompletionStatus]);
  useEffect(() => {
    dispatch(getStudentStageByProductId(student, product));
    dispatch(
      getVariantStepsById(student, (response) => {
        setStageDetails(response);
      })
    );
    // dispatch(getDisableButtonCall(studentId, productId)
    // )
  }, []);
  const [completionMessage, setCompletionMessage] = useState('');
  useEffect(() => {
    let id = stageDetails?.steps?.find((el) => el.stepName === 'Profile Mentoring').id;

    dispatch(getproductstepsCall(id));
  }, [stageDetails]);
  console.log(stageId, 'gvugvvjvjh');
  useEffect(() => {
    if (studentStages) {
      if (studentStages.success) {
        const a = studentStages.data.find((item) => item.stepName === 'Profile Mentoring');

        const b = a.steps.find((data) => data.stepName === 'QPMC 2');

        const stageId = b.steps.find((item) =>
          item.stepName === 'QPMC 2 Report' ? item.id : null
        );

        setStageId(stageId.id);

        const { data } = studentStages;
        let subStage = getSubStageByStage(data, 'Profile Mentoring', 'Completed Cv');
        if (subStage.length !== 0) {
          dispatch(
            getStepsBySubStageId(
              student,
              product,

              subStage[0]['id'],
              'profileMentoring'
            )
          );
        }
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackVariant: 'error',
          snackMsg: studentStages.message,
        });
      }
    }
    let Tabsteps1 = studentStages?.data
      ?.find((el) => el.stepName === 'Profile Mentoring')
      ?.steps?.find((el) => el.stepName === 'QPMC 1')?.steps;

    let arr1 = Tabsteps1?.find(({ stepName }) => stepName === 'CV Review');

    setState({
      ...state,
      activeTabValue: 'QPMC 1',
      activeSubTabValue: 'CV Review',
      activeStateValue: arr1?.id,
    });
  }, [studentStages]);

  useEffect(() => {
    if (subStageSteps) {
      if (subStageSteps.success) {
        let arr = subStageSteps.data;
        if (arr.length !== 0) {
          arr.sort(function(a, b) {
            return a.rank - b.rank;
          });
          setState({
            ...state,
            steps: arr,
            activeTabValue: 'QPMC 1',
            sectionId: arr.length !== 0 && arr[0]['id'],
          });
        }
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackVariant: 'error',
          snackMsg: subStageSteps.message,
        });
      }
    }
  }, [subStageSteps]);

  useEffect(() => {
    dispatch(getDocumentModelBySubStageId(student, product, stage));
  }, [activeSubTabValue]);

  useEffect(() => {
    if (completedStages) {
      if (completedStages.success) {
        setState({
          ...state,
          completedStagesList: completedStages.data || [],
        });
      } else {
        setState({
          ...state,
          completedStagesList: [],
        });
      }
    }
  }, [completedStages]);

  useEffect(() => {
    if (documentModel) {
      if (documentModel.success) {
        const { data } = documentModel;

        setState({
          ...state,
          upcomingFileName: data.fileName,
          status: data.stepStatus,
          documentList: data.content || [],
        });
      } else {
        setState({
          ...state,
          // snackOpen: true,
          // snackVariant: "error",
          // snackMsg: documentModel.message,
          upcomingFileName: null,
          status: null,
          documentList: [],
        });
      }
      dispatch(clearCustomData('documentModel'));
    }
  }, [documentModel]);
  useEffect(() => {
    if (activeSubTabValue === 'QPMC 2 Report') {
      if (getQPMC2ReportStatus) {
        if (getQPMC2ReportStatus.success) {
          const { data } = getQPMC2ReportStatus;

          setState({
            ...state,
            upcomingFileName: data.fileName,
            status: data.stepStatus,
            documentList: data || [],
          });
        } else {
          setState({
            ...state,
            // snackOpen: true,
            // snackVariant: "error",
            // snackMsg: getQPMC2Report.message,
            upcomingFileName: null,
            status: null,
            documentList: [],
          });
        }
        dispatch(clearCustomData('getQPMC2Report'));
      }
    }
  }, [getQPMC2ReportStatus]);
  // useEffect(() => {
  //   if (activeSubTabValue === "QPMC 2 Report") {
  //     dispatch(
  //       getQPMC2ReportCall(
  //         studentId,
  //         productId,
  //         "aca00ae8-19fd-11ed-861d-0242ac120002"
  //       )
  //     );
  //   }
  // }, [activeSubTabValue, fileUploadStatus]);
  useEffect((event) => {
    if (event?.target?.innerHTML === 'Worksheet') {
      dispatch(
        getQPMC2ReportCall(
          '4970547e-7dd2-4afb-b994-545276783e3f',
          '7e3d19b6-ea88-4f55-81d3-1ec5d19f8f5a',
          '619c3a6c-19fd-11ed-861d-0242ac120002'
        )
      );
    }
  }, []);
  useEffect(() => {
    if (fileUploadStatus) {
      if (fileUploadStatus.success) {
        if (activeSubTabValue === 'QPMC 2 Report') {
          setState({
            ...state,
            snackOpen: true,
            snackVariant: 'success',
            snackMsg: UPLOADED_SUCCESS,
            file: null,
            fileName: null,
            comment: null,
            fileNameHelperText: '',
            commentHelperText: '',
            open: false,
            cvloader: true,
          });
          dispatch(
            getQPMC2ReportCall(
              '4970547e-7dd2-4afb-b994-545276783e3f',
              '7e3d19b6-ea88-4f55-81d3-1ec5d19f8f5a',
              stage
            )
          );
        } else {
          setState({
            ...state,
            // snackOpen: true,
            // snackVariant: "success",
            // snackMsg: "Saved Successfully",
            file: null,
            fileName: null,
            comment: null,
            fileNameHelperText: '',
            commentHelperText: '',
            open: false,
            cvloader: true,
          });
          dispatch(getDocumentModelBySubStageId(student, product, stage));
        }
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackVariant: 'error',
          snackMsg: fileUploadStatus.message,
        });
      }
      dispatch(clearCustomData('fileUploadStatus'));
    }
  }, [fileUploadStatus]);

  useEffect(() => {
    if (downloadFileResponse) {
      if (downloadFileResponse.success) {
        textToDownloadFile(
          downloadFileResponse.data,
          downloadFileResponse.fileName,
          downloadFileResponse.fileName.split('.').pop()
        );
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackVariant: 'error',
          snackMsg: downloadFileResponse.message,
        });
      }
      dispatch(clearCustomData('downloadFileResponse'));
    }
  }, [downloadFileResponse]);

  const handleCancel = () => {
    setState({
      ...state,
      file: null,
      fileName: null,
      comment: null,
      fileNameHelperText: '',
      commentHelperText: '',
      open: false,
    });
  };
  const handleUploadReport = () => {
    let error = false;

    if (!file) {
      error = true;
      setState({
        ...state,
        snackOpen: true,
        snackMsg: FILE_UPLOAD_ERROR,
        snackVariant: 'error',
      });
    } else if (!(comment && comment.trim().length !== 0)) {
      error = true;
      setState({ ...state, commentHelperText: REQUIRED_ERROR });
    }

    if (!error) {
      var fileObj = file;
      var newFileName = fileName;
      var newFileType = fileObj.path.split('.').pop();
      var blob = new Blob([fileObj], { type: newFileType });
      var newFile = new File([blob], `${newFileName}.${newFileType}`, {
        type: newFileType,
      });
      let uploadFormData = new FormData();
      uploadFormData.append('file', newFile);
      dispatch(
        uploadFileForReport(
          // "4970547e-7dd2-4afb-b994-545276783e3f", "7e3d19b6-ea88-4f55-81d3-1ec5d19f8f5a",
          student,
          product,
          stage,
          uploadFormData,
          comment
        )
      );
    }
  };
  const handleUpload = () => {
    let error = false;

    if (!file) {
      error = true;
      setState({
        ...state,
        snackOpen: true,
        snackMsg: FILE_UPLOAD_ERROR,
        snackVariant: 'error',
      });
    } else if (!(comment && comment.trim().length !== 0)) {
      error = true;
      setState({ ...state, commentHelperText: REQUIRED_ERROR });
    }

    if (!error && '1cb5ebc8-19fd-11ed-861d-0242ac120002') {
      var fileObj = file;
      var newFileName = fileName;
      var newFileType = fileObj.path.split('.').pop();
      var blob = new Blob([fileObj], { type: newFileType });
      var newFile = new File([blob], `${newFileName}.${newFileType}`, {
        type: newFileType,
      });
      let uploadFormData = new FormData();
      uploadFormData.append('file', newFile);
      dispatch(uploadFile(student, product, uploadFormData, comment));
    }
  };

  const handleUploadClick = () => {
    setState({ ...state, open: true });
  };
  const handleStageComplete = () => {
    dispatch(qpmcStageComplete(student, product, activeTabValue));
  };

  const handleDrop = (fileArray) => {
    if (fileArray.length !== 0) {
      if (bytesToMegaBytes(fileArray[0]['size']) > 5) {
        setState({
          ...state,
          snackOpen: true,
          snackMsg: FILE_SIZE_ERROR,
          snackVariant: 'error',
        });
      } else {
        setState({
          ...state,
          file: fileArray[0],
          fileName: upcomingFileName || fileArray[0]['name'],
        });
      }
    } else {
      setState({
        ...state,
        snackOpen: true,
        snackMsg: FILE_FORMAT_ERROR,
        snackVariant: 'error',
      });
    }
  };

  const handleComment = (comment, e) => {
    setState({
      ...state,
      popoverComment: comment,
      anchorEl: e.currentTarget,
    });
  };

  // const handleDownload = (path, id, e) => {
  //   let name = path.split(".").pop();
  //   console.log(name, "$$$$$$$$$$$$")
  //   let file = name === "docx" ? path.slice(0, -4) : path.slice(0, -3);
  //   console.log(file, "&&&&&&&&&&&&&&&&&&&&&&&&&&&&")
  //   dispatch(getDownloadByDocumentForCv(studentId, productId, id, file));
  // };
  const handleDownload = (path, id, e) => {
    console.log(path, path.slice(0, -3), '%%%%%%%%');
    console.log(path, path.slice(0, -3), '%%%%%%%%');
    let name = path.split('.').pop();
    let dot = '.';
    let name1 = path.concat(dot);
    console.log(name, name1, '$$$$$$$$$$$$');
    // let file = name === "docx" ? cvPath.slice(0, -4) : name1 === cvPath ? cvPath + "." : cvPath.slice(0, -3);
    let file =
      name == 'pdf'
        ? path.slice(0, -3)
        : name == 'docx'
        ? `${path.slice(0, -4)}docx`
        : `${path.slice(0, -3)}doc`;

    dispatch(getDownloadByDocumentForCv(student, product, id, file));
  };
  const handleDownloadReport = (path, id, e) => {
    console.log(id, 'hello');
    dispatch(
      getDownloadByDocumentIdForReport('4970547e-7dd2-4afb-b994-545276783e3f', stage, path, id)
    );
  };
  const handleDownloadForWorksheet = (path, id, e) => {
    dispatch(
      getDownloadByDocumentIdForReport('4970547e-7dd2-4afb-b994-545276783e3f', stage, path, id)
    );
  };

  const handleDelete = (id, path, e) => {};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value, [`${name}HelperText`]: null });
  };

  const isStageCompleted = () => {
    return completedStagesList.includes('Profile Mentoring');
  };

  const renderComponent = () => {
    const renderProps = {
      open: open,
      stepName: 'CV Review',
      handleCancel: handleCancel,
      handleUpload: handleUpload,
      handleUploadReport: handleUploadReport,
      handleUploadClick: handleUploadClick,
      handleStageComplete: handleStageComplete,
      // activeTabValue: activeTabValue,
      onDrop: handleDrop,
      handleComment: handleComment,
      handleDownload: handleDownload,
      handleDownloadReport: handleDownloadReport,
      handleDownloadForWorksheet: handleDownloadForWorksheet,
      handleDelete: handleDelete,
      tableData: documentList,
      handleChange: handleChange,
      fileName: fileName,
      comment: comment,
      fileNameHelperText: fileNameHelperText,
      commentHelperText: commentHelperText,
      file: file,
      disabledUploadButton: isStageCompleted() || documentList.length === 0,
      isDisabledFileName: true,
      lastestCVLoading: cvloader,
      completionMessage: completionMessage,
      ...props,
    };

    if (activeSubTabValue && activeTabValue) {
      if (activeTabValue === 'School Research') {
        // return (
        //     <PreferenceList
        //         studentId={studentId}
        //         productId={productId}
        //         {...renderProps}
        //     />
        // );
        //   return(
        //      <DocumentComponentForReport {...renderProps} />
        //   );
      } else if (activeSubTabValue === 'CV Review') {
        return (
          // <PlanOfAction tabList={tabListForQPMC1} activeTabValue={"Version 1"} />
          <DocumentComponent {...renderProps} />
        );
      } else if (
        activeSubTabValue === 'Additional points to consider' &&
        activeTabValue === 'QPMC 1'
      ) {
        // return (
        //     <PlanOfAction
        //         tabList={tabListForQPMC1}
        //         activeTabValue={"Version 1"}
        //     />
        // );
      } else if (activeSubTabValue === 'Plan of action' && activeTabValue === 'QPMC 1') {
        // return <PreferenceList studentId={studentId} productId={productId} />;
        // return (
        //     //  <PlanOfAction/>
        //     <PlanOfActionIndex
        //         activeTabValue={"Version 1"}
        //         tabList={tabListForQPMC1}
        //     />
        // );
      } else if (activeSubTabValue === 'Plan of action') {
        // return <PreferenceList studentId={studentId} productId={productId} />;
        // return (
        //     //  <PlanOfAction/>
        //     <PlanOfActionIndex
        //         activeTabValue={"Version 1"}
        //         tabList={tabListForQPMC2}
        //     />
        // );
      } else if (activeSubTabValue === 'Additional points to consider') {
        // return <PreferenceList studentId={studentId} productId={productId} />;
        // return (
        //     <PlanOfAction
        //         tabList={tabListForQPMC2}
        //         activeTabValue={"Version 1"}
        //     />
        // );
      } else if (activeSubTabValue === 'CV Review') {
        // return <PreferenceList studentId={studentId} productId={productId} />;
        return <DocumentComponent {...renderProps} />;
        // } else if (activeSubTabValue === "QPMC 2 Report") {
        //     // return <PreferenceList studentId={studentId} productId={productId} />;
        //     return <DocumentComponentForReport {...renderProps} />;
      } else if (activeSubTabValue === 'QPMC 1 call schedule') {
        // return <PreferenceList studentId={studentId} productId={productId} />;
        // return (
        //     <MomIndex
        //         activeTabValue={"QPMC 1"}
        //         activeStateValue={activeStateValue}
        //     />
        // );
      } else if (activeSubTabValue === 'QPMC 2 call schedule') {
        // return <PreferenceList studentId={studentId} productId={productId} />;
        // return (
        //     <MomIndex
        //         activeTabValue={"QPMC 2"}
        //         activeStateValue={activeStateValue}
        //     />
        // );
      } else {
        return null;
      }
    }
  };

  const handleTabChange = (e, newValue) => {
    if (newValue === '1cb5ebc8-19fd-11ed-861d-0242ac120002') {
      // dispatch(
      //   getDocumentModelBySubStageId(
      //     studentId,
      //     productId,
      //     "c4971d3a-b4a4-11ec-b909-0242ac120002"
      //   )
      // );
      // let arr = steps.filter(({ sectionName }) => sectionName === newValue);
      // let newSectionId = arr.length !== 0 ? arr[0]["id"] : null;
      // setState({ ...state, activeTabValue: newValue, sectionId: newSectionId });
    } else {
      if (newValue === 'QPMC 1') {
        let Tabsteps1 = studentStages?.data
          ?.find((el) => el.stepName === 'Profile Mentoring')
          ?.steps?.find((el) => el.stepName === 'QPMC 1')?.steps;

        let arr1 = Tabsteps1.find(({ stepName }) => stepName === 'CV Review');

        setState({
          ...state,
          activeTabValue: newValue,
          activeSubTabValue: 'CV Review',
          activeStateValue: arr1?.id,
        });
      } else if (newValue === 'QPMC 2') {
        let Tabsteps1 = studentStages?.data
          ?.find((el) => el.stepName === 'Profile Mentoring')
          ?.steps?.find((el) => el.stepName === 'QPMC 2')?.steps;

        let arr1 = Tabsteps1.find(({ stepName }) => stepName === 'CV Review');

        setState({
          ...state,
          activeTabValue: newValue,
          activeSubTabValue: 'CV Review',
          activeStateValue: arr1?.id,
        });
      } else {
        let Tabsteps = getproductsteps?.steps?.find((el) => el.stepName === 'Completed Cv').steps;
        let arr = Tabsteps.filter(({ stepName }) => stepName === newValue);
        let newSectionId = arr.length !== 0 ? arr[0]['id'] : null;
        setState({
          ...state,
          activeTabValue: newValue,
          sectionId: newSectionId,
        });
      }
    }
  };
  const handleMainTabChange = (e, newValue) => {
    console.log(newValue, '%%% spain %%%');
    if (newValue === 'Profile Mentoring') {
      setState({
        ...state,
        activeMainTabValue: newValue,
        activeTabValue: 'QPMC 1',
        activeSubTabValue: 'CV Review',
      });
    } else if (newValue === 'Profile Gap Analysis') {
      setState({
        ...state,
        activeMainTabValue: newValue,
        // activeTabValue: "QPMC 1",
        // activeSubTabValue: "CV Review",
      });
    }
  };

  const handleSubTabChange = (e, newValue) => {
    if (newValue === '1cb5ebc8-19fd-11ed-861d-0242ac120002') {
      dispatch(
        getDocumentModelBySubStageId(
          student,
          product,

          '1cb5ebc8-19fd-11ed-861d-0242ac120002'
        )
      );

      let arr = steps.filter(({ id }) => id === newValue);

      let newSectionId = arr.length !== 0 ? arr[0]['id'] : null;
      setState({
        ...state,
        activeSubTabValue: 'CV Review',
        SubsectionId: newSectionId,
        activeStateValue: 'CV Review',
      });
    } else {
      let Tabsteps1 = studentStages?.data
        ?.find((el) => el.stepName === 'Profile Mentoring')
        ?.steps?.find((el) => el.stepName === 'QPMC 1')?.steps;

      let arr1 = Tabsteps1.filter(({ id }) => id === newValue);

      let newSectionId1 = arr1.length !== 0 ? arr1[0]['id'] : null;
      let Tabsteps2 = studentStages?.data
        ?.find((el) => el.stepName === 'Profile Mentoring')
        ?.steps?.find((el) => el.stepName === 'QPMC 2')?.steps;

      let arr2 = Tabsteps2.filter(({ id }) => id === newValue);

      let newSectionId2 = arr2.length !== 0 ? arr2[0]['id'] : null;
      setState({
        ...state,
        activeSubTabValue: arr2?.[0]?.['stepName']
          ? arr2?.[0]?.['stepName']
          : arr1?.[0]?.['stepName'],
        activeStateValue: 'CV Review',
        SubsectionId: newSectionId1 ? newSectionId1 : newSectionId2,
      });
    }
  };

  const renderTabs = () => {
    let Tabsteps = studentStages?.data?.find((el) => el.stepName === 'Profile Mentoring')?.steps;
    console.log(Tabsteps, '*** tab data **8');
    let TabData = Tabsteps?.filter((item) => item.stepName != 'School Research');
    console.log(TabData, '&&&&&');
    return TabData !== 0
      ? TabData?.map(({ sectionName, id, stepName }, index) => (
          <CustomTab value={stepName} label={stepName} id={`${id}${index}`} minHeight={'72px'} />
        ))
      : null;
  };
  const renderMainTabs = () => {
    let Tabsteps = studentStages?.data?.filter((el) => el.stepName === 'Profile Mentoring');
    console.log(Tabsteps, '@@@@@@');
    // let TabData = Tabsteps?.filter((item) => item.stepName != "School Research");
    // console.log(TabData, "&&&&&")
    return Tabsteps !== 0
      ? Tabsteps?.map(({ sectionName, id, stepName }, index) => (
          <CustomTab value={stepName} label={stepName} id={`${id}${index}`} minHeight={'72px'} />
        ))
      : null;
  };

  const handleSnackClose = () => {
    setState({ ...state, snackOpen: false, snackVariant: '', snackMsg: '' });
  };
  const handleQPMC1enable = () => {
    dispatch(
      getEnableCall(
        '4970547e-7dd2-4afb-b994-545276783e3f',
        '7e3d19b6-ea88-4f55-81d3-1ec5d19f8f5a',
        'qpmc1enablemail'
      )
    );
  };
  const handleQPMC2enable = () => {
    dispatch(
      getEnableCall(
        '4970547e-7dd2-4afb-b994-545276783e3f',
        '7e3d19b6-ea88-4f55-81d3-1ec5d19f8f5a',
        'qpmc2enablemail'
      )
    );
  };

  useEffect(() => {
    if (enableStage?.success === true) {
      setState({
        ...state,
        snackOpen: true,
        snackMsg: 'Mail Sent To User',
        snackVariant: 'success',
      });
      setQpmc1Notif(false);
      dispatch(clearCustomData('enableStage'));
      dispatch(
        getDisableButtonCall(
          '4970547e-7dd2-4afb-b994-545276783e3f',
          '7e3d19b6-ea88-4f55-81d3-1ec5d19f8f5a'
        )
      );
      setTimeout(() => {
        console.log(enableStage);
        console.log(disableStage);
      }, 5000);
    }
  }, [enableStage]);

  const handleClickAway = () => {
    setState({ ...state, anchorEl: null, popoverComment: null });
  };
  let TabstepsQPMC1 = studentStages?.data
    ?.find((el) => el.stepName === 'Profile Mentoring')
    ?.steps?.find((el) => el.stepName === 'QPMC 1')?.steps;
  let TabstepReview1 = TabstepsQPMC1?.filter((item) => item?.stepName === 'CV Review');
  console.log(TabstepReview1, '777777');
  let stageName = studentStages?.data?.find((el) => el.stepName === 'Profile Mentoring');
  console.log(stageName, '8888');
  let TabstepsQPMC2 = studentStages?.data
    ?.find((el) => el.stepName === 'Profile Mentoring')
    ?.steps?.find((el) => el.stepName === 'QPMC 2')?.steps;
  let TabstepReview2 = TabstepsQPMC2?.filter((item) => item?.stepName === 'CV Review');

  let a = [];
  if (TabstepReview1) {
    a = [...TabstepReview1]?.sort((a, b) => a.orderNo - b.orderNo);
  }
  let b = [];
  if (TabstepReview2) {
    b = [...TabstepReview2]?.sort((a, b) => a.orderNo - b.orderNo);
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', margin: '10px' }}>
        <img
          src={BackButton}
          style={{ cursor: 'pointer', marginTop: '-10px' }}
          onClick={() => props.history.goBack()}
        />
        <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />}>
          <Typography
            style={{ cursor: 'pointer', fontWeight: '600', marginLeft: '10px' }}
            onClick={() => props.history.push(studentPath)}
          >
            Home
          </Typography>
          <Typography style={{ cursor: 'pointer', fontWeight: '600' }}>Manage Client</Typography>
          {/* <Typography style={{ cursor: "pointer", fontWeight: "600" }}>
                Files
            </Typography> */}
        </Breadcrumbs>
      </div>

      <div className={classes.preStrategyWorkSheetContainer}>
        <Grid container>
          {/* <Grid item lg={12} xs={12} xl={12} md={12} sm={12}>
                        <Box display={"flex"} alignItems={"center"}>
                            <Box flex={1}>
                                <CustomTabs value={activeMainTabValue} onChange={handleMainTabChange}>
                                    {renderMainTabs()}
                                </CustomTabs>
                            </Box>
                        </Box>
                        {/* <Divider className={classes.dividerStyle} /> */}
          {/* <Divider />
                    </Grid>  */}
          {/* <Grid item lg={12} xs={12} xl={12} md={12} sm={12}>
                        <Box display={"flex"} alignItems={"center"}>
                            <Box flex={1}>
                                <CustomTabs value={activeTabValue} onChange={handleTabChange}>
                                    {renderTabs()}
                                </CustomTabs>
                            </Box>
                        </Box>
                        <Divider />
                    </Grid> */}
          {/* {activeTabValue === "QPMC 1" && (
                        <>
                            <StyledButton
                                className={classes.enableButton}
                                variant={"contained"}
                                style={
                                    customTheme["palette"][disableStage?.data?.enableQPMC1 === true ? "disabled" :
                                        "contained"
                                    ]
                                }
                                onClick={handleQPMC1enable}
                                disabled={disableStage?.data?.enableQPMC1 === true}

                            >
                                {"Enable QPMC 1"}
                            </StyledButton>
                            {console.log(disableStage?.data?.enableQPMC1)}
                            <Grid item lg={12} xs={12} xl={12} md={12} sm={12}>
                                <Box display={"flex"} alignItems={"center"}>
                                    <Box flex={1}>
                                        <CustomTabsPM
                                            value={activeStateValue}
                                            handleChange={handleSubTabChange}
                                            tabList={a}
                                            style={{ margin: "21px" }}
                                        ></CustomTabsPM>
                                    </Box>
                                </Box>
                                <Divider className={classes.dividerStyle} />
                            </Grid>
                        </>
                    )} */}

          {/* {activeTabValue === "QPMC 2" && (
                        <>
                            <StyledButton
                                className={classes.enableButton}
                                variant={"contained"}
                                style={
                                    customTheme["palette"][disableStage?.data?.enableQPMC2 === true ? "disabled" :
                                        "contained"
                                    ]
                                }
                                onClick={handleQPMC2enable}
                                disabled={disableStage?.data?.enableQPMC2 === true}

                            >
                                {"Enable QPMC 2"}
                            </StyledButton>
                           
                        </>
                    )} */}
          <Grid item lg={12} xs={12} xl={12} md={12} sm={12}>
            <Box display={'flex'} alignItems={'center'}>
              <Box flex={1}>
                <CustomTabsPM
                  value={activeStateValue}
                  handleChange={handleSubTabChange}
                  tabList={b}
                  style={{ margin: '21px' }}
                ></CustomTabsPM>
              </Box>
            </Box>
            <Divider className={classes.dividerStyle} />
          </Grid>
          {activeTabValue && activeSubTabValue && (
            <Grid item lg={12} xs={12} xl={12} md={12} sm={12}>
              {renderComponent()}
            </Grid>
          )}
        </Grid>
        <CommentBoxPopper
          anchorEl={anchorEl}
          handleClickAway={handleClickAway}
          popperComment={popoverComment}
        />
        <MySnackBar
          onClose={handleSnackClose}
          snackOpen={snackOpen}
          snackVariant={snackVariant}
          snackMsg={snackMsg}
        />
        <Backdrop className={classes.backdrop} open={loading}>
          <Loader />
        </Backdrop>
      </div>
    </>
  );
}

export default ProfileMentoring;
