import { WALL } from '../Redux/Action';
import axios from 'axios';
import { errorHandler } from '../Component/Utils/Helpers';
import { catchError } from '../Component/Utils/Helpers';

const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;

export const listWallPosts = (status, type, page = 0, search = '') => async (dispatch) => {
  try {
    dispatch({ type: WALL.LIST_REQUEST });

    const { data } = await axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/api/v1/wallpost?isEvent=${type}&activeStatus=${status}&page=${page -
        1}&size=6&title=${search}`,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    dispatch({
      type: WALL.LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WALL.LIST_FAIL,
      payload:
        error.response && error.response.message ? error.response.data.message : error.message,
    });
  }
};

// Create Success Story
export const createWallSuccessStory = (post) => async (dispatch) => {
  try {
    dispatch({
      type: WALL.CREATE_STORY_REQUEST,
    });
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/v1/successstories`,
      post,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    dispatch({
      type: WALL.CREATE_STORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message ? error.response.data.message : error.message;

    dispatch({
      type: WALL.CREATE_STORY_FAIL,
      payload: message,
    });
  }
};

// Update Success Story
export const updateWallSuccessStory = (post) => async (dispatch) => {
  try {
    dispatch({
      type: WALL.UPDATE_STORY_REQUEST,
    });
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/v1/successstories`,
      post,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    dispatch({
      type: WALL.UPDATE_STORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message ? error.response.data.message : error.message;

    dispatch({
      type: WALL.UPDATE_STORY_FAIL,
      payload: message,
    });
  }
};

//delete all 3 success story images
export const deleteSuccessStoryImages = (id, callback) => {
  return (dispatch) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/api/v1/wallfile/${id}`, {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

// Success Story Homepage GET call
export const listWallStory = (page = 0) => async (dispatch) => {
  try {
    dispatch({ type: WALL.LIST_STORY_REQUEST });
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/successstories/viewall?page=${page - 1}&size=6`,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    dispatch({
      type: WALL.LIST_STORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WALL.LIST_STORY_FAIL,
      payload:
        error.response && error.response.message ? error.response.data.message : error.message,
    });
  }
};

// Success Story pre populated data on edit btn click
export const showWallStory = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/successstories/${id}`,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    dispatch({
      type: WALL.GET_STORY_BY_ID,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(WALL.GET_STORY_BY_ID, error, false));
  }
};

export const listAllWallPosts = (status, type) => async (dispatch) => {
  try {
    dispatch({ type: WALL.LIST_REQUEST });

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/wallpost/test?isEvent=${type}&activeStatus=${status}&page=0&size=1000`,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    dispatch({
      type: WALL.LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WALL.LIST_FAIL,
      payload:
        error.response && error.response.message ? error.response.data.message : error.message,
    });
  }
};
export const listWallWebinars = (page = 0, type) => async (dispatch) => {
  let department = window.sessionStorage.getItem('department');

  try {
    dispatch({ type: WALL.WEBINAR_LIST_REQUEST });

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/wallpost/webinarlist`,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
        params: {
          page: page - 1,
          size: 6,
          activeStatus: type ? type : '',
          department: department,
        },
      }
    );

    dispatch({
      type: WALL.WEBINAR_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WALL.WEBINAR_LIST_FAIL,
      payload:
        error.response && error.response.message ? error.response.data.message : error.message,
    });
  }
};

export const listAllWallWebinars = () => async (dispatch) => {
  let department = window.sessionStorage.getItem('department');
  try {
    dispatch({ type: WALL.WEBINAR_LIST_REQUEST });

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/wallpost/webinarlist`,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
        params: {
          page: 0,
          size: 1000,
          department: department,
        },
      }
    );

    dispatch({
      type: WALL.WEBINAR_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WALL.WEBINAR_LIST_FAIL,
      payload:
        error.response && error.response.message ? error.response.data.message : error.message,
    });
  }
};

export const getWallCategories = (status) => async (dispatch) => {
  let department = window.sessionStorage.getItem('department');
  try {
    dispatch({ type: WALL.WALL_CATEGORIES_REQUEST });

    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/wallcategory`, {
      crossDomain: true,
      headers: {
        admin: 'yes',
        Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
      },
      params: {
        activeStatus: status,
        department: department,
      },
    });

    dispatch({
      type: WALL.WALL_CATEGORIES_SUCCESS,
      payload: data.content,
    });
  } catch (error) {
    dispatch({
      type: WALL.WALL_CATEGORIES_FAIL,
      payload:
        error.response && error.response.message ? error.response.data.message : error.message,
    });
  }
};
export const getWallConcepts = (getcourseId) => {
  // let params = { allowSubject: true };
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/lms/webinar/concepts?courseId=` + getcourseId, {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        dispatch({
          type: WALL.CONCEPTS,
          payload: response.data.data,
        });
      })
      .catch((error) => console.log(error));
  };
};
export const getWallAccounts = () => {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/lms/webinar/accounts`, {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        dispatch({
          type: WALL.ACCOUNTS,
          payload: response.data.data,
        });
      })
      .catch((error) => console.log(error));
  };
};
export const getWallJobList = (status) => async (dispatch) => {
  try {
    dispatch({ type: WALL.WALL_JOB_LIST_REQUEST });

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/jobcategories?activeStatus=${status}`,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    dispatch({
      type: WALL.WALL_JOB_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: WALL.WALL_JOB_LIST_FAIL,
      payload:
        error.response && error.response.message ? error.response.data.message : error.message,
    });
  }
};

export const deleteWallPost = (id) => async (dispatch) => {
  try {
    dispatch({
      type: WALL.DELETE_REQUEST,
    });

    await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/wallpost/${id}`, {
      crossDomain: true,
      headers: {
        admin: 'yes',
        Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
      },
    });
    dispatch({
      type: WALL.DELETE_SUCCESS,
    });
  } catch (error) {
    console.log(error.message);
    const message =
      error.response && error.response.data.message ? error.response.data.message : error.message;

    dispatch({
      type: WALL.DELETE_FAIL,
      payload: message,
    });
  }
};

//Create Wallpost
export const createWallPost = (data, callback) => {
  let accessToken = window.sessionStorage.getItem('accessToken');

  return async (dispatch) => {
    try {
      dispatch({
        type: WALL.CREATE_REQUEST,
      });
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/v1/wallpost`, data, {
          crossDomain: true,
          headers: {
            admin: 'yes',
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          callback(response);
          dispatch({
            type: WALL.CREATE_SUCCESS,
            payload: response.data,
          });
        });
    } catch (error) {
      dispatch(errorHandler(WALL.CREATE_FAIL, error, false));
    }
  };
};

//update Wallpost
export const updateWallPost = (post) => async (dispatch) => {
  try {
    dispatch({
      type: WALL.UPDATE_REQUEST,
    });
    const { data } = await axios
      .put(`${process.env.REACT_APP_API_URL}/api/v1/wallpost/${post.id}`, post, {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((res) => console.log(res));
    dispatch({
      type: WALL.UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message ? error.response.data.message : error.message;
    dispatch({
      type: WALL.UPDATE_FAIL,
      payload: message,
    });
  }
};

// Placement drive pre populated data on edit btn click
export const showDriveData = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/wallpost/${id}`, {
      crossDomain: true,
      headers: {
        admin: 'yes',
        Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
      },
    });
    dispatch({
      type: WALL.GET_PD_BY_ID,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(WALL.GET_PD_BY_ID, error, false));
  }
};

export const uploadImage = (image, callback) => {
  return (dispatch) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/files/upload/lms/webinar/hostImage`, image, {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getPlatforms = () => {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/platforms`, {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        dispatch({
          type: WALL.PLATFORMS,
          payload: response.data.data,
        });
      })
      .catch((error) => console.log(error));
  };
};

export const getCourses = () => {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/lms/webinar/courses`, {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        dispatch({
          type: WALL.VIEWCOURSES,
          payload: response.data.data,
        });
      })
      .catch((error) => console.log(error));
  };
};
// export const postRecordedVideoUrl = (webinarId, url) => {
//   return dispatch => {
//     axios.post(
//       `${process.env.REACT_APP_API_URL}/api/v1/webinar/${webinarId}/record/video`,
//       {
//         crossDomain: true,
//         headers: {
//           admin: "yes",
//           Authorization: `Bearer ${window.sessionStorage.getItem(
//             "accessToken"
//           )}`,
//         },
//         params: {
//           url,
//         },
//       }
//     ).tehn;
//   };
// };

export const postRecordedVideoUrl = (webinarId, url, callback) => {
  return (dispatch) => {
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/v1/lms/webinar/${webinarId}/record/video`,
        {},
        {
          crossDomain: true,
          headers: {
            admin: 'yes',
            Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
          },
          params: {
            url,
          },
        }
      )
      .then((response) => {
        console.log('hello');
        callback(response.data);
      })
      .catch((error) => {
        console.log(error);
        callback(error.response.data);
      });
  };
};

export const getStudentEventStatus = (eventId, callback) => {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/event/${eventId}/wallstep/status`, {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        console.log(error);
        callback(error?.response?.data);
      });
  };
};

export const getStudentList = (eventId, pageNo, roundId, callback) => {
  return (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/event/${eventId}/wallstep/${roundId}/result?page=${pageNo}&size=100`,
        {
          crossDomain: true,
          headers: {
            admin: 'yes',
            Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
          },
        }
      )
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        console.log(error);
        callback(error.response?.data);
      });
  };
};

export const updateStudentEventStatus = (eventId, data, callback) => {
  return (dispatch) => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/api/v1/event/${eventId}/wallstep/status`, data, {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        console.log(error);
        callback(error.response?.data);
      });
  };
};

// PD - Master Sheet download - result page
export const pdMasterSheetDownload = (id, data, callback) => {
  let accessToken = window.sessionStorage.getItem('accessToken');
  return () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/event/${id}/filter/result/page`, data, {
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'blob',
      })
      .then((result) => {
        callback(result);
      })
      .catch((error) => {
        callback(catchError(error));
      });
  };
};

// PD - CV download - result page
export const cvDownload = (id, data, callback) => {
  let accessToken = window.sessionStorage.getItem('accessToken');
  return () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/event/${id}/filter/cv`, data, {
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'blob',
      })
      .then((result) => {
        callback(result);
      })
      .catch((error) => {
        callback(catchError(error));
      });
  };
};

// PD Master Sheet download - home page
export const driveMasterSheetDownload = (id, callback) => {
  let accessToken = window.sessionStorage.getItem('accessToken');

  return async (dispatch) => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/api/v1/events/${id}/export/excel`, {
          headers: {
            admin: 'yes',
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          callback(response);
          dispatch({
            type: WALL.PDMASTERSHEET,
            payload: response.data,
          });
        });
    } catch (error) {
      callback(catchError(error));
    }
  };
};

// Drive Tracking - Get Drive Data
export const getAllDriveList = (page, searchValue) => async (dispatch) => {
  try {
    dispatch({ type: WALL.LOADER });
    const { data } = await axios.get(`${baseUrl}/adminimprovements/drivetracking`, {
      crossDomain: true,
      headers: {
        admin: 'yes',
        Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
      },
      params: {
        page: page,
        size: 6,
        search: searchValue,
      },
    });
    dispatch({
      type: WALL.GET_DRIVE_BY_ID,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(WALL.GET_DRIVE_BY_ID, error, false));
  }
};

// Drive Tracking Sheet Download
export const driveTrackingSheetDownload = (data, callback) => {
  let accessToken = window.sessionStorage.getItem('accessToken');
  return () => {
    axios
      .post(`${baseUrl}/adminimprovements/drivetracking/report`, data, {
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'blob',
      })
      .then((result) => {
        callback(result);
      })
      .catch((error) => {
        callback(catchError(error));
      });
  };
};

// Drive Tracking Get DropDown Data
export const getRoundUpdateData = () => async (dispatch) => {
  try {
    dispatch({ type: WALL.LOADER });
    const { data } = await axios.get(`${baseUrl}/adminimprovements/roundstatus`, {
      crossDomain: true,
      headers: {
        admin: 'yes',
        Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
      },
    });
    dispatch({
      type: WALL.GET_ROUND_UPDATE_BY_ID,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(WALL.GET_ROUND_UPDATE_BY_ID, error, false));
  }
};

// Drive Tracking Update Drive Data
export const updateRoundUpdatedData = (datas) => async (dispatch) => {
  try {
    dispatch({ type: WALL.LOADER });
    const { data } = await axios.put(`${baseUrl}/adminimprovements/roundstatus`, datas, {
      crossDomain: true,
      headers: {
        admin: 'yes',
        Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
      },
    });
    dispatch({
      type: WALL.UPDATE_ROUND_UPDATE_BY_ROUND_MESSAGE,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(WALL.UPDATE_ROUND_UPDATE_BY_ROUND_MESSAGE, error, false));
  }
};

// Drive Result WhatsApp Sent
export const sentWhatsappMSG = (id, datas) => async (dispatch) => {
  try {
    dispatch({ type: WALL.LOADER });
    const { data } = await axios.post(
      `${baseUrl}/adminimprovements/wallstep/${id}/whatsappmessage`,
      datas,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    dispatch({
      type: WALL.SENT_WHATSAPP_MSG_BY_ID,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(WALL.SENT_WHATSAPP_MSG_BY_ID, error, false));
  }
};

// Drive tracking global filter
export const driveFilter = (page, datas) => async (dispatch) => {
  try {
    dispatch({ type: WALL.LOADER });
    const { data } = await axios.post(
      `${baseUrl}/adminimprovements/drivetracking/filter?page=${page}&size=6`,
      datas,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    dispatch({
      type: WALL.FILTER_DRIVE_BY_VALUE,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(WALL.FILTER_DRIVE_BY_VALUE, error, false));
  }
};

/*Wall -> What's New ----------- */
// Wall -> User Type dropdown
export const getWallUserType = () => async (dispatch) => {
  let department = window.sessionStorage.getItem('department');
  try {
    dispatch({ type: WALL.WALL_USERTYPE_REQUEST });

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/whatsnewsections/usertypes`,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    dispatch({
      type: WALL.WALL_USERTYPE_SUCCESS,
      payload: data?.data,
    });
  } catch (error) {
    dispatch({
      type: WALL.WALL_USERTYPE_FAIL,
      payload:
        error.response && error.response.message ? error.response.data.message : error.message,
    });
  }
};

//Wall -> Redirection dropdown
export const getWallRedirectionList = () => async (dispatch) => {
  try {
    dispatch({ type: WALL.WALL_REDIRECTIONTYPE_REQUEST });

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/whatsnewsections/redirectionurl`,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    dispatch({
      type: WALL.WALL_REDIRECTIONTYPE_SUCCESS,
      payload: data?.data,
    });
  } catch (error) {
    dispatch({
      type: WALL.WALL_REDIRECTIONTYPE_FAIL,
      payload:
        error.response && error.response.message ? error.response.data.message : error.message,
    });
  }
};

// Create Whats New
export const createWallWhatsNew = (post) => async (dispatch) => {
  try {
    dispatch({
      type: WALL.CREATE_WHATSNEW_REQUEST,
    });
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/v1/whatsnewsections`,
      post,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    dispatch({
      type: WALL.CREATE_WHATSNEW_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message ? error.response.data.message : error.message;

    dispatch({
      type: WALL.CREATE_WHATSNEW_FAIL,
      payload: message,
    });
  }
};

// Update Whats New
export const updateWallWhatsNew = (post) => async (dispatch) => {
  try {
    dispatch({
      type: WALL.UPDATE_WHATSNEW_REQUEST,
    });
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/v1/whatsnewsections`,
      post,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    dispatch({
      type: WALL.UPDATE_WHATSNEW_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message ? error.response.data.message : error.message;

    dispatch({
      type: WALL.UPDATE_WHATSNEW_FAIL,
      payload: message,
    });
  }
};

// What's New Homepage GET call
export const listWallWhatsNew = (page = 0) => async (dispatch) => {
  try {
    dispatch({ type: WALL.LIST_WHATSNEW_REQUEST });
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/whatsnewsections?size=6&page=${page - 1}`,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    dispatch({
      type: WALL.LIST_WHATSNEW_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WALL.LIST_STORY_FAIL,
      payload:
        error.response && error.response.message ? error.response.data.message : error.message,
    });
  }
};

// What's New homepage delete call
export const deleteWallWhatsNew = (id) => async (dispatch) => {
  try {
    dispatch({
      type: WALL.DELETE_WHATSNEW_REQUEST,
    });

    await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/whatsnewsections/${id}`, {
      crossDomain: true,
      headers: {
        admin: 'yes',
        Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
      },
    });
    dispatch({
      type: WALL.DELETE_WHATSNEW_SUCCESS,
    });
  } catch (error) {
    console.log(error.message);
    const message =
      error.response && error.response.data.message ? error.response.data.message : error.message;

    dispatch({
      type: WALL.DELETE_WHATSNEW_FAIL,
      payload: message,
    });
  }
};

// What's New pre populated data on edit btn click
export const showWallWhatsNew = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/whatsnewsections/${id}`,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    dispatch({
      type: WALL.GET_WHATSNEW_BY_ID,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(WALL.GET_WHATSNEW_BY_ID, error, false));
  }
};
/*Wall -> What's New ---------*/

/*Wall -> Story ----------- */
// Wall -> User Type dropdown
export const getStoryUserType = () => async (dispatch) => {
  let department = window.sessionStorage.getItem('department');
  try {
    dispatch({ type: WALL.WALL_STORY_USERTYPE_REQUEST });

    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/stories/usertypes`, {
      crossDomain: true,
      headers: {
        admin: 'yes',
        Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
      },
    });
    dispatch({
      type: WALL.WALL_STORY_USERTYPE_SUCCESS,
      payload: data?.data,
    });
  } catch (error) {
    dispatch({
      type: WALL.WALL_STORY_USERTYPE_FAIL,
      payload:
        error.response && error.response.message ? error.response.data.message : error.message,
    });
  }
};

//Wall -> tag dropdown
export const getStoryTagList = () => async (dispatch) => {
  try {
    dispatch({ type: WALL.WALL_TAG_REQUEST });

    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/stories/tags`, {
      crossDomain: true,
      headers: {
        admin: 'yes',
        Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
      },
    });
    dispatch({
      type: WALL.WALL_TAG_SUCCESS,
      payload: data?.data,
    });
  } catch (error) {
    dispatch({
      type: WALL.WALL_TAG_FAIL,
      payload:
        error.response && error.response.message ? error.response.data.message : error.message,
    });
  }
};

// STORY Homepage GET call
export const listWallStories = (page = 0) => async (dispatch) => {
  try {
    dispatch({ type: WALL.LIST_STORIES_REQUEST });
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/stories?page=${page - 1}&size=6`,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    dispatch({
      type: WALL.LIST_STORIES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WALL.LIST_STORIES_FAIL,
      payload:
        error.response && error.response.message ? error.response.data.message : error.message,
    });
  }
};

// Story Homepage delete call
export const deleteWallStory = (id) => async (dispatch) => {
  try {
    dispatch({
      type: WALL.DELETE_STORIES_REQUEST,
    });

    await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/stories/${id}`, {
      crossDomain: true,
      headers: {
        admin: 'yes',
        Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
      },
    });
    dispatch({
      type: WALL.DELETE_STORIES_SUCCESS,
    });
  } catch (error) {
    console.log(error.message);
    const message =
      error.response && error.response.data.message ? error.response.data.message : error.message;
    dispatch({
      type: WALL.DELETE_STORIES_FAIL,
      payload: message,
    });
  }
};

// STORY Homepage hide/show status update
export const updateHideStatus = (id, status) => async (dispatch) => {
  try {
    dispatch({ type: WALL.UPDATE_HIDDEN_STATUS_REQUEST });
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/v1/stories/${id}?hideStatus=${status}`,
      {},
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    dispatch({
      type: WALL.UPDATE_HIDDEN_STATUS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WALL.UPDATE_HIDDEN_STATUS_FAIL,
      payload:
        error.response && error.response.message ? error.response.data.message : error.message,
    });
  }
};

// Create Story
export const createWallStory = (post) => async (dispatch) => {
  try {
    dispatch({
      type: WALL.CREATE_STORIES_REQUEST,
    });
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/stories`, post, {
      crossDomain: true,
      headers: {
        admin: 'yes',
        Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
      },
    });
    dispatch({
      type: WALL.CREATE_STORIES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message ? error.response.data.message : error.message;
    dispatch({
      type: WALL.CREATE_STORIES_FAIL,
      payload: message,
    });
  }
};

// Wall -> REDIRECTION TYPE dropdown
export const getStoryRedirectionList = (post) => async (dispatch) => {
  let department = window.sessionStorage.getItem('department');
  try {
    dispatch({ type: WALL.STORY_REDIRECTIONTYPE_REQUEST });

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/stories/redirectionurl`,
      post,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    dispatch({
      type: WALL.STORY_REDIRECTIONTYPE_SUCCESS,
      payload: data?.data,
    });
  } catch (error) {
    dispatch({
      type: WALL.STORY_REDIRECTIONTYPE_FAIL,
      payload:
        error.response && error.response.message ? error.response.data.message : error.message,
    });
  }
};
/*Wall -> Story ----------- */

/*Wall -> Animated Banner ----------- */
//Empty/clear the reducer values
export const setFieldValue = (fieldName, fieldValue) => {
  return (dispatch) => {
    dispatch({
      type: WALL.setFieldValue,
      fieldName: fieldName,
      fieldValue: fieldValue,
    });
  };
};

// Animated Banner Homepage Lists
export const getAnimatedBannerLists = () => {
  let accessToken = window.sessionStorage.getItem('accessToken');

  return async (dispatch) => {
    try {
      dispatch({ type: WALL.LOADER });
      await axios
        .get(`${process.env.REACT_APP_API_URL}/api/v1/animatedbanners`, {
          headers: {
            admin: 'yes',
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          dispatch({
            type: WALL.ANIMATED_BANNER_LISTS,
            payload: response.data,
            loading: true,
          });
        });
    } catch (error) {
      dispatch(errorHandler(WALL.ANIMATED_BANNER_LISTS, error, false));
    }
  };
};

// Animated Banner delete call
export const deleteAnimatedBanner = (id) => {
  let accessToken = window.sessionStorage.getItem('accessToken');

  return async (dispatch) => {
    try {
      dispatch({ type: WALL.LOADER });
      await axios
        .delete(`${process.env.REACT_APP_API_URL}/api/v1/animatedbanners?animatedBannerId=${id}`, {
          headers: {
            admin: 'yes',
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          dispatch({
            type: WALL.DELETE_ANIMATED_BANNER,
            payload: response.data,
            loading: true,
          });
        });
    } catch (error) {
      dispatch(errorHandler(WALL.DELETE_ANIMATED_BANNER, error, false));
    }
  };
};

// Animated Banner create/update (Thumbs up) call
export const createAnimatedBanner = (payload) => {
  let accessToken = window.sessionStorage.getItem('accessToken');

  return async (dispatch) => {
    try {
      dispatch({ type: WALL.LOADER });
      await axios
        .put(`${process.env.REACT_APP_API_URL}/api/v1/animatedbanners`, payload, {
          headers: {
            admin: 'yes',
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          dispatch({
            type: WALL.CREATE_ANIMATED_BANNER,
            payload: response.data,
          });
        });
    } catch (error) {
      dispatch(errorHandler(WALL.CREATE_ANIMATED_BANNER, error, false));
    }
  };
};
/*Wall -> Animated Banner ----------- */

/*Job Description*/
export const getJobDescriptionDetails = (page, searchValue) => async (dispatch) => {
  try {
    dispatch({ type: WALL.LOADER });
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/jdpage`, {
      crossDomain: true,
      headers: {
        admin: 'yes',
        Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
      },
      params: {
        page: page,
        size: 30,
        search: searchValue,
      },
    });
    dispatch({
      type: WALL.getJobDescriptionDetails,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(WALL.getJobDescriptionDetails, error, false));
  }
};

// JD Master Sheet download
export const jdSheetDownload = (data, callback) => {
  let accessToken = window.sessionStorage.getItem('accessToken');
  return () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/jobdescription/report`, data, {
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'blob',
      })
      .then((result) => {
        callback(result);
      })
      .catch((error) => {
        callback(catchError(error));
      });
  };
};
/*Job Description*/

/*Drive Limit*/
export const getDriveLimitUserLists = () => async (dispatch) => {
  try {
    dispatch({ type: WALL.LOADER });
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/placementdrives/usertypes`,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    dispatch({
      type: WALL.getDriveLimitUserLists,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(WALL.getDriveLimitUserLists, error, false));
  }
};

export const getDriveLimitDetails = () => async (dispatch) => {
  try {
    dispatch({ type: WALL.LOADER });
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/placementdrives/limits`,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    dispatch({
      type: WALL.getDriveLimitDetails,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(WALL.getDriveLimitDetails, error, false));
  }
};

export const updateDriveLimit = (payload) => async (dispatch) => {
  try {
    dispatch({ type: WALL.LOADER });
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/v1/placementdrives/limits`,
      payload,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    dispatch({
      type: WALL.updateDriveLimit,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(WALL.updateDriveLimit, error, false));
  }
};
/*Drive Limit*/
