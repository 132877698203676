import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import MySnackBar from '../MySnackBar';
import CvViewer from '../ProfileGapAnalysis/CvViewer';
import { DocumentUploadPopup } from '../Utils/controls/DocumentUploadPopup';
import { customTheme, StyledButton } from '../Utils/controls/Styles';
import { DownloadCvTable } from '../Utils/DownloadCvTable';

function DocumentComponent({
  open,
  stepName,
  handleCancel,
  handleUpload,
  handleUploadClick,
  handleStageComplete,
  onDrop,
  handleComment,
  activeTabValue,
  handleDownload,
  handleDelete,
  tableData,
  handleChange,
  comment,
  fileName,
  fileNameHelperText,
  commentHelperText,
  file,
  disabledUploadButton,
  isDisabledFileName,
  lastestCVLoading,
  completionMessage,
  ...props
}) {
  const [state, setState] = useState({
    snackMsg: '',
    snackOpen: false,
    snackVariant: '',
  });
  const { snackMsg, snackOpen, snackVariant } = state;
  const handleSnackClose = () => {
    setState({ ...state, snackOpen: false, snackVariant: '', snackMsg: '' });
  };
  useEffect(() => {
    if (completionMessage !== '' || null) {
      setState({
        ...state,
        snackOpen: true,
        snackVariant: 'asdfsdaf',
        snackVariant: 'success',
      });
    }
  }, [completionMessage]);
  console.log('kathir');
  return (
    <Grid container>
      <Grid item xs={8}>
        {console.log(props)}
        <Box margin={'26px 20px 30px 30px'}>
          <Box textAlign={'right'}>
            {activeTabValue === 'QPMC 1' && (
              <StyledButton
                variant={'outlined'}
                color={'primary'}
                style={customTheme['palette']['outlined']}
                onClick={handleStageComplete}
              >
                {'QPMC 1 Complete'}
              </StyledButton>
            )}
            &nbsp;&nbsp;
            <StyledButton
              variant={'contained'}
              style={customTheme['palette'][disabledUploadButton ? 'disabled' : 'contained']}
              onClick={handleUploadClick}
              disabled={disabledUploadButton}
            >
              {'Upload'}
            </StyledButton>
          </Box>
          <Grid item xs={12}>
            <Box>
              <DownloadCvTable
                headers={['Version', 'Uploaded date', 'Comment', '']}
                body={tableData}
                handleComment={handleComment}
                handleDownload={handleDownload}
                handleDelete={handleDelete}
              />
            </Box>
          </Grid>
        </Box>
      </Grid>
      <MySnackBar
        onClose={handleSnackClose}
        snackOpen={snackOpen}
        snackVariant={snackVariant}
        snackMsg={snackMsg}
      />
      <Grid item xs={4}>
        {/* <CvViewer doctype={"cv"} cvloading={lastestCVLoading} {...props} /> */}
      </Grid>
      <DocumentUploadPopup
        open={open}
        title={`Upload Document | ${stepName}`}
        leftButtonText={'Upload'}
        rightButtonText={'Cancel'}
        handleLeftButton={handleUpload}
        handleRightButton={handleCancel}
        handleClose={handleCancel}
        acceptTypes={'.doc, .docx'}
        onDrop={onDrop}
        handleChange={handleChange}
        comment={comment}
        fileName={fileName}
        fileNameHelperText={fileNameHelperText}
        commentHelperText={commentHelperText}
        file={file}
        isDisabledFileName={isDisabledFileName}
      />
    </Grid>
  );
}

export default DocumentComponent;
