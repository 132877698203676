import { Box, Button, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { textToDownloadFile } from "../../../../../Component/Utils/Helpers";
import {
  clearFieldValue,
  FeedbackGivenExport,
  getFeedbackGiven,
} from "../../../../Redux/Action/Student";
import LoadingSpinner from "../../../../Utils/LoadingSpinner";
import { SnackBar } from "../../../../Utils/SnackBar";
import EditableTable from "./EditableTable";

function FeedbackGiven({ studentId, courseId }) {
  const dispatch = useDispatch();
  const { feedbackGivenReport, loading } = useSelector(
    (state) => state.LmsStudentReducer
  );

  const [data, setData] = useState([]);
  const [snack, setSnack] = useState({
    open: false,
    message: "",
    color: "",
  });

  const { open, message, color } = snack;

  const handleSnackClose = () => {
    setSnack({
      open: false,
      message: "",
      color: "",
    });
  };

  useEffect(() => {
    if (studentId && courseId) {
      dispatch(getFeedbackGiven(studentId, courseId, true));
    }
  }, [studentId, courseId]);

  useEffect(() => {
    if (feedbackGivenReport) {
      if (feedbackGivenReport.success) {
        setData([...feedbackGivenReport.data]);
      } else {
        setData([]);
        setSnack({
          open: true,
          message: feedbackGivenReport.message,
          color: "error",
        });
      }
      dispatch(clearFieldValue("feedbackGivenReport"));
    }
  }, [feedbackGivenReport]);

  const handleExport = () => {
    dispatch(
      FeedbackGivenExport(studentId, courseId, (res) => {
        if (res.success) {
          textToDownloadFile(res.data, "Student_Feedback_Report", "xlsx");
        } else {
          setSnack({
            open: true,
            message: res.message,
            color: "error",
          });
        }
      })
    );
  };

  return (
    <Box position={"relative"}>
      {loading && <LoadingSpinner loading={loading} />}
      <Box textAlign={"right"} padding={"0 0 20px !important"}>
        <Button
          variant='contained'
          onClick={handleExport}
          disabled={data.length === 0}
        >
          {"Export"}
        </Button>
      </Box>
      <EditableTable feedReportData={data} isLoading={Boolean(loading)} />
      <SnackBar
        snackData={{
          open,
          snackClose: handleSnackClose,
          snackType: color,
          message: message,
        }}
      />
    </Box>
  );
}
export default FeedbackGiven;
