import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import LivePost from './Pages/LivePost';
import DraftPost from './Pages/DraftPost';
import ScheduledPost from './Pages/ScheduledPost';
import SuccessStory from './Pages/SuccessStory';
import { Container, TopTab, TopTabs } from './Assets/Styles/WallStyles';
import Events from './Pages/Events';
import { useLocation } from 'react-router-dom';
import Webinars from './Pages/Webinars';
import RecordedVideos from './Pages/RecordedVideos';
import { LMS_ROLES } from '../../Lms/Constants';
import WhatsNew from './Pages/WhatsNew';
import Stories from './Pages/Stories';
import AnimatedBanner from './Pages/AnimatedBanner';

export const isLms_Role = (role) => {
  return LMS_ROLES.includes(role);
};

const Lms_Tabs = [
  {
    label: 'Webinars',
  },
  {
    label: 'Recorded videos',
  },
];

const WallLanding = () => {
  let role = window.sessionStorage.getItem('department');
  let location = useLocation();
  const [tabCount, setTabCount] = useState(location.tab ?? 0);

  const renderContent = (value) => {
    try {
      if (value === 0) {
        return <LivePost />;
      } else if (value === 1) {
        return <DraftPost />;
      } else if (value === 2) {
        return <ScheduledPost />;
      } else if (value === 3) {
        return <SuccessStory />;
      } else if (value === 4) {
        return <WhatsNew />;
      } else if (value === 5) {
        return <Stories />;
      } else if (value === 6) {
        return <AnimatedBanner />;
      }
      // else if (value === 3) {
      //   return <Events />;
      // }
      // else if (value === 4) {
      //   return <Webinars />;
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const renderLmsWebinarContent = (value) => {
    try {
      if (value === 0) {
        return <Webinars />;
      } else if (value === 1) {
        return <RecordedVideos />;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderLmsTab = () => {
    return Lms_Tabs.map(({ label }) => <TopTab label={label} />);
  };

  return (
    <Container>
      <Grid container>
        <Grid item md={12}>
          {isLms_Role(role) ? (
            <TopTabs
              value={tabCount}
              textColor={'inherit'}
              onChange={(e, value) => setTabCount(value)}
              aria-label='tabs'
            >
              {renderLmsTab()}
            </TopTabs>
          ) : (
            <TopTabs
              value={tabCount}
              textColor={'inherit'}
              onChange={(e, value) => setTabCount(value)}
              variant='scrollable'
              scrollButtons={false}
              aria-label='tabs'
            >
              <TopTab label='Live Posts' />
              <TopTab label='Draft Posts' />
              <TopTab label='Scheduled Posts' />
              <TopTab label='Success Story' />
              <TopTab label='What’s New' />
              <TopTab label='Stories' />
              <TopTab label='Animated Banner' />
              {/* <TopTab label='Events' /> */}
              {/* <TopTab label="Webinars" /> */}
            </TopTabs>
          )}
        </Grid>
        <Grid item md={12} overflow='auto'>
          {isLms_Role(role) ? renderLmsWebinarContent(tabCount) : renderContent(tabCount)}
        </Grid>
      </Grid>
    </Container>
  );
};

export default WallLanding;
