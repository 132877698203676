import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import CvViewer from "../ProfileGapAnalysis/CvViewer";
import { DocumentUploadPopup } from "../Utils/controls/DocumentUploadPopup";
import { DocumentUploadPopupForPostAdmit } from "../Utils/controls/DocumentUploadPopupForPostAdmit";
import { customTheme, StyledButton } from "../Utils/controls/Styles";
import { DownloadCvTablePAS } from "../Utils/DownloadCvTablePAS";
import React, { useEffect, useState } from "react";
import { deleteFilesPAS, getFilesPAS } from "../../Actions/PostAdmitServices";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function DocumentComponentForPostAdmit({
  open,
  stepName,
  handleCancel,
  handleUpload,
  handleUploadClick,
  onDrop,
  handleComment,
  handleDownload,
  handleDelete,
  tableData,
  handleChange,
  comment,
  fileName,
  fileNameHelperText,
  commentHelperText,
  file,
  disabledUploadButton,
  isDisabledFileName,
  lastestCVLoading,
  documentId,
  countryRegionId,
  countryOrRegionCond,
  ...props
}) {
  const params = useParams();
  const dispatch = useDispatch();
  const { studentId, productId } = params;
  const { postFileUpload, getFiles } = useSelector(
    (state) => state.PostAdmitServicesReducer
  );
  useEffect(() => {
    dispatch(getFilesPAS(studentId, productId));
  }, []);
  // const handleDelete = (e, fileName, documentId) => {
  // console.log("clickedthedelete");
  // console.log(documentId, "getthefileName");
  // dispatch(deleteFilesPAS(studentId, productId, documentId));
  // setTimeout(() => {
  //   dispatch(getFilesPAS(studentId, productId));
  // }, 500);
  // };
  // const [documentId, setDocumentId] = useState("");
  const needed = (value) => {
    documentId(value);
  };
  const neededNext = (value) => {
    console.log(value);
    countryRegionId(value);
  };
  const neededName = (value) => {
    console.log(value);
    countryOrRegionCond(value);
  };
  console.log(getFiles);
  return (
    <Grid container>
      <Grid item xs={8}>
        <Box margin={"26px 20px 30px 30px"}>
          <Box textAlign={"right"}>
            <StyledButton
              variant={"contained"}
              style={
                customTheme["palette"][
                  disabledUploadButton ? "disabled" : "contained"
                ]
              }
              onClick={handleUploadClick}
              disabled={disabledUploadButton}
            >
              {"Upload"}
            </StyledButton>
          </Box>
          <Grid item xs={12}>
            <Box>
              <DownloadCvTablePAS
                headers={["Document Name", "Region Name", "Uploaded date", ""]}
                body={getFiles ? getFiles?.data : ""}
                handleComment={handleComment}
                handleDownload={handleDownload}
                handleDelete={handleDelete}
              />
            </Box>
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={4}>
        {/* <CvViewer doctype={"cv"} cvloading={lastestCVLoading} {...props} /> */}
      </Grid>
      <DocumentUploadPopupForPostAdmit
        open={open}
        title={`Upload Document | Scholarship Details`}
        leftButtonText={"Upload"}
        rightButtonText={"Cancel"}
        handleLeftButton={handleUpload}
        handleRightButton={handleCancel}
        handleClose={handleCancel}
        acceptTypes={".doc, .docx,.pdf"}
        onDrop={onDrop}
        handleChange={handleChange}
        comment={comment}
        fileName={fileName}
        fileNameHelperText={fileNameHelperText}
        commentHelperText={commentHelperText}
        file={file}
        isDisabledFileName={isDisabledFileName}
        needed={needed}
        neededNext={neededNext}
        neededName={neededName}
      />
    </Grid>
  );
}

export default DocumentComponentForPostAdmit;
