import axios from "axios";
import { GRAD_SCHOOL } from "../Redux/Action";
import { URL } from "./URL";
const BASE_URL = `${URL}/api/v1`;

// grad school view
export const listOfGradSchool = (page, size, keyword) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/gradschoollist?page=${page}&size=${size}&q=${keyword}`,
      {
        crossDomain: true,
        headers: {
          admin: "yes",
          authorization: `Bearer ${window.sessionStorage.getItem(
            "accessToken"
          )}`,
        },
      }
    );

    dispatch({ type: GRAD_SCHOOL.getGradSchool, payload: data.data });
  } catch (error) {
    dispatch({
      type: GRAD_SCHOOL.getGradSchool,
      payload: error,
    });
  }
};

// grad school program view
export const listOfGradSchoolProgram = (id, page, size, keyword) => async (
  dispatch
) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/gradschoolprogram?gradSchoolId=${id}&page=${page}&size=${size}&q=${keyword}`,
      {
        crossDomain: true,
        headers: {
          admin: "yes",
          authorization: `Bearer ${window.sessionStorage.getItem(
            "accessToken"
          )}`,
        },
      }
    );

    dispatch({ type: GRAD_SCHOOL.getGradSchoolProgram, payload: data.data });
  } catch (error) {
    dispatch({
      type: GRAD_SCHOOL.getGradSchoolProgram,
      payload: error,
    });
  }
};

//grad school region list

export const getGradSclRegionList = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/regionlist`, {
      crossDomain: true,
      headers: {
        admin: "yes",
        authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`,
      },
    });
    dispatch({ type: GRAD_SCHOOL.getRegionList, payload: data.data });
  } catch (error) {
    dispatch({
      type: GRAD_SCHOOL.getRegionList,
      payload: error,
    });
  }
};

// get per grad school details

export const getPerGradSchool = (id, callback) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/gradschool/${id}
      `,
      {
        crossDomain: true,
        headers: {
          admin: "yes",
          authorization: `Bearer ${window.sessionStorage.getItem(
            "accessToken"
          )}`,
        },
      }
    );
    callback(data.data);
    dispatch({ type: GRAD_SCHOOL.getGradSchoolById, payload: data.data });
  } catch (error) {
    dispatch({
      type: GRAD_SCHOOL.getGradSchoolById,
      payload: error,
    });
  }
};

// get grad school country lists

export const getCountryLists = (regionId, subRegionId) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/country/${regionId}/${subRegionId}`,
      {
        crossDomain: true,
        headers: {
          admin: "yes",
          authorization: `Bearer ${window.sessionStorage.getItem(
            "accessToken"
          )}`,
        },
      }
    );
    dispatch({ type: GRAD_SCHOOL.getCountryList, payload: data.data });
  } catch (error) {
    dispatch({
      type: GRAD_SCHOOL.getCountryList,
      payload: error,
    });
  }
};

// get grad school sub region lists

export const getSubRegionListCall = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/subregion/${id}`, {
      crossDomain: true,
      headers: {
        admin: "yes",
        authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`,
      },
    });
    dispatch({ type: GRAD_SCHOOL.getSubRegionList, payload: data.data });
  } catch (error) {
    dispatch({
      type: GRAD_SCHOOL.getSubRegionList,
      payload: error,
    });
  }
};

// not used

export const searchOnGradSchool = (keyword) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/searchgradschoollist?keyword=${keyword}`,
      {
        crossDomain: true,
        headers: {
          admin: "yes",
          authorization: `Bearer ${window.sessionStorage.getItem(
            "accessToken"
          )}`,
        },
      }
    );
    dispatch({ type: GRAD_SCHOOL.getSearchOnGradSclList, payload: data.data });
  } catch (error) {
    dispatch({
      type: GRAD_SCHOOL.getSearchOnGradSclList,
      payload: error,
    });
  }
};

// create grad school

export const createGradSchoolList = (obj, callback) => async (dispatch) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  try {
    const { data } = await axios.put(`${BASE_URL}/gradschool`, obj, {
      crossDomain: true,
      headers: {
        admin: "yes",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    callback(data);
    dispatch({ type: GRAD_SCHOOL.createGradSchool, payload: data });
  } catch (error) {
    callback(error.response.data);
    dispatch({ type: GRAD_SCHOOL.createGradSchool, payload: error });
  }
};

// create grad school gmat

export const createGradSchoolGmatList = (obj, callback) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .put(`${BASE_URL}/gradschoolgmat`, obj, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        callback(result.data);
        dispatch({
          type: GRAD_SCHOOL.createGradSchoolGmat,
          payload: result,
        });
      })
      .catch((error) => {
        dispatch({
          type: GRAD_SCHOOL.createGradSchoolGmat,
          payload: error.response.data,
        });
      });
  };
};

// create grad school requirements

export const createGradSchoolRequirementsList = (obj, callback) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .put(`${BASE_URL}/gradschoolrequirements`, obj, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        callback(result);
        dispatch({
          type: GRAD_SCHOOL.createGradShoolRequirement,
          payload: result,
        });
      })
      .catch((error) => {
        callback(error.response.data);
        dispatch({
          type: GRAD_SCHOOL.createGradShoolRequirement,
          payload: error,
        });
      });
  };
};

// create grad school toefl

export const createGradSchoolToeflList = (obj, callback) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .put(`${BASE_URL}/gradschooltoefl`, obj, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        callback(result.data);
        dispatch({
          type: GRAD_SCHOOL.createGradSchoolToefl,
          payload: result,
        });
      })
      .catch((error) => {
        dispatch({
          type: GRAD_SCHOOL.createGradSchoolToefl,
          payload: error,
        });
      });
  };
};

// create grad school ielts

export const createGradSchoolIeltslList = (obj, callback) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .put(`${BASE_URL}/gradschoolielts`, obj, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        callback(result.data);
        dispatch({
          type: GRAD_SCHOOL.createGradSchoolIelts,
          payload: result,
        });
      })
      .catch((error) => {
        dispatch({
          type: GRAD_SCHOOL.createGradSchoolIelts,
          payload: error,
        });
      });
  };
};

// create grad school gre

export const createGradSchoolGreList = (obj, callback) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .put(`${BASE_URL}/gradschoolgre`, obj, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        console.log(result);
        callback(result.data);
        dispatch({
          type: GRAD_SCHOOL.createGradSchoolGre,
          payload: result,
        });
      })
      .catch((error) => {
        dispatch({
          type: GRAD_SCHOOL.createGradSchoolGre,
          payload: error,
        });
      });
  };
};

// create grad school program

export const createGradSchoolProgramList = (obj, callback) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .put(`${BASE_URL}/gradschoolprogram`, obj, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        callback(result.data);
        dispatch({
          type: GRAD_SCHOOL.createGradSchoolProgram,
          payload: result.data,
        });
      })
      .catch((error) => {
        callback(error);
        dispatch({
          type: GRAD_SCHOOL.createGradSchoolProgram,
          payload: error,
        });
      });
  };
};

// get grad school program per list

export const getGradSchoolProgramListById = (id, callback) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/gradschoolprogram/${id}`, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        // callback(result.data);
        dispatch({
          type: GRAD_SCHOOL.getOneGradSchoolProgramList,
          payload: result.data,
        });
      })
      .catch((error) => {
        // callback(error);
        dispatch({
          type: GRAD_SCHOOL.getOneGradSchoolProgramList,
          payload: error,
        });
      });
  };
};

// grad school degree

export const GradSchoolDegree = () => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .put(`${BASE_URL}/gradschooldegree`, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        dispatch({
          type: GRAD_SCHOOL.gradSchoolDegree,
          payload: result,
        });
      })
      .catch((error) => {
        dispatch({
          type: GRAD_SCHOOL.gradSchoolDegree,
          payload: error.response.data,
        });
      });
  };
};

// grad school category

export const GradSchoolCategoryList = () => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/gradschoolcategorylist`, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        dispatch({
          type: GRAD_SCHOOL.gradSchoolProgramCategoryList,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GRAD_SCHOOL.gradSchoolProgramCategoryList,
          payload: error,
        });
      });
  };
};

// grad school degree

export const GradSchoolDegreeList = () => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/gradschooldegree`, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        dispatch({
          type: GRAD_SCHOOL.gradSchoolProgramDegreeList,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GRAD_SCHOOL.gradSchoolProgramDegreeList,
          payload: error,
        });
      });
  };
};

// grad school field of study

export const GradSchoolFieldOfStudyList = (id) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/fieldofstudy?degreeId=${id}`, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        dispatch({
          type: GRAD_SCHOOL.gradSchoolProgramFieldOfStudyList,
          payload: result.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GRAD_SCHOOL.gradSchoolProgramFieldOfStudyList,
          payload: error,
        });
      });
  };
};

// grad school are of specialization

export const GradSchoolAreaOfSpecializationList = (id) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/areaofspecialization?aspirationBranchId=${id}`, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        dispatch({
          type: GRAD_SCHOOL.gradSchoolProgramAreaOfSpecializationList,
          payload: result.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GRAD_SCHOOL.gradSchoolProgramAreaOfSpecializationList,
          payload: error,
        });
      });
  };
};

// grad school program view

export const GradSchoolProgramList = () => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/gradschoolprogramlist`, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        dispatch({
          type: GRAD_SCHOOL.gradSchoolProgramList,
          payload: result.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GRAD_SCHOOL.gradSchoolProgramList,
          payload: error,
        });
      });
  };
};

// get grad school requirements

export const getGradSchoolRequirementsList = (id) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/getgradschoolrequirements/${id}`, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        dispatch({
          type: GRAD_SCHOOL.getGradSchoolRequirements,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GRAD_SCHOOL.getGradSchoolRequirements,
          payload: error.response?.data,
        });
      });
  };
};

// get grad school gmat

export const getGradSchoolGmatList = (id) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/getgradschoolgmat/${id}`, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        dispatch({
          type: GRAD_SCHOOL.getGradSchoolGmat,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GRAD_SCHOOL.getGradSchoolGmat,
          payload: error.response?.data,
        });
      });
  };
};

// get grad school gre

export const getGradSchoolGreList = (id) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/getgradschoolgre/${id}`, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        dispatch({
          type: GRAD_SCHOOL.getGradSchoolGre,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GRAD_SCHOOL.getGradSchoolGre,
          payload: error.response?.data,
        });
      });
  };
};

// get grad school toefl

export const getGradSchoolToeflList = (id) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/getgradschooltoefl/${id}`, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        dispatch({
          type: GRAD_SCHOOL.getGradSchoolToefl,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GRAD_SCHOOL.getGradSchoolToefl,
          payload: error.response?.data,
        });
      });
  };
};

// get grad school ielts

export const getGradSchoolIeltsList = (id) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/getgradschoolielts/${id}`, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        dispatch({
          type: GRAD_SCHOOL.getGradSchoolIelts,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GRAD_SCHOOL.getGradSchoolIelts,
          payload: error.response?.data,
        });
      });
  };
};

export const clearData = () => {
  return (dispatch) => {
    dispatch({ type: GRAD_SCHOOL.clearData });
  };
};
