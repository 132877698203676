import React, { useState, useEffect } from "react";
import { Breadcrumbs, Grid, makeStyles, Typography } from "@material-ui/core";
import { TopTab } from "../../ThirdYearWall/Assets/Styles/WallStyles";
import { TopTabs } from "../../Wall/Assets/Styles/WallStyles";
import { useHistory, useLocation, useParams } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import VideoPage from "./VideoPage";
import {
  examSpacePath,
  examSpaceUniversityPath,
  semesterPath,
  subjectPath,
} from "../../RoutePaths";
import CustomStepper from "../Components/CustomStepper";
import BackHandler from "../Components/BackHandler";
import { useDispatch } from "react-redux";
import { CreatePostContainer } from "../Assets/Styles/CreatePostStyles";
import ImportantQuestion from "./ImportantQuestion";
import NotesPage from "./NotesPage";
import PreviousYearQuestion from "./PreviousYearQuestion";
import { getAllContent } from "../../../Actions/ExamSpaceAction";

const useStyles = makeStyles((theme) => ({
  rowNo: {
    width: "30px",
  },
  chapter: {
    width: "200px",
  },
  actions: {
    width: "200px",
    textAlign: "right",
  },
  btnContainer: {
    justifyContent: "flex-end !important",
    gap: "10px",
    marginTop: "30px !important",
  },
  btnStyle: {
    borderRadius: "4px",
    width: "140px",
  },
  univBranchHeading: {
    color: "#001D3A",
    fontSize: "18px",
    margin: "0 !important",
  },
  breadCrumbsContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "0 10px",
  },
  newButton: {
    borderRadius: "26px",
    width: "160px",
    fontSize: "13px",
  },
  fieldStyle: {
    display: "flex",
  },
  doneButton: {
    padding: " 4px 40px 4px 40px",
    marginTop: "19px",
    marginLeft: "190px",
  },
  fieldError: { color: "red" },
}));

const ChapterPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  let location = useLocation();
  const search = location.search;
  const subId = new URLSearchParams(search).get("subId");
  const univId = new URLSearchParams(search).get("univId");
  const univBranchId = new URLSearchParams(search).get("univBranchId");
  const subjectName = new URLSearchParams(search).get("subjectName");
  const branchName = new URLSearchParams(search).get("branch");
  const chapter = new URLSearchParams(search).get("chapter");

  const [tabCount, setTabCount] = useState(0);
  const [content, setContent] = useState([]);
  const steps = ["University & Branch", "Semester & Subject", "Chapter"];
  let contentId = content && content[tabCount]?.id;

  useEffect(() => {
    dispatch(
      getAllContent((res) => {
        if (res.success) {
          let contentType = res?.data;
          setContent(contentType);
        }
      })
    );
  }, [dispatch]);

  const renderContent = (value) => {
    if (contentId) {
      try {
        if (value === 0) {
          return <ImportantQuestion contentId={contentId} />;
        } else if (value === 1) {
          return <NotesPage contentId={contentId} />;
        } else if (value === 2) {
          return <PreviousYearQuestion contentId={contentId} />;
        } else if (value === 3) {
          return <VideoPage contentId={contentId} />;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Grid container>
        <Grid item md={12}>
          <div className={classes.breadCrumbsContainer}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />}>
              <Typography
                style={{
                  cursor: "pointer",
                  fontWeight: "500",
                  color: "#1093FF",
                }}
                onClick={() => {
                  history.push({
                    pathname: examSpacePath,
                  });
                }}
              >
                Exam Space
              </Typography>
              <Typography
                style={{
                  cursor: "pointer",
                  fontWeight: "500",
                  color: "#1093FF",
                }}
                onClick={() => {
                  history.push(`${examSpaceUniversityPath}?univId=${univId}`);
                }}
              >
                University
              </Typography>
              <Typography
                style={{
                  cursor: "pointer",
                  fontWeight: "500",
                  color: "#1093FF",
                }}
                onClick={() => {
                  history.push(
                    `${semesterPath}?univId=${univId}&univBranchId=${univBranchId}&branch=${branchName}`
                  );
                }}
              >
                Semester
              </Typography>
              <Typography
                style={{
                  cursor: "pointer",
                  fontWeight: "500",
                  color: "#1093FF",
                }}
                onClick={() => {
                  history.push(
                    `${subjectPath}?univId=${univId}&univBranchId=${univBranchId}&branch=${branchName}&subjectName=${subjectName}&subId=${subId}`
                  );
                }}
              >
                Subject
              </Typography>
              <Typography style={{ fontWeight: "400", color: "#001D3A" }}>
                Chapter
              </Typography>
            </Breadcrumbs>
          </div>
          <div>
            <BackHandler
              title={chapter}
              pathname={subjectPath}
              search={`?univId=${univId}&univBranchId=${univBranchId}&branch=${branchName}&subjectName=${subjectName}&subId=${subId}`}
            />
          </div>

          <div>
            <CustomStepper stepsLabel={steps} activeStep={2} />
          </div>
        </Grid>
        <CreatePostContainer>
          <Grid item md={12}>
            <TopTabs
              value={tabCount}
              textColor={"inherit"}
              onChange={(e, value) => {
                setTabCount(value);
              }}
              aria-label='tabs'
            >
              {content.map((item, index) => {
                return <TopTab label={`${item.contentType}`} id={item.id} />;
              })}
            </TopTabs>
          </Grid>
          <Grid item md={12} overflow='auto'>
            {renderContent(tabCount)}
          </Grid>
        </CreatePostContainer>
      </Grid>
    </>
  );
};

export default ChapterPage;
