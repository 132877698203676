import React, { useState, useEffect } from 'react';
import { ButtonsContainer, CreatePostContainer } from '../Assets/Styles/CreatePostStyles';
import BackHandler from '../Components/BackHandler';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Formik, Form } from 'formik';
import Controls from '../../Utils/controls/Controls';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { MultipleFileUploadField } from '../Components/Upload/MultipleFileUploadField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {
  getStoryUserType,
  getStoryTagList,
  createWallStory,
  getStoryRedirectionList,
} from '../../../Actions/WallActions';
import Notification from '../../Utils/Notification';
import { useHistory, useParams } from 'react-router-dom';
import { wallPath } from '../../RoutePaths';
import ConfirmDialog from '../../Utils/ConfirmDialog';

const useStyles = makeStyles({
  root: {
    '& .MuiSelect-root': {
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '4px',
      padding: '1rem',
    },
  },
  captionStyle: {
    width: '48%',
    marginTop: 20,
    marginBottom: 15,
  },
});

const CreateStories = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { usertype } = useSelector((state) => state.getStoryUserTypeReducer);
  const { redirectiontype } = useSelector((state) => state.getWallRedirectionReducer);
  const { tags } = useSelector((state) => state.getWallTagReducer);

  const [state, setState] = useState({
    elevStoryUserTypes: [],
    elevStoryRedirectionUrl: null,
    elevStoryTags: null,
    redirectionText: '',
    supportingMedia: 'image',
    elevStoryImage: [],
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  useEffect(() => {
    dispatch(getStoryUserType());
    dispatch(getStoryTagList());
  }, [dispatch]);

  const validate = (values) => {
    if (values.supportingMedia === 'image' && values.elevStoryImage?.length === 0) {
      setNotify({
        isOpen: true,
        message: 'Please upload an image',
        type: 'error',
      });
      return false;
    }

    if (
      values.supportingMedia === 'image' &&
      values.elevStoryImage[0]?.errors[0]?.code === 'file-too-large'
    ) {
      setNotify({
        isOpen: true,
        message: 'File is larger than 2MB',
        type: 'error',
      });
      return false;
    }

    if (
      (values.supportingMedia === 'image' &&
        values.elevStoryImage[0]?.errors[0]?.code === 'file-invalid-type') ||
      (values.supportingMedia === 'image' && values.elevStoryImage[0]?.url.includes('svg'))
    ) {
      setNotify({
        isOpen: true,
        message: 'File type is wrong, Upload accepted format only',
        type: 'error',
      });
      return false;
    }

    if (values.supportingMedia === 'video' && values.elevStoryImage?.length === 0) {
      setNotify({
        isOpen: true,
        message: 'Please upload a video',
        type: 'error',
      });
      return false;
    }

    if (
      values.supportingMedia === 'video' &&
      values.elevStoryImage[0]?.errors[0]?.code === 'file-too-large'
    ) {
      setNotify({
        isOpen: true,
        message: 'File is larger than 50MB',
        type: 'error',
      });
      return false;
    }

    if (
      (values.supportingMedia === 'video' &&
        values.elevStoryImage[0]?.errors[0]?.code === 'file-invalid-type') ||
      (values.supportingMedia === 'video' && values.elevStoryImage[0]?.url.includes('webm'))
    ) {
      setNotify({
        isOpen: true,
        message: 'File type is wrong, Upload accepted format only',
        type: 'error',
      });
      return false;
    }
    return true;
  };

  const postvalidationSchema = yup.object({
    elevStoryUserTypes: yup
      .array()
      .min(1)
      .required('User type is required'),
    elevStoryRedirectionUrl: yup
      .object()
      .nullable()
      .required('Redirection is required'),
    elevStoryTags: yup
      .object()
      .nullable()
      .required('Tag is required'),
    redirectionText: yup.string().required('Redirection text is required'),
  });

  const createPost = (post) => {
    let payload = {
      redirectionText: post.redirectionText,
      supportingMedia: post.supportingMedia,
      userTypeId: post.elevStoryUserTypes.map(({ id }) => id),
      elevStoryRedirectionUrl: post.elevStoryRedirectionUrl?.redirectionUrl,
      elevStoriesMediaId: post.elevStoryImage[0]?.id,
      elevStoriesTagId: post.elevStoryTags?.id,
      hideStatus: true,
    };
    dispatch(createWallStory(payload));

    setNotify({
      isOpen: true,
      message: 'Story Created Successfully',
      type: 'success',
    });
    setTimeout(() => {
      history.push({
        pathname: wallPath,
        tab: 5,
      });
    }, 1500);
  };

  const onDiscard = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    setTimeout(() => {
      history.push({
        pathname: wallPath,
        tab: 5,
      });
    }, 1200);
    setNotify({
      isOpen: true,
      message: 'Discarded',
      type: 'warning',
    });
  };

  return (
    <>
      <BackHandler title={'Create New Story'} tab={5} />
      <CreatePostContainer>
        <Formik
          initialValues={state || []}
          validationSchema={postvalidationSchema}
          onSubmit={(values, { resetForm }) => {
            if (validate(values)) {
              createPost(values);
              resetForm();
            }
          }}
          enableReinitialize
        >
          {({ handleSubmit, errors, handleChange, values, touched, setFieldValue, submitForm }) => {
            return (
              <>
                <div className='CreatePost'>
                  <Form onSubmit={handleSubmit} autoComplete='off'>
                    <RadioGroup
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '10px',
                      }}
                      aria-label='type'
                      disabled
                      name='supportingMedia'
                      value={values.supportingMedia}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value='image'
                        control={<Radio color='primary' />}
                        label='Image'
                      />
                      <FormControlLabel
                        value='video'
                        control={<Radio color='primary' />}
                        label='Video'
                      />
                    </RadioGroup>

                    <Grid
                      item
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <FormControl className={classes.root} style={{ width: '48%' }}>
                        <Autocomplete
                          multiple
                          id='elevStoryUserTypes'
                          name='elevStoryUserTypes'
                          getOptionLabel={(option) => option?.userType}
                          options={usertype ?? []}
                          onChange={(e, value) => {
                            let payload = {
                              userType: value.map(({ userType }) => userType),
                            };
                            setFieldValue('elevStoryUserTypes', value !== null ? value : usertype);
                            dispatch(getStoryRedirectionList(payload));
                          }}
                          fullWidth
                          value={values.elevStoryUserTypes}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label='Select User type'
                              name='elevStoryUserTypes'
                              variant='outlined'
                              error={
                                touched.elevStoryUserTypes &&
                                Boolean(values.elevStoryUserTypes.length === 0)
                              }
                            />
                          )}
                          style={{
                            marginTop: '10px',
                            marginBottom: '10px',
                          }}
                        />
                      </FormControl>

                      <FormControl className={classes.root} style={{ width: '48%' }}>
                        <Autocomplete
                          id='elevStoryRedirectionUrl'
                          name='elevStoryRedirectionUrl'
                          getOptionLabel={(option) => option?.redirectionUrl}
                          options={redirectiontype ?? []}
                          onChange={(e, value) => {
                            setFieldValue('elevStoryRedirectionUrl', value);
                          }}
                          fullWidth
                          value={values.elevStoryRedirectionUrl}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label='Select Redirection'
                              name='elevStoryRedirectionUrl'
                              variant='outlined'
                              error={
                                touched.elevStoryRedirectionUrl &&
                                Boolean(values.elevStoryRedirectionUrl === null)
                              }
                            />
                          )}
                          style={{
                            marginTop: '10px',
                            marginBottom: '10px',
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <FormControl className={classes.root} style={{ width: '48%' }}>
                        <Autocomplete
                          id='elevStoryTags'
                          name='elevStoryTags'
                          getOptionLabel={(option) => option?.tagName}
                          options={tags ?? []}
                          onChange={(e, value) => {
                            setFieldValue('elevStoryTags', value);
                          }}
                          fullWidth
                          value={values.elevStoryTags}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label='Select Tag'
                              name='elevStoryTags'
                              variant='outlined'
                              error={
                                touched.elevStoryTags && Boolean(values.elevStoryTags === null)
                              }
                            />
                          )}
                          style={{
                            marginTop: '20px',
                            marginBottom: '15px',
                          }}
                        />
                      </FormControl>

                      <Controls.Input
                        label='Enter Redirection Text'
                        name='redirectionText'
                        value={values.redirectionText}
                        onChange={handleChange}
                        error={touched.redirectionText && Boolean(errors.redirectionText)}
                        className={classes.captionStyle}
                      />
                    </Grid>

                    <Grid container direction='column' style={{ width: '100%', marginTop: '10px' }}>
                      {values.supportingMedia === 'image' && (
                        <MultipleFileUploadField
                          name='elevStoryImage'
                          fileType='image'
                          isSingle={true}
                          disable={values.elevStoryImage?.length >= 1}
                          imageUrl={`${process.env.REACT_APP_API_URL}/api/v1/stories/medias/image`}
                          story={true}
                          bucketUrl={`${process.env.REACT_APP_ASSETS}/Elev8/story/media`}
                        />
                      )}

                      {values.supportingMedia === 'video' && (
                        <MultipleFileUploadField
                          name='elevStoryImage'
                          fileType='video'
                          isSingle={true}
                          disable={values.elevStoryImage?.length >= 1}
                          imageUrl={`${process.env.REACT_APP_API_URL}/api/v1/stories/medias/video`}
                          story={true}
                          videoSize={50000 * 1024}
                          maxSize={'50MB'}
                        />
                      )}
                    </Grid>
                  </Form>
                </div>

                <ButtonsContainer>
                  <Button
                    color='primary'
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: 'Are you sure to discard this post?',
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDiscard();
                        },
                      });
                    }}
                  >
                    {`Cancel`}
                  </Button>
                  <Controls.Button
                    text={'Submit'}
                    variant='contained'
                    color='primary'
                    style={{ borderRadius: '26px' }}
                    type='submit'
                    onClick={submitForm}
                  />
                </ButtonsContainer>
              </>
            );
          }}
        </Formik>
      </CreatePostContainer>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
};

export default CreateStories;
