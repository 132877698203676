/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Divider, IconButton } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import React, { Component } from "react";

import { Close } from "@material-ui/icons";
import "../../../../Assets/css/Preview/Preview.css";
import {
  Container,
  Div,
  Edit,
  Footer,
  Next,
  OverflowContainer,
  QuestionBody as Body,
  QuestionCount,
  QuestionTitle,
  TestTitle,
  TitleContainer,
  TitleHeader,
} from "../../../../Assets/css/Preview/TestComponent";
import { FlexView } from "../../../../Assets/StyledComponents";
import Passage from "./Passage";
import SingleSelect from "./SingleSelect";
import Binary from "./Binary";
import Explanation from "../../SingleUpload/preview/Explanation";
import Twopart from "./Twopart";
import DropDown from "./DropDown";
import Multitab from "./Multitab";

class Index extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    console.log("fdanjfjsf");
    console.log(this.props, "props");
  }
  renderQuestion = () => {
    const {
      questionType,
      question,
      choices,
      description,
      imgUrl,
      isHaveDescription,
      totalBundle,
      answer,
      answer1,
      optionalType,
      unit,
      multiDescription,
      tabValue,
      multiAnswerType,
      firstSelectedName,
      secondSelectedName,
    } = this.props.questions;
    if (questionType === "SINGLE_SELECT") {
      return isHaveDescription ? (
        <Passage
          description={description}
          question={question}
          choices={choices}
          selectedChoice={[]}
          // imgUrl={imgUrl}
          bundleLength={totalBundle}
        />
      ) : (
        <SingleSelect
          question={question}
          options={choices}
          selectedChoice={[]}
          description={description}
          // imgUrl={imgUrl}
        />
      );
    } else if (
      questionType === "SUBJECTIVE" ||
      questionType === "DESCRIPTIVE"
    ) {
      return (
        <Passage
          question={question}
          // para={question}
          description={description}
          subjective={true}
          imgUrl={imgUrl}
          bundleLength={totalBundle}
          answer={answer}
          unit={unit}
          optionalType={optionalType}
          isHaveDescription={isHaveDescription}
        />
      );
    } else if (questionType === "FRACTION") {
      return (
        <Passage
          // para={question}
          question={question}
          description={description}
          fraction={true}
          imgUrl={imgUrl}
          bundleLength={totalBundle}
          answer={answer}
          answer1={answer1}
          unit={unit}
          isHaveDescription={isHaveDescription}
        />
      );
    } else if (questionType === "BUNDLE") {
      return (
        <Passage
          para={question}
          description={description}
          bundle={true}
          choices={choices}
          bundleLength={Math.max.apply(
            Math,
            choices.map((item) => item.bundleNo)
          )}
          selectedChoice={[]}
          imgUrl={imgUrl}
          isHaveDescription={isHaveDescription}
          // bundleLength={totalBundle}
        />
      );
    } else if (questionType === "MULTI_CHOICE") {
      return isHaveDescription ? (
        <Passage
          description={description}
          question={question}
          choices={choices}
          selectedChoice={[]}
          imgUrl={imgUrl}
          isMulti={true}
          bundleLength={totalBundle}
        />
      ) : (
        <SingleSelect
          question={question}
          options={choices}
          selectedChoice={[]}
          description={description}
          imgUrl={imgUrl}
          isMulti={true}
        />
      );
    } else if (questionType === "BINARY") {
      return (
        <Binary
          choices={choices}
          description={description}
          question={question}
          isHaveDescription={isHaveDescription}
        />
      );
    } else if (questionType === "TWO_PART") {
      return (
        <Twopart
          choices={choices}
          description={description}
          question={question}
          isHaveDescription={isHaveDescription}
          firstSelectedName={firstSelectedName}
          secondSelectedName={secondSelectedName}
        />
      );
    } else if (questionType === "DROP_DOWN") {
      return (
        <DropDown
          choices={choices}
          description={description}
          question={question}
          isHaveDescription={isHaveDescription}
        />
      );
    } else if (questionType === "MULTI_TAB") {
      return (
        <Multitab
          choices={choices}
          description={description}
          question={question}
          isHaveDescription={isHaveDescription}
          tabValue={tabValue}
          multiAnswerType={multiAnswerType}
          multiDescription={multiDescription}
        />
      );
    }
  };

  getRemainingTime = (time) => {
    this.time = time;
    if (time === 0) {
      this.setState({ stop: true });
      this.timeOver();
    }
  };

  render() {
    const {
      type,
      totalNoOfQuestions,
      conceptName,
      testTitle,
      activeQuestion,
      explanation,
      videos,
      orderNo,
      isGmatOg,
    } = this.props.questions;

    const {
      handleEdit,
      handleNext,
      handlePreviewClose,
      questions,
    } = this.props;
    const isQuestionBank = type === "QUESTIONBANK";
    let isLastQuestion = activeQuestion === totalNoOfQuestions;
    return (
      <OverflowContainer>
        <Container>
          <IconButton style={closeButtonStyle} onClick={handlePreviewClose}>
            <Close />
          </IconButton>

          <TitleContainer style={{ minHeight: "200px" }}>
            <Div display={"flex"}>
              <TestTitle>
                {isQuestionBank ? "Question Bank Test" : testTitle}
              </TestTitle>
            </Div>
            <TitleHeader>
              {isQuestionBank ? (
                <QuestionTitle padding={"0px"}>{conceptName}</QuestionTitle>
              ) : (
                <>
                  <Div display={"flex"}>
                    <QuestionCount bold="bold">{activeQuestion}</QuestionCount>
                    <QuestionCount>/{totalNoOfQuestions}</QuestionCount>
                    <QuestionTitle>{conceptName}</QuestionTitle>
                  </Div>
                  <Div>
                    <LinearProgress variant="determinate" value={0} />
                  </Div>
                </>
              )}
              {isGmatOg && (
                <Box
                  style={{
                    display: "flex",
                    fontSize: "18px",
                    alignItems: "center",
                    float: "right",
                  }}
                >
                  OG Question Number - &nbsp;
                  <QuestionCount bold="bold">{orderNo}</QuestionCount>
                </Box>
              )}
            </TitleHeader>
          </TitleContainer>
          <Body style={{ marginTop: !isGmatOg ? "0px" : "3vh" }}>
            {this.renderQuestion()}
          </Body>
          <Footer>
            <Box width={"100%"}>
              <Divider />
            </Box>
            <Box className={"test_bottom_pad"}>
              <div />
              <div>
                <FlexView gap={"30px"}>
                  <Edit disabled={false} loading={false} onClick={handleEdit}>
                    {"Edit"}
                  </Edit>
                  <Next
                    disabled={false}
                    loading={false}
                    onClick={isLastQuestion ? handlePreviewClose : handleNext}
                  >
                    {isLastQuestion ? "Close" : "Next"}
                  </Next>
                </FlexView>
              </div>
            </Box>
          </Footer>
        </Container>
        <Explanation
          testResponse={{
            data: { video: videos, videoExplanation: explanation },
          }}
        />
      </OverflowContainer>
    );
  }
}

export default Index;

const closeButtonStyle = {
  position: "fixed",
  top: "10px",
  right: "10px",
};
