import React, { useEffect, useState } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { useDispatch, useSelector } from 'react-redux';
import * as moment from 'moment';
import ThumbUp from '@material-ui/icons/ThumbUp';
import FilterIcon from '@material-ui/icons/Tune';
import SortIcon from '@material-ui/icons/CompareArrows';
import SearchIcon from '@material-ui/icons/SearchSharp';
import ExportIcon from '@material-ui/icons/GetApp';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Check, ChevronLeft, ChevronRight } from '@material-ui/icons';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import 'date-fns';
import Notification from '../../Utils/Notification';
import {
  createAnimatedBanner,
  deleteAnimatedBanner,
  getAnimatedBannerLists,
  getStoryUserType,
  setFieldValue,
} from '../../../Actions/WallActions';
import Loader from '../../Utils/controls/Loader';

function AnimatedBanner() {
  const dispatch = useDispatch();
  const { usertype } = useSelector((state) => state.getStoryUserTypeReducer);
  const { getAnimatedLists, deleteBannerStatus, createBannerStatus, loading } = useSelector(
    (state) => state.wallAnimatedBannerReducer
  );
  const [tableData, setTableData] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });

  useEffect(() => {
    dispatch(getStoryUserType());
    dispatch(getAnimatedBannerLists());
  }, [dispatch]);

  useEffect(() => {
    if (getAnimatedLists) {
      if (getAnimatedLists.success) {
        setTableData(getAnimatedLists?.data);
        dispatch(setFieldValue('getAnimatedLists', null));
      } else {
        setTableData([]);
        dispatch(setFieldValue('getAnimatedLists', null));
      }
    }
  }, [getAnimatedLists]);

  useEffect(() => {
    if (deleteBannerStatus) {
      if (deleteBannerStatus.success) {
        dispatch(getAnimatedBannerLists());
        setNotify({
          isOpen: true,
          message: 'Animated Banner Deleted',
          type: 'success',
        });
        dispatch(setFieldValue('deleteBannerStatus', null));
      } else {
        setNotify({
          isOpen: true,
          message: deleteBannerStatus.message,
          type: 'error',
        });
        dispatch(setFieldValue('deleteBannerStatus', null));
      }
    }
  }, [deleteBannerStatus]);

  useEffect(() => {
    if (createBannerStatus) {
      if (createBannerStatus.success) {
        dispatch(getAnimatedBannerLists());
        setNotify({
          isOpen: true,
          message: 'Animated Banner Created/Updated!',
          type: 'success',
        });
        dispatch(setFieldValue('createBannerStatus', null));
      } else {
        setNotify({
          isOpen: true,
          message: createBannerStatus.message,
          type: 'error',
        });
        dispatch(setFieldValue('createBannerStatus', null));
      }
    }
  }, [createBannerStatus, loading]);

  const convertTimeFormat = (date) =>
    new Date(date).toLocaleTimeString('en-US', {
      hour: 'numeric',
      hour12: true,
      minute: 'numeric',
    });

  // Field names in the tableColumns variable must be same as the KEY of the data variable.
  const tableColumns = [
    {
      title: 'User',
      field: 'userType',
      sorting: false,
      emptyValue: () => <em>--</em>,
      editComponent: (props) => {
        return (
          <Autocomplete
            id='userType'
            name='userType'
            getOptionLabel={(option) => option.userType}
            options={usertype}
            onChange={(e, newVal) => {
              props.onChange(newVal);
            }}
            value={props.value}
            fullWidth
            renderInput={(params) => <TextField {...params} label='User' variant='outlined' />}
            multiple
          />
        );
      },
      render: (rowData) => (
        <div style={{ lineHeight: '25px' }}>
          {rowData.userType.length !== 0
            ? rowData.userType.map(({ userType }) => userType).join(', ')
            : null}
        </div>
      ),
    },
    {
      title: 'Posted on',
      field: 'createdAt',
      editable: 'false',
      sorting: true,
      type: 'date',
      dateSetting: {
        locale: 'en-GB',
      },
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) => (
        <div>
          {moment(new Date(rowData.createdAt)).format('DD MMM yyyy')} ,{' '}
          {convertTimeFormat(rowData.createdAt)}
        </div>
      ),
    },
    {
      title: 'Text',
      field: 'text',
      sorting: false,
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Posted by',
      field: 'createrEmailId',
      sorting: false,
      editable: 'false',
      emptyValue: () => <em>--</em>,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
  ];

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <MaterialTable
            columns={tableColumns}
            data={tableData}
            isLoading={Boolean(loading)}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  if (newData.text && newData.userType.length >= 1) {
                    setTimeout(() => {
                      setTableData([{ ...newData, id: null }, ...tableData]);
                      resolve();
                    }, 1000);
                  } else {
                    setNotify({
                      isOpen: true,
                      message: 'All fields are required',
                      type: 'error',
                    });
                    reject();
                  }
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  if (newData.text && newData.userType.length >= 1) {
                    setTimeout(() => {
                      const dataUpdate = [...tableData];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;
                      setTableData([...dataUpdate]);
                      resolve();
                    }, 1000);
                  } else {
                    setNotify({
                      isOpen: true,
                      message: 'All fields are required',
                      type: 'error',
                    });
                    reject();
                  }
                }),
            }}
            localization={{
              header: {
                actions: 'Actions',
              },
              body: {
                addTooltip: 'Add a new row',
                emptyDataSourceMessage: 'No records to display',
              },
            }}
            components={{
              Toolbar: (props) => (
                <div
                  style={{
                    display: 'flex',
                    marginTop: '2rem',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderBottom: '3px solid #0094b1',
                    borderTop: '3px solid #007d93',
                    borderRadius: '5px',
                  }}
                >
                  <div
                    style={{
                      flexGrow: 1,
                      padding: '1rem',
                      width: '35%',
                    }}
                  >
                    <MTableToolbar {...props} />
                  </div>
                </div>
              ),
            }}
            actions={[
              {
                icon: () => <ThumbUp style={{ color: 'green' }} />,
                tooltip: 'Create / Update',
                onClick: (e, tableData) => {
                  let payload = tableData.map((item) => ({
                    id: item.id,
                    text: item.text,
                    userTypes: item.userType.map((item) => ({
                      id: item.id,
                    })),
                  }));
                  dispatch(createAnimatedBanner(payload));
                },
              },
              {
                icon: () => <DeleteIcon style={{ color: 'red' }} />,
                tooltip: 'Delete',
                onClick: (e, tableData) => {
                  let animatedBannerId = tableData.map((item) => ({
                    id: item.id,
                  }));
                  let id = animatedBannerId[0].id;
                  dispatch(deleteAnimatedBanner(id));
                },
              },
            ]}
            options={{
              sorting: true,
              search: false,
              searchFieldAlignment: 'right',
              searchFieldVariant: 'outlined',
              filtering: false,
              paging: false,
              pageSize: 60, // make initial page size
              emptyRowsWhenPaging: false, // To avoid of having empty rows
              exportButton: false,
              exportAllData: false,
              addRowPosition: 'first',
              actionsColumnIndex: -1,
              selection: true,
              showSelectAllCheckbox: true,
              showTextRowsSelected: false,
              grouping: false,
              columnsButton: false,
              rowStyle: (tableData, index) =>
                index % 2 !== 0 ? { background: '#f5f5f5', height: '70px' } : { height: '70px' },
              headerStyle: {
                background: '#F0F8FF',
                color: '#000',
                fontWeight: 'bold',
                height: '90px',
                fontSize: '15px',
              },
            }}
            title={'Animated Banner'}
            icons={{
              Add: () => <AddBoxIcon style={{ color: '#1093FF' }} />,
              Edit: () => <EditOutlinedIcon style={{ color: '#1093FF' }} />,
              Check: Check,
              FirstPage: FirstPage,
              NextPage: ChevronRight,
              PreviousPage: ChevronLeft,
              LastPage: LastPage,
              Filter: () => <FilterIcon style={{ color: '#807f7f' }} />,
              SortArrow: () => (
                <SortIcon
                  fontSize='small'
                  style={{ color: '#c6c6c6', transform: 'rotate(-90deg)' }}
                />
              ),
              Clear: () => <ClearIcon />,
              Export: () => <ExportIcon style={{ color: 'green' }} />,
              ResetSearch: () => <ClearIcon />,
              Search: () => <SearchIcon />,
              ViewColumn: () => <ViewColumnIcon />,
            }}
          />
          <Notification notify={notify} setNotify={setNotify} />
        </div>
      )}
    </div>
  );
}

export default AnimatedBanner;
