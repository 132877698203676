import { Button } from "@mui/material";
import React from "react";
import { Typo } from "../Asset/StyledComponents/CvReview";

function CustomizedButton({
    children,
    variant,
    color,
    onClick,
    disabled,
    opacity,
    className,
    buttonName,
    type,
    ...rest
}) {
    return (
        <Button
            variant={variant}
            color={color}
            onClick={onClick}
            disabled={disabled}
            opacity={opacity}
            className={className}
            type={type}
            {...rest}
        >
            {buttonName}
        </Button>
    );
}

export default CustomizedButton;
