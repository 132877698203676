import React, { useEffect, useState } from "react";
import TableComponent from "../TableComponent/TableComponent";
import { createTheme, Grid, ThemeProvider } from "@material-ui/core";
import { addBSchool, bsProgram, rankView } from "../RoutePaths";
import { useDispatch, useSelector } from "react-redux";
import { listOfBSchool } from "../../Actions/BSchoolAction";
import { isEmptyString } from "../Validation";
export default function BSchoolIndex(props) {
  const { getAllBSchoolList } = useSelector((state) => state.BSchoolReducer);
  const [bSchoolData, setBSchoolData] = useState([]);
  const dispatch = useDispatch();
  const [search, setSearch] = useState({
    size: 20,
    page: 0,
    keyword: "",
  });
  const col = [
    {
      title: "ID",
      fieldName: "id",
    },
    { title: "Name", fieldName: "name" },
    { title: "Created By", fieldName: "createdBy" },
    { title: "Created Date", fieldName: "createdDate" },
    { title: "Updated By", fieldName: "updatedBy" },
    { title: "Updated Date", fieldName: "updatedDate" },
  ];

  const data = [
    { id: "1", name: "Booth School of Business" },
    { id: "2", name: "Samuel Curtis Johnson Graduate School of Management" },
  ];

  const tableTheme = () => {
    createTheme({
      palette: {
        primary: {
          main: "#007bff",
        },
      },
      overrides: {
        MuiTypography: {
          h6: {
            fontWeight: "bold",
          },
        },
        MuiIconButton: {
          root: {
            "&:hover": {
              backgroundColor: "none",
              borderRadius: 0,
            },
          },
        },
      },
    });
  };

  const handleClickOpen = () => {
    props.history.push({
      pathname: addBSchool,
    });
  };

  const handleEdit = (data) => {
    props.history.push({ pathname: addBSchool, state: { data } });
  };

  const handleRank = (data) => {
    props.history.push({ pathname: rankView, state: { data } });
  };

  const handleView = (data) => {
    props.history.push({ pathname: bsProgram, state: { data } });
  };

  useEffect(() => {
    dispatch(listOfBSchool(0, 20, ""));
  }, []);

  useEffect(() => {
    if (getAllBSchoolList !== null) {
      setBSchoolData(getAllBSchoolList.content);
    }
  }, [getAllBSchoolList]);

  const paginate = (page, size, keyword) => {
    setSearch({ ...search, page: page, size: size, keyword: keyword });
    if (page !== search.page || size !== search.size) {
      dispatch(listOfBSchool(page, size, keyword));
    } else if (isEmptyString(keyword)) {
      dispatch(listOfBSchool(0, 20, ""));
    } else {
    }
  };

  const handleSearch = (e) => {
    dispatch(
      listOfBSchool(
        search.page,
        search.size,
        search.keyword
        // props.location.state.data?.id
      )
    );
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={tableTheme}>
        <div>
          <Grid container>
            <Grid item md={12}>
              <TableComponent
                cols={col}
                data={bSchoolData}
                title={"B - School"}
                add={true}
                action={true}
                onAddClick={handleClickOpen}
                Rank={true}
                onAddRankClick={handleRank}
                onView={true}
                totalCount={
                  getAllBSchoolList && getAllBSchoolList.totalElements
                }
                onKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    document.getElementById("search").click();
                  }
                }}
                pageCount={getAllBSchoolList && getAllBSchoolList.totalPages}
                onViewClick={handleView}
                onEdit={true}
                onEditClick={handleEdit}
                onSearchClick={(e) => handleSearch(e)}
                onSearch={paginate}
                needSearch
                paginate={paginate}
                searchPlaceHolder="Name"
              />
            </Grid>
          </Grid>
        </div>
      </ThemeProvider>
    </React.Fragment>
  );
}
