import { Grid, makeStyles } from '@material-ui/core';
import { useField } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { SingleFileUploadWithProgress } from './SingleFileUploadWithProgress';
import { UploadError } from './UploadError';

export function MultipleFileUploadField({
  name,
  fileType,
  disable,
  isSingle,
  imageUrl,
  whatsnew,
  story,
  bucketUrl,
  videoSize,
  maxSize,
  successStory,
  removeDeleteIcon,
  isClear,
  setIsClear = () => {},
}) {
  const [fileLength, setFileLength] = useState(0);
  const useStyles = makeStyles((theme) => ({
    dropzone: {
      border: `2px dashed ${theme.palette.primary.main}`,
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 5,
      cursor: (disable || (isSingle && fileLength === 1)) && 'no-drop',
      background: theme.palette.background.default,
      height: theme.spacing(10),
      outline: 'none',
    },
    info: {
      color: 'grey',
      marginTop: 5,
      marginBottom: 10,
    },
  }));

  const [_, __, helpers] = useField(name);
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const onDrop = useCallback((accFiles, rejFiles) => {
    const mappedAcc = accFiles.map((file) => ({ file, errors: [] }));
    const mappedRej = rejFiles.map((r) => ({ ...r }));
    setFiles((curr) => [...curr, ...mappedAcc, ...mappedRej]);
  }, []);

  useEffect(() => {
    helpers.setValue(files);
    setFileLength(files.length);
  }, [files]);

  useEffect(() => {
    if (isClear) {
      setFiles([]);
      setIsClear();
    }
  }, [isClear]);

  function onUpload(file, data) {
    setFiles((curr) =>
      curr.map((fw) => {
        if (fw.file === file) {
          return { ...data, ...fw };
        }
        return fw;
      })
    );
  }

  function onDelete(file) {
    setFiles((curr) => curr.filter((fw) => fw.file !== file));
    setFileLength(0);
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: [`${fileType}/*`],
    maxSize: videoSize ? videoSize : 2000 * 1024,
    disabled: disable,
  });

  return (
    <React.Fragment>
      <Grid item>
        {isSingle && (
          <div {...getRootProps({ className: classes.dropzone })}>
            {!disable && <input {...getInputProps()} />}
            <p
              style={{ marginBottom: '-2px' }}
            >{`Drag & drop some ${fileType} here, or click to select ${fileType}`}</p>
          </div>
        )}

        {!isSingle && (
          <div {...getRootProps({ className: classes.dropzone })}>
            {!disable && <input {...getInputProps()} />}
            <p
              style={{ marginBottom: '-2px' }}
            >{`Drag & drop some ${fileType} here, or click to select ${fileType}`}</p>
          </div>
        )}

        {fileType === 'image' && (
          <p {...getRootProps({ className: classes.info })}>
            (Supported format: jpeg, PNG only, max 2MB)
          </p>
        )}
        {fileType === 'video' && (
          <p {...getRootProps({ className: classes.info })}>
            (Supported format: mp4, max {maxSize ? maxSize : '2MB'})
          </p>
        )}
        {fileType === 'audio' && (
          <p {...getRootProps({ className: classes.info })}>(Supported format: mp3, max 2MB)</p>
        )}
      </Grid>
      {files.map((fileWrapper) => (
        <Grid item key={fileWrapper.file.name}>
          {fileWrapper.errors.length ? (
            <UploadError
              file={fileWrapper.file}
              errors={fileWrapper.errors}
              onDelete={onDelete}
              url={
                successStory
                  ? successStory === 'fromImageUrl'
                    ? fileWrapper.fromImageUrl
                    : fileWrapper.toImageUrl
                  : fileWrapper.url
              }
            />
          ) : (
            <SingleFileUploadWithProgress
              onDelete={onDelete}
              onUpload={onUpload}
              file={fileWrapper.file}
              url={
                successStory
                  ? successStory === 'fromImageUrl'
                    ? fileWrapper.fromImageUrl
                    : fileWrapper.toImageUrl
                  : fileWrapper.url
              }
              fileType={fileType}
              imageUrl={imageUrl}
              whatsnew={whatsnew}
              story={story}
              bucketUrl={bucketUrl}
              removeDeleteIcon={removeDeleteIcon}
            />
          )}
        </Grid>
      ))}
    </React.Fragment>
  );
}
