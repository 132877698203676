import React, { useState, useEffect } from "react";
import {
  Breadcrumbs,
  Grid,
  makeStyles,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { TopTab } from "../../ThirdYearWall/Assets/Styles/WallStyles";
import { TopTabs } from "../../Wall/Assets/Styles/WallStyles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../Utils/controls/Loader";
import { SemesterPopover } from "../Components/SemesterPopover";
import {
  CreatePostContainer,
  TabButtonContainer,
  ButtonsContainer,
} from "../Assets/Styles/CreatePostStyles";
import { Formik, Form, FieldArray } from "formik";
import Notification from "../../Utils/Notification";
import ConfirmDialog from "../../Utils/ConfirmDialog";
import CustomStepper from "../Components/CustomStepper";
import BackHandler from "../Components/BackHandler";
import DeleteIcon from "@material-ui/icons/Delete";
import Controls from "../../Utils/controls/Controls";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {
  examSpacePath,
  examSpaceUniversityPath,
  semesterPath,
  subjectPath,
} from "../../RoutePaths";
import useTable from "../../Utils/useTable";
import {
  getAllSemester,
  addSemester,
  getAllSubject,
  addSubject,
  deleteSubject,
  deleteSemester,
  setFieldValue,
} from "../../../Actions/ExamSpaceAction";

const useStyles = makeStyles((theme) => ({
  rowNo: {
    width: "30px",
  },
  subject: {
    width: "200px",
  },
  actions: {
    width: "200px",
    textAlign: "right",
  },
  btnContainer: {
    justifyContent: "flex-end !important",
    gap: "10px",
    marginTop: "30px !important",
  },
  btnStyle: {
    borderRadius: "4px",
    width: "140px",
  },
  breadCrumbsContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "0 10px",
  },
  newButton: {
    borderRadius: "26px",
    width: "160px",
    fontSize: "13px",
  },
  fieldStyle: {
    display: "flex",
  },
}));

const headCells = [
  { id: "#", label: "#", disableSorting: true },
  { id: "subject", label: "Subject Name" },
  { id: "actions", label: "", disableSorting: true },
];

const SemesterPage = ({ act, semButtons }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  let location = useLocation();
  const search = location.search;
  const univId = new URLSearchParams(search).get("univId");
  const univBranchId = new URLSearchParams(search).get("univBranchId");
  const branchName = new URLSearchParams(search).get("branch");
  const tabCounts = new URLSearchParams(search).get("tabCount");
  const tab = Number(tabCounts);
  const steps = ["University & Branch", "Semester & Subject", "Chapter"];
  const { getSemesterList, getSubjectList } = useSelector(
    (state) => state.examSpaceReducer
  );

  const [deleteClick, setDeleteClick] = useState(false);
  const [tabCount, setTabCount] = useState(0);
  const [state, setState] = useState({
    subjectList: [{ subject: "", done: "" }],
  });
  const [open, setOpen] = React.useState(false);
  const [semButton, setSemButton] = useState([]);
  const [selectSem, setSelectSem] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [loading, setLoading] = useState(false);
  let obj = {
    universityBranchId: univBranchId,
    semesters: semButton.map((sem) => ({
      semester: sem,
    })),
  };
  let semesterId = selectSem && selectSem[tabCount]?.id;

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    subjectList,
    headCells,
    filterFn
  );

  useEffect(() => {
    dispatch(
      getAllSemester(univBranchId, (res) => {
        if (res.success) {
          let semesterData = res?.data?.examCrackerSemesters || [];
          setSelectSem(semesterData);
          let semNumber =
            semesterData.length !== 0
              ? semesterData.map(({ semester }) => parseInt(semester))
              : [];
          setSemButton(semNumber);
          setOpen(false);
          if (tab !== null || "undefined") {
            setTabCount(tab);
          } else {
            setTabCount(0);
          }
        }
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (semesterId) {
      setLoading(true);
      dispatch(
        getAllSubject(univBranchId, semesterId, (res) => {
          if (res.success) {
            setLoading(false);
            let subjectEntry = res?.data?.examCrackerSubjects;
            setSubjectList(subjectEntry);
          }
        })
      );
      dispatch(setFieldValue("getSubjectList", null));
    } else {
      setSubjectList([]);
    }
  }, [semesterId]);

  const handleSemester = (button) => {
    let tmp = [...semButton];
    if (semButton.includes(button)) {
      tmp = semButton.filter((el) => el !== button);
    } else {
      tmp.push(button);
    }
    setSemButton(tmp);
  };

  const handleDoneClick = () => {
    if (semButton.length === 0) {
      setNotify({
        isOpen: true,
        message: "Please choose atleast 1 semester",
        type: "warning",
      });
    } else {
      dispatch(
        addSemester(obj, (res) => {
          setNotify({
            isOpen: true,
            message: "Semester Added",
            type: "warning",
          });
          if (res.success) {
            setNotify({
              isOpen: true,
              message: "Semester Added Successfully",
              type: "success",
            });
            dispatch(
              getAllSemester(univBranchId, (res) => {
                if (res.success) {
                  let semesterData = res?.data?.examCrackerSemesters || [];
                  let semNumber =
                    semesterData.length !== 0
                      ? semesterData.map(({ semester }) => parseInt(semester))
                      : [];
                  setSemButton(semNumber);
                  setSelectSem(semesterData);
                  setOpen(false);
                }
              })
            );
          } else {
            setNotify({
              isOpen: true,
              message: res.message,
              type: "error",
            });
          }
        })
      );
    }
  };

  const handleSemDelete = () => {
    setConfirmDialog({
      isOpen: true,
      title: "DELETE SEMESTER?",
      subTitle: `Are you sure you want to delete the current Semester & corresponding subjects?
 It will go longer will be visible on the App.`,
      onConfirm: onSemDelete,
    });
  };

  const onSemDelete = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    dispatch(
      deleteSemester(univBranchId, semesterId, (res) => {
        if (res.success) {
          setNotify({
            isOpen: true,
            message: "Semester Deleted Successfully",
            type: "success",
          });
          dispatch(
            getAllSemester(univBranchId, (res) => {
              if (res.success) {
                let semesterData = res?.data?.examCrackerSemesters || [];
                setSelectSem(semesterData);
                let semNumber =
                  semesterData.length !== 0
                    ? semesterData.map(({ semester }) => parseInt(semester))
                    : [];
                setSemButton(semNumber);
                setOpen(false);
                setTabCount(0);
              }
            })
          );
        } else {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "error",
          });
        }
      })
    );

    setDeleteClick(true);
  };

  const handleDeleteClick = (item) => {
    setConfirmDialog({
      isOpen: true,
      title: "DELETE SUBJECT?",
      subTitle: `Are you sure you want to delete the selected Subject?
 It will go longer will be visible on the App.`,
      onConfirm: () => {
        onDelete(item.id);
      },
    });
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    dispatch(
      deleteSubject(id, (res) => {
        if (res.success) {
          setNotify({
            isOpen: true,
            message: "Subject Deleted Successfully",
            type: "success",
          });
          dispatch(
            getAllSubject(univBranchId, semesterId, (res) => {
              let subjectEntry = res?.data?.examCrackerSubjects;
              setSubjectList(subjectEntry);
            })
          );
        } else {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "error",
          });
        }
      })
    );
    setDeleteClick(true);
    dispatch(setFieldValue("getSubjectList", null));
  };

  const validate = (values) => {
    if (!getSemesterList?.data?.examCrackerSemesters) {
      setNotify({
        isOpen: true,
        message: "Create atleast one Semester...",
        type: "error",
      });
      return false;
    } else if (subjectList === null) {
      setNotify({
        isOpen: true,
        message: "Create atleast one Subject...",
        type: "error",
      });
      return false;
    }
    return true;
  };

  const handleNext = () => {
    let list = subjectList ?? {};
    const subject = Object.values(list).pop(); // return last value of the obj
    history.push(
      `${subjectPath}?univId=${univId}&univBranchId=${univBranchId}&subjectName=${subject?.subject}&subId=${subject?.id}&branch=${branchName}&tabCount=${tabCount}`
    );
  };

  const openInPage = (item) => {
    history.push(
      `${subjectPath}?univId=${univId}&univBranchId=${univBranchId}&subjectName=${item?.subject}&subId=${item?.id}&branch=${branchName}&tabCount=${tabCount}`
    );
  };

  const onDiscard = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    setTimeout(() => {
      history.push({
        pathname: examSpacePath,
        tab: 0,
      });
    }, 1200);
    setNotify({
      isOpen: true,
      message: "Discarded",
      type: "warning",
    });
  };

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * Handle to Save the subject
   * @param {Array} subjectList
   * @param {Number} index
   * @param {Function} remove
   */
  const handleDone = (subject, index, remove) => {
    if (!getSemesterList?.data?.examCrackerSemesters) {
      setNotify({
        isOpen: true,
        message: "Please create semester",
        type: "warning",
      });
    } else if (subject.trim().length === 0) {
      setNotify({
        isOpen: true,
        message: "Enter the subject name",
        type: "error",
      });
    } else {
      let subjectPayload = {
        semesterId: semesterId,
        universityBranchId: univBranchId,
        subject: subject,
      };
      dispatch(
        addSubject(subjectPayload, (res) => {
          if (res.success) {
            setNotify({
              isOpen: true,
              message: "Subject Added Successfully",
              type: "success",
            });
            setLoading(true);
            dispatch(
              getAllSubject(univBranchId, semesterId, (res) => {
                setLoading(true);
                if (res.success) {
                  setLoading(false);
                  let subjectEntry = res?.data?.examCrackerSubjects;
                  setSubjectList(subjectEntry);
                }
              })
            );
          } else {
            setNotify({
              isOpen: true,
              message: res.message,
              type: "error",
            });
          }
        })
      );
      remove(index);
      dispatch(setFieldValue("getSubjectList", null));
    }
  };

  return (
    <>
      <div className={classes.breadCrumbsContainer}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />}>
          <Typography
            style={{
              cursor: "pointer",
              fontWeight: "500",
              color: "#1093FF",
            }}
            onClick={() => {
              history.push({
                pathname: examSpacePath,
              });
            }}
          >
            Exam Space
          </Typography>
          <Typography
            style={{
              cursor: "pointer",
              fontWeight: "500",
              color: "#1093FF",
            }}
            onClick={() => {
              history.push(`${examSpaceUniversityPath}?univId=${univId}`);
            }}
          >
            University
          </Typography>
          <Typography
            style={{ fontWeight: "400", color: "#001D3A" }}
            onClick={() => {
              history.push(
                `${semesterPath}?univBranchId=${univBranchId}&semesterId=${semesterId}`
              );
            }}
          >
            Semester
          </Typography>
        </Breadcrumbs>
      </div>
      <div style={{ display: "flex" }}>
        <BackHandler
          title={branchName}
          pathname={examSpaceUniversityPath}
          search={`?univId=${univId}`}
        />
      </div>

      <div>
        <CustomStepper stepsLabel={steps} activeStep={1} />
      </div>

      <CreatePostContainer>
        <TabButtonContainer>
          <div style={{ width: "80%", overflowX: "scroll" }}>
            <TopTabs
              value={tabCount}
              textColor={"inherit"}
              onChange={(e, value) => {
                setTabCount(value);
              }}
              aria-label='tabs'
              variant='scrollable'
              scrollButtons='false'
            >
              {selectSem.map((item, index) => {
                return <TopTab label={`Semester ${item.semester}`} />;
              })}
            </TopTabs>
          </div>
          {getSemesterList?.data?.examCrackerSemesters ||
          semButton.length !== 0 ? (
            <Tooltip title='Delete Active Semester' placement='top'>
              <Controls.ActionButton onClick={() => handleSemDelete()}>
                <DeleteIcon fontSize='medium' color='primary' />
              </Controls.ActionButton>
            </Tooltip>
          ) : (
            ""
          )}
          <SemesterPopover
            handleSemester={handleSemester}
            handleDoneClick={handleDoneClick}
            handleClick={handleClick}
            open={open}
            semButtons={semButton}
            handleClose={handleClose}
            selectSem={selectSem}
          />
        </TabButtonContainer>

        <div>
          {!loading ? (
            <TblContainer>
              <TblHead />
              {subjectList && (
                <TableBody>
                  {recordsAfterPagingAndSorting().map((item, index) => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell className={classes.rowNo}>
                          {index + 1}
                        </TableCell>
                        <TableCell
                          className={classes.subject}
                        >{`${item.subject}`}</TableCell>
                        <TableCell className={classes.actions}>
                          <Controls.ActionButton
                            onClick={() => openInPage(item)}
                          >
                            <EditOutlinedIcon
                              fontSize='small'
                              color='primary'
                            />
                          </Controls.ActionButton>
                          <Controls.ActionButton
                            onClick={() => handleDeleteClick(item)}
                          >
                            <DeleteIcon fontSize='small' color='secondary' />
                          </Controls.ActionButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </TblContainer>
          ) : (
            <Loader />
          )}
        </div>

        <Formik
          initialValues={state}
          onSubmit={(values, { resetForm }) => {
            if (validate(values)) {
              handleNext();
            }
          }}
          enableReinitialize
        >
          {({
            handleSubmit,
            errors,
            handleChange,
            values,
            touched,
            setFieldValue,
            submitForm,
          }) => {
            return (
              <>
                <div className='CreatePost'>
                  <Form onSubmit={handleSubmit} autoComplete='off'>
                    <div style={{ marginTop: "30px" }}>
                      <FieldArray
                        name='subjectList'
                        className={classes.fieldStyle}
                        render={({ insert, remove, push }) => (
                          <Grid
                            container
                            spacing={1}
                            direction='column'
                            style={{ margin: 0 }}
                          >
                            {values.subjectList.map((key, index) => (
                              <>
                                <Grid
                                  container
                                  style={{
                                    alignItems: "center",
                                    marginBottom: "15px",
                                  }}
                                >
                                  <Grid item md={3}>
                                    <Controls.Input
                                      label='subject'
                                      name={`subjectList.${index}.subject`}
                                      fullWidth
                                      value={values.subjectList[index].subject}
                                      onChange={handleChange}
                                    />
                                  </Grid>

                                  <Grid item md={2}>
                                    <Controls.ActionButton
                                      onClick={() => remove(index)}
                                    >
                                      <DeleteIcon
                                        fontSize='small'
                                        color='secondary'
                                      />
                                    </Controls.ActionButton>
                                  </Grid>

                                  <Grid item xs>
                                    <ButtonsContainer
                                      style={{ justifyContent: "flex-end" }}
                                    >
                                      <Controls.Button
                                        name={`subjectList.${index}.done`}
                                        text={`Done`}
                                        variant='contained'
                                        color='primary'
                                        className={classes.btnStyle}
                                        onClick={() =>
                                          handleDone(
                                            values.subjectList[index].subject,
                                            index,
                                            remove
                                          )
                                        }
                                      />
                                    </ButtonsContainer>
                                  </Grid>
                                </Grid>
                              </>
                            ))}
                            <Controls.Button
                              text='Add Subject'
                              variant='contained'
                              color='primary'
                              startIcon={<AddIcon />}
                              className={classes.newButton}
                              onClick={() => push({ subject: "", done: "" })}
                            />
                          </Grid>
                        )}
                      />
                    </div>
                  </Form>
                </div>
                <ButtonsContainer className={classes.btnContainer}>
                  <Controls.Button
                    text={`Cancel`}
                    variant='outlined'
                    color='primary'
                    className={classes.btnStyle}
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to discard this?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDiscard();
                        },
                      });
                    }}
                  />
                  <Controls.Button
                    text={`Next`}
                    variant='contained'
                    color='primary'
                    className={classes.btnStyle}
                    type={"submit"}
                    onClick={submitForm}
                  />
                </ButtonsContainer>
              </>
            );
          }}
        </Formik>
      </CreatePostContainer>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default SemesterPage;
