import {
  Add,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import Edit from "@material-ui/icons/Edit";
import MaterialTable from "material-table";
import React, { Fragment } from "react";

const COLUMNS = [
  {
    field: "videoId",
    title: "Id",
    hidden: true,
  },
  {
    field: "videoName",
    title: "Video name",
    render: (rowData) => <div style={videoNameStyle}>{rowData.videoName}</div>,
  },
  {
    field: "conceptName",
    title: "Concept name",
  },
  {
    field: "topicName",
    title: "Topic",
  },
  {
    field: "taskType",
    title: "Task name",
  },
  {
    field: "averageRating",
    title: "Rating provided",
    sorting: true,
    align: "center",
  },
];

function EditableTable({ feedReportData, isLoading }) {
  return (
    <Fragment>
      <MaterialTable
        data={feedReportData}
        isLoading={isLoading}
        options={{
          sorting: true,
          showTitle: false,
          search: false,
          pageSize: 10,
          rowStyle: {
            fontSize: "16px",
            color: "#052A4E",
            fontWeight: 400,
          },
          headerStyle: {
            fontSize: "16px",
            color: "#052A4E",
            fontWeight: 600,
            padding: 20,
          },
        }}
        columns={COLUMNS}
        icons={{
          Add: Add,
          Clear: Clear,
          Check: Check,
          Delete: DeleteOutline,
          DetailPanel: ChevronRight,
          Edit: Edit,
          Export: SaveAlt,
          Filter: FilterList,
          FirstPage: FirstPage,
          LastPage: LastPage,
          NextPage: ChevronRight,
          PreviousPage: ChevronLeft,
          ResetSearch: Clear,
          Search: Search,
          SortArrow: ArrowDownward,
          ThirdStateCheck: Remove,
          ViewColumn: ViewColumn,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: "Feedback not yet Provided",
          },
        }}
      />
    </Fragment>
  );
}

export default EditableTable;

const videoNameStyle = {
  maxWidth: "400px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};
