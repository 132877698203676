import React, { Component, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { PDFViewer } from '@react-pdf/renderer';
import MsReport from './GenerateReport/MsReport';
import MbaReport from './GenerateReport/MbaReport';
import { MsSampleReport } from './GenerateReport/SampleMsReport';
import { mbaReport } from './GenerateReport/SampleMbaReport';
import { pbPlacement } from './GenerateReport/PbPlacementSample';
import { getReportPreview, getReportStatus, s3LinkTobase64 } from '../../Actions/ProfileGapAction';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import PbPlacementReport from './GenerateReport/PbPlacementReport';
import { pbMaster } from './GenerateReport/PbMasterSample';
import PbMasterReport from './GenerateReport/PbMasterReport';
import MbaPbReport from './GenerateReport/MbaPbReport';
import MimPbReport from './GenerateReport/MimPbReport';
import MimReport from './GenerateReport/MimReport';
import { postSpiderGraphImg } from '../../Actions/ProfileFitSpiderGraph';
import { useParams } from 'react-router-dom';

class Preview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportData: [],
      blobData: '',
    };
  }

  componentDidMount() {
    this.props.getReportStatus(
      this.props.match.params.studentId,
      this.props.match.params.productId,
      (response) => {
        console.log(response);
      }
    );
    this.props.getReportPreview(
      this.props.match.params.studentId,
      this.props.match.params.productId,
      (response) => {
        this.setState({
          //   reportData: MsSampleReport && MsSampleReport.data,
          // reportData: mbaReport && mbaReport.data,
          reportData: response.data.data,
        });
        let url =
          response.data.data && response.data.data?.contents.filter((item) => item?.spiderGraphUrl);
        var newSpiderUrl = url?.[0]?.spiderGraphUrl;
        var payload = {
          filePath: newSpiderUrl,
        };
        this.props.s3LinkTobase64(this.props.match.params.studentId, payload, (response) => {
          this.setState({
            blobData: response.data.data,
          });
        });
      }
    );
  }

  mbaReportHeader = [
    {
      title: 'Management Fit',
      description:
        'Includes various parameters like leadership potential, ability to be part of a team, etc.',
    },
    {
      title: 'Intellectual Abilities',
      description:
        'Includes parameters like academic excellence, critical thinking and analytical abilities, etc.',
    },
    {
      title: 'People Skills',
      description: 'Includes parameters like your personality, contribution to the community, etc.',
    },
    {
      title: 'MBA Program Fit',
      description:
        ' Includes parameters that are important to the admissions committee like career growth, employability potential, etc.',
    },
    {
      title: 'Global Mindset',
      description:
        'Includes parameters like experience of working abroad or of working in a multi-cultural environment, etc.',
    },
  ];

  renderReport = () => {
    let productId = this.props.match.params.productId;
    let productName = this.props.variantStepList.referProductCodeName;
    let product = this.props.variantStepList.variant_SKU;

    // if (
    //   productId === "5" ||
    //   productId === "7" ||
    //   productName === "ACS_MIM" ||
    //   productName === "ACS_MBA" ||

    //   productName === "ACS_PB"
    // ) {
    //   return <MbaReport mailId={this.state.reportData || []} content={this.state.reportData.contents || []} />;
    // } else {
    //   return <MsReport mailId={this.state.reportData || []} content={this.state.reportData.contents || []} />;
    // }
    if (productName === 'ACS_MBA' || productName === 'ACS_PB') {
      return (
        <MbaReport
          client={this.state.reportData.clientName}
          content={this.state.reportData.contents || []}
          mailId={this.state.reportData || []}
          assessment={this.mbaReportHeader}
          blobData={this.state.blobData}
        />
      );
    } else if (productName === 'ACS_MIM') {
      return (
        <MimReport
          mailId={this.state.reportData || []}
          content={this.state.reportData.contents || []}
        />
      );
    } else if (product === 'ACS_PB') {
      return (
        <MbaPbReport
          mailId={this.state.reportData || []}
          content={this.state.reportData.contents || []}
        />
      );
    }
    //  else if (product === "ACS_MIM_PB") {
    //   return <MimPbReport mailId={this.state.reportData || []} content={this.state.reportData.contents || []} />;
    // }
    // else if (productName === "PBM") {
    //   return <MbaReport mailId={this.state.reportData || []} content={this.state.reportData.contents || []} />;

    // }
    // else if (productName === "PBP") {
    //   return <MbaReport mailId={this.state.reportData || []} content={this.state.reportData.contents || []} />;

    // }
    else {
      return (
        <MsReport
          mailId={this.state.reportData || []}
          content={this.state.reportData.contents || []}
        />
      );
    }
  };

  render() {
    return (
      <div>
        <Grid container>
          <Grid item md={12}>
            <PDFViewer style={{ width: '100%', height: '100vh' }}>{this.renderReport()}</PDFViewer>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    variantStepList: state.ProductReducer.variantStepList,
  };
};

export default connect(mapStateToProps, {
  getReportPreview,
  getReportStatus,
  s3LinkTobase64,
})(Preview);
