import axios from "axios";
import { B_SCHOOL } from "../Redux/Action";
import { URL } from "./URL";
const BASE_URL = `${URL}/api/v1`;
// b school view
export const listOfBSchool = (page, size, keyword) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/bschool?q=${keyword}&page=${page}&size=${size}`,
      {
        crossDomain: true,
        headers: {
          admin: "yes",
          authorization: `Bearer ${window.sessionStorage.getItem(
            "accessToken"
          )}`,
        },
      }
    );

    dispatch({ type: B_SCHOOL.getAllBSchool, payload: data.data });
  } catch (error) {
    dispatch({
      type: B_SCHOOL.getAllBSchool,
      payload: error,
    });
  }
};

//B- Schools//
export const createBSchoolList = (obj, callback) => async (dispatch) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  try {
    const { data } = await axios.put(`${BASE_URL}/bschool`, obj, {
      crossDomain: true,
      headers: {
        admin: "yes",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    callback(data);
    dispatch({ type: B_SCHOOL.createBSchool, payload: data });
  } catch (error) {
    callback(error.response);
    dispatch({ type: B_SCHOOL.createBSchool, payload: error });
  }
};
//B- School Sub Region //
export const getBSchoolSubRegion = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/subregion/${id}
      `,
      {
        crossDomain: true,
        headers: {
          admin: "yes",
          authorization: `Bearer ${window.sessionStorage.getItem(
            "accessToken"
          )}`,
        },
      }
    );
    // callback(data.data);
    dispatch({ type: B_SCHOOL.getBSchoolSubRegionById, payload: data.data });
  } catch (error) {
    dispatch({
      type: B_SCHOOL.getBSchoolSubRegionById,
      payload: error,
    });
  }
};

// B School Region List //

export const getBSclRegionList = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/regionlist`, {
      crossDomain: true,
      headers: {
        admin: "yes",
        authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`,
      },
    });
    dispatch({ type: B_SCHOOL.createPerBSchoolProgram, payload: data.data });
    dispatch({ type: B_SCHOOL.getBSchoolRegionList, payload: data.data });
  } catch (error) {
    dispatch({
      type: B_SCHOOL.getBSchoolRegionList,
      payload: error,
    });
  }
};

// B School Country Lists//

export const getBSclCountryLists = (regionId, subRegionId) => async (
  dispatch
) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/country/${regionId}/${subRegionId}`,
      {
        crossDomain: true,
        headers: {
          admin: "yes",
          authorization: `Bearer ${window.sessionStorage.getItem(
            "accessToken"
          )}`,
        },
      }
    );
    dispatch({ type: B_SCHOOL.getBSchoolCountryList, payload: data.data });
  } catch (error) {
    dispatch({
      type: B_SCHOOL.getBSchoolCountryList,
      payload: error,
    });
  }
};

//Clear ///
export const clearData = () => {
  return (dispatch) => {
    dispatch({ type: B_SCHOOL.BSchool_clearData });
  };
};

// get one b school program

export const getOneBSchoolProgram = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/bsprogram/${id}`, {
      crossDomain: true,
      headers: {
        admin: "yes",
        authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`,
      },
    });

    dispatch({ type: B_SCHOOL.getPerBSchoolProgram, payload: data.data });
  } catch (error) {
    dispatch({
      type: B_SCHOOL.getPerBSchoolProgram,
      payload: error,
    });
  }
};

// b school program view

// get bs season

export const getBSchoolSeasons = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/bsseasons`, {
      crossDomain: true,
      headers: {
        admin: "yes",
        authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`,
      },
    });

    dispatch({ type: B_SCHOOL.bs_seasons, payload: data.data });
  } catch (error) {
    dispatch({
      type: B_SCHOOL.bs_seasons,
      payload: error,
    });
  }
};

//get all bs round

export const getAllBSchoolRound = (id, page, size) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/bsRounds?programId=${id}&page=${page}&size=${size}`,
      {
        crossDomain: true,
        headers: {
          admin: "yes",
          authorization: `Bearer ${window.sessionStorage.getItem(
            "accessToken"
          )}`,
        },
      }
    );

    dispatch({ type: B_SCHOOL.getAllbsRound, payload: data.data });
  } catch (error) {
    dispatch({
      type: B_SCHOOL.getAllbsRound,
      payload: error,
    });
  }
};

//get one bs round

export const getOneBSchoolRound = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/bsRound/${id}`, {
      crossDomain: true,
      headers: {
        admin: "yes",
        authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`,
      },
    });

    dispatch({ type: B_SCHOOL.getPerBSchoolRound, payload: data.data });
  } catch (error) {
    dispatch({
      type: B_SCHOOL.getPerBSchoolRound,
      payload: error,
    });
  }
};

//get all BSEssayQuestions

export const getAllEsEssayQuestions = (id, page, size) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/bsessayquestion?bsprogramid=${id}&page=${page}&size=${size}`,
      {
        crossDomain: true,
        headers: {
          admin: "yes",
          authorization: `Bearer ${window.sessionStorage.getItem(
            "accessToken"
          )}`,
        },
      }
    );

    dispatch({ type: B_SCHOOL.getAllBSchoolEssayQues, payload: data.data });
  } catch (error) {
    dispatch({
      type: B_SCHOOL.getAllBSchoolEssayQues,
      payload: error,
    });
  }
};

//get one BSEssayQuestions

export const getOneBSEssayQuestions = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/bsessayquestion/${id}`, {
      crossDomain: true,
      headers: {
        admin: "yes",
        authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`,
      },
    });

    dispatch({ type: B_SCHOOL.getPerBSchoolEssayQues, payload: data.data });
  } catch (error) {
    dispatch({
      type: B_SCHOOL.getPerBSchoolEssayQues,
      payload: error,
    });
  }
};

//get one AptitudeTest

export const getPerAptitudeTest = (id, callback) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/bsaptitudetest/${id}`, {
      crossDomain: true,
      headers: {
        admin: "yes",
        authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`,
      },
    });
    callback(data.data);
    dispatch({
      type: B_SCHOOL.getPerAptitudeTestForBSchool,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: B_SCHOOL.getPerAptitudeTestForBSchool,
      payload: error,
    });
  }
};

//get all  AptitudeTest

export const getAllAptitudeTest = (id, callback) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/bsaptitudetest?programId=${id}`,
      {
        crossDomain: true,
        headers: {
          admin: "yes",
          authorization: `Bearer ${window.sessionStorage.getItem(
            "accessToken"
          )}`,
        },
      }
    );

    callback(data);

    dispatch({
      type: B_SCHOOL.getAllAptitudeTestForBSchool,
      payload: data,
    });
  } catch (error) {
    callback(error.response.data);
    dispatch({
      type: B_SCHOOL.getAllAptitudeTestForBSchool,
      payload: error.response.data,
    });
  }
};

//create bs essayQuestion

export const createBSEssayQuestion = (obj, callback) => async (dispatch) => {
  try {
    const { data } = await axios.put(`${BASE_URL}/bsessayquestion`, obj, {
      crossDomain: true,
      headers: {
        admin: "yes",
        authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`,
      },
    });
    callback(data);
    dispatch({
      type: B_SCHOOL.createBSchoolEssayQuestions,
      payload: data.data,
    });
  } catch (error) {
    callback(error.response);
    dispatch({
      type: B_SCHOOL.createBSchoolEssayQuestions,
      payload: error,
    });
  }
};

//create bs round

export const createBSround = (obj, callback) => async (dispatch) => {
  try {
    const { data } = await axios.put(`${BASE_URL}/bsRound`, obj, {
      crossDomain: true,
      headers: {
        admin: "yes",
        authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`,
      },
    });
    callback(data);
    dispatch({ type: B_SCHOOL.createBSchoolProgramRound, payload: data.data });
  } catch (error) {
    callback(error.response);
    dispatch({
      type: B_SCHOOL.createBSchoolProgramRound,
      payload: error,
    });
  }
};

// clearData

export const listOfBSchoolProgram = (page, size, keyword, id) => async (
  dispatch
) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/bsprogram?page=${page}&size=${size}&q=${keyword}&bschoolId=${id}`,
      {
        crossDomain: true,
        headers: {
          admin: "yes",
          authorization: `Bearer ${window.sessionStorage.getItem(
            "accessToken"
          )}`,
        },
      }
    );

    dispatch({ type: B_SCHOOL.getAllBSchoolProgram, payload: data.data });
  } catch (error) {
    dispatch({
      type: B_SCHOOL.getAllBSchoolProgram,
      payload: error,
    });
  }
};

// create b school program

export const createBSchoolProgram = (obj, callback) => async (dispatch) => {
  try {
    const { data } = await axios.put(`${BASE_URL}/bsprogram`, obj, {
      crossDomain: true,
      headers: {
        admin: "yes",
        authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`,
      },
    });
    callback(data);
    dispatch({ type: B_SCHOOL.createPerBSchoolProgram, payload: data.data });
  } catch (error) {
    callback(error.response);
    dispatch({
      type: B_SCHOOL.createPerBSchoolProgram,
      payload: error,
    });
  }
};

// Create B School Rank //

export const createBSchoolRankList = (obj, callback) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .put(`${BASE_URL}/bsrank`, obj, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        callback(result.data);
        dispatch({
          type: B_SCHOOL.CreateBSchoolRank,
          payload: result,
        });
      })
      .catch((error) => {
        callback(error.response);
        dispatch({
          type: B_SCHOOL.CreateBSchoolRank,
          payload: error,
        });
      });
  };
};

// B School Rank List //

export const listOfBSchoolRankList = (page, size, keyword, bschoolId) => async (
  dispatch
) => {
  // console.log(id, "div");
  try {
    const { data } = await axios.get(
      `${BASE_URL}/bsrank?BSchoolId=${bschoolId}&page=${page}&size=${size}&q=${keyword}`,
      {
        crossDomain: true,
        headers: {
          admin: "yes",
          authorization: `Bearer ${window.sessionStorage.getItem(
            "accessToken"
          )}`,
        },
      }
    );

    dispatch({ type: B_SCHOOL.getAllBSchoolRank, payload: data.data });
  } catch (error) {
    dispatch({
      type: B_SCHOOL.getAllBSchoolRank,
      payload: error,
    });
  }
};
// create aptitude test
export const createBSchoolAptitudeTest = (obj, callback) => async (
  dispatch
) => {
  try {
    const { data } = await axios.put(`${BASE_URL}/bsaptitudetest`, obj, {
      crossDomain: true,
      headers: {
        admin: "yes",
        authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`,
      },
    });
    callback(data);
    dispatch({ type: B_SCHOOL.createBSchoolAptitude, payload: data.data });
  } catch (error) {
    callback(error.response);
    dispatch({
      type: B_SCHOOL.createBSchoolAptitude,
      payload: error,
    });
  }
};

// b school degree

export const bSchoolDegree = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/bsdegree`, {
      crossDomain: true,
      headers: {
        admin: "yes",
        authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`,
      },
    });

    dispatch({ type: B_SCHOOL.bSchoolDegree, payload: data.data });
  } catch (error) {
    dispatch({
      type: B_SCHOOL.bSchoolDegree,
      payload: error,
    });
  }
};

// b school program Category

export const bSchoolProgramCategory = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/bsprogramcategory`, {
      crossDomain: true,
      headers: {
        admin: "yes",
        authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`,
      },
    });

    dispatch({ type: B_SCHOOL.bSchoolProgramCategory, payload: data.data });
  } catch (error) {
    dispatch({
      type: B_SCHOOL.bSchoolProgramCategory,
      payload: error,
    });
  }
};

// b school program area of specialization

export const bSchoolProgramAreaOfSpecialization = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/bsareaofspecialization`, {
      crossDomain: true,
      headers: {
        admin: "yes",
        authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`,
      },
    });

    dispatch({
      type: B_SCHOOL.bSchoolAreaOfSpecialization,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: B_SCHOOL.bSchoolAreaOfSpecialization,
      payload: error,
    });
  }
};

// Get B School Rank //

export const getBSchoolBSRank = (id, callback) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/bsrank/${id}`, {
      crossDomain: true,
      headers: {
        admin: "yes",
        authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`,
      },
    });
    callback(data.data);
    dispatch({
      type: B_SCHOOL.getBSRankId,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: B_SCHOOL.getBSRankId,
      payload: error,
    });
  }
};
