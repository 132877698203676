import {
  createTheme,
  Grid,
  ThemeProvider,
  Slide,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import TableComponent from "../TableComponent/TableComponent";
// import "../../Asset/All.css";
import { useDispatch, useSelector } from "react-redux";
import { getPaginateIntake } from "../../Actions/InTake";
import { addGradSchool, viewGradSchoolProgram } from "../RoutePaths";
import {
  clearData,
  getPerGradSchool,
  listOfGradSchool,
  searchOnGradSchool,
} from "../../Actions/GradSchoolAction";
import { isEmptyString } from "../Validation";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function GradSchool(props) {
  const dispatch = useDispatch();
  const [schoolList, setSchoolList] = useState([]);
  const [search, setSearch] = useState({
    size: 20,
    page: 0,
    keyword: "",
  });
  const { getGradSchoolList, getSearchedListOnGradScl } = useSelector(
    (state) => state.GradSchoolReducer
  );
  const tableTheme = () => {
    createTheme({
      palette: {
        primary: {
          main: "#007bff",
        },
      },
      overrides: {
        MuiTypography: {
          h6: {
            fontWeight: "bold",
          },
        },
        MuiIconButton: {
          root: {
            "&:hover": {
              backgroundColor: "none",
              borderRadius: 0,
            },
          },
        },
      },
    });
  };
  /**
   * Table Columns
   */
  const col = [
    {
      title: "ID",
      fieldName: "id",
    },
    { title: "Name", fieldName: "name" },
    { title: "Region", fieldName: "region" },
    { title: "Country", fieldName: "country" },
    { title: "Location", fieldName: "location" },
    { title: "Created By", fieldName: "createdBy" },
    { title: "Created Date", fieldName: "createdDate" },
  ];

  const handleClickOpen = () => {
    props.history.push({ pathname: addGradSchool });
    dispatch(clearData());
  };
  const handleDelete = () => {};
  const handleEdit = (data) => {
    dispatch(
      getPerGradSchool(data.id, (res) => {
        props.history.push({ pathname: addGradSchool, state: res });
      })
    );
  };

  const paginate = (page, size, keyword) => {
    setSearch({ ...search, page: page, size: size, keyword: keyword });
    if (page !== search.page || size !== search.size) {
      dispatch(listOfGradSchool(page, size, keyword));
      setSchoolList(getGradSchoolList.content);
    } else if (isEmptyString(keyword)) {
      dispatch(listOfGradSchool(0, 20, ""));
    } else {
    }
  };
  useEffect(() => {
    dispatch(listOfGradSchool(0, 20, ""));
  }, []);

  const handleSearch = () => {
    dispatch(listOfGradSchool(search.page, search.size, search.keyword));
    setSchoolList(getGradSchoolList.content);
  };

  useEffect(() => {
    if (getGradSchoolList !== null) {
      setSchoolList(getGradSchoolList.content);
    }
  }, [getGradSchoolList]);
  const handleView = (data) => {
    props.history.push({ pathname: viewGradSchoolProgram, state: data });
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={tableTheme}>
        <div>
          <Grid container>
            <Grid item md={12}>
              <TableComponent
                cols={col}
                data={schoolList}
                title={"Grad School"}
                add={true}
                action={true}
                onAddClick={handleClickOpen}
                onView={true}
                totalCount={getGradSchoolList.totalElements}
                pageCount={getGradSchoolList.totalPages}
                onViewClick={handleView}
                // onDeleteClick={handleDelete}
                onKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    document.getElementById("search").click();
                  }
                }}
                onEdit={true}
                onEditClick={handleEdit}
                onSearch={paginate}
                onSearchClick={handleSearch}
                needSearch
                paginate={paginate}
                searchPlaceHolder="Name"
              />
            </Grid>
          </Grid>
        </div>
      </ThemeProvider>
    </React.Fragment>
  );
}
