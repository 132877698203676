import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import LatexViewer from "../../../../../Utils/LatexViewer";
import { Passage as passage } from "../../../../../Assets/css/Preview/TestComponent";
import { QuestionDiv } from "../../../../../Assets/css/Preview/GmatStyles";

export default function Twopart(props) {
  const { testResponse } = props;
  const { Question } = passage;
  const style = {
    show: {
      display: "block",
    },
    hide: {
      display: "none",
    },
  };
  const { hide, show } = style;
  return (
    <Grid container>
      <Grid
        item
        xs={6}
        style={testResponse.data.isHaveDescription === false ? hide : show}
        className="blue_border"
      >
        <Box display="flex" height="100%" justifyContent="space-between">
          <QuestionDiv>
            {testResponse.data.description !== "" && (
              <Box fontSize={16} color={"#052A4E"} lineHeight={"30px"}>
                <LatexViewer math={testResponse.data.description} />
              </Box>
            )}
            {testResponse.data.isHaveDescription === false ? (
              ""
            ) : (
              <Box>
                <Divider variant="middle" orientation={"vertical"} />
              </Box>
            )}
          </QuestionDiv>
        </Box>
      </Grid>
      <Grid item xs={testResponse.data.isHaveDescription === false ? 12 : 6}>
        <Box className={"overflow-scroll"} id="inside-test-container">
          <QuestionDiv>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                <Question style={{ marginBottom: "15px" }}>
                  <LatexViewer math={testResponse.data.question} />
                </Question>
              </FormLabel>
              <table className="two-part-table">
                <tr>
                  <th>{testResponse.data.firstSelectedName}</th>
                  <th>{testResponse.data.secondSelectedName}</th>
                  <th></th>
                </tr>
                <tr>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      {testResponse.data.choices.map((item) => {
                        return (
                          <Grid item>
                            <RadioGroup
                              row
                              style={{ justifyContent: "center" }}
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="row-radio-buttons-group"
                              value={item.correctChoice}
                              // onChange={handleChange}
                            >
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                style={{ margin: "0px" }}
                              />
                            </RadioGroup>
                          </Grid>
                        );
                      })}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      {testResponse.data.choices.map((item) => {
                        return (
                          <Grid item>
                            <RadioGroup
                              row
                              style={{ justifyContent: "center" }}
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="row-radio-buttons-group"
                              value={item.wrongChoice}
                              // onChange={handleChange}
                            >
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                style={{ margin: "0px" }}
                              />
                            </RadioGroup>
                          </Grid>
                        );
                      })}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      {testResponse.data.choices.map((choice, index) => (
                        <Typography
                          variant="h4"
                          color="textPrimary"
                          style={{ margin: "10px" }}
                        >
                          {choice.text ? (
                            <LatexViewer math={choice.text} />
                          ) : (
                            <img src={choice.choiceImage} alt="" />
                          )}
                        </Typography>
                      ))}
                    </div>
                  </td>
                </tr>
              </table>
            </FormControl>
          </QuestionDiv>
        </Box>
      </Grid>
    </Grid>
  );
}
