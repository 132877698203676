import {
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { DropDownDiv } from "../../../Assets/StyledComponents";
import { AVOID_INPUT, ROLES } from "../../../Constants";
import { validateSerialNo } from "../../../Redux/Action/Test";
import DropDown from "../../../Utils/DropDown";
import { InputTextField } from "../../../Utils/TextField";

function DropDownRack(props) {
  const dispatch = useDispatch();
  const [serialNoError, setSerialNoError] = useState(false);

  const aeDept = window.sessionStorage.getItem("department");
  var testQuestionSetId = window.localStorage.getItem("testQuestionSetId");

  const {
    subjects,
    concepts,
    topics,
    activeSubject,
    activeConcept,
    activeTopic,
    handleSubjectChange,
    handleConceptChange,
    handleTopicChange,
    difficulty,
    activeLevel,
    handleInputChange,
    expectedTime,
    orderNo,
    type,
    separateScore,
    allowExpectedTime,
    allowSerialNumber,
  } = props;

  /*To check if the serial number is already exists or not while onBlur (out of focus) */
  const handleErrorForSerialNo = (event) => {
    const serialNo = event.target.value;
    dispatch(
      validateSerialNo(testQuestionSetId, serialNo, (res) => {
        if (res.success === true) {
          setSerialNoError(res?.data?.isAlreadyExits);
        }
      })
    );
  };

  if (subjects !== null && concepts !== null) {
    console.log(activeSubject, "activeSubject", subjects.data);
    return (
      <React.Fragment>
        {topics !== null && (
          <DropDownDiv>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <DropDown
                  label="Subject"
                  items={subjects.data}
                  value={activeSubject}
                  onChange={handleSubjectChange}
                  disabled={subjects.data.length < 2}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DropDown
                  label="Concept"
                  items={concepts.data}
                  value={activeConcept}
                  onChange={handleConceptChange}
                  disabled={concepts.data.length < 2}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DropDown
                  label="Topic"
                  items={topics}
                  value={activeTopic}
                  onChange={handleTopicChange}
                  disabled={topics.length < 2}
                />
              </Grid>
            </Grid>
          </DropDownDiv>
        )}
        <DropDownDiv>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <DropDown
                label="Difficulty level *"
                items={difficulty}
                value={activeLevel}
                name="activeLevel"
                onChange={handleInputChange}
              />
            </Grid>
            {aeDept === "assessment_engine_admin" && (
              <>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel
                      shrink={true}
                      style={{
                        top: "-8px",
                        left: "15px",
                        background: "#FFFFFF",
                        padding: "0 10px 0 8px",
                        zIndex: 1,
                      }}
                    >
                      Expected time for completion *
                    </InputLabel>
                    <OutlinedInput
                      inputProps={{
                        style: {
                          height: "11px",
                        },
                      }}
                      type={"number"}
                      onKeyDown={(evt) =>
                        (AVOID_INPUT.includes(evt.key) ||
                          // Up arrow and down arrow disabling
                          evt.keyCode === 38 ||
                          evt.keyCode === 40) &&
                        evt.preventDefault()
                      }
                      id="expectedTime"
                      value={type === "VIDEO" ? "360" : expectedTime}
                      name="expectedTime"
                      disabled={type === "VIDEO"}
                      onChange={(e) => {
                        if (e.target.value.length <= 3) {
                          handleInputChange(e);
                        } else {
                          e.preventDefault();
                        }
                      }}
                      endAdornment={
                        <InputAdornment position="end">seconds</InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel
                      shrink={true}
                      style={{
                        top: "-8px",
                        left: "15px",
                        background: "#FFFFFF",
                        padding: "0 10px 0 8px",
                        zIndex: 1,
                      }}
                    >
                      {"Score *"}
                    </InputLabel>
                    <OutlinedInput
                      inputProps={{
                        style: {
                          height: "11px",
                        },
                      }}
                      type={"number"}
                      onKeyDown={(evt) =>
                        (AVOID_INPUT.includes(evt.key) ||
                          // Up arrow and down arrow disabling
                          evt.keyCode === 38 ||
                          evt.keyCode === 40) &&
                        evt.preventDefault()
                      }
                      id="separateScore"
                      value={
                        type === "FILE_UPLOAD" || type === "VIDEO"
                          ? "0"
                          : separateScore
                      }
                      name="separateScore"
                      min="0"
                      disabled={type === "FILE_UPLOAD" || type === "VIDEO"}
                      onChange={(e) => {
                        if (e.target.value.length <= 4) {
                          handleInputChange(e);
                        } else {
                          e.preventDefault();
                        }
                      }}
                    />
                  </FormControl>
                </Grid>
              </>
            )}

            {(topics ||
              (aeDept !== ROLES.assessment_engine_admin &&
                allowExpectedTime)) && (
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel
                    shrink={true}
                    style={{
                      top: "-8px",
                      left: "15px",
                      background: "#FFFFFF",
                      padding: "0 10px 0 8px",
                      zIndex: 1,
                    }}
                  >
                    {"Expected time for completion"}
                  </InputLabel>
                  <OutlinedInput
                    inputProps={{
                      style: {
                        height: "11px",
                      },
                    }}
                    type={"number"}
                    onKeyDown={(evt) =>
                      (AVOID_INPUT.includes(evt.key) ||
                        // Up arrow and down arrow disabling
                        evt.keyCode === 38 ||
                        evt.keyCode === 40) &&
                      evt.preventDefault()
                    }
                    id="expectedTime"
                    value={expectedTime}
                    name="expectedTime"
                    disabled={type === "VIDEO"}
                    min={1}
                    onChange={handleInputChange}
                    endAdornment={
                      <InputAdornment position="end">seconds</InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            )}

            {allowSerialNumber === true && (
              <Grid item xs={12} sm={4}>
                <InputTextField
                  id="orderNo"
                  name="orderNo"
                  value={orderNo}
                  type={"number"}
                  onKeyDown={(evt) => {
                    (AVOID_INPUT.includes(evt.key) ||
                      // Up arrow and down arrow disabling
                      evt.keyCode === 38 ||
                      evt.keyCode === 40) &&
                      evt.preventDefault();
                  }}
                  onBlur={handleErrorForSerialNo}
                  onChange={handleInputChange}
                  label={"Add serial number"}
                  height="10px"
                  placeholder={"Add serial number"}
                  required
                />
                {serialNoError === true && (
                  <div
                    style={{ color: "red", fontSize: "12px", marginTop: "5px" }}
                  >
                    Warning: This Serial number already exist
                  </div>
                )}
              </Grid>
            )}
          </Grid>
        </DropDownDiv>
        <Divider className={"single_select_divider"} />
      </React.Fragment>
    );
  } else return null;
}

export default DropDownRack;
