import { UPLOAD_PGA_REPORT } from "../Redux/Action";

const initialState = {
  isLoading: false,
  studentPgaList: null,
  pgaDownloadStatus: null,
  pgaUploadStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_PGA_REPORT.loader:
      return {
        ...state,
        isLoading: true,
      };
    case UPLOAD_PGA_REPORT.getStudentPgaList:
      return {
        ...state,
        studentPgaList: action.payload,
        isLoading: action.loading,
      };
    case UPLOAD_PGA_REPORT.pgaReportUpload:
      return {
        ...state,
        pgaUploadStatus: action.payload,
        isLoading: action.loading,
      };
    case UPLOAD_PGA_REPORT.pgaDownload:
      return {
        ...state,
        pgaDownloadStatus: action.payload,
        isLoading: action.loading,
      };
    default:
      break;
  }
  return state;
};
