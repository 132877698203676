import { Backdrop, Box, Divider, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  clearCustomData,
  getDocumentModelBySubStageId,
  // getDownloadByDocumentId,
  getDownloadByDocumentFile,
  getDownloadByDocumentId,
  getFilePath,
  uploadDocumentBySubStageId,
  uploadFileBySubStageId,
} from "../../Actions/StrategySession";
import {
  getStepsBySubStageId,
  getStudentStageByProductId,
} from "../../Actions/Student";
import MySnackBar from "../MySnackBar";
import { CommentBoxPopper } from "../Utils/controls/CommentBoxPopper";
import { CustomTab, CustomTabs } from "../Utils/controls/CustomTabComponent";
import Loader from "../Utils/controls/Loader";
import {
  bytesToMegaBytes,
  getSubStageByStage,
  textToDownloadFile,
} from "../Utils/Helpers";
import StrategySessionFileUpload from "./StrategySessionFileUpload";
import { useStyles } from "./Styles";
import TestTranscripts from "./TestTranscripts/Index";
import CustomTabsPM from "../ProfileMentoring/additionalPoints/CustomTabsForProfileMentoring";
import MomPoints from "./MOM/Index";
import DocumentComponentForSS from "./DocumentComponentForSS";
import {
  getDisableButtonCall,
  getEnableCall,
  qpmcStageComplete,
} from "../../Actions/ProfileMentoring";
import RecommendedToolIndex from "../RecommendedTool/RecommendedToolIndex";
import { customTheme, StyledButton } from "../Utils/controls/Styles";

const FILE_FORMAT_ERROR = "Invalid file format";
const FILE_SIZE_ERROR = "Please check the file size";
const FILE_UPLOAD_ERROR = "Please select a file";
const UPLOADED_SUCCESS = "Uploaded Successfully";
const REQUIRED_ERROR = "Please fill the required field";

function Index(props) {
  const classes = useStyles();
  const [fileId, setFileId] = useState("");
  const [step1, setSteps2] = useState([]);
  const [ssNotif, setSsNotif] = useState(true);
  const dispatch = useDispatch();
  const [lastSection, setLastSection] = useState("");
  const params = useParams();
  const { studentId, productId, subStageId } = params;
  const [state, setState] = useState({
    steps: [],
    documentList: [],
    activeTabValue: null,
    activeNeededValue: null,
    activeStateValue: "",
    activeMainTabValue: "Pre Strategy call",
    sectionId: null,
    open: false,
    comment: null,
    upcomingFileName: null,
    fileName: null,
    fileNameHelperText: "",
    commentHelperText: "",
    file: null,
    snackMsg: "",
    snackOpen: false,
    snackVariant: "",
    status: null,
    anchorEl: null,
    popoverComment: null,
    completedStagesList: [],
  });

  const {
    steps,
    documentList,
    sectionId,
    activeTabValue,
    activeMainTabValue,
    activeNeededValue,
    open,
    comment,
    fileName,
    fileNameHelperText,
    commentHelperText,
    file,
    snackMsg,
    snackOpen,
    snackVariant,
    upcomingFileName,
    status,
    anchorEl,
    popoverComment,
    completedStagesList,
  } = state;
  const {
    loading,
    documentModel,
    fileUploadStatus,
    documentUpdateStatus,
    downloadFileResponse,
    fileObject,
  } = useSelector((state) => state.StrategySessionReducer);
  const { enableStage, disableStage, momPoints } = useSelector(
    (state) => state.ProfileMentoringReducer
  );
  // const { disableStage } = useSelector((state) => state.profileMentoringReducer);
  // useEffect(() => {
  //   if (qpmcStageCompletionStatus?.success) {
  //     setState({
  //       ...state,
  //       snackOpen: true,
  //       snackVariant: "success",
  //       snackMsg: qpmcStageCompletionStatus?.message,
  //     });

  //     // setCompletionMessage(qpmcStageCompletionStatus?.message);
  //   } else {
  //     setState({
  //       ...state,
  //       snackOpen: true,
  //       snackVariant: "error",
  //       snackMsg: qpmcStageCompletionStatus?.message,
  //     });
  //   }
  // }, [qpmcStageCompletionStatus]);

  const { studentStages, subStageSteps, completedStages } = useSelector(
    (state) => state.StudentReducer
  );

  useEffect(() => {
    dispatch(getStudentStageByProductId(studentId, productId));
    dispatch(getDisableButtonCall(studentId, productId));
  }, []);

  useEffect(() => {
    if (studentStages) {
      if (studentStages.success) {
        const { data } = studentStages;
        let subStage = getSubStageByStage(
          data,
          "Strategy Session",
          "Upload Document"
        );
        if (subStage.length !== 0) {
          dispatch(
            getStepsBySubStageId(
              studentId,
              productId,
              subStage[0]["id"],
              "stageSS"
            )
          );
        }
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackVariant: "error",
          snackMsg: studentStages.message,
        });
      }
    }
  }, [studentStages]);

  useEffect(() => {
    if (studentStages) {
      if (studentStages.success) {
        let arr = studentStages?.data?.find(
          (el) => el.stepName === "Strategy Session"
        )?.steps;
        let arr1 = arr?.find(
          ({ stepName }) => stepName === "Pre Strategy call"
        );

        if (arr.length !== 0) {
          arr.sort(function(a, b) {
            return a.rank - b.rank;
          });
          setState({
            ...state,
            steps: arr,
            activeTabValue: arr.length !== 0 && arr[0]["sectionName"],
            activeStateValue: arr1?.id,
            sectionId: arr.length !== 0 && arr[0]["id"],
          });
        }
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackVariant: "error",
          snackMsg: subStageSteps.message,
        });
      }
    }
  }, [studentStages]);
  //We have to check whether it is needed
  useEffect(() => {
    if (sectionId) {
      dispatch(getDocumentModelBySubStageId(studentId, productId, sectionId));
    }
  }, [sectionId]);
  // useEffect(() => {
  //   if (activeNeededValue) {
  //     dispatch(
  //       getDocumentModelBySubStageId(studentId, productId, activeNeededValue)
  //     );
  //   }
  // }, [activeNeededValue]);
  useEffect(() => {
    if (documentModel) {
      if (documentModel.success) {
        const { data } = documentModel;
        setState({
          ...state,
          upcomingFileName: data.fileName,
          status: data.stepStatus,
          documentList: data.content || [],
        });
        const index = data.content.length - 1;
        const path =
          data.content.length !== 0 ? data.content[index]["path"] : "";
        dispatch(getFilePath(studentId, sectionId, path));
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackVariant: "error",
          snackMsg: documentModel.message,
          documentList: [],
          status: null,
          upcomingFileName: null,
        });
      }
      dispatch(clearCustomData("documentModel"));
    }
  }, [documentModel]);

  useEffect(() => {
    if (fileUploadStatus) {
      if (fileUploadStatus.success) {
        setState({
          ...state,
          snackOpen: true,
          snackVariant: "success",
          snackMsg: UPLOADED_SUCCESS,
        });
        let requestBody = {
          id: fileUploadStatus.data?.id,
          uploadedBy: "admin",
          status: "Review Completed",
          comment: comment,
          fileName: fileName,
        };
        dispatch(
          uploadDocumentBySubStageId(
            studentId,
            productId,
            sectionId,
            requestBody
          )
        );
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackVariant: "error",
          snackMsg: fileUploadStatus.message,
        });
      }
      dispatch(clearCustomData("fileUploadStatus"));
    }
  }, [fileUploadStatus]);

  useEffect(() => {
    if (documentUpdateStatus) {
      if (documentUpdateStatus.success) {
        setState({
          ...state,
          file: null,
          fileName: null,
          comment: null,
          fileNameHelperText: "",
          commentHelperText: "",
          open: false,
        });
        dispatch(getDocumentModelBySubStageId(studentId, productId, sectionId));
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackVariant: "error",
          snackMsg: documentUpdateStatus.message,
        });
      }
      dispatch(clearCustomData("documentUpdateStatus"));
    }
  }, [documentUpdateStatus]);

  useEffect(() => {
    if (downloadFileResponse) {
      if (downloadFileResponse.success) {
        textToDownloadFile(
          downloadFileResponse.data,
          downloadFileResponse.fileName,
          downloadFileResponse.fileName.split(".").pop()
        );
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackVariant: "error",
          snackMsg: downloadFileResponse.message,
        });
      }
      dispatch(clearCustomData("downloadFileResponse"));
    }
  }, [downloadFileResponse]);

  useEffect(() => {
    if (completedStages) {
      if (completedStages.success) {
        setState({
          ...state,
          completedStagesList: completedStages.data || [],
        });
      } else {
        setState({
          ...state,
          completedStagesList: [],
        });
      }
    }
  }, [completedStages]);

  const handleCancel = () => {
    setState({
      ...state,
      file: null,
      fileName: null,
      comment: null,
      fileNameHelperText: "",
      commentHelperText: "",
      open: false,
    });
  };

  const handleUpload = () => {
    let error = false;
    if (!file) {
      error = true;
      setState({
        ...state,
        snackOpen: true,
        snackMsg: FILE_UPLOAD_ERROR,
        snackVariant: "error",
      });
    } else if (!(fileName && fileName.trim().length !== 0)) {
      error = true;
      setState({ ...state, fileNameHelperText: REQUIRED_ERROR });
    } else if (!(comment && comment.trim().length !== 0)) {
      error = true;
      setState({ ...state, commentHelperText: REQUIRED_ERROR });
    }
    if (!error && sectionId) {
      var fileObj = file;
      var newFileName = fileName;
      var newFileType = fileObj.path.split(".").pop();
      var blob = new Blob([fileObj], { type: newFileType });
      var newFile = new File([blob], `${newFileName}.${newFileType}`, {
        type: newFileType,
      });
      let uploadFormData = new FormData();
      uploadFormData.append("file", newFile);
      dispatch(
        uploadFileBySubStageId(studentId, productId, sectionId, uploadFormData)
      );
    }
  };

  const handleUploadClick = () => {
    setState({ ...state, open: true });
  };

  const handleDrop = (fileArray) => {
    if (fileArray.length !== 0) {
      if (bytesToMegaBytes(fileArray[0]["size"]) > 5) {
        setState({
          ...state,
          snackOpen: true,
          snackMsg: FILE_SIZE_ERROR,
          snackVariant: "error",
        });
      } else {
        setState({
          ...state,
          file: fileArray[0],
          fileName: upcomingFileName || fileArray[0]["name"],
        });
      }
    } else {
      setState({
        ...state,
        snackOpen: true,
        snackMsg: FILE_FORMAT_ERROR,
        snackVariant: "error",
      });
    }
  };

  const handleComment = (comment, e) => {
    setState({
      ...state,
      popoverComment: comment,
      anchorEl: e.currentTarget,
    });
  };

  // const handleDownload = (path, id, e) => {
  //   console.log("Jsk jsk");
  //   dispatch(getDownloadByDocumentId(studentId, sectionId, path, id));
  // };

  // New DOWNLOAD CALL //
  const strategyDocumentID = studentStages?.data
    ?.find((el) => el.stepName === "Strategy Session")
    ?.steps?.find((el) => el.stepName === "Strategy Session")
    ?.steps?.find((el) => el.stepName === "Strategy Documents")?.id;
  console.log(strategyDocumentID, "%%%%%substage Id");
  const handleDownload = (path, id, e) => {
    dispatch(getDownloadByDocumentId(studentId, sectionId, path, id));
  };

  const handleDelete = (id, path, e) => {};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value, [`${name}HelperText`]: null });
  };

  const isStageCompleted = () => {
    return completedStagesList.includes("Strategy Session");
  };

  const renderComponent = () => {
    const renderProps = {
      open: open,
      stepName: activeTabValue,
      handleCancel: handleCancel,
      handleUpload: handleUpload,
      handleUploadClick: handleUploadClick,
      onDrop: handleDrop,
      handleComment: handleComment,
      handleDownload: handleDownload,
      handleDelete: handleDelete,
      tableData: documentList,
      handleChange: handleChange,
      fileName: fileName,
      comment: comment,
      fileNameHelperText: fileNameHelperText,
      commentHelperText: commentHelperText,
      file: file,
      disabledUploadButton: isStageCompleted() || documentList.length === 0,
      isDisabledFileName: false,
      fileObject: fileObject,
      ...props,
    };

    let arr2 = studentStages?.data?.find(
      (el) => el.stepName === "Strategy Session"
    )?.steps;
    let arr3 = arr2?.find(({ stepName }) => stepName === "Pre Strategy call")
      ?.steps;
    let arr4 = arr3?.find(
      ({ stepName }) => stepName === "Pre Strategy Call Schedule"
    );

    let arr5 = arr2?.find(
      ({ stepName }) => stepName === "Strategy Session call"
    )?.steps;
    let arr7 = arr5?.find(
      ({ stepName }) => stepName === "Strategy Call Schedule"
    );

    switch (activeMainTabValue) {
      case "Pre Strategy call":
        return (
          <>
            <StyledButton
              className={classes.enableButton}
              variant={"contained"}
              style={
                customTheme["palette"][
                  disableStage?.data?.enablePreSS === true
                    ? "disabled"
                    : "contained"
                ]
              }
              onClick={handlePreStrategyEnable}
              disabled={disableStage?.data?.enablePreSS === true}
            >
              {"Enable Pre Strategy Call"}
            </StyledButton>
            <MomPoints
              activeStateValue={arr4?.id}
              activeTabValue='Pre Strategy call'
            />
          </>
        );
      case "Strategy Session call":
        return (
          <>
            <StyledButton
              className={classes.enableButton}
              variant={"contained"}
              style={
                customTheme["palette"][
                  disableStage?.data?.enableStrategySession === true
                    ? "disabled"
                    : "contained"
                ]
              }
              onClick={handleStrategyEnable}
              disabled={disableStage?.data?.enableStrategySession === true}
            >
              {"Enable Strategy Call"}
            </StyledButton>
            <MomPoints
              activeStateValue={arr7?.id}
              activeTabValue='Strategy Session call'
            />
          </>
        );
      case "Strategy Session":
        return (
          <StrategySessionFileUpload
            {...renderProps}
            studentId={studentId}
            productId={productId}
            stageId={sectionId}
            completeValue={"Strategy"}
            stageName='Strategy Session'
            activeTabValue='Strategy Session'
            fileId={fileId}
          />
        );
      case "Recommenders":
        return (
          <Grid container xs={12}>
            <Grid item xs={12}>
              <RecommendedToolIndex />
            </Grid>
          </Grid>
        );
    }
    switch (activeTabValue) {
      case "Test Transcripts":
        return (
          <TestTranscripts {...props} isStageCompleted={isStageCompleted()} />
        );
      case "Essay Questionnaire":
        return (
          <DocumentComponentForSS
            {...renderProps}
            isStageCompleted={isStageCompleted()}
            stageName='Essay Questionnaire'
          />
        );
      case "Graduate School List":
        return (
          <DocumentComponentForSS
            {...renderProps}
            isStageCompleted={isStageCompleted()}
            stageName='Graduate School List'
            completeValue={"Pre Strategy"}
            activeTabValue='Graduate School List'
          />
        );
      // case "LOR Questionnaire ":
      //   return (
      //     <DocumentComponentForSS stageName="LOR Questionnaire" />
      //   );
      case "Pre Strategy Worksheet":
        return (
          <DocumentComponentForSS
            {...renderProps}
            isStageCompleted={isStageCompleted()}
            stageName='Pre Strategy Worksheet'
          />
        );
      case "LOR Questionnaire":
        return (
          <DocumentComponentForSS
            {...renderProps}
            completeValue={"Pre Strategy"}
            // activeTabValue={"LOR Questionnaire"}
            stageName='LOR Questionnaire'
          />
        );

      // case "Essay Questionnaire":
      //   return (
      //     <DocumentComponentForSS />
      //   );
      // default:
      //   return <DocumentComponent {...renderProps} />;
    }
  };

  const handleTabChange = (e, newValue) => {
    let arr = steps
      ?.find((el) => el.stepName === "Pre Strategy")
      ?.steps.filter(({ id }) => id === newValue);

    let newSectionId = arr.length !== 0 ? arr[0]["id"] : null;
    setState({
      ...state,
      activeTabValue: arr[0]?.stepName,
      activeNeededValue: newValue,
      sectionId: newSectionId,
    });
  };

  const renderTabs = () => {
    return step1.length !== 0
      ? step1.map(({ stepName, id }, index) => (
          <CustomTab
            value={stepName}
            label={stepName}
            id={`${id}${index}`}
            minHeight={"72px"}
          />
        ))
      : null;
  };
  console.log(activeMainTabValue, "**************activeTabValue");
  const [transcriptValue, setTranscriptValue] = useState("");
  const handleMainTabChange = (e, newValue) => {
    let arr = steps.filter(({ stepName }) => stepName === newValue);
    let newSectionId = arr.length !== 0 ? arr[0]["id"] : null;
    setState({
      ...state,
      activeMainTabValue: newValue,
      sectionId: newSectionId,
    });
    if (newValue === "Pre Strategy") {
      let arr = steps
        ?.find((el) => el.stepName === "Pre Strategy")
        ?.steps.find(({ orderNo }) => orderNo === 1);
      setState({
        ...state,
        activeMainTabValue: newValue,
        sectionId: arr?.id,
        activeTabValue: "Pre Strategy Worksheet",
        activeNeededValue: arr?.id,
      });
      setTranscriptValue(arr?.id);
      // setState({ ...state, activeTabValue: "Test Transcripts" });
      // handleTabChange("a", arr?.id);
      // let newSectionId = arr.length !== 0 ? arr[0]["id"] : null;
      // setState({
      //   ...state,

      //   activeNeededValue: arr?.id,
      // });
    }
  };
  useEffect(() => {
    if (transcriptValue) {
      setState({
        ...state,
        activeTabValue: "Pre Strategy Worksheet",
        activeNeededValue: transcriptValue,
      });
    }
  }, [transcriptValue]);
  useEffect(() => {
    if (sectionId) {
      const strategySessionId = steps?.find(
        (item) => item.stepName === "Strategy Session"
      )?.steps;
      const fileUploadId = strategySessionId?.[0]["id"];
      setFileId(fileUploadId);
    }
  }, [sectionId]);
  const handleSnackClose = () => {
    setState({ ...state, snackOpen: false, snackVariant: "", snackMsg: "" });
  };

  const handleClickAway = () => {
    setState({ ...state, anchorEl: null, popoverComment: null });
  };
  const [tabList, setTabList] = useState([]);
  const handlePreStrategyEnable = () => {
    dispatch(getEnableCall(studentId, productId, "prestrategyenablemail"));
  };
  useEffect(() => {
    if (enableStage?.success === true) {
      setState({
        ...state,
        snackOpen: true,
        snackMsg: "Mail Sent To User",
        snackVariant: "success",
      });
      setSsNotif(false);
      dispatch(clearCustomData("enableStage"));
      dispatch(getDisableButtonCall(studentId, productId));
      setTimeout(() => {
        console.log(enableStage);
        console.log(disableStage);
      }, 5000);
    }
  }, [enableStage]);
  const handleStrategyEnable = () => {
    dispatch(getEnableCall(studentId, productId, "strategyenablemail"));
  };

  useEffect(() => {
    if (steps) {
      var orderingElement = steps?.find((el) => el.stepName === "Pre Strategy")
        ?.steps;
      let a = [];
      if (orderingElement) {
        a = [...orderingElement]?.sort((a, b) => a.orderNo - b.orderNo);
      }
      let ab = { id: "Recommenders", stepName: "Recommenders" };
      let b = [...steps, ab];
      console.log(b, "***********abcd");
      setSteps2(b);
      setTabList(a);
      // let arr = steps
      //   ?.find((el) => el.stepName === "Pre Strategy")
      //   ?.steps.find(({ orderNo }) => orderNo === 5);
      // setLastSection(arr?.stepName);
    }
  }, [steps]);

  return (
    <div className={classes.preStrategyWorkSheetContainer}>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <Box display={"flex"} alignItems={"center"}>
            <Box flex={1}>
              <CustomTabs
                value={activeMainTabValue}
                onChange={handleMainTabChange}
              >
                {renderTabs()}
              </CustomTabs>
            </Box>
          </Box>
          <Divider className={classes.dividerStyle} />
        </Grid>
        {activeMainTabValue === "Pre Strategy" && (
          <Grid item xs={12} lg={12}>
            <Box display={"flex"} alignItems={"center"}>
              <Box flex={1}>
                <CustomTabsPM
                  value={activeNeededValue}
                  tabList={tabList || []}
                  handleChange={handleTabChange}
                ></CustomTabsPM>
              </Box>
            </Box>
            <Divider className={classes.dividerStyle} />
          </Grid>
        )}
        {}

        <Grid item xs={12} lg={12}>
          {renderComponent()}
        </Grid>
      </Grid>
      <CommentBoxPopper
        handleClickAway={handleClickAway}
        anchorEl={anchorEl}
        popperComment={popoverComment}
      />
      <MySnackBar
        onClose={handleSnackClose}
        snackOpen={snackOpen}
        snackVariant={snackVariant}
        snackMsg={snackMsg}
      />

      <Backdrop className={classes.backdrop} open={loading}>
        <Loader />
      </Backdrop>
    </div>
  );
}

export default Index;
