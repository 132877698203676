import { FEEDBACK } from "../Action";

const initialState = {
  feedList: [],
  overviewList: [],
  feedExport: null,
  loading: false,
  ratingExport: null,
};

const FeedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case FEEDBACK.getFeedback: {
      return {
        ...state,
        feedList: action.payload,
        loading: action.loading || false,
      };
    }
    case FEEDBACK.getOverview: {
      return {
        ...state,
        overviewList: action.payload,
      };
    }

    case FEEDBACK.feedbackExport: {
      return {
        ...state,
        feedExport: action.payload,
      };
    }
    case FEEDBACK.overviewExport: {
      return {
        ...state,
        ratingExport: action.payload,
      };
    }

    default:
      break;
  }
  return state;
};

export default FeedbackReducer;
