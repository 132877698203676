import { makeStyles } from "@material-ui/core";
import { autocompleteClasses } from "@mui/material";
export const useStyles = makeStyles((theme) => ({
  headers: {
    fontSize: "20px",
    fontWeight: "400",
    marginTop: 0,
  },
  subHeaders: {
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "3.5",
    marginTop: "15px",
  },
  tableHeading: {
    marginBottom: "30px",
    fontSize: "13px",
  },
  tableScroll: {
    overFlow: "auto",
  },
  lineColor: {
    // background: "#8c9bab",
    background: "rgba(0, 0, 0, 0.54)",
  },
  containerStyle: {
    height: "80vh",
    overflowY: "auto",
    padding: "20px",
    paddingBottom: 0,
    overflowX: "hidden",
  },
}));
