import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import {
  getDownloadByDocumentIdForReport,
  getDownloadByDocumentIdForSchoolResearch,
  getQPMC2ReportCall,
} from "../../Actions/ProfileMentoring";
import CvViewer from "../ProfileGapAnalysis/CvViewer";
import { DocumentUploadPopup } from "../Utils/controls/DocumentUploadPopup";
import { customTheme, StyledButton } from "../Utils/controls/Styles";
import { DownloadCvTable } from "../Utils/DownloadCvTable";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

function DocumentComponentForSchoolResearch({
  open,
  stepName,
  handleCancel,
  handleUpload,
  handleUploadClick,
  onDrop,
  handleComment,
  activeSubTabValue,
  handleDelete,
  tableData,
  handleChange,
  comment,
  fileName,
  fileNameHelperText,
  commentHelperText,
  file,
  disabledUploadButton,
  isDisabledFileName,
  lastestCVLoading,
  ...props
}) {
  const { studentStages, subStageSteps, completedStages } = useSelector(
    (state) => state.StudentReducer
  );
  let stageObj =
    studentStages &&
    Array.isArray(studentStages) &&
    studentStages.find(({ stepName }) => stepName === "Profile Mentoring");
  let stepObj = studentStages?.data
    ?.find((el) => el.stepName === "Profile Mentoring")
    ?.steps?.find((el) => el.stepName === "Worksheets")
    ?.steps?.find((el) => el.stepName === "School Research Worksheet")?.id;
  const [stageId, setStageId] = useState(stepObj);

  useEffect(() => {
    if (studentStages) {
      if (activeSubTabValue !== undefined) {
        const a = studentStages.data.find(
          (item) => item.stepName === "Profile Mentoring"
        );

        const c = a.steps.find((data) => data.stepName === "Worksheets");

        const stageId1 = c.steps.find((item) =>
          item.stepName === activeSubTabValue ? item.id : null
        );
        setStageId(stageId1?.id);
        console.log(
          activeSubTabValue,
          c,
          stageId1,
          stageId1?.id,
          "gethesubtabvalues",
          stageId
        );
      }
    }
  }, [activeSubTabValue]);
  const {
    loading,
    documentModel,
    fileUploadStatus,
    downloadFileResponse,
    getQPMC2ReportStatus,
    qpmcStageCompletionStatus,
    momPoints,
  } = useSelector((state) => state.ProfileMentoringReducer);
  const params = useParams();
  const { studentId, productId } = params;
  const dispatch = useDispatch();

  const handleDownloadForWorksheet = (path, id) => {
    console.log(stageId, "whenclickthedownload");
    dispatch(
      getDownloadByDocumentIdForSchoolResearch(
        studentId,
        productId,
        stageId,
        path,
        id
      )
    );
  };
  console.log(getQPMC2ReportStatus?.data);
  return (
    <Grid container>
      <Grid item xs={8}>
        <Box margin={"26px 20px 30px 30px"}>
          <Box textAlign={"right"}>{console.log(props)}</Box>
          <Grid item xs={12}>
            <Box>
              <DownloadCvTable
                headers={["Version", "Uploaded date", "Comment", ""]}
                body={
                  getQPMC2ReportStatus?.success === true
                    ? getQPMC2ReportStatus?.data
                    : null
                }
                handleComment={handleComment}
                handleDownload={handleDownloadForWorksheet}
                handleDelete={handleDelete}
              />
            </Box>
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={4}>
        {/* <CvViewer doctype={"cv"} cvloading={lastestCVLoading} {...props} /> */}
      </Grid>
    </Grid>
  );
}

export default DocumentComponentForSchoolResearch;
