import { RECOMMENDER_SELECTOR } from "../Redux/Action";

const initialState = {
  recommenderModel: [],
  putRecommenderBox: [],
  downloadRecommender: [],
};

const RecommenderToolReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECOMMENDER_SELECTOR.getRecommendersDetails:
      return {
        ...state,
        recommenderModel: action.payload,
      };
    case RECOMMENDER_SELECTOR.downloadRecommenderDetails:
      return {
        ...state,
        downloadRecommender: action.payload,
      };
    case RECOMMENDER_SELECTOR.putRecommendersDetails:
      return {
        ...state,
        putRecommenderBox: action.payload,
      };
    default:
      return state;
  }
};
export default RecommenderToolReducer;
