// export const port='http://services.thecareerlabs.com:8080';
export const port = 'http://localhost:8080';
// Regine Lite path
export const rootPath = '/admin';
export const rootLoginPath = rootPath.concat('/login');
export const studentPath = rootPath.concat('/students');
export const studentIdPath = studentPath;
export const departmentPath = rootPath.concat('/departments');
export const collegePath = rootPath.concat('/college');
export const degreePath = rootPath.concat('/degree');
export const intakePath = rootPath.concat('/intake');
export const gradSchool = rootPath.concat('/gradschool');
export const addGradSchool = rootPath.concat('/gradschool/addgradschool');
export const addGradSchoolProgram = rootPath.concat('/gradschool/add-grad-school-program');
export const viewGradSchoolProgram = rootPath.concat('/gradschool/view-grad-school-program');
export const bSchool = rootPath.concat('/bschool');
export const addBSchool = rootPath.concat('/add-bschool');
export const rankView = rootPath.concat('/rank-view');
export const bsRank = rootPath.concat('/bsrank');
export const bsProgram = rootPath.concat('/view-bs-program');
export const bsRound = rootPath.concat('/bs-round');
export const bsEssayQuestion = rootPath.concat('/bs-essay-question');
export const addBSEssayQuestion = rootPath.concat('/add-bs-essayquestion');
export const addBSProgram = rootPath.concat('/add-bs-program');
export const bsAptitudeTest = rootPath.concat('/bs-aptitude-test');
export const addRound = rootPath.concat('/addRound');
export const editRound = rootPath.concat('/editRound');
export const coursePath = rootPath.concat('/courses');

export const editCoursePath = coursePath.concat('/edit/');
export const addCoursePath = coursePath.concat('/add');

export const questionBankPath = rootPath.concat('/questionbank');
export const universityPath = rootPath.concat('/university');

// Selva
export const cityPath = rootPath.concat('/city');
export const aspirationPath = rootPath.concat('/aspiration');
export const questionSetPath = rootPath.concat('/questionSet');
export const questionsPath = rootPath.concat('/questionSet/questions/');
export const choicePath = rootPath.concat('/questionSet/questions/choice/');
export const videoPath = rootPath.concat('/video');
export const productPath = rootPath.concat('/product');
export const documentDetailsPath = rootPath.concat('/documents');
export const webinarPath = rootPath.concat('/webinar');
export const notificationPath = rootPath.concat('/notification');
export const reportsPath = rootPath.concat('/reports');
export const reportContentPath = rootPath.concat('/report/:reportName');
export const testimonialsPath = rootPath.concat('/testimonials');
export const CRUDPath = rootPath.concat('/crud');
export const FolderPath = rootPath.concat('/folder');
export const Folder2Path = rootPath.concat('/folders');
export const FilePath = rootPath.concat('/files');
export const FolderSecondPagePath = rootPath.concat('/sub-folders');
export const FileSecondPagePath = rootPath.concat('/sub-files');
export const CRUDwebinarPath = rootPath.concat('/crud/webinar');
export const careerTrackPath = rootPath.concat('/career-track');
export const careerTrackVideoSetPath = rootPath.concat('/career-track/videoset/');
export const careerTrackVideoPath = rootPath.concat('/career-track/videoset/video/');
export const callSchedulePath = rootPath.concat('/schedule');
export const productBasedPath = rootPath.concat('/based');
export const appVersion = rootPath.concat('/appVersion');
export const testPath = rootPath.concat('/tests');
export const testCreate = rootPath.concat('/tests/create');
export const testEdit = rootPath.concat('/tests/edit');
export const wallPath = rootPath.concat('/wall');
export const createPath = rootPath.concat('/wall/create');
export const editPath = rootPath.concat('/wall/edit');
export const createSuccessPath = rootPath.concat('/wall/success/create');
export const editSuccessPath = rootPath.concat('/wall/success/edit');
export const createWhatsNewPath = rootPath.concat('/wall/whatsnew/create');
export const editWhatsNewPath = rootPath.concat('/wall/whatsnew/edit');
export const createStoriesPath = rootPath.concat('/wall/stories/create');
export const placementDrives = rootPath.concat('/placement_drives');
export const drivePath = rootPath.concat('/placement_drives/drive_result');
export const starterPackPath = rootPath.concat('/starterPack');
export const productVariantPath = rootPath.concat('/product/variant');
export const productPunchingPath = rootPath.concat('/productpunching');
export const productStudentPath = rootPath.concat('/productpunching/Clients');
export const productcomboPath = rootPath.concat('/productcombo');
export const productuserPunchingPath = rootPath.concat('/productpunching/');
export const productstructurePath = rootPath.concat('/productstructure/');
export const clientDetailsPath = rootPath.concat('/clientdetails/');
export const cvReviewPath = rootPath.concat('/cvReview');
export const callSummaryLayoutPath = rootPath.concat('/callSummary/');
export const obOperationPath = rootPath.concat('/obOperation');
export const listUsersProdBasedPath = rootPath.concat('/listUsers');
export const stagedTabsPath = rootPath.concat('/stageTabs/');
export const productActivationPath = rootPath.concat('/productActivation');
export const landingAdminPath = rootPath.concat('/welcome-admin');
export const premiumUsersPath = rootPath.concat('/premium-users');
export const clsaPath = rootPath.concat('/clsa');
export const driveTrackingPath = rootPath.concat('/drive-tracking');
export const clsaResultPath = rootPath.concat('/clsa/clsa_result');
export const thirdYear = rootPath.concat('/third-year');
export const createWebinarPath = rootPath.concat('/third-year/webinar/create');
export const editWebinarPath = rootPath.concat('/third-year/webinar/edit');

/*Exam Space Paths */
export const examSpacePath = rootPath.concat('/exam-space');
export const examSpaceUniversityPath = rootPath.concat('/exam-space/university');
export const semesterPath = rootPath.concat('/exam-space/university/semester');
export const subjectPath = rootPath.concat('/exam-space/university/semester/subject');
export const chapterPath = rootPath.concat('/exam-space/university/semester/subject/chapter');
/*Exam Space Paths */

/*IBH Path*/
export const ibhSpacePath = rootPath.concat('/ibh');
export const createIbhWebinarPath = rootPath.concat('/ibh/webinar/create');
export const editIbhWebinarPath = rootPath.concat('/ibh/webinar/edit');
/*IBH Path*/

export const clsaTestCreate = rootPath.concat('/clsa/create');
export const clsaTestEdit = rootPath.concat('/clsa/edit/');
export const result = rootPath.concat('/result/');
export const dashboard = rootPath.concat('/dashboard');

// Rengine another version path

export const loginPath = rootPath.concat('/Mlogin');
export const curatedPath = rootPath.concat('/curatedCourse');
export const personelInfoPath = rootPath.concat('/personalInfo');

// export const OnboardingPersonalInfoPath = rootPath.concat('/Onboarding/personalInfo');
// export const OnboardingAcademicInfoPath = rootPath.concat('/Onboarding/academicInfo');
export const OnboardingPath = rootPath.concat('/onboarding');
export const userManagementPath = rootPath.concat('/user-management');

// LMS
export const lms_course_landing = rootPath.concat('/course-landing');
export const lms_feedback = rootPath.concat('/feedback');
export const lms_add_topic = rootPath.concat('/add-topic');
export const lms_study_plans = rootPath.concat('/study-plans');
export const lms_add_study_plan = rootPath.concat('/add-study-plans');
export const lms_view_study_plan = rootPath.concat('/view-study-plan/:studyPlanId');
export const lms_feedback_overview = rootPath.concat('/overview-rating/:videoId');
export const lms_concept = rootPath.concat('/concept');
export const lms_course_taken = rootPath.concat('/course-taken');
export const lmsTest = rootPath.concat('/test');
export const lms_add_test = rootPath.concat('/test/add');
export const bulk_upload = rootPath.concat('/test/bulk-upload');
export const single_upload = rootPath.concat('/test/single-upload');
export const lms_copy_question = rootPath.concat('/test/copy-questions');
export const lms_passage = rootPath.concat('/passage');
export const lms_result = rootPath.concat('/clsa-result/');

// NAVIGATE ROUTE PATH
export const NAVIGATE_TO = {
  reportContentPath: (reportName) => `${rootPath}/report/${reportName}`,
  viewStudyPlanPath: (studyPlanId) => `${rootPath}/view-study-plan/${studyPlanId}`,
  overViewRatingPath: (video) => `${rootPath}/overview-rating/${video}`,
};

//Add two numbers in javascript to the path format
