import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import DateRangePickerComp from "./DateRangePicker";
import ChartCard from "./ChartCard";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { analyticsData } from "../../../Actions/DashboardAction";
import Loader from "../../../Lms/Utils/Loader";
import moment from "moment";

const useStyle = makeStyles({
  heading1: {
    color: "#354354",
  },
});

const DashboardAnalytics = () => {
  const [startDate, setStartDate] = useState(
    new Date(new Date().setUTCHours(0, 0, 0))
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().setUTCHours(23, 59, 59))
  );
  const [value, setValue] = useState(false);

  const classes = useStyle();
  const { loading, error, analytics } = useSelector(
    (state) => state.dashboardAnalyticsReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    analyticsCalling();
    setValue(false);
  }, [value]);

  let requestBody = {
    fromDate: startDate,
    toDate: endDate,
  };

  const analyticsCalling = () => {
    dispatch(analyticsData(requestBody));
  };

  const chartOptionBar = {
    colors: ["#FF7189"],
    chart: {
      sparkline: { enabled: true },
    },
    plotOptions: {
      bar: { columnWidth: "38%", borderRadius: 2 },
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function(seriesName) {
            return "";
          },
        },
      },
      marker: {
        show: false,
      },
    },
  };

  const chartOptionArea = {
    colors: ["#F2C05F"],
    chart: {
      sparkline: { enabled: true },
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function(seriesName) {
            return "";
          },
        },
      },
      marker: {
        show: false,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.8,
        opacityTo: 0,
        stops: [30, 90, 100],
      },
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      colors: undefined,
      width: 2,
      dashArray: 0,
    },
  };

  const chartOptionLine = {
    colors: ["#39C6A9"],
    chart: {
      sparkline: { enabled: true },
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function(seriesName) {
            return "";
          },
        },
      },
      marker: {
        show: false,
      },
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      colors: undefined,
      width: 2,
      dashArray: 0,
    },
  };

  return (
    <Grid item xs={6}>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "5%",
          }}
        >
          <Typography className={classes.heading1} fontWeight={'600'} component="h1" variant="h5">
            Analytics Overview
          </Typography>
          <DateRangePickerComp
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setValue={setValue}
          />
        </Box>
        <Box sx={{ marginTop: "8%" }}>
          <Grid container spacing={3} rowSpacing={5}>
            {analytics?.data && (
              <>
                <ChartCard
                  type={"bar"}
                  number={analytics?.data?.liveDriveCount}
                  chartOption={chartOptionBar}
                  title="Total Drives Live"
                  chartData={analytics?.data?.liveDriveCountGraph.reverse()}
                />
                <ChartCard
                  type={"area"}
                  number={analytics?.data?.updatedDriveCount}
                  chartOption={chartOptionArea}
                  title="Total Drives Updated"
                  chartData={analytics?.data?.updatedDrivesGraph.reverse()}
                />
                <ChartCard
                  type={"line"}
                  number={analytics?.data?.softwareTakers}
                  chartOption={chartOptionLine}
                  title="Total Software CLSA Takers"
                  chartData={analytics?.data?.softwareClsaGraph.reverse()}
                />
                <ChartCard
                  type={"bar"}
                  number={analytics?.data?.salesTakers}
                  chartOption={chartOptionBar}
                  title="Total Sales CLSA Takers"
                  chartData={analytics?.data?.salesClsaGraph.reverse()}
                />
                <ChartCard
                  type={"area"}
                  number={analytics?.data?.bankingTakers}
                  chartOption={chartOptionArea}
                  title="Total Banking CLSA Takers"
                  chartData={analytics?.data?.bankingClsaGraph.reverse()}
                />
                {/* <ChartCard
                  type={"line"}
                  number={analytics?.data?.totalVideoBands}
                  chartOption={chartOptionLine}
                  title="Total video bands updated"
                  chartData={analytics?.data?.videoBandsGraph}
                /> */}
                <ChartCard
                  type={"bar"}
                  number={analytics?.data?.totalNoOfDriveNeedAssistance}
                  chartOption={chartOptionBar}
                  title="Total drives that need assistance"
                  chartData={analytics?.data?.driveNeedAssistanceGraph.reverse()}
                />
                <ChartCard
                  type={"area"}
                  number={analytics?.data?.noOfStudentInHigherSoftwareBand}
                  chartOption={chartOptionArea}
                  title="Number of students in higher band "
                  chartData={analytics?.data?.higherSoftwareBandGraph.reverse()}
                />
                <ChartCard
                  type={"line"}
                  number={analytics?.data?.noOfStudentInLowerSoftwareBand}
                  chartOption={chartOptionLine}
                  title="Number of students in lower band "
                  chartData={analytics?.data?.lowerSoftwareBandGraph.reverse()}
                />
              </>
            )}
            <div style={{ margin: "2rem auto", width: "60%" }}>
              {loading && <Loader />}
              {error && <Alert severity="info">0 Tests Found</Alert>}
            </div>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};

export default DashboardAnalytics;
