import {
  Backdrop,
  ClickAwayListener,
  Grid,
  IconButton,
  Popper,
  TextField,
  Box,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { clearCustomData } from "../../Actions/CvReview";
import {
  pgaReportUpload,
  getStudentPgaList,
  pgaDownload,
} from "../../Actions/UploadPgaReport";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import {
  Container,
  ContentWrapper,
  customTheme,
  FlexEndView,
  FlexJustifyView,
  Paper,
  StyledButton,
  StyledTable,
  Typo,
  useStyles,
  Wrapper,
} from "../../Asset/StyledComponents/CvReview";
import MySnackBar from "../MySnackBar";
import DropzoneComponent from "../Utils/controls/CustomDropZone";
import CustomPopup from "../Utils/controls/CustomPopup";
import Loader from "../Utils/controls/Loader";
import { getStudentStageByProductId } from "../../Actions/Student";

const PGA_REPORT_UPLOAD_MESSAGE = "PGA Report Uploaded Successfully";
const FILE_REQUIRED_MESSAGE = "Please select a file";
const FILE_SELECT_INVALID = "Please select a valid format (.pdf) file";

function Index(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    snackOpen: false,
    snackVariant: "",
    snackMsg: "",
    pgaReviewList: [],
    cvStatus: null,
    commentText: "",
    popupOpen: false,
    anchorEl: null,
    file: null,
    popperComment: "",
    cvloader: false,
  });
  const {
    snackOpen,
    snackVariant,
    snackMsg,
    pgaReviewList,
    commentText,
    popupOpen,
    anchorEl,
    file,
    popperComment,
    cvloader,
  } = state;

  const params = useParams();
  const { studentId, productId } = params;
  const dispatch = useDispatch();
  const {
    isLoading,
    pgaUploadStatus,
    studentPgaList,
    pgaDownloadStatus,
  } = useSelector((state) => state.UploadPgaReportReducer);

  const handleSnack = (open, color, message) => {
    setState({
      ...state,
      snackOpen: open,
      snackVariant: color,
      snackMsg: message,
    });
  };

  useEffect(() => {
    dispatch(
      getStudentStageByProductId(studentId, productId, (response) => {
        if (response.status === 200) {
          let getstageId = response?.data?.data[1]?.id;
          dispatch(getStudentPgaList(studentId, productId, getstageId));
        }
      })
    );
  }, []);

  const { studentStages } = useSelector((state) => state.StudentReducer);

  useEffect(() => {
    if (studentPgaList) {
      if (studentPgaList.success) {
        console.log(studentPgaList?.data?.fileName);
        setState({
          ...state,
          pgaReviewList: studentPgaList?.data || [],
        });
      } else {
        handleSnack(true, "error", studentPgaList.message);
      }
      // dispatch(clearCustomData("studentCvList"));
    }
  }, [studentPgaList]);

  useEffect(() => {
    if (pgaDownloadStatus) {
      if (!pgaDownloadStatus.success) {
        handleSnack(true, "error", pgaDownloadStatus.message);
      }
      dispatch(clearCustomData("pgaDownloadStatus"));
    }
  }, [pgaDownloadStatus]);

  useEffect(() => {
    // console.log(studentStages, "GetAllthedetails");
    if (pgaUploadStatus) {
      const customProp = {
        snackOpen: true,
        popupOpen: false,
        file: null,
        commentText: "",
      };
      if (pgaUploadStatus.success) {
        setState({
          ...state,
          snackVariant: "success",
          snackMsg: PGA_REPORT_UPLOAD_MESSAGE,
          cvloader: !cvloader,
          ...customProp,
        });
        dispatch(
          getStudentPgaList(studentId, productId, studentStages?.data[1]?.id)
        );
      } else {
        setState({
          ...state,
          snackVariant: "error",
          snackMsg: pgaUploadStatus.message,
          ...customProp,
        });
      }
      dispatch(clearCustomData("pgaUploadStatus"));
      console.log(pgaUploadStatus);
    }
  }, [pgaUploadStatus]);

  const handleDrop = (files) => {
    console.log(files, "getthefiels");
    if (files && files.length !== 0) {
      setState({ ...state, file: files[0] });
    } else {
      setTimeout(() => handleSnack(true, "error", FILE_SELECT_INVALID), 200);
    }
  };

  const handleCancel = () => {
    setState({ ...state, popupOpen: false, file: null, commentText: "" });
  };

  const handlePopupOpen = () => {
    // console.log(studentStages?.data[1]?.id, "GettheAllDetails");
    setState({ ...state, popupOpen: true });
  };

  const handleUpload = () => {
    if (file) {
      let uploadFormData = new FormData();
      uploadFormData.append("file", file);
      dispatch(
        pgaReportUpload(
          studentId,
          productId,
          uploadFormData,
          studentStages?.data[1]?.id
        )
      );
    } else {
      handleSnack(true, "error", FILE_REQUIRED_MESSAGE);
    }
  };

  const handleSnackClose = () => {
    setState({ ...state, snackOpen: false, snackVariant: "", snackMsg: "" });
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleDelete = () => {
    setState({
      ...state,
      file: null,
    });
  };

  const renderDialogContent = () => {
    return (
      <div>
        <DropzoneComponent acceptTypes={".pdf"} onDrop={handleDrop} />
        <FlexJustifyView>
          <Typo
            variant={"h6"}
            className={classes.subTextStyle}
            color={"#333333"}
          >
            {"Document Details"}
          </Typo>
        </FlexJustifyView>
        <TextField
          placeholder={"File name"}
          id={"fileName"}
          value={file ? pgaReviewList[0]?.previousFileName : ""}
          name={"fileName"}
          // helperText={fileNameHelperText || " "}
          // error={fileNameHelperText && fileNameHelperText.length !== 0}
          onChange={handleChange}
          disabled={true}
          fullWidth
        />
      </div>
    );
  };

  const handleClickAway = () => {
    if (document.activeElement.id !== "command_icon")
      setState({ ...state, anchorEl: null, popperComment: "" });
  };

  const renderPopperContent = () => {
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <Paper>
          <Wrapper>
            <Typo variant={"body1"} color={"#18AAE7"}>
              {"Comment"}
            </Typo>
            <ContentWrapper className={classes.popperContentStyle}>
              <Typo
                variant={"body1"}
                color={"#333333"}
                paragraph={true}
                className={classes.popperContent}
              >
                {popperComment}
              </Typo>
            </ContentWrapper>
          </Wrapper>
        </Paper>
      </ClickAwayListener>
    );
  };

  const handleDownload = (id) => {
    dispatch(pgaDownload(studentId, productId, id));
  };
  const renderTable = () => {
    console.log(pgaReviewList);
    console.log(
      pgaReviewList[pgaReviewList?.length - 1]?.fileName,
      "pgaListget"
    );
    // console.log(cvReviewList[cvReviewList?.length - 1]?.fileName);
    return pgaReviewList &&
      pgaReviewList?.length !== 0 &&
      pgaReviewList[0]?.id !== null ? (
      <StyledTable>
        <tr>
          <th>{"File Name"}</th>
          <th>{"Uploaded Date"}</th>
          <th>{"Version"}</th>
          <th></th>
        </tr>
        {pgaReviewList?.map(({ uploadedDate, id, fileName, versionName }) => (
          <tr>
            <td>{fileName}</td>
            <td>{uploadedDate}</td>
            <td>{versionName}</td>
            <td>
              <StyledButton
                height={"25px"}
                variant={"outlined"}
                style={customTheme.palette.outlined}
                onClick={() => handleDownload(id)}
              >
                {"Download"}
              </StyledButton>
            </td>
          </tr>
        ))}
      </StyledTable>
    ) : (
      <StyledTable>
        <Box width={"100%"}>
          <tr>
            <th>{"File Name"}</th>
            <th>{"Uploaded Date"}</th>
            <th>{"Version"}</th>
            {/* <th>{""}</th> */}
          </tr>
        </Box>
        <Box display={"flex"} justifyContent={"center"} width={"100%"}>
          <DescriptionOutlinedIcon
            sx={{ fontSize: "6rem", color: "darkgray", marginTop: "2rem" }}
          />
        </Box>
        <Typo
          style={{
            fontSize: "1.2rem",
            padding: "0.5rem 2rem",
            textAlign: "center",
          }}
        >
          {"No Document added"}
        </Typo>
      </StyledTable>
    );
  };

  return (
    <Grid container>
      <Grid item sm={12} md={8}>
        <Container>
          <Grid container spacing={3} className={classes.flexFlow}>
            <Grid item lg={12}>
              <FlexEndView>
                <StyledButton
                  variant={"contained"}
                  style={{
                    backgroundColor: "#18AAE7",
                    color: "#FFFFFF",
                    marginTop: "2rem",
                  }}
                  onClick={handlePopupOpen}
                  // disabled={!isReview}
                >
                  {"Upload"}
                </StyledButton>
              </FlexEndView>
            </Grid>
            <Grid item lg={12} className={classes.overflow}>
              {renderTable()}
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid item sm={12} md={4}>
        {/* <CvViewer doctype={"cv"} {...props} cvloading={cvloader} /> */}
      </Grid>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement={"bottom-start"}
      >
        {renderPopperContent()}
      </Popper>
      <CustomPopup
        open={popupOpen}
        title={"Upload Document | Profile Gap Analysis Report"}
        width={"600px"}
        leftButtonText={"Cancel"}
        rightButtonText={"Upload"}
        handleLeftButton={handleCancel}
        handleRightButton={handleUpload}
        handleClose={handleCancel}
        dialogContent={renderDialogContent()}
      />
      <MySnackBar
        onClose={handleSnackClose}
        snackOpen={snackOpen}
        snackVariant={snackVariant}
        snackMsg={snackMsg}
      />
      <Backdrop className={classes.backdrop} open={isLoading}>
        <Loader />
      </Backdrop>
    </Grid>
  );
}

export default Index;
