import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { URL } from "../../Actions/URL";
import PdfViewer from "../../Utils/PdfViewer";
import CvViewer from "../ProfileGapAnalysis/CvViewer";
import { DocumentUploadPopup } from "../Utils/controls/DocumentUploadPopup";
import { customTheme, StyledButton, Typo } from "../Utils/controls/Styles";
import { DownloadCvTable } from "../Utils/DownloadCvTable";
import FileViewer from "../../Utils/FileViewer";
import { qpmcStageComplete } from "../../Actions/ProfileMentoring";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomizedSnackBars from "../CustomizedSnackBars";
import MySnackBar from "../MySnackBar";

function DocumentComponentForSS({
  open,
  stepName,
  handleCancel,
  handleUpload,
  handleUploadClick,
  onDrop,
  handleComment,
  handleDownload,
  handleDelete,
  activeTabValue,
  tableData,
  completeValue,
  // handleStageComplete,
  handleChange,
  comment,
  fileName,
  fileNameHelperText,
  commentHelperText,
  file,
  disabledUploadButton,
  isDisabledFileName,
  fileObject,
  stageName,
  ...props
}) {
  console.log(activeTabValue);
  const dispatch = useDispatch();
  const params = useParams();
  const [state, setState] = useState({
    snackMessage: "",
    snackOpen: false,
    snackVariant: "",
  });
  const { snackMsg, snackOpen, snackVariant } = state;
  const { studentId, productId, subStageId } = params;
  const handleStageComplete = () => {
    dispatch(qpmcStageComplete(studentId, productId, completeValue));
    // if (qpmcStageCompletionStatus?.success) {
    setState({
      ...state,
      snackOpen: true,
      snackVariant: "success",
      snackMsg: "Pre Strategy Completed Successfully",
    });

    // setCompletionMessage(qpmcStageCompletionStatus?.message);
    // }
  };
  const handleSnackClose = () => {
    setState({
      ...state,
      snackOpen: false,
      snackMsg: "",
      snackVariant: "",
    });
  };
  const { qpmcStageCompletionStatus } = useSelector(
    (state) => state.ProfileMentoringReducer
  );
  // useEffect(() => {
  //     if (qpmcStageCompletionStatus?.success) {
  //         setState({
  //             ...state,
  //             snackOpen: true,
  //             snackVariant: "success",
  //             snackMsg: qpmcStageCompletionStatus?.message,
  //         });

  //         // setCompletionMessage(qpmcStageCompletionStatus?.message);
  //     }
  // else {
  //     setState({
  //         ...state,
  //         snackOpen: true,
  //         snackVariant: "error",
  //         snackMsg: qpmcStageCompletionStatus?.message,
  //     });
  // }
  // }, [qpmcStageCompletionStatus]);
  return (
    <Grid container>
      <Grid item xs={8}>
        {/* <Grid style={{ marginLeft: "20px" }}>{stageName} Download</Grid> */}

        <Box margin={"26px 20px 30px 30px"}>
          <Grid style={{ marginLeft: "20px" }}>{stageName} Download</Grid>
          <Box textAlign={"right"}>
            {activeTabValue === "Strategy Session" && (
              <StyledButton
                variant={"outlined"}
                style={customTheme["palette"]["outlined"]}
                onClick={handleStageComplete}
              >
                {"Strategy Session Complete"}
              </StyledButton>
            )}
            &nbsp;&nbsp;&nbsp;
            {activeTabValue === "Graduate School List" && (
              <StyledButton
                variant={"outlined"}
                style={customTheme["palette"]["outlined"]}
                onClick={handleStageComplete}
              >
                {"Pre Strategy Complete"}
              </StyledButton>
            )}
            &nbsp;&nbsp;&nbsp;
            <StyledButton
              variant={"contained"}
              style={
                customTheme["palette"][
                  disabledUploadButton ? "disabled" : "contained"
                ]
              }
              onClick={handleUploadClick}
              disabled={disabledUploadButton}
            >
              {"Upload"}
            </StyledButton>
          </Box>
          <Grid item xs={12}>
            <Box>
              <DownloadCvTable
                headers={["Version", "Uploaded date", "Comment", ""]}
                body={tableData}
                handleComment={handleComment}
                handleDownload={handleDownload}
                handleDelete={handleDelete}
              />
            </Box>
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={4}>
        <FileViewer
          filePath={window.URL.createObjectURL(new Blob([fileObject?.path]))}
          fileType={fileObject?.type}
        />
      </Grid>
      <DocumentUploadPopup
        open={open}
        title={`Upload Document | ${stepName}`}
        leftButtonText={"Upload"}
        rightButtonText={"Cancel"}
        handleLeftButton={handleUpload}
        handleRightButton={handleCancel}
        handleClose={handleCancel}
        acceptTypes={".doc, .docx, .xls, .xlsx, .csv, .pdf"}
        onDrop={onDrop}
        handleChange={handleChange}
        comment={comment}
        fileName={fileName}
        fileNameHelperText={fileNameHelperText}
        commentHelperText={commentHelperText}
        file={file}
        isDisabledFileName={isDisabledFileName}
      />
      <MySnackBar
        onClose={handleSnackClose}
        snackOpen={snackOpen}
        snackVariant={snackVariant}
        snackMsg={snackMsg}
      />
    </Grid>
  );
}

export default DocumentComponentForSS;
