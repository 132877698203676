import React, { useState, useEffect } from 'react';
import { ButtonsContainer, CreatePostContainer } from '../Assets/Styles/CreatePostStyles';
import BackHandler from '../Components/BackHandler';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Formik, Form } from 'formik';
import Controls from '../../Utils/controls/Controls';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { MultipleFileUploadField } from '../Components/Upload/MultipleFileUploadField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {
  getWallUserType,
  getWallRedirectionList,
  createWallWhatsNew,
  updateWallWhatsNew,
  showWallWhatsNew,
} from '../../../Actions/WallActions';
import Notification from '../../Utils/Notification';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { wallPath } from '../../RoutePaths';
import ConfirmDialog from '../../Utils/ConfirmDialog';
import { ExistingMedia } from '../Components/Upload/ExistingMedia';

const useStyles = makeStyles({
  root: {
    '& .MuiSelect-root': {
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '4px',
      padding: '1rem',
    },
  },
  captionStyle: {
    width: '48%',
    marginTop: 20,
    marginBottom: 15,
  },
  roleStyle: {
    width: '100%',
    marginTop: 10,
  },
  title: {
    fontSize: '16px',
    color: '#052A4E',
    marginTop: 40,
    fontWeight: 400,
    lineHeight: '19.5px',
  },
  divider: { backgroundColor: '#D8D8D8', marginTop: 40 },
  spacer: {
    width: '80%',
    marginTop: '10px',
  },
  hostImage: {
    borderRadius: '50%',
  },
});

const CreateWhatsNew = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const isEdit = params?.id;

  const { usertype } = useSelector((state) => state.getWallUserTypeReducer);
  const { redirectiontype } = useSelector((state) => state.getWallRedirectionTypeReducer);
  const { post } = useSelector((state) => state.wallWhatsNewReducer);

  const [existingWallFiles, setExistingWallFiles] = useState([]);
  const [mediaType, setMediaType] = useState('');
  const [imageDelete, setImageDelete] = useState('');

  const [state, setState] = useState({
    id: params?.id || null,
    elevWhatsNewUserTypes: [],
    elevWhatsNewRedirectionUrl: null,
    caption: '',
    redirectionText: '',
    mediaUrl: '',
    supportingMedia: 'Image',
    elevWhatsNewImage: [],
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  useEffect(() => {
    dispatch(getWallUserType());
    dispatch(getWallRedirectionList());
    if (isEdit) {
      dispatch(showWallWhatsNew(params?.id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (post) {
      if (post.success) {
        setState(post?.data);
        setMediaType(post?.data?.supportingMedia);
        setImageDelete(post?.data?.id);
        setExistingWallFiles([post?.data?.elevWhatsNewImage] || []);
      }
    }
  }, [post]);

  /* Validating if the video url is valid or not */
  function ValidURL(str) {
    var regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    if (!regex.test(str)) {
      return false;
    } else {
      return true;
    }
  }

  const validate = (values) => {
    if (values.supportingMedia === 'Image' && values.elevWhatsNewImage?.length === 0) {
      setNotify({
        isOpen: true,
        message: 'Please upload an image',
        type: 'error',
      });
      return false;
    }

    if (
      values.supportingMedia === 'Image' &&
      values.elevWhatsNewImage[0]?.errors[0]?.code === 'file-too-large'
    ) {
      setNotify({
        isOpen: true,
        message: 'File is larger than 2MB',
        type: 'error',
      });
      return false;
    }

    if (
      (values.supportingMedia === 'Image' &&
        values.elevWhatsNewImage[0]?.errors[0]?.code === 'file-invalid-type') ||
      (values.supportingMedia === 'Image' && values.elevWhatsNewImage[0]?.url.includes('svg'))
    ) {
      setNotify({
        isOpen: true,
        message: 'File type is wrong, Upload accepted format only',
        type: 'error',
      });
      return false;
    }

    if (values.supportingMedia === 'Video' && values.mediaUrl.length === 0) {
      setNotify({
        isOpen: true,
        message: 'Please enter a video link',
        type: 'error',
      });
      return false;
    }

    if (values.supportingMedia === 'Video' && values.mediaUrl.length !== 0) {
      if (ValidURL(values.mediaUrl) === false) {
        setNotify({
          isOpen: true,
          message: 'Not a valid URL',
          type: 'error',
        });
        return false;
      }
      return true;
    }
    return true;
  };

  const postvalidationSchema = yup.object({
    elevWhatsNewUserTypes: yup
      .array()
      .min(1)
      .required('User type is required'),
    elevWhatsNewRedirectionUrl: yup
      .object()
      .nullable()
      .required('Redirection is required'),
    caption: yup.string().required('caption is required'),
    redirectionText: yup.string().required('Redirection text is required'),
  });

  const createPost = (post) => {
    let payload = {
      id: post.id,
      caption: post.caption,
      redirectionText: post.redirectionText,
      supportingMedia: post.supportingMedia,
      mediaUrl:
        post.supportingMedia === 'Image'
          ? post.elevWhatsNewImage?.url || post.elevWhatsNewImage[0]?.url
          : post.mediaUrl,
      elevWhatsNewUserTypes: post.elevWhatsNewUserTypes,
      elevWhatsNewRedirectionUrl: post.elevWhatsNewRedirectionUrl,
    };

    if (isEdit) {
      dispatch(updateWallWhatsNew(payload));
    } else {
      dispatch(createWallWhatsNew(payload));
    }
    setNotify({
      isOpen: true,
      message: `${
        isEdit ? "What's new content updated" : "What's new content created"
      } Successfully`,
      type: 'success',
    });
    setTimeout(() => {
      history.push({
        pathname: wallPath,
        tab: 4,
      });
    }, 1500);
  };

  const onDiscard = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    setTimeout(() => {
      history.push({
        pathname: wallPath,
        tab: 4,
      });
    }, 1200);
    setNotify({
      isOpen: true,
      message: 'Discarded',
      type: 'warning',
    });
  };

  return (
    <>
      <BackHandler title={`${isEdit ? 'Edit' : 'Create new - '} What's New`} tab={4} />
      <CreatePostContainer>
        <Formik
          initialValues={state || []}
          validationSchema={postvalidationSchema}
          onSubmit={(values, { resetForm }) => {
            if (validate(values)) {
              createPost(values);
              resetForm();
            }
          }}
          enableReinitialize
        >
          {({ handleSubmit, errors, handleChange, values, touched, setFieldValue, submitForm }) => {
            return (
              <>
                <div className='CreatePost'>
                  <Form onSubmit={handleSubmit} autoComplete='off'>
                    <RadioGroup
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '10px',
                      }}
                      aria-label='type'
                      disabled
                      name='supportingMedia'
                      value={values.supportingMedia}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value='Image'
                        control={<Radio color='primary' />}
                        label='Image'
                        disabled={mediaType === 'Video'}
                      />
                      <FormControlLabel
                        value='Video'
                        control={<Radio color='primary' />}
                        label='Video'
                        disabled={mediaType === 'Image'}
                      />
                    </RadioGroup>

                    <Grid
                      item
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <FormControl className={classes.root} style={{ width: '48%' }}>
                        <Autocomplete
                          multiple
                          id='elevWhatsNewUserTypes'
                          name='elevWhatsNewUserTypes'
                          getOptionLabel={(option) => option?.userType}
                          options={usertype ?? []}
                          onChange={(e, value) => {
                            setFieldValue(
                              'elevWhatsNewUserTypes',
                              value !== null ? value : usertype
                            );
                          }}
                          fullWidth
                          value={values.elevWhatsNewUserTypes}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label='Select User type'
                              name='elevWhatsNewUserTypes'
                              variant='outlined'
                              error={
                                touched.elevWhatsNewUserTypes &&
                                Boolean(values.elevWhatsNewUserTypes.length === 0)
                              }
                            />
                          )}
                          style={{
                            marginTop: '10px',
                            marginBottom: '10px',
                          }}
                        />
                      </FormControl>

                      <FormControl className={classes.root} style={{ width: '48%' }}>
                        <Autocomplete
                          id='elevWhatsNewRedirectionUrl'
                          name='elevWhatsNewRedirectionUrl'
                          getOptionLabel={(option) => option?.redirectionUrlName}
                          options={redirectiontype ?? []}
                          onChange={(e, value) => {
                            setFieldValue('elevWhatsNewRedirectionUrl', value);
                          }}
                          fullWidth
                          value={values.elevWhatsNewRedirectionUrl}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label='Select Redirection'
                              name='elevWhatsNewRedirectionUrl'
                              variant='outlined'
                              error={
                                touched.elevWhatsNewRedirectionUrl &&
                                Boolean(values.elevWhatsNewRedirectionUrl === null)
                              }
                            />
                          )}
                          style={{
                            marginTop: '10px',
                            marginBottom: '10px',
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Controls.Input
                        label='Enter Caption'
                        value={values.caption}
                        name='caption'
                        onChange={handleChange}
                        error={touched.caption && Boolean(errors.caption)}
                        className={classes.captionStyle}
                      />

                      <Controls.Input
                        label='Enter Redirection Text'
                        name='redirectionText'
                        value={values.redirectionText}
                        onChange={handleChange}
                        error={touched.redirectionText && Boolean(errors.redirectionText)}
                        className={classes.captionStyle}
                      />
                    </Grid>

                    <Grid container direction='column' style={{ width: '100%', marginTop: '10px' }}>
                      {values.supportingMedia === 'Image' && (
                        <MultipleFileUploadField
                          name='elevWhatsNewImage'
                          fileType='image'
                          isSingle={true}
                          disable={
                            (values.elevWhatsNewImage && values.elevWhatsNewImage?.length >= 1) ||
                            (existingWallFiles[0] !== null && existingWallFiles.length >= 1)
                          }
                          imageUrl={`${process.env.REACT_APP_API_URL}/api/v1/whatsnewsections/image`}
                          whatsnew={true}
                        />
                      )}

                      {values.supportingMedia === 'Image' &&
                        isEdit &&
                        existingWallFiles.length !== 0 &&
                        existingWallFiles[0] !== null && (
                          <Grid container direction='column' style={{ width: '100%' }}>
                            {existingWallFiles?.map((media) => (
                              <ExistingMedia
                                whatsnew={true}
                                media={media || []}
                                elevWhatsNewImage={existingWallFiles}
                                imageDelete={imageDelete}
                                handleRemoveFile={(fileId) => {
                                  handleChange({
                                    target: {
                                      value:
                                        values.elevWhatsNewImage &&
                                        Array.isArray(values.elevWhatsNewImage)
                                          ? values.elevWhatsNewImage?.filter(
                                              ({ id }) => id !== fileId
                                            )
                                          : [],
                                      name: 'elevWhatsNewImage',
                                    },
                                  });
                                  setExistingWallFiles([]);
                                }}
                              />
                            ))}
                          </Grid>
                        )}
                    </Grid>

                    {values.supportingMedia === 'Video' && (
                      <Grid item>
                        <Controls.Input
                          label='Paste video link'
                          name='mediaUrl'
                          style={{
                            width: '48%',
                            marginTop: '10px',
                            marginBottom: '10px',
                          }}
                          value={values.mediaUrl}
                          error={touched.mediaUrl && Boolean(errors.mediaUrl)}
                          onChange={handleChange}
                        />
                      </Grid>
                    )}
                  </Form>
                </div>

                <ButtonsContainer>
                  <Button
                    color='primary'
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: 'Are you sure to discard this post?',
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDiscard();
                        },
                      });
                    }}
                  >
                    {`Cancel`}
                  </Button>
                  <Controls.Button
                    text={`${isEdit ? 'Update' : 'Submit'}`}
                    variant='contained'
                    color='primary'
                    style={{ borderRadius: '26px' }}
                    type='submit'
                    onClick={submitForm}
                  />
                </ButtonsContainer>
              </>
            );
          }}
        </Formik>
      </CreatePostContainer>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
};

export default CreateWhatsNew;
