import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { getStageDropDown, getStagesCount } from '../../Actions/AdminAction';
import TextFieldComponent from '../Controls/TextField';
import { withStyles } from "@material-ui/styles";
import {
  listUsersProdBasedPath, productActivationPath, productPunchingPath, productStudentPath, productuserPunchingPath
} from "../RoutePaths";

// import BlueCell from "../../Asset/StyledComponent"
// import { Autocomplete } from "@material-ui/lab";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DropDown from '../Controls/DropDown';
import { ExpandMore } from "@mui/icons-material";
// import CustomizedMenus from './AutoComplete';
import { Button } from '@mui/material';
import { useStyles } from "./Styles";


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));
const BlueCell = withStyles({
  root: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "24px",
    color: "#0A66C2",
    textAlign: "left",
    borderBottom: "none",
  },
})(TableCell);
const data =
{
  searchName: "",
  stage: ""
};

export default function ManageTable(props) {


  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const classes = useStyles();
  const { productId } = params;
  const { getCount, getDropdown } = useSelector((state) => state.AdminReducer);
  useEffect(() => {
    dispatch(getStageDropDown(productId));
  }, [])
  const count = getCount?.data?.data?.stageNameCounts;
  console.log(count, "*****data******");
  const dropDown = getDropdown?.data?.data;
  console.log(dropDown, "%%%%drop down data &&&&&&& ")
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (stageName) => {
    console.log(stageName, "^^^^^^^selection of data")
    dispatch(getStagesCount(productId, stageName));

    setAnchorEl(null);
  };
  const tabData = [
    {
      search: ""
    }
  ]
  const handleLink = (stageName, stepName) => {
    console.log(stageName, stepName, "========stageName==========");
    const data =
    {
      searchName: "",
      stage: stageName
    };
    if (stageName !== "Pre-Onboarding") {
      history.push({
        pathname:
          listUsersProdBasedPath + "/" + productId,
        state: data,
        stepName: stepName
      });

    }
    else if (stageName === "Pre-Onboarding" && stepName === "Total Clients Not-Activated") {
      history.push(

        {
          pathname: productActivationPath + "/" + productId,
          state: data,
          stepName: stepName
        }
      );
    }
    else if (stageName === "Pre-Onboarding" && stepName === "Total Clients Punched") {
      history.push(
        {
          pathname: productStudentPath,
          state: data,
          stepName: stepName,
          productId: productId

        }
      );
    }


  }
  // const handleScroll = () => {
  //     window.scrollBy(0, 100);
  // }
  useEffect(() => {
    dispatch(getStagesCount(productId, null));

  }, [])

  const disableLink = count?.message;
  console.log(disableLink, "^^^^");
  const renderData = () => {
    return (
      <>
        <div style={{
          position: "relative",
          height: "25rem",
          width: "90%",
          display: "flex",
          marginLeft: "2rem",
          marginTop: "2rem",
          overflowY: "scroll",
          textAlign: "justify",
          fontSize: "0.8rem",
          flexWrap: "wrap",
          // scrollbarColor: "red yellow",
          border: "1px solid #C8C8C8",

        }}>
          <TableContainer

            classes={{ root: classes.customTableContainer }}>
            <Table stickyHeader >
              <TableHead >
                <TableRow
                // style={{ border: "1px solid green" }}
                >
                  <TableCell  >Client's Stage</TableCell>
                  <TableCell >Count</TableCell>
                  {/* <TableCell><ArrowDropDown /></TableCell> */}
                </TableRow>
              </TableHead>

              <TableBody>

                {count?.map((item) => {
                  return (
                    <TableRow>
                      <TableCell style={{ fontSize: "13px" }}>
                        {item.stepName}
                      </TableCell>
                      <TableCell>
                        <BlueCell
                          onClick={() =>
                            handleLink(item.stageName, item.stepName)
                          }
                          style={{
                            pointerEvents: item.count === 0 ? "none" : "unset",
                          }}
                        >
                          {item.count}
                        </BlueCell>
                        {/* <a onClick={handleLink(item.stageName)}>{item.count}</a> */}

                      </TableCell>
                      {/* <TableCell></TableCell> */}
                    </TableRow>
                  );
                })}

              </TableBody>
            </Table>
          </TableContainer>
        </div>

      </>

    );
  }

  return (
    <>
      <Grid container>
        <Grid item md={4} lg={5}>
          <div style={{ marginLeft: "2rem", marginTop: "2rem" }}>
            <Button
              id="demo-customized-button"
              style={{ textTransform: "none", fontSize: "13px", position: "relative" }}
              aria-controls={open ? 'demo-customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}

              startIcon={<KeyboardArrowDownIcon />}
            >
              {"Stage Dashboard"}
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {dropDown?.map((item) => {
                return (
                  <MenuItem style={{ fontSize: "13px" }} onClick={() => handleClose(item.stageName)} disableRipple>

                    {item.stageName}
                  </MenuItem>
                );
              })}


            </StyledMenu>

          </div>


        </Grid>
        <Grid item md={8} lg={7}>
          {renderData(getCount)}
        </Grid>



      </Grid>



    </>

  )

}
