import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import ReactApexChart from "react-apexcharts";



const useStyle = makeStyles((theme) => ({
  paperContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "15px",
    paddingRight: "15px",
    alignItems: "center",
    height: "114px",
    color: "#354354",
    borderRadius: "4px",
    boxShadow: "0px 4px 16px rgba(88, 66, 246, 0.15)",
  },
}));

const ChartCard = ({ chartData, type, chartOption, number, title }) => {
  const classes = useStyle();

  return (
    <Grid item xs={6}>
      <Paper elevation={3} className={classes.paperContainer}>
        <Box>
          <Typography variant="h5" fontWeight={"bold"} fontSize={"1.2rem"}>
            {number}
          </Typography>
          <Typography
            width={"90%"}
            variant="h6"
            fontWeight={"500"}
            fontSize={"1rem"}
          >
            {title}
          </Typography>
        </Box>
        <Box>
          <ReactApexChart
            type={type}
            series={[{ data: chartData }]}
            options={chartOption}
            width={80}
            height={56}
          />
        </Box>
      </Paper>
    </Grid>
  );
};

export default ChartCard;
