import React, { useState, useEffect } from "react";
import { Grid, Button, Typography, Checkbox } from "@material-ui/core";
import { useStyles } from "../RecommendedTool/Styles";
import { useParams } from "react-router-dom";
import BottomContainer from "../PgaReport/BottomContainer";
import { useDispatch, useSelector } from "react-redux";
// import { PageWrapper } from "../../PgaReport/Components/StyledComponents";
import * as FileSaver from "file-saver";

import { PageWrapper } from "../PgaReport/Components/StyledComponents";
import {
  getRecommendersFiles,
  putRecommenderCall,
  getDownloadRecommenderFiles,
} from "../../Actions/StrategySession";
import "../RecommendedTool/Recommenders.css";
import { textToDownloadFile } from "../Utils/Helpers";
import MySnackBar from "../MySnackBar";

export default function RecommendedToolIndex(props) {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const { studentId, productId, recommenderId } = params;
  const { recommenderModel, downloadRecommender, putRecommendersDetails } = useSelector(
    (state) => state.RecommenderToolReducer
  );

  const [recommenders, setRecommender] = useState([]);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [state, setState] = useState({
    id: "",
    recommenderName: null,
    designation: null,
    relationship: null,
    mobileNumber: null,
    howLongHaveYouKnowTheRecommender: null,
    email: null,
   
    document: null,
    isSubmitted: null,
    isRecommended: null,
    snackMsg: "",
    snackVariant: "",
    snackOpen: false,
    recommender: null,
    fileName: null,
  });
  const {
    id,
    recommenderName,
    designation,
    relationship,
    mobileNumber,
    howLongHaveYouKnowTheRecommender,
    email,
   
    snackMsg,
    snackVariant,
    snackOpen,
    document,
    isSubmitted,
    isRecommended,
    recommender,
    fileName,
  } = state;

  const handleSave = () => {
    let payload = [];

    // recommenders.forEach((v) => {
    //   payload.push({ recommenderId: v.id, isRecommended: v.isRecommended });
    // });

    // console.log(checkboxValues);
    let isRecommendedTrue = recommenders.filter(
      (item) => item.isRecommended === true
    );
    const a = recommenders.map((item) =>
      payload.push({ isRecommended: item.isRecommended, id: item.id })
    );

    if (isRecommendedTrue.length <= 3) {
      dispatch(putRecommenderCall(studentId, productId, payload));
      console.log(putRecommendersDetails?.success, "^^^^^^")
      setTimeout(() => {
        setState({
          ...state,
          snackOpen: true,
          snackVariant: "success",
          snackMsg: "Recommenders shared successfully",
        });

      }, 5000);


    } else {
      console.log("error");
    }

    // console.log(isRecommendedTrue, "}}}}}}}}}}}}}}}}}}}}}}}}}}}}");
  };

  // console.log(checkboxValues);

  const handleOnChange = (event, Index, dataItem) => {
    // setChecked(event.target.checked);
    console.log(event.target.checked);

    // let tempVar = [...checkboxValues];

    // tempVar.push({
    //   isRecommended: true,
    //   recommenderId: dataItem.id,
    // });

    let temp = recommenders.map((a, ind) => {
      if (ind === Index) {
        return { ...a, isRecommended: event.target.checked };
      }
      return a;
    });
    // setCheckboxValues(tempVar);
    setRecommender(temp);
    console.log(isRecommended, "{{{{{{{");
  };

  console.log(recommenders, "**********************false true");
  useEffect(() => {
    dispatch(getRecommendersFiles(studentId, productId));
  }, []);
  useEffect(() => {
    setRecommender(recommenderModel?.data?.data);
  }, [recommenderModel]);

  // Download GET Call//

  const handleOnClick = (obj) => {
    // setRecommender(downloadRecommender?.data?.id);
    console.log(obj);
    dispatch(
      getDownloadRecommenderFiles(studentId, productId, obj.id, obj.fileName)
    );

    // Download Files //

    // // var FileSaver = require('file-saver');
    // var blob = new Blob(["Hello, world!"], {
    //   type: "text/plain;charset=utf-8",
    // });
    if (downloadRecommender) {
      if (downloadRecommender.success) {
        textToDownloadFile(
          downloadRecommender.data,
          downloadRecommender.fileName,
          downloadRecommender.fileName.split(".").pop()
        );
      } else {
        // setState({
        //   ...state,
        //   snackOpen: true,
        //   snackVariant: "error",
        //   snackMsg: downloadRecommender.message,
        // });
      }
    }
    // FileSaver.saveAs(blob, `${obj.document}.pdf`);
  };
  const handleSnackClose = () => {
    setState({
      ...state,
      snackOpen: false,
      snackMsg: "",
      snackVariant: "",
    });
  };
  useEffect(() => {

  }, [downloadRecommender]);
  return (
    <PageWrapper>
      <div>
        <div style={{ padding: "30px" }} className={classes.containerStyle}>
          <Grid container spacing={1}>
            <Grid item md={12} xs={12} sm={12} xl={12} lg={12}>
              <div className={classes.headers}>Recommenders Information </div>
            </Grid>
          </Grid>
          {recommenders?.map((item, ind) => {
            return (
              <div className={classes.tableScroll}>
                <Grid item md={12} xs={12} sm={12} xl={12} lg={12}>
                  <div className={classes.subHeaders}>{item.recommender} - {item.recommenderName}
                   <Checkbox
                      style={{ padding: "25px" }}
                      color="primary"
                      checked={item.isRecommended || checked}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      onChange={(e) => handleOnChange(e, ind, item)}
                    />
                  </div>
                </Grid>
                <br />

                <div class="flex-container">
                  <div id="rowAlign">
                    <div className={classes.tableHeading}>Name</div>
                    <div class="subContent">{item.recommenderName}</div>
                  </div>
                  <div id="rowAlign">
                    <div className={classes.tableHeading}>Relationship</div>
                    <div class="subContent">{item.relationship}</div>
                  </div>
                  <div class="mainHeader" id="rowAlign_1">
                    <div
                      className={classes.tableHeading}
                    // style={{ marginTop: "10px" }}
                    >
                      How long have you known the recommender
                    </div>
                    <div class="subContent" id="subId">
                      {item.howLongHaveYouKnowTheRecommender}
                    </div>
                  </div>
                  {/* <div id="rowAlign">
                    <div className={classes.tableHeading}>Title</div>
                    <div class="subContent">{item.title}</div>
                  </div> */}
                  <div id="rowAlign">
                    <div className={classes.tableHeading}>Designation</div>
                    <div class="subContent">{item.designation}</div>
                  </div>
                  <div id="rowAlign">
                    <div className={classes.tableHeading}> Mobile</div>
                    <div class="subContent">{item.mobileNumber}</div>
                  </div>
                  <div>
                    <div className={classes.tableHeading}>Email </div>
                    <div class="subContent">{item.email}</div>
                  </div>
                  <div id="rowAlign_1" className="boxAlign">
                    <Button
                      variant="outlined"
                      color="primary"
                      href="#outlined-buttons"
                      // id="buttonAlign"
                      onClick={() => handleOnClick(item)}
                      style={{
                        padding: "20px",
                        width: "240px",
                        boxSizing: "content-box",
                        position: "relative",
                        bottom: "15px",
                      }}
                    >
                      {" "}
                      Download Recommender <br />
                      Request Form -{item.document}
                    </Button>
                  </div>
                  <div className="checkbox" id="rowAlign">
                    {" "}
                    {/* <Checkbox
                      style={{ padding: "25px" }}
                      color="primary"
                      checked={item.isRecommended || checked}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      onChange={(e) => handleOnChange(e, ind, item)}
                    /> */}
                  </div>
                </div>
                <hr className={classes.lineColor} />
              </div>
            );
          })}
        </div>
        <div className="bottom">
          <BottomContainer onClick={handleSave} />
        </div>
        <MySnackBar
          onClose={handleSnackClose}
          snackOpen={snackOpen}
          snackVariant={snackVariant}
          snackMsg={snackMsg}
        />

      </div>
    </PageWrapper>
  );
}
