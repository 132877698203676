import { Box, IconButton } from "@material-ui/core";
import React from "react";
import { ReactComponent as CommentIcon } from "../../../Asset/icons/comment.svg";
import {
    customTheme,
    StyledButton,
    useStyles,
} from "../../../Asset/StyledComponents/Styles";
import { StyledCustomStaticTable } from "./Styles";
import { ReactComponent as Document } from "../../../Asset/icons/empty-document.svg";
// import { Typo } from "../../controls/Styles";
import { Typo } from "../../Utils/controls/Styles";
import moment from "moment";

function DownloadTable({
    headers,
    body,
    data,
    cardName,
    handleComment,
    handleDownload,
    handleDelete,
}) {
    const classes = useStyles();
    console.log(body, "bbhiber")
    return (
        <>
            <div style={{ padding: "2rem" }}>
                <Typo style={{ fontSize: "1rem" }}>{cardName}</Typo>
            </div>
            <StyledCustomStaticTable>
                <thead>
                    <tr>
                        {headers &&
                            headers.length !== 0 &&
                            headers.map((item, index) => <th>{item}</th>)}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{data.fileName}</td>
                        <td>
                            {data.uploadedDate
                                ? moment(new Date(data.uploadedDate)).format("DD MMMM YYYY")
                                : "NA"}
                        </td>
                        <td>
                            <StyledButton
                                height={"25px"}
                                variant={"outlined"}
                                style={
                                    customTheme["palette"][
                                    "outlined"
                                    ]
                                }
                                onClick={handleDownload}
                            // disabled={Boolean(!path)}
                            >
                                {"Download"}
                            </StyledButton>
                        </td>
                    </tr>
                </tbody>
            </StyledCustomStaticTable>
        </>
    );
}

export { DownloadTable };
