import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font, Link } from '@react-pdf/renderer';
import { MONTHS } from './Variables';
import MbaReportTable from './MbaReportTable';
import NewMbaReportGraph from '../../../Asset/Images/NewMbaReportGraph.png';
import { urlPatternValidation } from '../../Validation';

const MyDocument = ({
  preferredProgram = '',
  title = '',
  inTake = '',
  description = '',
  isGreenCardVisible = false,
  isSecondaryPassageVisible = false,
  isTableVisible = false,
  descriptionOne = '',
  descriptionTwo = '',
  tableHeading = '',
  row = [],
  subDescription = '',
  list = [],
  tableHelper = [],
  rowDataLength = 4,
  additionalPoint = [],
  allRoundActivities,
  strength,
  spiderGraphUrl,
  isQuarter,
}) => (
  <View>
    {isSecondaryPassageVisible ? (
      <View style={{ padding: '10px 0px' }}>
        <Text style={{ ...styles.heading, padding: '10px 0px' }}>
          {'Application Timeline and Program'}
        </Text>
        <Text
          style={{
            ...styles.small,
            // ...styles.p_10,
          }}
        >
          <Text style={{ fontFamily: 'Helvetica-Bold' }}>{inTake + `:`}</Text> {'     '}
          {description}
        </Text>
      </View>
    ) : null}

    {/* Table Component */}
    <View style={{ margin: '5px 0px' }}>
      {isTableVisible && (
        <>
          <Text style={{ ...styles.heading, ...styles.p_10, marginBottom: '10px' }}>{title}</Text>
          <MbaReportTable
            tableHeading={title}
            rowDataLength={rowDataLength}
            data={row}
            subDescription={subDescription}
            tableHelper={tableHelper}
          />
        </>
      )}
    </View>

    {/* List Component */}
    {isEmpty(strength) && isQuarter === false && (
      <View>
        <View style={{ ...styles.p_10, ...styles.heading }}>
          <Text style={styles.colorBoxTitle}>{title}</Text>
        </View>
        <View>
          <Text
            style={{
              ...styles.remark,
              padding: descriptionOne && '5px',
              textAlign: descriptionOne && 'center',
              backgroundColor: descriptionOne && '#CFE1F3',
              marginTop: descriptionOne && '5px',
              marginBottom: '15px',
            }}
          >
            {descriptionOne}
          </Text>
        </View>
        {strength.map((item, idx) => (
          <View style={styles.list_wrapper} key={idx.toString()}>
            <View style={styles.dot_icon_ash}></View>
            <View
              wrap={true}
              style={{
                fontSize: 10,
                paddingLeft: 5,
                lineHeight: '2.3px',
                width: '100%',
              }}
            >
              <Text>{item.name}</Text>
            </View>
          </View>
        ))}
      </View>
    )}
  </View>
);

function isEmpty(data) {
  return data && data.length !== 0;
}

const renderDateTime = (date) => {
  const year = new Date(date).getFullYear();
  const month = MONTHS[new Date(date).getMonth()];
  const reportDate = new Date(date).getDate();
  const finalDate = reportDate + ' ' + month + ' ' + year;
  return finalDate;
};

function MbaReport({ client, content = [], assessment = [], mailId = [], blobData }) {
  const email = mailId?.mailId;
  let ReportDate =
    content.find((item) => item.content.dateTime) &&
    content.find((item) => item.content.dateTime).content.dateTime;

  const spiderBlobData = `data:image/*;base64,${blobData}`;

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.main_container}>
          <View
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '15px',
              marginBottom: '6px',
            }}
          >
            <Text
              style={{
                fontSize: 14,
                textAlign: 'center',
                color: '#528DD2',
                fontFamily: 'Helvetica-Bold',
              }}
            >
              CAREERLABS TECHNOLOGIES PVT. LTD.
            </Text>
          </View>
          <hr
            style={{
              border: '0.75px solid #528DD2',
              marginTop: '10px',
              marginBottom: '5px',
            }}
          />
          <View style={styles.analysis_title_div}>
            <Text style={styles.minutesTitle}>MINUTES OF THE PROFILE GAP ANALYSIS SESSION</Text>
          </View>

          <View style={styles.clientdateView}>
            <Text
              wrap={true}
              style={{ fontSize: 11, fontFamily: 'Helvetica-Bold', color: '#528DD2' }}
            >
              {`NAME : ` + client}
            </Text>
            <Text style={styles.date}>
              {ReportDate ? `DATE : ` + renderDateTime(ReportDate) : ''}
            </Text>
          </View>

          <View>
            <Text wrap={true} style={styles.subTitle}>
              This report is a brief summary of the Profile gap analysis that was done based on your
              current CV and questionnaire.
            </Text>
          </View>

          {/* <View>
            <Text
              wrap={true}
              style={{ fontSize: '10px', paddingBottom: '5px', lineHeight: '1.5px' }}
            >
              The following is an assessment of your profile based on the various admissions
              criteria that are typically considered by the B-schools
            </Text>
          </View> */}

          {assessment &&
            assessment.map((item, idx) => (
              <View style={{ margin: '5px 0px' }} key={idx.toString()}>
                <View style={styles.list_container}>
                  <Text wrap={true} style={styles.assessmentDesc}>
                    <Text style={styles.highlight_text}>{item.title}</Text>
                    {' - '}
                    {item.description}
                  </Text>
                </View>
              </View>
            ))}

          {/* Spider Graph */}
          {isEmpty(spiderBlobData) && (
            <View style={styles.spider_graph_card} wrap={false}>
              <View style={styles.spider_graph_image_view}>
                <Image src={spiderBlobData} style={styles.spider_graph_image} />
              </View>
            </View>
          )}

          {content.map((item, idx) => {
            const {
              content,
              table,
              tableSubDescription,
              subDescription,
              descriptionOne,
              descriptionTwo,
              csfs,
              additionalPoint,
              spiderGraphUrl,
              mailId,
              isQuarter,
            } = item;

            return (
              <>
                <MyDocument
                  preferredProgram={content.preferredProgram}
                  title={item.title}
                  inTake={content.inTake}
                  description={content.description}
                  isSecondaryPassageVisible={
                    isEmpty(item.title) &&
                    isEmpty(content.preferredProgram) &&
                    isEmpty(content.inTake)
                  }
                  allRoundActivities={additionalPoint}
                  strength={additionalPoint}
                  isQuarter={isQuarter}
                  descriptionOne={descriptionOne}
                  descriptionTwo={descriptionTwo}
                  isTableVisible={table.rows && table.rows.length !== 0}
                  tableHeading={''}
                  row={table.rows}
                  rowDataLength={table.rows && table.rows.length > 0 ? table.rows[0].length : 4}
                  subDescription={subDescription}
                  tableHelper={tableSubDescription}
                  list={csfs}
                  additionalPoint={additionalPoint}
                  isEnd={content.length - 1 === idx}
                  spiderGraphUrl={spiderGraphUrl}
                />
              </>
            );
          })}
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <View style={{ ...styles.p_10, ...styles.heading }}>
              <Text style={{ ...styles.colorBoxTitle, margin: '10px 0px' }}>
                GMAT Scores across the globe
              </Text>
            </View>
            <View>
              <Image src={NewMbaReportGraph} />
            </View>
          </View>

          {/* footer description */}
          <View style={{ marginTop: '20px' }}>
            <View style={{ marginBottom: '5px' }}>
              <Text style={styles.footerText}>
                {'Source:'}
                {'  '}
                <Text style={styles.footer_list_item}>
                  {'As found on respective B-School Websites'}
                </Text>
              </Text>
            </View>
            <View>
              <Text style={styles.footerText}>
                {'Disclaimer:'} {'  '}
                <Text style={styles.footer_list_item}>
                  {
                    'The data has been taken from the Class Profiles of respective B-Schools as on Mar-2019. Refer to the school websites for the latest information.'
                  }
                </Text>
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingBottom: 65,
    paddingHorizontal: 10,
    padding: 20,
  },
  main_container: {
    padding: 20,
    borderLeft: '1.25px solid black',
    borderRight: '1.25px solid black',
    borderTop: '1.25px solid black',
    borderBottom: '1.25px solid black',
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    color: '#488DFF',
    fontWeight: 'medium',
    padding: '10px 0px',
  },
  heading: {
    color: '#488DFF',
    fontSize: 12,
    fontWeight: 'semibold',
  },
  analysis_title_div: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '5%',
  },
  colorBox: {
    width: '100%',
    backgroundColor: '#E6F3DC',
    minHeight: 100,
    borderRadius: 10,
    padding: 10,
  },
  small: {
    fontSize: 10,
  },
  p_10: {
    padding: '5px 0px',
  },
  p_15: {
    padding: '15px 0px',
  },
  title_container: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    flexWrap: 'wrap',
  },
  degreeName: {
    fontSize: 10,
    paddingLeft: 5,
    fontWeight: 'bold',
  },
  colorBoxTitle: {
    fontSize: 12,
  },
  list_wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingBottom: '3px',
  },
  list_container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    margin: '5px 0px',
  },
  list_item: {
    fontSize: 10,
    paddingLeft: 5,
  },
  footer_list_item: {
    fontSize: 9,
    fontFamily: 'Helvetica-Oblique',
    paddingLeft: '0px',
    lineHeight: '1.5px',
  },
  subTitle: {
    fontSize: 9,
    marginBottom: '20px',
    fontFamily: 'Helvetica-Oblique',
    padding: '5px',
    textAlign: 'center',
    backgroundColor: '#CFE1F3',
  },
  footerText: {
    fontSize: 10,
    fontFamily: 'Helvetica-Bold',
    marginBottom: '5px',
  },
  remark: {
    fontSize: 9,
    fontFamily: 'Helvetica-Oblique',
  },
  analysis_date_div: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    marginLeft: '70%',
    marginTop: '10px',
  },
  date: {
    fontSize: '11px',
    fontWeight: '600',
    fontFamily: 'Helvetica-Bold',
    color: '#528DD2',
  },
  footer: { textAlign: 'center', padding: 10 },
  dot_icon_ash: {
    backgroundColor: '#000000',
    marginRight: '5px',
    marginTop: '2px',
    height: '5px',
    width: '5px',
    borderRadius: '7px',
  },
  highlight_text: {
    color: '#2bb2ea',
    letterSpacing: '0px',
    fontWeight: '500',
    fontSize: '10px',
    opacity: '1',
    lineHeight: '1.5px',
  },
  assessmentDesc: {
    fontSize: 10,
    width: '100%',
    lineHeight: '1.25px',
  },
  spider_graph_image_view: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '80%',
  },
  spider_graph_image: {
    height: 280,
    width: 280,
    alignItems: 'center',
  },
  spider_graph_card: {
    border: '2px solid #ac9eca',
    height: '350px',
    width: '515px',
    borderRadius: '6px',
    marginTop: '15px',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    lineHeight: '3px',
    padding: '8px',
  },

  clientdateView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '25px',
  },
  minutesTitle: {
    fontSize: 12,
    textAlign: 'center',
    color: '#528DD2',
    fontFamily: 'Helvetica-Bold',
    padding: '0px',
    marginTop: '10px',
  },
});

export default MbaReport;
