import React, { useState, useEffect } from 'react';
import { ButtonsContainer, CreatePostContainer } from '../Assets/Styles/CreatePostStyles';
import BackHandler from '../Components/BackHandler';
import Preview from '../Components/Preview';
import { Formik, Form } from 'formik';
import Controls from '../../Utils/controls/Controls';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Tooltip } from '@material-ui/core';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { MultipleFileUploadField } from '../Components/Upload/MultipleFileUploadField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  createWallSuccessStory,
  deleteSuccessStoryImages,
  getWallCategories,
  showWallStory,
  updateWallSuccessStory,
} from '../../../Actions/WallActions';
import Notification from '../../Utils/Notification';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { wallPath } from '../../RoutePaths';
import ConfirmDialog from '../../Utils/ConfirmDialog';
import { ExistingMedia } from '../Components/Upload/ExistingMedia';

const useStyles = makeStyles({
  root: {
    '& .MuiSelect-root': {
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '4px',
      padding: '1rem',
    },
  },
  captionStyle: {
    width: '80%',
    marginTop: 20,
    marginBottom: 15,
  },
  title: {
    fontSize: '16px',
    color: '#052A4E',
    marginTop: 40,
    fontWeight: 400,
    lineHeight: '19.5px',
  },
  divider: { backgroundColor: '#D8D8D8', marginTop: 40 },
  spacer: {
    width: '80%',
    marginTop: '10px',
  },
  hostImage: {
    borderRadius: '50%',
  },
});

const CreateSuccessStory = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  const isEdit = params?.id;
  const { categories } = useSelector((state) => state.getWallCategoriesReducer);
  const { story } = useSelector((state) => state.wallSuccessStoryReducer);

  const [existingWallFiles, setExistingWallFiles] = useState([]);
  const [state, setState] = useState({
    id: params?.id || null,
    wallCategories: [],
    eventTitle: '',
    fromText: '',
    toText: '',
    jobRole: '',
    caption: '',
    supportingMedia: 'image',
    activeStatus: 'Live',
    totalLikes: 0,
    wallFiles: [],
    fromWallFile: [],
    toWallFile: [],
  });
  const [isClear, setIsClear] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  useEffect(() => {
    dispatch(getWallCategories('Live'));
    if (isEdit) {
      dispatch(showWallStory(params?.id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (story) {
      if (story.success) {
        setState({
          ...state,
          ...story?.data,
          fromWallFile: story?.data?.wallFiles,
          toWallFile: story?.data?.wallFiles,
        });
        setExistingWallFiles(story?.data?.wallFiles || []);
      }
    }
  }, [story]);

  // Delete all the three images
  const handleDeleteAllImages = (values, setValues) => {
    const { wallFiles, fromText, toText, wallCategories, eventTitle, jobRole, caption } = values;
    dispatch(
      deleteSuccessStoryImages(wallFiles[0]?.id, (res) => {
        if (res) {
          setNotify({
            isOpen: true,
            message: 'All three images deleted successfully!',
            type: 'success',
          });
          setValues({
            ...values,
            wallCategories: wallCategories,
            eventTitle: eventTitle,
            jobRole: jobRole,
            caption: caption,
            fromText: fromText,
            toText: toText,
            wallFiles: [],
            fromWallFile: [],
            toWallFile: [],
          });
          setState({
            ...state,
            wallCategories: wallCategories,
            eventTitle: eventTitle,
            jobRole: jobRole,
            caption: caption,
            fromText: fromText,
            toText: toText,
            wallFiles: [],
            fromWallFile: [],
            toWallFile: [],
          });
          setIsClear(true);
          setExistingWallFiles([]);
        }
      })
    );
  };

  const validate = (values) => {
    if (values.supportingMedia === 'image' && values.wallFiles.length === 0) {
      setNotify({
        isOpen: true,
        message: 'Please upload a profile image',
        type: 'error',
      });
      return false;
    }
    if (values.supportingMedia === 'image' && values?.fromWallFile?.length === 0) {
      setNotify({
        isOpen: true,
        message: 'Please upload a FROM image',
        type: 'error',
      });
      return false;
    }
    if (values.supportingMedia === 'image' && values?.toWallFile?.length === 0) {
      setNotify({
        isOpen: true,
        message: 'Please upload a TO image',
        type: 'error',
      });
      return false;
    }
    return true;
  };

  const validationSchema = yup.object({
    wallCategories: yup
      .array()
      .min(1)
      .required('category is required'),
    eventTitle: yup.string().required('student name is required'),
    jobRole: yup.string().required('company name is required'),
    caption: yup.string().required('caption is required'),
    fromText: yup.string().required('From Image Title is required'),
    toText: yup.string().required('To Image Title is required'),
  });

  const createStory = (post) => {
    let payload = {
      id: post.id,
      activeStatus: post.activeStatus,
      caption: post.caption,
      eventTitle: post.eventTitle,
      jobRole: post.jobRole,
      supportingMedia: post.supportingMedia,
      totalLikes: post.totalLikes,
      wallCategories: post.wallCategories,
      wallFiles: post.wallFiles,
      fromText: post.fromText,
      toText: post.toText,
    };

    if (isEdit) {
      dispatch(updateWallSuccessStory(payload));
    } else {
      dispatch(createWallSuccessStory(payload));
    }
    setNotify({
      isOpen: true,
      message: `${isEdit ? 'Updated' : 'Created'} Successfully`,
      type: 'success',
    });
    setTimeout(() => {
      history.push({
        pathname: wallPath,
        tab: 3,
      });
    }, 1500);
  };

  const onDiscard = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    setTimeout(() => {
      history.push({
        pathname: wallPath,
        tab: 3,
      });
    }, 1200);
    setNotify({
      isOpen: true,
      message: 'Discarded',
      type: 'warning',
    });
  };

  return (
    <>
      <BackHandler title={`${isEdit ? 'Edit' : 'Create New'} Story`} tab={3} />
      <CreatePostContainer>
        <Formik
          initialValues={state}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            if (validate(values)) {
              createStory(values);
              resetForm();
            }
          }}
          enableReinitialize
        >
          {({
            handleSubmit,
            errors,
            handleChange,
            values,
            setValues,
            touched,
            setFieldValue,
            submitForm,
          }) => {
            return (
              <>
                <div className='CreatePost'>
                  <Form onSubmit={handleSubmit} autoComplete='off'>
                    {/* Category Dropdown */}
                    <FormControl className={classes.root} style={{ width: '80%' }}>
                      <Autocomplete
                        multiple
                        id='wallCategories'
                        name='wallCategories'
                        getOptionLabel={(option) => option?.name}
                        options={categories ?? []}
                        onChange={(e, value) => {
                          setFieldValue('wallCategories', value !== null ? value : categories);
                        }}
                        fullWidth
                        value={values.wallCategories}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Select Category'
                            name='wallCategories'
                            variant='outlined'
                            error={
                              touched.wallCategories && Boolean(values.wallCategories.length === 0)
                            }
                          />
                        )}
                        style={{
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      />
                    </FormControl>

                    <Grid item>
                      <Controls.Input
                        label='Enter Student Name'
                        name='eventTitle'
                        style={{ width: '80%', marginTop: '18px' }}
                        value={values.eventTitle}
                        error={touched.eventTitle && Boolean(errors.eventTitle)}
                        onChange={handleChange}
                      />
                      <Controls.Input
                        label='Enter Company Name'
                        name='jobRole'
                        style={{
                          width: '80%',
                          marginTop: '18px',
                        }}
                        error={touched.jobRole && Boolean(errors.jobRole)}
                        value={values.jobRole}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item>
                      <Controls.Input
                        label='Enter Caption'
                        value={values.caption}
                        name='caption'
                        onChange={handleChange}
                        error={touched.caption && Boolean(errors.caption)}
                        multiline
                        className={classes.captionStyle}
                        rows={3}
                        fullWidth
                      />
                    </Grid>
                    <Grid item>
                      <Box
                        style={{
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          display: 'flex',
                          width: '80%',
                        }}
                      >
                        <span style={{ color: '#009be5', fontWeight: 600 }}>
                          Click here to delete all the below images:
                        </span>
                        <Controls.ActionButton
                          disabled={values.wallFiles.length < 1}
                          onClick={() => {
                            handleDeleteAllImages(values, setValues);
                          }}
                        >
                          <Tooltip
                            title='Click to delete all below three images...'
                            placement='Bottom'
                          >
                            <DeleteIcon
                              fontSize='small'
                              color={values.wallFiles.length < 1 ? 'gray' : 'secondary'}
                            />
                          </Tooltip>
                        </Controls.ActionButton>
                      </Box>
                    </Grid>
                    <Grid container direction='column' style={{ width: '80%' }}>
                      <MultipleFileUploadField
                        name='wallFiles'
                        fileType='image'
                        isSingle={true}
                        removeDeleteIcon={true}
                        disable={values.wallFiles.length >= 1}
                        isClear={isClear}
                        setIsClear={setIsClear(false)}
                      />
                    </Grid>
                    {isEdit && existingWallFiles.length !== 0 && (
                      <Grid container direction='column' style={{ width: '80%' }}>
                        {existingWallFiles?.map((media) => (
                          <ExistingMedia
                            removeDeleteIcon={true}
                            media={{ ...media, type: 'Profile Image' }}
                            wallFiles={existingWallFiles}
                            handleRemoveFile={(fileId) => {
                              handleChange({
                                target: {
                                  value: values.wallFiles.filter(({ id }) => id !== fileId),
                                  name: 'wallFiles',
                                },
                              });
                              setExistingWallFiles(
                                existingWallFiles.filter(({ id }) => id !== fileId)
                              );
                            }}
                          />
                        ))}
                      </Grid>
                    )}
                    <Grid item>
                      <Controls.Input
                        label='From Which Position'
                        name='fromText'
                        style={{ width: '80%', margin: '18px 0' }}
                        value={values.fromText || ''}
                        error={touched.fromText && Boolean(errors.fromText)}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid container direction='column' style={{ width: '80%' }}>
                      <MultipleFileUploadField
                        name='fromWallFile'
                        fileType='image'
                        isSingle={true}
                        removeDeleteIcon={true}
                        successStory={`fromImageUrl`}
                        imageUrl={`${process.env.REACT_APP_API_URL}/api/v1/wallfiles/${values.wallFiles[0]?.id}?type=fromImage`}
                        disable={values?.fromWallFile?.length >= 1 || values?.wallFiles?.length < 1}
                        isClear={isClear}
                        setIsClear={setIsClear(false)}
                      />
                    </Grid>
                    {isEdit && existingWallFiles.length !== 0 && (
                      <Grid container direction='column' style={{ width: '80%' }}>
                        {existingWallFiles?.map((media) => (
                          <ExistingMedia
                            removeDeleteIcon={true}
                            media={{
                              id: media.id,
                              type: 'From Image',
                              url: media.fromImageUrl,
                            }}
                            wallFiles={existingWallFiles}
                            handleRemoveFile={(fileId) => {
                              handleChange({
                                target: {
                                  value: values.wallFiles.filter(({ id }) => id !== fileId),
                                  name: 'wallFiles',
                                },
                              });
                              setExistingWallFiles(
                                existingWallFiles.filter(({ id }) => id !== fileId)
                              );
                            }}
                          />
                        ))}
                      </Grid>
                    )}
                    <Grid item>
                      <Controls.Input
                        label='To Which Position'
                        name='toText'
                        style={{ width: '80%', margin: '18px 0' }}
                        value={values.toText || ''}
                        error={touched.toText && Boolean(errors.toText)}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid container direction='column' style={{ width: '80%' }}>
                      <MultipleFileUploadField
                        name='toWallFile'
                        fileType='image'
                        isSingle={true}
                        removeDeleteIcon={true}
                        successStory={`toImageUrl`}
                        imageUrl={`${process.env.REACT_APP_API_URL}/api/v1/wallfiles/${values.wallFiles[0]?.id}?type=toImage`}
                        disable={values?.toWallFile?.length >= 1 || values?.wallFiles?.length < 1}
                        isClear={isClear}
                        setIsClear={setIsClear(false)}
                      />
                    </Grid>
                    {isEdit && existingWallFiles.length !== 0 && (
                      <Grid container direction='column' style={{ width: '80%' }}>
                        {existingWallFiles?.map((media) => (
                          <ExistingMedia
                            removeDeleteIcon={true}
                            media={{
                              id: media.id,
                              type: 'To Image',
                              url: media.toImageUrl,
                            }}
                            wallFiles={existingWallFiles}
                            handleRemoveFile={(fileId) => {
                              handleChange({
                                target: {
                                  value: values.wallFiles.filter(({ id }) => id !== fileId),
                                  name: 'wallFiles',
                                },
                              });
                              setExistingWallFiles(
                                existingWallFiles.filter(({ id }) => id !== fileId)
                              );
                            }}
                          />
                        ))}
                      </Grid>
                    )}
                  </Form>

                  {/* Mobile Preview */}
                  <div style={{ flexDirection: 'column' }}>
                    <Preview state={values} />
                  </div>
                </div>

                <ButtonsContainer>
                  <Button
                    color='primary'
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: 'Are you sure to discard this story?',
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDiscard();
                        },
                      });
                    }}
                  >
                    {`Cancel`}
                  </Button>
                  <Controls.Button
                    text={`${isEdit ? 'Update' : 'Submit'}`}
                    variant='contained'
                    color='primary'
                    style={{ borderRadius: '26px' }}
                    type='submit'
                    onClick={submitForm}
                  />
                </ButtonsContainer>
              </>
            );
          }}
        </Formik>
      </CreatePostContainer>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
};

export default CreateSuccessStory;
