import { Box, Breadcrumbs, Grid, IconButton, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { ReactComponent as CommentIcon } from "../../../Asset/icons/comment.svg";
import { FlexView, useStyles } from "./Styles";
import { ReactComponent as Document } from "../../../Asset/icons/empty-document.svg";
// import { Typo } from "../../controls/Styles";
import { Typo } from "../../Utils/controls/Styles";
import moment from "moment";
import { DownloadTable } from "./DownloadTable";
import { useDispatch, useSelector } from "react-redux";
import { DocumentDownload, getDocumentCard, getDocumentList } from "../../../Actions/PostAdmitServices";
import { useLocation, useParams } from "react-router-dom";
import BackButton from '../../../Asset/Images/backbutton.svg';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { typographyStyle } from "./Styles";
import { NavigateBefore } from "@material-ui/icons";
import CvViewer from "../../ProfileGapAnalysis/CvViewer";
import { NoDataFound } from "../../../Asset/StyledComponents/Styles";



function DocumentComponentForAdmitCard({
    headerRouting
}) {
    const classes = useStyles();
    const params = useParams();
    const { studentId, productId } = params;
    const location = useLocation();
    console.log(location, "jdjjdddkkdkkkkk");
    const documentId = location?.state?.state;
    const dispatch = useDispatch();
    const { getDocuments } = useSelector((state) => state.PostAdmitServicesReducer)
    const handleDownload = (id, postAdmitSchoolId, fileName, documentName) => {
        // dispatch()
        dispatch(DocumentDownload(studentId, productId, id, postAdmitSchoolId, fileName, documentName))
    }
    console.log(getDocuments, "^^^^^^");
    useEffect(() => {
        dispatch(getDocumentCard(studentId, productId, documentId));

    }, [])
    // const handleBack = () => {
    //     headerRouting(true);
    // }
    return (
        <>
            <FlexView onClick={() => headerRouting()}>
                <NavigateBefore className={classes.breadCrumpsArrow} />
                <div className={classes.breadCrumpsText}>
                    {"Back"}
                </div>
            </FlexView>
            {
                getDocuments?.data?.length != 0 ? (
                    <Grid>
                        {
                            //  Array.isArray(sectionList) &&
                            getDocuments?.data?.map(({ file, id, postAdmitSchoolId, name, }) => {
                                console.log(file, "jnjibibhiibhr")
                                return (
                                    <DownloadTable
                                        cardName={name}
                                        headers={["Version", "Uploaded date", ""]}
                                        data={file}
                                        handleDownload={() => handleDownload(id, postAdmitSchoolId, file?.id, file?.fileName)}
                                    />
                                );
                            })}
                    </Grid>

                ) : (
                    <Grid container>
                        <Grid item md={12} align={"center"}>
                            <NoDataFound> No Data Found</NoDataFound>
                        </Grid>
                    </Grid>

                )
            }


            <Grid>

            </Grid>
            {/* <CvViewer doctype={"cv"} cvloading={lastestCVLoading} {...props} /> */}
        </>
    );
}

export { DocumentComponentForAdmitCard };
