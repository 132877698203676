import { DASHBOARD } from '../Redux/Action';

export const dashboardAnalyticsReducer = (state = { analytics: [] }, action) => {
  switch (action.type) {
    case DASHBOARD.ANALYTICS_REQUEST:
      return { loading: true, analytics: [] };
    case DASHBOARD.ANALYTICS_SUCCESS:
      return {
        loading: false,
        analytics: action.payload,
      };
    case DASHBOARD.ANALYTICS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const dashboardLiveDriveReducer = (state = { drives: [] }, action) => {
  switch (action.type) {
    case DASHBOARD.LIVEDRIVE_REQUEST:
      return { loading: true, drives: [] };
    case DASHBOARD.LIVEDRIVE_SUCCESS:
      return {
        loading: false,
        drives: action.payload,
      };
    case DASHBOARD.LIVEDRIVE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const dashboardClsaTestTakerReducer = (state = { clsaTest: [] }, action) => {
  switch (action.type) {
    case DASHBOARD.CLSA_TEST_REQUEST:
      return { loading: true, clsaTest: [] };
    case DASHBOARD.CLSA_TEST_SUCCESS:
      return {
        loading: false,
        clsaTest: action.payload,
      };
    case DASHBOARD.CLSA_TEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const dashboardUserJourneyReducer = (state = { userJourney: [] }, action) => {
  switch (action.type) {
    case DASHBOARD.USER_JOURNEY_REQUEST:
      return { loading: true, userJourney: [] };
    case DASHBOARD.USER_JOURNEY_SUCCESS:
      return {
        loading: false,
        userJourney: action.payload,
      };
    case DASHBOARD.USER_JOURNEY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const dashboardDriveStatusAlertReducer = (state = { driveStatus: [] }, action) => {
  switch (action.type) {
    case DASHBOARD.STATUS_ALERT_REQUEST:
      return { loading: true, driveStatus: [] };
    case DASHBOARD.STATUS_ALERT_SUCCESS:
      return {
        loading: false,
        driveStatus: action.payload,
      };
    case DASHBOARD.STATUS_ALERT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const dashboardUpdateDrivesReducer = (state = { updateDrives: [] }, action) => {
  switch (action.type) {
    case DASHBOARD.UPDATE_DRIVES_REQUEST:
      return { loading: true, updateDrives: [] };
    case DASHBOARD.UPDATE_DRIVES_SUCCESS:
      return {
        loading: false,
        updateDrives: action.payload,
      };
    case DASHBOARD.UPDATE_DRIVES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const dashboardClsaCountReducer = (state = { clsaCount: [] }, action) => {
  switch (action.type) {
    case DASHBOARD.CLSA_COUNT_REQUEST:
      return { loading: true, clsaCount: [] };
    case DASHBOARD.CLSA_COUNT_SUCCESS:
      return {
        loading: false,
        clsaCount: action.payload,
      };
    case DASHBOARD.CLSA_COUNT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
