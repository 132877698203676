import React, { useState, useEffect } from "react";
import { ButtonsContainer } from "../Assets/Styles/CreatePostStyles";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Grid,
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { examSpacePath } from "../../RoutePaths";
import ConfirmDialog from "../../Utils/ConfirmDialog";
import Controls from "../../Utils/controls/Controls";
import Loader from "../../Utils/controls/Loader";
import Notification from "../../Utils/Notification";
import useTable from "../../Utils/useTable";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { MultipleFileUploadField } from "../Components/Upload/MultipleFileUploadField";
import {
  deleteContent,
  listAllContents,
  saveFileById,
  setFieldValue,
} from "../../../Actions/ExamSpaceAction";

const useStyles = makeStyles((theme) => ({
  rowNo: {
    width: "30px",
  },
  fileName: { width: "100px" },
  file: {
    width: "200px",
  },
  actions: {
    width: "200px",
    textAlign: "right",
  },
  roleStyle: {
    width: "100%",
    marginTop: 10,
  },
  btnStyle: {
    borderRadius: "4px",
    width: "140px",
  },
  btnContainer: {
    justifyContent: "flex-end !important",
    gap: "10px",
    marginTop: "30px !important",
  },
}));

const headCells = [
  { id: "#", label: "#", disableSorting: true },
  { id: "fileName", label: "File Name" },
  { id: "attachment", label: "Attachment", disableSorting: true },
  { id: "actions", label: "", disableSorting: true },
];

const PreviousYearQuestion = ({ contentId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  let location = useLocation();
  const params = useParams();
  const search = location.search;
  const chapId = new URLSearchParams(search).get("chapId");

  const [contentLists, setContentLists] = useState([]);
  const [deleteClick, setDeleteClick] = useState(false);
  const [file, setFile] = useState(false);
  const [isClear, setIsClear] = useState(false);
  const [state, setState] = useState({
    id: params?.id || null,
    fileName: "",
    wallFiles: [],
  });

  const { getAllContents, removeContent, createFile, loading } = useSelector(
    (state) => state.examSpaceReducer
  );

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    contentLists,
    headCells,
    filterFn
  );

  useEffect(() => {
    if (chapId) {
      dispatch(listAllContents(chapId, contentId));
    }
  }, [dispatch, chapId]);

  useEffect(() => {
    if (getAllContents) {
      if (getAllContents.success) {
        setContentLists(getAllContents?.data);
      } else {
        setContentLists([]);
      }
    }
  }, [getAllContents]);

  useEffect(() => {
    if (createFile) {
      if (createFile.success) {
        setNotify({
          isOpen: true,
          message: createFile.message,
          type: "success",
        });
        setTimeout(() => {
          dispatch(listAllContents(chapId, contentId));
        }, 700);
      } else {
        setNotify({
          isOpen: true,
          message: createFile.message,
          type: "error",
        });
      }
      dispatch(setFieldValue("createFile", null));
    }
  }, [createFile]);

  useEffect(() => {
    if (deleteClick && removeContent) {
      if (removeContent.success) {
        setNotify({
          isOpen: true,
          message: removeContent.message,
          type: "success",
        });
        setTimeout(() => {
          dispatch(listAllContents(chapId, contentId));
        }, 700);
      } else {
        setNotify({
          isOpen: true,
          message: removeContent.message,
          type: "error",
        });
      }
      setDeleteClick(false);
    }
  }, [deleteClick, removeContent]);

  const handleDeleteClick = (item) => {
    setConfirmDialog({
      isOpen: true,
      title: "DELETE THIS?",
      subTitle: `Are you sure you want to delete the selected one?
          It will go longer will be visible on the App.`,
      onConfirm: () => {
        onDelete(item.id);
      },
    });
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    dispatch(deleteContent(id));
    setDeleteClick(true);
  };

  const onDiscard = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    setTimeout(() => {
      history.push({
        pathname: examSpacePath,
        tab: 0,
      });
    }, 1200);
    setNotify({
      isOpen: true,
      message: "Discarded",
      type: "warning",
    });
  };

  const onSubmit = () => {
    setTimeout(() => {
      history.push({
        pathname: examSpacePath,
        tab: 0,
      });
    }, 1200);
    setNotify({
      isOpen: true,
      message: "Submitted, Redirecting to Homepage...",
      type: "success",
    });
  };

  const handleDone = (fileName, wallFiles, resetForm) => {
    let obj = {
      isVideo: false,
      isPdf: true,
      url: wallFiles[0]?.data?.url,
      name: fileName,
    };

    if (fileName.trim().length === 0) {
      setNotify({
        isOpen: true,
        message: "Enter the file name",
        type: "error",
      });
      return false;
    } else if (wallFiles.length === 0) {
      setNotify({
        isOpen: true,
        message: "Please upload a PDF",
        type: "error",
      });
      return false;
    } else if (
      wallFiles &&
      wallFiles[0]?.errors[0]?.code === "file-too-large"
    ) {
      setNotify({
        isOpen: true,
        message: "File is larger than 15MB",
        type: "error",
      });
      return false;
    } else if (
      wallFiles &&
      wallFiles[0]?.errors[0]?.code === "file-invalid-type"
    ) {
      setNotify({
        isOpen: true,
        message: "File type is wrong, Upload PDF only",
        type: "error",
      });
      return false;
    } else {
      dispatch(saveFileById(obj, chapId, contentId));
      resetForm();
      setIsClear(true);
      return true;
    }
  };

  return (
    <>
      <div>
        <Formik
          initialValues={state}
          onSubmit={(values, { resetForm }) => {
            handleDone(values.fileName, values.wallFiles, resetForm);
          }}
          enableReinitialize
        >
          {({
            handleSubmit,
            errors,
            handleChange,
            values,
            touched,
            setFieldValue,
            submitForm,
          }) => {
            return (
              <>
                <Form onSubmit={handleSubmit} autoComplete='off'>
                  <Grid
                    container
                    spacing={1}
                    style={{ margin: "30px 0 10px", alignItems: "flex-start" }}
                  >
                    <Grid item md={4}>
                      <Controls.Input
                        label='File Name'
                        name='fileName'
                        value={values.fileName}
                        onChange={handleChange}
                        error={touched.fileName && Boolean(errors.fileName)}
                        style={{
                          width: "80%",
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={4}
                      style={{ textAlign: "center", paddingTop: 0 }}
                    >
                      <MultipleFileUploadField
                        name='wallFiles'
                        fileType='pdf'
                        type='application/pdf'
                        isSingle={true}
                        isClear={isClear}
                        setIsClear={setIsClear}
                        disable={
                          values.wallFiles.length >= 1 ||
                          contentLists.length >= 1
                        }
                        chapterId={chapId}
                        contentTypeId={contentId}
                      />
                    </Grid>

                    <Grid item xs>
                      <ButtonsContainer
                        style={{ marginTop: "0px", justifyContent: "flex-end" }}
                      >
                        <Controls.Button
                          text={`Done`}
                          variant='contained'
                          color='primary'
                          className={classes.btnStyle}
                          type={"submit"}
                          onClick={submitForm}
                        />
                      </ButtonsContainer>
                    </Grid>
                  </Grid>
                </Form>

                <Grid container>
                  <Grid item md={12}>
                    {!loading && getAllContents ? (
                      <TblContainer>
                        <TblHead />
                        {contentLists && (
                          <TableBody>
                            {recordsAfterPagingAndSorting().map(
                              (item, index) => {
                                return (
                                  <TableRow key={item.id}>
                                    <TableCell className={classes.rowNo}>
                                      {index + 1}
                                    </TableCell>
                                    <TableCell className={classes.fileName}>{`${
                                      item.name === null ? "NA" : item.name
                                    }`}</TableCell>
                                    <TableCell className={classes.file}>
                                      <a
                                        href={item.url}
                                        target='_blank'
                                        rel='noreferrer noopener'
                                      >
                                        {`${item.url.slice(0, 50)}...`}
                                      </a>
                                    </TableCell>
                                    <TableCell className={classes.actions}>
                                      <Controls.ActionButton
                                        onClick={() => handleDeleteClick(item)}
                                      >
                                        <DeleteIcon
                                          fontSize='small'
                                          color='secondary'
                                        />
                                      </Controls.ActionButton>
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )}
                          </TableBody>
                        )}
                      </TblContainer>
                    ) : (
                      <Loader />
                    )}
                  </Grid>
                </Grid>
                <ButtonsContainer className={classes.btnContainer}>
                  <Controls.Button
                    text={`Cancel`}
                    variant='outlined'
                    color='primary'
                    className={classes.btnStyle}
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to discard this?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDiscard();
                        },
                      });
                    }}
                  />
                  <Controls.Button
                    text={`Submit`}
                    variant='contained'
                    color='primary'
                    className={classes.btnStyle}
                    onClick={() => onSubmit()}
                  />
                </ButtonsContainer>
              </>
            );
          }}
        </Formik>
      </div>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default PreviousYearQuestion;
