import * as React from "react";
import { Box, Divider, Grid } from "@material-ui/core";
import LatexViewer from "../../../../Utils/LatexViewer";
import { Passage as passage } from "../../../../Assets/css/Preview/TestComponent";
import DropDown from "../../../../Utils/PreviewDropDown";

export default function Binary(props) {
  const { choices, description, isHaveDescription, question } = props;

  const { Question } = passage;
  const style = {
    show: {
      display: "block",
    },
    hide: {
      display: "none",
    },
  };

  const option = (option) => [
    { title: "Select", value: "Select", id: "default", disabled: true },
    ...option.map((item, index) => ({
      title: item,
      value: item,
      id: item,
    })),
  ];

  const math = (ii) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(ii, "text/html").body.textContent;
    return doc;
  };
  const { hide, show } = style;
  return (
    <Grid container id="test-container">
      <Grid
        item
        md={isHaveDescription === false ? 12 : 6}
        style={isHaveDescription === false ? hide : show}
        id="inside-test-container"
      >
        <Box display={"flex"} className={"overflow-scroll"} height={"inherit"}>
          <Box flex={1} className={"question-left-container"}>
            {description !== "" && (
              <Box fontSize={16} color={"#052A4E"} lineHeight={"30px"}>
                <LatexViewer math={description} />
              </Box>
            )}
          </Box>
          {isHaveDescription === false ? (
            ""
          ) : (
            <Box>
              <Divider variant="middle" orientation={"vertical"} />
            </Box>
          )}
        </Box>
      </Grid>
      <Grid
        item
        md={isHaveDescription === false ? 12 : 6}
        id="inside-test-container-right"
      >
        <Box
          minHeight={400}
          className={"overflow-scroll"}
          id="inside-test-container"
        >
          <Question style={{ marginBottom: "15px" }}>
            <LatexViewer math={question} />
          </Question>

          {choices.map((item) => {
            return (
              <Grid container>
                <span
                  style={{
                    wordBreak: "break-word",
                    width: "100%",
                    marginBottom: "1.2rem",
                  }}
                >
                  <span>
                    {math(item.text)}
                    <DropDown
                      name="Map Error Genre preview-dropDown"
                      items={option(item.dropDownList)}
                      value={item.dropDown || "default"}
                    />
                  </span>
                </span>
              </Grid>
            );
          })}
        </Box>
      </Grid>
    </Grid>
  );
}
