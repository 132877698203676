import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Controls from '../../Utils/controls/Controls';
import { ErrorMessage, FieldArray, Field } from 'formik';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import MULTIPLECHOICE from '@material-ui/icons/ShortTextRounded';
import FILEUPLOAD from '@material-ui/icons/ArrowUpward';
import TEXT from '@material-ui/icons/TextFormatRounded';

const useStyles = makeStyles({
  input: {
    display: 'flex',
    gap: '2rem',
    alignItems: 'flex-start',
    width: '100%',
  },
  actionBtns: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  inputWidth: { width: '100%' },
  choiceWidth: { width: '100%', display: 'flex', justifyContent: 'space-between' },
  spacer: {
    width: '100%',
    marginBottom: '10px',
    padding: '0.5rem',
  },
  addStepBtn: {
    borderRadius: '26px',
  },
  fieldStep: {
    border: '1px solid #dbdadab9',
    marginBottom: '1rem',
    padding: '14px 1.5rem',
    borderRadius: '10px',
    display: 'flex',
    width: '850px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fieldlabel: { color: '#052A4E', fontSize: '0.8rem' },
  fieldErr: { color: '#ff150d', fontSize: '0.8rem', marginBottom: '10px' },
});

const DynamicFormContainer = React.memo(({ ibhForm, setFieldValue, handleSave }) => {
  const classes = useStyles();

  const Types = () => [
    { id: 'TEXT', answerType: 'Text', icon: <TEXT /> },
    { id: 'MULTIPLECHOICE', answerType: 'Multiple Choice', icon: <MULTIPLECHOICE /> },
    { id: 'FILEUPLOAD', answerType: 'File Upload', icon: <FILEUPLOAD /> },
  ];

  const DynamicForm = () => {
    return (
      <>
        <FieldArray
          name={'ibhForm'}
          render={(arrayHelpers) => (
            <div>
              {ibhForm?.map((option, index) => (
                <div className={classes.fieldStep}>
                  <div key={index} className={classes.input}>
                    {(option.answerType === 'TEXT' || option.answerType === 'FILEUPLOAD') && (
                      <div className={classes.inputWidth}>
                        <h6 className={classes.fieldlabel}>Enter Your Question</h6>
                        <Field className={classes.spacer} name={`ibhForm.${index}.questions`} />
                        <ErrorMessage
                          name={`ibhForm.${index}.questions`}
                          render={(msg) => <p className={classes.fieldErr}>{msg}</p>}
                        />
                      </div>
                    )}
                    {option.answerType === 'MULTIPLECHOICE' && (
                      <div className={classes.inputWidth}>
                        <div className={classes.inputWidth}>
                          <h6 className={classes.fieldlabel}>Enter Your Question</h6>
                          <Field className={classes.spacer} name={`ibhForm.${index}.questions`} />
                          <ErrorMessage
                            name={`ibhForm.${index}.questions`}
                            render={(msg) => <p className={classes.fieldErr}>{msg}</p>}
                          />
                        </div>
                        <FieldArray
                          name={`ibhForm.${index}.ibhFormQuestionChoices`}
                          render={(arrayHelpers) => (
                            <div>
                              {option?.ibhFormQuestionChoices?.map((_, idx) => (
                                <div key={idx} className={classes.choiceWidth}>
                                  <div className={classes.inputWidth}>
                                    <h6 className={classes.fieldlabel}>{`Choice ${idx + 1}`}</h6>
                                    <Field
                                      className={classes.spacer}
                                      name={`ibhForm.${index}.ibhFormQuestionChoices.${idx}.questionChoice`}
                                    />
                                    <ErrorMessage
                                      name={`ibhForm.${index}.ibhFormQuestionChoices.${idx}.questionChoice`}
                                      render={(msg) => <p className={classes.fieldErr}>{msg}</p>}
                                    />
                                  </div>
                                  <div style={{ marginTop: '1rem' }}>
                                    <Controls.ActionButton
                                      onClick={() => {
                                        arrayHelpers.remove(idx);
                                      }}
                                    >
                                      <RemoveCircleIcon
                                        fontSize='medium'
                                        color='secondary'
                                        style={{ marginTop: '8px' }}
                                      />
                                    </Controls.ActionButton>
                                  </div>
                                </div>
                              ))}
                              <Controls.Button
                                text='Add Choice'
                                variant='contained'
                                color='primary'
                                startIcon={<AddIcon />}
                                className={classes.addStepBtn}
                                onClick={() =>
                                  arrayHelpers.push({
                                    questionChoice: '',
                                  })
                                }
                              />
                            </div>
                          )}
                        />
                      </div>
                    )}
                    <div style={{ display: 'flex', marginTop: '10px' }}>
                      <Controls.FormSelect
                        label='Answer Type'
                        name={`ibhForm.${index}.answerType`}
                        size='300px'
                        value={option.answerType}
                        onChange={(e) => {
                          setFieldValue(`ibhForm.${index}.answerType`, e.target.value);
                        }}
                        options={Types()}
                      />
                      <Controls.ActionButton
                        onClick={() => {
                          arrayHelpers.remove(index);
                          // handleDelete(ibhForm[index]?.id, setFieldValue);
                        }}
                      >
                        <RemoveCircleIcon fontSize='large' color='secondary' />
                      </Controls.ActionButton>
                    </div>
                  </div>
                </div>
              ))}
              <div className={classes.actionBtns}>
                <Controls.Button
                  text='Add Question'
                  variant='contained'
                  color='primary'
                  startIcon={<AddIcon />}
                  className={classes.addStepBtn}
                  onClick={() =>
                    arrayHelpers.push({
                      questions: '',
                      answerType: 'TEXT',
                      ibhFormQuestionChoices: [],
                    })
                  }
                />
                {ibhForm?.length > 0 && (
                  <Controls.Button
                    text='Save'
                    startIcon={<SaveIcon />}
                    variant='contained'
                    color='primary'
                    onClick={() => handleSave(ibhForm, setFieldValue)}
                    className={classes.addStepBtn}
                  />
                )}
              </div>
            </div>
          )}
        />
      </>
    );
  };

  return (
    <Grid container>
      <Grid item md={12}>
        {DynamicForm()}
      </Grid>
    </Grid>
  );
});

export default DynamicFormContainer;
