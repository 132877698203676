import React, { useEffect, useRef, useState } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ThumbUp from '@material-ui/icons/ThumbUp';
import CloudDownloadIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import ThumbDown from '@material-ui/icons/ThumbDown';
import FilterIcon from '@material-ui/icons/Tune';
import SortIcon from '@material-ui/icons/CompareArrows';
import BackHandler from '../Components/BackHandler';
import SearchIcon from '@material-ui/icons/SearchSharp';
import ExportIcon from '@material-ui/icons/GetApp';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import ClearIcon from '@material-ui/icons/Clear';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Loader from '../../Utils/controls/Loader';
import { Button } from '@material-ui/core';
import { FormControl, MenuItem, Select, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  cvDownload,
  getStudentEventStatus,
  getStudentList,
  pdMasterSheetDownload,
  sentWhatsappMSG,
  updateStudentEventStatus,
} from '../../../Actions/WallActions';
import Notification from '../../Utils/Notification';
import Controls from '../../Utils/controls/Controls';
// import { textToDownloadFile } from '../../Utils/Helpers';

function DriveResult() {
  let textRef = useRef(null);
  let selectedTextRef = useRef(null);
  let rejectedTextRef = useRef(null);
  const [noOfDriveTaken, setNoOfDriveTaken] = useState('all');
  const [sslcValue, setSslcValue] = useState('all');
  const [hscValue, setHscValue] = useState('all');
  const [ugValue, setUgValue] = useState('all');
  const { id } = useParams();
  const dispatch = useDispatch();
  const [rounds, setRounds] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRound, setSelectedRound] = useState([]);
  const [customQuestions, setCustomQuestions] = useState([]);
  const [eventInfo, setEventInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cvDownloadIsVisible, setCvDownloadIsVisible] = useState();
  const [masterReportIsVisible, setMasterReportIsVisible] = useState();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  let columns = [
    {
      title: 'Name',
      field: 'studentName',
      editable: false,
      filtering: false,
      sorting: true,
      defaultSort: 'asc',
      emptyValue: () => <em>--</em>,
    },
    {
      title: 'Email',
      field: 'studentEmailId',
      filtering: false,
      filterPlaceholder: 'filter',
      editable: false,
      sorting: false,
      emptyValue: () => <em>--</em>,
    },
    {
      title: 'Banding',
      field: 'softwareBandScore',
      filtering: true,
      editable: false,
      sorting: false,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      render: (rowData) => <p style={{ width: '70px' }}>{rowData.softwareBandScore}</p>,
    },
    {
      title: 'Video bands',
      field: 'softwareVideoBand',
      filtering: true,
      editable: false,
      sorting: false,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      render: (rowData) => <p style={{ width: '120px' }}>{rowData.softwareVideoBand}</p>,
    },
    {
      title: 'Status',
      field: 'stepStatus',
      sorting: false,
      emptyValue: () => <em>--</em>,
      render: (rowData) => (
        <p
          style={{
            background:
              rowData.stepStatus === 'Qualified'
                ? '#02ae02af'
                : rowData.stepStatus === 'Not Qualified'
                ? '#d80303aa'
                : '#9f9f9f',
            borderRadius: '4px',
            textAlign: 'center',
            padding: 5,
          }}
        >
          {rowData.stepStatus}
        </p>
      ),
      lookup: {
        Qualified: 'Qualified',
        'Not Qualified': 'Not Qualified',
        NA: 'NA',
      },
    },
    {
      title: 'No. of Drives taken',
      field: 'noOfDriveTaken',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      filterComponent: () => (
        <Select
          id='noOfDriveTaken'
          style={{ width: 70 }}
          value={noOfDriveTaken}
          onChange={(e) => {
            setNoOfDriveTaken(e.target.value);
            let filteredData = tableData.filter(
              (student) =>
                parseInt(student.noOfDriveTaken) >= e.target.value || e.target.value === 'all'
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={'all'}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={60}>60</MenuItem>
          <MenuItem value={90}>90</MenuItem>
          <MenuItem value={120}>120</MenuItem>
          <MenuItem value={150}>150</MenuItem>
          <MenuItem value={180}>180</MenuItem>
          <MenuItem value={200}>200</MenuItem>
        </Select>
      ),
      emptyValue: () => <em>--</em>,
      render: (rowData) => <p style={{ width: '80px' }}>{rowData.noOfDriveTaken}</p>,
    },
    {
      title: 'Elev8 Premium',
      field: 'elev8Premium',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div>{rowData.elev8Premium === false ? 'No' : 'Yes'}</div>,
      lookup: { true: 'Yes', false: 'No' },
    },
    {
      title: '10%',
      field: 'sscScore',
      cellStyle: {
        textAlign: 'center',
      },
      filterComponent: () => (
        <Select
          id='sslc'
          style={{ width: 70 }}
          value={sslcValue}
          onChange={(e) => {
            setSslcValue(e.target.value);
            let filteredData = tableData.filter(
              (student) => parseInt(student.sscScore) >= e.target.value || e.target.value === 'all'
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={'all'}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={60}>60%</MenuItem>
          <MenuItem value={70}>70%</MenuItem>
          <MenuItem value={80}>80%</MenuItem>
          <MenuItem value={90}>90%</MenuItem>
        </Select>
      ),
      emptyValue: () => <em>--</em>,
      render: (rowData) => <p style={{ width: '70px' }}>{rowData.sscScore}</p>,
    },
    {
      title: '12%',
      field: 'hscScore',
      cellStyle: {
        textAlign: 'center',
      },
      filterComponent: () => (
        <Select
          id='hsc'
          style={{ width: 70 }}
          value={hscValue}
          onChange={(e) => {
            setHscValue(e.target.value);
            let filteredData = tableData.filter(
              (student) => parseInt(student.hscScore) >= e.target.value || e.target.value === 'all'
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={'all'}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={60}>60%</MenuItem>
          <MenuItem value={70}>70%</MenuItem>
          <MenuItem value={80}>80%</MenuItem>
          <MenuItem value={90}>90%</MenuItem>
        </Select>
      ),
      emptyValue: () => <em>--</em>,
      render: (rowData) => <p style={{ width: '70px' }}>{rowData.hscScore}</p>,
    },
    {
      title: 'UG CGPA',
      field: 'ugScore',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      filterComponent: () => (
        <Select
          id='ugscore'
          style={{ width: 70 }}
          value={ugValue}
          onChange={(e) => {
            setUgValue(e.target.value);
            let filteredData = tableData.filter(
              (student) => parseInt(student.ugScore) >= e.target.value || e.target.value === 'all'
            );
            setFilteredData(filteredData);
          }}
        >
          <MenuItem value={'all'}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={6}>6</MenuItem>
          <MenuItem value={7}>7</MenuItem>
          <MenuItem value={8}>8</MenuItem>
          <MenuItem value={9}>9</MenuItem>
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={60}>60%</MenuItem>
          <MenuItem value={70}>70%</MenuItem>
          <MenuItem value={80}>80%</MenuItem>
          <MenuItem value={90}>90%</MenuItem>
        </Select>
      ),
      emptyValue: () => <em>--</em>,
      render: (rowData) => <p style={{ width: '70px' }}>{rowData.ugScore}</p>,
    },
    {
      title: 'Graduation',
      field: 'ugEndDate',
      cellStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      render: (rowData) => <p>{new Date(rowData.ugEndDate).getFullYear()}</p>,
    },
    {
      title: 'Active Backlogs',
      field: 'activeBacklogs',
      type: 'numeric',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      render: (rowData) => <p>{rowData.activeBacklogs}</p>,
    },
    {
      title: 'Fresher/Experience',
      field: 'experienceStatus',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      emptyValue: () => <em>--</em>,
      render: (rowData) => <div>{rowData.experienceStatus}</div>,
      lookup: { Fresher: 'Fresher', Experienced: 'Experienced' },
    },
  ];

  //Populates the custom questions into columns array
  const customQuestionFields = customQuestions?.forEach((question, idx) => {
    columns.push({
      title: question,
      field: 'answers',
      sorting: false,
      render: (rowData) => (
        <div style={{ width: '300px', display: 'flex' }}>
          <p>{rowData?.answers[idx] ?? '--'}</p>
        </div>
      ),
      customFilterAndSearch: (term, rowData) =>
        Boolean(
          rowData?.answers &&
            rowData?.answers[idx] &&
            rowData?.answers[idx].toLowerCase().indexOf(term.toLowerCase()) > -1
        ),
    });
    return columns;
  });

  const fetchDriveDetails = () => {
    setIsLoading(true);
    dispatch(
      getStudentEventStatus(id, (response) => {
        setCustomQuestions(response?.data?.stepDetailsModelList[0]?.customQuestions);
        setEventInfo(response.data);
        setRounds(response?.data?.stepDetailsModelList);
        setIsLoading(false);
      })
    );
  };

  //Load More Students
  const fetchMoreStudentList = (number) => {
    setIsLoading(true);
    setPageNo((prev) => prev + number);
    dispatch(
      getStudentList(id, pageNo, selectedRound?.stepId, (response) => {
        setTotalItems(response?.data?.totalElements ?? 0);
        setTableData([...(tableData || []), ...(response?.data?.studentList ?? [])]);
        setFilteredData([...(tableData || []), ...(response?.data?.studentList ?? [])]);
        setIsLoading(false);
        setCvDownloadIsVisible(response?.data?.isEnableCv);
        setMasterReportIsVisible(response?.data?.isEnableMasterReport);
      })
    );
  };

  useEffect(() => {
    fetchDriveDetails();
  }, []);

  useEffect(() => {
    if (tableData.length < totalItems) {
      setIsLoading(true);
      fetchMoreStudentList(1);
    } else {
      setIsLoading(false);
    }
  }, [tableData]);

  //Updates the specific round details
  const _roundUpdate = (data) => {
    setIsLoading(true);

    let payload = {
      eventId: eventInfo?.eventId,
      eventName: eventInfo?.eventName,
      stepDetailsModelList: [
        {
          ...selectedRound,
          rejectedReason: textRef.current.value === '' ? null : textRef.current.value,
          rejectedText: rejectedTextRef.current.value === '' ? null : rejectedTextRef.current.value,
          selectedText: selectedTextRef.current.value === '' ? null : selectedTextRef.current.value,
          studentList: data,
        },
      ],
    };

    //once the update is success, it re-renders the ui with latest information
    dispatch(
      updateStudentEventStatus(id, payload, (res) => {
        if (res.success) {
          fetchDriveDetails();
          setNotify({
            isOpen: true,
            message: res.message,
            type: 'success',
          });
          setSslcValue('all');
          setHscValue('all');
          setUgValue('all');
          setPageNo(0);
        } else {
          setSslcValue('all');
          setHscValue('all');
          setUgValue('all');
          setIsLoading(false);
          setNotify({
            isOpen: true,
            message: res.message,
            type: 'error',
          });
        }
      })
    );
  };

  return (
    <div>
      <BackHandler title='' tab={0} isDrive={true} />
      <h3 style={{ textAlign: 'center', marginBottom: '15px' }}>{eventInfo?.eventName}</h3>
      <Controls.Input
        inputRef={textRef}
        label='Enter Rejection Reason'
        name='rejectReason'
        style={{ width: '100%' }}
        multiline
        rows={3}
      />
      {selectedRound?.stepId && (
        <div
          style={{
            marginTop: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          <Button
            disabled={isLoading || (tableData.length === totalItems && tableData.length !== 0)}
            color='primary'
            variant='outlined'
            style={{ height: '56px' }}
            onClick={() => fetchMoreStudentList(1)}
          >
            {isLoading ? 'Loading...' : 'Load Students List'}
          </Button>

          <Controls.Input
            inputRef={selectedTextRef}
            label='Selected Text'
            name='selectedText'
            style={{ width: '160px' }}
          />

          <Controls.Input
            inputRef={rejectedTextRef}
            label='Rejected Text'
            name='rejectedText'
            style={{ width: '160px' }}
          />

          {tableData.length > 0 && (
            <h6>
              {tableData.length}/{totalItems}{' '}
            </h6>
          )}
        </div>
      )}
      <MaterialTable
        columns={columns}
        data={filteredData}
        components={{
          Toolbar: (props) => (
            <div
              style={{
                display: 'flex',
                marginTop: '1rem',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: '2px solid #0094b1',
                borderTop: '2px solid #007d93',
                borderRadius: '4px',
              }}
            >
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <FormControl>
                    <Autocomplete
                      style={{ width: '300px', paddingLeft: '1.5rem' }}
                      name='rounds'
                      getOptionLabel={(option) => option?.stepName}
                      options={rounds ?? []}
                      value={selectedRound}
                      onChange={(e, value) => {
                        if (value) {
                          setSelectedRound(value);
                          setTableData([]);
                          setFilteredData([]);
                          setPageNo(0);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Select Round'
                          name='rounds'
                          variant='outlined'
                        />
                      )}
                    />
                  </FormControl>
                  <div
                    id='material-table'
                    style={{ flexGrow: 1, padding: '2rem 0rem', marginLeft: '10px' }}
                  >
                    <MTableToolbar {...props} />
                  </div>
                </>
              )}
            </div>
          ),
        }}
        actions={[
          {
            icon: () => <ThumbUp style={{ color: 'green' }} />,
            tooltip: 'Accept',
            onClick: (e, data) => {
              if (selectedTextRef.current.value.length === 0) {
                setNotify({
                  isOpen: true,
                  message: 'Please fill the selection text',
                  type: 'error',
                });
                return;
              }
              let filterStatus = data.map((student) => {
                student.stepStatus = 'Qualified';
                return student;
              });
              setTableData(tableData, filterStatus);
              setFilteredData(filteredData, filterStatus);
              _roundUpdate(filterStatus);
            },
          },
          {
            icon: () => <ThumbDown color='error' />,
            tooltip: 'Reject',
            onClick: (e, data) => {
              if (
                textRef.current.value.length === 0 ||
                rejectedTextRef.current.value.length === 0
              ) {
                setNotify({
                  isOpen: true,
                  message: 'Please fill the rejection text & reason',
                  type: 'error',
                });
                return;
              }
              let filterStatus = data.map((student) => {
                student.stepStatus = 'Not Qualified';
                return student;
              });
              setTableData(tableData, filterStatus);
              setFilteredData(filteredData, filterStatus);
              _roundUpdate(filterStatus);
            },
          },
          {
            icon: () => <WhatsAppIcon style={{ color: 'green' }} />,
            tooltip: 'Whatsapp',
            onClick: (e, tableData) => {
              let filteredIds = tableData.map((student) => student.studentId);
              let payload = { studentIdList: filteredIds };

              let booleanArr = tableData.map((item) => Boolean(item.studentId));
              if (booleanArr.includes(false)) {
                setNotify({
                  isOpen: true,
                  message: 'Please Choose Atleast one Student',
                  type: 'error',
                });
              } else {
                dispatch(sentWhatsappMSG(selectedRound?.stepId, payload));
                setNotify({
                  isOpen: true,
                  message: 'WhatsApp Message Sent Successfully',
                  type: 'success',
                });
              }
            },
          },
          {
            icon: () => (
              <CloudDownloadIcon
                style={{ color: cvDownloadIsVisible === false ? 'gray' : '#a60202aa' }}
              />
            ),
            tooltip: 'Send CVs to Email',
            disabled: cvDownloadIsVisible === false,
            onClick: (e, data) => {
              let filteredIds = data.map((student) => student.studentId);
              let obj = { studentIdList: filteredIds };
              dispatch(
                cvDownload(id, obj, (response) => {
                  if (response.status === 200) {
                    // textToDownloadFile(response.data, eventInfo?.eventName, 'zip');
                    setNotify({
                      isOpen: true,
                      message: 'CVs will be sent to your email soon...',
                      type: 'success',
                    });
                    setPageNo(0);
                    setTableData([]);
                  } else {
                    setNotify({
                      isOpen: true,
                      message: response.message,
                      type: 'error',
                    });
                  }
                })
              );
            },
          },
          {
            icon: () => (
              <DescriptionIcon
                style={{ color: masterReportIsVisible === false ? 'gray' : '#1093FF' }}
              />
            ),
            tooltip: 'Send Master Sheet to Email',
            disabled: masterReportIsVisible === false,
            onClick: (e, data) => {
              let filteredIds = data.map((student) => student.studentId);
              let obj = { studentIdList: filteredIds };
              dispatch(
                pdMasterSheetDownload(id, obj, (response) => {
                  if (response.status === 200) {
                    // textToDownloadFile(response.data, eventInfo?.eventName, 'csv');
                    setNotify({
                      isOpen: true,
                      message: 'Master Sheet will be sent to your email soon...',
                      type: 'success',
                    });
                    setPageNo(0);
                    setTableData([]);
                  } else {
                    setNotify({
                      isOpen: true,
                      message: response.message,
                      type: 'error',
                    });
                  }
                })
              );
            },
          },
        ]}
        localization={{
          toolbar: {
            nRowsSelected: 'No. of Student(s) : {0}',
          },
        }}
        options={{
          sorting: true,
          search: true,
          searchFieldAlignment: 'right',
          searchFieldVariant: 'outlined',
          filtering: true,
          paging: false,
          exportButton: false,
          exportAllData: false,
          exportFileName: eventInfo?.eventName,
          addRowPosition: 'first',
          actionsColumnIndex: -1,
          selection: true,
          showSelectAllCheckbox: true,
          showTextRowsSelected: true,
          grouping: false,
          columnsButton: true,
          rowStyle: (data, index) => (index % 2 === 0 ? { background: '#f5f5f5' } : null),
          headerStyle: { background: 'aliceblue', color: '#000' },
        }}
        title={''}
        icons={{
          Filter: () => <FilterIcon style={{ color: '#807f7f' }} />,
          SortArrow: () => (
            <SortIcon fontSize='small' style={{ color: '#c6c6c6', transform: 'rotate(-90deg)' }} />
          ),
          Clear: () => <ClearIcon />,
          Export: () => <ExportIcon style={{ color: 'green' }} />,
          ResetSearch: () => <ClearIcon />,
          Search: () => <SearchIcon />,
          ViewColumn: () => <ViewColumnIcon />,
        }}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}

export default DriveResult;
