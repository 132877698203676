import { makeStyles } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
export const useStyles = makeStyles({
    customTableContainer: {
        overflowX: "initial",

        // border: "1px solid #C8C8C8",



    }
})