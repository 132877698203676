import { Grid } from "@material-ui/core";
import React from "react";
import DropDown from "../../Utils/DropDown";

const SELECT_DEFAULT_OBJECT = {
  id: "default",
  title: "Select",
  disabled: true,
};

function FilterComponent({
  courseId,
  courses,
  subjectId,
  subjects,
  conceptId,
  concepts,
  topicList,
  topicId,
  onChange,
}) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={4} md={4}>
        <DropDown
          label='Course'
          name='course'
          value={courseId || "default"}
          items={[SELECT_DEFAULT_OBJECT, ...(courses?.data || [])]}
          onChange={onChange}
        />
      </Grid>

      <Grid item xs={4} md={4}>
        <DropDown
          label='Subject'
          name='subject'
          value={subjectId || "default"}
          items={[SELECT_DEFAULT_OBJECT, ...(subjects?.data || [])]}
          onChange={onChange}
        />
      </Grid>

      <Grid item xs={4} md={4}>
        <DropDown
          label='Concept'
          name='concept'
          value={conceptId || "default"}
          items={[SELECT_DEFAULT_OBJECT, ...(concepts?.data || [])]}
          onChange={onChange}
        />
      </Grid>

      <Grid item xs={4} md={4}>
        <DropDown
          label='Topic Name'
          name='topicId'
          value={topicId || "default"}
          items={[SELECT_DEFAULT_OBJECT, ...(topicList ? topicList.data : [])]}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
}

export default FilterComponent;
