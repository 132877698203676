import { POST_ADMIT_SERVICES } from "../Redux/Action";
const initialState = {
    getMomPoints: [],
    savePoints: ""
};
const PasReducer = (state = initialState, action) => {
    switch (action.type) {

        case POST_ADMIT_SERVICES.savePoints:
            return {
                ...state,
                savePoints: action.savePoints,
            };

        case POST_ADMIT_SERVICES.getMomPoints:
            return {
                ...state,
                getMomPoints: action.payload,
            };

        default:
            break;
    }
    return state;
};
export default PasReducer;