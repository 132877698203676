import { Grid, IconButton, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
// import { colors, HELPER_TEXT } from "../../Constant/Variables";
import { colors, HELPER_TEXT } from "../../../Constant/Variables";
import DropDown from "../../Controls/DropDown";
import TextFieldComponent from "../../Controls/TextField";
import BottomContainer from "../../PgaReport/BottomContainer";
import {
  AddButton,
  PageWrapper,
} from "../../PgaReport/Components/StyledComponents";
import { useStyles } from "../../PgaReport/Styles/Index";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import DeleteRounded from "../../../Asset/Images/DeleteRounded.svg";
import BulbIcon from "../../../Asset/Images/Bulb.svg";

// import {
//   deleteAdditionalPoints,
//   getAllAdditionalPoints,
//   getStudentAdditionalPoints,
//   saveAdditionalPoints,
// } from "../../AsyncApiCall/PgaReport/AdditionalPoint";
import {
  deletePoints,
  getAllAdditionalPoints,
  getAdditionalPoints,
  savePoints,
} from "../../../AsyncApiCall/PgaReport/AdditionalPoint";
import MySnackBar from "../../MySnackBar";
import { isEmptyObject, isEmptyString } from "../../Validation";
// import { getAdditionalPoints } from "../../../Actions/ProfileMentoring";
import { useDispatch } from "react-redux";
function AdditionalPoints(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [allAdditionalPointsList, setAllAdditionalPointsList] = useState([]);
  const [studentAdditionalPoints, setStudentAdditionalPoints] = useState([]);
  const [snack, setSnack] = useState({
    snackOpen: false,
    snackMsg: "",
    snackColor: "",
  });
  const versionNo = 1;
  const studentId = "dbe618ae-862c-4d5e-8f20-262d7ce1cd7b";
  const productId = "c46ccdff-0ce7-4b60-95d7-fc6b8a109646";
  // useEffect(() => {
  //   dispatch(getAdditionalPoints(studentId, productId, versionNo));
  // }, []);
  const getAndSetStudentAdditionalPoints = () => {
    getAdditionalPoints(props.studentId, props.productId, props.version).then(
      (response) => {
        if (response?.status === 200) {
          if (response?.data?.data?.length === 0) {
            setStudentAdditionalPoints([
              {
                id: null,
                pgaAdditionalPoint: null,
                remark: "",
                versionNo: props.version,
              },
            ]);
          } else {
            setStudentAdditionalPoints(response.data.data);
          }
        }
      }
    );
  };

  useEffect(() => {
    getAllAdditionalPoints(props.productId).then((response) => {
      if (response?.status === 200) {
        setAllAdditionalPointsList(response?.data?.data);
      }
    });
    getAndSetStudentAdditionalPoints();
  }, [props?.version]);

  const handleAddClick = () => {
    setStudentAdditionalPoints([
      ...studentAdditionalPoints,
      {
        id: null,
        pgaAdditionalPoint: null,
        remark: "",
        versionNo: props.version,
      },
    ]);
  };

  const handleAdditionalPointChange = (value, index) => {
    let copyOf = [...studentAdditionalPoints];
    copyOf[index].pgaAdditionalPoint = value;
    copyOf[index].remark = value ? value.remark : "";
    setStudentAdditionalPoints(copyOf);
  };

  const handleTextChange = (e, index) => {
    let copyOf = [...studentAdditionalPoints];
    copyOf[index][e.target.name] = e.target.value;
    setStudentAdditionalPoints(copyOf);
  };

  const handleSave = () => {
    let error = false;
    for (let index = 0; index < studentAdditionalPoints.length; index++) {
      if (isEmptyObject(studentAdditionalPoints[index].pgaAdditionalPoint)) {
        error = true;
        break;
      }
      if (isEmptyString(studentAdditionalPoints[index].remark)) {
        error = true;
        break;
      }
    }
    if (!error) {
      savePoints(
        props.studentId,
        props.productId,
        studentAdditionalPoints
      ).then((response) => {
        if (response.status === 200) {
          getAndSetStudentAdditionalPoints();
          setSnack({
            snackMsg: "Saved Successfully",
            snackColor: "success",
            snackOpen: true,
          });
        }
      });
    } else {
      setSnack({
        snackMsg: HELPER_TEXT.requiredField,
        snackColor: "error",
        snackOpen: true,
      });
    }
  };

  const handleDelete = (item, index) => {
    if (item.id) {
      deletePoints(item.id).then((response) => {
        if (response.status === 200) {
          getAndSetStudentAdditionalPoints();
        }
      });
    } else {
      let copyOf = [...studentAdditionalPoints];
      if (copyOf.length !== 1) {
        copyOf.splice(index, 1);
        setStudentAdditionalPoints(copyOf);
      }
    }
  };
  return (
    <PageWrapper>
      <Grid container className={classes.containerStyle}>
        <Grid item md={8}>
          <Grid container spacing={2}>
            <Grid item md={10}>
              <Typography variant={"h5"}>
                Additional Points
                <img
                  src={BulbIcon}
                  // className={classes.deleteIconStyle}
                  alt={"Bulb_Icon"}
                />
              </Typography>
            </Grid>
            <Grid item md={2} container justifyContent={"flex-end"}>
              {props.editable && (
                <AddButton
                  color={colors.primaryColor}
                  onClick={handleAddClick}
                  className={props.editable ? "" : classes.pointerEvent}
                >
                  Add
                </AddButton>
              )}
            </Grid>
            {studentAdditionalPoints.map((eachItem, index) => {
              return (
                <>
                  <Grid item md={3}>
                    <DropDown
                      id="combo-box-demo"
                      options={allAdditionalPointsList}
                      getOptionLabel={(option) => option.name}
                      value={eachItem.pgaAdditionalPoint}
                      onChange={(e, value) =>
                        handleAdditionalPointChange(value, index)
                      }
                      className={props.editable ? "" : classes.pointerEvent}
                      renderInput={(params) => (
                        <TextFieldComponent
                          {...params}
                          label="Additional Points"
                          variant="standard"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={9}></Grid>
                  <Grid item md={11}>
                    <TextFieldComponent
                      name={"remark"}
                      onChange={(e) => handleTextChange(e, index)}
                      value={eachItem.remark}
                      className={props.editable ? "" : classes.pointerEvent}
                      label={"Remarks"}
                      fullWidth
                      multiline
                    />
                  </Grid>
                  <Grid item md={1}>
                    <IconButton
                      onClick={() => handleDelete(eachItem, index)}
                      className={props.editable ? "" : classes.pointerEvent}
                    >
                      <img
                        src={DeleteRounded}
                        className={classes.deleteIconStyle}
                        alt={"Delete_Icon"}
                      />
                    </IconButton>
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Grid>
        <Grid item md={4}></Grid>
      </Grid>
      {props.editable === false ? (
        <></>
      ) : (
        <BottomContainer onClick={handleSave} />
      )}
      <MySnackBar
        onClose={() =>
          setSnack({
            snackOpen: false,
            snackMsg: "",
            snackColor: "",
          })
        }
        snackOpen={snack.snackOpen}
        snackVariant={snack.snackColor}
        snackMsg={snack.snackMsg}
      />
    </PageWrapper>
  );
}

export default AdditionalPoints;
