import { IBH } from '../Redux/Action';

const defaultState = {
  loading: false,
  getIbhRoundList: null,
  createStatus: null,
  updateStatus: null,
  allWebinarList: null,
  deleteStatus: null,
  webinarData: null,
};

export const ibhReducer = (state = defaultState, action) => {
  switch (action.type) {
    case IBH.LOADER:
      return { ...state, loading: true };
    case IBH.CLEAR_DATA:
      return defaultState;
    case IBH.GET_ALL_WEBINAR_LIST:
      return {
        ...state,
        loading: false,
        allWebinarList: action.payload,
      };
    case IBH.GET_WEBINAR_BY_ID:
      return { ...state, loading: false, webinarData: action.payload };
    case IBH.DELETE_WEBINAR_BY_ID:
      return { ...state, loading: false, deleteStatus: action.payload };
    case IBH.getIbhRoundList:
      return {
        ...state,
        loading: false,
        getIbhRoundList: action.payload,
      };
    case IBH.CREATE_WEBINAR:
      return { ...state, loading: false, createStatus: action.payload };
    case IBH.UPDATE_WEBINAR:
      return { ...state, loading: false, updateStatus: action.payload };
    default:
      return state;
  }
};
