import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import MySnackBar from "../MySnackBar";
import { GSContainer, GSTitle } from "../../Asset/StyledComponents/Styles";
import { makeStyles } from "@material-ui/core/styles";
import BackButton from "../../Asset/Images/backbutton.svg";
import { AppBar, Breadcrumbs } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Typography } from "@material-ui/core";
import { Grid, TextField } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
  createBSchoolAptitudeTest,
  getAllAptitudeTest,
  getPerAptitudeTest,
} from "../../Actions/BSchoolAction";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
  },
  btnStyles: {
    paddingLeft: 30,
    paddingRight: 30,
  },
}));
export default function BSAptitudeTest(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    getAllBSchoolDataForAptitudeTest,
    getPerBSchoolDataForAptitudeTest,
  } = useSelector((state) => state.BSchoolReducer);

  const [state, setState] = useState({
    avgGMATScore: null,
    highestGMATScore: null,
    lowestGMATScore: null,
    gmatRangeMax: null,
    gmatRangeMin: null,
    medianGMAT: null,
    avgGREScore: null,
    highestGREScore: null,
    lowestGREScore: null,
    medianGREScore: null,
    toeflRequiredForInternationalStudents: null,
    toeflMin: null,
    ieltsMin: null,
    gmatRequirement: null,
    waiverPolicy: null,
    waiverPolicyLink: null,
    programId: null,
    id: "",
  });

  const [snack, setSnack] = useState({
    open: false,
    message: "",
    color: "",
  });

  useEffect(() => {
    dispatch(
      getAllAptitudeTest(props.location.state.id, (res) => {
        if (res.success) {
          let id = res.data[0].id;
          dispatch(
            getPerAptitudeTest(id, (res) => {
              if (res.id) {
                setState({
                  avgGMATScore: res.avgGMATScore,
                  highestGMATScore: res.highestGMATScore,
                  lowestGMATScore: res.lowestGMATScore,
                  gmatRangeMax: res.gmatRangeMax,
                  gmatRangeMin: res.gmatRangeMin,
                  medianGMAT: res.medianGMAT,
                  avgGREScore: res.avgGREScore,
                  highestGREScore: res.highestGREScore,
                  lowestGREScore: res.lowestGREScore,
                  medianGREScore: res.medianGREScore,
                  toeflRequiredForInternationalStudents:
                    res.toeflRequiredForInternationalStudents,
                  toeflMin: res.toeflMin,
                  ieltsMin: res.ieltsMin,
                  gmatRequirement: res.gmatRequirement,
                  waiverPolicy: res.waiverPolicy,
                  waiverPolicyLink: res.waiverPolicyLink,
                  programId: res.programId,
                  id: res.id,
                });
              }
            })
          );
        }
      })
    );
  }, []);

  const handleSave = () => {
    if (
      state.avgGMATScore ||
      state.highestGMATScore ||
      state.lowestGMATScore ||
      state.gmatRangeMax ||
      state.gmatRangeMin ||
      state.medianGMAT ||
      state.avgGREScore ||
      state.highestGMATScore ||
      state.lowestGREScore ||
      state.medianGREScore ||
      state.toeflRequiredForInternationalStudents ||
      state.toeflMin ||
      state.ieltsMin ||
      state.gmatRequirement ||
      state.waiverPolicy ||
      state.waiverPolicyLink
    ) {
      if (state.id.length > 0) {
        let obj = {
          avgGMATScore:
            Number(state.avgGMATScore) === 0
              ? null
              : Number(state.avgGMATScore),
          highestGMATScore:
            Number(state.highestGMATScore) === 0
              ? null
              : Number(state.highestGMATScore),
          lowestGMATScore: state.lowestGMATScore,
          gmatRangeMax:
            Number(state.gmatRangeMax) === 0
              ? null
              : Number(state.gmatRangeMax),
          gmatRangeMin:
            Number(state.gmatRangeMin) === 0
              ? null
              : Number(state.gmatRangeMin),
          medianGMAT:
            Number(state.medianGMAT) === 0 ? null : Number(state.medianGMAT),
          avgGREScore:
            Number(state.avgGREScore) === 0 ? null : Number(state.avgGREScore),
          highestGREScore:
            Number(state.highestGREScore) === 0
              ? null
              : Number(state.highestGREScore),
          lowestGREScore: state.lowestGREScore,
          medianGREScore:
            Number(state.medianGREScore) === 0
              ? null
              : Number(state.medianGREScore),
          toeflRequiredForInternationalStudents:
            state.toeflRequiredForInternationalStudents,
          toeflMin: state.toeflMin,
          ieltsMin: state.ieltsMin,
          gmatRequirement: state.gmatRequirement,
          waiverPolicy: state.waiverPolicy,
          waiverPolicyLink: state.waiverPolicyLink,
          programId: props.location.state.id,
          id: state.id,
        };
        dispatch(
          createBSchoolAptitudeTest(obj, (res) => {
            if (res.success) {
              setSnack({
                message: "Updated Successfully!",
                open: true,
                color: "success",
              });
            } else {
              setSnack({
                message: res.data.message,
                open: true,
                color: "error",
              });
            }
          })
        );
      } else {
        let obj = {
          avgGMATScore:
            Number(state.avgGMATScore) === 0
              ? null
              : Number(state.avgGMATScore),
          highestGMATScore:
            Number(state.highestGMATScore) === 0
              ? null
              : Number(state.highestGMATScore),
          lowestGMATScore: state.lowestGMATScore,
          gmatRangeMax:
            Number(state.gmatRangeMax) === 0
              ? null
              : Number(state.gmatRangeMax),
          gmatRangeMin:
            Number(state.gmatRangeMin) === 0
              ? null
              : Number(state.gmatRangeMin),
          medianGMAT:
            Number(state.medianGMAT) === 0 ? null : Number(state.medianGMAT),
          avgGREScore:
            Number(state.avgGREScore) === 0 ? null : Number(state.avgGREScore),
          highestGREScore:
            Number(state.highestGREScore) === 0
              ? null
              : Number(state.highestGREScore),
          lowestGREScore: state.lowestGREScore,
          medianGREScore:
            Number(state.medianGREScore) === 0
              ? null
              : Number(state.medianGREScore),
          toeflRequiredForInternationalStudents:
            state.toeflRequiredForInternationalStudents,
          toeflMin: state.toeflMin,
          ieltsMin: state.ieltsMin,
          gmatRequirement: state.gmatRequirement,
          waiverPolicy: state.waiverPolicy,
          waiverPolicyLink: state.waiverPolicyLink,
          programId: props.location.state.id,
        };
        dispatch(
          createBSchoolAptitudeTest(obj, (res) => {
            if (res.success) {
              setSnack({
                message: "Saved Successfully!",
                open: true,
                color: "success",
              });
            } else {
              setSnack({
                message: res.data.message,
                open: true,
                color: "error",
              });
            }
          })
        );
      }
    }
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div style={{ display: "flex", flexDirection: "row", margin: "10px" }}>
          <img
            src={BackButton}
            style={{ cursor: "pointer", marginTop: "-10px" }}
            onClick={() => props.history.goBack()}
          />
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Typography
              style={{
                cursor: "pointer",
                fontWeight: "600",
                marginLeft: "10px",
              }}
            >
              Back
            </Typography>
          </Breadcrumbs>
        </div>
        <AppBar position="static" color="default">
          <GSContainer>
            <GSTitle>
              {" "}
              {state.id.length > 0 ? "Edit" : "Add"} Aptitude Test
            </GSTitle>
            <Grid
              container
              justifyContent="center"
              direction="column"
              alignItems="center"
              spacing={4}
            >
              <Grid item md={10}>
                <Grid container spacing={6}>
                  <Grid item md={6}>
                    <TextField
                      value={state.avgGMATScore}
                      onChange={(e) => {
                        setState({
                          ...state,
                          avgGMATScore: e.target.value,
                        });
                      }}
                      name={"avgGMATScore"}
                      label={"Average GMAT Score"}
                      fullWidth
                      type={"number"}
                      InputLabelProps={{
                        shrink:
                          state.avgGMATScore === null || "" ? false : true,
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      value={state.highestGMATScore}
                      onChange={(e) =>
                        setState({ ...state, highestGMATScore: e.target.value })
                      }
                      name={"highestGMATScore"}
                      label={"Highest GMAT Score"}
                      fullWidth
                      type={"number"}
                      InputLabelProps={{
                        shrink: state.highestGMATScore === null ? false : true,
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      value={state.lowestGMATScore}
                      onChange={(e) =>
                        setState({ ...state, lowestGMATScore: e.target.value })
                      }
                      name={"lowestGMATScore"}
                      label={"Lowest GMAT Score"}
                      fullWidth
                      InputLabelProps={{
                        shrink: state.lowestGMATScore === null ? false : true,
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      value={state.gmatRangeMax}
                      onChange={(e) =>
                        setState({ ...state, gmatRangeMax: e.target.value })
                      }
                      name={"gmatRangeMax"}
                      label={"GMAT Range Maximum"}
                      fullWidth
                      type={"number"}
                      InputLabelProps={{
                        shrink:
                          state.gmatRangeMax === null || "" ? false : true,
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      value={state.gmatRangeMin}
                      onChange={(e) =>
                        setState({ ...state, gmatRangeMin: e.target.value })
                      }
                      name={"gmatRangeMin"}
                      label={"GMAT Range Minimum"}
                      fullWidth
                      type={"number"}
                      InputLabelProps={{
                        shrink:
                          state.gmatRangeMin === null || "" ? false : true,
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      value={state.medianGMAT}
                      onChange={(e) =>
                        setState({ ...state, medianGMAT: e.target.value })
                      }
                      name={"medianGMAT"}
                      label={"Median GMAT"}
                      fullWidth
                      type={"number"}
                      InputLabelProps={{
                        shrink: state.medianGMAT === null || "" ? false : true,
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      value={state.avgGREScore}
                      onChange={(e) =>
                        setState({ ...state, avgGREScore: e.target.value })
                      }
                      name={"avgGREScore"}
                      label={"Average GRE Score"}
                      fullWidth
                      type={"number"}
                      InputLabelProps={{
                        shrink: state.avgGREScore === null || "" ? false : true,
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      value={state.highestGREScore}
                      onChange={(e) =>
                        setState({ ...state, highestGREScore: e.target.value })
                      }
                      name={"highestGREScore"}
                      label={"Highest GRE Score"}
                      fullWidth
                      type={"number"}
                      InputLabelProps={{
                        shrink:
                          state.highestGREScore === null || "" ? false : true,
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      value={state.lowestGREScore}
                      onChange={(e) =>
                        setState({ ...state, lowestGREScore: e.target.value })
                      }
                      name={"lowestGREScore"}
                      label={"Lowest GRE Score"}
                      fullWidth
                      InputLabelProps={{
                        shrink:
                          state.lowestGREScore === null || "" ? false : true,
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      value={state.medianGREScore}
                      onChange={(e) =>
                        setState({ ...state, medianGREScore: e.target.value })
                      }
                      name={"medianGREScore"}
                      label={"Median GRE Score"}
                      fullWidth
                      type={"number"}
                      InputLabelProps={{
                        shrink:
                          state.medianGREScore === null || "" ? false : true,
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      value={state.toeflRequiredForInternationalStudents}
                      onChange={(e) =>
                        setState({
                          ...state,
                          toeflRequiredForInternationalStudents: e.target.value,
                        })
                      }
                      name={"toeflRequiredForInternationalStudents"}
                      label={"Toefl Required For International Students"}
                      fullWidth
                      InputLabelProps={{
                        shrink:
                          state.toeflRequiredForInternationalStudents ===
                            null || ""
                            ? false
                            : true,
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      value={state.toeflMin}
                      onChange={(e) =>
                        setState({ ...state, toeflMin: e.target.value })
                      }
                      name={"toeflMin"}
                      label={"Toefl Minimum"}
                      fullWidth
                      InputLabelProps={{
                        shrink: state.toeflMin === null || "" ? false : true,
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      value={state.ieltsMin}
                      onChange={(e) =>
                        setState({ ...state, ieltsMin: e.target.value })
                      }
                      name={"ieltsMin"}
                      label={"Ielts Minimum"}
                      fullWidth
                      InputLabelProps={{
                        shrink: state.ieltsMin === null || "" ? false : true,
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      value={state.gmatRequirement}
                      onChange={(e) =>
                        setState({ ...state, gmatRequirement: e.target.value })
                      }
                      name={"gmatRequirement"}
                      label={"GMAT Requirement"}
                      fullWidth
                      InputLabelProps={{
                        shrink:
                          state.gmatRequirement === null || "" ? false : true,
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      value={state.waiverPolicy}
                      onChange={(e) =>
                        setState({ ...state, waiverPolicy: e.target.value })
                      }
                      name={"waiverPolicy"}
                      label={"Waiver Policy"}
                      fullWidth
                      InputLabelProps={{
                        shrink:
                          state.waiverPolicy === null || "" ? false : true,
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      value={state.waiverPolicyLink}
                      onChange={(e) =>
                        setState({ ...state, waiverPolicyLink: e.target.value })
                      }
                      name={"waiverPolicyLink"}
                      label={"Waiver Policy Link"}
                      fullWidth
                      InputLabelProps={{
                        shrink:
                          state.waiverPolicyLink === null || "" ? false : true,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={2}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  className={classes.btnStyles}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </GSContainer>
        </AppBar>
        <MySnackBar
          snackOpen={snack.open}
          snackVariant={snack.color}
          snackMsg={snack.message}
          onClose={() =>
            setSnack({
              open: false,
              message: "",
              color: "",
            })
          }
        />
      </div>
    </React.Fragment>
  );
}
