import { ListItemButton, ListItemText } from "@mui/material";
import React from "react";
// import { ICONS } from "../utils/Shared";
// import ICONS from "../../../Utils/Shared";
import { ReactComponent as EnableTick } from "../../../Asset/Images/enableTick.svg";
import { ReactComponent as DisableTick } from "../../../Asset/Images/disabledTick.svg";
import { useStyles } from "./Styles";
import { Grid } from "@material-ui/core";

function ActionListItem({ name, status, selected, onClick, list }) {
    const classes = useStyles();
    const ICONS = {
        enableTick: <EnableTick />,
        disableTick: <DisableTick />,
        // selectedMenuArrow: <SelectedMenuArrow />
    }
    console.log(status, "^^^status of card^^^^")
    const renderIcon = () => {
        if (status === true) {
            return ICONS["enableTick"];
        }
        // else if (status === "PROGRESS" && selected) {
        //   return ICONS["selectedMenuArrow"];
        // } 
        else if (status === false) {
            return ICONS["disableTick"];
        }
        else if (selected) return ICONS["selectedMenuArrow"];
        else return ICONS["disableTick"];
    };

    return (
        <div>
            <Grid
                container
                xs={12}
                md={12}
                spacing={2}
                style={{ pointerEvents: "none" }}
                justifyContent="center"
                alignItems="center"
                marginTop="-5px"
            >
                {/* <Grid item xs={0.5} md={1}></Grid> */}
                <Grid item xs={10} md={10.5}>
                    <div className={classes.listItemText}>
                        <div>{name}</div>
                    </div>
                </Grid>
                <Grid item xs={1} md={1}></Grid>
                <Grid item xs={1} md={0.5}>
                    <div style={{ paddingLeft: "1.7rem" }}>{renderIcon()}</div>
                </Grid>
            </Grid>
        </div>
    );
}

export default ActionListItem;
