import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ReactApexChart from "react-apexcharts";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles({
  container: {
    position: "relative",
    marginTop: "30px",
  },
  rightContainer: {
    position: "absolute",
    top: 15,
    right: 0,
    backgroundColor: "#fff",
    justifyContent: "center",
    height: 120,
    alignItems: "center",
    marginRight: "20px",
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
  },
  numberText: {
    fontSize: "24px",
    color: "#113741",
  },
  text: {
    fontSize: "16px",
    color: "#65686D",
  },
});

const SemiDonut = ({ chartData, chartColors, total, registrationCount, testTakersCount }) => {
  const classes = useStyle();

  const chartLabels = chartData.map((i) => i.label);

  const chartSeries = chartData.map((i) => i.value);

  const chartOptions = {
    colors: chartColors,
    labels: chartLabels,
    chart: {
      width: 150,
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        offsetY: -10,
        donut: {
          size: "75%",
          offsetY: -5,
          labels: {
            show: true,
            name: {
              offsetY: -5,
            },
            total: {
              show: true,
              fontSize: 20,
              color: "#65686D",
              formatter: function(w) {
                return total;
              },
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      padding: {
        bottom: -100,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 150,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <Box className={classes.container}>
      <Box marginLeft={-10}>
        <ReactApexChart
          type="donut"
          series={chartSeries}
          options={chartOptions}
          height={180}
        />
      </Box>
      <Box className={classes.rightContainer}>
        <Box marginBottom={2}>
          <Box className={classes.textContainer}>
            <Box
              sx={{
                width: "16px",
                height: "16px",
                borderRadius: "50%",
                backgroundColor: "#22D1EE",
                marginRight: "3px",
              }}
            ></Box>
            <Typography className={classes.numberText} fontWeight={"600"}>
              {registrationCount}
            </Typography>
          </Box>
          <Typography
            className={classes.text}
            fontWeight={"400"}
            fontFamily={"Montserrat"}
          >
            Registrations
          </Typography>
        </Box>
        <Box>
          <Box className={classes.textContainer}>
            <Box
              sx={{
                width: "16px",
                height: "16px",
                borderRadius: "50%",
                backgroundColor: "#5842F6",
                marginRight: "3px",
              }}
            ></Box>
            <Typography className={classes.numberText} fontWeight={"600"}>
              {testTakersCount}
            </Typography>
          </Box>
          <Typography
            className={classes.text}
            fontWeight={"400"}
            fontFamily={"Montserrat"}
          >
            Test takers
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SemiDonut;
