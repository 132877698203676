export const cardDummyData = [
  {
    university_name:
      "Rotterdam School of Management, Erasmus University in NewYork, America",
    degree: "CS",
    program_name: "Solving an issue",
    region: "Origin",
    completed: true,
    view_data: true,
    recommended: true,
    completed: true,
  },
  {
    university_name: "Rotterdam School of Management, Erasmus University",
    degree: "Computer Science",
    program_name: "Science is so beautiful",
    region: "Origin",
    completed: false,
    view_data: false,
    recommended: false,
    completed: false,
  },
  {
    university_name: "Rotterdam School of Management, Erasmus University",
    degree: "Master of Computer Application",
    program_name: "Source of th Science",
    region: "History",
    completed: true,
    view_data: false,
    recommended: false,
    completed: false,
  },
];
export const preferenceListHeader = [
  {
    headerLabel: "University Name",
  },
  {
    headerLabel: "Program Name",
  },
  {
    headerLabel: "Region",
  },
  {
    headerLabel: "Action",
  },
];
export const tabList = [
  {
    name: "Preferences 1",
    label: "Preferences 1",
  },
  {
    name: "Preferences 2",
    label: "Preferences 2",
  },
  {
    name: "Preferences 3",
    label: "Preferences 3",
  },
];
export const preferenceTableDummyData = [
  {
    category: "Technical",
    schoolName: "Carnegie Mellon University",
    programName: "M.S in Computer Science",
    region: "Pittsburgh, United States",
    added: true,
  },
  {
    category: "Technical",
    schoolName: "Carnegie Mellon University",
    programName: "M.S in Computer Science",
    region: "Pittsburgh, United States",
    added: true,
  },
  {
    category: "Technical",
    schoolName: "Carnegie Mellon University",
    programName: "M.S in Computer Science",
    region: "Pittsburgh, United States",
    added: false,
  },
  {
    category: "Technical",
    schoolName: "Carnegie Mellon University",
    programName: "M.S in Computer Science",
    region: "Pittsburgh, United States",
    added: false,
  },
  {
    category: "Technical",
    schoolName: "Carnegie Mellon University",
    programName: "M.S in Computer Science",
    region: "Pittsburgh, United States",
    added: false,
  },
  {
    category: "Technical",
    schoolName: "Carnegie Mellon University",
    programName: "M.S in Computer Science",
    region: "Pittsburgh, United States",
    added: false,
  },
  {
    category: "Technical",
    schoolName: "Carnegie Mellon University",
    programName: "M.S in Computer Science",
    region: "Pittsburgh, United States",
    added: false,
  },
  {
    category: "Technical",
    schoolName: "Carnegie Mellon University",
    programName: "M.S in Computer Science",
    region: "Pittsburgh, United States",
    added: false,
  },
  {
    category: "Technical",
    schoolName: "Carnegie Mellon University",
    programName: "M.S in Computer Science",
    region: "Pittsburgh, United States",
    added: false,
  },
  {
    category: "Technical",
    schoolName: "Carnegie Mellon University",
    programName: "M.S in Computer Science",
    region: "Pittsburgh, United States",
    added: false,
  },
  {
    category: "Technical",
    schoolName: "Carnegie Mellon University",
    programName: "M.S in Computer Science",
    region: "Pittsburgh, United States",
    added: false,
  },
  {
    category: "Technical",
    schoolName: "Carnegie Mellon University",
    programName: "M.S in Computer Science",
    region: "Pittsburgh, United States",
    added: false,
  },
  {
    category: "Technical",
    schoolName: "Carnegie Mellon University",
    programName: "M.S in Computer Science",
    region: "Pittsburgh, United States",
    added: false,
  },
  {
    category: "Technical",
    schoolName: "Carnegie Mellon University",
    programName: "M.S in Computer Science",
    region: "Pittsburgh, United States",
    added: false,
  },
  {
    category: "Technical",
    schoolName: "Carnegie Mellon University",
    programName: "M.S in Computer Science",
    region: "Pittsburgh, United States",
    added: false,
  },
  {
    category: "Technical",
    schoolName: "Carnegie Mellon University",
    programName: "M.S in Computer Science",
    region: "Pittsburgh, United States",
    added: false,
  },
  {
    category: "Technical",
    schoolName: "Carnegie Mellon University",
    programName: "M.S in Computer Science",
    region: "Pittsburgh, United States",
    added: false,
  },
  {
    category: "Technical",
    schoolName: "Carnegie Mellon University",
    programName: "M.S in Computer Science",
    region: "Pittsburgh, United States",
    added: false,
  },
  {
    category: "Technical",
    schoolName: "Carnegie Mellon University",
    programName: "M.S in Computer Science",
    region: "Pittsburgh, United States",
    added: false,
  },
  {
    category: "Technical",
    schoolName: "Carnegie Mellon University",
    programName: "M.S in Computer Science",
    region: "Pittsburgh, United States",
    added: false,
  },
  {
    category: "Technical",
    schoolName: "Carnegie Mellon University",
    programName: "M.S in Computer Science",
    region: "Pittsburgh, United States",
    added: false,
  },
  {
    category: "Technical",
    schoolName: "Carnegie Mellon University",
    programName: "M.S in Computer Science",
    region: "Pittsburgh, United States",
    added: false,
  },
  {
    category: "Technical",
    schoolName: "Carnegie Mellon University",
    programName: "M.S in Computer Science",
    region: "Pittsburgh, United States",
    added: false,
  },
  {
    category: "Technical",
    schoolName: "Carnegie Mellon University",
    programName: "M.S in Computer Science",
    region: "Pittsburgh, United States",
    added: false,
  },
];

export const tabListForQPMC2 = [
  {
    name: "Version 1",
    id: "Version 1",
    value: "Version 1",
  },
  {
    name: "Version 2",
    id: "Version 2",
    value: "Version 2",
  },
  {
    name: "Version 3",
    id: "Version 3",
    value: "Version 3",
  },
];
export const tabListForQPMC1 = [
  {
    name: "Version 1",
    id: "Version 1",
    value: "Version 1",
  },
  {
    name: "Version 2",
    id: "Version 2",
    value: "Version 2",
  },
];
