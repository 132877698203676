import axios from "axios";
import { errorHandler } from "../Component/Utils/Helpers";
import { PROFILE_MENTORING } from "../Redux/Action";
import { URL } from "./URL";

export const clearData = () => {
  return (dispatch) => {
    dispatch({ type: PROFILE_MENTORING.clearData });
  };
};

export const clearCustomData = (fieldName) => {
  return (dispatch) => {
    dispatch({
      type: PROFILE_MENTORING.clearCustomData,
      fieldName: fieldName,
    });
  };
};

export const getDocumentModelBySubStageId = (
  studentId,
  productId,
  subStageId
) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      dispatch({ type: PROFILE_MENTORING.loader });
      await axios
        .get(
          `${URL}/api/v1/students/${studentId}/products/${productId}/subStages/${subStageId}/cvList`,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          window.sessionStorage.setItem("productId", productId);
          window.sessionStorage.setItem("studentId", studentId);
          window.sessionStorage.setItem("stageId", subStageId);
          dispatch({
            type: PROFILE_MENTORING.getDocumentModelBySubStageId,
            payload: response.data,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(
        errorHandler(
          PROFILE_MENTORING.getDocumentModelBySubStageId,
          error,
          false
        )
      );
    }
  };
};

export const getQPMC2ReportCall = (
  studentId,
  productId,
  stageId,
  callback = () => {}
) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      dispatch({ type: PROFILE_MENTORING.loader });
      await axios
        .get(
          `${URL}/api/v1/students/${studentId}/products/${productId}/stages/${stageId}/acsfiles`,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          callback(response);
          dispatch({
            type: PROFILE_MENTORING.getQPMC2Report,
            payload: response.data,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(PROFILE_MENTORING.getQPMC2Report, error, false));
    }
  };
};
export const getEnableCall = (studentId, productId, enableStage) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      await axios
        .get(
          `${URL}/api/v1/student/${studentId}/product/${productId}/${enableStage}`,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          dispatch({
            type: PROFILE_MENTORING.enableStage,
            payload: response.data,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(PROFILE_MENTORING.enableStage, error, false));
    }
  };
};
export const getDisableButtonCall = (studentId, productId) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      await axios
        .get(
          `${URL}/api/v1/student/${studentId}/product/${productId}/buttonStatus`,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: PROFILE_MENTORING.disableStage,
            payload: response.data,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(PROFILE_MENTORING.disableStage, error, false));
    }
  };
};

export const uploadFile = (studentId, productId, data, comment) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      dispatch({ type: PROFILE_MENTORING.loader });
      await axios
        .post(`${URL}/api/v1/cv/${studentId}/${productId}`, data, {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            comment: comment,
            stage: "Profile Mentoring",
          },
        })
        .then((response) => {
          dispatch({
            type: PROFILE_MENTORING.postFileUpload,
            payload: { success: true, data: response.data },
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(PROFILE_MENTORING.postFileUpload, error, false));
    }
  };
};
export const updateMOMpoints = (studentId, productId, stageId, data) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      dispatch({ type: PROFILE_MENTORING.loader });
      await axios
        .post(
          `${URL}/api/v1/students/${studentId}/products/${productId}/stages/${stageId}/mompoints`,
          data,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: PROFILE_MENTORING.postFileUpload,
            payload: { success: true, data: response.data },
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(PROFILE_MENTORING.postFileUpload, error, false));
    }
  };
};
export const uploadFileForReport = (
  studentId,
  productId,
  stageId,
  data,
  comment
) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  console.log(
    studentId,
    productId,
    data,
    comment,
    stageId,
    "*************sectionId"
  );
  return async (dispatch) => {
    try {
      await axios
        .post(
          `${URL}/api/v1/students/${studentId}/products/${productId}/stages/${stageId}/acsfilesupload`,
          data,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: PROFILE_MENTORING.postFileUpload,
            payload: { success: true, data: response.data },
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(PROFILE_MENTORING.postFileUpload, error, false));
    }
  };
};

export const uploadDocumentBySubStageId = (
  studentId,
  productId,
  subStageId,
  data
) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      dispatch({ type: PROFILE_MENTORING.loader });
      await axios
        .put(
          `${URL}/api/v1/students/${studentId}/products/${productId}/subStages/${subStageId}`,
          data,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: PROFILE_MENTORING.putDocumentBySubStageId,
            payload: response.data,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(
        errorHandler(PROFILE_MENTORING.putDocumentBySubStageId, error, false)
      );
    }
  };
};
export const qpmcStageComplete = (studentId, productId, stageName) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      await axios
        .put(
          `${URL}/api/v1/students/${studentId}/product/${productId}/opsComplete?stage=${stageName}`,
          null,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: PROFILE_MENTORING.qpmcStageComplete,
            payload: response.data,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(PROFILE_MENTORING.qpmcStageComplete, error, false));
    }
  };
};
export const getDownloadByDocumentForCv = (
  studentId,
  productId,
  cvId,
  path
) => {
  // let studentId = window.sessionStorage.getItem("studentId");
  let accessToken = window.sessionStorage.getItem("accessToken");
  console.log(cvId, path, studentId, "***************************hivijay");
  return (dispatch) => {
    // dispatch({ type: PROFILE_MENTORING.loader });
    axios
      .get(
        `${URL}/api/v1/cv/students/${studentId}/product/${productId}/downloadcv/${cvId}`,
        {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: "blob",
        }
      )
      .then((result) => {
        var filename = path;

        const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `${filename}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        dispatch({
          type: PROFILE_MENTORING.downloadDocument,
          payload: { success: true, data: result.data, fileName: filename },
          loading: false,
        });
      })
      .catch((error) => {
        dispatch(
          errorHandler(PROFILE_MENTORING.downloadDocument, error, false)
        );
      });
  };
};
export const getDownloadByDocumentId = (studentId, cvId, path) => {
  // let studentId = window.sessionStorage.getItem("studentId");
  let accessToken = window.sessionStorage.getItem("accessToken");
  console.log(cvId, path, studentId, "***************************hi id");
  return (dispatch) => {
    // dispatch({ type: PROFILE_MENTORING.loader });
    axios
      .get(`${URL}/api/v1/files/students/${studentId}/cv/${cvId}`, {
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: "blob",
      })
      .then((result) => {
        var filename = path.replace(".pdf", ".docx");

        const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `${filename}.docx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        dispatch({
          type: PROFILE_MENTORING.downloadDocument,
          payload: { success: true, data: result.data, fileName: filename },
          loading: false,
        });
      })
      .catch((error) => {
        dispatch(
          errorHandler(PROFILE_MENTORING.downloadDocument, error, false)
        );
      });
  };
};
export const getDownloadByDocumentIdForReport = (
  studentId,
  subStageId,
  fileName,
  id
) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      dispatch({ type: PROFILE_MENTORING.loader });
      await axios
        .get(
          `${URL}/api/v1/students/${studentId}/subStage/${subStageId}/${id}`,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
            responseType: "blob",
          }
        )
        .then((response) => {
          dispatch({
            type: PROFILE_MENTORING.getDownloadByDocumentId,
            payload: { success: true, data: response.data, fileName: fileName },
            loading: false,
          });
        });
    } catch (error) {
      dispatch(
        errorHandler(PROFILE_MENTORING.getDownloadByDocumentId, error, false)
      );
    }
  };
};
export const getDownloadByDocumentIdForSchoolResearch = (
  studentId,
  productId,
  subStageId,
  fileName,
  id
) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      dispatch({ type: PROFILE_MENTORING.loader });
      await axios
        .get(
          `${URL}/api/v1/students/${studentId}/products/${productId}/subStage/${subStageId}/${id}`,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
            responseType: "blob",
          }
        )
        .then((response) => {
          dispatch({
            type: PROFILE_MENTORING.getDownloadByDocumentId,
            payload: { success: true, data: response.data, fileName: fileName },
            loading: false,
          });
        });
    } catch (error) {
      dispatch(
        errorHandler(PROFILE_MENTORING.getDownloadByDocumentId, error, false)
      );
    }
  };
};

export const deleteDocumentByDocumentId = (studentId, documentId) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      dispatch({ type: PROFILE_MENTORING.loader });
      await axios
        .delete(`${URL}/api/v1/students/${studentId}/documents/${documentId}`, {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          dispatch({
            type: PROFILE_MENTORING.deleteDocumentByDocumentId,
            payload: response.data,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(
        errorHandler(PROFILE_MENTORING.deleteDocumentByDocumentId, error, false)
      );
    }
  };
};
export const getAdditionalPoints = (studentId, productId, versionNo) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return async (dispatch) => {
    try {
      await axios
        .get(
          `${URL}/api/v1/students/${studentId}/product/${productId}/pgaReport/additionalPoint?${versionNo}`,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: PROFILE_MENTORING.getPoints,
            payload: response.data,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(PROFILE_MENTORING.getPoints, error, false));
    }
  };
};
export const getMOMPoints = (studentId, productId, stageId) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return async (dispatch) => {
    try {
      await axios
        .get(
          `${URL}/api/v1/students/${studentId}/products/${productId}/stages/${stageId}/mompoints`,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: PROFILE_MENTORING.momPoints,
            payload: response.data,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(PROFILE_MENTORING.momPoints, error, false));
    }
  };
};
export const getEnablePostAdmitServices = (studentId, productId, stageId) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      dispatch({ type: PROFILE_MENTORING.loader });
      await axios
        .get(
          `${URL}/api/v1/students/${studentId}/products/${productId}/postadmitservices/${stageId}?isStarted=true&isEnded=`,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: PROFILE_MENTORING.PostAdmitServicesStatus,
            payload: response.data,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(
        errorHandler(PROFILE_MENTORING.PostAdmitServicesStatus, error, false)
      );
    }
  };
};
export const getClosePostAdmitServices = (studentId, productId, stageId) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      dispatch({ type: PROFILE_MENTORING.loader });
      await axios
        .get(
          `${URL}/api/v1/students/${studentId}/products/${productId}/postadmitservices/${stageId}?isStarted=&isEnded=true`,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: PROFILE_MENTORING.PostAdmitServicesStatus,
            payload: response.data,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(
        errorHandler(PROFILE_MENTORING.PostAdmitServicesStatus, error, false)
      );
    }
  };
};
