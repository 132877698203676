import MomentUtils from '@date-io/moment';
import { Grid } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import EventIcon from '@material-ui/icons/Event';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { ibhSpacePath } from '../../RoutePaths';
import ConfirmDialog from '../../Utils/ConfirmDialog';
import Controls from '../../Utils/controls/Controls';
import Notification from '../../Utils/Notification';
import { ButtonsContainer, CreatePostContainer } from '../Assets/Styles/CreatePostStyles';
import BackHandler from '../Components/BackHandler';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {
  createWebinar,
  updateWebinar,
  getIbhRounds,
  getWebinarById,
  clearData,
} from '../../../Actions/IbhAction';

const AVOID_INPUT = ['/', '\\', '?', '%', '*'];

const useStyles = makeStyles({
  root: {
    '& .MuiSelect-root': {
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '4px',
      padding: '1rem',
    },
  },
  captionStyle: {
    width: '100%',
    marginTop: 15,
    marginBottom: 15,
  },
  spacer: {
    width: '100%',
    marginTop: '10px',
  },
  btnContainer: {
    justifyContent: 'flex-end !important',
    width: '100%',
    gap: '30px',
  },
});

const CreateIbhWebinar = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const { loading, getIbhRoundList, createStatus, updateStatus, webinarData } = useSelector(
    (state) => state.ibhReducer
  );

  const isEdit = params?.id;
  const [state, setState] = useState({
    id: params?.id || null,
    ibhRoundLists: null,
    webinarTitle: '',
    description: '',
    webinarLink: '',
    eventDate: new Date(),
    eventEndDate: new Date(),
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  useEffect(() => {
    dispatch(getIbhRounds(true));
    if (params?.id) {
      dispatch(getWebinarById(params?.id));
    }
    // Clearing all the data in the reducer while left from that page like ComponentWillUnmount.
    return () => {
      dispatch(clearData());
    };
  }, []);

  // Get webinar data by ID
  useEffect(() => {
    if (webinarData) {
      if (webinarData.success) {
        const { data } = webinarData;
        setState({
          ...state,
          ibhRoundLists: data?.ibhRound,
          webinarTitle: data?.eventTitle,
          description: data?.description,
          webinarLink: data?.zoomLink,
          eventDate: data?.eventDate ? new Date(data?.eventDate) : new Date(),
          eventEndDate: data?.eventEndDate ? new Date(data?.eventEndDate) : new Date(),
        });
      } else {
        setNotify({
          isOpen: true,
          message: webinarData.message,
          type: 'error',
        });
      }
    }
  }, [webinarData]);

  // Create Webinar
  useEffect(() => {
    if (createStatus) {
      if (createStatus.success) {
        setNotify({
          isOpen: true,
          message: 'Created Successfully',
          type: 'success',
        });
        setTimeout(() => {
          history.push({
            pathname: ibhSpacePath,
            tab: 1,
          });
        }, 1200);
      } else {
        setNotify({
          isOpen: true,
          message: createStatus.message,
          type: 'error',
        });
      }
    }
  }, [createStatus]);

  // Update Webinar
  useEffect(() => {
    if (updateStatus) {
      if (updateStatus.success) {
        setNotify({
          isOpen: true,
          message: 'Updated Successfully',
          type: 'success',
        });
        setTimeout(() => {
          history.push({
            pathname: ibhSpacePath,
            tab: 1,
          });
        }, 1200);
      } else {
        setNotify({
          isOpen: true,
          message: updateStatus.message,
          type: 'error',
        });
      }
    }
  }, [updateStatus]);

  const onDiscard = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    setTimeout(() => {
      history.push({
        pathname: ibhSpacePath,
        tab: 1,
      });
    }, 1200);
    setNotify({
      isOpen: true,
      message: 'Discarded',
      type: 'warning',
    });
  };

  const handleSchedule = (values) => {
    let payload = {
      id: values.id,
      ibhRound: values.ibhRoundLists,
      eventTitle: values.webinarTitle,
      description: values.description,
      zoomLink: values.webinarLink,
      eventDate: values.eventDate,
      eventEndDate: values.eventEndDate,
      activeStatus: 'Scheduled',
    };

    if (isEdit) {
      dispatch(updateWebinar(payload));
    } else {
      dispatch(createWebinar(payload));
    }
  };

  const validate = (values) => {
    /* Validating the timings */
    if (
      moment(values.eventEndDate).isSameOrBefore(values.eventDate) ||
      moment(values.eventDate).isBefore(moment()) ||
      moment(values.eventEndDate).isBefore(moment())
    ) {
      setNotify({
        isOpen: true,
        message: 'Please add proper timing & date',
        type: 'error',
      });
      return false;
    }
    return true;
  };

  //Max 30 words limit for description
  const getWordCount = (str) => {
    return str?.trim().split(/\s+/).length || 0;
  };

  const validationSchema = yup.object({
    ibhRoundLists: yup
      .object()
      .nullable()
      .required('Round is required'),
    webinarTitle: yup.string().required('Webinar Title is required'),
    description: yup
      .string()
      .required('Description is required')
      .test(
        'is-valid-word-count',
        "It shouldn't exceed more than 30 word Limit",
        (value) => getWordCount(value) <= 30
      ),
    webinarLink: yup
      .string()
      .url()
      .required('Webinar link is required'),
  });

  return (
    <>
      <BackHandler title={`${isEdit ? 'Edit' : 'Create'} IBH Webinar`} tab={1} isIbh={true} />
      <CreatePostContainer>
        <Formik
          initialValues={state}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            if (validate(values)) {
              handleSchedule(values);
              resetForm();
            }
          }}
          enableReinitialize
        >
          {({ handleSubmit, errors, handleChange, values, touched, setFieldValue, submitForm }) => {
            return (
              <>
                <div className='CreatePost'>
                  <Form onSubmit={handleSubmit} autoComplete='off'>
                    <Grid container spacing={1} direction='column'>
                      <Grid
                        container
                        direction='row'
                        justify='space-between'
                        className={classes.spacer}
                      >
                        <Grid item>
                          <h6 style={{ fontSize: '1rem' }}>{'Rounds'}</h6>
                          <FormControl className={classes.root} style={{ width: '500px' }}>
                            <Autocomplete
                              id='ibhRoundLists'
                              name='ibhRoundLists'
                              getOptionLabel={(option) => option?.name}
                              options={getIbhRoundList ?? []}
                              disabled={state.ibhRoundLists && state.ibhRoundLists !== null}
                              onChange={(e, value) => {
                                setFieldValue('ibhRoundLists', value);
                              }}
                              fullWidth
                              value={values.ibhRoundLists}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label='Select a Round'
                                  name='ibhRoundLists'
                                  variant='outlined'
                                  error={
                                    touched.ibhRoundLists && Boolean(values.ibhRoundLists === null)
                                  }
                                />
                              )}
                              style={{
                                marginTop: '10px',
                                marginBottom: '10px',
                              }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item>
                          <h6 style={{ fontSize: '1rem' }}>{'Webinar Title'}</h6>
                          <Controls.Input
                            label={'Enter webinar title (Avoid using these /, \\ , ?, %, *)'}
                            name={'webinarTitle'}
                            error={touched.webinarTitle && Boolean(errors.webinarTitle)}
                            helperText={touched.webinarTitle && errors.webinarTitle}
                            style={{ width: '500px', margin: '10px 0px' }}
                            value={values.webinarTitle}
                            onChange={handleChange}
                            onKeyDown={(evt) => {
                              AVOID_INPUT.includes(evt.key) && evt.preventDefault();
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Grid item>
                        <h6 style={{ fontSize: '1rem', marginTop: '10px' }}>{'Add Description'}</h6>
                        <Controls.Input
                          label={'Type description here...'}
                          value={values.description}
                          name={'description'}
                          onChange={handleChange}
                          error={touched.description && Boolean(errors.description)}
                          helperText={`* (Maximum word limit is 30)`}
                          multiline
                          className={classes.captionStyle}
                          rows={2}
                        />
                      </Grid>

                      <Grid item>
                        <h6 style={{ fontSize: '1rem' }}>{'Webinar Link'}</h6>
                        <Controls.Input
                          label='Webinar Link'
                          name='webinarLink'
                          error={touched.webinarLink && Boolean(errors.webinarLink)}
                          helperText={touched.webinarLink && errors.webinarLink}
                          className={classes.captionStyle}
                          value={values.webinarLink}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid
                        container
                        direction='row'
                        justify='space-between'
                        className={classes.spacer}
                      >
                        <Grid item lg={12}>
                          <h6 style={{ fontSize: '1rem' }}>{'Schedule Details'}</h6>
                        </Grid>
                        <Grid item>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DateTimePicker
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position='start'>
                                    <EventIcon />
                                  </InputAdornment>
                                ),
                              }}
                              value={values.eventDate}
                              style={{ width: '500px', margin: '10px 0px' }}
                              disablePast
                              name={'eventDate'}
                              label={'Start Data & Time'}
                              inputVariant='outlined'
                              onChange={(val) => {
                                setFieldValue('eventDate', val);
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DateTimePicker
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position={'start'}>
                                    <EventIcon />
                                  </InputAdornment>
                                ),
                              }}
                              value={values.eventEndDate}
                              style={{ width: '500px', margin: '10px 0px' }}
                              disablePast
                              name={'eventEndDate'}
                              label={'End Data & Time'}
                              inputVariant={'outlined'}
                              onChange={(val) => {
                                setFieldValue('eventEndDate', val);
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                </div>
                <ButtonsContainer className={classes.btnContainer}>
                  <Controls.Button
                    text={`Discard`}
                    variant='contained'
                    color='primary'
                    style={{ borderRadius: '26px', marginRight: '10px' }}
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: 'Are you sure to discard this webinar?',
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDiscard();
                        },
                      });
                    }}
                  />
                  <Controls.Button
                    text={`Submit`}
                    variant='contained'
                    color='primary'
                    style={{ borderRadius: '26px' }}
                    type={'submit'}
                    onClick={submitForm}
                    loading={loading}
                  />
                </ButtonsContainer>
              </>
            );
          }}
        </Formik>
      </CreatePostContainer>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
};

export default CreateIbhWebinar;
