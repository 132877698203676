import {
    Backdrop,
    Box,
    Divider,
    Grid,
    TextField,
    Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Typo } from "../../Utils/controls/Styles";
import BottomContainer from "../../PgaReport/BottomContainer";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import { useStyles } from "./Styles";
import { PageWrapper } from "../../PgaReport/Components/StyledComponents";
import {
    getMomPoints,
    savePoints,
} from "../../../Actions/PostAdmitServices";
import Alert from "@material-ui/lab/Alert";
import CustomizedSnackBars from "../CustomizedSnackBars";
import MySnackBar from "../../MySnackBar";

function MomPoints(props) {
    console.log(props, "**************************hi");
    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const { studentId, productId } = params;
    console.log(studentId, productId, "**********************student");
    const [state, setState] = useState({
        activeTabValue: null,
        open: false,
        comment: null,
        snackMessage: "",
        snackOpen: false,
        snackVariant: "",
        status: null,
        anchorEl: null,
        popoverComment: null,
        momQPMC1: null,
        momQPMC2: null,
    });
    const {
        activeTabValue,
        snackMsg,
        snackOpen,
        snackVariant,
        anchorEl,
        popoverComment,
        momQPMC1,
        momQPMC2,
    } = state;
    console.log(
        props.activeTabValue, props.activeStateValue,
        "***********************activeTabValue****************"
    );
    const { momPoints } = useSelector((state) => state.PostAdmitServicesReducer);
    console.log(momPoints, "*******MOMpoints******");

    const handleSave = () => {
        // if (props.activeTabValue === "Pre Strategy call") {
        if (momQPMC1 && momQPMC1.trim().length !== 0) {
            if (momQPMC1) {
                let obj = {
                    momPoints: momQPMC1,
                };

                dispatch(
                    savePoints(studentId, productId, props?.activeStateValue, obj)
                );
                setState({
                    snackMsg: "Saved Successfully",
                    snackOpen: true,
                    snackVariant: "success",
                });
            }
        }
        // else {
        //     setState({
        //         ...state,
        //         snackMsg: "Please fill the required field",
        //         snackOpen: true,
        //         snackVariant: "error",
        //     });
        // }
    }
    // } else if (props.activeTabValue === "Strategy Session call") {
    //     if (momQPMC2 && momQPMC2.trim().length !== 0) {
    //         if (momQPMC2) {
    //             let obj = {
    //                 momPoints: momQPMC2,
    //             };
    //             dispatch(
    //                 updateMOMpoints(studentId, productId, props?.activeStateValue, obj)
    //             );
    //             setState({
    //                 snackMsg: "Saved Successfully",
    //                 snackOpen: true,
    //                 snackVariant: "success",
    //             });
    //         }
    //     } else {
    //         setState({
    //             ...state,
    //             snackMsg: "Please fill the required field",
    //             snackOpen: true,
    //             snackVariant: "error",
    //         });
    //     }
    // }
    // };
    // console.log(momQPMC1);
    useEffect(() => {
        dispatch(getMomPoints(studentId, productId, props?.activeStateValue));
    }, [props.activeStateValue]);
    useEffect(() => {
        if (momPoints?.success) {
            console.log(momQPMC1, "&&&&&&");
            setState({
                ...state,
                momQPMC1: momPoints?.data?.momPoints,
                // momQPMC2: getMomPoints?.data?.momPoints,
            });
        }
    }, [momPoints]);
    const handleSnackClose = () => {
        setState({
            ...state,
            snackOpen: false,
            snackMsg: "",
            snackVariant: "",
        });
    };
    useEffect(() => {
        if (props?.activeTabValue) {
            console.log(momQPMC1, "&&&&&&");
            setState({
                ...state,
                momQPMC1: "",
                // momQPMC2: "",
            });
        }
    }, [props?.activeTabValue]);
    return (
        <PageWrapper>
            {/* {props.activeTabValue === "Pre Strategy call" && ( */}
            <Grid container xs={12} className={classes.mainGrid}>
                <Grid item xs={12}>
                    <div className={classes.heading}>Feedback Session- MOM</div>
                    <Grid item xs={12} className={classes.textField}>
                        <TextField
                            value={state.momQPMC1}
                            onChange={(e) =>
                                setState({
                                    ...state,
                                    momQPMC1: e.target.value,
                                })
                            }
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            {/* )} */}
            {/* {props.activeTabValue === "Strategy Session call" && (
                <Grid container className={classes.mainGrid}>
                    <Grid item xs={12}>
                        <div className={classes.heading}>Strategy Session- MOM</div>
                        <Grid item xs={12} className={classes.textField}>
                            <TextField
                                value={state.momQPMC2}
                                onChange={(e) =>
                                    setState({
                                        ...state,
                                        momQPMC2: e.target.value,
                                    })
                                }
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )} */}

            <BottomContainer onClick={handleSave} />
            <MySnackBar
                onClose={handleSnackClose}
                snackOpen={snackOpen}
                snackVariant={snackVariant}
                snackMsg={snackMsg}
            />
        </PageWrapper>
    );
}
export default MomPoints;  