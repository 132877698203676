import { createTheme, Grid, Slide, ThemeProvider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearData,
  getGradSchoolGmatList,
  getGradSchoolGreList,
  getGradSchoolIeltsList,
  getGradSchoolProgramListById,
  getGradSchoolRequirementsList,
  getGradSchoolToeflList,
  listOfGradSchoolProgram,
} from "../../Actions/GradSchoolAction";
import "../../Asset/All.css";
import { addGradSchoolProgram } from "../RoutePaths";
import TableComponent from "../TableComponent/TableComponent";
import { isEmptyString } from "../Validation";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function GradSchoolProgram(props) {
  const {
    getPerGradSchoolProgramList,
    getGradSchoolRequirementReducer,
  } = useSelector((state) => state.GradSchoolReducer);
  const [gradSchoolId, setGradSchoolId] = useState(null);
  const [gradSchoolPrograms, setGradSchoolPrograms] = useState([]);
  const [search, setSearch] = useState({
    size: 20,
    page: 0,
    keyword: "",
  });
  const dispatch = useDispatch();
  const tableTheme = () => {
    createTheme({
      palette: {
        primary: {
          main: "#007bff",
        },
      },
      overrides: {
        MuiTypography: {
          h6: {
            fontWeight: "bold",
          },
        },
        MuiIconButton: {
          root: {
            "&:hover": {
              backgroundColor: "none",
              borderRadius: 0,
            },
          },
        },
      },
    });
  };
  const col = [
    {
      title: "ID",
      fieldName: "id",
    },
    { title: "Program Name", fieldName: "programName" },
    { title: "Category", fieldName: "gradSchoolCategory" },
    { title: "Degree", fieldName: "degree" },
    { title: "Field Of Study", fieldName: "fieldOfStudy" },
    { title: "Created By", fieldName: "createdBy" },
    { title: "Created Date", fieldName: "createdDate" },
  ];

  const addProgram = () => {
    props.history.push({
      pathname: addGradSchoolProgram,
      state: { gradSchoolProgramId: gradSchoolId },
    });
    dispatch(clearData());
  };

  useEffect(() => {
    dispatch(listOfGradSchoolProgram(props.location.state.id, 0, 20, ""));
  }, []);

  const handleEditClick = (data) => {
    props.history.push({ pathname: addGradSchoolProgram, state: data });
    dispatch(getGradSchoolProgramListById(data.id));
    dispatch(getGradSchoolRequirementsList(data.id));
    dispatch(getGradSchoolGmatList(data.id));
    dispatch(getGradSchoolGreList(data.id));
    dispatch(getGradSchoolToeflList(data.id));
    dispatch(getGradSchoolIeltsList(data.id));
  };

  useEffect(() => {
    setGradSchoolId(props.location.state.id);
  }, []);

  useEffect(() => {
    setGradSchoolPrograms(getPerGradSchoolProgramList?.content);
  }, [getPerGradSchoolProgramList]);

  const paginate = (page, size, keyword) => {
    setSearch({ ...search, page: page, size: size, keyword: keyword });
    if (page !== search.page || size !== search.size) {
      dispatch(
        listOfGradSchoolProgram(props.location.state.id, page, size, keyword)
      );
    } else if (isEmptyString(keyword)) {
      dispatch(listOfGradSchoolProgram(props.location.state.id, 0, 20, ""));
    } else {
    }
  };

  const handleSearch = (e) => {
    dispatch(
      listOfGradSchoolProgram(
        props.location.state.id,
        search.page,
        search.size,
        search.keyword
      )
    );
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={tableTheme}>
        <div>
          <Grid container>
            <Grid item md={12}>
              <TableComponent
                cols={col}
                title={"Grad School Program"}
                action={true}
                onEdit={true}
                data={gradSchoolPrograms}
                onAddClick={addProgram}
                add={true}
                totalCount={
                  getPerGradSchoolProgramList &&
                  getPerGradSchoolProgramList.totalElements
                }
                onKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    document.getElementById("search").click();
                  }
                }}
                pageCount={
                  getPerGradSchoolProgramList &&
                  getPerGradSchoolProgramList.totalPages
                }
                onEditClick={handleEditClick}
                onSearchClick={(e) => handleSearch(e)}
                onAddBtnTitle={"Add Program"}
                onSearch={paginate}
                needSearch
                paginate={paginate}
                searchPlaceHolder="Program Name"
              />
            </Grid>
          </Grid>
        </div>
      </ThemeProvider>
    </React.Fragment>
  );
}
