import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Box, Divider, Grid } from "@material-ui/core";
import LatexViewer from "../../../../../Utils/LatexViewer";
import { Passage as passage } from "../../../../../Assets/css/Preview/TestComponent";
import { QuestionDiv } from "../../../../../Assets/css/Preview/GmatStyles";

export default function Binary(props) {
  const { testResponse } = props;
  // console.log(testResponse.data.choices, "asiffsio");
  const { Question } = passage;
  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    console.log(event.target.value);
    setValue(event.target.value);
  };
  const style = {
    show: {
      display: "block",
    },
    hide: {
      display: "none",
    },
  };
  const { hide, show } = style;
  return (
    <Grid container>
      <Grid
        item
        xs={6}
        style={testResponse.data.isHaveDescription === false ? hide : show}
        className="blue_border"
      >
        <Box display="flex" height="100%" justifyContent="space-between">
          <QuestionDiv>
            {testResponse.data.description !== "" && (
              <Box fontSize={16} color={"#052A4E"} lineHeight={"30px"}>
                <LatexViewer math={testResponse.data.description} />
              </Box>
            )}
            {testResponse.data.isHaveDescription === false ? (
              ""
            ) : (
              <Box>
                <Divider variant="middle" orientation={"vertical"} />
              </Box>
            )}
          </QuestionDiv>
        </Box>
      </Grid>
      <Grid item xs={testResponse.data.isHaveDescription === false ? 12 : 6}>
        <Box className={"overflow-scroll"} id="inside-test-container">
          <QuestionDiv>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                <Question style={{ marginBottom: "15px" }}>
                  <LatexViewer math={testResponse.data.question} />
                </Question>
                <Box display={"flex"}>
                  <Question>
                    <span>Yes &nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </Question>
                  <Question>
                    <span>No</span>
                  </Question>
                </Box>
              </FormLabel>
              {testResponse.data.choices.map((item) => {
                return (
                  <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                    <Grid item>
                      <RadioGroup
                        row
                        style={{ alignItems: "center" }}
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="row-radio-buttons-group"
                        value={item.correctChoice}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          style={{ marginBottom: "0px" }}
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          style={{ marginRight: "0px", marginBottom: "0px" }}
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs style={{ alignSelf: "center" }}>
                      <LatexViewer math={item.text} />
                    </Grid>
                  </Grid>
                );
              })}
            </FormControl>
          </QuestionDiv>
        </Box>
      </Grid>
    </Grid>
  );
}
