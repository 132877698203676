import React, { useEffect } from 'react'
import { Button, Card, CardContent, Grid, List } from '@mui/material'
import CustomButton from '../../../CommonComponents/CustomButton'
import { Typo } from '../../Utils/controls/Styles'
import { useStyles } from './Styles'
import { useHistory, useParams } from 'react-router-dom'
import { stagedTabsPath } from "../../RoutePaths";
import { getDetails } from '../../../Actions/PostAdmitServices'
import { useDispatch, useSelector } from 'react-redux'
import School from '../../../Asset/Images/School.png'
import ActionListItem from './ActionListItem'
import CustomizedButton from '../../../CommonComponents/CustomizedButton'
import { DocumentComponentForAdmitCard } from './DocumentComponent'


function DocumentCard({ uploadDocument }) {
    const classes = useStyles();
    const params = useParams();
    const { studentId, productId } = params;
    const history = useHistory();
    const { getCardDetails } = useSelector((state) => state.PostAdmitServicesReducer);

    const dispatch = useDispatch();
    const handleOpen = (id) => {
        // history.push("/open")
        history.push((
            `${stagedTabsPath}${studentId}/${productId}?stage=PostAdmitServices/openDocument/` + id
        ), {
            state: id
        });

        uploadDocument(true);
    }
    useEffect(() => {

        dispatch(getDetails(studentId, productId));

    }, [])
    console.log(getCardDetails, "bfbhfbibi")
    return (
        <>
            <div style={{ paddingBottom: "12px" }}>
                <Grid container spacing={3} p={"35px"}>
                    {getCardDetails?.data?.map((item, index) => (
                        <Grid item xs={4} md={4} key={index}>
                            <div className={classes.cardHeight}>
                                <Card key={index} variant="outlined">
                                    <Grid container xs={12} md={12} key={index} style={{ padding: "1rem" }}>
                                        <Grid item xs={3} md={3} style={{ padding: "0.5rem" }}>
                                            <div className={classes.schoolImage}>
                                                <img
                                                    src={School}
                                                    alt={"School"}
                                                    width="85px"
                                                    height="80px"
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={9} md={9}>
                                            <div className={classes.cardContentMargin}>
                                                <Grid container xs={12} md={12}>
                                                    {/* <div className={classes.schoolName}> */}
                                                    <Typo
                                                        fontSize="14px"
                                                        fontWeight={600}
                                                        fontFamily="Poppins, sans-serif !important"
                                                        noWrap
                                                    >
                                                        {item?.schoolInfo?.schoolName}
                                                    </Typo>
                                                    {/* </div> */}
                                                </Grid>
                                                <Grid container xs={12} md={12}>
                                                    <List>
                                                        {Array.isArray(item?.admitCardInfo) &&
                                                            item?.admitCardInfo.map(
                                                                ({ admitCardNames, status }, index) => {
                                                                    return (
                                                                        <ActionListItem
                                                                            key={index}
                                                                            name={admitCardNames}
                                                                            status={status}
                                                                            list={true}
                                                                        />
                                                                    );
                                                                }
                                                            )}
                                                    </List>
                                                </Grid>
                                                <Grid
                                                    container
                                                    xs={12}
                                                    md={12}
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <Grid item xs={12} md={12} style={{ paddingLeft: "1rem" }}>
                                                        <CustomButton
                                                            color={"primary"}
                                                            variant="outlined"
                                                            style={{ borderRadius: "21px", textTransform: "none", fontFamily: "Poppins, sans-serif !important" }}
                                                            // className={classes.rescheduleButton}
                                                            onClick={() => handleOpen(item?.schoolInfo?.studentPostAdmitSchoolId)}
                                                        >
                                                            Open Document
                                                        </CustomButton>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </div>

        </>
    )
}

export default DocumentCard
