import { Table, TableBody, TableRow } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { NAVIGATE_TO } from "../../../Component/RoutePaths";
import {
  BlueCell,
  BodyCell,
  Head,
  HeadCell,
  HeadInline,
} from "../../Assets/StyledTableComponents";

const columns = [
  "Video Name",
  "Concept name",
  "Topic name",
  "Task name",
  "Average Rating",
  "",
];

function TableComponent({ feedbackData }) {
  const history = useHistory();

  const handleView = (videoId) => {
    history.push(NAVIGATE_TO.overViewRatingPath(videoId));
  };

  return (
    <React.Fragment>
      <Table stickyHeader>
        <Head>
          <TableRow>
            {columns.map((item, index) => (
              <HeadCell className={index === 4 && "table_center_align"}>
                <HeadInline>{item}</HeadInline>
              </HeadCell>
            ))}
          </TableRow>
        </Head>
        <TableBody>
          {feedbackData.length !== 0 ? (
            feedbackData.map((item) => {
              return (
                <TableRow>
                  <BodyCell style={style.videoName}>{item.videoName}</BodyCell>
                  <BodyCell>{item.conceptName}</BodyCell>
                  <BlueCell>{item.topicName}</BlueCell>
                  <BodyCell>{item.taskType}</BodyCell>
                  <BodyCell className={"table_center_align"}>
                    {item.averageRating}
                  </BodyCell>
                  <BlueCell
                    className={"table_center_align"}
                    id={item.videoId}
                    onClick={() => handleView(item.videoId)}
                    style={style.pointer}
                  >
                    {"View Users"}
                  </BlueCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <BodyCell className={"table_center_align"} colSpan={6}>
                {"No result found"}
              </BodyCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
export default TableComponent;

const style = {
  videoName: {
    width: "150px",
    overflow: "hidden",
    position: "relative",
    display: "inline-block",
    textDecoration: "none",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  pointer: {
    cursor: "pointer",
  },
};
