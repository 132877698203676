import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@mui/material/Box';
import SensorsRoundedIcon from '@mui/icons-material/SensorsRounded';
import SemiDonut from './SemiDonut';
import DataTable from './DataTable';
import { liveDriveData } from '../../../Actions/DashboardAction';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Lms/Utils/Loader';
import DateRangePickerComp from './DateRangePicker';

const useStyle = makeStyles((theme) => ({
  paper: {
    boxShadow: '0px 16px 24px rgba(146, 170, 212, 0.12)',
    borderRadius: '8px',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  textWithIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  headerText: {
    fontSize: '18px',
    color: '#354354',
  },
  modalContainer: {
    textAlign: 'center',
    alignItems: 'center',
    width: '140px',
    position: 'absolute',
    right: 0,
    top: -50,
    backgroundColor: '#fefefe',
    borderRadius: '8px',
  },
  btnText: {
    height: '25px',
    width: '100%',
  },
  buttonContainer: {
    border: '1px solid #E1E5EB',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    color: '#354354',
    width: '100px',
    height: '35px',
    cursor: 'pointer',
  },
  icons: {
    fontSize: '6px',
    color: '#828D98',
  },
}));

const DashboardLiveDrive = () => {
  const classes = useStyle();
  const [startDate, setStartDate] = useState(new Date(new Date().setUTCHours(0, 0, 0)));
  const [endDate, setEndDate] = useState(new Date(new Date().setUTCHours(23, 59, 59)));
  const [value, setValue] = useState(false);

  const dispatch = useDispatch();
  const { loading, error, drives } = useSelector((state) => state.dashboardLiveDriveReducer);

  useEffect(() => {
    liveDriveCalling();
    setValue(false);
  }, [value]);

  let requestBody = {
    fromDate: startDate,
    toDate: endDate,
  };

  const liveDriveCalling = () => {
    dispatch(liveDriveData(requestBody));
  };

  return (
    <Grid item xs={6}>
      <Paper elevation={3} className={classes.paper}>
        <div style={{ padding: '20px' }}>
          <Box className={classes.headerContainer}>
            <Box className={classes.textWithIcon}>
              <SensorsRoundedIcon sx={{ color: '#3662B4', fontSize: '28px' }} />
              <Typography fontWeight={'500'} marginLeft={2} className={classes.headerText}>
                Drives
              </Typography>
            </Box>
            <Box>
              <DateRangePickerComp
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setValue={setValue}
              />
            </Box>
          </Box>
          {drives.data && (
            <>
              <SemiDonut
                total={drives?.data?.liveDriveCount}
                chartColors={['#22D1EE', '#5842F6']}
                chartData={[
                  {
                    label: 'Registrations',
                    value: drives?.data?.totalRegistration,
                  },
                  {
                    label: 'Test takers',
                    value: drives?.data?.totalTestTakers,
                  },
                ]}
                registrationCount={drives?.data?.totalRegistration}
                testTakersCount={drives?.data?.totalTestTakers}
              />
            </>
          )}
          <div style={{ margin: '2rem auto', width: '60%' }}>
            {loading && <Loader />}
            {error && <Alert severity='info'>0 Tests Found</Alert>}
          </div>
        </div>
        <DataTable
          marginTop={'-60px'}
          sorting={false}
          maxBodyHeight={440}
          minBodyHeight={440}
          overflowWrap='break-word'
          columns={[
            {
              title: 'Drive Name',
              field: 'driveName',
              cellStyle: {
                minWidth: 200,
                maxWidth: 200,
              },
              align: 'left',
            },
            {
              title: 'Registrations',
              field: 'registrations',
              type: 'numeric',
              align: 'center',
              width: '30%',
            },
            {
              title: 'Test Takers',
              field: 'testTakers',
              type: 'numeric',
              align: 'center',
              width: '30%',
            },
          ]}
          data={drives?.data?.driveDetails}
        />
      </Paper>
    </Grid>
  );
};

export default DashboardLiveDrive;
