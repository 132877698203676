import { Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { GSContainer, useStyles } from "../../Asset/StyledComponents/Styles";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  clearData,
  createGradSchoolToeflList,
  getGradSchoolToeflList,
} from "../../Actions/GradSchoolAction";
import MySnackBar from "../MySnackBar";
export default function GradSchoolToefl(props) {
  const { getGradSchoolToeflReducer } = useSelector(
    (state) => state.GradSchoolReducer
  );
  const [state, setState] = useState({
    open: false,
    message: "",
    color: "",
  });
  const dispatch = useDispatch();
  const classes = useStyles();
  const [gradSclToeflVariant, setGradSchToeflVariant] = useState({
    toeflTotal: null,
    toeflReading: null,
    toeflListening: null,
    toeflWriting: null,
    toeflSpeaking: null,
    id: "",
  });
  const coursesFields = [
    {
      name: "toeflTotal",
      value: gradSclToeflVariant.toeflTotal,
      label: "TOEFL Total",
      type: "number",
    },

    {
      name: "toeflListening",
      value: gradSclToeflVariant.toeflListening,
      label: "TOEFL Listening",
      type: "number",
    },
    {
      name: "toeflReading",
      value: gradSclToeflVariant.toeflReading,
      label: "TOEFL Reading",
      type: "number",
    },
    {
      name: "toeflSpeaking",
      value: gradSclToeflVariant.toeflSpeaking,
      label: "TOEFL Speaking",
      type: "number",
    },
    {
      name: "toeflWriting",
      value: gradSclToeflVariant.toeflWriting,
      label: "TOEFL Writing",
      type: "number",
    },
  ];
  const onChange = (e) => {
    const { name, value } = e.target;
    setGradSchToeflVariant({
      ...gradSclToeflVariant,
      [name]: value,
    });
  };

  const handleSave = () => {
    if (
      gradSclToeflVariant.toeflListening ||
      gradSclToeflVariant.toeflReading ||
      gradSclToeflVariant.toeflSpeaking ||
      gradSclToeflVariant.toeflTotal ||
      gradSclToeflVariant.toeflWriting
    ) {
      if (gradSclToeflVariant.id.length > 0) {
        let obj = {
          toeflTotal:
            Number(gradSclToeflVariant.toeflTotal) === 0
              ? null
              : Number(gradSclToeflVariant.toeflTotal),
          toeflListening:
            Number(gradSclToeflVariant.toeflListening) === 0
              ? null
              : Number(gradSclToeflVariant.toeflListening),
          toeflSpeaking:
            Number(gradSclToeflVariant.toeflSpeaking) === 0
              ? null
              : Number(gradSclToeflVariant.toeflSpeaking),
          toeflReading:
            Number(gradSclToeflVariant.toeflReading) === 0
              ? null
              : Number(gradSclToeflVariant.toeflReading),
          toeflWriting:
            Number(gradSclToeflVariant.toeflWriting) === 0
              ? null
              : Number(gradSclToeflVariant.toeflWriting),
          id: gradSclToeflVariant.id,
        };
        dispatch(
          createGradSchoolToeflList(obj, (res) => {
            if (res.success) {
              setState({
                open: true,
                message: "Updated Successfully",
                color: "success",
              });
              dispatch(getGradSchoolToeflList(props.location.state.id));
            }
          })
        );
      } else {
        let obj = {
          toeflTotal:
            Number(gradSclToeflVariant.toeflTotal) === 0
              ? null
              : Number(gradSclToeflVariant.toeflTotal),
          toeflListening:
            Number(gradSclToeflVariant.toeflListening) === 0
              ? null
              : Number(gradSclToeflVariant.toeflListening),
          toeflSpeaking:
            Number(gradSclToeflVariant.toeflSpeaking) === 0
              ? null
              : Number(gradSclToeflVariant.toeflSpeaking),
          toeflReading:
            Number(gradSclToeflVariant.toeflReading) === 0
              ? null
              : Number(gradSclToeflVariant.toeflReading),
          toeflWriting:
            Number(gradSclToeflVariant.toeflWriting) === 0
              ? null
              : Number(gradSclToeflVariant.toeflWriting),
          gradSchoolProgram:
            props.gradSchoolProgramCreatedId || props.location.state.id,
        };
        dispatch(
          createGradSchoolToeflList(obj, (res) => {
            if (res.success) {
              setState({
                open: true,
                message: "Saved Successfully",
                color: "success",
              });
              dispatch(clearData());
            }
          })
        );
      }
    }
  };

  useEffect(() => {
    if (getGradSchoolToeflReducer !== null) {
      if (getGradSchoolToeflReducer.success) {
        const {
          id,
          toeflListening,
          toeflReading,
          toeflSpeaking,
          toeflTotal,
          toeflWriting,
        } = getGradSchoolToeflReducer.data;
        setGradSchToeflVariant({
          ...gradSclToeflVariant,
          toeflTotal: toeflTotal ? toeflTotal : null,
          toeflReading: toeflReading ? toeflReading : null,
          toeflListening: toeflListening ? toeflListening : null,
          toeflWriting: toeflWriting ? toeflWriting : null,
          toeflSpeaking: toeflSpeaking ? toeflSpeaking : null,
          id: id,
        });
      }
    }
  }, [getGradSchoolToeflReducer]);

  useEffect(() => {
    if (props.location.state.id) {
      dispatch(getGradSchoolToeflList(props.location.state.id));
    }
  }, []);

  return (
    <React.Fragment>
      <GSContainer>
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems="center"
          spacing={4}
        >
          <Grid item md={10}>
            <Grid container spacing={6}>
              {coursesFields.map((item) => {
                return (
                  <Grid item md={6}>
                    <TextField
                      value={item.value}
                      onChange={onChange}
                      name={item.name}
                      label={item.label}
                      fullWidth
                      type={item.type}
                      InputLabelProps={{
                        shrink: item.value === null ? false : true,
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item md={2}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              className={classes.btnStyles}
              onClick={handleSave}
              disabled={
                props.location.state.id
                  ? false
                  : props.gradSchoolProgramCreatedId
                  ? false
                  : true
              }
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </GSContainer>
      <MySnackBar
        snackOpen={state.open}
        snackVariant={state.color}
        snackMsg={state.message}
        onClose={() =>
          setState({
            open: false,
            message: "",
            color: "",
          })
        }
      />
    </React.Fragment>
  );
}
