import { CRUD_WEBINAR } from '../Redux/Action';

const initialState = {
  addWebinarLink:null,
  getWebinarLink:null,
  deleteWebinarLink:null,
  updateWebinarLink:null,
  getTools:null,
  documentList:null,
  fileList:null,
  getdownloadFileCV:null,
  loader:false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CRUD_WEBINAR.loader:
      return {
        ...state,
        isLoading: true,
      };
    case CRUD_WEBINAR.addWebinarLink:
      return {
        ...state,
        addWebinarLink: action.payload,
        isLoading: action.loading,
      };
      case CRUD_WEBINAR.getWebinarLink:
      return {
        ...state,
        getWebinarLink: action.payload,
        isLoading: action.loading,
      };
      case CRUD_WEBINAR.clearCustomData:
        return {
          ...state,
          getdownloadFileCV: null,
        };
    
      case CRUD_WEBINAR.deleteWebinarLink:
        return {
          ...state,
          deleteWebinarLink: action.payload,
          isLoading: action.loading,
        };
        case CRUD_WEBINAR.updateWebinarLink:
        return {
          ...state,
          updateWebinarLink: action.payload,
          isLoading: action.loading,
        };
        case CRUD_WEBINAR.getTools:
        return {
          ...state,
          getTools: action.payload,
          isLoading: action.loading,
        };
        case CRUD_WEBINAR.documentList:
        return {
          ...state,
          documentList: action.payload,
          isLoading: action.loading,
        };
        case CRUD_WEBINAR.fileList:
          return {
            ...state,
            fileList: action.payload,
            isLoading: action.loading,
          };
          case CRUD_WEBINAR.getdownloadFileCV:
            return {
              ...state,
              getdownloadFileCV: action.payload,
              isLoading: action.loading,
              
              
            };
        
      
    
    default:
      break;
  }
  return state;
};
