import { DASHBOARD } from '../Redux/Action';
import axios from 'axios';

export const analyticsData = (post) => async (dispatch) => {
  try {
    dispatch({
      type: DASHBOARD.ANALYTICS_REQUEST,
    });
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/dashboard/overview?days=7`,
      post,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    // console.log(data, "burgiir Analytics");
    dispatch({
      type: DASHBOARD.ANALYTICS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message ? error.response.data.message : error.message;

    dispatch({
      type: DASHBOARD.ANALYTICS_FAIL,
      payload: message,
    });
  }
};

export const liveDriveData = (post) => async (dispatch) => {
  try {
    dispatch({
      type: DASHBOARD.LIVEDRIVE_REQUEST,
    });
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/dashboard/livedrives`,
      post,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    // console.log(data, "burgiir liveDrive");
    dispatch({
      type: DASHBOARD.LIVEDRIVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message ? error.response.data.message : error.message;

    dispatch({
      type: DASHBOARD.LIVEDRIVE_FAIL,
      payload: message,
    });
  }
};

export const clsaTestTakerData = (post) => async (dispatch) => {
  try {
    dispatch({
      type: DASHBOARD.CLSA_TEST_REQUEST,
    });
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/dashboard/clsatesttakers`,
      post,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    // console.log(data, "burgiir CLSA Test");
    dispatch({
      type: DASHBOARD.CLSA_TEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message ? error.response.data.message : error.message;

    dispatch({
      type: DASHBOARD.CLSA_TEST_FAIL,
      payload: message,
    });
  }
};

export const userJourneyData = (post) => async (dispatch) => {
  try {
    dispatch({
      type: DASHBOARD.USER_JOURNEY_REQUEST,
    });
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/dashboard/userjourney`,
      post,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    dispatch({
      type: DASHBOARD.USER_JOURNEY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message ? error.response.data.message : error.message;
    dispatch({
      type: DASHBOARD.USER_JOURNEY_FAIL,
      payload: message,
    });
  }
};

export const driveStatusAlertData = (post) => async (dispatch) => {
  try {
    dispatch({
      type: DASHBOARD.STATUS_ALERT_REQUEST,
    });
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/dashboard/drivestatusalert`,
      post,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    // console.log(data, "burgiir status alert");
    dispatch({
      type: DASHBOARD.STATUS_ALERT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message ? error.response.data.message : error.message;

    dispatch({
      type: DASHBOARD.STATUS_ALERT_FAIL,
      payload: message,
    });
  }
};

export const updateDriveData = (post) => async (dispatch) => {
  try {
    dispatch({
      type: DASHBOARD.UPDATE_DRIVES_REQUEST,
    });
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/dashboard/updateddriverounds`,
      post,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    // console.log(data, "burgiir");
    dispatch({
      type: DASHBOARD.UPDATE_DRIVES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message ? error.response.data.message : error.message;

    dispatch({
      type: DASHBOARD.UPDATE_DRIVES_FAIL,
      payload: message,
    });
  }
};

export const clsaCountData = (post) => async (dispatch) => {
  try {
    dispatch({
      type: DASHBOARD.CLSA_COUNT_REQUEST,
    });
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/dashboard/clsajobdetails`,
      post,
      {
        crossDomain: true,
        headers: {
          admin: 'yes',
          Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
        },
      }
    );
    dispatch({
      type: DASHBOARD.CLSA_COUNT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message ? error.response.data.message : error.message;
    dispatch({
      type: DASHBOARD.CLSA_COUNT_FAIL,
      payload: message,
    });
  }
};
