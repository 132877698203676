import axios from "axios";
import { catchError, errorHandler } from "../Component/Utils/Helpers";
import { EXAMSPACE } from "../Redux/Action";
import { URL } from "./URL";

// Set the field value (any field) of the reducer as null
export const setFieldValue = (fieldName, fieldValue) => {
  return (dispatch) => {
    dispatch({
      type: EXAMSPACE.setFieldValue,
      fieldName: fieldName,
      fieldValue: fieldValue,
    });
  };
};

// University dropdown
export const getAllUniversity = () => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      await axios
        .get(`${URL}/api/v1/examspace/universities`, {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          dispatch({
            type: EXAMSPACE.getUniversity,
            payload: response.data,
          });
        });
    } catch (error) {
      dispatch(errorHandler(EXAMSPACE.getUniversity, error, false));
    }
  };
};

// Branch dropdown
export const getAllBranch = () => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      await axios
        .get(`${URL}/api/v1/examspace/branches`, {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          dispatch({
            type: EXAMSPACE.getBranch,
            payload: response.data,
          });
        });
    } catch (error) {
      dispatch(errorHandler(EXAMSPACE.getBranch, error, false));
    }
  };
};

// Examspace homepage details
export const examSpaceHomeDetails = (page, searchValue = "") => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      dispatch({ type: EXAMSPACE.loader });
      await axios
        .get(`${URL}/api/v1/examspace/universitylist`, {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            page: page,
            size: 6,
            search: searchValue,
          },
        })
        .then((response) => {
          dispatch({
            type: EXAMSPACE.examSpaceHomeDetails,
            payload: response.data,
          });
        });
    } catch (error) {
      dispatch(errorHandler(EXAMSPACE.examSpaceHomeDetails, error, false));
    }
  };
};

// Delete university - Homepage
export const deleteUniversityById = (
  universityId,
  currentPageContentLength
) => async (dispatch) => {
  try {
    dispatch({ type: EXAMSPACE.loader });
    const { data } = await axios.delete(
      `${URL}/api/v1/examspace/universities/${universityId}`,
      {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${window.sessionStorage.getItem(
            "accessToken"
          )}`,
        },
      }
    );
    dispatch({
      type: EXAMSPACE.deleteUniversityById,
      payload: { ...data, currentPageContentLength },
    });
  } catch (error) {
    dispatch(errorHandler(EXAMSPACE.deleteUniversityById, error, false));
  }
};

// Create University and Branch
export const createUniversityBranch = (payload) => async (dispatch) => {
  try {
    dispatch({ type: EXAMSPACE.loader });
    const { data } = await axios.post(
      `${URL}/api/v1/examspace/universities/branch`,
      payload,
      {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${window.sessionStorage.getItem(
            "accessToken"
          )}`,
        },
      }
    );
    dispatch({
      type: EXAMSPACE.createUniversityBranch,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(EXAMSPACE.createUniversityBranch, error, false));
  }
};

// Get University & all the branches
export const getUniversityBranch = (universityId) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      dispatch({ type: EXAMSPACE.loader });
      await axios
        .get(`${URL}/api/v1/examspace/university/${universityId}/branches`, {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          dispatch({
            type: EXAMSPACE.getUniversityBranch,
            payload: response.data,
          });
        });
    } catch (error) {
      dispatch(errorHandler(EXAMSPACE.getUniversityBranch, error, false));
    }
  };
};

// Delete Branch
export const deleteBranchById = (universityId, branchId) => async (
  dispatch
) => {
  try {
    dispatch({ type: EXAMSPACE.loader });
    const { data } = await axios.delete(
      `${URL}/api/v1/examspace/universities/${universityId}/branches/${branchId}`,
      {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${window.sessionStorage.getItem(
            "accessToken"
          )}`,
        },
      }
    );
    dispatch({
      type: EXAMSPACE.deleteBranchById,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(EXAMSPACE.deleteBranchById, error, false));
  }
};

// Create Chapter
export const createChapters = (payload) => async (dispatch) => {
  try {
    dispatch({ type: EXAMSPACE.loader });
    const { data } = await axios.post(
      `${URL}/api/v1/examspace/chapter`,
      payload,
      {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${window.sessionStorage.getItem(
            "accessToken"
          )}`,
        },
      }
    );
    dispatch({
      type: EXAMSPACE.createChapter,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(EXAMSPACE.createChapter, error, false));
  }
};

// Get all Chapters
export const getAllChapters = (subjectId) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      dispatch({ type: EXAMSPACE.loader });
      await axios
        .get(`${URL}/api/v1/examspace/subjects/${subjectId}/chapters`, {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          dispatch({
            type: EXAMSPACE.getAllChapter,
            payload: response.data,
          });
        });
    } catch (error) {
      dispatch(errorHandler(EXAMSPACE.getAllChapter, error, false));
    }
  };
};

// Get all semesters
export const getAllSemester = (universityBranchId, callback) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      await axios
        .get(
          `${URL}/api/v1/examspace/universitybranch/${universityBranchId}/semesters`,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          callback(response.data);
          dispatch({
            type: EXAMSPACE.getSemester,
            payload: response.data,
          });
        });
    } catch (error) {
      dispatch(errorHandler(EXAMSPACE.getSemester, error, false));
    }
  };
};

// Delete Chapter
export const deleteChapterById = (chapterId) => async (dispatch) => {
  try {
    dispatch({ type: EXAMSPACE.loader });
    const { data } = await axios.delete(
      `${URL}/api/v1/examspace/chapters/${chapterId}`,
      {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${window.sessionStorage.getItem(
            "accessToken"
          )}`,
        },
      }
    );
    dispatch({
      type: EXAMSPACE.deleteChapterById,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(EXAMSPACE.deleteChapterById, error, false));
  }
};

// Add a semester
export const addSemester = (data, callback) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      await axios
        .post(`${URL}/api/v1/examspace/semesters`, data, {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          callback(response.data);
          dispatch({
            type: EXAMSPACE.createSemester,
            payload: response.data,
          });
        });
    } catch (error) {
      dispatch(errorHandler(EXAMSPACE.createSemester, error, false));
    }
  };
};

// Get all the subjects
export const getAllSubject = (universityBranchId, semesterId, callback) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      await axios
        .get(
          `${URL}/api/v1/examspace/universitybranch/${universityBranchId}/semester/${semesterId}/subjects`,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          callback(response.data);
          dispatch({
            type: EXAMSPACE.getSubject,
            payload: response.data,
          });
        });
    } catch (error) {
      dispatch(errorHandler(EXAMSPACE.getSubject, error, false));
    }
  };
};

// Add a subject
export const addSubject = (data, callback) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      await axios
        .post(`${URL}/api/v1/examspace/subjects`, data, {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          callback(response.data);
          dispatch({
            type: EXAMSPACE.createSubject,
            payload: response.data,
          });
        });
    } catch (error) {
      dispatch(errorHandler(EXAMSPACE.createSubject, error, false));
    }
  };
};

// Delete a subject
export const deleteSubject = (subjectId, callback) => {
  return (dispatch) => {
    let accessToken = window.sessionStorage.getItem("accessToken");

    axios
      .delete(URL + `/api/v1/examspace/subject/${subjectId}`, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        callback(response.data);
        console.log(response);
        dispatch({
          type: EXAMSPACE.removeSubject,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

// Delete a semester
export const deleteSemester = (universityBranchId, semesterId, callback) => {
  return (dispatch) => {
    let accessToken = window.sessionStorage.getItem("accessToken");

    axios
      .delete(
        URL +
          `/api/v1/examspace/universitybranch/${universityBranchId}/semester/${semesterId} `,
        {
          crossDomain: true,
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        callback(response.data);
        console.log(response);
        dispatch({
          type: EXAMSPACE.removeSemester,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

// Exam Space Master Sheet download
export const examSpaceSheetDownload = (universityId, callback) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return () => {
    axios
      .get(`${URL}/api/v1/examspace/universities/${universityId}/mastersheet`, {
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: "blob",
      })
      .then((result) => {
        callback(result);
      })
      .catch((error) => {
        callback(catchError(error));
      });
  };
};

// Get all the content types (MIQ, Notes, PYP, CV)
export const getAllContent = (callback) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      await axios
        .get(`${URL}/api/v1/examspace/contenttype`, {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          callback(response.data);
          dispatch({
            type: EXAMSPACE.getContent,
            payload: response.data,
          });
        });
    } catch (error) {
      dispatch(errorHandler(EXAMSPACE.getContent, error, false));
    }
  };
};

// List all the contents in the Content page
export const listAllContents = (chapterId, contentTypeId) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      dispatch({ type: EXAMSPACE.loader });
      await axios
        .get(
          `${URL}/api/v1/examspace/chapters/${chapterId}/contenttype/${contentTypeId}/content`,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: EXAMSPACE.listAllContents,
            payload: response.data,
          });
        });
    } catch (error) {
      dispatch(errorHandler(EXAMSPACE.listAllContents, error, false));
    }
  };
};

// delete the contents in the Content page
export const deleteContent = (contentId) => async (dispatch) => {
  try {
    dispatch({ type: EXAMSPACE.loader });
    const { data } = await axios.delete(
      URL + `/api/v1/examspace/content/${contentId}`,
      {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${window.sessionStorage.getItem(
            "accessToken"
          )}`,
        },
      }
    );
    dispatch({
      type: EXAMSPACE.deleteContent,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(EXAMSPACE.deleteContent, error, false));
  }
};

// Save the Video content in the content page
export const saveVideoById = (data, chapterId, contentId, callback) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      await axios
        .post(
          `${URL}/api/v1/examspace/chapters/${chapterId}/contenttype/${contentId}/video/content`,
          data,
          {
            headers: {
              admin: "yes",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          callback(response.data);
          dispatch({
            type: EXAMSPACE.saveVideo,
            payload: response.data,
          });
        });
    } catch (error) {
      dispatch(errorHandler(EXAMSPACE.saveVideo, error, false));
    }
  };
};

// Save the PDF file in the content page
export const saveFileById = (payload, chapterId, contentTypeId) => async (
  dispatch
) => {
  try {
    dispatch({ type: EXAMSPACE.loader });
    const { data } = await axios.post(
      `${URL}/api/v1/examspace/chapters/${chapterId}/contenttype/${contentTypeId}/file/content`,
      payload,
      {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${window.sessionStorage.getItem(
            "accessToken"
          )}`,
        },
      }
    );
    dispatch({
      type: EXAMSPACE.saveFile,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(EXAMSPACE.saveFile, error, false));
  }
};
