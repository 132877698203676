import { combineReducers } from 'redux';
import CourseReducer from '../Reducer/CourseReducer';
import QuestionsReducer from '../Reducer/QuestionsReducer';
import CollegeReducer from '../Reducer/CollegeReducer';
import StudentReducer from '../Reducer/StudentReducer';
import ChoiceAnswerReducer from '../Reducer/ChoiceAnswerReducer';
import DepartmentReducer from '../Reducer/DepartmentReducer';
import DegreeReducer from '../Reducer/DegreeReducer';
import InTakeReducer from '../Reducer/InTakeReducer';
import AspirationReducer from '../Reducer/AspirationReducer';
import QuestionSetReducer from '../Reducer/QuestionSetReducer';
import VideoReducer from '../Reducer/VideoReducer';
import ProductReducer from '../Reducer/ProductReducer';
import AdminReducer from '../Reducer/AdminReducer';
import CareerTrackReducer from '../Reducer/CareerTrackReducer';
import NotificationReducer from '../Reducer/NotificationReducer';
import HelperReducer from '../Reducer/HelperReducer';
import ReportReducer from '../Reducer/ReportReducer';
import ProfileGapAnalysisReducer from '../Reducer/ProfileGapAnalysisReducer';
import CRGReducer from '../Reducer/CRGReducer';
import CvReviewReducer from '../Reducer/CvReviewReducer';
import UploadPgaReportReducer from '../Reducer/UploadPgaReportReducer';
import ProfileFitSpiderGraphReducer from '../Reducer/ProfileFitSpiderGraphReducer';
import {
  testimonialCreateReducer,
  testimonialDeleteReducer,
  testimonialUpdateReducer,
  testimonialListReducer,
} from '../Reducer/TestimonialReducer';
import {
  wallPostCreateReducer,
  wallPostDeleteReducer,
  wallPostUpdateReducer,
  wallPostListReducer,
  wallSuccessStoryReducer,
  getWallCategoriesReducer,
  getWallConceptsReducer,
  getWallAccountsReducer,
  getWallJobListReducer,
  getWallRedirectionTypeReducer,
  wallWhatsNewReducer,
  wallWhatsNewDeleteReducer,
  wallWebinarListReducer,
  platformsReducer,
  coursesReducer,
  driveTrackingReducer,
  getWallUserTypeReducer,
  getStoryUserTypeReducer,
  getWallTagReducer,
  wallStoryReducer,
  wallStoryDeleteReducer,
  getWallRedirectionReducer,
  wallAnimatedBannerReducer,
  jobDescriptionReducer,
  driveLimitReducer,
} from '../Reducer/WallReducer';
import StudentMarkDetailReducer from '../Reducer/MarkReducer';
import ScoreDetailsReducer from '../Reducer/ScoreReducer';
import MentorReducer from '../Reducer/MentorReducer';
import PgaReducer from '../Reducer/PgaReducer';
import CallReducer from '../Reducer/CallReducer';
import {
  testCreateReducer,
  testDeleteReducer,
  testUpdateReducer,
  testListReducer,
  testDetailsReducer,
} from '../Reducer/TestReducer';
import { getAppVersionReducer, updateAppVersionReducer } from '../Reducer/AppVersionReducer';

//dashboard
import {
  dashboardAnalyticsReducer,
  dashboardLiveDriveReducer,
  dashboardClsaTestTakerReducer,
  dashboardUpdateDrivesReducer,
  dashboardDriveStatusAlertReducer,
  dashboardClsaCountReducer,
  dashboardUserJourneyReducer,
} from '../Reducer/DashboardReducer';

//elev8 Clsa
import ClsaReducer from '../Reducer/ClsaReducer';
import { thirdYearWebinarListReducer } from '../Reducer/ThirdWebinarReducer';
import examSpaceReducer from '../Reducer/ExamSpaceReducer';
import { ibhReducer } from '../Reducer/IbhReducer';

// LMS
import CourseMaterialReducer from '../Lms/Redux/Reducer/CourseMaterialReducer';
import TestReducer from '../Lms/Redux/Reducer/TestReducer';
import LmsStudentReducer from '../Lms/Redux/Reducer/LmsStudentReducer';
import PgaReportReducer from '../Reducer/PgaReportReducer';
import StrategySessionReducer from '../Reducer/StrategySessionReducer';
import ProfileMentoringReducer from '../Reducer/ProfileMentoringReducer';
import ApplicationStageReducer from '../Reducer/ApplicationStageReducer';
import UserManagementReducer from '../Reducer/UserManagementReducer';
import PassageReducer from '../Lms/Redux/Reducer/PassageReducer';
import LmsConceptReducer from '../Lms/Redux/Reducer/ConceptReducer';
import FeedbackReducer from '../Lms/Redux/Reducer/FeedbackReducer';
import PostAdmitServicesReducer from '../Reducer/PostAdmitServicesReducer';

//School Research
import SchoolResearchReducer from '../Reducer/SchoolResearchReducer';
import { GradSchoolReducer } from '../Reducer/GradSchoolReducer';
import { BSchoolReducer } from '../Reducer/BSchoolReducer';
import PasReducer from '../Reducer/PasReducer';
import RecommenderToolReducer from '../Reducer/RecommenderToolReducer';
import CRUDwebinarReducer from '../Reducer/CRUDwebinarReducer';

export default combineReducers({
  CourseReducer: CourseReducer,
  QuestionsReducer: QuestionsReducer,
  CollegeReducer: CollegeReducer,
  StudentReducer: StudentReducer,
  ChoiceAnswerReducer: ChoiceAnswerReducer,
  DepartmentReducer: DepartmentReducer,
  DegreeReducer: DegreeReducer,
  InTakeReducer: InTakeReducer,
  AspirationReducer: AspirationReducer,
  QuestionSetReducer: QuestionSetReducer,
  VideoReducer: VideoReducer,
  ProductReducer: ProductReducer,
  AdminReducer: AdminReducer,
  ReportReducer: ReportReducer,
  CareerTrackReducer: CareerTrackReducer,
  NotificationReducer: NotificationReducer,
  StudentMarkDetailReducer: StudentMarkDetailReducer,
  ScoreReducer: ScoreDetailsReducer,
  MentorReducer: MentorReducer,
  CallReducer: CallReducer,
  PgaReducer: PgaReducer,
  ProfileGapAnalysisReducer: ProfileGapAnalysisReducer,
  CRGReducer: CRGReducer,
  PgaReportReducer: PgaReportReducer,
  ProfileFitSpiderGraphReducer: ProfileFitSpiderGraphReducer,
  CvReviewReducer: CvReviewReducer,
  UploadPgaReportReducer: UploadPgaReportReducer,
  StrategySessionReducer: StrategySessionReducer,
  ProfileMentoringReducer: ProfileMentoringReducer,
  ApplicationStageReducer: ApplicationStageReducer,
  PostAdmitServicesReducer: PostAdmitServicesReducer,
  CRUDwebinarReducer: CRUDwebinarReducer,

  //TESTIMONIAL
  testimonialCreateReducer,
  testimonialDeleteReducer,
  testimonialUpdateReducer,
  testimonialListReducer,
  //APP VERSION
  getAppVersionReducer,
  updateAppVersionReducer,

  //DASHBOARD
  dashboardAnalyticsReducer,
  dashboardLiveDriveReducer,
  dashboardClsaTestTakerReducer,
  dashboardUpdateDrivesReducer,
  dashboardDriveStatusAlertReducer,
  dashboardClsaCountReducer,
  dashboardUserJourneyReducer,

  //WALL
  wallPostCreateReducer,
  wallPostDeleteReducer,
  wallPostUpdateReducer,
  wallPostListReducer,
  wallSuccessStoryReducer,
  getWallCategoriesReducer,
  getWallConceptsReducer,
  getWallAccountsReducer,
  getWallJobListReducer,
  getWallRedirectionTypeReducer,
  wallWhatsNewReducer,
  wallWhatsNewDeleteReducer,
  wallWebinarListReducer,
  platformsReducer,
  coursesReducer,
  getWallUserTypeReducer,
  getStoryUserTypeReducer,
  getWallTagReducer,
  wallStoryReducer,
  wallStoryDeleteReducer,
  getWallRedirectionReducer,
  wallAnimatedBannerReducer,
  jobDescriptionReducer,
  driveLimitReducer,
  //THIRDYEARWEBINAR
  thirdYearWebinarListReducer,
  //EXAMSPACE
  examSpaceReducer,
  driveTrackingReducer,
  //IBH
  ibhReducer,
  //TESTS
  testCreateReducer,
  testDeleteReducer,
  testDetailsReducer,
  testUpdateReducer,
  testListReducer,
  HelperReducer: HelperReducer,
  // LMS
  CourseMaterialReducer: CourseMaterialReducer,
  TestReducer: TestReducer,
  LmsStudentReducer: LmsStudentReducer,
  PassageReducer: PassageReducer,
  LmsConceptReducer: LmsConceptReducer,
  FeedbackReducer: FeedbackReducer,
  //elev8 clsa
  ClsaReducer: ClsaReducer,
  UserManagementReducer: UserManagementReducer,
  SchoolResearchReducer: SchoolResearchReducer,
  RecommenderToolReducer: RecommenderToolReducer,
  //GradSchool
  GradSchoolReducer: GradSchoolReducer,
  BSchoolReducer: BSchoolReducer,
});
