import { Backdrop, Box, Divider, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import MySnackBar from "../../MySnackBar";
import { CommentBoxPopper } from "../../Utils/controls/CommentBoxPopper";

import Loader from "../../Utils/controls/Loader";
import { useStyles } from "../Styles";
import AdditionalPoints from "./AdditionalPoints";
import CustomTabs from "../PlanOfAction/CutomTabsForProfileMentoring";

function Index(props) {
  console.log(props.activeTabValue, "**************************hi");
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const { studentId, productId } = params;
  console.log(studentId, productId, "**********************student");
  const [state, setState] = useState({
    activeTabValue: "Version 1",
    open: false,
    comment: null,
    snackMsg: "",
    snackOpen: false,
    snackVariant: "",
    status: null,
    anchorEl: null,
    popoverComment: null,
  });
  const {
    activeTabValue,
    snackMsg,
    snackOpen,
    snackVariant,
    anchorEl,
    popoverComment,
  } = state;
  console.log(
    activeTabValue,
    "***********************activeTabValue****************"
  );
  const renderComponent = () => {
    if (activeTabValue === "Version 1") {
      return (
        <AdditionalPoints
          version="1"
          studentId={studentId}
          productId={productId}
          editable={false}
        />
      );
    } else if (activeTabValue === "Version 2") {
      return (
        <AdditionalPoints
          version="2"
          studentId={studentId}
          productId={productId}
          editable={props.tabList.length === 3 ? false : true}
        />
      );
    } else if (activeTabValue === "Version 3") {
      return (
        <AdditionalPoints
          version="3"
          studentId={studentId}
          productId={productId}
          editable={true}
        />
      );
    } else {
      return null;
    }
  };
  const handleTabChange = (e, newValue) => {
    setState({ ...state, activeTabValue: newValue });
  };

  const handleSnackClose = () => {
    setState({ ...state, snackOpen: false, snackVariant: "", snackMsg: "" });
  };

  const handleClickAway = () => {
    setState({ ...state, anchorEl: null, popoverComment: null });
  };
  useEffect(() => {
    handleTabChange("a", "Version 1");
  }, [props]);
  return (
    <div>
      <Grid container>
        <Grid item lg={12} xs={12} xl={12} md={12} sm={12}>
          <Box display={"flex"} alignItems={"center"}>
            <Box flex={1}>
              <CustomTabs
                value={activeTabValue}
                handleChange={handleTabChange}
                tabList={props.tabList}
                style={{ margin: "21px" }}
              >
                {console.log(props.tabList)}
                {/* {renderTabs()} */}
              </CustomTabs>
              {/* <div className={classes.planOfActionContainer}>
                <Typography variant={"h5"}>Quarterly Plan of Action</Typography>
              </div> */}
            </Box>
          </Box>
        </Grid>
        <Grid item lg={12} xs={12} xl={12} md={12} sm={12}>
          {renderComponent()}
        </Grid>
      </Grid>
      <CommentBoxPopper
        anchorEl={anchorEl}
        handleClickAway={handleClickAway}
        popperComment={popoverComment}
      />
      <MySnackBar
        onClose={handleSnackClose}
        snackOpen={snackOpen}
        snackVariant={snackVariant}
        snackMsg={snackMsg}
      />
      {/* <Backdrop className={classes.backdrop} open={loading}>
        <Loader />
      </Backdrop> */}
    </div>
  );
}

export default Index;
