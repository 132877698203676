import { Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { GSContainer, useStyles } from "../../Asset/StyledComponents/Styles";
import Button from "@material-ui/core/Button";
import {
  clearData,
  createGradSchoolGreList,
  getGradSchoolGreList,
} from "../../Actions/GradSchoolAction";
import { useDispatch, useSelector } from "react-redux";
import MySnackBar from "../MySnackBar";
import { isEmptyString } from "../Validation";
export default function GradSchoolGre(props) {
  const { getGradSchoolGreReducer } = useSelector(
    (state) => state.GradSchoolReducer
  );
  const dispatch = useDispatch();
  const classes = useStyles();
  const [gradSclGreVariant, setGradSclGreVariant] = useState({
    id: "",
    GreTotal: null,
    GreV: null,
    GreQ: null,
    GreAWA: null,
  });
  const coursesFields = [
    {
      name: "GreTotal",
      value: gradSclGreVariant.GreTotal,
      label: "GRE Total",
      type: "number",
    },
    {
      name: "GreV",
      value: gradSclGreVariant.GreV,
      label: "GRE V",
      type: "number",
    },
    {
      name: "GreQ",
      value: gradSclGreVariant.GreQ,
      label: "GRE Q",
      type: "number",
    },
    {
      name: "GreAWA",
      value: gradSclGreVariant.GreAWA,
      label: "GRE AWA",
      type: "number",
    },
  ];
  const onChange = (e) => {
    const { name, value } = e.target;
    setGradSclGreVariant({
      ...gradSclGreVariant,
      [name]: value,
    });
  };

  const [state, setState] = useState({
    open: false,
    message: "",
    color: "",
  });

  const handleSave = () => {
    if (
      gradSclGreVariant.GreTotal ||
      gradSclGreVariant.GreQ ||
      gradSclGreVariant.GreV ||
      gradSclGreVariant.GreAWA
    ) {
      if (gradSclGreVariant.id.length > 0) {
        let obj = {
          greTotal:
            Number(gradSclGreVariant.GreTotal) === 0
              ? null
              : Number(gradSclGreVariant.GreTotal),
          greQ:
            Number(gradSclGreVariant.GreQ) === 0
              ? null
              : Number(gradSclGreVariant.GreQ),
          greV:
            Number(gradSclGreVariant.GreV) === 0
              ? null
              : Number(gradSclGreVariant.GreV),
          greAwa:
            Number(gradSclGreVariant.GreAWA) === 0
              ? null
              : Number(gradSclGreVariant.GreAWA),
          id: gradSclGreVariant.id,
        };
        dispatch(
          createGradSchoolGreList(obj, (res) => {
            if (res.success) {
              setState({
                open: true,
                message: "Updated Successfully",
                color: "success",
              });
              dispatch(getGradSchoolGreList(props.location.state.id));
            }
          })
        );
      } else {
        let obj = {
          greTotal:
            Number(gradSclGreVariant.GreTotal) === 0
              ? null
              : Number(gradSclGreVariant.GreTotal),
          greQ:
            Number(gradSclGreVariant.GreQ) === 0
              ? null
              : Number(gradSclGreVariant.GreQ),
          greV:
            Number(gradSclGreVariant.GreV) === 0
              ? null
              : Number(gradSclGreVariant.GreV),
          greAwa:
            Number(gradSclGreVariant.GreAWA) === 0
              ? null
              : Number(gradSclGreVariant.GreAWA),
          gradSchoolProgram:
            props.gradSchoolProgramCreatedId || props.location.state.id,
        };
        dispatch(
          createGradSchoolGreList(obj, (res) => {
            if (res.success) {
              setState({
                open: true,
                message: "Saved Successfully",
                color: "success",
              });
              dispatch(clearData());
            }
          })
        );
      }
    }
  };

  useEffect(() => {
    if (getGradSchoolGreReducer !== null) {
      if (getGradSchoolGreReducer.success) {
        const {
          greAwa,
          greQ,
          greTotal,
          greV,
          id,
        } = getGradSchoolGreReducer.data;
        setGradSclGreVariant({
          GreTotal: greTotal ? greTotal : null,
          GreV: greV ? greV : null,
          GreQ: greQ ? greQ : null,
          GreAWA: greAwa ? greAwa : null,
          id: id,
        });
      }
    }
  }, [getGradSchoolGreReducer]);

  useEffect(() => {
    if (props.location.state.id) {
      dispatch(getGradSchoolGreList(props.location.state.id));
    }
  }, []);

  return (
    <React.Fragment>
      <GSContainer>
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems="center"
          spacing={4}
        >
          <Grid item md={10}>
            <Grid container spacing={6}>
              {coursesFields.map((item) => {
                return (
                  <Grid item md={6}>
                    <TextField
                      value={item.value}
                      onChange={onChange}
                      name={item.name}
                      label={item.label}
                      fullWidth
                      type={item.type}
                      InputLabelProps={{
                        shrink: item.value === null ? false : true,
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item md={2}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              className={classes.btnStyles}
              onClick={handleSave}
              disabled={
                props.location.state.id
                  ? false
                  : props.gradSchoolProgramCreatedId
                  ? false
                  : true
              }
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </GSContainer>
      <MySnackBar
        snackOpen={state.open}
        snackVariant={state.color}
        snackMsg={state.message}
        onClose={() =>
          setState({
            open: false,
            message: "",
            color: "",
          })
        }
      />
    </React.Fragment>
  );
}
