import React, { useState, useEffect } from 'react';
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
} from '@material-ui/core';
import useTable from '../../Utils/useTable';
import Controls from '../../Utils/controls/Controls';
import { Search } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Notification from '../../Utils/Notification';
import { useHistory } from 'react-router-dom';
import { createSuccessPath, editSuccessPath } from '../../RoutePaths';
import moment from 'moment';
import Loader from '../../Utils/controls/Loader';
import MuiAlert from '@material-ui/lab/Alert';
import ConfirmDialog from '../../Utils/ConfirmDialog';
import { useSelector, useDispatch } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import { deleteWallPost, listWallStory } from '../../../Actions/WallActions';
import { renderListCategory } from '../../Utils/Helpers';

const Alert = (props) => <MuiAlert elevation={6} variant='filled' {...props} />;

const useStyles = makeStyles((theme) => ({
  pageContent: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
  },
  searchInput: {
    width: '65%',
  },
  filterBtn: {
    position: 'absolute',
    right: '250px',
    borderRadius: '26px',
  },
  newButton: {
    position: 'absolute',
    right: '20px',
    borderRadius: '26px',
  },
  totalLikes: {
    textAlign: 'center',
    width: '130px',
  },
}));

const headCells = [
  { id: 'category', label: 'Category', disableSorting: true },
  { id: 'createdAt', label: 'Published' },
  { id: 'name', label: 'Name' },
  { id: 'caption', label: 'Caption', disableSorting: true },
  { id: 'totalLikes', label: 'Congratulations' },
  { id: 'actions', label: 'Actions', disableSorting: true },
];

export default function SuccessStory() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, error, stories } = useSelector((state) => state.wallSuccessStoryReducer);
  let totalPages = stories?.data?.totalPages;

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting, page } = useTable(
    stories?.data?.content,
    headCells,
    filterFn,
    totalPages
  );

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value == '') return items;
        else
          return items.filter((x) => x.caption.toLowerCase().includes(target.value.toLowerCase()));
      },
    });
  };

  const openInPage = (item) => {
    history.push({
      pathname: `${editSuccessPath}/${item.id}`,
      postType: 'Story',
    });
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    dispatch(deleteWallPost(id));
    setTimeout(() => {
      dispatch(listWallStory(page));
    }, 1200);
    setNotify({
      isOpen: true,
      message: 'Deleted Successfully',
      type: 'success',
    });
  };

  useEffect(() => {
    dispatch(listWallStory(page));
  }, [dispatch, page]);

  return (
    <>
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.RoundedInput
            className={classes.searchInput}
            placeholder='Search Success Stories by caption...'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          <Controls.Button
            text='Create New Story'
            variant='contained'
            color='primary'
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={() => {
              history.push({
                pathname: createSuccessPath,
                type: false,
                postType: 'Story',
              });
            }}
          />
        </Toolbar>

        <TblContainer>
          <TblHead />
          {stories?.data?.content && (
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{renderListCategory(item.wallCategories)}</TableCell>
                  <TableCell>{moment(item.createdAt).fromNow()}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{`${item.caption?.slice(0, 20)}...`}</TableCell>
                  <TableCell className={classes.totalLikes}>{item.totalLikes}</TableCell>
                  <TableCell>
                    <Controls.ActionButton onClick={() => openInPage(item)}>
                      <EditOutlinedIcon fontSize='small' color='primary' />
                    </Controls.ActionButton>
                    <Controls.ActionButton
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title: 'Are you sure to delete this story?',
                          subTitle: "You can't undo this operation",
                          onConfirm: () => {
                            onDelete(item.id);
                          },
                        });
                      }}
                    >
                      <DeleteIcon fontSize='small' color='secondary' />
                    </Controls.ActionButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </TblContainer>
        <div style={{ margin: '2rem auto', width: '60%' }}>
          {loading && <Loader />}
          {error && <Alert severity='error'>{error}</Alert>}
          {!loading && stories?.data?.content?.length === 0 && (
            <Alert severity='info'>0 Success Story Found</Alert>
          )}
        </div>
        <TblPagination />
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
}
