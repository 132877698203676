import { Box, Breadcrumbs, Button, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { lms_feedback } from "../../../Component/RoutePaths";
import { textToDownloadFile } from "../../../Component/Utils/Helpers";
import Before from "../../../Lms/Assets/icons/BeforeNavigate.svg";
import { Container, FlexView } from "../../Assets/StyledComponents";
import { getOverview, overviewExport } from "../../Redux/Action/FeedBack";
import PaginationComponent from "../../Utils/PaginationComponent";
import TableComponent from "./TableComponent";

const SIZE = 10;
function Index(props) {
  const { overviewList } = useSelector((state) => state.FeedbackReducer);
  const overviewData =
    (Array.isArray(overviewList?.data?.content) && overviewList.data.content) ||
    [];

  const history = useHistory();
  const params = useParams();
  const videoId = params?.videoId;

  const dispatch = useDispatch();

  const [state, setState] = useState({
    page: 0,
    totalPage: 0,
  });

  const { page, totalPage } = state;

  useEffect(() => {
    if (videoId) dispatch(getOverview(SIZE, page, videoId));
  }, [videoId, page]);

  useEffect(() => {
    if (overviewList && overviewList.success) {
      setState({
        ...state,
        totalPage: overviewList.data?.totalPages || 0,
      });
    }
  }, [overviewList]);

  const handlePageChange = (e, newPage) => {
    setState({
      ...state,
      page: newPage - 1,
    });
  };

  const handleExport = () => {
    dispatch(
      overviewExport(videoId, (res) => {
        if (res.success)
          textToDownloadFile(res.data, "Feedback_User_Report", "xlsx");
      })
    );
  };

  const disabled = !videoId || (overviewData && overviewData.length === 0);
  return (
    <>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs>
            <FlexView>
              <div>
                <FlexView gap={"6px"}>
                  <img
                    src={Before}
                    style={style.pointer}
                    onClick={() => history.goBack()}
                  />
                  <Breadcrumbs
                    separator={<NavigateNextIcon fontSize='small' />}
                  >
                    <Typography
                      style={{ ...style.navText, ...style.pointer }}
                      onClick={() => history.push(lms_feedback)}
                    >
                      {"Feedback"}
                    </Typography>
                    <Typography
                      style={{
                        ...style.navText,
                        color: "black",
                      }}
                    >
                      {"View users"}
                    </Typography>
                  </Breadcrumbs>
                </FlexView>
              </div>
              <Button
                onClick={handleExport}
                style={style.exportButton(disabled)}
                variant={"contained"}
                disabled={disabled}
              >
                {"Export Report"}
              </Button>
            </FlexView>
          </Grid>
          <Grid item xs={12}>
            <TableComponent overviewData={overviewData} />
            <Box padding={"15px 0px 20px !important"}>
              <PaginationComponent
                pageCount={totalPage}
                onPageChange={handlePageChange}
                page={page + 1}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
export default Index;

const style = {
  navText: {
    fontWeight: "600",
    marginLeft: "10px",
    color: "#009be5",
  },
  pointer: {
    cursor: "pointer",
  },
  exportButton: (disabled) => ({
    background: !disabled && "#1093FF",
    color: !disabled && "#ffffff",
    textTransform: "none",
    minWidth: 130,
    fontWeight: 500,
    fontSize: "14px",
  }),
};
