import { Button, ThemeProvider } from "@material-ui/core";
import React from "react";
import { ButtonBox, buttonTheme } from "../../../Assets/StyledTest";
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";

function Buttons(props) {
  const {
    handleSaveClick,
    handleCancelClick,
    handlePreviewClick,
    disable,
  } = props;
  return (
    <ButtonBox>
      <ThemeProvider theme={buttonTheme}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={
            <PlayArrowOutlinedIcon
              fontSize="large"
              style={{ fontSize: "2.1875rem" }}
            />
          }
          onClick={handlePreviewClick}
        >
          Preview
        </Button>
        <Button variant="outlined" color="primary" onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={disable}
          onClick={handleSaveClick}
        >
          {disable && (
            <CircularProgress
              size={20}
              thickness={6}
              color={"inherit"}
              style={{ marginRight: "10px" }}
            />
          )}
          Save
        </Button>
      </ThemeProvider>
    </ButtonBox>
  );
}

export default Buttons;
