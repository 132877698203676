import { WALL } from "../Redux/Action";

export const wallPostListReducer = (state = { posts: [] }, action) => {
  switch (action.type) {
    case WALL.LIST_REQUEST:
      return { loading: true, posts: [] };
    case WALL.LIST_SUCCESS:
      return {
        loading: false,
        posts: action.payload,
      };
    case WALL.LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

/* Wall Success Story List reducer */
export const wallSuccessStoryReducer = (state = { stories: {} }, action) => {
  switch (action.type) {
    case WALL.LIST_STORY_REQUEST:
      return { loading: true, stories: {} };
    case WALL.LIST_STORY_SUCCESS:
      return {
        loading: false,
        stories: action.payload,
      };
    case WALL.GET_STORY_BY_ID:
      return { loading: false, story: action.payload };
    case WALL.LIST_STORY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
/* Wall Success Story List reducer */

/* Wall What's New reducer ------- */
export const getWallUserTypeReducer = (state = { usertype: [] }, action) => {
  switch (action.type) {
    case WALL.WALL_USERTYPE_REQUEST:
      return { loading: true, posts: [] };
    case WALL.WALL_USERTYPE_SUCCESS:
      return {
        loading: false,
        usertype: action.payload,
      };
    case WALL.WALL_USERTYPE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getWallRedirectionTypeReducer = (
  state = { redirectiontype: [] },
  action
) => {
  switch (action.type) {
    case WALL.WALL_REDIRECTIONTYPE_REQUEST:
      return { loading: true, posts: [] };
    case WALL.WALL_REDIRECTIONTYPE_SUCCESS:
      return {
        loading: false,
        redirectiontype: action.payload,
      };
    case WALL.WALL_REDIRECTIONTYPE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getWallConceptsReducer = (state = { concept: [] }, action) => {
  switch (action.type) {
    case WALL.CONCEPTS:
      return {
        concept: action.payload,
      };

    default:
      return state;
  }
};
export const getWallAccountsReducer = (
  state = { webinarAccount: [] },
  action
) => {
  switch (action.type) {
    case WALL.ACCOUNTS:
      return {
        webinarAccount: action.payload,
      };

    default:
      return state;
  }
};

// Wall Whats New List reducer
export const wallWhatsNewReducer = (state = { posts: {} }, action) => {
  switch (action.type) {
    case WALL.LIST_WHATSNEW_REQUEST:
      return { loading: true, posts: {} };
    case WALL.LIST_WHATSNEW_SUCCESS:
      return {
        loading: false,
        posts: action.payload,
      };
    case WALL.GET_WHATSNEW_BY_ID:
      return { loading: false, post: action.payload };
    case WALL.LIST_WHATSNEW_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Wall Whats New Delete reducer
export const wallWhatsNewDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case WALL.DELETE_WHATSNEW_REQUEST:
      return { loading: true };
    case WALL.DELETE_WHATSNEW_SUCCESS:
      return { loading: false, success: true };
    case WALL.DELETE_WHATSNEW_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
/* Wall What's New reducer ----- */

/* Wall - Story reducer ------- */
//Story User Type dropdown
export const getStoryUserTypeReducer = (state = { usertype: [] }, action) => {
  switch (action.type) {
    case WALL.WALL_STORY_USERTYPE_REQUEST:
      return { loading: true, posts: [] };
    case WALL.WALL_STORY_USERTYPE_SUCCESS:
      return {
        loading: false,
        usertype: action.payload,
      };
    case WALL.WALL_STORY_USERTYPE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//Story Tags dropdown
export const getWallTagReducer = (state = { tags: [] }, action) => {
  switch (action.type) {
    case WALL.WALL_TAG_REQUEST:
      return { loading: true, posts: [] };
    case WALL.WALL_TAG_SUCCESS:
      return {
        loading: false,
        tags: action.payload,
      };
    case WALL.WALL_TAG_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Story List Homepage
export const wallStoryReducer = (state = { stories: {} }, action) => {
  switch (action.type) {
    case WALL.LIST_STORIES_REQUEST:
      return { loading: true, stories: {} };
    case WALL.LIST_STORIES_SUCCESS:
      return {
        loading: false,
        stories: action.payload,
      };
    case WALL.LIST_STORIES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Wall Story Delete reducer
export const wallStoryDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case WALL.DELETE_STORIES_REQUEST:
      return { loading: true };
    case WALL.DELETE_STORIES_SUCCESS:
      return { loading: false, success: true };
    case WALL.DELETE_STORIES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Wall Story redirection type dropdown
export const getWallRedirectionReducer = (
  state = { redirectiontype: [] },
  action
) => {
  switch (action.type) {
    case WALL.STORY_REDIRECTIONTYPE_REQUEST:
      return { loading: true, posts: [] };
    case WALL.STORY_REDIRECTIONTYPE_SUCCESS:
      return {
        loading: false,
        redirectiontype: action.payload,
      };
    case WALL.STORY_REDIRECTIONTYPE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

/* Wall - Story reducer ------- */

/* Wall - Animated Banner reducer ------- */
export const wallAnimatedBannerReducer = (
  state = {
    loading: false,
    getAnimatedLists: null,
    deleteBannerStatus: null,
    createBannerStatus: null,
  },
  action
) => {
  switch (action.type) {
    case WALL.LOADER:
      return {
        loading: true,
      };
    case WALL.ANIMATED_BANNER_LISTS:
      return {
        getAnimatedLists: action.payload,
      };
    case WALL.DELETE_ANIMATED_BANNER:
      return {
        deleteBannerStatus: action.payload,
      };
    case WALL.CREATE_ANIMATED_BANNER:
      return {
        createBannerStatus: action.payload,
      };
    default:
      return state;
  }
};

/* Wall - Animated Banner reducer ------- */

export const wallWebinarListReducer = (state = { webinars: [] }, action) => {
  switch (action.type) {
    case WALL.WEBINAR_LIST_REQUEST:
      return { loading: true, webinars: [] };
    case WALL.WEBINAR_LIST_SUCCESS:
      return {
        loading: false,
        webinars: action.payload,
      };
    case WALL.WEBINAR_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getWallCategoriesReducer = (
  state = { categories: [] },
  action
) => {
  switch (action.type) {
    case WALL.WALL_CATEGORIES_REQUEST:
      return { loading: true, posts: [] };
    case WALL.WALL_CATEGORIES_SUCCESS:
      return {
        loading: false,
        categories: action.payload,
      };
    case WALL.WALL_CATEGORIES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
// export const getWallConceptsReducer = (state = { concept: [] }, action) => {
//   switch (action.type) {
//     case WALL.CONCEPTS:
//       return {
//         concept: action.payload,
//       };

//     default:
//       return state;
//   }
// };
// export const getWallAccountsReducer = (state = {webinarAccount: [] }, action) => {
//   switch (action.type) {
//     case WALL.ACCOUNTS:
//       return {
//         webinarAccount: action.payload,
//       };

//     default:
//       return state;
//   }
// };

export const getWallJobListReducer = (state = { categories: [] }, action) => {
  switch (action.type) {
    case WALL.WALL_JOB_LIST_REQUEST:
      return { loading: true, posts: [] };
    case WALL.WALL_JOB_LIST_SUCCESS:
      return {
        loading: false,
        jobs: action.payload,
      };
    case WALL.WALL_JOB_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const wallPostDetailsReducer = (state = { post: [] }, action) => {
  switch (action.type) {
    case WALL.DETAILS_REQUEST:
      return { ...state, loading: true };
    case WALL.DETAILS_SUCCESS:
      return { loading: false, post: action.payload };
    case WALL.DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const wallPostDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case WALL.DELETE_REQUEST:
      return { loading: true };
    case WALL.DELETE_SUCCESS:
      return { loading: false, success: true };
    case WALL.DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const wallPostCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case WALL.CREATE_REQUEST:
      return { loading: true };
    case WALL.CREATE_SUCCESS:
      return { loading: false, success: true, WALL: action.payload };
    case WALL.CREATE_FAIL:
      return { loading: false, error: action.payload };
    case WALL.GET_PD_BY_ID:
      return { loading: false, post: action.payload };
    case WALL.CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const wallPostUpdateReducer = (state = { post: {} }, action) => {
  switch (action.type) {
    case WALL.UPDATE_REQUEST:
      return { loading: true };
    case WALL.UPDATE_SUCCESS:
      return { loading: false, success: true, post: action.payload };
    case WALL.UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case WALL.UPDATE_RESET:
      return { post: {} };
    default:
      return state;
  }
};

export const platformsReducer = (state = { platforms: [] }, action) => {
  switch (action.type) {
    case WALL.PLATFORMS:
      return {
        platforms: action.payload,
      };

    default:
      return state;
  }
};

export const coursesReducer = (state = { courses: [] }, action) => {
  console.log(action.payload);
  switch (action.type) {
    case WALL.VIEWCOURSES:
      return {
        courses: action.payload,
      };

    default:
      return state;
  }
};

export const getWallAccountReducer = (
  state = { webinarAccount: [] },
  action
) => {
  switch (action.type) {
    case WALL.ACCOUNTS:
      return {
        webinarAccount: action.payload,
      };

    default:
      return state;
  }
};

/* Wall Drive Tracking reducer */
export const driveTrackingReducer = (
  state = {
    loading: false,
    allDriveList: null,
    allUpdateRound: null,
    updatedData: null,
    filterData: null,
  },
  action
) => {
  switch (action.type) {
    case WALL.LOADER:
      return { loading: true };
    case WALL.GET_DRIVE_BY_ID:
      return {
        loading: false,
        allDriveList: action.payload,
      };
    case WALL.GET_ROUND_UPDATE_BY_ID:
      return {
        loading: false,
        allUpdateRound: action.payload,
      };
    case WALL.UPDATE_ROUND_UPDATE_BY_ROUND_MESSAGE:
      return {
        loading: false,
        updatedData: action.payload,
      };
    case WALL.FILTER_DRIVE_BY_VALUE:
      return {
        loading: false,
        filterData: action.payload,
      };
    default:
      return state;
  }
};
/* Wall Drive Tracking reducer */

/*Job Description reducer */
export const jobDescriptionReducer = (
  state = {
    loading: false,
    getJobDescription: null,
  },
  action
) => {
  switch (action.type) {
    case WALL.LOADER:
      return { loading: true };
    case WALL.getJobDescriptionDetails:
      return {
        loading: false,
        getJobDescription: action.payload,
      };
    default:
      return state;
  }
};
/*Job Description reducer */

/*Drive Limit reducer */
export const driveLimitReducer = (
  state = {
    loading: false,
    getUserLists: null,
    getDriveLimitLists: null,
    updateDriveLimits: null,
  },
  action
) => {
  switch (action.type) {
    case WALL.LOADER:
      return { loading: true };
    case WALL.getDriveLimitUserLists:
      return {
        loading: false,
        getUserLists: action.payload,
      };
    case WALL.getDriveLimitDetails:
      return {
        loading: false,
        getDriveLimitLists: action.payload,
      };
    case WALL.updateDriveLimit:
      return {
        loading: false,
        updateDriveLimits: action.payload,
      };
    default:
      return state;
  }
};
/*Drive Limit reducer */
