import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DashboardAnalytics from './Components/DashboardAnalytics';
import DashboardLiveDrive from './Components/DashboardLiveDrive';
import DashboardClsaTaker from './Components/DashboardClsaTaker';
import DashboardDriveStatus from './Components/DashboardDriveStatus';
import DashboardDriveUpdate from './Components/DashboardDriveUpdate';
import DashboardClsaCount from './Components/DashboardClsaCount';
import DashboardUserJourney from './Components/DashboardUserJourney';

const Dashboard = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3}>
        <DashboardAnalytics />
        <DashboardLiveDrive />
        <DashboardClsaTaker />
        <DashboardClsaCount />
        <DashboardUserJourney />
        <DashboardDriveUpdate />
        <DashboardDriveStatus />
      </Grid>
    </Box>
  );
};

export default Dashboard;
