import styled from "styled-components";
export const SemesterButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px, 24px, 14px, 24px;
  cursor: pointer;
  height: 48px;
  background: #ffffff;
  mix-blend-mode: normal;
  box-shadow: 0px 4px 10px rgba(0, 65, 130, 0.1);
  border-radius: 4px;
  flex: none;
  order: 3;
  flex-grow: 0;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border: ${({ act }) => (act ? "1px solid #1093FF" : "")};
`;

export const Container = styled.div`
  position: absolute;
  width: 1330px;
  height: 200px;
  left: 270px;
  top: 189px;
  background: #ffffff;
  mix-blend-mode: normal;
  box-shadow: 0px 5px 20px rgba(0, 65, 130, 0.15);
  border-radius: 12px;
`;
export const Typodef = styled.div`
  padding: 15px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #001d3a;
`;
