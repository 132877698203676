import {
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import { Search } from '@material-ui/icons';
import Loader from '../Utils/controls/Loader';
import AddIcon from '@material-ui/icons/Add';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DescriptionIcon from '@material-ui/icons/Description';
import RefreshIcon from '@mui/icons-material/Refresh';
import PrintIcon from '@mui/icons-material/Print';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MuiAlert from '@material-ui/lab/Alert';
import * as moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clsaMasterSheetDownload, clsaTestDelete, clsaTestList } from '../../Actions/ClsaActions';
import { clsaResultPath, clsaTestCreate, clsaTestEdit, testEdit } from '../RoutePaths';
import { ButtonsContainerTwo } from '../Test/Assets/Styles/CreateTestStyles';
import { DrawerContainer } from '../Test/Assets/Styles/WallStyles';
import { default as Controls } from '../Utils/controls/Controls';
import Notification from '../Utils/Notification';
import ConfirmDialog from '../Utils/ConfirmDialog';
import PaginationComponent from '../Utils/CustomPaginationComponent';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    marginTop: '24px',
    padding: '10px 0',
  },
  searchInput: {
    width: '65%',
  },
  table: {
    marginTop: theme.spacing(3),
    '& thead th': {
      fontWeight: '600',
      color: '#052A4E',
      backgroundColor: '#F4F7F9',
    },
    '& tbody td': {
      fontWeight: '300',
    },
    '& tbody tr:hover': {
      backgroundColor: '#fffbf2',
      cursor: 'pointer',
    },
  },
  filterBtn: {
    position: 'absolute',
    right: '250px',
    borderRadius: '26px',
  },
  newButton: {
    position: 'absolute',
    right: '20px',
    bottom: '25px',
    borderRadius: '26px',
  },
}));

export default function LiveTest() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [data, setData] = useState('');
  const [page, setPage] = useState(0);
  const [error, setError] = useState(false);
  const [viewData, setViewData] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });
  const [loading, setLoading] = useState(false);
  const [reportBtnLoading, setReportBtnLoading] = useState(false);
  const [list, setList] = useState([]);
  const [idx, setIdx] = useState('');
  const Alert = (props) => <MuiAlert elevation={6} variant='filled' {...props} />;

  const handleSearch = (text) => {
    setSearchValue(text);
    setLoading(true);
    clsaTestList(page, text).then((response) => {
      setLoading(false);
      if (response.status === 200) {
        setData(response?.data?.data);
        setList(response?.data?.data?.content);
      }
    });
  };

  const openInPage = (item) => {
    history.push({
      pathname: testEdit,
      testId: item.id,
      testType: 'Live',
    });
    setOpenDrawer(false);
  };

  useEffect(() => {
    setLoading(true);
    clsaTestList(0)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setData(response?.data?.data);
          setList(response?.data?.data.content);
        }
        if (response === 'CLSA List Is Empty') {
          setLoading(false);
          setError(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  const handleEdit = (item) => {
    history.push({
      pathname: clsaTestEdit + item.id,
      testIdx: item.id,
    });
  };

  const openResultPage = (item) => {
    history.push({
      pathname: clsaResultPath + item.id,
      id: item.id,
    });
    setOpenDrawer(false);
  };

  const handleDelete = (item) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    setLoading(true);
    clsaTestDelete(item.id)
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            clsaTestList(page).then((response) => {
              setLoading(false);
              if (response.status === 200) {
                setData(response?.data?.data);
                setList(response?.data?.data?.content);
              }
            });
          }, 1200);
          setNotify({
            isOpen: true,
            message: 'Deleted Successfully',
            type: 'error',
          });
        }
        if (response === 'Test is Live, It not able to delete') {
          setLoading(false);
          setNotify({
            isOpen: true,
            message: response,
            type: 'error',
          });
        }
        if (response === 'User attended test, It not able to delete') {
          setLoading(false);
          setNotify({
            isOpen: true,
            message: response,
            type: 'error',
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const convertTimeFormat = (date) =>
    new Date(date).toLocaleTimeString('en-US', {
      hour: 'numeric',
      hour12: true,
      minute: 'numeric',
    });

  const handlePageChange = (e, value) => {
    setPage(value - 1);
    setLoading(true);
    clsaTestList(value - 1, searchValue).then((response) => {
      if (response.status === 200) {
        setLoading(false);
        setData(response?.data?.data);
        setList(response?.data?.data.content);
      }
      if (response === 'CLSA List Is Empty') {
        setLoading(false);
        setError(true);
      }
    });
  };

  return (
    <>
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.RoundedInput
            className={classes.searchInput}
            placeholder='Search CLSA Category here...'
            helperText={'Press Enter key to search after typing'}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Search />
                </InputAdornment>
              ),
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSearch(e.target.value);
              }
            }}
          />
          <Controls.ActionButton
            style={{ position: 'absolute', right: '220px', bottom: '23px' }}
            onClick={() => {
              setLoading(true);
              clsaTestList(page).then((response) => {
                setLoading(false);
                if (response.status === 200) {
                  setData(response?.data?.data);
                  setList(response?.data?.data?.content);
                }
              });
              setNotify({
                isOpen: true,
                message: 'Page Refreshed...',
                type: 'success',
              });
            }}
          >
            <Tooltip title='Reload' placement='top'>
              <RefreshIcon fontSize='large' color='primary' />
            </Tooltip>
          </Controls.ActionButton>
          <Controls.Button
            text='Create New Test'
            variant='contained'
            color='primary'
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={() => {
              history.push({
                pathname: clsaTestCreate,
                type: false,
              });
            }}
          />
        </Toolbar>
      </Paper>
      <Grid container>
        <Grid item md={12}>
          {reportBtnLoading || loading ? (
            <Loader />
          ) : (
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Test Name</TableCell>
                    <TableCell>Created on</TableCell>
                    <TableCell>Created by</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list &&
                    list.map((item, index) => {
                      return (
                        <TableRow>
                          {/* <TableCell>{index + 1}</TableCell> */}
                          <TableCell style={{ color: '#1093FF' }}>{item.testName}</TableCell>
                          <TableCell>
                            {moment(new Date(item.createdOn)).format('DD MMM yyyy')} ,{' '}
                            {convertTimeFormat(item.createdOn)}
                          </TableCell>
                          <TableCell>{item.createdBy}</TableCell>
                          <TableCell>
                            {item?.jobCategory ? item?.jobCategory?.name : 'NA'}
                          </TableCell>
                          <TableCell>
                            <Controls.ActionButton
                              disabled={item.status === 'PROGRESS'}
                              onClick={() => {
                                setReportBtnLoading(true);
                                dispatch(
                                  clsaMasterSheetDownload(item.id, (response) => {
                                    console.log(response, 'response');
                                    if (response.status === 200) {
                                      if (response?.data?.message === 'Nobody took CLSA test yet') {
                                        setReportBtnLoading(false);
                                        setNotify({
                                          isOpen: true,
                                          message: response?.data?.message,
                                          type: 'success',
                                        });
                                        clsaTestList(page).then((response) => {
                                          if (response.status === 200) {
                                            setData(response?.data?.data);
                                            setList(response?.data?.data?.content);
                                          }
                                        });
                                      } else {
                                        setReportBtnLoading(false);
                                        setNotify({
                                          isOpen: true,
                                          message:
                                            'Generating Sheet, Please refresh & download...!',
                                          type: 'success',
                                        });
                                        clsaTestList(page).then((response) => {
                                          if (response.status === 200) {
                                            setData(response?.data?.data);
                                            setList(response?.data?.data?.content);
                                          }
                                        });
                                      }
                                    } else {
                                      setReportBtnLoading(false);
                                      setNotify({
                                        isOpen: true,
                                        message: 'Something went wrong!',
                                        type: 'error',
                                      });
                                    }
                                  })
                                );
                              }}
                            >
                              <Tooltip title='Generate a Master Sheet' placement='top'>
                                <PrintIcon
                                  fontSize='small'
                                  style={{
                                    color:
                                      item.status === 'TODO' || item.status === 'COMPLETED'
                                        ? '#1093FF'
                                        : 'gray',
                                  }}
                                />
                              </Tooltip>
                            </Controls.ActionButton>
                            <Controls.ActionButton
                              disabled={!item.url || item.status === 'PROGRESS'}
                              onClick={() => {
                                window.open(item.url);
                              }}
                            >
                              <Tooltip title='Download' placement='top'>
                                <CloudDownloadIcon
                                  fontSize='small'
                                  style={{
                                    color: item.status === 'COMPLETED' ? 'green' : 'gray',
                                  }}
                                />
                              </Tooltip>
                            </Controls.ActionButton>
                            <Controls.ActionButton
                              onClick={() => {
                                handleEdit(item);
                              }}
                            >
                              <EditOutlinedIcon fontSize='small' color='primary' />
                            </Controls.ActionButton>
                            <Controls.ActionButton
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: 'Are you sure to delete this post?',
                                  subTitle: "You can't undo this operation",
                                  onConfirm: () => {
                                    handleDelete(item);
                                  },
                                });
                              }}
                            >
                              <DeleteIcon fontSize='small' color='secondary' />
                            </Controls.ActionButton>
                            <Controls.ActionButton onClick={() => openResultPage(item)}>
                              <Tooltip title='Result Page' placement='top'>
                                <DescriptionIcon fontSize='small' color='primary' />
                              </Tooltip>
                            </Controls.ActionButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              <div style={{ margin: '2rem auto', width: '60%' }}>
                {error && <Alert severity='info'>0 Tests Found</Alert>}
              </div>
              {list?.length > 0 && (
                <PaginationComponent
                  page={page + 1}
                  pageCount={data.totalPages}
                  onPageChange={handlePageChange}
                />
              )}
            </TableContainer>
          )}
        </Grid>
      </Grid>

      <Drawer anchor='right' open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <DrawerContainer>
          <ButtonsContainerTwo>
            <span style={{ fontSize: '1rem' }} onClick={() => openInPage(viewData)}>
              <IconButton aria-label='edit'>
                <EditIcon color='primary' size='large' />
              </IconButton>
              Edit
            </span>
          </ButtonsContainerTwo>
        </DrawerContainer>
      </Drawer>
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
