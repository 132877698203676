import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DropDown from "../../Controls/DropDown";
import TextFieldComponent from "../../Controls/TextField";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import "../../../Asset/PlanOfAction.css";

const useStyles = makeStyles({
  // table: {
  //   minWidth: 650,

  // },
  // tableCell: {
  //   border: "2px solid #488DFF",
  // },
  tableHead: {
    height: 44,
  },
  input: {
    fontSize: 10,
  },
  pointerEvent: {
    pointerEvents: "none",
  },
});
const VerticalTableHead = styled.th`
  text-align: center;
  font-size: 14px;
  color: #488dff;
  padding: 5px 0px !important;
  width: 400px;
  padding: 9px 30px 9px 30px !important;
`;
const VerticalTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: 2px solid #488dff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  overflow: auto !important;
  left: 0px;
  ::-webkit-scrollbar {
    width: 8px;
    height: 52px;
  }

  tr th,
  tr td {
    border-left: 2px solid #488dff;
    border-bottom: 2px solid #488dff;
    padding: 5px;
  }

  tr th:first-child,
  tr td:first-child {
    border-left: none;
  }

  tr:last-child td {
    border-bottom: none;
  }

  tr:first-child th {
    border-top-left-radius: 10px;
    padding: 20px !important;
  }

  tr:last-child td {
    border-bottom-left-radius: 10px;
  }

  tr:last-child th {
    border-bottom: none !important;
  }

  tr:first-child td {
    border-top-left-radius: 10px;
  }
`;

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function BlueTable({
  data,
  focusList,
  handleFocusChange,
  edit,
}) {
  const classes = useStyles();

  const renderContent = (
    type,
    name,
    focusNo,
    planId,
    ind,
    index,
    fontColor
  ) => {
    if (type === "TEXT") {
      return <Typography style={{ color: fontColor }}>{name}</Typography>;
    } else if (type === "OBJECT") {
      return (
        <DropDown
          id="combo-box-demo"
          options={focusList}
          value={name}
          onChange={(e, newValue) =>
            handleFocusChange(newValue, focusNo, planId, name, ind, index)
          }
          className={edit ? "" : classes.pointerEvent}
          getOptionLabel={(option) => option.activity}
          renderInput={(params) => (
            <TextFieldComponent
              {...params}
              className={"plan-of-action-font"}
              label="Select Option"
              variant="standard"
              // InputProps={{ className:classes.input}}
              // InputLabelProps={{ style: { fontSize: 14 } }}
            />
          )}
        />
      );
    }
  };
  return (
    <TableContainer>
      <VerticalTable className={classes.tableHead} aria-label="simple table">
        {data.map((eachRow, ind) => {
          return (
            <TableRow>
              {eachRow.map((eachData, index) => {
                return (
                  <VerticalTableHead
                    align={"center"}
                    className={classes.tableCell}
                  >
                    {renderContent(
                      eachData.type,
                      eachData.name,
                      eachData.focusNo,
                      eachData.planId,
                      ind,
                      index,
                      eachData.frontColorCode
                    )}
                  </VerticalTableHead>
                );
              })}
            </TableRow>
          );
        })}
      </VerticalTable>
    </TableContainer>
  );
}
