import { Backdrop, Box, Divider, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { withRouter } from "react-router-dom";
import {
  clearCustomData,
  getDisableButtonCall,
  getDocumentModelBySubStageId,
  getDownloadByDocumentForCv,
  getDownloadByDocumentId,
  getDownloadByDocumentIdForReport,
  getEnableCall,
  getQPMC2ReportCall,
  qpmcStageComplete,
  uploadFile,
  uploadFileForReport,
} from "../../Actions/ProfileMentoring";
import {
  getStepsBySubStageId,
  getStudentStageByProductId,
} from "../../Actions/Student";
import MySnackBar from "../MySnackBar";
import { CommentBoxPopper } from "../Utils/controls/CommentBoxPopper";
import { CustomTab, CustomTabs } from "../Utils/controls/CustomTabComponent";
import CustomTabsPM from "./additionalPoints/CustomTabsForProfileMentoring";
import Loader from "../Utils/controls/Loader";
import { getVariantStepsById } from "../../Actions/ProductAction";
import PreferenceIndex from "../SchoolResearchPreferenceList/Index";
import DocumentComponentForReport from "./DocumentComponentForReport";
import DocumentComponentForSchoolResearch from "./DocumentComponentForSchoolResearch";
import { customTheme, StyledButton } from "../Utils/controls/Styles";
import {
  bytesToMegaBytes,
  getSubStageByStage,
  textToDownloadFile,
} from "../Utils/Helpers";
import DocumentComponent from "./DocumentComponent";
import { useStyles } from "./Styles";
import { getproductstepsCall } from "../../Actions/ProductAction";
import PreferenceList from "../SchoolResearchPreferenceList/PreferenceList";
import PlanOfAction from "./additionalPoints/Index";
import { tabListForQPMC1, tabListForQPMC2 } from "../../Utils/Data";
import PlanOfActionIndex from "./PlanOfAction/Index";
import MomIndex from "./MOM/Index";

const FILE_FORMAT_ERROR = "Invalid file format";
const FILE_SIZE_ERROR = "Please check the file size";
const FILE_UPLOAD_ERROR = "Please select a file";
const UPLOADED_SUCCESS = "Uploaded Successfully";
const REQUIRED_ERROR = "Please fill the required field";

function Index(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const { studentId, productId } = params;
  const [stageDetails, setStageDetails] = useState("");
  const [qpmc1Notif, setQpmc1Notif] = useState(true);
  const [state, setState] = useState({
    steps: [],
    documentList: [],
    activeTabValue: "QPMC 1",
    sectionId: null,
    open: false,
    comment: null,
    upcomingFileName: null,
    fileName: null,
    fileNameHelperText: "",
    commentHelperText: "",
    file: null,
    snackMsg: "",
    snackOpen: false,
    snackVariant: "",
    status: null,
    anchorEl: null,
    popoverComment: null,
    completedStagesList: [],
    cvloader: false,
  });

  const [stageId, setStageId] = useState("");

  const {
    steps,
    documentList,
    sectionId,
    activeTabValue,
    activeStateValue,
    activeSubTabValue,
    SubsectionId,
    open,
    comment,
    fileName,
    fileNameHelperText,
    commentHelperText,
    file,
    snackMsg,
    snackOpen,
    snackVariant,
    upcomingFileName,
    status,
    anchorEl,
    popoverComment,
    completedStagesList,
    cvloader,
  } = state;
  const {
    loading,
    documentModel,
    fileUploadStatus,
    downloadFileResponse,
    getQPMC2ReportStatus,
    qpmcStageCompletionStatus,
    disableStage,
    enableStage,
    momPoints,
  } = useSelector((state) => state.ProfileMentoringReducer);

  const { studentStages, subStageSteps, completedStages } = useSelector(
    (state) => state.StudentReducer
  );

  const { getproductsteps } = useSelector((state) => state.ProductReducer);
  useEffect(() => {
    if (qpmcStageCompletionStatus?.success) {
      setState({
        ...state,
        snackOpen: true,
        snackVariant: "success",
        snackMsg:
          activeTabValue === "QPMC 1"
            ? "QPMC 1 Completed Successfully"
            : "QPMC 2 Completed Successfully",
      });
      // setCompletionMessage(qpmcStageCompletionStatus?.message);
    } else {
      setState({
        ...state,
        snackOpen: true,
        snackVariant: "error",
        snackMsg: qpmcStageCompletionStatus?.message,
      });
    }
  }, [qpmcStageCompletionStatus]);
  console.log(studentStages, "gethtestudentsStages");
  useEffect(() => {
    dispatch(getStudentStageByProductId(studentId, productId));
    dispatch(
      getVariantStepsById(productId, (response) => {
        setStageDetails(response);
      })
    );
    dispatch(getDisableButtonCall(studentId, productId));
  }, []);
  const [completionMessage, setCompletionMessage] = useState("");
  useEffect(() => {
    let id = stageDetails?.steps?.find(
      (el) => el.stepName === "Profile Mentoring"
    ).id;

    dispatch(getproductstepsCall(id));
  }, [stageDetails]);

  useEffect(() => {
    if (studentStages) {
      if (studentStages.success) {
        const a = studentStages.data.find(
          (item) => item.stepName === "Profile Mentoring"
        );

        const b = a.steps.find((data) => data.stepName === "QPMC 2");

        const stageId = b.steps.find((item) =>
          item.stepName === "QPMC 2 Report" ? item.id : null
        );

        setStageId(stageId.id);

        const { data } = studentStages;
        let subStage = getSubStageByStage(
          data,
          "Profile Mentoring",
          "Completed Cv"
        );
        if (subStage.length !== 0) {
          dispatch(
            getStepsBySubStageId(
              studentId,
              productId,
              subStage[0]["id"],
              "profileMentoring"
            )
          );
        }
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackVariant: "error",
          snackMsg: studentStages.message,
        });
      }
    }
    let Tabsteps1 = studentStages?.data
      ?.find((el) => el.stepName === "Profile Mentoring")
      ?.steps?.find((el) => el.stepName === "QPMC 1")?.steps;

    let arr1 = Tabsteps1?.find(
      ({ stepName }) => stepName === "QPMC 1 call schedule"
    );

    setState({
      ...state,
      activeTabValue: "QPMC 1",
      activeSubTabValue: "QPMC 1 call schedule",
      activeStateValue: arr1?.id,
    });
  }, [studentStages]);

  useEffect(() => {
    if (subStageSteps) {
      if (subStageSteps.success) {
        let arr = subStageSteps.data;
        if (arr.length !== 0) {
          arr.sort(function(a, b) {
            return a.rank - b.rank;
          });
          setState({
            ...state,
            steps: arr,
            activeTabValue: "QPMC 1",
            sectionId: arr.length !== 0 && arr[0]["id"],
          });
        }
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackVariant: "error",
          snackMsg: subStageSteps.message,
        });
      }
    }
  }, [subStageSteps]);

  useEffect(() => {
    if (studentStages) {
      const a = studentStages.data.find(
        (item) => item.stepName === "Profile Mentoring"
      );

      const c = a.steps.find((data) => data.stepName === "Worksheets");

      const stageId1 = c.steps.find((item) =>
        item.stepName === activeSubTabValue ? item.id : null
      );

      console.log(activeSubTabValue, c, stageId1, "gethesubtabvalues", stageId);
      if (
        activeSubTabValue === "School Research Worksheet" ||
        activeSubTabValue === "Region Research Worksheet" ||
        activeSubTabValue === "Industry Research Worksheet" ||
        activeSubTabValue === "Cost of Attendance Worksheet"
      ) {
        dispatch(getQPMC2ReportCall(studentId, productId, stageId1?.id));
      }
    }
    if (activeSubTabValue === "CV Review") {
      dispatch(getDocumentModelBySubStageId(studentId, productId, stageId));
    } else if (activeSubTabValue === "QPMC 2 Report") {
      dispatch(getQPMC2ReportCall(studentId, productId, stageId));
    }
  }, [activeSubTabValue]);

  useEffect(() => {
    if (completedStages) {
      if (completedStages.success) {
        setState({
          ...state,
          completedStagesList: completedStages.data || [],
        });
      } else {
        setState({
          ...state,
          completedStagesList: [],
        });
      }
    }
  }, [completedStages]);

  useEffect(() => {
    if (documentModel) {
      if (documentModel.success) {
        const { data } = documentModel;

        setState({
          ...state,
          upcomingFileName: data.fileName,
          status: data.stepStatus,
          documentList: data.content || [],
        });
      } else {
        setState({
          ...state,
          // snackOpen: true,
          // snackVariant: "error",
          // snackMsg: documentModel.message,
          upcomingFileName: null,
          status: null,
          documentList: [],
        });
      }
      dispatch(clearCustomData("documentModel"));
    }
  }, [documentModel]);
  useEffect(() => {
    if (activeSubTabValue === "QPMC 2 Report") {
      if (getQPMC2ReportStatus) {
        if (getQPMC2ReportStatus.success) {
          const { data } = getQPMC2ReportStatus;

          setState({
            ...state,
            upcomingFileName: data.fileName,
            status: data.stepStatus,
            documentList: data || [],
          });
        } else {
          setState({
            ...state,
            // snackOpen: true,
            // snackVariant: "error",
            // snackMsg: getQPMC2Report.message,
            upcomingFileName: null,
            status: null,
            documentList: [],
          });
        }
        dispatch(clearCustomData("getQPMC2Report"));
      }
    }
  }, [getQPMC2ReportStatus]);
  // useEffect(() => {
  //   if (activeSubTabValue === "QPMC 2 Report") {
  //     dispatch(
  //       getQPMC2ReportCall(
  //         studentId,
  //         productId,
  //         "aca00ae8-19fd-11ed-861d-0242ac120002"
  //       )
  //     );
  //   }
  // }, [activeSubTabValue, fileUploadStatus]);
  useEffect((event) => {
    if (event?.target?.innerHTML === "Worksheet") {
      dispatch(
        getQPMC2ReportCall(
          studentId,
          productId,
          "619c3a6c-19fd-11ed-861d-0242ac120002"
        )
      );
    }
  }, []);
  useEffect(() => {
    if (fileUploadStatus) {
      if (fileUploadStatus.success) {
        if (activeSubTabValue === "QPMC 2 Report") {
          setState({
            ...state,
            snackOpen: true,
            snackVariant: "success",
            snackMsg: UPLOADED_SUCCESS,
            file: null,
            fileName: null,
            comment: null,
            fileNameHelperText: "",
            commentHelperText: "",
            open: false,
            cvloader: true,
          });
          dispatch(getQPMC2ReportCall(studentId, productId, stageId));
        } else {
          setState({
            ...state,
            // snackOpen: true,
            // snackVariant: "success",
            // snackMsg: "Saved Successfully",
            file: null,
            fileName: null,
            comment: null,
            fileNameHelperText: "",
            commentHelperText: "",
            open: false,
            cvloader: true,
          });
          dispatch(getDocumentModelBySubStageId(studentId, productId, stageId));
        }
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackVariant: "error",
          snackMsg: fileUploadStatus.message,
        });
      }
      dispatch(clearCustomData("fileUploadStatus"));
    }
  }, [fileUploadStatus]);

  useEffect(() => {
    if (downloadFileResponse) {
      if (downloadFileResponse.success) {
        textToDownloadFile(
          downloadFileResponse.data,
          downloadFileResponse.fileName,
          downloadFileResponse.fileName.split(".").pop()
        );
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackVariant: "error",
          snackMsg: downloadFileResponse.message,
        });
      }
      dispatch(clearCustomData("downloadFileResponse"));
    }
  }, [downloadFileResponse]);

  const handleCancel = () => {
    setState({
      ...state,
      file: null,
      fileName: null,
      comment: null,
      fileNameHelperText: "",
      commentHelperText: "",
      open: false,
    });
  };
  const handleUploadReport = () => {
    let error = false;

    if (!file) {
      error = true;
      setState({
        ...state,
        snackOpen: true,
        snackMsg: FILE_UPLOAD_ERROR,
        snackVariant: "error",
      });
    } else if (!(comment && comment.trim().length !== 0)) {
      error = true;
      setState({ ...state, commentHelperText: REQUIRED_ERROR });
    }

    if (!error) {
      var fileObj = file;
      var newFileName = fileName.split(".")[0];
      var newFileType = fileObj.path.split(".")[1];
      var blob = new Blob([fileObj], { type: newFileType });
      var newFile = new File([blob], `${newFileName}.${newFileType}`, {
        type: newFileType,
      });
      let uploadFormData = new FormData();
      uploadFormData.append("file", newFile);
      dispatch(
        uploadFileForReport(
          studentId,
          productId,
          stageId,
          uploadFormData,
          comment
        )
      );
    }
  };
  const handleUpload = () => {
    let error = false;

    if (!file) {
      error = true;
      setState({
        ...state,
        snackOpen: true,
        snackMsg: FILE_UPLOAD_ERROR,
        snackVariant: "error",
      });
    } else if (!(comment && comment.trim().length !== 0)) {
      error = true;
      setState({ ...state, commentHelperText: REQUIRED_ERROR });
    }

    if (!error && "1cb5ebc8-19fd-11ed-861d-0242ac120002") {
      var fileObj = file;
      var newFileName = fileName;
      var newFileType = fileObj.path.split(".").pop();
      var blob = new Blob([fileObj], { type: newFileType });
      var newFile = new File([blob], `${newFileName}.${newFileType}`, {
        type: newFileType,
      });
      let uploadFormData = new FormData();
      uploadFormData.append("file", newFile);
      dispatch(uploadFile(studentId, productId, uploadFormData, comment));
    }
  };

  const handleUploadClick = () => {
    setState({ ...state, open: true });
  };
  const handleStageComplete = () => {
    dispatch(qpmcStageComplete(studentId, productId, activeTabValue));
  };

  const handleDrop = (fileArray) => {
    if (fileArray.length !== 0) {
      if (bytesToMegaBytes(fileArray[0]["size"]) > 5) {
        setState({
          ...state,
          snackOpen: true,
          snackMsg: FILE_SIZE_ERROR,
          snackVariant: "error",
        });
      } else {
        setState({
          ...state,
          file: fileArray[0],
          fileName: upcomingFileName || fileArray[0]["name"],
        });
      }
    } else {
      setState({
        ...state,
        snackOpen: true,
        snackMsg: FILE_FORMAT_ERROR,
        snackVariant: "error",
      });
    }
  };

  const handleComment = (comment, e) => {
    setState({
      ...state,
      popoverComment: comment,
      anchorEl: e.currentTarget,
    });
  };

  // const handleDownload = (path, id, e) => {
  //   let name = path.split(".").pop();
  //   console.log(name, "$$$$$$$$$$$$")
  //   let file = name === "docx" ? path.slice(0, -4) : path.slice(0, -3);
  //   console.log(file, "&&&&&&&&&&&&&&&&&&&&&&&&&&&&")
  //   dispatch(getDownloadByDocumentForCv(studentId, productId, id, file));
  // };
  const handleDownload = (path, id, e) => {
    console.log(path, path.slice(0, -3), "%%%%%%%%");
    console.log(path, path.slice(0, -3), "%%%%%%%%");
    let name = path.split(".").pop();
    let dot = ".";
    let name1 = path.concat(dot);
    console.log(name, name1, "$$$$$$$$$$$$");
    // let file = name === "docx" ? cvPath.slice(0, -4) : name1 === cvPath ? cvPath + "." : cvPath.slice(0, -3);
    let file =
      name == "pdf"
        ? path.slice(0, -3)
        : name == "docx"
        ? `${path.slice(0, -4)}docx`
        : `${path.slice(0, -3)}doc`;
    console.log(file, "&&&&&&&&&&&&&&&&&&&&&&&&&&&&");

    dispatch(getDownloadByDocumentForCv(studentId, productId, id, file));
  };
  const handleDownloadReport = (path, id, e) => {
    console.log(id, "hello");
    dispatch(getDownloadByDocumentIdForReport(studentId, stageId, path, id));
  };
  const handleDownloadForWorksheet = (path, id, e) => {
    dispatch(getDownloadByDocumentIdForReport(studentId, stageId, path, id));
  };

  const handleDelete = (id, path, e) => {};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value, [`${name}HelperText`]: null });
  };

  const isStageCompleted = () => {
    return completedStagesList.includes("Profile Mentoring");
  };
  console.log(activeSubTabValue, "gertheactivesubtabvalue");
  const renderComponent = () => {
    const renderProps = {
      open: open,
      stepName: activeTabValue,
      handleCancel: handleCancel,
      handleUpload: handleUpload,
      handleUploadReport: handleUploadReport,
      handleUploadClick: handleUploadClick,
      handleStageComplete: handleStageComplete,
      activeTabValue: activeTabValue,
      activeSubTabValue: activeSubTabValue,
      onDrop: handleDrop,
      handleComment: handleComment,
      handleDownload: handleDownload,
      handleDownloadReport: handleDownloadReport,
      handleDownloadForWorksheet: handleDownloadForWorksheet,
      handleDelete: handleDelete,
      tableData: documentList,
      handleChange: handleChange,
      fileName: fileName,
      comment: comment,
      fileNameHelperText: fileNameHelperText,
      commentHelperText: commentHelperText,
      file: file,
      disabledUploadButton: isStageCompleted() || documentList.length === 0,
      isDisabledFileName: true,
      lastestCVLoading: cvloader,
      completionMessage: completionMessage,
      ...props,
    };

    if (activeSubTabValue && activeTabValue) {
      if (
        activeSubTabValue === "School Research Worksheet" &&
        activeTabValue === "Worksheets"
      ) {
        return (
          <PreferenceList
            studentId={studentId}
            productId={productId}
            {...renderProps}
          />
        );
        //   return(
        //      <DocumentComponentForReport {...renderProps} />

        //   );
      } else if (activeSubTabValue === "Region Research Worksheet") {
        return <DocumentComponentForSchoolResearch {...renderProps} />;
      } else if (activeSubTabValue === "Industry Research Worksheet") {
        return <DocumentComponentForSchoolResearch {...renderProps} />;
      } else if (activeSubTabValue === "Cost of Attendance Worksheet") {
        return <DocumentComponentForSchoolResearch {...renderProps} />;
      } else if (activeSubTabValue === "CV Review") {
        return (
          // <PlanOfAction tabList={tabListForQPMC1} activeTabValue={"Version 1"} />
          <DocumentComponent {...renderProps} />
        );
      } else if (
        activeSubTabValue === "Additional points to consider" &&
        activeTabValue === "QPMC 1"
      ) {
        return (
          <PlanOfAction
            tabList={tabListForQPMC1}
            activeTabValue={"Version 1"}
          />
        );
      } else if (
        activeSubTabValue === "Plan of action" &&
        activeTabValue === "QPMC 1"
      ) {
        // return <PreferenceList studentId={studentId} productId={productId} />;
        return (
          //  <PlanOfAction/>
          <PlanOfActionIndex
            activeTabValue={"Version 1"}
            tabList={tabListForQPMC1}
          />
        );
      } else if (activeSubTabValue === "Plan of action") {
        // return <PreferenceList studentId={studentId} productId={productId} />;
        return (
          //  <PlanOfAction/>
          <PlanOfActionIndex
            activeTabValue={"Version 1"}
            tabList={tabListForQPMC2}
          />
        );
      } else if (activeSubTabValue === "Additional points to consider") {
        // return <PreferenceList studentId={studentId} productId={productId} />;
        return (
          <PlanOfAction
            tabList={tabListForQPMC2}
            activeTabValue={"Version 1"}
          />
        );
      } else if (activeSubTabValue === "CV Review") {
        // return <PreferenceList studentId={studentId} productId={productId} />;
        return <DocumentComponent {...renderProps} />;
      } else if (activeSubTabValue === "QPMC 2 Report") {
        // return <PreferenceList studentId={studentId} productId={productId} />;
        return <DocumentComponentForReport {...renderProps} />;
      } else if (activeSubTabValue === "QPMC 1 call schedule") {
        // return <PreferenceList studentId={studentId} productId={productId} />;
        return (
          <MomIndex
            activeTabValue={"QPMC 1"}
            activeStateValue={activeStateValue}
          />
        );
      } else if (activeSubTabValue === "QPMC 2 call schedule") {
        // return <PreferenceList studentId={studentId} productId={productId} />;
        return (
          <MomIndex
            activeTabValue={"QPMC 2"}
            activeStateValue={activeStateValue}
          />
        );
      } else {
        return null;
      }
    }
  };

  const handleTabChange = (e, newValue) => {
    if (newValue === "1cb5ebc8-19fd-11ed-861d-0242ac120002") {
      // dispatch(
      //   getDocumentModelBySubStageId(
      //     studentId,
      //     productId,
      //     "c4971d3a-b4a4-11ec-b909-0242ac120002"
      //   )
      // );
      // let arr = steps.filter(({ sectionName }) => sectionName === newValue);
      // let newSectionId = arr.length !== 0 ? arr[0]["id"] : null;
      // setState({ ...state, activeTabValue: newValue, sectionId: newSectionId });
    } else {
      if (newValue === "QPMC 1") {
        let Tabsteps1 = studentStages?.data
          ?.find((el) => el.stepName === "Profile Mentoring")
          ?.steps?.find((el) => el.stepName === "QPMC 1")?.steps;

        let arr1 = Tabsteps1.find(
          ({ stepName }) => stepName === "QPMC 1 call schedule"
        );

        setState({
          ...state,
          activeTabValue: newValue,
          activeSubTabValue: "QPMC 1 call schedule",
          activeStateValue: arr1?.id,
        });
      } else if (newValue === "QPMC 2") {
        let Tabsteps1 = studentStages?.data
          ?.find((el) => el.stepName === "Profile Mentoring")
          ?.steps?.find((el) => el.stepName === "QPMC 2")?.steps;

        let arr1 = Tabsteps1.find(
          ({ stepName }) => stepName === "QPMC 2 call schedule"
        );

        setState({
          ...state,
          activeTabValue: newValue,
          activeSubTabValue: "QPMC 2 call schedule",
          activeStateValue: arr1?.id,
        });
      } else if (newValue === "Worksheets") {
        let Tabsteps1 = studentStages?.data
          ?.find((el) => el.stepName === "Profile Mentoring")
          ?.steps?.find((el) => el.stepName === "Worksheets")?.steps;

        let arr1 = Tabsteps1.find(
          ({ stepName }) => stepName === "School Research Worksheet"
        );

        setState({
          ...state,
          activeTabValue: newValue,
          activeSubTabValue: "School Research Worksheet",
          activeStateValue: arr1?.id,
        });
      } else {
        let Tabsteps = getproductsteps?.steps?.find(
          (el) => el.stepName === "Completed Cv"
        ).steps;
        let arr = Tabsteps.filter(({ stepName }) => stepName === newValue);
        let newSectionId = arr.length !== 0 ? arr[0]["id"] : null;
        setState({
          ...state,
          activeTabValue: newValue,
          sectionId: newSectionId,
        });
      }
    }
  };

  const handleSubTabChange = (e, newValue) => {
    if (newValue === "1cb5ebc8-19fd-11ed-861d-0242ac120002") {
      dispatch(
        getDocumentModelBySubStageId(
          studentId,
          productId,
          "1cb5ebc8-19fd-11ed-861d-0242ac120002"
        )
      );

      let arr = steps.filter(({ id }) => id === newValue);

      let newSectionId = arr.length !== 0 ? arr[0]["id"] : null;
      setState({
        ...state,
        activeSubTabValue: "CV Review",
        SubsectionId: newSectionId,
        activeStateValue: newValue,
      });
    } else {
      let Tabsteps1 = studentStages?.data
        ?.find((el) => el.stepName === "Profile Mentoring")
        ?.steps?.find((el) => el.stepName === "QPMC 1")?.steps;

      let arr1 = Tabsteps1.filter(({ id }) => id === newValue);

      let newSectionId1 = arr1.length !== 0 ? arr1[0]["id"] : null;
      let Tabsteps2 = studentStages?.data
        ?.find((el) => el.stepName === "Profile Mentoring")
        ?.steps?.find((el) => el.stepName === "QPMC 2")?.steps;

      let arr2 = Tabsteps2.filter(({ id }) => id === newValue);

      let newSectionId2 = arr2.length !== 0 ? arr2[0]["id"] : null;

      let Tabsteps3 = studentStages?.data
        ?.find((el) => el.stepName === "Profile Mentoring")
        ?.steps?.find((el) => el.stepName === "Worksheets")?.steps;

      let arr3 = Tabsteps3.filter(({ id }) => id === newValue);

      let newSectionId3 = arr3.length !== 0 ? arr3[0]["id"] : null;
      setState({
        ...state,
        activeSubTabValue: arr3?.[0]?.["stepName"]
          ? arr3?.[0]?.["stepName"]
          : arr2?.[0]?.["stepName"]
          ? arr2?.[0]?.["stepName"]
          : arr1?.[0]?.["stepName"],
        activeStateValue: newValue,
        SubsectionId: newSectionId1
          ? newSectionId1
          : newSectionId2
          ? newSectionId2
          : newSectionId3,
      });
    }
  };

  const renderTabs = () => {
    let Tabsteps = studentStages?.data?.find(
      (el) => el.stepName === "Profile Mentoring"
    )?.steps;
    return Tabsteps !== 0
      ? Tabsteps?.map(({ sectionName, id, stepName }, index) => (
          <CustomTab
            value={stepName}
            label={stepName}
            id={`${id}${index}`}
            minHeight={"72px"}
          />
        ))
      : null;
  };

  const handleSnackClose = () => {
    setState({ ...state, snackOpen: false, snackVariant: "", snackMsg: "" });
  };
  const handleQPMC1enable = () => {
    dispatch(getEnableCall(studentId, productId, "qpmc1enablemail"));
  };
  const handleQPMC2enable = () => {
    dispatch(getEnableCall(studentId, productId, "qpmc2enablemail"));
  };

  useEffect(() => {
    if (enableStage?.success === true) {
      setState({
        ...state,
        snackOpen: true,
        snackMsg: "Mail Sent To User",
        snackVariant: "success",
      });
      setQpmc1Notif(false);
      dispatch(clearCustomData("enableStage"));
      dispatch(getDisableButtonCall(studentId, productId));
      setTimeout(() => {
        console.log(enableStage);
        console.log(disableStage);
      }, 5000);
    }
  }, [enableStage]);

  const handleClickAway = () => {
    setState({ ...state, anchorEl: null, popoverComment: null });
  };
  let TabstepsQPMC1 = studentStages?.data
    ?.find((el) => el.stepName === "Profile Mentoring")
    ?.steps?.find((el) => el.stepName === "QPMC 1")?.steps;
  let TabstepsQPMC2 = studentStages?.data
    ?.find((el) => el.stepName === "Profile Mentoring")
    ?.steps?.find((el) => el.stepName === "QPMC 2")?.steps;

  let TabstepsQPMC3 = studentStages?.data
    ?.find((el) => el.stepName === "Profile Mentoring")
    ?.steps?.find((el) => el.stepName === "Worksheets")?.steps;

  let a = [];
  if (TabstepsQPMC1) {
    a = [...TabstepsQPMC1]?.sort((a, b) => a.orderNo - b.orderNo);
  }
  let b = [];
  if (TabstepsQPMC2) {
    b = [...TabstepsQPMC2]?.sort((a, b) => a.orderNo - b.orderNo);
  }
  let c = [];
  if (TabstepsQPMC3) {
    c = [...TabstepsQPMC3]?.sort((a, b) => a.orderNo - b.orderNo);
  }
  return (
    <div className={classes.preStrategyWorkSheetContainer}>
      <Grid container>
        <Grid item lg={12} xs={12} xl={12} md={12} sm={12}>
          <Box display={"flex"} alignItems={"center"}>
            <Box flex={1}>
              <CustomTabs value={activeTabValue} onChange={handleTabChange}>
                {renderTabs()}
              </CustomTabs>
            </Box>
          </Box>
          <Divider className={classes.dividerStyle} />
        </Grid>
        {activeTabValue === "QPMC 1" && (
          <>
            <StyledButton
              className={classes.enableButton}
              variant={"contained"}
              style={
                customTheme["palette"][
                  disableStage?.data?.enableQPMC1 === true
                    ? "disabled"
                    : "contained"
                ]
              }
              onClick={handleQPMC1enable}
              disabled={disableStage?.data?.enableQPMC1 === true}
            >
              {"Enable QPMC 1"}
            </StyledButton>
            {console.log(disableStage?.data?.enableQPMC1)}
            <Grid item lg={12} xs={12} xl={12} md={12} sm={12}>
              <Box display={"flex"} alignItems={"center"}>
                <Box flex={1}>
                  <CustomTabsPM
                    value={activeStateValue}
                    handleChange={handleSubTabChange}
                    tabList={a}
                    style={{ margin: "21px" }}
                  ></CustomTabsPM>
                </Box>
              </Box>
              <Divider className={classes.dividerStyle} />
            </Grid>
          </>
        )}

        {activeTabValue === "QPMC 2" && (
          <>
            <StyledButton
              className={classes.enableButton}
              variant={"contained"}
              style={
                customTheme["palette"][
                  disableStage?.data?.enableQPMC2 === true
                    ? "disabled"
                    : "contained"
                ]
              }
              onClick={handleQPMC2enable}
              disabled={disableStage?.data?.enableQPMC2 === true}
            >
              {"Enable QPMC 2"}
            </StyledButton>
            <Grid item lg={12} xs={12} xl={12} md={12} sm={12}>
              <Box display={"flex"} alignItems={"center"}>
                <Box flex={1}>
                  <CustomTabsPM
                    value={activeStateValue}
                    handleChange={handleSubTabChange}
                    tabList={b}
                    style={{ margin: "21px" }}
                  ></CustomTabsPM>
                </Box>
              </Box>
              <Divider className={classes.dividerStyle} />
            </Grid>
          </>
        )}

        {activeTabValue === "Worksheets" && (
          <>
            {/* <StyledButton
              className={classes.enableButton}
              variant={"contained"}
              style={
                customTheme["palette"][
                  disableStage?.data?.enableQPMC2 === true
                    ? "disabled"
                    : "contained"
                ]
              }
              onClick={handleQPMC2enable}
              disabled={disableStage?.data?.enableQPMC2 === true}
            >
              {"Enable QPMC 2"}
            </StyledButton> */}
            <Grid item lg={12} xs={12} xl={12} md={12} sm={12}>
              <Box display={"flex"} alignItems={"center"}>
                <Box flex={1}>
                  <CustomTabsPM
                    value={activeStateValue}
                    handleChange={handleSubTabChange}
                    tabList={c}
                    style={{ margin: "21px" }}
                  ></CustomTabsPM>
                </Box>
              </Box>
              <Divider className={classes.dividerStyle} />
            </Grid>
          </>
        )}
        {activeTabValue && activeSubTabValue && (
          <Grid item lg={12} xs={12} xl={12} md={12} sm={12}>
            {renderComponent()}
          </Grid>
        )}
      </Grid>
      <CommentBoxPopper
        anchorEl={anchorEl}
        handleClickAway={handleClickAway}
        popperComment={popoverComment}
      />
      <MySnackBar
        onClose={handleSnackClose}
        snackOpen={snackOpen}
        snackVariant={snackVariant}
        snackMsg={snackMsg}
      />
      <Backdrop className={classes.backdrop} open={loading}>
        <Loader />
      </Backdrop>
    </div>
  );
}

export default Index;
