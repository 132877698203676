import {
  Box,
  Button,
  createTheme,
  IconButton,
  TextField,
  ThemeProvider,
  Grid,
  Typography,
  InputLabel,
  Divider,
} from "@material-ui/core";
import { Fragment } from "react";
import { isNumber, isAlpha } from "../../../../Component/Validation";
import { AddRounded, DeleteRounded, Label } from "@material-ui/icons";
import React, { useState } from "react";
import CheckedIcon from "../../../Assets/icons/Checked.svg";
import UnCheckedIcon from "../../../Assets/icons/UnChecked.svg";
import {
  C1,
  Checkbox,
  EditorBox,
  FormControlLabel,
  T1,
} from "../../../Assets/StyledTest";
import TextEditor from "../../../Utils/TextEditor";
import DeleteIcon from "@material-ui/icons/Delete";
import { OutlinedInput, InputAdornment } from "@material-ui/core";
import { AVOID_INPUT } from "../../../Constants";
import CKEditorDropDown from "../../../Utils/CKEditorDropDown";
// import Tab from "@mui/material/Tab";
// import TabContext from "@mui/lab/TabContext";
// import TabList from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@mui/material/Tabs";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabell from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Radio, MultiLabel } from "../../../Assets/StyledTest";
import { AutocompleteText } from "../../../Utils/Autocomplete";
import { AutoSpanText } from "../../../Assets/StyledComponents";

const LETTERS = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
const aedept = window.sessionStorage.getItem("department");
const calibration = window.sessionStorage.getItem("testType");
console.log(aedept);
function Choice(props) {
  const {
    handleCheckBoxes,
    handleAddOption,
    handleImageUpload,
    bucketArray,
    activeTab,
    handleDeleteIconClick,
    handleTextChange,
    handleTextChange1,
    handleUnitChange,
    answerType,
    text,
    unit,
    fraction,
    handleDeleteChoiceClick,
    isShowQuestionDropDown,
    questionType,
    subjectName,
    isDataInsights,
    handleDropDown,
    multiHandleRadioChange,
    multiAnswerType,
    editData,
    handleDescriptionChangeMulti,
    multiDescription,
    tabValue,
    handleChangeTab,
    handleInstructionChange,
    selectOnDelete,
    firstSelectedName,
    secondSelectedName,
    handleFirstSelect,
    handleSecondSelect,
  } = props;

  console.log(bucketArray, "bucketArray", answerType);

  const option = (option) => [
    { title: "Select", value: "Select", id: "default", disabled: true },
    ...option.map((item, index) => ({
      title: item,
      value: item,
      id: item,
    })),
  ];
  const tabValues = [0, 1, 2];

  if (answerType) {
    return (
      <div>
        {answerType === "MULTI_TAB" && (
          <Box sx={{ width: "100%", typography: "body1" }}>
            <>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  onChange={handleChangeTab}
                  value={tabValue}
                  aria-label="lab API tabs example"
                >
                  {tabValues.map((value) => (
                    <Tab
                      key={value}
                      label={`Item ${value + 1}`}
                      value={value}
                    />
                  ))}
                </Tabs>
              </Box>
              {tabValues.map((index) => {
                console.log(index, "pokjjj", multiDescription);
                return (
                  <Fragment key={index}>
                    <div hidden={tabValue !== index}>
                      <TabPanel value={tabValue} index={index}>
                        <EditorBox>
                          <TextEditor
                            onChange={(event, editor) =>
                              handleDescriptionChangeMulti(event, editor)
                            }
                            data={multiDescription[index]["description"]}
                          />
                        </EditorBox>
                      </TabPanel>
                    </div>
                  </Fragment>
                );
              })}
            </>

            <FormControl component="fieldset">
              <RadioGroup
                aria-label="choice"
                name="row-radio-buttons-group"
                style={{ flexDirection: "row" }}
                onChange={multiHandleRadioChange}
                value={answerType || multiAnswerType}
              >
                <FormControlLabell
                  value={"MULTI_TAB" || "MULTIBINARY"}
                  control={<Radio color="primary" />}
                  label={
                    <MultiLabel
                      active={
                        answerType === "MULTI_TAB" ||
                        multiAnswerType === "MULTIBINARY"
                      }
                    >
                      Binary
                    </MultiLabel>
                  }
                  disabled={editData}
                />
                {/* <FormControlLabell
                  value="MULTITWO_PART"
                  control={<Radio color="primary" />}
                  label={
                    <MultiLabel active={multiAnswerType === "MULTITWO_PART"}>
                      Two Part
                    </MultiLabel>
                  }
                  disabled={editData}
                />
                <FormControlLabell
                  value="MULTIDROP_DOWN"
                  control={<Radio color="primary" />}
                  label={
                    <MultiLabel active={multiAnswerType === "MULTIDROP_DOWN"}>
                      Dropdown
                    </MultiLabel>
                  }
                  disabled={editData}
                /> */}
              </RadioGroup>
            </FormControl>
          </Box>
        )}

        {answerType === "TWO_PART" && (
          <Grid style={{ gap: "1rem", display: "flex" }}>
            <TextField
              id="outlined-basic"
              label="First Column"
              variant="outlined"
              value={firstSelectedName}
              onChange={handleFirstSelect}
            />
            <TextField
              id="outlined-basic"
              label="Second Column"
              variant="outlined"
              value={secondSelectedName}
              onChange={handleSecondSelect}
            />
          </Grid>
        )}
        {bucketArray[activeTab] &&
          // multiAnswerType !== "true" &&
          bucketArray[activeTab].choices &&
          bucketArray[activeTab].choices.length !== 0 &&
          bucketArray[activeTab].choices.map((choice, index) => {
            // console.log(choice.unit)
            console.log(bucketArray[activeTab].choices);

            console.log(choice, choice.selected);
            console.log(choice.unit);
            return (
              <div>
                {(answerType === "DROP_DOWN" ||
                  multiAnswerType === "MULTIDROP_DOWN") && (
                  <Grid style={{ margin: "10px" }}>
                    <AutocompleteText
                      autoData={{
                        label: "Test Instruction Details",
                        placeholder: "List The Instruction",
                        title: "Type the content and press enter",
                        value:
                          choice.dropDownList !== null
                            ? choice.dropDownList
                            : [],
                        onChange: (e) => handleInstructionChange(e, index),
                        onDelete: (e) => selectOnDelete(e, index),
                        name: "name",
                      }}
                    />
                    <AutoSpanText>
                      Please type and press the 'Enter' button
                    </AutoSpanText>
                  </Grid>
                )}
                <C1>
                  {answerType !== "SUBJECTIVE" && answerType !== "FRACTION" && (
                    <T1>{LETTERS[index]}</T1>
                  )}

                  <Box flex={1} style={{ width: "80%" }}>
                    {answerType === "FRACTION" ? (
                      <Grid container style={{ display: "flex", gap: "13px" }}>
                        <Grid item md={12} style={{ display: "flex" }}>
                          <Grid item md={3}>
                            <InputLabel style={{ padding: "10px" }}>
                              Enter Numerator
                            </InputLabel>
                          </Grid>
                          <Grid item md={4}>
                            <TextField
                              type={"number"}
                              onKeyPress={(evt) => {
                                if (isNumber(evt)) evt.preventDefault();
                              }}
                              key={index}
                              value={choice.text}
                              onChange={(e) => handleTextChange(e, index)}
                              variant={"outlined"}
                              fullWidth
                              multiline
                            />
                          </Grid>
                          <Grid item md={3} style={{ marginLeft: "125px" }}>
                            <TextField
                              // {...params}
                              onKeyPress={(evt) => {
                                if (isAlpha(evt)) evt.preventDefault();
                              }}
                              value={choice.unit}
                              onChange={(e) => handleUnitChange(e, index)}
                              label={"Optional"}
                              placeholder={"Enter unit"}
                              variant={"outlined"}
                              // fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid item md={12} style={{ display: "flex" }}>
                          <Grid md={3}></Grid>{" "}
                          <Grid item md={4}>
                            <Divider
                              style={{
                                width: "45vh",
                                // marginLeft: "342px",
                                background: "#D2D2D2",
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item md={12} style={{ display: "flex" }}>
                          <Grid md={3}>
                            {" "}
                            <InputLabel style={{ padding: "10px" }}>
                              Enter Denominator
                            </InputLabel>
                          </Grid>
                          <Grid item md={4}>
                            <TextField
                              type={"number"}
                              onKeyPress={(evt) => {
                                if (isNumber(evt)) evt.preventDefault();
                              }}
                              key={index}
                              value={choice.text1}
                              onChange={(e) => handleTextChange1(e, index)}
                              variant={"outlined"}
                              fullWidth
                              multiline
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )}

                    {answerType !== "SUBJECTIVE" ? (
                      <></>
                    ) : (
                      <Grid container spacing={2}>
                        <Grid
                          item
                          md={12}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Grid item md={isShowQuestionDropDown ? 6 : 12}>
                            <TextField
                              key={index}
                              value={choice.text}
                              onChange={(e) => handleTextChange(e, index)}
                              variant={"outlined"}
                              fullWidth
                              multiline
                            />
                          </Grid>
                          {isShowQuestionDropDown ? (
                            <Grid item md={5}>
                              <TextField
                                key={index}
                                value={choice.unit}
                                onKeyPress={(evt) => {
                                  if (isAlpha(evt)) evt.preventDefault();
                                }}
                                onChange={(e) => handleUnitChange(e, index)}
                                label={"Optional"}
                                placeholder={"Enter unit"}
                                variant={"outlined"}
                                // fullWidth
                              />
                            </Grid>
                          ) : null}
                        </Grid>
                      </Grid>
                    )}
                    {answerType !== "SUBJECTIVE" &&
                    answerType !== "FRACTION" ? (
                      <div className="ckeditorwidth">
                        <TextEditor
                          data={choice.text}
                          onChange={(e, editorDate) =>
                            handleTextChange(
                              { target: { value: editorDate.getData() } },
                              index
                            )
                          }
                          key={index}
                        />
                        {/* {answerType === "DROP_DOWN" && (
                        <div style={{ position: "relative" }}>
                          <Grid item xs={2} md={2}>
                            <CKEditorDropDown
                              name="Map Error Genre" */}
                        {(answerType === "DROP_DOWN" ||
                          multiAnswerType === "MULTIDROP_DOWN") && (
                          <div style={{ position: "relative" }}>
                            <Grid item xs={2} md={2}>
                              <CKEditorDropDown
                                items={option(choice.dropDownList)}
                                value={choice.dropDown || "default"}
                                onChange={(e) => handleDropDown(e, index)}
                              />
                            </Grid>
                          </div>
                        )}
                      </div>
                    ) : null}
                  </Box>

                  {(answerType === "SINGLE_SELECT" ||
                    answerType === "MULTI_CHOICE" ||
                    answerType === "SUBJECTIVE") &&
                    answerType !== "DROP_DOWN" &&
                    multiAnswerType !== "MULTIDROP_DOWN" && (
                      <FormControlLabel
                        disabledRipple
                        control={
                          <Checkbox
                            value={index}
                            checked={choice.selected}
                            onChange={(e) => handleCheckBoxes(e)}
                            disableRipple
                            name={"selected"}
                            icon={<img src={UnCheckedIcon} alt="" />}
                            checkedIcon={<img src={CheckedIcon} alt="" />}
                          />
                        }
                      />
                    )}

                  {/* {calibration === "CALIBRATION" && isDataInsights && ( */}
                  {(answerType === "BINARY" ||
                    answerType === "TWO_PART" ||
                    answerType === "MULTI_TAB" ||
                    multiAnswerType === "MULTIBINARY" ||
                    multiAnswerType === "MULTITWO_PART") && (
                    <Grid style={{ display: "flex" }}>
                      <FormControlLabel
                        disabledRipple
                        control={
                          <Checkbox
                            value={index}
                            checked={choice.firstSelected}
                            onChange={(e) => handleCheckBoxes(e)}
                            disableRipple
                            name={"firstSelected"}
                            icon={<img src={UnCheckedIcon} alt="" />}
                            checkedIcon={<img src={CheckedIcon} alt="" />}
                          />
                        }
                      />
                      <FormControlLabel
                        style={{ width: "2%" }}
                        disabledRipple
                        control={
                          <Checkbox
                            value={index}
                            name={"secondSelected"}
                            checked={choice.secondSelected}
                            onChange={(e) => handleCheckBoxes(e)}
                            disableRipple
                            icon={<img src={UnCheckedIcon} alt="" />}
                            checkedIcon={<img src={CheckedIcon} alt="" />}
                          />
                        }
                      />
                    </Grid>
                  )}
                  {answerType === "FRACTION" || answerType === "SUBJECTIVE" ? (
                    <></>
                  ) : (
                    <IconButton
                      color="secondary"
                      onClick={() => handleDeleteChoiceClick(index)}
                      style={{ marginTop: "-9px" }}
                    >
                      <DeleteRounded />
                    </IconButton>
                  )}
                </C1>
              </div>
            );
          })}

        {answerType !== "SUBJECTIVE" && answerType !== "FRACTION" && (
          <ThemeProvider theme={buttonTheme}>
            <Button
              variant="text"
              startIcon={<AddRounded />}
              onClick={handleAddOption}
            >
              Add Option
            </Button>
          </ThemeProvider>
        )}
      </div>
    );
  } else return null;
}

const buttonTheme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "16px",
        textAlign: "right",
        background: "white",
        color: "#1093FF",
        textTransform: "none",
        "&:hover": {
          backgroundColor: "white",
        },
        marginLeft: "40px",
        marginTop: "26px",
      },
    },
  },
});

export default Choice;
