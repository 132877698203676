import { ADMIN } from "../Redux/Action";
import axios from "axios";
import { URL } from "../Actions/URL";
import { errorHandler } from "../Component/Utils/Helpers";

export const adminLogin = (data, callback) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .put(URL + "/api/v1/students/validateAdmin", data, {
        crossDomain: true,
      })
      .then((result) => {
        callback(result);
        dispatch({ type: ADMIN.adminLogin, adminLoginDetails: result.data });
      })
      .catch((error) => {
        callback(error);
        // dispatch({type:ADMIN.adminLogin,adminLoginDetails:error.response.data})
      });
  };
};
export const loginWithSso = (data) => {
  return axios.put(URL + "/api/v1/students/validateAdmin", data);
};

export const toRefreshToken = (data) => {
  let refreshToken = window.sessionStorage.getItem("refreshToken");
  return (dispatch) => {
    axios
      .get(URL + "/api/v1/refresh/token", {
        crossDomain: true,
        headers: {
          "x-refresh-token": refreshToken,
          //admin: 'yes',
        },
      })
      .then((result) => {
        dispatch({
          type: ADMIN.refreshToken,
          refreshTokenDetails: result.data,
        });
      })
      .catch((error) => {});
  };
};

export const postStudentAccess = (data) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return (dispatch) => {
    axios
      .put(URL + "/api/v1/students/unifiedAccess", data, {
        crossDomain: true,
        headers: {
          //admin: 'yes',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        dispatch({
          type: ADMIN.studentAccess,
          studentAccessResponse: result.data,
        });
      })
      .catch((error) => {});
  };
};

export const updateStudentPersonal = (id, data) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .put(URL + "/api/v1/student/" + id + "/personalDetails", data, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        dispatch({
          type: ADMIN.updatePersonalData,
          updatePersonalResponse: result.data,
        });
      })
      .catch((error) => {});
  };
};

export const updateStudentEducation = (id, data) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return (dispatch) => {
    axios
      .put(URL + "/api/v1/student/" + id + "/educationaldetails", data, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        dispatch({
          type: ADMIN.updateEducationalData,
          updateEducationalonalResponse: result.data,
        });
      })
      .catch((error) => {});
  };
};

export const updateStudentContact = (id, data) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .put(URL + "/api/v1/student/" + id + "/contactDetails", data, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        dispatch({
          type: ADMIN.updateContactData,
          contactDataResponse: result.data,
        });
      })
      .catch((error) => {});
  };
};

export const updateAccountStatus = (id, data) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .put(URL + "/api/v1/student/" + id + "/accountstatus", data, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        dispatch({
          type: ADMIN.updateAccountStatus,
          updateAccStatusResponse: result.data,
        });
      })
      .catch((error) => {});
  };
};

export const updateInternAccess = (id, data) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .put(
        URL + "/api/v1/students/olduser/" + id + "/" + data,
        {},
        {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((result) => {
        dispatch({ type: ADMIN.giveInternAccess, payload: result.data });
      })
      .catch((error) => {});
  };
};

export const updateLmsAccess = (id, data) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .put(URL + "/api/v1/student/" + id + "/accountstatus", data, {
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        dispatch({ type: ADMIN.updateLmsAccess, payload: result.data });
      })
      .catch((error) => {});
  };
};

export const updateAspirationData = (id, data) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return (dispatch) => {
    axios
      .put(URL + "/aspiration/update/" + id, data, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        dispatch({ type: ADMIN.updateAspirationData, payload: result.data });
      })
      .catch((error) => {});
  };
};

export const viewStudentStatus = (id) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return (dispatch) => {
    axios
      .get(URL + "/api/v1/studentVerification/view/" + id, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        dispatch({
          type: ADMIN.viewStudentStatus,
          studentStatusResponse: result.data,
        });
      })
      .catch((error) => {});
  };
};

export const updateVerificationStatus = (data, callback) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return (dispatch) => {
    axios
      .put(URL + "/api/v1/studentVerification/update", data, {
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
        crossDomain: true,
      })
      .then((result) => {
        callback(result);
        dispatch({
          type: ADMIN.updateVerificationStatus,
          updateVerificationResponse: result.data,
        });
      })
      .catch((error) => {});
  };
};

export const getAllMentors = (productId) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return (dispatch) => {
    axios
      .get(URL + "/api/v1/products/" + productId + "/mentors", {
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        dispatch({ type: ADMIN.getAllMentor, payload: result.data });
      })
      .catch((error) => {});
  };
};

export const allocateMentor = (mentorId, studentId) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return (dispatch) => {
    axios
      .post(
        URL + "/api/v1/student/mentor/" + studentId + "/" + mentorId,
        {},
        {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((result) => {
        dispatch({ type: ADMIN.alocateMentor, payload: result.data });
      })
      .catch((error) => {});
  };
};
export const deletementor = (studentId, productId, callback) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .delete(
        URL +
          "/api/v1/students/" +
          studentId +
          "/product/" +
          productId +
          "/mentor",
        {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((result) => {
        callback(result);
        dispatch({ type: ADMIN.deletementor, payload: result.data });
      })
      .catch((error) => {
        callback(error);
      });
  };
};
export const getStagesCount = (
  productId,
  stageName,
  size,
  page,
  intake,
  keyword
) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  let adminId = window.sessionStorage.getItem("adminUserId");
  return (dispatch) => {
    dispatch({ type: ADMIN.loader });
    axios
      .get(
        `${URL}/api/v1/admin/dashboard/product/${productId}/stages/count?stageType=${stageName}`,
        {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((result) => {
        dispatch({
          type: ADMIN.getCount,
          payload: { success: true, data: result.data, loading: false },
        });
      })
      .catch((error) => {
        dispatch(errorHandler(ADMIN.getCount, error, false));
      });
  };
};
export const getUserGuide = (productId) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    // dispatch({ type: ADMIN.loader });
    axios
      .get(`${URL}/api/v1/products/${productId}/userguidelink?userType=Admin`, {
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
        // responseType: "blob",
      })
      .then((result) => {
        // console.log(result, "bjhbjhbj");
        window.open(result?.data?.data);
        // var filename = "Unified_Portal_OpsAdminUserGuide";
        // const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));
        // const link = document.createElement('a');
        // link.href = downloadUrl;
        // link.setAttribute('download', `${filename}.pdf`);
        // document.body.appendChild(link);
        // link.click();
        // link.remove();
        dispatch({
          type: ADMIN.getGuide,
          payload: result.data,
        });
      })
      .catch((error) => {
        // dispatch(errorHandler(ADMIN.getGuide, error, false));
      });
  };
};
export const getStageDropDown = (
  productId,
  stageName,
  size,
  page,
  intake,
  keyword
) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  let adminId = window.sessionStorage.getItem("adminUserId");
  return (dispatch) => {
    dispatch({ type: ADMIN.loader });
    axios
      .get(`${URL}/api/v1/admin/dashboard/product/${productId}/dropdown`, {
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        dispatch({
          type: ADMIN.getDropdown,
          payload: { success: true, data: result.data, loading: false },
        });
      })
      .catch((error) => {
        dispatch(errorHandler(ADMIN.getDropdown, error, false));
      });
  };
};

// For Getting List of users based on admin user Id

export const getAwaitingUsersByAdminId = () => {
  let adminUserId = window.sessionStorage.getItem("adminUserId");
  let product = JSON.parse(window.sessionStorage.getItem("adminLinkedProduct"));
  let accessToken = window.sessionStorage.getItem("accessToken");

  let productid = product.products[0].id;

  return (dispatch) => {
    axios
      .get(
        URL +
          "/api/v1/product/" +
          productid +
          "/admin/" +
          adminUserId +
          "/search?page=0&size=20&q=",
        {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((result) => {
        dispatch({
          type: ADMIN.getAwaitingUsersByAdminId,
          payload: result.data,
        });
      })
      .catch((error) => {});
  };
};

// TO Activate Student Product

export const activateStudentProduct = (data, callback) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  let id = window.sessionStorage.getItem("adminUserId");
  return (dispatch) => {
    axios
      .put(URL + "/api/v1/product/activate/" + id, data, {
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        dispatch({ type: ADMIN.activateStudentProduct, payload: result.data });
        callback(result);
      })
      .catch((error) => {
        callback(error);
      });
  };
};

// To get admin linked product

export const getAdminLinkedProduct = () => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  let id = window.sessionStorage.getItem("adminUserId");
  return (dispatch) => {
    axios
      .get(URL + "/api/v1/adminusers/" + id, {
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        window.sessionStorage.setItem(
          "adminDetails",
          JSON.stringify(result.data)
        );
        dispatch({ type: ADMIN.getAdminLinkedProduct, payload: result.data });
      })
      .catch((error) => {});
  };
};
export const getSearchingProduct = (productId, keyword) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  let id = window.sessionStorage.getItem("adminUserId");
  return (dispatch) => {
    axios
      .get(URL + "/api/v1/search/manage/student/" + productId, {
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          q: keyword ? keyword : "",
        },
      })
      .then((result) => {
        dispatch({
          type: ADMIN.getSearchingProductId,
          payload: { success: true, data: result.data, loading: false },
        });
      })
      .catch((error) => {
        dispatch(errorHandler(ADMIN.getSearchingProductId, error, false));
      });
  };
};
export const checkTokenStatus = () => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .get(URL + "/api/v1/token/status", {
        headers: {
          jwt: accessToken,
        },
      })
      .then((result) => {
        dispatch({ type: ADMIN.checkTokenStatus, payload: result.data });
      })
      .catch((error) => {});
  };
};

// To get students by stages

export const getStudentByStages = (
  productId,
  stageName,
  size,
  page,
  intake,
  keyword,
  stepName,
  studentCategory,
  report,
  fileName
) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  let adminId = window.sessionStorage.getItem("adminUserId");
  return (dispatch) => {
    dispatch({ type: ADMIN.loader });
    axios
      .get(
        `${URL}/api/v1/product/${productId}/admin/${adminId}/searchbystage`,
        {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            stage: stageName,
            size: size ? size : "",
            page: page ? page : "",
            intake: intake ? intake : "",
            q: keyword ? keyword : "",
            stepName: stepName ? stepName : "",
            export: report ? report : "",
            paidOrUnpaid: studentCategory
              ? studentCategory.toLowerCase()
              : "allusers",
          },
        }
      )
      .then((result) => {
        dispatch({
          type: ADMIN.getStudentsByStages,
          payload: {
            success: true,
            data: result.data,
            fileName: fileName,
            loading: false,
          },
        });
      })
      .catch((error) => {
        dispatch(errorHandler(ADMIN.getStudentsByStages, error, false));
      });
  };
};
export const downloadStudentByStages = (
  productId,
  stageName,
  size,
  page,
  intake,
  keyword,
  stepName,
  report,
  fileName
) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  let adminId = window.sessionStorage.getItem("adminUserId");
  return (dispatch) => {
    dispatch({ type: ADMIN.loader });
    axios
      .get(
        `${URL}/api/v1/product/${productId}/admin/${adminId}/searchbystage`,
        {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: "blob",
          params: {
            stage: stageName,
            size: size ? size : "",
            page: page ? page : "",
            intake: intake ? intake : "",
            q: keyword ? keyword : "",
            stepName: stepName ? stepName : "",
            export: report ? report : "",
          },
        }
      )
      .then((result) => {
        var filename = "Dashboard_Report";
        const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `${filename}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        dispatch({
          type: ADMIN.downloadStudentsByStages,
          payload: { success: true, data: result.data, loading: false },
        });
      })
      .catch((error) => {
        // dispatch(errorHandler(ADMIN.getStudentsByStages, error, false));
      });
  };
};
// To get manage student by stages

export const getManageStudentByStages = (
  productId,
  sectionName,
  stageName,
  size,
  page,
  intake,
  keyword,
  stepName,
  report
) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  let adminId = window.sessionStorage.getItem("adminUserId");
  return (dispatch) => {
    dispatch({ type: ADMIN.loader });
    axios
      .get(
        `${URL}/api/v1/product/${productId}/admin/${adminId}/section/${sectionName}`,
        {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            stage: stageName,
            size: size ? size : "",
            page: page ? page : "",
            intake: intake ? intake : "",
            q: keyword ? keyword : "",
            stepName: stepName ? stepName : "",
            export: report ? report : "",
          },
        }
      )
      .then((result) => {
        dispatch({
          type: ADMIN.getManageStudentsByStages,
          payload: { success: true, data: result.data, loading: false },
        });
      })
      .catch((error) => {
        dispatch(errorHandler(ADMIN.getManageStudentsByStages, error, false));
      });
  };
};
export const downloadManageStudentByStages = (
  productId,
  sectionName,
  stageName,
  size,
  page,
  intake,
  keyword,
  stepName,
  report
) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  let adminId = window.sessionStorage.getItem("adminUserId");
  return (dispatch) => {
    dispatch({ type: ADMIN.loader });
    axios
      .get(
        `${URL}/api/v1/product/${productId}/admin/${adminId}/section/${sectionName}`,
        {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: "blob",
          params: {
            stage: stageName,
            size: size ? size : "",
            page: page ? page : "",
            intake: intake ? intake : "",
            q: keyword ? keyword : "",
            stepName: stepName ? stepName : "",
            export: report ? report : "",
          },
        }
      )
      .then((result) => {
        var filename = "Dashboard_Report";
        const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `${filename}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        dispatch({
          type: ADMIN.downloadManageStudentsByStages,
          payload: { success: true, data: result.data, loading: false },
        });
      })
      .catch((error) => {
        // dispatch(errorHandler(ADMIN.getStudentsByStages, error, false));
      });
  };
};

// To get all admin users

export const getAllAdminUsers = () => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  return (dispatch) => {
    axios
      .get(URL + "/api/v1/adminUsers", {
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        dispatch({ type: ADMIN.getAllAdminUsers, payload: result.data });
      })
      .catch((error) => {});
  };
};

export const getAdminUserDepartments = () => {
  return (dispatch) => {
    axios
      .get(`${URL}/api/v1/user/department`, {
        crossDomain: true,
      })
      .then((result) => {
        dispatch({
          type: ADMIN.getAdminUserDepartments,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch(errorHandler(ADMIN.getAdminUserDepartments, error, false));
      });
  };
};

export const clearCustomData = (fieldName) => {
  return (dispatch) => {
    dispatch({
      type: ADMIN.clearCustomData,
      fieldName: fieldName,
    });
  };
};

export const clearData = () => {
  return (dispatch) => {
    dispatch({ type: ADMIN.clearData });
  };
};

export const getReportProduct = (id) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return (dispatch) => {
    axios
      .get(`${URL}/api/v1/get/product/productFamily/${id}`, {
        crossDomain: true,
        headers: {
          admin: "yes",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        dispatch({
          type: ADMIN.getProductsInReports,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch(errorHandler(ADMIN.getProductsInReports, error, false));
      });
  };
};
export const sendForReport = (studentId, productId) => {
  let accessToken = window.sessionStorage.getItem("accessToken");

  let id = window.sessionStorage.getItem("adminUserId");
  return (dispatch) => {
    axios
      .get(
        URL +
          "/api/v1/students/" +
          studentId +
          "/product/" +
          productId +
          "/sendPgaReport",
        {
          headers: {
            admin: "yes",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((result) => {
        dispatch({
          type: ADMIN.report,
          payload: { success: true, data: result.data, loading: false },
        });
      })
      .catch((error) => {
        dispatch(errorHandler(ADMIN.report, error, false));
      });
  };
};
