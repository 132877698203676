import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import { Grid } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import EventIcon from '@material-ui/icons/Event';
import Controls from '../../Utils/controls/Controls';
import { useDispatch } from 'react-redux';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { scheduleTest } from '../../../Actions/TestActions';
import Notification from '../../Utils/Notification';
import CloseIcon from '@material-ui/icons/Close';
import { DateTimePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(5),
  },
  dialogTitle: {
    textAlign: 'center',
    padding: 0,
  },
  dialogContent: {
    textAlign: 'center',
  },
  dialogAction: {
    justifyContent: 'center',
    marginTop: '10px',
  },
  titleIcon: {
    backgroundColor: 'rgba(84,179,230,0.20)',
    padding: '1.5rem',
    margin: '1rem',
    color: theme.palette.primary.main,
    '& .MuiSvgIcon-root': {
      fontSize: '3rem',
    },
  },
}));

export default function ScheduleLater(props) {
  const { scheduler, setScheduler, data, type, listWallPosts, page, searchValue } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });

  const validate = (values) => {
    /* Validating the timings */
    if (
      moment(values.eventEndDate).isSameOrBefore(values.eventDate) ||
      moment(values.eventDate).isBefore(moment()) ||
      moment(values.eventEndDate).isBefore(moment())
    ) {
      setNotify({
        isOpen: true,
        message: 'Please add proper timing & date',
        type: 'error',
      });
      return false;
    }
    return true;
  };

  const onSubmit = (id, dates) => {
    // console.log(id, dates, 'repost');
    dispatch(scheduleTest(id, dates));
    setScheduler(false);
    setNotify({
      isOpen: true,
      message: 'Schedule Updated',
      type: 'success',
    });
    setTimeout(() => {
      dispatch(listWallPosts(type, true, page, searchValue));
    }, 1200);
  };

  return (
    <>
      <Dialog open={scheduler} classes={{ paper: classes.dialog }}>
        <CloseIcon
          fontSize='medium'
          onClick={() => setScheduler(false)}
          style={{ position: 'relative', marginLeft: 'auto', cursor: 'pointer' }}
        />
        <DialogTitle className={classes.dialogTitle}>
          <IconButton disableRipple className={classes.titleIcon}>
            <ScheduleIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography variant='h6' style={{ marginBottom: '2rem' }}>
            Repost the test for later
          </Typography>
          <Formik
            initialValues={data || []}
            onSubmit={(values) => {
              // console.log(values, 'vijay');
              if (validate(values)) {
                let scheduleDate = {
                  startDateTime: values.eventDate,
                  endDateTime: values.eventEndDate,
                };
                onSubmit(values?.linkedTestQuestionSetId, scheduleDate);
              }
            }}
            enableReinitialize
          >
            {({ handleSubmit, values, setFieldValue }) => (
              <>
                <div className='CreateTest'>
                  <Form onSubmit={handleSubmit} autoComplete='off'>
                    <Grid
                      item
                      container
                      direction='row'
                      justifyContent='space-around'
                      style={{ width: '100%' }}
                    >
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DateTimePicker
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position='start'>
                                <EventIcon />
                              </InputAdornment>
                            ),
                          }}
                          style={{ width: '100%', marginBottom: '1.5rem' }}
                          value={values.eventDate}
                          name='eventDate'
                          inputVariant='outlined'
                          label='Start Date & Time'
                          disablePast
                          onChange={(val) => {
                            setFieldValue('eventDate', val);
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DateTimePicker
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position='start'>
                                <EventIcon />
                              </InputAdornment>
                            ),
                          }}
                          disablePast
                          style={{ width: '100%' }}
                          value={values.eventEndDate}
                          name='eventEndDate'
                          inputVariant='outlined'
                          label='End Date & Time'
                          onChange={(val) => {
                            setFieldValue('eventEndDate', val);
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <DialogActions className={classes.dialogAction}>
                      <Controls.Button
                        variant='outlined'
                        text='Cancel'
                        color='primary'
                        onClick={() => setScheduler(false)}
                      />
                      <Controls.Button text='Repost' type='submit' color='primary' />
                    </DialogActions>
                  </Form>
                </div>
              </>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
