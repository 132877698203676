import React from "react";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    minHeight: "250px !important",
    display: "contents",
    "& .MuiAutocomplete-inputRoot": {
      minHeight: "250px",
      alignItems: "start",
      alignContent: "flex-start",
    },
  },
});

export const AutocompleteText = (props) => {
  const classes = useStyles();
  const {
    onChange,
    value,
    label,
    placeholder,
    title,
    key,
    onDelete,
    name,
  } = props.autoData;

  const inputProps = name === "name" ? { endAdornment: null } : {};

  return (
    <Autocomplete
      key={key !== undefined && key}
      title={title !== undefined && title}
      classes={{ root: name === "name" ? "" : classes.root }}
      multiple
      onChange={onChange !== undefined && onChange}
      options={[]}
      freeSolo
      value={value !== null ? value : []}
      // rules={required:true}
      renderTags={(value, getTagProps) =>
        value.map((option, index) =>
          onDelete === undefined ? (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ) : (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              onDelete={() => onDelete(option)}
            />
          )
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          required
          variant={"outlined"}
          InputProps={{
            ...params.InputProps,
            ...inputProps,
          }}
          label={label !== undefined && label}
          placeholder={placeholder !== undefined && placeholder}
        />
      )}
    />
  );
};
