import { POST_ADMIT_SERVICES } from "../Redux/Action";

const initialState = {
  loading: false,
  PostAdmitServicesDropDown: null,
  momPoints: [],
  savePoints: "",
  getDocuments: [],
  downloadDocuments: "",
  getCountry: null,
  getRegion: null,
  postFileUpload: null,
  getFiles: null,
  deleteFiles: null,
};

const PostAdmitServicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_ADMIT_SERVICES.PostAdmitServicesDropDown:
      return {
        ...state,
        PostAdmitServicesDropDown: action.payload,
        loading: action.loading,
      };
    case POST_ADMIT_SERVICES.getCountry:
      return {
        ...state,
        getCountry: action.payload,
        loading: action.loading,
      };
    case POST_ADMIT_SERVICES.getRegion:
      return {
        ...state,
        getRegion: action.payload,
        loading: action.loading,
      };
    case POST_ADMIT_SERVICES.savePoints:
      return {
        ...state,
        savePoints: action.savePoints,
      };

    case POST_ADMIT_SERVICES.momPoints:
      return {
        ...state,
        momPoints: action.payload,
      };
    case POST_ADMIT_SERVICES.getDocuments:
      return {
        ...state,
        getDocuments: action.payload,
      };
    case POST_ADMIT_SERVICES.downloadDocuments:
      return {
        ...state,
        downloadDocuments: action.payload,
        loading: action.loading,
      };
    case POST_ADMIT_SERVICES.getCardDetails:
      return {
        ...state,
        getCardDetails: action.payload,
      };

    case POST_ADMIT_SERVICES.postFileUpload:
      return {
        ...state,
        postFileUpload: action.payload,
        loading: action.loading,
      };
    case POST_ADMIT_SERVICES.getFiles:
      return {
        ...state,
        getFiles: action.payload,
        loading: action.loading,
      };
    case POST_ADMIT_SERVICES.deleteFiles:
      return {
        ...state,
        deleteFiles: action.payload,
        loading: action.loading,
      };
    case POST_ADMIT_SERVICES.clearData:
      return {};

    default:
      return state;
  }
};

export default PostAdmitServicesReducer;
