import { Backdrop, Box, Divider, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { withRouter } from "react-router-dom";
import {
  clearCustomData,
  getClosePostAdmitServices,
  getDocumentModelBySubStageId,
  getDownloadByDocumentId,
  getEnablePostAdmitServices,
  uploadFile,
} from "../../Actions/ProfileMentoring";
import {
  getStepsBySubStageId,
  getStudentStageByProductId,
} from "../../Actions/Student";
import MySnackBar from "../MySnackBar";
import { CommentBoxPopper } from "../Utils/controls/CommentBoxPopper";
import { CustomTab, CustomTabs } from "../Utils/controls/CustomTabComponent";
import { customTheme, StyledButton } from "../Utils/controls/Styles";

import Loader from "../Utils/controls/Loader";
import { getVariantStepsById } from "../../Actions/ProductAction";
import PreferenceIndex from "../SchoolResearchPreferenceList/Index";
import GroupImage from "../../Asset/Images/PostAdmitActive.svg";
import {
  bytesToMegaBytes,
  getSubStageByStage,
  textToDownloadFile,
} from "../Utils/Helpers";
// import DocumentComponent from "./DocumentComponent";
import { useStyles } from "../ProfileMentoring/Styles";
import { getproductstepsCall } from "../../Actions/ProductAction";
import PreferenceList from "../SchoolResearchPreferenceList/PreferenceList";
import { Typo } from "../Utils/controls/Styles";
import PrimaryButton from "../../Utils/PrimaryButton";
import DocumentComponent from "../ProfileMentoring/DocumentComponent";
import DocumentComponentForPostAdmit from "./DocumentComponent";
import MomPoints from "./MOM/Index";
import { DocumentComponentForAdmitCard } from "./UploadAdmitCard/DocumentComponent";
import DocumentCard from "./UploadAdmitCard/DocumentCard";
import AdmitIndex from "./UploadAdmitCard/Index";
// import DocumentComponentForAdmitCard from "./UploadAdmitCard/DocumentComponent";
import {
  DocumentDownloadPAS,
  getFilesPAS,
  deleteFilesPAS,
  uploadFilePAS,
  uploadFilePAScountry,
  uploadFilePASregion,
} from "../../Actions/PostAdmitServices";
import CustomPopup from "../Utils/controls/CustomPopup";
import CustomPopupPAS from "../Utils/controls/CustomPopup copy";
import CustomTabsPM from "../ProfileMentoring/additionalPoints/CustomTabsForProfileMentoring";
import MomInterview from "./MOM/MomIndex";

const FILE_FORMAT_ERROR = "Invalid file format";
const FILE_SIZE_ERROR = "Please check the file size";
const FILE_UPLOAD_ERROR = "Please select a file";
const UPLOADED_SUCCESS = "Uploaded Successfully";
const REQUIRED_ERROR = "Please fill the required field";

function Index(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const { studentId, productId } = params;
  const [stageDetails, setStageDetails] = useState("");
  const [documentTypeId, setDocumentTypeId] = useState("");
  const [countryOrRegionId, setCountryOrRegionId] = useState("");
  const [condition, setCondition] = useState("");
  const [openPopup, setOpenPopup] = useState("");

  const [postAdmitStatus, setPostAdmitStatus] = useState("");
  const [state, setState] = useState({
    steps: [],
    documentList: [],
    activeTabValue: "QPMC 1",
    sectionId: null,
    open: false,
    comment: null,
    upcomingFileName: null,
    fileName: null,
    fileNameHelperText: "",
    commentHelperText: "",
    file: null,
    snackMsg: "",
    snackOpen: false,
    snackVariant: "",
    status: null,
    anchorEl: null,
    popoverComment: null,
    completedStagesList: [],
    cvloader: false,
  });

  const {
    steps,
    documentList,
    sectionId,
    activeTabValue,
    activeStateValue,
    activeSubTabValue,
    open,
    comment,
    fileName,
    fileNameHelperText,
    commentHelperText,
    file,
    snackMsg,
    snackOpen,
    snackVariant,
    upcomingFileName,
    status,
    anchorEl,
    popoverComment,
    completedStagesList,
    cvloader,
  } = state;
  const {
    loading,
    documentModel,

    downloadFileResponse,
    PostAdmitServicesStatus,
  } = useSelector((state) => state.ProfileMentoringReducer);
  const { postFileUpload, getFiles } = useSelector(
    (state) => state.PostAdmitServicesReducer
  );

  const { studentStages, subStageSteps, completedStages } = useSelector(
    (state) => state.StudentReducer
  );
  const { getproductsteps } = useSelector((state) => state.ProductReducer);

  console.log(PostAdmitServicesStatus);

  useEffect(() => {
    dispatch(clearCustomData("postFileUpload"));
    dispatch(getStudentStageByProductId(studentId, productId));
    dispatch(
      getVariantStepsById(productId, (response) => {
        setStageDetails(response);
      })
    );
  }, []);

  useEffect(() => {
    console.log(stageDetails);
    let id = stageDetails?.steps?.find(
      (el) => el.stepName === "Profile Mentoring"
    ).id;
    console.log(id);
    dispatch(getproductstepsCall(id));
  }, [stageDetails]);

  console.log(stageDetails);
  console.log(getproductsteps);

  useEffect(() => {
    if (studentStages) {
      if (studentStages.success) {
        const { data } = studentStages;
        let subStage = getSubStageByStage(
          data,
          "Post Admit Services",
          "Visa interview Call"
        );
        if (subStage.length !== 0) {
          // dispatch(
          //   getStepsBySubStageId(
          //     studentId,
          //     productId,
          //     subStage[0]["id"],
          //     "Post Admit Services"
          //   )
          // );
        }
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackVariant: "error",
          snackMsg: studentStages.message,
        });
      }
    }
    let Tabsteps1 = studentStages?.data
      ?.find((el) => el.stepName === "Profild")
      ?.steps?.find((el) => el.stepName === "mmmm")?.steps;

    let arr1 = Tabsteps1?.find(
      ({ stepName }) => stepName === "Visa interview Call 1"
    );

    setState({
      ...state,
      activeTabValue: "Upload Admit Card",
      activeSubTabValue: "Visa interview Call 1",
      activeStateValue: arr1?.id,
    });
  }, [studentStages]);
  console.log(activeSubTabValue, "djjddjdj");
  useEffect(() => {
    if (subStageSteps) {
      if (subStageSteps.success) {
        let arr = subStageSteps.data;
        if (arr.length !== 0) {
          arr.sort(function(a, b) {
            return a.rank - b.rank;
          });
          setState({
            ...state,
            steps: arr,
            activeTabValue: "Visa interview Call",

            sectionId: arr.length !== 0 && arr[0]["id"],
          });
        }
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackVariant: "error",
          snackMsg: subStageSteps.message,
        });
      }
    }
  }, [subStageSteps]);
  console.log(activeTabValue);
  useEffect(() => {
    if (sectionId && activeTabValue === "QPMC 1") {
      dispatch(
        getDocumentModelBySubStageId(
          studentId,
          productId,
          "c4971d3a-b4a4-11ec-b909-0242ac120002"
        )
      );
    }
  }, [sectionId]);

  useEffect(() => {
    if (completedStages) {
      if (completedStages.success) {
        setState({
          ...state,
          completedStagesList: completedStages.data || [],
        });
      } else {
        setState({
          ...state,
          completedStagesList: [],
        });
      }
    }
  }, [completedStages]);

  useEffect(() => {
    if (documentModel) {
      if (documentModel.success) {
        const { data } = documentModel;
        setState({
          ...state,
          upcomingFileName: data.fileName,
          status: data.stepStatus,
          documentList: data.content || [],
        });
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackVariant: "error",
          snackMsg: documentModel.message,
          upcomingFileName: null,
          status: null,
          documentList: [],
        });
      }
      dispatch(clearCustomData("documentModel"));
    }
  }, [documentModel]);

  useEffect(() => {
    if (postFileUpload) {
      if (postFileUpload.success) {
        setState({
          ...state,
          snackOpen: true,
          snackVariant: "success",
          snackMsg: UPLOADED_SUCCESS,
          file: null,
          fileName: null,
          comment: null,
          fileNameHelperText: "",
          commentHelperText: "",
          open: false,
          cvloader: true,
        });
        dispatch(getFilesPAS(studentId, productId));
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackVariant: "error",
          snackMsg: postFileUpload.message,
        });
      }
      dispatch(clearCustomData("postFileUpload"));
    }
  }, [postFileUpload]);

  useEffect(() => {
    if (downloadFileResponse) {
      if (downloadFileResponse.success) {
        textToDownloadFile(
          downloadFileResponse.data,
          downloadFileResponse.fileName,
          downloadFileResponse.fileName.split(".").pop()
        );
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackVariant: "error",
          snackMsg: downloadFileResponse.message,
        });
      }
      dispatch(clearCustomData("downloadFileResponse"));
    }
  }, [downloadFileResponse]);
  useEffect(() => {
    console.log(studentStages);
    let postAdmitValue =
      studentStages?.data?.find((el) => el.stepName === "Post Admit Services")
        .status === "PROGRESS";
    console.log(postAdmitValue);
    setPostAdmitStatus(postAdmitValue);
    let arr = studentStages?.data?.find(
      (el) => el.stepName === "Post Admit Services"
    )?.steps;
    let arr1 = arr?.find(({ stepName }) => stepName === "Feedback Session")
      ?.steps;
    let arr2 = arr1?.find(({ stepName }) => stepName === "Feedback Session");
    console.log(arr2?.id, "call Session %%%%%");
  }, [studentStages]);

  let arr = studentStages?.data?.find(
    (el) => el.stepName === "Post Admit Services"
  )?.steps;
  console.log(arr);
  let arr1 = arr?.find(({ stepName }) => stepName === "Feedback Session")
    ?.steps;
  console.log(arr1, "PAS");
  let arr2 = arr1?.find(({ stepName }) => stepName === "Feedback Session Call");
  console.log(arr2?.id, "call Session %%%%%");
  let interview = studentStages?.data?.find(
    (el) => el.stepName === "Post Admit Services"
  )?.steps;
  console.log(arr);
  let interview1 = interview?.find(
    ({ stepName }) => stepName === "Visa interview Call"
  )?.steps;
  console.log(arr1, "PAS");
  let call1 = interview1?.find(
    ({ stepName }) => stepName === "Visa interview Call 1"
  );

  let call2 = interview1?.find(
    ({ stepName }) => stepName === "Visa interview Call 2"
  );
  const handleSubTabChange = (e, newValue) => {
    if (newValue === "1cb5ebc8-19fd-11ed-861d-0242ac120002") {
      dispatch(
        getDocumentModelBySubStageId(
          studentId,
          productId,
          "1cb5ebc8-19fd-11ed-861d-0242ac120002"
        )
      );

      let arr = steps.filter(({ id }) => id === newValue);

      let newSectionId = arr.length !== 0 ? arr[0]["id"] : null;
      // setState({
      //   ...state,
      //   activeSubTabValue: "CV Review",
      //   SubsectionId: newSectionId,
      //   activeStateValue: newValue,
      // });
    } else {
      let Tabsteps1 = studentStages?.data
        ?.find((el) => el.stepName === "Post Admit Services")
        ?.steps?.find((el) => el.stepName === "Visa interview Call")?.steps;

      let arr1 = Tabsteps1.filter(({ id }) => id === newValue);
      console.log(arr1, "&&&&&&TabSteps");

      let newSectionId1 = arr1.length !== 0 ? arr1[0]["id"] : null;
      console.log(newSectionId1, "%%%%%%%");

      setState({
        ...state,
        activeSubTabValue: arr1?.[0]?.["stepName"],
        activeStateValue: newValue,
        SubsectionId: newSectionId1,
      });
    }
  };

  const handleCancel = () => {
    setState({
      ...state,
      file: null,
      fileName: null,
      comment: null,
      fileNameHelperText: "",
      commentHelperText: "",
      open: false,
    });
  };

  const handleUpload = () => {
    console.log("file upload");
    let error = false;
    console.log(file);
    if (!file) {
      error = true;
      setState({
        ...state,
        snackOpen: true,
        snackMsg: FILE_UPLOAD_ERROR,
        snackVariant: "error",
      });
    } else if (!(condition.length !== 0)) {
      error = true;
      setState({
        ...state,
        snackOpen: true,
        snackMsg: "Please select a Document Type",
        snackVariant: "error",
      });
    }

    console.log(countryOrRegionId, documentTypeId);
    console.log();
    if (!error) {
      var fileObj = file;
      var newFileName = fileName;
      var newFileType = fileObj.path.split(".").pop();
      var blob = new Blob([fileObj], { type: newFileType });
      var newFile = new File([blob], `${newFileName}`, {
        type: newFileType,
      });
      let uploadFormData = new FormData();
      uploadFormData.append("file", newFile);
      console.log(condition, documentTypeId, countryOrRegionId);
      if (condition === "Scholarship details") {
        if (!(countryOrRegionId.length !== 0)) {
          error = true;
          setState({
            ...state,
            snackOpen: true,
            snackMsg: "Please select a Region",
            snackVariant: "error",
          });
        } else {
          dispatch(
            uploadFilePASregion(
              studentId,
              productId,
              documentTypeId,
              countryOrRegionId,
              uploadFormData,
              comment
            )
          );
          setDocumentTypeId("");
          setCountryOrRegionId("");
          setCondition("");
        }
      } else if (condition === "Visa checklist") {
        if (!(countryOrRegionId.length !== 0)) {
          error = true;
          setState({
            ...state,
            snackOpen: true,
            snackMsg: "Please select a Country",
            snackVariant: "error",
          });
        } else {
          dispatch(
            uploadFilePAScountry(
              studentId,
              productId,
              documentTypeId,
              countryOrRegionId,
              uploadFormData,
              comment
            )
          );
          setDocumentTypeId("");
          setCountryOrRegionId("");
          setCondition("");
        }
      } else {
        dispatch(
          uploadFilePAS(
            studentId,
            productId,
            documentTypeId,

            uploadFormData,
            comment
          )
        );
        setDocumentTypeId("");
        setCountryOrRegionId("");
        setCondition("");
      }
    }
  };

  const handleUploadClick = () => {
    setState({ ...state, open: true });
  };

  const handleDrop = (fileArray) => {
    if (fileArray.length !== 0) {
      if (bytesToMegaBytes(fileArray[0]["size"]) > 5) {
        setState({
          ...state,
          snackOpen: true,
          snackMsg: FILE_SIZE_ERROR,
          snackVariant: "error",
        });
      } else {
        setState({
          ...state,
          file: fileArray[0],
          fileName: upcomingFileName || fileArray[0]["name"],
        });
      }
    } else {
      setState({
        ...state,
        snackOpen: true,
        snackMsg: FILE_FORMAT_ERROR,
        snackVariant: "error",
      });
    }
  };

  const handleComment = (comment, e) => {
    setState({
      ...state,
      popoverComment: comment,
      anchorEl: e.currentTarget,
    });
  };

  const handleDownload = (documentId, fileName, e) => {
    console.log(documentId, "downloaddocumentId");
    dispatch(DocumentDownloadPAS(studentId, productId, documentId, fileName));
  };

  const handleDelete = (documentId, documentTypeId) => {
    console.log(documentTypeId, "getthefileName");
    dispatch(deleteFilesPAS(studentId, productId, documentTypeId));
    setTimeout(() => {
      dispatch(getFilesPAS(studentId, productId));
    }, 500);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value, [`${name}HelperText`]: null });
  };

  const isStageCompleted = () => {
    return completedStagesList.includes("Profile Mentoring");
  };
  const documentId = (value) => {
    console.log(value, "getthedocumentvalue");
    setDocumentTypeId(value);
  };
  const countryRegionId = (value) => {
    console.log(value);
    setCountryOrRegionId(value);
  };
  const countryOrRegionCond = (value) => {
    console.log(value);
    setCondition(value);
  };
  let TabstepsQPMC1 = studentStages?.data
    ?.find((el) => el.stepName === "Post Admit Services")
    ?.steps?.find((el) => el.stepName === "Visa interview Call")?.steps;

  let a = [];
  if (TabstepsQPMC1) {
    a = [...TabstepsQPMC1]?.sort((a, b) => a.orderNo - b.orderNo);
  }

  const renderComponent = () => {
    const renderProps = {
      open: open,
      stepName: activeTabValue,
      handleCancel: handleCancel,
      handleUpload: handleUpload,
      handleUploadClick: handleUploadClick,
      onDrop: handleDrop,
      handleComment: handleComment,
      handleDownload: handleDownload,
      handleDelete: handleDelete,
      tableData: documentList,
      handleChange: handleChange,
      fileName: fileName,
      comment: comment,
      fileNameHelperText: fileNameHelperText,
      commentHelperText: commentHelperText,
      file: file,
      disabledUploadButton: false,
      isDisabledFileName: true,
      lastestCVLoading: cvloader,
      documentId: documentId,
      countryRegionId: countryRegionId,
      countryOrRegionCond: countryOrRegionCond,
      ...props,
    };
    if (activeSubTabValue && activeTabValue) {
      if (activeTabValue === "Student's perusal Document") {
        return <DocumentComponentForPostAdmit {...renderProps} />;
      } else if (activeTabValue === "Feedback Session") {
        return (
          <MomPoints
            activeTabValue="Feedback Session"
            activeStateValue={arr2?.id}
          />
        );
      } else if (activeTabValue === "Upload Admit Card") {
        return (
          <AdmitIndex
            activeTabValue="Feedback Session"
            activeStateValue={arr2?.id}
          />
        );
      }
      // else
      //   if (activeTabValue === "Visa interview Call" && activeSubTabValue === "Visa interview Call 1") {
      //     return (
      //       <>
      //         <MomInterview
      //           tabList={a}
      //           activeTabValue={"Visa interview Call 1"}
      //           activeStateValue={call1?.id} />
      //       </>

      //     );
      //   }
      else if (activeSubTabValue === "Visa interview Call 1") {
        return (
          <>
            <MomInterview
              activeTabValue={"Visa interview Call 1"}
              activeValue={activeStateValue}
              a={a}
              handleSubTabChange={handleSubTabChange}
              activeStateValue={call1?.id}
            />
          </>
        );
      } else if (activeSubTabValue === "Visa interview Call 2") {
        return (
          <>
            <MomInterview
              activeTabValue={"Visa interview Call 2"}
              activeValue={activeStateValue}
              a={a}
              handleSubTabChange={handleSubTabChange}
              activeStateValue={call2?.id}
            />
          </>
        );
      }
    }
    console.log(activeSubTabValue, "**************subTabvalue *************");
  };

  const handleTabChange = (e, newValue) => {
    if (newValue === "QPMC 1") {
      dispatch(
        getDocumentModelBySubStageId(
          studentId,
          productId,
          "c4971d3a-b4a4-11ec-b909-0242ac120002"
        )
      );
      let arr = steps.filter(({ sectionName }) => sectionName === newValue);
      let newSectionId = arr.length !== 0 ? arr[0]["id"] : null;
      setState({ ...state, activeTabValue: newValue, sectionId: newSectionId });
    } else {
      let Tabsteps = getproductsteps?.steps?.find(
        (el) => el.stepName === "Completed Cv"
      ).steps;
      let arr = Tabsteps.filter(({ stepName }) => stepName === newValue);
      let newSectionId = arr.length !== 0 ? arr[0]["id"] : null;
      setState({ ...state, activeTabValue: newValue, sectionId: newSectionId });
    }
  };

  const renderTabs = () => {
    console.log(
      getproductsteps?.steps?.find((el) => el.stepName === "Completed Cv").steps
    );
    let Tabsteps = [
      {
        color: null,
        description: "Evaluate yourself with our customised assessment tests.",
        disabled: false,
        endMonth: "2022-08-16T10:46:04.654Z",
        href: "school_research",
        icon: null,
        iconCompleted: null,
        id: "4979dffd-d8e5-4ff8-bdf7-fdd0944d4d3b",
        image:
          "https://ufcdn.s3.ap-south-1.amazonaws.com/Assets/ACS/MS/Onboarding/Graduate_Tests.svg",
        isChild: null,
        isParent: null,
        lockImg:
          "https://ufcdn.s3.ap-south-1.amazonaws.com/Assets/ACS/MS/Dashboard/Stratagy_Session.svg",
        max_tat: 7,
        min_tat: 5,
        orderNo: null,
        rank: 3,
        startMonth: "2022-08-16T10:46:04.654Z",
        stepName: "Upload Admit Card",
      },
      {
        color: null,
        description: "Evaluate yourself with our customised assessment tests.",
        disabled: false,
        endMonth: "2022-08-16T10:46:04.654Z",
        href: "school_research",
        icon: null,
        iconCompleted: null,
        id: "4979dffd-d8e5-4ff8-bdf7-fdd0944d4d3b",
        image:
          "https://ufcdn.s3.ap-south-1.amazonaws.com/Assets/ACS/MS/Onboarding/Graduate_Tests.svg",
        isChild: null,
        isParent: null,
        lockImg:
          "https://ufcdn.s3.ap-south-1.amazonaws.com/Assets/ACS/MS/Dashboard/Stratagy_Session.svg",
        max_tat: 7,
        min_tat: 5,
        orderNo: null,
        rank: 3,
        startMonth: "2022-08-16T10:46:04.654Z",
        stepName: "Feedback Session",
      },
      {
        color: null,
        description: "Evaluate yourself with our customised assessment tests.",
        disabled: false,
        endMonth: "2022-08-16T10:46:04.654Z",
        href: "school_research",
        icon: null,
        iconCompleted: null,
        id: "4979dffd-d8e5-4ff8-bdf7-fdd0944d4d3b",
        image:
          "https://ufcdn.s3.ap-south-1.amazonaws.com/Assets/ACS/MS/Onboarding/Graduate_Tests.svg",
        isChild: null,
        isParent: null,
        lockImg:
          "https://ufcdn.s3.ap-south-1.amazonaws.com/Assets/ACS/MS/Dashboard/Stratagy_Session.svg",
        max_tat: 7,
        min_tat: 5,
        orderNo: null,
        rank: 3,
        startMonth: "2022-08-16T10:46:04.654Z",
        stepName: "Student's perusal Document",
      },
      {
        color: "null",
        description: null,
        disabled: true,
        endMonth: null,
        href: "visa_interview_call",
        icon: "visaInterviewCallInProgress",
        iconCompleted: "visaInterviewCallCompleted",
        id: "3d5a17b8-2453-11ed-861d-0242ac120002",
        image: null,
        isChild: true,
        isParent: false,
        lockImg: null,
        max_tat: null,
        min_tat: null,
        orderNo: null,
        rank: 3,
        startMonth: null,
        status: "TODO",
        stepName: "Visa interview Call",
      },
    ];
    return Tabsteps !== 0
      ? Tabsteps?.map(({ sectionName, id, stepName }, index) => (
          <CustomTab
            value={stepName}
            label={stepName}
            id={`${id}${index}`}
            minHeight={"72px"}
          />
        ))
      : null;
  };

  console.log();

  const handleSnackClose = () => {
    setState({ ...state, snackOpen: false, snackVariant: "", snackMsg: "" });
  };

  const handleClickAway = () => {
    setState({ ...state, anchorEl: null, popoverComment: null });
  };
  const serviceClose = () => {
    // let stageId = studentStages?.data?.find(
    //   (el) => el.stepName === "Post Admit Services"
    // ).id;
    // console.log(stageId)
    // dispatch(getClosePostAdmitServices(studentId, productId, stageId));
    setOpenPopup(true);
  };
  const handleStop = () => {
    let stageId = studentStages?.data?.find(
      (el) => el.stepName === "Post Admit Services"
    ).id;
    console.log(stageId);
    dispatch(getClosePostAdmitServices(studentId, productId, stageId));
    setOpenPopup(false);
  };
  const handleClosePop = () => {
    setOpenPopup(false);
  };
  const handlePostAdmitActivation = () => {
    let stageId = studentStages?.data?.find(
      (el) => el.stepName === "Post Admit Services"
    ).id;
    console.log(stageId);

    dispatch(getEnablePostAdmitServices(studentId, productId, stageId));
  };
  useEffect(() => {
    dispatch(getStudentStageByProductId(studentId, productId));
  }, [PostAdmitServicesStatus]);

  return (
    <div className={classes.preStrategyWorkSheetContainer}>
      <Grid container>
        {postAdmitStatus === true && (
          <>
            <PrimaryButton
              color={"Secondary"}
              variant={"outlined"}
              className={classes.closeServiceButton}
              onClick={serviceClose}
            >
              {"Close Services"}
            </PrimaryButton>
            <Grid item lg={12} xs={12} xl={12} md={12} sm={12}>
              <Box display={"flex"} alignItems={"center"}>
                <Box flex={1}>
                  <CustomTabs value={activeTabValue} onChange={handleTabChange}>
                    {renderTabs()}
                  </CustomTabs>
                </Box>
              </Box>
              <Divider className={classes.dividerStyle} />
              {renderComponent()}
            </Grid>
          </>
        )}
        {postAdmitStatus === false && (
          <Grid item xs={12}>
            <Grid container>
              <div className={classes.GroupImage}>
                <img src={GroupImage} alt={"ADD IMAGE"} />
                <br />
                <Typo
                  style={{
                    color: "grey",
                    position: "absolute",
                    padding: "10px",
                    textAlign: "center",
                    fontSize: "18px",
                  }}
                >
                  Post&nbsp;Admit&nbsp;Services&nbsp;is&nbsp;not&nbsp;enabled&nbsp;yet
                </Typo>
                <PrimaryButton
                  color={"primary"}
                  variant={"contained"}
                  // disabled={this.isStageCompleted()}
                  onClick={handlePostAdmitActivation}
                  className={classes.PostAdmitButton}
                >
                  {"Enable the Post Admit Services"}
                </PrimaryButton>
              </div>
            </Grid>
          </Grid>
        )}
        {/* <Grid item lg={12} xs={12} xl={12} md={12} sm={12}>
          {renderComponent()}
        </Grid> */}
      </Grid>
      <CommentBoxPopper
        anchorEl={anchorEl}
        handleClickAway={handleClickAway}
        popperComment={popoverComment}
      />
      <MySnackBar
        onClose={handleSnackClose}
        snackOpen={snackOpen}
        snackVariant={snackVariant}
        snackMsg={snackMsg}
      />
      <Backdrop className={classes.backdrop} open={loading}>
        <Loader />
      </Backdrop>
      <CustomPopupPAS
        title={"Confirmation"}
        open={openPopup}
        width={"600px"}
        leftButtonText={"Stop the Services"}
        handleLeftButton={handleStop}
        rightButtonText={"Cancel"}
        handleRightButton={handleClosePop}
        dialogContent={"Wants to stop the services"}
      ></CustomPopupPAS>
    </div>
  );
}

export default Index;
