import * as React from "react";
import { Box, Divider, Grid } from "@material-ui/core";
import LatexViewer from "../../../../../Utils/LatexViewer";
import { Passage as passage } from "../../../../../Assets/css/Preview/TestComponent";
import { QuestionDiv } from "../../../../../Assets/css/Preview/GmatStyles";
import DropDown from "../../../../../Utils/PreviewDropDown";

export default function Binary(props) {
  const { testResponse } = props;
  const option = (option) => [
    { title: "Select", value: "Select", id: "default", disabled: true },
    ...option.map((item, index) => ({
      title: item,
      value: item,
      id: item,
    })),
  ];
  const math = (ii) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(ii, "text/html").body.textContent;
    return doc;
  };
  const { Question } = passage;

  const style = {
    show: {
      display: "block",
    },
    hide: {
      display: "none",
    },
  };
  const { hide, show } = style;
  return (
    <Grid container>
      <Grid
        item
        xs={6}
        style={testResponse.data.isHaveDescription === false ? hide : show}
        className="blue_border"
      >
        <Box display="flex" height="100%" justifyContent="space-between">
          <QuestionDiv>
            {testResponse.data.description !== "" && (
              <Box fontSize={16} color={"#052A4E"} lineHeight={"30px"}>
                <LatexViewer math={testResponse.data.description} />
              </Box>
            )}
            {testResponse.data.isHaveDescription === false ? (
              ""
            ) : (
              <Box>
                <Divider variant="middle" orientation={"vertical"} />
              </Box>
            )}
          </QuestionDiv>
        </Box>
      </Grid>
      <Grid item xs={testResponse.data.isHaveDescription === false ? 12 : 6}>
        <Box className={"overflow-scroll"} id="inside-test-container">
          <QuestionDiv>
            <Question style={{ marginBottom: "15px" }}>
              <LatexViewer math={testResponse.data.question} />
            </Question>
            {testResponse.data.choices.map((item) => {
              return (
                <Grid container spacing={2}>
                  <span
                    style={{
                      wordBreak: "break-word",
                      width: "100%",
                      marginBottom: "2rem",
                    }}
                  >
                    <span>
                      {math(item.text)}
                      <DropDown
                        name="Map Error Genre"
                        items={option(item.dropDownList)}
                        value={item.dropDown || "default"}
                      />
                    </span>
                  </span>
                </Grid>
              );
            })}
          </QuestionDiv>
        </Box>
      </Grid>
    </Grid>
  );
}
