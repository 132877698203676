import React, { useState, useEffect } from 'react';
// import TestimonialForm from './TestimonialForm';
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  Typography
} from '@material-ui/core';
import useTable from '../Utils/useTable';
import Controls from '../Utils/controls/Controls';
import { Search } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import Popup from '../Utils/Popup';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CloseIcon from '@material-ui/icons/Close';
import Notification from '../Utils/Notification';
import Loader from '../Utils/controls/Loader';
import MuiAlert from '@material-ui/lab/Alert';
import ConfirmDialog from '../Utils/ConfirmDialog';
import { useSelector, useDispatch } from 'react-redux';
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  listTestimonials,
  deleteTestimonial,
  createTestimonial,
  updateTestimonial,
} from '../../Actions/TestimonialActions';
import { CRUDwebinarPath, studentPath } from "../RoutePaths";
import BackButton from '../../Asset/Images/backbutton.svg';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Breadcrumbs } from '@material-ui/core'
import { Typo } from '../Utils/controls/Styles';
import { Co2Sharp } from '@mui/icons-material';
import { downloadFileAppEssayLorReports, downloadFileCV, downloadFileEssayLorReports, downloadFileMarksheetsGat, downloadFileOthersAdmit, downloadFileOthersPerusal, getFilesList, getToolsCall, getWebinarCall } from '../../Actions/CRUDwebinar';
import CustomizedSnackBars from '../PostAdmitServiceStage/CustomizedSnackBars';
import MySnackBar from '../MySnackBar';
import moment from 'moment';
import { clearCustomData } from '../../Actions/CRUDwebinar';
import { getStudentStageByProductId } from '../../Actions/Student';

const Alert = (props) => <MuiAlert elevation={6} variant='filled' {...props} />;

const useStyles = makeStyles((theme) => ({
  pageContent: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(3),
  },
  folderIcon: {
    padding: "3%",
    alignItems: "center",
    alignContent: "center",
    textAlign: "center"
  },
  noFilesText: {
    fontWeight: 500,
    fontSize: "20px",
    color: "grey",
    padding: "20%",
    paddingLeft: "40%"
  },

}));



export default function FilesPage(props) {
  const classes = useStyles();
  const { documentList, fileList, getdownloadFileCV } = useSelector((state) => state.CRUDwebinarReducer);
  const { studentStages, subStageSteps } = useSelector(
    (state) => state.StudentReducer
  );
  const dispatch = useDispatch();
  const [state, setState] = useState({
    snackMsg: "",
    snackOpen: false,
    snackVariant: "",

  });
  const {

    snackMsg,
    snackOpen,
    snackVariant,

  } = state;
  let studentId = sessionStorage.getItem("studentId");
  let productId = sessionStorage.getItem("productId")
  let type = sessionStorage.getItem("type")
  let type2 = sessionStorage.getItem("type2")





  const downloadFile = (item) => {
    console.log(item?.schoolId, "&&&&&^&&&&&")
    if (type === "CV") {
      dispatch(downloadFileCV(studentId, productId, item.fileId, item.path));
    }
    if (type === "Essay") {
      if (item?.schoolId) {
        dispatch(downloadFileAppEssayLorReports(studentId, item.subStageId, item.schoolId, item.path, item.schoolType, item.path))
      }
      else {
        dispatch(downloadFileEssayLorReports(studentId, item.subStageId, item.fileId, item.path))
      }
    }
    if (type === "GAT") {
      dispatch(downloadFileMarksheetsGat(studentId, item.fileId, item.path))
    }
    if (type === "LOR") {
      if (item?.schoolId) {
        dispatch(downloadFileAppEssayLorReports(studentId, item.subStageId, item.schoolId, item.path, item.schoolType, item.path))
      }
      else {
        dispatch(downloadFileEssayLorReports(studentId, item.subStageId, item.fileId, item.path))
      }
    }
    if (type === "Marksheets") {
      dispatch(downloadFileMarksheetsGat(studentId, item.fileId, item.path))
    }
    if (type === "Others") {
      if (type2 === "AdmitCard") {
        console.log("Admity card if")
        dispatch(downloadFileOthersAdmit(studentId, productId, item.admitcardId, item.postadmitschoolId, item.fileId, item.path))
      }
      else if (type2 === "Perusal") {
        dispatch(downloadFileOthersPerusal(studentId, productId, item.fileId, item.path))
      }
    }
    if (type === "Reports") {
      console.log("persual if")
      dispatch(downloadFileEssayLorReports(studentId, item.subStageId, item.fileId, item.path))
    }


  };

  useEffect(() => {
    if (getdownloadFileCV?.success === true) {

      setState({
        ...state,
        snackOpen: true,
        snackMsg: "Downloaded Successfully",
        snackVariant: "success",
      });
    }
    else if (getdownloadFileCV?.success === false) {

      setState({
        ...state,
        snackOpen: true,
        snackMsg: "Download Failed",
        snackVariant: "error",
      });
    }

    setTimeout(() => {
      setState({
        ...state,
        snackOpen: false,

      });
      dispatch(clearCustomData("getdownliadFileCV"));
    }, 2000);
  }, [getdownloadFileCV]);




  useEffect(() => {

    dispatch(getFilesList(studentId, productId, type));
    dispatch(getStudentStageByProductId(studentId, productId));
    console.log(studentStages, subStageSteps, "bingo"
      // ?.find((el) => el.stepName === "Application Stage").steps?.fin((el) => el.stepName === "Upload Documents").steps?.find((el) => el.stepName === "Essay Framework")
    )
  }, []);



  console.log(fileList)
  // console.log(studentStages?.data
  //   ?.find((el) => el.stepName === "Application Stage")
  //   .steps?.find((el) => el.stepName === "Upload Documents")
  //   .steps?.find((el) => el.stepName === "Essay FrameWork")
  // )
  return (
    <>

      <div style={{ display: "flex", flexDirection: "row", margin: "10px" }}>
        <img
          src={BackButton}
          style={{ cursor: "pointer", marginTop: "-10px" }}
          onClick={() => props.history.goBack()}
        />
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <Typography style={{ cursor: "pointer", fontWeight: "600", marginLeft: "10px" }} onClick={() => props.history.push(studentPath)}>
            Home
          </Typography>
          <Typography style={{ cursor: "pointer", fontWeight: "600" }}>
            Manage Client
          </Typography>
          <Typography style={{ cursor: "pointer", fontWeight: "600" }}>
            Files
          </Typography>
        </Breadcrumbs>
      </div>
      <Grid container xs={12}>
        {console.log(fileList?.data?.data?.[0]?.documents?.length)}
        {fileList?.success === false || fileList?.data?.data?.[0]?.documents?.length === 0 &&
          (
            <Typo className={classes.noFilesText}>
              No Files to Display
            </Typo>
          )

        }
        {fileList?.data?.data !== 0 && fileList?.success === true && fileList?.data?.data?.map((item, fileName) => {


          return (<>
            {item?.document && (
              <Typo className={classes.noFilesText}>
                No Files to Display
              </Typo>

            )}
          </>

          )
        })}


        {fileList?.data?.data !== 0 && fileList?.success === true && fileList?.data?.data?.map((item, fileName) => {
          { console.log(item, fileName, item.fileName) }
          return (<Grid item xs={2}>
            {item.path && (
              <>
                <div className={classes.folderIcon}>
                  <img
                    src={require("../../Asset/Images/Files.png")}
                    alt={"Not supported"}
                    style={{ cursor: "pointer", height: 100, width: 130 }}
                    onClick={() => downloadFile(item)}

                  />
                </div>
                <Typography style={{ cursor: "pointer", fontWeight: "600", color: "grey", textAlign: "center" }}>
                  {item.fileName}
                </Typography>
              </>

            )}



          </Grid>

          )

        })}

        <MySnackBar

          snackOpen={snackOpen}
          snackVariant={snackVariant}
          snackMsg={snackMsg}
        />
      </Grid>




    </>
  );
}
