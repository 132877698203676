import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import DataTable from './DataTable';
import { updateDriveData } from '../../../Actions/DashboardAction';
import { useDispatch, useSelector } from 'react-redux';
import LockIcon from '@mui/icons-material/Lock';
import Loader from '../../../Lms/Utils/Loader';
import DateRangePickerComp from './DateRangePicker';

const useStyle = makeStyles((theme) => ({
  paper: {
    boxShadow: '0px 16px 24px rgba(146, 170, 212, 0.12)',
    borderRadius: '8px',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  textWithIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  headerText: {
    fontSize: '18px',
    color: '#354354',
  },
  modalContainer: {
    textAlign: 'center',
    alignItems: 'center',
    width: '140px',
    position: 'absolute',
    right: 0,
    top: -50,
    backgroundColor: '#fefefe',
    borderRadius: '8px',
  },
  btnText: {
    height: '25px',
    width: '100%',
  },
  buttonContainer: {
    border: '1px solid #E1E5EB',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    color: '#354354',
    width: '100px',
    height: '35px',
    cursor: 'pointer',
  },
  icons: {
    fontSize: '6px',
    color: '#828D98',
  },
}));

const DashboardDriveUpdate = () => {
  const classes = useStyle();
  const [startDate, setStartDate] = useState(new Date(new Date().setUTCHours(0, 0, 0)));
  const [endDate, setEndDate] = useState(new Date(new Date().setUTCHours(23, 59, 59)));
  const [value, setValue] = useState(false);
  const dispatch = useDispatch();
  const { loading, error, updateDrives } = useSelector(
    (state) => state.dashboardUpdateDrivesReducer
  );

  useEffect(() => {
    updateDriveCalling();
    setValue(false);
  }, [value]);

  let requestBody = {
    fromDate: startDate,
    toDate: endDate,
  };

  const updateDriveCalling = () => {
    dispatch(updateDriveData(requestBody));
  };

  return (
    <Grid item xs={6}>
      <Paper elevation={3} className={classes.paper}>
        <div style={{ padding: '20px' }}>
          <Box className={classes.headerContainer}>
            <Box className={classes.textWithIcon}>
              <LockIcon sx={{ color: '#63AA99', fontSize: '28px' }} />
              <Typography fontWeight={'500'} marginLeft={2} className={classes.headerText}>
                Drives Updated
              </Typography>
            </Box>
            <Box>
              <DateRangePickerComp
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setValue={setValue}
              />
            </Box>
          </Box>
        </div>
        {updateDrives?.data && (
          <DataTable
            sorting={false}
            maxBodyHeight={345}
            minBodyHeight={345}
            columns={[
              {
                title: '#No',
                field: 'tableData.id',
                width: '10%',
                type: 'numeric',
                align: 'left',
                render: (rowData) => rowData.tableData.id + 1,
              },
              {
                title: 'Drive Name',
                field: 'eventName',
                width: '40%',
              },
              {
                title: 'Qualified',
                field: 'qualifiedCount',
                align: 'center',
                type: 'numeric',
                width: '10%',
              },
              {
                title: 'Round',
                field: 'stepName',
                align: 'center',
                width: '30%',
              },
              {
                title: 'Registrations',
                field: 'registrationCount',
                type: 'numeric',
                width: '10%',
                align: 'right',
              },
            ]}
            data={updateDrives?.data}
          />
        )}
        <div style={{ margin: '2rem auto', width: '60%' }}>
          {loading && <Loader />}
          {error && <Alert severity='info'>0 Tests Found</Alert>}
        </div>
      </Paper>
    </Grid>
  );
};

export default DashboardDriveUpdate;
